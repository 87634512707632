import { BaseApiRequest } from "@pavabits/components";
import BaseReponse from "../../domain/BaseResponse";
import { CodigoPostalEndpoints } from "../../modules/Oportunidades/infraestructure/oportunidades.api-endpoints";
import { CodigoPostalDto, CodigoPostalFilter } from "../../domain/model/CodigoPostal";

export default class CodigoPostalRequestListByFilter extends BaseApiRequest<
	CodigoPostalFilter,
	BaseReponse<CodigoPostalDto[]>
> {
	public constructor(data: CodigoPostalFilter, token: string) {
		super(
			"POST",
			CodigoPostalEndpoints.CodigoPostal_CON_FILTRO,
			data,
			{ Authorization: `${token}` }
		)
	}
}