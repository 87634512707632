import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";
import BaseResponse from "../../../../domain/BaseResponse";
import UnidadObraElementoRepository from "../../domain/UnidadObraElementoRepository";
import UnidadObraElemento, { UnidadObraElementoReadDto } from "../../domain/model/UnidadObraElemento";
import UnidadObraElementoRequestGetById from "../ws/UnidadObraElementoRequestGetById";
import UnidadObraElementoRequestListByFilter from "../ws/UnidadObraElementoRequestListByFilter";

export default class UnidadObraElementoApiRepository extends ApiRepositoryBase<UnidadObraElementoReadDto, UnidadObraElemento> implements UnidadObraElementoRepository {
    protected transformDtoIntoModel(dto: BaseResponse<UnidadObraElementoReadDto>): BaseResponse<UnidadObraElemento> {
        return (
            {
                items: this.buildModelFromItems(dto.items),
                currentPage: dto.currentPage,
                totalCount: dto.totalCount,
            }
        )    
    }

    buildModelFromItems(dtoItems: UnidadObraElementoReadDto): UnidadObraElemento {
        return new UnidadObraElemento(dtoItems);
    }
 
    async getUnidadObraElementosConFiltro(filter: any): Promise<UnidadObraElementoReadDto[]> {
        const res= await new UnidadObraElementoRequestListByFilter(filter, this.authToken).executeAsPromise();
        return res?.items;
    }

    async getById(id: string): Promise<UnidadObraElementoReadDto> {
        return await new UnidadObraElementoRequestGetById(id, this.authToken).executeAsPromise();

    }
}