import ApiRequest from "../../../../domain/ApiRequest";
import { ElementosEquipoApiEndpoints, } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class EquipoElementoRequestDelete extends ApiRequest<
{},
boolean
>
{
	constructor(id: string, token: string) {
		super(
			"DELETE",
			ElementosEquipoApiEndpoints.DELETE_ELEMENTO+id,
			{ Authorization: `${token}`},
			{}
		)
	}
}