import { useContext, useEffect } from "react";
import { GlobalContext, GlobalContextType } from "../../../context/Global.Context";
import useColumnasGrid from "../../../hooks/useColumnasGrid";
import useClientesTags from "../../Ofertas/hooks/useClienteTags";
import { TagsReadContext } from "../../Oportunidades/context/FilterFields.Context";
import useFilters from "../../Oportunidades/hooks/useFilters/useFilters";
import { ClienteFilter } from "../domain/model/Cliente";
import useClientePost from "../hooks/useClientePost";
import { ClienteFilterState, ClientePagination, ClienteSearchHandler, ClienteTableData, ClienteTagHandler, ClienteTags } from "./Cliente.Context";

const ClienteProvider: React.FC<{}> = ({ children }) => {

	const { columns } = useColumnasGrid("BACKENDOPORTUNIDADES", "CLIENTE");
	const defaultSorting = [{ field: "lastModificationTime", desc: true }];
	const { globalState, updateGlobalState } = useContext(GlobalContext) as GlobalContextType;
	const globalFilters = globalState?.clientes?.filters;

	const {
		filter,
		sorting,
		onSearchField,
		onPageBackwards,
		onPageSelect,
		onSetMaxCount,
		onSort
	} = useFilters<ClienteFilter>({ 
		sorting: defaultSorting,
		filtering: {
			filter: globalFilters ?? {},
			maxResultCount: globalState?.clientes?.pagination?.maxResultCount ?? 10,
			skipCount: globalState?.clientes?.pagination?.skipCount ?? 0,
			sortingCriteria:  globalState?.clientes?.order?.sortingCriteria ? (globalState?.clientes?.order?.sortingCriteria + globalState?.clientes?.order?.desc) : "",
		}
	 });

	const maxResultCount = filter.maxResultCount;

	const data = useClientePost(filter);

	const { tags, updateTags } = useClientesTags();

	useEffect(() => {
		updateGlobalState("clientes", {filters: filter.filter})
		updateGlobalState("clientes", {tags: tags})
	 }, [filter])

	return (
		<ClienteTableData.Provider value={
			{ columns, data }
		}>
			<ClienteFilterState.Provider value={{ filter }}>
				<ClienteSearchHandler.Provider value={{ onSearchField }}>
					<ClienteTags.Provider value={{ tags }}>
						<ClienteTagHandler.Provider value={{ onFilter: updateTags }}>
							<ClientePagination.Provider value={{ sorting, maxResultCount, onPageBackwards, onPageSelect, onSetMaxCount, onSort }}>
							<TagsReadContext.Provider value={
								{
									tags: tags
								}
								}>
								{children}
								</TagsReadContext.Provider>
							</ClientePagination.Provider>
						</ClienteTagHandler.Provider>
					</ClienteTags.Provider>
				</ClienteSearchHandler.Provider>
			</ClienteFilterState.Provider>
		</ClienteTableData.Provider>
	)
}

export default ClienteProvider;