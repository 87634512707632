import ApiRequest from "../../../../domain/ApiRequest";
import { UnidadObraMaterialApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";


export default class UnidadesMaterialRequestDelete extends ApiRequest<{}, boolean> 
{
    constructor(id: string, token: string) {
        super(
            "DELETE",
            UnidadObraMaterialApiEndpoints.DELETE+id,
            {Authorization: `${token}`},
             {}
        )
    }
}