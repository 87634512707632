import { BaseApiRequest } from "@pavabits/components";
import BaseReponse from "../../../../domain/BaseResponse";
import { PromotoresEndpoints } from "../oportunidades.api-endpoints";
import { PromotorDto, PromotorFilter } from "../../domain/model/Promotor";

export default class PromotorRequestListBySearch extends BaseApiRequest<
PromotorFilter,
BaseReponse<PromotorDto[]>
> {
	public constructor(data: PromotorFilter, token: string) {
		super(
			"POST",
			PromotoresEndpoints.PROMOTORES_CON_FILTRO,
			data,
			{ Authorization: `${token}`}
		)
	}
}