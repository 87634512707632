import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { EstadoOfertaEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { EstadoOfertaDto } from "../../domain/model/EstadoOferta";

export default class EstadoOfertaRequestGetAll extends ApiRequest<{}, BaseResponse<EstadoOfertaDto[]>>
{
	constructor( token: string) {
		super(
			"GET",
			EstadoOfertaEndpoints.ALL,
			{ Authorization: token },
			{}
		)
	}
}