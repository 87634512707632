import { Icon } from "@pavabits/components"
import { SortingField } from "../../../../domain/BaseFilter";
import { useContext, useEffect, useState } from "react";
import { GlobalContext, GlobalContextType } from "../../../../context/Global.Context";

interface Props {
	columnKey: string,
	sorted?: SortingField[],
	onSort: (field: SortingField[]) => void
	component?: string,
	sortingField?: any
}

const SortingArrows: React.FC<Props> = ({ columnKey, sorted, onSort, component, sortingField }) => {

	const beingSorted = sorted && sorted.find(sort => sort.field === columnKey);
	const { globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;
	const [isSorted, setIsSorted] = useState(false);
	const [desc, setDesc] = useState(false);

	useEffect(() => {
		if (sortingField) {setIsSorted(sortingField.sortingCriteria === columnKey)}
	}, [globalState])

	function handleAsc() {
		onSort([{ field: columnKey, desc: false}]);
		setDesc(false)
		if (component) {
			updateGlobalState(component, {order: {sortingCriteria: columnKey, desc: false}})	
		}
		
		setIsSorted(columnKey === "nombre")
		if (sorted && sorted.findIndex(field => field.field.includes(columnKey)) >= 0) {
		}
	}
	
	function handleDesc() {
		onSort([{ field: columnKey, desc: true}]);
		setDesc(true)

		if (component) updateGlobalState(component, {order: {sortingCriteria: columnKey, desc: true}})
	}

	return (
		<div className="sort-arrows">
			<button 
			className={(beingSorted && !beingSorted.desc) || (isSorted && desc === false) 
			? "sorting-arrow-sorted" 
			: "sorting-arrow"} 
			onClick={handleAsc}>
			{/* <button className={`sorting-arrow`} onClick={handleAsc}> */}
				{/* <Icon icon="arrow_drop_up" /> */}
				<Icon icon="expand_less"/>
			</button>
			{/* <button className={`sorting-arrow`} onClick={handleDesc}> */}
				<button 
				className={(beingSorted && beingSorted.desc) || (isSorted && desc)
					? "sorting-arrow-sorted" 
					: "sorting-arrow"}
				onClick={handleDesc}>
			{/* <button className={`btn btn--icon arrow${(beingSorted && beingSorted.desc) || (isSorted && desc === true) ? ' arrow--sorted' : ''}`} onClick={handleDesc}> */}
				{/* <Icon icon="arrow_drop_down" /> */}
				<Icon icon="expand_more" />
			</button>
		</div>
	)
}

export default SortingArrows;