import { Icon } from "@pavabits/components";
import { ReactNode, useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import "./FilterModal.scss";
import IconButton from "../Buttons/IconButton";
import {
  GlobalContext,
  GlobalContextType,
} from "../../../../context/Global.Context";
import { OportunidadesDataContext } from "../../../../modules/Oportunidades/context/Oportunidades.Context";
import IconButtonFontAwesome from "../Buttons/IconButtonFontAwesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../../../modules/Login/AuthContextProvider";

interface Props {
  filters?: ReactNode;
  titleShow?: string;
  modalTitle?: string;
  modalSubtitle?: string;
  downloadExcel?: any;
  label?: string;
}

const FilterModal: React.FC<Props> = ({
  filters,
  titleShow,
  modalTitle,
  modalSubtitle,
  downloadExcel,
  label,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  // const {downloadExcel } = useContext(OportunidadesDataContext);
  return (
    <>
      <div className="filters-space-container">
        <button
          data-cy={titleShow + "Boton"}
          className="btn-primary"
          onClick={() => setOpen(true)}
        >
          <Icon icon="filter_alt" />
          {titleShow}
        </button>

        {downloadExcel && (
          <button className="btn-primary" onClick={downloadExcel}>
            <IconButtonFontAwesome icon={faFileExcel} />
            <p>Descargar Datos</p>
          </button>
        )}

      </div>
      <Modal
        show={open}
        onHide={() => setOpen(false)}
      >
        <ModalHeader>
          <ModalTitle data-cy={label}>{modalTitle}</ModalTitle>
          <IconButton icon="close" onClick={() => setOpen(false)} label="Close"/>
        </ModalHeader>
        {modalSubtitle && (
          <ModalTitle className="modal-title--subtitle">
            {modalSubtitle}
          </ModalTitle>
        )}
        <ModalBody>
          <div className="double-col-grid">{filters}</div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default FilterModal;
