import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { NaturalezasClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import NaturalezaCliente from "../../domain/model/NaturalezaCliente";

export default class NaturalezasClienteGetAll extends ApiRequest<undefined, BaseResponse<NaturalezaCliente[]>>
{
	constructor(token: string) {
		super(
			"GET",
			NaturalezasClienteEndpoints.BASE_ENPOINT,
			{ Authorization: token },
			undefined,
			{ }
		)
	}
}