import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react'
import CustomDropdownInput from '../../../components/CustomDropdownInput'
import { GlobalContext, GlobalContextType } from '../../../context/Global.Context';
import { AuthContext } from '../../Login/AuthContextProvider';
import TipoSeguimientoClienteRepository from '../../Clientes/domain/TipoSeguimientoClienteRepository';
import TipoSeguimientoCliente from '../../Clientes/domain/model/TipoSeguimientoCliente';
import TipoSeguimientoClienteApiRepository from '../../Clientes/infraestructure/api/TipoSeguimientoCliente.ApiRepository.';
import { SelectableItem } from '@pavabits/components';
import FormField from '../../../components/ui/molecules/Form-field/FormField';
import Dropdown, { DropdownProps } from '../../../components/ui/atoms/Dropdown/Dropdown';

const SeguimientoClienteTipoDropdownField = () => {
  const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;
  const [optionsTipoSeguimientoCliente, setOptionsTipoSeguimientoCliente] = useState<TipoSeguimientoCliente[]>([]);
  const componentName = "seguimientoCliente";
  const [value, setValue] = useState(globalState.seguimientoCliente?.filters?.tipoId ?? "");
  const tipoSeguimientoClienteSelected = { id: '0', descripcion: "Seleccionar" } as SelectableItem;
  const { getToken } = useContext(AuthContext);

  useEffect(() => {
    fetchTipoSeguimiento()
  }, [])

  const fetchTipoSeguimiento = useCallback(
    () => {
        const repo: TipoSeguimientoClienteRepository = new TipoSeguimientoClienteApiRepository(getToken());
        return repo.getAll().then((resp: TipoSeguimientoCliente[]) => {
            setOptionsTipoSeguimientoCliente(resp);
        })
    }
    , [getToken, tipoSeguimientoClienteSelected]);

  const handleSelectTipo = (tipoSeguimiento: TipoSeguimientoCliente) => {
    setValue(tipoSeguimiento.id)
    const seguimientoObj: any | undefined =  optionsTipoSeguimientoCliente.find(((item: any) => item.id === tipoSeguimiento.id));
    if (seguimientoObj) {
      const seguimientoUpd: any = {...seguimientoObj}
      updateGlobalState(componentName, 
        {
          filters: {...globalState.seguimientoCliente?.filters,
        "tipoId":  +seguimientoUpd.id,
        "tipoDescripcion": seguimientoUpd.descripcion},
        pagination: {
          skipCount: 0
        }
      },
        
      )
    }
  }
  
  return (
        <FormField<DropdownProps>
        label="Tipo"
        options={optionsTipoSeguimientoCliente.map(
          (element) => {
            return {
              text: element.descripcion,
              id: element.id,
              selected: value == +element.id ? true : false,
              onClick: () => {handleSelectTipo(element)}
            }
          })}
        disabled={false}
        singleSelection={true}
        component={Dropdown}
        idTest='FiltroTipoSeguimientoCliente'
      />
  )
}

export default SeguimientoClienteTipoDropdownField