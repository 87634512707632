import ApiRequest from "../../../../domain/ApiRequest";
import { BaseFilter } from "../../../../domain/BaseFilter";
import { OfertasEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { OfertasFilter } from "../../domain/model/Ofertas";



export default class OfertasRequestGetSumaToneladas extends ApiRequest<
BaseFilter<OfertasFilter>,
number>
{
	constructor(data: BaseFilter<OfertasFilter>, token: string) {
		super(
			"POST",
			OfertasEndpoints.SUMA_TONELADAS,
			{ Authorization: token },
			data,
		)
	}
}