import ApiRequest from "../../../../domain/ApiRequest";
import { LibroEstandarApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import LibroEstandar from "../../domain/model/LibroEstandar";

export default class LibroEstandarAutoGenerar extends ApiRequest<any, LibroEstandar>
{
    public constructor (id: string, token: string, params?: {[key: string]: number | string}) {
        super (
            "GET",
            LibroEstandarApiEndpoints.AUTOGENERAR_LIBRO_ESTANDAR + "?ofertaId=" + id,
            {Authorization: `${token}`},
            {},
            params ?? ""
        )
    }
}