import { BaseRepository } from "@pavabits/components";
import { ConfiguracionGrid } from "../../domain/model/ConfiguracionGrid/ConfiguracionGrid";
import ConfiguracionGridDto from "../../domain/model/ConfiguracionGrid/ConfiguracionGridDto";
import ConfiguracionGridFilter from "../../domain/model/ConfiguracionGrid/ConfiguracionGridFilter";
import ConfiguracionGridRepository from "../../domain/ConfiguracionGridsRepository";
import ConfiguracionGridRequestListByFilter from "../ws/ConfiguracionGrids.RequestListByFilter";

export default class ApiConfiguracionGridsRepository
	extends BaseRepository<ConfiguracionGridDto[], ConfiguracionGrid[]>
	implements ConfiguracionGridRepository {

	protected transformDtoIntoModel(dto: ConfiguracionGridDto[]): ConfiguracionGrid[] {

		return dto.sort((a, b) => a.columnOrder - b.columnOrder).map((item) => {
			return new ConfiguracionGrid(
				item.id,
				item.proyecto,
				item.entity,
				item.attributeName,
				item.columnName,
				item.sortable,
				item.type,
				item.width
			)
		})
	}

	public async retrieveConfig(filter: ConfiguracionGridFilter): Promise<ConfiguracionGrid[]> {
		return new Promise((resolve, reject) => {
			new ConfiguracionGridRequestListByFilter(filter, this.authToken)
				.request()
				.then((response) => {
					if (response.success) {
						resolve(this.transformDtoIntoModel(response.data));
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error) => {
					console.log(error)
					reject(error);
				})
		})
	}

}