import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { ClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { ClienteDto } from "../../domain/model/Cliente";

export default class ClienteRequestListByCif extends ApiRequest<
{},
BaseResponse<ClienteDto[]>>
{
	constructor(data: string, token: string) {
		super(
			"GET",
			ClienteEndpoints.CON_ID+"?Filters%5B0%5D.Key=Cif&Filters%5B0%5D.Value="+data,{ Authorization: `${token}` },
			{},
		)
	}
}

