import { FormEvent, useCallback, useContext, useEffect, useState } from 'react'
import FormTemplate from '../../../components/common/FormTemplate/FormTemplate';
import TipoSeguimientoCliente from '../../Clientes/domain/model/TipoSeguimientoCliente';
import { AuthContext } from '../../Login/AuthContextProvider';
import { SelectableItem } from '@pavabits/components';
import ContactosCliente from '../../Clientes/domain/model/ContactosCliente';
import { useHistory, useLocation } from 'react-router-dom';
import { fechaValida, formatDate2String, formatDDMMYYYY, formatYYYYMMDD } from '../../../utils/DateUtil';
import TipoSeguimientoClienteRepository from '../../Clientes/domain/TipoSeguimientoClienteRepository';
import TipoSeguimientoClienteApiRepository from '../../Clientes/infraestructure/api/TipoSeguimientoCliente.ApiRepository.';
import ContactosRepository from '../../Clientes/domain/ContactosRepository';
import ContactosClientesApiRepository from '../../Clientes/infraestructure/api/ContactosClientes.ApiRepository';
import ClienteRepository from '../../Oportunidades/domain/ClienteRepository';
import ClienteApiRepository from '../../Oportunidades/infraestructure/api/Cliente.ApiRepository';
import FormField from '../../../components/ui/molecules/Form-field/FormField';
import SingleSuggestionInputField, { SingleSuggestionInputFieldProps } from '../../../components/ui/atoms/SingleSuggestionInput/SingleSuggestionInput.Field';
import Dropdown, { DropdownProps } from '../../../components/ui/atoms/Dropdown/Dropdown';
import CustomInput from '../../../shared/components/Inputs/CustomInput/CustomInput';
import { useLoading } from '../../../context/Loading.Context';
import SeguimientoHistoricoClienteRepository from '../domain/SeguimientoHistoricoClienteRepository';
import SeguimientoHistoricoClienteApiRepository from '../infrastructure/api/SeguimientoHistoricoClienteApiRepository';
import { toast } from 'react-toastify';
import SeguimientoCliente from '../../Clientes/domain/model/SeguimientoCliente';
import Constantes from '../../../assets/constants/Constantes';

const ClientesSeguimientoEdicion = () => {
  const location = useLocation();
  const url= location.pathname.split("/")
  const id= url[url.length-1];	
  const formId = "seguimiento-historico-clientes-form";
  const [optionsTipoSeguimientoCliente, setOptionsTipoSeguimientoCliente] = useState<TipoSeguimientoCliente[]>([]);
  const { getToken, username } = useContext(AuthContext);
  const tipoSeguimientoClienteSelected = { id: '0', descripcion: "Seleccionar" } as SelectableItem;
  const [contactos, setContactos] = useState<ContactosCliente[]>([])
  const history = useHistory();
  const {setLoading} = useLoading();
  const [showFields, setShowFields] = useState<boolean>(false);
  const repo: SeguimientoHistoricoClienteRepository = new SeguimientoHistoricoClienteApiRepository(getToken());
  const [errores, setErrores] = useState<string[]>([]);

  const [seguimiento, setSeguimiento] = useState<SeguimientoCliente>({
    id: "",
    fechaSeguimiento: "",
    observaciones:"",
    clienteId: undefined,
    tipoSeguimientoClienteId: 1,
    estadoOfertaId: 1,
    lastModificationTime:"",
    contactoId:"",
    ofertaId: "",
    seguimientoOfertaId:"",
    clienteNombre: ""
})

  useEffect(() => {
    fetchTipoSeguimiento()
  }, [])

  useEffect(() => {
    fetchSeguimiento(id)
  }, [id]);

  const fetchSeguimiento = useCallback((id: string) => {
    setLoading(true);
    return repo.getById(id)
    .then((data) => {
        setSeguimiento({...seguimiento, 
          id: data.id, 
          fechaSeguimiento: formatDate2String(formatDDMMYYYY(data.fechaSeguimiento.split('T')[0])),
          observaciones: data.observaciones,
          clienteId: data.clienteId,
          tipoSeguimientoClienteId: data.tipoSeguimientoClienteId,
          estadoOfertaId: data.estadoOfertaId,
          lastModificationTime: data.lastModificationTime,
          contactoId: data.contactoId,
          ofertaId: data.ofertaId,
          seguimientoOfertaId: data.seguimientoOfertaId,
          clienteNombre: data.clienteNombre
  })
    })
    .catch(message => toast.error(message))
  }, [getToken()])

  useEffect(() => {
    setContactos([]);
    if (seguimiento.clienteId) {
      fetchContactos()
    }
  }, [seguimiento.clienteId])

  const fetchTipoSeguimiento = useCallback(
    () => {
        const repo: TipoSeguimientoClienteRepository = new TipoSeguimientoClienteApiRepository(getToken());
        return repo.getAll().then((resp: TipoSeguimientoCliente[]) => {
            setOptionsTipoSeguimientoCliente(resp);
        })
    }
    , [getToken, tipoSeguimientoClienteSelected]);

    const fetchContactos = useCallback((id?: string) => {
      const repo: ContactosRepository = new ContactosClientesApiRepository(getToken());
      setLoading(true);
      return repo.getByClienteId(seguimiento.clienteId as number)
        .then((items) => {
          setContactos(items);
        })
        .finally(() => {
          setLoading(false);
          setShowFields(true);
        });;
    }
      , [getToken, seguimiento.clienteId]);

      const handleSelectTipo = (tipoSeguimiento: TipoSeguimientoCliente) => {
        const seguimientoObj: TipoSeguimientoCliente | undefined =  optionsTipoSeguimientoCliente.find(((item: any) => item.id === tipoSeguimiento.id));
    if (seguimientoObj) {
      setSeguimiento((prev: any) => ({...prev, tipoSeguimientoClienteId: seguimientoObj.id }))
    }
  }

  const searchCliente = useCallback((search: string) => {
    const repoCliente: ClienteRepository = new ClienteApiRepository(getToken());
    return repoCliente.searchCliente({ filter: { descripcion: search} })
  }, [getToken])

  const handleFechaChange = (newValue: string) => {
    setSeguimiento((prev: any) => ({...prev, fechaSeguimiento: newValue}))
  }

  const handlePropertyChange = (property: string, newValue: string | number) => {
    setSeguimiento((prev: any) => ({...prev, [property]: newValue, Usuario: username}));
}

const handleClienteChange = (newValue: string) => {
  setSeguimiento((prev: any) => ({...prev, clienteId: newValue != "" ? Number(newValue) : undefined,
                                           contactoId: undefined, contactoNombre: undefined}))
}

const seguimientoClienteValidation = (): boolean => {
  const errores: string[] = [];

  if (seguimiento.tipoSeguimientoClienteId < 0) errores[0] = 'El tipo tiene que estar seleccionado';
  if (seguimiento.clienteId == undefined) errores[1] = 'El cliente no puede estar vacío';
  if (!fechaValida(seguimiento.fechaSeguimiento)) errores[2] = 'La fecha no es valida';
  if (seguimiento.observaciones && seguimiento.observaciones?.length > Constantes.OBSERVACIONES_MAX_LENGTH) {
      errores[3] = 'Máximo 200 caracteres.';
  }
  setErrores(errores);
  return errores.length === 0;
}

const updateSeguimiento = (data: any) => {
  repo.update({...seguimiento, fechaSeguimiento: formatYYYYMMDD(seguimiento.fechaSeguimiento)} as SeguimientoCliente)
  .then((resp) => {
    toast.success(`Seguimiento actualizado con éxito`);
    setSeguimiento({...resp, fechaSeguimiento: formatDate2String(formatDDMMYYYY(resp.fechaSeguimiento.split('T')[0]))})
  })
  .catch(() => {
    toast.error(`Error al actualizar el seguimiento`);
});
}

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (seguimientoClienteValidation()) {
      updateSeguimiento(seguimiento);
    }
  }

  return (
    <div className='add-edit-view-container'>
    <div className='add-edit-header'>
    <h4>Editar Seguimiento de Clientes</h4>
    </div>
    <div className="add-edit-body">
    <FormTemplate
        formId={formId}
        onSubmit={handleSubmit}
        >
       <div className="row-of-two">
       {optionsTipoSeguimientoCliente &&
          <FormField<DropdownProps>
            label="Tipo"
            options={optionsTipoSeguimientoCliente.filter(x => x.visible).map(
              (element) => {
                return {
                  text: element.descripcion,
                  id: element.id,
                  selected: seguimiento.tipoSeguimientoClienteId == +element.id ? true : false,
                  onClick: () => {handleSelectTipo(element)}
                }
              })}
            idTest='TipoClienteSeguimientoHistorico'
            required={true}
            disabled={false}
            singleSelection={true}
            component={Dropdown}
            error={errores[0]}
          />
        }

        {showFields && <FormField<SingleSuggestionInputFieldProps>
							label="Cliente"
							value={{ id: seguimiento.clienteId?.toString() ?? "", 
                      text: seguimiento.clienteNombre ?? ""}}
							onChange={handleClienteChange}
							searchCallback={(searchCliente)}
							component={SingleSuggestionInputField}
              required
              error={errores[1]}
              idTest="NombreClienteSeguimientoHistorico"
						/>}

        {contactos && seguimiento.id && <FormField<DropdownProps>
          label="Contactos"
          options={contactos!.map(
            (element) => {
              return {
                text: element.personaContacto,
                id: element.id,
                selected: seguimiento.contactoId == element.id ? true : false,
                onClick: () => { handlePropertyChange("contactoId", element.id); }
              }
              })}
          idTest='ContactosClienteSeguimientoHistorico'
          disabled={seguimiento.clienteId === null}
          singleSelection
          component={Dropdown}
        />}

         <CustomInput
            value={seguimiento.fechaSeguimiento}
            onChange={(e) => {handleFechaChange(e)}}
            formClassName={"secondary-input"}
            type={'date'}
            label="Fecha"
            error={errores[2]}
            idTest='FechaClienteSeguimientoHistorico'
          />

          <CustomInput
            value={seguimiento.observaciones}
             onChange={(e) => { handlePropertyChange('observaciones', e); }}
            label="Observaciones"
            idTest="ObservacionesClienteSeguimientoHistorico"
            error={errores[3]}/>
        </div>
        </FormTemplate>

    </div>
    </div>
  )
}

export default ClientesSeguimientoEdicion