
interface AjusteEntity {
	
	id?: string,
	toneladas: string,
	licitacionId: string,
	oportunidadId: string,
	last_modification_time?: string,
	motivo:string
}

export default class Ajuste  {

	id?: string;
	toneladas: string;
	licitacionId: string;
	oportunidadId: string;
	last_modification_time?: string;
	motivo:string;

	constructor(
		entity: AjusteEntity

	) {
		
		this.id = entity.id;
		this.toneladas = entity.toneladas;
		this.licitacionId = entity.licitacionId;
		this.oportunidadId = entity.oportunidadId;
		this.last_modification_time=entity.last_modification_time;
		this.motivo=entity.motivo;
	}
}


export interface AjusteDto extends Ajuste { };
