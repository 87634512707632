import { ReactNode, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../../shared/components/Buttons/CustomButton";

interface Props {
	open:boolean,
    children:ReactNode
    handleCloseModal:()=>void
}


const DirtyModal: React.FC<Props> = (
    {
        open,
        children,
        handleCloseModal
    }:Props
)=>
{
    const [openDirty, setOpenDirty]=useState<boolean>(open);
    const {t} = useTranslation<['main']>(['main']);

return  (
    <Modal
    className="filters-modal modal-l"
    show={openDirty}
    onHide={() => {setOpenDirty(false);handleCloseModal();}}>
    <ModalHeader>
        <ModalTitle>
            {t("modalDatosSinguardarTitle")}
        </ModalTitle>
    </ModalHeader>
    <ModalTitle className="modal-title--subtitle">
    </ModalTitle>
    <ModalBody>
        {t("modalDatosSinguardarBody")}
        <div className="bottom-buttons">
            <CustomButton
                key={`removeCancelModal`}
                title={t('cancelar')}
                className={'btn-primary'}
                onButtonClick={() => { setOpenDirty(false);handleCloseModal();}}
                text={t('cancelar')}
            />
            {children}
        </div>
    </ModalBody>
</Modal>
)
}

export default DirtyModal;