import { BaseRepository } from "@pavabits/components";
import Contratista, { ContratistaDto, ContratistaFilter } from "../../domain/model/Contratista";
import ContratistaRepository from "../../domain/ContratistaRepository";
import ContratistaRequestListBySearch from "../ws/Contratista.RequestListBySearch";

export default class ContratistaApiRepository extends BaseRepository<
ContratistaDto[],
Contratista[]
> implements ContratistaRepository {
	searchContratista(data: ContratistaFilter): Promise<Contratista[]> {
		return new Promise((resolve, reject) => {
			new ContratistaRequestListBySearch(data, this.authToken)
			.request()
			.then((response) => {
				if (response.success) {
					resolve(this.transformDtoIntoModel(response.data.items));
				}
				else {
					reject(`Error ${response.errorCode}: ${response.errorMessage}`);
				}
			})
			.catch((error) => {
				reject(error);
			})
		})
	}

	protected transformDtoIntoModel(dto: ContratistaDto[]): Contratista[] {
		if (dto && dto.length) {
			return dto.map(item => {
				return new Contratista(
					item.id.toString(),
					item.descripcion,
					item.asfaltero
				)
			})
		}
		return []
	}
}