import React, { createContext, useContext, useState } from 'react';

interface LoadingContextProps {
    isLoading: boolean;
    setLoading: (isLoading: boolean) => void;
}

const LoadingContext = createContext<LoadingContextProps>({} as LoadingContextProps);

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider: React.FC = ({ children }) => {
    const [isLoading, setLoading] = useState<boolean>(false);

    return (
        <LoadingContext.Provider value={{isLoading, setLoading}}>
{children}
        </LoadingContext.Provider>
    )
}