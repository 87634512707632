import { useCallback, useContext, useEffect } from "react"
import OportunidadesRepository from "../domain/OportunidadesRepository"
import OportunidadesApiRepository from "../infraestructure/api/Oportunidades.ApiRepository"
import Oportunidad, { OportunidadFilter } from "../domain/model/Oportunidad"
import { usePsQuery } from "@pavabits/components"
import BaseReponse from "../../../domain/BaseResponse"
import { BaseFilter } from "../../../domain/BaseFilter"
import { AuthContext } from "../../Login/AuthContextProvider"
import { useLoading } from "../../../context/Loading.Context"
import { GlobalContext, GlobalContextType } from "../../../context/Global.Context"

function useOportunidadesPost(filter: BaseFilter<OportunidadFilter>): BaseReponse<Oportunidad[]> {

	const {getToken} = useContext(AuthContext);
	const {setLoading} = useLoading();
	const { globalState, updateGlobalState } = useContext(GlobalContext) as GlobalContextType;
	
	const fetchOportunidadesByFilter = useCallback(() => {
		const repo: OportunidadesRepository = new OportunidadesApiRepository(getToken());
		filter.sortingCriteria= filter.sortingCriteria == "" ? "fechaRegistro DESC" : filter.sortingCriteria;
		setLoading(true);
		return repo.fetchOportunidadesByFilter(filter).finally(() => {setLoading(false)});
	}, [filter]);

	const { data } = usePsQuery(fetchOportunidadesByFilter);

	useEffect(() => {
		updateGlobalState("oportunidades", {
			pagination: {
				...globalState.oportunidades?.pagination, 
				currentPage: data?.currentPage,
				totalCount: data?.totalCount,
				maxResultCount: filter?.maxResultCount,
				skipCount: filter?.skipCount
			}})
			}, [data])

	return {
		currentPage: data?.currentPage ?? 0,
		items: data?.items ?? [],
		totalCount: data?.totalCount ?? 0
	}
}

export default useOportunidadesPost;