import ApiRequest from "../../../../domain/ApiRequest";
import { BaseFilter } from "../../../../domain/BaseFilter";
import BaseResponse from "../../../../domain/BaseResponse";
import { SeguimientoHistoricoClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
// import { ClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { SeguimientoHistoricoClienteDto, SeguimientoHistoricoClienteFilter } from "../../domain/model/SeguimientoHistoricoCliente";

export default class SeguimientoHistoricoClienteRequestConFiltro extends ApiRequest<
BaseFilter<SeguimientoHistoricoClienteFilter>,
BaseResponse<any[]>>
{
	constructor(data: any, token: string) {
		super(
			"POST",
			SeguimientoHistoricoClienteEndpoints.CON_FILTRO,
			{ Authorization: token },
			data,
		)
	}
}
