import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";
import BaseResponse from "../../../../domain/BaseResponse";
import LibroEstandarRepository from "../../domain/LibroEstandarRepository";
import LibroEstandar, { LibroEstandarReadDto } from "../../domain/model/LibroEstandar";
import LibroEstandarAutoGenerar from "../ws/LibroEstandar.AutoGenerar";
import LibroEstandarGetAllByFilter from "../ws/LibroEstandar.GetAll";
import LibroEstandarRequestAdd from "../ws/LibroEstandar.Request.Add";
import LibroEstandarRequestDelete from "../ws/LibroEstandar.Request.Delete";
import LibroEstandarRequestUpdate from "../ws/LibroEstandar.Request.Update";
import LibroEstandarTraspasar from "../ws/LibroEstandar.Traspasar";


export default class LibroEstandarApiRepository
	extends ApiRepositoryBase<
		LibroEstandarReadDto,
		LibroEstandar
	> implements LibroEstandarRepository {

	async add(data: LibroEstandar): Promise<LibroEstandar> {
		const res = await new LibroEstandarRequestAdd(data, this.authToken).executeAsPromise();
		return res;
	}

	async update(data: LibroEstandar): Promise<LibroEstandar> {
		return await new LibroEstandarRequestUpdate(data, this.authToken).executeAsPromise();
	}

	async delete(id: string): Promise<boolean> {
		await new  LibroEstandarRequestDelete(id, this.authToken).executeAsPromise();
		return true;
	}

	async getAllByFilter(id: string): Promise<LibroEstandar[]> {
		const res = await new LibroEstandarGetAllByFilter(id, this.authToken).executeAsPromise();
		return res?.items;
	}
	async conFiltro(id: string): Promise<LibroEstandar[]> {
		const res = await new LibroEstandarGetAllByFilter(id, this.authToken).executeAsPromise();
		return res?.items;
	}

	async autoGenerarLibroEstandar(id: string): Promise<LibroEstandar> {
		return await new LibroEstandarAutoGenerar(id, this.authToken).executeAsPromise();
		
	}

	
	async traspasarLibroEstandar(id: string): Promise<LibroEstandar> {
		return await new LibroEstandarTraspasar(id, this.authToken).executeAsPromise();
		
	}

	buildModelFromItems(item: LibroEstandarReadDto): LibroEstandar {
		return new LibroEstandar(item);
	}

	protected transformDtoIntoModel(dto: BaseResponse<LibroEstandarReadDto>): BaseResponse<LibroEstandar> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}
}