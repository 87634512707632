import ApiRequest from "../../../../domain/ApiRequest";
import ClonarOfertas from "../../../Ofertas/domain/model/ClonarOfertas";
import { PresupuestoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class PresupuestBorrador extends ApiRequest
<ClonarOfertas, 
string
>
{
	constructor(data: ClonarOfertas, token: string) {
		super(
			"POST",
			PresupuestoApiEndpoints.BORRADORCONTRATADO,
			{  Authorization: `${token}` },
			data,
		)
	}
}