import SelectableItem, { SelectableItemDto } from "../../components/ui/atoms/SuggestionInput/model/SuggestionInput.Model";
import { BaseFilter } from "../BaseFilter";

export type DelegacionesFilter = BaseFilter<Delegacion>;

export interface DelegacionDto extends SelectableItemDto { };

export default class Delegacion extends SelectableItem { };


export interface DelegacionDetalleDto extends DelegacionEntity { };


interface DelegacionEntity {
    id: string,
    descripcion: string,
    direccion: string
    poblacion: string
    codigoPostal: string
    provincia: string
    telefono: string
    email: string
    cif: string
}


export class DelegacionDetalle implements DelegacionEntity {

    id: string;
    descripcion: string;
    direccion: string;
    poblacion: string;
    codigoPostal: string;
    provincia: string;
    telefono: string;
    email: string;
    cif: string;


    constructor(entity: DelegacionEntity) {

        this.id = entity.id;
        this.descripcion = entity.descripcion
        this.direccion = entity.direccion;
        this.poblacion = entity.poblacion;
        this.codigoPostal = entity.codigoPostal;
        this.provincia = entity.provincia;
        this.telefono = entity.telefono;
        this.email = entity.email;
        this.cif = entity.cif;
    }
    
}
