import { FC } from "react";
import OportunidadesProvider from "./context/Oportunidades.Provider";
import OportunidadesGrid from "./views/objects/Oportunidades.Grid";
import TableScreen from "../../components/common/TableScreen/Table.Screen";
import OportunidadesFilterSection from "./views/objects/Oportunidades.FilterSection";

const OportunidadesAsfalto: FC<{}> = () => {


	return (
		<OportunidadesProvider>
    	<div className='grid-view-container'>
			<TableScreen
				title="Oportunidades"
				filterSection={(
					<OportunidadesFilterSection />
				)}
			>
			</TableScreen>
			<OportunidadesGrid />
			</div>
		</OportunidadesProvider>
	)
}

export default OportunidadesAsfalto;