import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { ContactoClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { ContactoClienteDto } from "../../domain/model/ContactosCliente";

export default class ContactoClientesRequestPostClientes extends ApiRequest<{}, BaseResponse<ContactoClienteDto[]>>
{
	constructor(id: number, delegaciones: number[], firmante : boolean, token: string) {
		super(
			"POST",
			ContactoClienteEndpoints.CON_FILTRO,
			{ Authorization: token },
			{ "filter": {
				"clienteId": id,
				"delegacionesId": delegaciones,
				"firmantes" : firmante
			  },
			  "sortingCriteria" : "delegacionId DESC"
			}
		)
	}
}