import React, { useContext, useMemo } from 'react'
import { debounce } from "lodash";
import FormFieldGroup from '../../../../components/ui/objects/FormFieldGroup/FormField.Group'
import { CodigoLicitacionFieldContext } from '../../context/FilterFields.Context';

interface CodigoLicitacionInputProps {
	idTest?:string
}

const CodigoLicitacionInput : React.FC<CodigoLicitacionInputProps> = ({idTest}) => {
  
    const field = useContext(CodigoLicitacionFieldContext);

    const debouncedSearch = useMemo(() => { 
		
		const baseTag = {
			label: "Código Licitación",
			onClear: () => {},
		}

		function handleClear() {
			field.onClear();
			field.onFilter({ ...baseTag, text: undefined });
		}

		const handleFilterChange = (value: string) => {
			field.onSearch(value); 
			field.onFilter({...baseTag, text: value, onClear: handleClear})
		};

		return debounce((value: string) => handleFilterChange(value), 2000)
	}, [field]);

    const handleInputChange = (e: any) => {
		const value = e.target.value;
		debouncedSearch(value);
	}

  return (
    <FormFieldGroup>
		<div className="input-container">
    <label> Código Licitación</label>
    <input className="op-input form-control" 
           placeholder=""
           onChange={handleInputChange}
		   data-cy={idTest}
    >
    </input>
	</div>
</FormFieldGroup>
  )
}

export default CodigoLicitacionInput