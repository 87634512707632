import React from 'react'
import "./CustomTextInput.scss";

export interface CustomTextInputProps {
  name?: string;
  label?: string;
  value?: any;
  onChange?: any;
  required?: boolean;
  resizable?: boolean;
  disabled?: boolean;
  error?: string;
  type?: string;
  idTest?:string
  onKeyDown?: (value: any) => void;
  min?: number;
}

const CustomTextInput = (props: CustomTextInputProps) => {
  return (
      <div className='input-container'>
        <label htmlFor={props.name}>{props.required ? props.label + " *" : props.label}</label>
        {!props.resizable ?
          <input data-cy={props.idTest == undefined ? (props.label?.substring(0,6)) : props.idTest}
            disabled={props.disabled}
            type={props.type ? props.type : 'text'}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            required={props.required}
            onKeyDown={props.onKeyDown} 
            min={props.min}/> :
          <textarea data-cy={props.label?.substring(0, 8)}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            required={props.required}
          />
        }
        {
          props.error && <span className="custom-input__label--error">{props.error}</span>
        }
      </div>
  )
}

export default CustomTextInput