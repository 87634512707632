interface UnidadObraElementoEntity {
    delegacionId: number;
    unidadObraElementoTipoId: number;
    tipoUnidad: string | null;
    descripcionUnidadMedida: string | null;
    unidadMedidaId: number | null;
    nombre: string;
    descripcion: string;
    tarifa: number;
}

export default class UnidadObraElemento implements UnidadObraElementoEntity {
    delegacionId: number;
    unidadObraElementoTipoId: number;
    tipoUnidad: string | null;
    descripcionUnidadMedida: string | null;
    unidadMedidaId: number | null;
    nombre: string;
    descripcion: string;
    tarifa: number;

    constructor(entity: UnidadObraElementoEntity) {
        this.delegacionId = entity.delegacionId;
        this.unidadObraElementoTipoId = entity.unidadObraElementoTipoId;
        this.tipoUnidad = entity.tipoUnidad;
        this.descripcionUnidadMedida = entity.descripcionUnidadMedida;
        this.unidadMedidaId = entity.unidadMedidaId;
        this.nombre = entity.nombre;
        this.descripcion = entity.descripcion;
        this.tarifa = entity.tarifa;
	}

	public static toArray(entities: UnidadObraElementoEntity[]): UnidadObraElementoEntity[] {
		return entities?.map(entity => { return new UnidadObraElemento(entity) });
	}
}

export interface UnidadObraElementoReadDto extends UnidadObraElemento {
	id: string;
    errors?: string[]
};