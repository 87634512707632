import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";
import { BaseFilter } from "../../../../domain/BaseFilter";
import BaseResponse from "../../../../domain/BaseResponse";
import ClienteRepository from "../../domain/ClienteRepository";
import Cliente, { ClienteCreationDto, ClienteDto, ClienteFilter, ClienteUpdateDto } from "../../domain/model/Cliente";
import ClienteRequestCreate from "../ws/Cliente.Request.Create";
import ClienteRequestGetById from "../ws/Cliente.Request.GetById";
import ClienteRequestListByCif from "../ws/Cliente.Request.ListByCif";
import ClienteRequestListByFilter from "../ws/Cliente.Request.ListByFilter";
import ClienteRequestUpdate from "../ws/Cliente.Request.Update";

export default class ClienteApiRepository
	extends ApiRepositoryBase<
		ClienteDto,
		Cliente
	> implements ClienteRepository {

	async update(data: ClienteUpdateDto): Promise<any> {
		return new ClienteRequestUpdate(data, this.authToken).executeAsPromise();
	}

	async getById(id: number): Promise<Cliente> {
		return await new ClienteRequestGetById(id, this.authToken).executeAsPromise()
	}

	addCliente(data: ClienteCreationDto): Promise<any> {
		return new ClienteRequestCreate(data, this.authToken).executeAsPromise();
	}

	async fetchClienteByFilter(data: BaseFilter<ClienteFilter>): Promise<BaseResponse<Cliente[]>> {
		const res = await new ClienteRequestListByFilter(data, this.authToken).executeAsPromise();
		return (
			{
				...res,
				items: Cliente.toArray(res.items)
			}
		);
	}
	async fetchClienteByCif(data: string): Promise<Cliente | undefined> {
		const res = await new ClienteRequestListByCif(data, this.authToken).executeAsPromise();
		if (res && res.items.length > 0) {
			return this.buildModelFromItems(res.items[0]);
		} else {
			return undefined;
		}
		
	}



	protected mapResponseItems(dto: BaseResponse<ClienteDto[]>): BaseResponse<Cliente[]> {
		return (
			{
				items: dto.items.map(item => this.buildModelFromItems(item)),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}

	buildModelFromItems(item: ClienteDto): Cliente {
		return new Cliente(item);
	}

	protected transformDtoIntoModel(dto: BaseResponse<ClienteDto>): BaseResponse<Cliente> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}


}