import { BaseRepository } from "@pavabits/components";
import Provincia, { ProvinciaDto, ProvinciaFilter } from "../../domain/model/Provincia";
import ProvinciaRepository from "../../domain/ProvinciaRepository";
import ProvinciaRequestListBySearch from "../ws/Provincia.RequestListBySearch";


export default class ProvinciaApiRepository extends BaseRepository<
ProvinciaDto[],
Provincia[]
> implements ProvinciaRepository {
	searchProvincias(data: ProvinciaFilter): Promise<Provincia[]> {
		return new Promise((resolve, reject) => {
			return new ProvinciaRequestListBySearch(data, this.authToken)
			.request()
			.then((response) => {
				if (response.success) {
					resolve(this.transformDtoIntoModel(response.data.items));
				} else {
					reject(`Error ${response.errorCode} : ${response.errorMessage}`);
				}
			})
			.catch((error) => {
				reject(error)
			})
		})
	}
	protected transformDtoIntoModel(dto: ProvinciaDto[]): Provincia[] {
		if (dto && dto.length) {
			return dto.map(item => {
				return new Provincia(
					item.id,
					item.descripcion
				)
			})
		}
		return []
	}
}