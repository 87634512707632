import { CustomButton } from "../../../../shared/components/Buttons/CustomButton";
import FormFieldGroup, { FormFieldGroupType } from "../../objects/FormFieldGroup/FormField.Group";

type FormFieldProps<T> = {
	label?: string
	type?: FormFieldGroupType
	error?: string
	required?: boolean
	disabled?: boolean
	component: React.FC<T>
	size?: string
	createNewItem?: boolean
	titleNewItem?: string
	idTest?:string
	handleNewItem?: () => void
	handleClick?: () => void
} & T

const FormField = <T extends {}>(props: FormFieldProps<T>) => {

	const {
		label,
		type,
		error,
		required,
		disabled,
		component,
		size,
		createNewItem,
		titleNewItem,
		handleNewItem,
		handleClick,
		idTest
	} = props;

	return (
		<div onClick={()=>{ if(typeof handleClick == 'function')handleClick()}} data-cy={label} className={`input-container ${disabled ? 'input-container--disabled' : size ? 'form-input-litle' : ''}`}>
			<FormFieldGroup
				className={createNewItem ? "width-with-add-btn" : ""}
				type={type ?? "input"}
				error={error !== undefined}
				
			>
				<label>
					<span>{label}{(required && label) && ' *'}</span>
				</label>
				
				{createNewItem && <CustomButton
					id={"add-btn"}
					icon="add"
					title={titleNewItem}
					className="add-btn"
					btnStyle="mini"
					onButtonClick={handleNewItem}
					type="button"
				/>}
					{component(props)}
			</FormFieldGroup>
			<label className="error">{error && <span>{error}</span>}</label>
		</div>
	)
}

export default FormField;