import React, { useState } from 'react'
// import './Sidebar.scss'
import { Link } from 'react-router-dom';
import { Icon } from '@pavabits/components';

const SidebarDropdownItem = (props: any) => {
const [showOptions, setShowOptions] = useState(false);
    const toggleDropdown = () => {
        setShowOptions(!showOptions)
    }

    const baseClass = "nav-item";

	const classes = [baseClass, `${props.selected ? baseClass + '--selected' : ''}`, props.className].join(" ").trimEnd();

  const optionsBaseClass = "nav-option";

  return ( 
    <>
    <li className={classes} onClick={toggleDropdown}>
      <div className='nav-item-content'>
       {props.icon && <Icon icon={props.icon!} />}
        {props.label && (
        <div>
					{props.label}
				</div>
        )}
        <div className='expand-icon'>
        {showOptions ? <Icon icon="expand_less" /> :<Icon icon="expand_more" />}
        </div>
      </div>
    </li>
    {showOptions && <ul className=''>{props.options.map((item: any, index: number) => {return (
      <div key={index}>
        <Link className={[optionsBaseClass, `${item.optionSelected ? optionsBaseClass + '--selected' : ''}`, props.className].join(" ").trimEnd()} to={item.navigateTo} onClick={((e) => e.stopPropagation())}>
            <li key={item.id} data-cy={item.optionLabel}>{item.optionLabel}
            </li>
            </Link>
            </div>) })
    }
            </ul>}
    </>
  )
}

export default SidebarDropdownItem