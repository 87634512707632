
export const formatDDMMYYYY=(date: string | Date): Date => {
	if (date?.toString().includes('/')) {
		var [day, month, year] = date?.toString().split('/');
		return new Date(+year,+month-1,+day);
	} else if (date?.toString().includes('-')) {
		var [year, month, day] = date?.toString().split('-');
		return new Date(+year,+month-1,+day);
	} else {
		return new Date(); 
	}
	// los meses van de 0 a 1 (hay que restarle uno)
	
}

export const formatYYYYMMDD=(date: string | Date): string => {
	if (date?.toString().includes('/')) {
		var [day, month, year] = date?.toString().split('/');
		return year+ "-" +month+ "-" +day;
	} else if (date?.toString().includes('-')) {
		var [year, month, day] = date?.toString().split('-');
		return year+ "-" +month+ "-" +day;
	} else {
		return ""; 
	}
	// los meses van de 0 a 1 (hay que restarle uno)
	
}

export const formatDate2String=(date: Date | undefined): string => {
	if (date === undefined)
		return "";

	return new Date(date.toString() ?? "").toLocaleDateString('es-es', { month: '2-digit', day: '2-digit', year: 'numeric', hour12: false });
}


export const formatUsDate2String=(date: Date | undefined): string => {
	if (date === undefined)
		return "";

	return date.toISOString().split('T')[0];
}

const regValidDate = /^(0[1-9]|[12][0-9]|[3][0-1]|[01])[\/ \/.](0[1-9]|1[012])[\/ \/.](19|20)\d\d$/;
export const fechaValida=(fecha: string)=> {
	return fecha?.toString().match(regValidDate);
}


export const formatDate2input=(fecha: string):string=> {
	if (fecha===undefined || fecha==='' || fecha.split('T')[0]=="0001-01-01")
		return '';

	return fecha.split('T')[0];
}

export const quitarFechasVacias=(dateStr:string)=>{
	return noAplicaDate(AS400nullDate(dateStr));
}

export const noAplicaDate=(dateStr:string)=>{
	return dateStr==="1/1/1"?"No aplica":dateStr;
}

export const AS400nullDate=(dateStr:string)=>{
	return (dateStr==="1/1/1940" || dateStr==="01/01/1901")?"":dateStr;
}