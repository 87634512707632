
import ApiRequest from "../../../../domain/ApiRequest";
import { EstudioDetalleAvanzadoElementoDto } from "../../../Estudios/domain/model/EstudioDetalleAvanzadoElemento";
import { EstudioDetalleAvanzadoElementoEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class EstudioDetalleAvanzadoElementoRequestDelete extends ApiRequest<
{},
EstudioDetalleAvanzadoElementoDto
>
{
	constructor(id: string, token: string) {
		super(
			"DELETE",
			EstudioDetalleAvanzadoElementoEndpoints.DELETE+id,
			{ Authorization: `${token}`},
			{},
		)
	}
}