import BaseResponse from "../../../../domain/BaseResponse";
import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";
import EstudioDetalleAvanzadoEquipo, { EstudioDetalleAvanzadoEquipoDto } from "../../../Estudios/domain/model/EstudioDetalleAvanzadoEquipo";
import EstudioDetalleAvanzadoEquipoRepository from "../../domain/EstudioDetalleAvanzadoEquipoRepository";
import EstudioDetalleAvanzadoEquipoRequestDelete from "../ws/EstudioDetalleAvanzadoEquipo.Request.Delete";
import EstudioDetalleAvanzadoElemento, { EstudioDetalleAvanzadoElementoDto } from "../../../Estudios/domain/model/EstudioDetalleAvanzadoElemento";
import EstudioDetalleAvanzadoElementoRepository from "../../domain/EstudioDetalleAvanzadoElementoRepository";
import EstudioDetalleAvanzadoElementoRequestDelete from "../ws/EstudioDetalleAvanzadoElemento.Request.Delete";


export default class EstudioDetalleAvanzadoElementoApiRepository
	extends ApiRepositoryBase<
		EstudioDetalleAvanzadoElementoDto,
		EstudioDetalleAvanzadoElemento
	> implements  EstudioDetalleAvanzadoElementoRepository {

	
	
	async delete(id: string): Promise<EstudioDetalleAvanzadoElementoDto> {
		return await new EstudioDetalleAvanzadoElementoRequestDelete(id, this.authToken).executeAsPromise();
		
	}

	buildModelFromItems(item:  EstudioDetalleAvanzadoElementoDto):  EstudioDetalleAvanzadoElemento {
		return new  EstudioDetalleAvanzadoElemento(item);
	}

	protected transformDtoIntoModel(dto: BaseResponse< EstudioDetalleAvanzadoElementoDto>): BaseResponse<EstudioDetalleAvanzadoElemento> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}
}