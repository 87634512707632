export class Routes {
	public static ROOT = '/';
	public static OPORTUNIDADES = '/oportunidades';
	public static CLIENTES = '/clientes';
	public static SEGUIMIENTO_CLIENTES = '/seguimientoclientes';
	public static SEGUIMIENTO_CLIENTES_GRABACION = '/seguimientoclientes/grabacion';
	public static SEGUIMIENTO_CLIENTES_EDICION = '/seguimientoclientes/edicion/:id';
	public static CLIENTES_GRABACION = '/clientes/grabacion';
	public static CLIENTES_EDICION = '/clientes/:id';
	public static CONTACTOS = '/contactos';
	public static OFERTAS = '/ofertas';
	public static OFERTAS_GRABACION = '/ofertas/grabacion';
	public static OFERTAS_EDICION = '/ofertas/edicion/:id';
	public static UNIDADES = '/mantenimiento/unidades';
	public static UNIDADES_GRABACION = '/mantenimiento/unidades/grabacion';
	public static UNIDADES_EDICION = '/mantenimiento/unidades/edicion/:id';
	public static CLAUSULAS = '/mantenimiento/clausulas';
	public static CLAUSULAS_GRABACION = '/mantenimiento/clausulas/grabacion';
	public static CLAUSULAS_EDICION = '/mantenimiento/clausulas/edicion/:id';
	public static ELEMENTOS_GRABACION = '/mantenimiento/elementos/grabacion';
	public static ELEMENTOS_EDICION = '/mantenimiento/elementos/edicion/:id';
	public static EQUIPOS = '/mantenimiento/equipos';
	public static EQUIPOS_GRABACION = '/mantenimiento/equipos/grabacion';
	public static EQUIPOS_EDICION = '/mantenimiento/equipos/edicion/:id';
}