import { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useState } from 'react'
import FormTemplate from '../../components/common/FormTemplate/FormTemplate';
import CustomTextInput from '../../components/CustomTextInput';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import ClausulasRepository from './domain/ClausulasRepository';
import ClausulasApiRepository from './infrastructure/api/ClausulasApiRepository';
import { AuthContext } from '../Login/AuthContextProvider';
import { ClausulaUpdateDto } from './domain/model/Clausula';
import DelegacionesRepository from '../../domain/DelegacionesRepository';
import DelegacionesApiRepository from '../../infraestructure/api/Delegaciones.ApiRepository';
import { DelegacionDto } from '../../domain/model/Delegacion';
import { useLoading } from '../../context/Loading.Context';
import FormField from '../../components/ui/molecules/Form-field/FormField';
import Dropdown, { DropdownProps } from '../../components/ui/atoms/Dropdown/Dropdown';
import { VentanaPermiso } from '../Login/domain/model/VentanaPermiso';
import { COD_DELEGACIONES_SIN_TODAS, USER_ROLES } from '../../assets/constants/Constantes';

interface Props {
  permisoVentana: VentanaPermiso
}

const ClausulasEdicion = ({ permisoVentana }: Props) => {
  const location = useLocation();
  const url = location.pathname.split("/")
  const id = url[url.length - 1];
  const { getToken, getDelegacion, hasRole } = useContext(AuthContext);
  const [permisoModificacion, setPermisoModificacion] = useState<boolean>(permisoVentana.permisoModificacion);
  const [delegaciones, setDelegaciones] = useState<DelegacionDto[]>([]);
  const [clausula, setClausula] = useState<ClausulaUpdateDto>({
    id: "",
    nombre: "",
    descripcion: "",
    delegacionId: -1,
    delegacionNombre: "",
    lastModificationTime: "",
    lastModificationUsername: "",
    orden: null,
    negrita: false
  })
  const formId = "clausulas-edit-form";
  const { setLoading } = useLoading();

  useEffect(() => {
    fetchDelegaciones()
  }, [getToken()])

  const fetchClausula = useCallback((id: string) => {
    const repo: ClausulasRepository = new ClausulasApiRepository(getToken());
    setLoading(true);
    return repo.getById(id)
      .then((data) => {
        setClausula({ ...data, delegacionId: data.delegacionId ? data.delegacionId : -1 });
        // Si el usuario no es Admin puede ver datos de todas las delegaciones pero sólo crear y editar datos de su delegación
        setPermisoModificacion(permisoModificacion && (hasRole(USER_ROLES.ADMIN) || data.delegacionId === +getDelegacion()?.id));
      })
      .catch(message => toast.error(message))
      .finally(() => { setLoading(false); });
  }, [getToken()]);

  useEffect(() => { // Side Effect
    fetchClausula(id);
  }
    , [id]);

  const fetchDelegaciones = useCallback(() => {
    const repo: DelegacionesRepository = new DelegacionesApiRepository(getToken());
    setLoading(true);
    return repo.fetchDelegacionesDto({ filter: { ids: COD_DELEGACIONES_SIN_TODAS } }).then((data) => {
      const delegacionesUpd = data;
      delegacionesUpd.push({ descripcion: "TODAS", id: "-1" })
      setDelegaciones(delegacionesUpd);
    })
      .catch(message => toast.error(message))
      .finally(() => { setLoading(false); });
  }, [getToken()]);

  const editDelegacion = (delegacion: DelegacionDto) => {
    const delegacionObj: DelegacionDto | undefined = delegaciones.find(((item: DelegacionDto) => item.id === delegacion.id));
    const clausulaUpd = { ...delegacionObj, delegacionId: delegacionObj?.id ? +delegacionObj.id : null };
    setClausula(prev => ({ ...prev, delegacionId: clausulaUpd.delegacionId }))
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const repo: ClausulasRepository = new ClausulasApiRepository(getToken());

    try {
      const clausulaUpd: ClausulaUpdateDto = { ...clausula, delegacionId: null };

      if (clausula?.delegacionId != -1) {
        clausulaUpd.delegacionId = clausula.delegacionId;
      }

      repo.update(clausulaUpd).then((a) => {
        toast.success("Cláusula actualizada con éxito");
        setClausula({ ...clausulaUpd, delegacionId: a.delegacionId ?? -1, lastModificationTime: a.lastModificationTime })
      })
    } catch (err) {
      console.error(err)
      toast.error("Error al actualizar la Cláusula");
    }
  }

  const handleEditClausula = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setClausula(prev => ({ ...prev, [name]: value }))
  }

  const setNegrita = async () => {
    setClausula({ ...clausula, negrita: !clausula.negrita });
  }

  return (
    <div className='add-edit-view-container'>
      <div className='add-edit-header'>
        <h4>{permisoModificacion ? "Editar " : ""}Cláusula</h4>
      </div>
      <div className="add-edit-body">
        <FormTemplate
          formId={formId}
          onSubmit={handleSubmit}
          readOnly={!permisoModificacion}
        >
          <div className="row-of-two">

            {delegaciones &&
              <FormField<DropdownProps>
                label="Delegación"
                options={delegaciones.filter(x => hasRole(USER_ROLES.ADMIN) || +x.id === clausula.delegacionId).map(
                  (element) => {
                    return {
                      text: element.descripcion,
                      id: element.id,
                      selected: clausula.delegacionId == +element.id ? true : false,
                      onClick: () => { editDelegacion(element); }
                    }
                  })}
                required={true}
                disabled={false}
                singleSelection={true}
                component={Dropdown}
              />
            }

            <CustomTextInput
              label="Nombre"
              name="nombre"
              value={clausula.nombre}
              onChange={handleEditClausula}
              required />
          </div>

          <div className='row-of-three' style={{ marginTop: '18px' }}>
            <CustomTextInput
              label="Orden"
              name="orden"
              value={clausula.orden}
              onChange={handleEditClausula}
              type='number'
              required
              min={1}
            />
            <div className="flex-horizontal">
              <label>Negrita</label>
              <input
                style={{ cursor: 'pointer' }}
                checked={clausula.negrita!}
                name="negrita"
                onChange={setNegrita}
                type="checkbox"
                data-cy="NegritaClausula"
              />
            </div>
          </div>

          <div className='single-row'>
            <CustomTextInput
              label="Cláusula"
              name="descripcion"
              value={clausula.descripcion}
              onChange={handleEditClausula}
              resizable
              required />
          </div>

        </FormTemplate>
      </div>
    </div>
  )
}

export default ClausulasEdicion