import ApiRequest from "../../../../domain/ApiRequest";
import { BaseFilter } from "../../../../domain/BaseFilter";
import { OfertasEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { OfertasFilter } from "../../domain/model/Ofertas";


export default class OfertasRequestDownloadExcel extends ApiRequest<
BaseFilter<OfertasFilter>,
string>
{
	constructor(data: BaseFilter<OfertasFilter>, token: string) {
		super(
			"POST",
			OfertasEndpoints.DOWNLOAD_EXCEL,
			{ Authorization: token },
			data,
		)
	}
}