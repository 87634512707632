import { useContext } from "react"
import DateSelector from "../../../../components/ui/molecules/Date-selector/DateSelector"
import FormFieldGroup from "../../../../components/ui/objects/FormFieldGroup/FormField.Group"
import { FechaRegistroContext } from "../../context/FilterFields.Context"

const FechaRegistroDateSelector: React.FC<{ idTest?:string }> = ( { idTest } ) => {

	const { onDateSelect } = useContext(FechaRegistroContext);

	return (
		<div className="input-container">
		<FormFieldGroup>
			<label>Fecha grabación</label>			
			<DateSelector
			idTest={idTest}
				onDateSelect={onDateSelect}
			/>
			
		</FormFieldGroup>
		</div>
	)
}

export default FechaRegistroDateSelector;