import { ReactNode } from "react";
import { SortingField } from "../../domain/BaseFilter";
import SortingArrows from "../ui/molecules/Sorting/SortingArrows";
import GridRow, { RowAction } from "./components/GridRow";
import TablePrintableObject from "./types/TablePrintableObject";
import { ColumnTraits } from "../../hooks/useColumnasGrid";
import "./Table.Component.scss";
import { formatNumber } from "../../utils/NumberUtil";

export interface TableComponentProps<T> {
	columns: { [key: string]: ColumnTraits }
	data: T[]
	sumaToneladas?: number
	className?: string
	headerBtn?: ReactNode
	cellsChilden?: {[Key in keyof T]: ReactNode}
	expandedSection?: ReactNode
	rowAction?: RowAction
	sorting?: SortingField[]
	onSort?: (sortState: SortingField[]) => void
}

const TableComponent = <T extends TablePrintableObject>(
	{
		columns,
		data,
		sumaToneladas,
		className,
		headerBtn,
		cellsChilden,
		expandedSection,
		rowAction,
		sorting,
		onSort
	}: TableComponentProps<T>
) => {

	const theadClass = className + "__thead";
	const trClass = className + "__thead__tr";
	const thClass = className + "__th";
	const tdClass = className + "__td";
	const tbodyClass = className + "__tbody";

	return (
		<div className="grid-container">
		<table className={["table", className].join(" ").trimEnd()}>
			<thead className={theadClass}>
				<tr className={trClass}>
					{/* {RowExpansion !== undefined && */<th className={thClass}></th>}
					{
						Object.keys(columns).map(key => {
							return (
							
								<th key={key} className={thClass}>
								
									<div className="header-cell">
										{
											//columns[key].columnName === "Fecha" ? "Fecha de grabación" : columns[key].columnName
											columns[key].columnName
										}
										{
											columns[key].sortable &&
											!!sorting && onSort &&
											<SortingArrows
												columnKey={key}
												sorted={sorting}
												onSort={onSort}
											/>
										}
									</div>
									{
									key==="toneladas"?
										<label>{ formatNumber(sumaToneladas!==undefined?sumaToneladas:0)}</label>
										:
										''
								}
								</th>
								
							)
						})
					}
					{!!headerBtn  &&
						<th className={thClass}>
							{headerBtn}
						</th>
					}
				</tr>
			</thead>
			<tbody key={"table-body"}>
			{/* <tbody key={"table-body"} className={tbodyClass}> */}
				{
					data.map((item, index) => {
						return (
							<GridRow
								key={index}
								item={item}
								sonIconos= {
									Object.keys(columns).reduce(function (ind: number[], el, i) {
									if (columns[el].type === 'ICON')
										ind.push(i)
									return ind;
								}, [])}
								// tdClass={tdClass}
								// trClass={trClass}
								cellsChildren={cellsChilden}
								expandedSection={expandedSection}
								rowAction={rowAction}
								idTest={index}
							/>
						)
					})
				}
			</tbody>
		</table>
		</div>
	)
}

export default TableComponent;