import BaseResponse from "../../../../domain/BaseResponse";
import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";

import EstadoOfertaRepository from "../../domain/EstadoOfertaRepository";
import EstadoOferta, { EstadoOfertaDto, EstadoOfertaFilter } from "../../domain/model/EstadoOferta";
import EstadoOfertaRequestGetAll from "../ws/EstadoOfertaRequestGetAll";
import { BaseFilter } from "../../../../domain/BaseFilter";
import EstadoOfertaRequestListByFilter from "../ws/EstadoOferta.Request.ListByFilter";


export default class EstadoOfertaApiRepository
	extends ApiRepositoryBase<
		EstadoOfertaDto,
		EstadoOferta
	> implements  EstadoOfertaRepository {



	async getAll(): Promise<EstadoOferta[]> {
		const res= await new EstadoOfertaRequestGetAll( this.authToken).executeAsPromise();
		return  EstadoOferta.toArray(res?.items);
	}

	async fetchEstadoOfertaByFilter(data: EstadoOfertaFilter): Promise<BaseResponse<EstadoOferta[]>> {
		const res = await new EstadoOfertaRequestListByFilter(data, this.authToken).executeAsPromise();
		return (
			{
				...res,
				items: EstadoOferta.toArray(res.items)
			}
		);
	}


	buildModelFromItems(item:  EstadoOfertaDto):  EstadoOferta {
		return new  EstadoOferta(item);
	}

	protected transformDtoIntoModel(dto: BaseResponse< EstadoOfertaDto>): BaseResponse<EstadoOferta> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}
}