import React, { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useState } from 'react'
import FormTemplate from '../../../components/common/FormTemplate/FormTemplate';
import TipoSeguimientoClienteRepository from '../../Clientes/domain/TipoSeguimientoClienteRepository';
import TipoSeguimientoClienteApiRepository from '../../Clientes/infraestructure/api/TipoSeguimientoCliente.ApiRepository.';
import TipoSeguimientoCliente from '../../Clientes/domain/model/TipoSeguimientoCliente';
import { AuthContext } from '../../Login/AuthContextProvider';
import { SelectableItem } from '@pavabits/components';
import { HistoryHandler } from '../../../context/History.Context';
import CustomDropdownInput from '../../../components/CustomDropdownInput';
import { formatDate2String, formatDDMMYYYY, formatUsDate2String, formatYYYYMMDD } from '../../../utils/DateUtil';
import CustomInput from '../../../shared/components/Inputs/CustomInput/CustomInput';
import ClienteRepository from '../../Oportunidades/domain/ClienteRepository';
import ClienteApiRepository from '../../Oportunidades/infraestructure/api/Cliente.ApiRepository';
import FormField from '../../../components/ui/molecules/Form-field/FormField';
import SingleSuggestionInputField, { SingleSuggestionInputFieldProps } from '../../../components/ui/atoms/SingleSuggestionInput/SingleSuggestionInput.Field';
import Dropdown, { DropdownProps } from '../../../components/ui/atoms/Dropdown/Dropdown';
import ContactosCliente from '../../Clientes/domain/model/ContactosCliente';
import ContactosRepository from '../../Clientes/domain/ContactosRepository';
import ContactosClientesApiRepository from '../../Clientes/infraestructure/api/ContactosClientes.ApiRepository';
import { Route, useHistory } from 'react-router-dom';
import SeguimientoHistoricoClienteRepository from '../domain/SeguimientoHistoricoClienteRepository';
import SeguimientoHistoricoClienteApiRepository from '../infrastructure/api/SeguimientoHistoricoClienteApiRepository';
import { toast } from 'react-toastify';
import { Routes } from '../../../router/routes/Routes';

const ClientesSeguimientoGrabacion = () => {
  const formId = "seguimiento-historico-clientes-form";
  const [optionsTipoSeguimientoCliente, setOptionsTipoSeguimientoCliente] = useState<TipoSeguimientoCliente[]>([]);
  const { getToken, username } = useContext(AuthContext);
  const tipoSeguimientoClienteSelected = { id: '0', descripcion: "Seleccionar" } as SelectableItem;
  const [clienteSearchValue, setClienteSearchValue] = useState("");
  const [contactos, setContactos] = useState<ContactosCliente[]>([])
  const {goToRoute} = useContext(HistoryHandler);
  const history = useHistory();
  
  const [newSeguimiento, setNewSeguimiento] = useState<any>({
    id: "",
    fechaSeguimiento: formatDate2String(formatDDMMYYYY(new Date())),
    observaciones: "",
    tipoSeguimientoClienteId: 1,
    estadoOfertaId: 1,
    clienteId: null,
    lastModificationTime: '',
    contactoId: "",
    ofertaId: "",
    seguimientoOfertaId: ''
  })

  useEffect(() => {
    fetchTipoSeguimiento()
  }, [])

  useEffect(() => {
    fetchContactos()
    return () => {
      setContactos([]);
    };
  }, [newSeguimiento.clienteId])

  const fetchTipoSeguimiento = useCallback(
    () => {
        const repo: TipoSeguimientoClienteRepository = new TipoSeguimientoClienteApiRepository(getToken());
        return repo.getAll()
        .then((resp: TipoSeguimientoCliente[]) => {
            setOptionsTipoSeguimientoCliente(resp);
        })
    }
    , [getToken, tipoSeguimientoClienteSelected]);

    const fetchContactos = useCallback((id?: string) => {
      const repo: ContactosRepository = new ContactosClientesApiRepository(getToken());
      return repo.getByClienteId(newSeguimiento.clienteId)
        .then((items) => {
          setContactos(items);
        });
    }
      , [getToken, newSeguimiento.clienteId]);

  const handleSelectTipo = (tipoSeguimiento: TipoSeguimientoCliente) => {
    const seguimientoObj: TipoSeguimientoCliente | undefined =  optionsTipoSeguimientoCliente.find(((item: any) => item.id === tipoSeguimiento.id));
    if (seguimientoObj) {
      setNewSeguimiento((prev: any) => ({...prev, tipoSeguimientoClienteId: seguimientoObj.id }))
    }
  }

  const searchCliente = useCallback((search: string) => {
    const repoCliente: ClienteRepository = new ClienteApiRepository(getToken());
    return repoCliente.searchCliente({ filter: { descripcion: search} })
  }, [getToken])

  const handleFechaChange = (newValue: string) => {
    setNewSeguimiento((prev: any) => ({...prev, fechaSeguimiento: newValue}))
  }

  const handlePropertyChange = (property: string, newValue: string | number) => {
    setNewSeguimiento((prev: any) => ({...prev, [property]: newValue, Usuario: username}));
}

const handleClienteChange = (newValue: string) => {
  setNewSeguimiento((prev: any) => ({...prev, clienteId: Number(newValue)}))
}

const saveSeguimiento = (data: any) => {
  const repoSave: SeguimientoHistoricoClienteRepository = new SeguimientoHistoricoClienteApiRepository(getToken());
  repoSave.add(data).then(() => {
    goToRoute(Routes.SEGUIMIENTO_CLIENTES)
    toast.success("Seguimiento de clientes añadido con éxito");
    //history.goBack();
  }).catch(err => {
    toast.error(err)
  })
};

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    saveSeguimiento({...newSeguimiento, fechaSeguimiento: formatYYYYMMDD(newSeguimiento.fechaSeguimiento)})
    setNewSeguimiento({
      id: "",
      fechaSeguimiento: formatDate2String(formatDDMMYYYY(new Date())),
      observaciones: "",
      tipoSeguimientoClienteId: 1,
      estadoOfertaId: 1,
      clienteId: null,
      lastModificationTime: '',
      contactoId: "",
      ofertaId: "",
      seguimientoOfertaId: ''
    })
  }
  
  return (
    <div className='add-edit-view-container'>
       <div className='add-edit-header'>
			 <h4>Nuevo Seguimiento de Clientes</h4>
			 </div>
       <div className="add-edit-body">
       <FormTemplate
        formId={formId}
        onSubmit={handleSubmit}
        >
       <div className="row-of-two">
        {optionsTipoSeguimientoCliente &&
          <FormField<DropdownProps>
            label="Tipo"
            options={optionsTipoSeguimientoCliente.filter(x => x.visible).map(
              (element) => {
                return {
                  text: element.descripcion,
                  id: element.id,
                  selected: newSeguimiento.tipoSeguimientoClienteId == +element.id ? true : false,
                  onClick: () => {handleSelectTipo(element)}
                }
              })}
            idTest='TipoClienteSeguimientoHistorico'
            required={true}
            disabled={false}
            singleSelection={true}
            component={Dropdown}
          />
        }

         <FormField<SingleSuggestionInputFieldProps>
							label="Cliente"
              required
							value={{ id: newSeguimiento.clienteId, 
                  text: clienteSearchValue}}
							onChange={handleClienteChange}
							searchCallback={(searchCliente)}
							component={SingleSuggestionInputField}
              idTest="NombreClienteSeguimientoHistorico"
						/>

        <FormField<DropdownProps>
          label="Contactos"
          options={contactos!.map(
            (element) => {
              return {
                text: element.personaContacto,
                id: element.id,
                selected: newSeguimiento.contactoId == element.id ? true : false,
                onClick: () => { handlePropertyChange("contactoId", element.id); }
              }
              })}
          idTest='ContactosClienteSeguimientoHistorico'
          disabled={newSeguimiento.clienteId === null}
          singleSelection
          component={Dropdown}
        />

         <CustomInput
            value={newSeguimiento.fechaSeguimiento}
            onChange={(e) => {handleFechaChange(e)}}
            formClassName={"secondary-input"}
            type={'date'}
            label="Fecha"
            idTest='FechaClienteSeguimientoHistorico'
          />

          <CustomInput
            value={newSeguimiento.observaciones}
             onChange={(e) => { handlePropertyChange('observaciones', e); }}
            label="Observaciones"
            idTest="ObservacionesClienteSeguimientoHistorico"
                                />
       </div>
       </FormTemplate>
       </div>
    </div>
  )
}

export default ClientesSeguimientoGrabacion