import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";
import ClausulaOfertaAdd from "../ws/ClausulaOferta.Request.Add";
import ClausulaOfertaDelete from "../ws/ClausulaOferta.Request.Delete";
import ClausulaOfertaUpdate from "../ws/ClausulaOferta.Request.Update";
import ClausulasOfertaRepository from "../../domain/ClausulasOfertaRepository";
import ClausulaOferta, { ClausulaOfertaCreateDto, ClausulaOfertaReadDto, ClausulaOfertaUpdateDto } from "../../domain/model/ClausulaOferta";
import BaseResponse from "../../../../domain/BaseResponse";
import ClausulaOfertaGetByOfertaId from "../ws/ClausulaOferta.Request.GetByOfertaId";

export default class ClausulasOfertaApiRepository
extends ApiRepositoryBase<ClausulaOfertaReadDto, ClausulaOferta> implements ClausulasOfertaRepository {
	getById(id: string): Promise<ClausulaOfertaReadDto> {
		// TODO: Implement this method
		return new Promise<ClausulaOfertaReadDto>(async (resolve, reject) => {});
	}

	async getByOfertaId(filter: {}): Promise<ClausulaOferta[]> {		
		const res = await new ClausulaOfertaGetByOfertaId(filter, this.authToken).executeAsPromise();
		return ClausulaOferta.toArray(res?.items);
	}

    async add(data: ClausulaOfertaCreateDto): Promise<ClausulaOferta> {
		const res = await new ClausulaOfertaAdd(data, this.authToken).executeAsPromise();
		return res;
	}

	async delete(id: string): Promise<boolean> {
		await new ClausulaOfertaDelete(id, this.authToken).executeAsPromise();
		return true;  
	}

	async update(data: ClausulaOfertaUpdateDto): Promise<ClausulaOfertaUpdateDto> {
		return await new ClausulaOfertaUpdate(data, this.authToken).executeAsPromise();
	}

	buildModelFromItems(dtoItems: ClausulaOfertaReadDto): ClausulaOferta {
		throw new Error("Method not implemented.");
	}
	protected transformDtoIntoModel(dto: BaseResponse<ClausulaOfertaReadDto>): BaseResponse<ClausulaOferta> {
		throw new Error("Method not implemented.");
	}
	
}