import { DelegacionesEndpoints } from "../../modules/Oportunidades/infraestructure/oportunidades.api-endpoints";
import ApiRequest from "../../domain/ApiRequest";
import { DelegacionDetalleDto, DelegacionDto } from "../../domain/model/Delegacion";


export default class DelegacionRequestById extends ApiRequest<undefined,DelegacionDetalleDto>
{
    public constructor (id : string, token : string){
        super(
            "GET",
            DelegacionesEndpoints.DELEGACION + id,
            { Authorization: token },
            undefined,
            { }
        )
    }
}