import ApiRequest from "../../../../domain/ApiRequest";
import { UnidadObraApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { UnidadCreateDto } from "../../domain/model/Unidad";

export default class UnidadRequestAdd extends ApiRequest<UnidadCreateDto, UnidadCreateDto>
{
    constructor(data: UnidadCreateDto, token: string) {
        super(
            "POST",
           UnidadObraApiEndpoints.CREAR,
            { Authorization: `${token}`},
            data
        )
    }
}