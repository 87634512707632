import UnidadMedida from "../../../../domain/model/UnidadMedida";

interface LibroEstandarDetalleEntity {
    id: string | undefined;
    libroEstandarId: string;
    codigoUnidad: string;
    descripcion: string;   
    unidadMedidaId: string;
    unidadMedida:UnidadMedida;
    gfh: number;
    precioEjecucionMaterial: number;
    coste: number;
    medicion: number;
    medicionFinal: number;
}

export default class LibroEstandarDetalle implements LibroEstandarDetalleEntity {
    id: string | undefined;
    libroEstandarId: string;
    codigoUnidad: string;
    descripcion: string;   
    unidadMedidaId: string;
    unidadMedida:UnidadMedida;
    gfh: number;
    precioEjecucionMaterial: number;
    coste: number;
    medicion: number;
    medicionFinal: number;    

    constructor(entity: LibroEstandarDetalleEntity) {
        this.id = entity.id != null ? entity.id : "";
        this.libroEstandarId = entity.libroEstandarId;
        this.codigoUnidad = entity.codigoUnidad;
        this.descripcion = entity.descripcion;
        this.unidadMedidaId = entity.unidadMedidaId;
        this.unidadMedida=entity.unidadMedida;
        this.gfh = entity.gfh;
        this.precioEjecucionMaterial = entity.precioEjecucionMaterial;
        this.coste = entity.coste;
        this.medicion = entity.medicion;
        this.medicionFinal = entity.medicionFinal;
    }

    public static toArray(entities: LibroEstandarDetalleEntity[]): LibroEstandarDetalle[] {
		return entities.map(entity => { return new LibroEstandarDetalle(entity) });
	}
}

export interface LibroEstandarDetalleReadDto extends LibroEstandarDetalle {
    errors?: string[]    
    creationTime?: string;
    creationUsername?: string;    
    lastModificationTime?: string;
    lastModificationUsername?: string;
}
export type LibroEstandarDetalleUpdateDto = LibroEstandarDetalle;

export interface LibroEstandarDetalleDto extends LibroEstandarDetalle {
	errors: string[]
};