import React, { Fragment, FC, ReactNode } from "react"
import BodyCell, { CellChild } from "../../Table/components/BodyCell";
import { BaseEntity, Icon } from "@pavabits/components";
import { nameof } from "../../../utils/Utilities";
import IconButton from "../../ui/atoms/Buttons/IconButton";

interface RowAction {
	icon: string
	title?: string
	onClick: (id: string) => void
}

interface ITableRow<TObject extends object> {
	rowMapper(item: TObject): React.ReactNode
}

/**
 * Clase que mapea una instancia de TObject según la implementación de bodyRowMapper
 * para pintar la fila en un TableGrid
 */
abstract class RowMapper<TObject extends BaseEntity> implements ITableRow<TObject> {

	public constructor(
		private readonly rowAction?: RowAction,
		private readonly cellsChilds?: Array<CellChild<TObject>>,
	) {
		this.rowAction = rowAction;
		this.cellsChilds = cellsChilds;
	}

	/**
	 * Método para generar una key unívoca para cada celda de una fila de un TableGrid
	 * @param column Atributo en TObject que usar para identificar la celda
	 * @param id Id del entity de tipo TObject que se está pintando en la fila
	 * @returns String con la concatenación del nombre de columna y el id
	 */
	rowKey(column: keyof TObject, id: string) {
		return nameof<TObject>(column).concat("-", id.toString())
	}

	/**
	 * Método abstracto para mapear una instancia de TObject en un objeto de tipo Map 
	 * usando como clave sus atributos, a cada uno de los cuales se asigna un string
	 * @param item Instancia de TObject que se va a mapear
	 * @returns Instancia de Map con argumento de tipo TObject y string que asocia
	 * valor para los atributos en TObject definidos al implementar el método
	 */
	abstract objectMap(item: TObject): Map<keyof TObject, string>;

	rowMapper(item: TObject) {

		const RowComponent: FC<{
			expanded?: boolean
			rowClassName?: string,
			cellClassName?: string,
			expandedColSpan?: number,
			actionClassName?: string,
			onExpand?: () => void
			expandedSection?: (id: string) => ReactNode,
			allExpanded?: boolean
		}> = ({
			expanded = false,
			cellClassName,
			rowClassName,
			actionClassName,
			expandedColSpan,
			expandedSection,
			onExpand,
			allExpanded,
		}) => {
				const dictObject = this.objectMap(item);
				let bodyCells: ReactNode[] = [];
				dictObject.forEach((value, key) => {
					let codigoOferta = "";
					if (value === "codigo") {
						codigoOferta = key.toString();
					}
					bodyCells.push(
						<BodyCell
							id={this.rowKey(key, item.id)}
							className={cellClassName}
							cellValue={value?.toLowerCase()}
							cellKey={key.toString()}
						>
							{this.cellsChilds && this.cellsChilds.find(child => child.key === key)?.childRender(item.id)}
						</BodyCell>
					)
				})

				function handleExpand() {
					onExpand && onExpand();
				}

				return (
					<Fragment>
						<tr key={item.id} className={rowClassName}>
							{expandedSection &&
								<BodyCell cellValue="" id={"row-btn-" + item.id} className={cellClassName}>
									<button onClick={handleExpand} className="expand-oportunidad-btn">
										<Icon icon={!expanded ? "expand_more" : "expand_less"} />
									</button>
								</BodyCell>
							}
							{
								bodyCells
							}
							{!!this.rowAction &&
								<BodyCell
									cellValue=""
									id="empty-last"
									className={["actions", cellClassName].join(" ").trimEnd()}
								>
									<IconButton
										icon={this.rowAction.icon}
										onClick={() => this.rowAction!.onClick(item.id)}
									/>
								</BodyCell>
							}
						</tr>
						  {(expandedSection !== undefined && !allExpanded && expanded) &&
							<tr>
								<BodyCell
									cellValue=""
									id=""
									className={["expanded-cell", cellClassName].join(" ").trimEnd()}
									colSpan={expandedColSpan! + (this.rowAction !== undefined ? 1 : 0) + 1}
								>
									{
										expandedSection(item.id)
									}
								</BodyCell>
							</tr>
						}
						  { (expandedSection !== undefined && !expanded && allExpanded)  
						&&
							<tr>
								<BodyCell
									cellValue=""
									id=""
									className={["expanded-cell", cellClassName].join(" ").trimEnd()}
									colSpan={expandedColSpan! + (this.rowAction !== undefined ? 1 : 0) + 1}
								>
									{
										expandedSection(item.id)
									}
								</BodyCell>
							</tr>
						}
					</Fragment>
				)
			}

		return RowComponent
	}

}

export default RowMapper;