
import TipoOferta from "./TipoOferta";




interface OfertasEntity {

	id: string
	codigo: string
	obraDescripcion: string
	fechaOferta: Date
	promotorId: string
	clienteId: string
	clienteDescripcion: string
	codigoPostalId: string;
	codigoPostalDescripcion: string;
	estadoOfertaId: number;
	estadoOfertaDescripcion: string
	motivoId?: string;
	motivoDescripcion?: string;
	clientePerdidaId?: string;
	clientePerdidaDescripcion?: string;
	toneladas: number
	lastModificationTime: string
	lastModificationUsername: string
	tipoOferta: TipoOferta
	tipoOfertaId: number
	licitacionCodigo?: number;
	tieneAviso: boolean;
	oportunidadCodigo: number;
	provinciaId?: string;
	provinciaDescripcion?: string;
	tipoOfertaDescripcion?: string;
	// oferta?: string;
	// estado: string;
}

export default class ClonarOfertas {
	ofertaId: string;
	estudiosId: string[];
	clausulas: string[];
	porOpciones?: boolean;
	clienteContactoOferta?: ClienteContactoOferta[];
	clonarPresupuesto:boolean;

	constructor(ofertaId: string,
		estudiosId: string[],
		clausulas: string[],
		clonarPresupuesto:boolean,
		porOpciones?: boolean,
		clienteContactoOferta?: ClienteContactoOferta[]

		) {
		this.ofertaId = ofertaId;
		this.estudiosId = estudiosId;
		this.clausulas = clausulas;
		this.clienteContactoOferta = clienteContactoOferta;
		this.porOpciones = porOpciones;
		this.clonarPresupuesto=clonarPresupuesto;
	}

}


export class ClienteContactoOferta {
	clienteId: number | undefined;
	clienteDescripcion: string;
	contactoId: string | undefined;
	contactoDescripcion?: string;

	constructor(clienteId: number | undefined, clienteDescripcion: string, contactoId: string | undefined, contactoDescripcion: string | undefined) {
		this.clienteId = clienteId;
		this.clienteDescripcion = clienteDescripcion;
		this.contactoId = contactoId;
		this.contactoDescripcion = contactoDescripcion;
	}
}
