import { CSSProperties, ChangeEventHandler, FC, KeyboardEventHandler, useEffect, useRef } from 'react';
import FormFieldGroup from '../../../../components/ui/objects/FormFieldGroup/FormField.Group';
import { Icon, MaterialIcons } from '../../Icon/Icon';
import './CustomInput.scss';

export type CustomInputProps = {
    id?: string,
    type?: 'text' | 'password' | 'email' | 'date' | 'number',
    value: string | undefined,
    iconLeft?: MaterialIcons,
    label?: string,
    containerClassName?: string,
    labelClassName?: string,
    className?: string,
    formClassName?: string,
    required?: boolean,
    placeholder?: string,
    children?: never,
    onChange: (value: string) => void,
    onKeyPress?: (key: string) => void,
    onSubmit?: () => void,
    error?: string,
    reclaimFocus?: boolean,
    disabled?: boolean,
    resizable?: boolean,
    childInputRef?: React.RefObject<HTMLInputElement>,
    maxLength?: number,
    idCypress?: string
    onKeyDown?: (value: any) => void,
    style?: CSSProperties | undefined,
    idTest?: string
}

const CustomInput: FC<CustomInputProps> = (
    {
        id,
        type = 'text',
        value,
        iconLeft,
        label,
        containerClassName,
        labelClassName,
        className,
        formClassName,
        required = false,
        placeholder,
        onChange,
        onKeyPress,
        onSubmit,
        error,
        reclaimFocus = false,
        disabled,
        resizable,
        childInputRef,
        maxLength,
        idCypress,
        onKeyDown,
        style,
        idTest
    }) => {

    const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
        const { name, value } = e.target;
        //let regex = new RegExp("^[a-zA-Z ]+$");
        // let regex = new RegExp("^[-_ºç\,\.\(\)\/ñíóáéú@ 0-9a-zA-Z ]+");
        let regex = new RegExp("^[-_ºç\,\.\(\)\/ñíóáéúàèòÁÉÍÓÚÀÈÒÑ@ 0-9a-zA-Z ]+");
    
        if (regex.test(value) || value === '') {
            onChange(e.target.value);
        }        
    };

    const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (e) => {        
        onKeyPress && onKeyPress(e.key);
    };

    const handleSubmit = () => {
        onSubmit && onSubmit();
    };

    const inputElement = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (inputElement.current && reclaimFocus) {
            inputElement.current.focus();
        } else {
            inputElement.current?.blur();
        }
        
    }, [reclaimFocus]);

    return (
        <div style={style} className={`input-container ${disabled ? 'input-container--disabled' : ''}`}>
            {/* <div id={id}> */}
            <FormFieldGroup
				type="input"
				error={error !== undefined}
			>
            {
                label && <label className={labelClassName ? labelClassName : ''}>{label}</label>
            }
                {
                    iconLeft && <Icon icon={iconLeft} />
                }
                {resizable !== true ?
                    <input style={type == "number" ? {textAlign: "right"} : {textAlign: "left"}}
                        ref={childInputRef ? childInputRef : inputElement}
                        //type={type}
                        placeholder={placeholder}
                        required={required}
                        value={value}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                        onSubmit={handleSubmit}
                        disabled={disabled}
                        maxLength={maxLength}
                        onKeyDown={onKeyDown}
                        data-cy={idTest}
                    />
                    :
                    <textarea
                        id={id}
                        placeholder={placeholder}
                        required={required}
                        value={value}
                        onChange={handleChange}
                        onKeyPress={handleSubmit}
                        disabled={disabled}
                        maxLength={maxLength}
                        data-cy={idCypress}
                    />
                }

            </FormFieldGroup>
            <label className="error">{
                error && <span className="custom-input__label--error">{error}</span>
            }</label>
        </div>
    )
}

export default CustomInput;