import { useReducer } from "react"

type RecentRange = {
	lastSevenDays?: boolean,
	lastMonth?: boolean,
	lastThreeMonths?: boolean,
	lastYear?: boolean,
}

type TermTags =
	"whole"
	| "first"
	| "second"
	| "third"
	| "fourth"

type Terms = {
	wholeYear?: boolean,
	firstTerm?: boolean,
	secondTerm?: boolean,
	thirdTerm?: boolean,
	fourthTerm?: boolean,
}

type YearTags =
	"current"
	| "previous"
	| "2-previous"
	| "3-previous"
	| "4-previous"

type Years = {
	currentYear?: boolean,
	prevYear?: boolean,
	prevTwoYears?: boolean,
	prevThreeYears?: boolean,
	prevFourYears?: boolean,
}

type Period = {
	pickedYear?: number,
	year?: Years,
	term?: Terms
}

type DateSelection = {
	from?: string,
	until?: string,
}

type DateSelectorState = {
	date: DateSelection,
	recent?: RecentRange,
	period: Period
}

type DateSelectorAction = {
	type: ActionType,
	termKey?: keyof Terms,
	yearKey?: keyof Years,
	rangeKey?: keyof RecentRange,
	payload: any
}

enum ActionType {
	PICK_PERIOD_YEAR,
	PICK_PERIOD_TERM,
	PICK_RANGE,
	INPUT_FROM,
	INPUT_UNTIL,
	CLEAR_ALL,
}

const timeFormat: Intl.DateTimeFormatOptions = {
	year: "numeric",
	month: "2-digit",
	day: "2-digit",
};

function calculateYear(state: DateSelectorState, year?: YearTags): number {
	if (year === undefined) {
		if (state.period !== undefined && state.period.year !== undefined) {
			return state.period.pickedYear!;
		}
		return new Date().getFullYear();
	}

	switch (year) {
		case "previous":
			return new Date().getFullYear() - 1;
		case "2-previous":
			return new Date().getFullYear() - 2;
		case "3-previous":
			return new Date().getFullYear() - 3;
		case "4-previous":
			return new Date().getFullYear() - 4;
		default:
			return new Date().getFullYear();
	}

}

function currentPickedTerm(state: DateSelectorState): TermTags | undefined {
	if (state.period) {
		if (state.period && state.period.term?.firstTerm) return "first";
		if (state.period && state.period.term?.secondTerm) return "second";
		if (state.period && state.period.term?.thirdTerm) return "third";
		if (state.period && state.period.term?.fourthTerm) return "fourth";

		return "whole";
	}
	return undefined;
}

function elaborateDate(term: TermTags, year: number) {
	let from: Date = new Date();
	let until: Date = new Date();

	switch (term) {
		case "first":
			from = new Date(`${year}-1-1`);
			until = new Date(`${year}-3-31`);
			break;
		case "second":
			from = new Date(`${year}-4-1`);
			until = new Date(`${year}-6-30`);
			break;
		case "third":
			from = new Date(`${year}-7-1`);
			until = new Date(`${year}-9-30`);
			break;
		case "fourth":
			from = new Date(`${year}-10-1`);
			until = new Date(`${year}-12-31`);
			break;
		default:
			from = new Date(`${year}-1-1`);
			until = new Date(`${year}-12-31`);
			break;
	}
	return (
		{
			from: Intl.DateTimeFormat("fr-CA", timeFormat).format(from),
			until: Intl.DateTimeFormat("fr-CA", timeFormat).format(until),
		}
	)
}


function calculateDateFromYear(state: DateSelectorState, year: YearTags) {
	const numericYear = calculateYear(state, year);
	const term = currentPickedTerm(state);
	if (term !== undefined) {
		return elaborateDate(term, numericYear);
	}
	return state.date;
}

function calculateDateFromTerm(state: DateSelectorState, term: TermTags): DateSelection {
	const year: number = calculateYear(state);
	return elaborateDate(term, year);
}

function calculateDateFromRange(value: number): DateSelection {
	let fromDate = new Date();
	let untilDate = new Date();

	return (
		{
			from: Intl.DateTimeFormat("fr-CA", timeFormat).format(new Date(fromDate.setDate(fromDate.getDate() - value))),
			until: Intl.DateTimeFormat("fr-CA", timeFormat).format(untilDate),
		}
	)
}

function reducer(
	state: DateSelectorState,
	action: DateSelectorAction
): DateSelectorState {

	switch (action.type) {
		case ActionType.INPUT_FROM:
			return {
				...state,
				date: {
					...state.date,
					from: action.payload
				}
			}
		case ActionType.INPUT_UNTIL:
			return {
				...state,
				date: {
					...state.date,
					until: action.payload
				}
			}
		case ActionType.PICK_PERIOD_YEAR:
			return {
				...state,
				recent: undefined,
				period: {
					...state.period,
					pickedYear: calculateYear(state, action.payload),
					year: {
						[action.yearKey!]: true,
					},
				},
				date: calculateDateFromYear(state, action.payload)
			}
		case ActionType.PICK_PERIOD_TERM:
			return {
				...state,
				recent: undefined,
				date: calculateDateFromTerm(state, action.payload),
				period: {
					...state.period,
					pickedYear: state.period && state.period.pickedYear ? state.period.pickedYear : new Date().getFullYear(),
					year: state.period && state.period.pickedYear ? { ...state.period.year } : { currentYear: true },
					term: {
						[action.termKey!]: true
					},
				}
			}
		case ActionType.PICK_RANGE:
			return {
				...state,
				period: {},
				date: calculateDateFromRange(action.payload),
				recent: {
					[action.rangeKey!]: true,
				}
			}
		case ActionType.CLEAR_ALL:
			return {
				date: {
					from: undefined,
					until: undefined,
				}
			} as DateSelectorState
		default:
			return state;
	}
}

export default function useDateReducer() {

	const [state, dispatch] = useReducer(reducer, { date: {} } as DateSelectorState);

	function onUntilInputChange(value: any) {
		dispatch({
			type: ActionType.INPUT_UNTIL,
			payload: value,
		})
	}

	function onFromInputChange(value: any) {
		dispatch({
			type: ActionType.INPUT_FROM,
			payload: value
		})
	}

	function onTermPickChange(key: keyof Terms) {
		return (value: TermTags) => {
			dispatch({
				payload: value,
				termKey: key,
				type: ActionType.PICK_PERIOD_TERM
			});
		}
	}

	function onYearPickChange(key: keyof Years) {
		return (value: YearTags) => {
			dispatch({
				payload: value,
				yearKey: key,
				type: ActionType.PICK_PERIOD_YEAR
			});
		}
	}

	function onRangePickChange(key: keyof RecentRange) {
		return (value: number) => {
			dispatch({
				payload: value,
				rangeKey: key,
				type: ActionType.PICK_RANGE,
			});
		}
	}

	function onClear() {
		return (
			dispatch({
				type: ActionType.CLEAR_ALL,
				payload: undefined
			})
		)
	}

	return {
		dates: [state.date.from, state.date.until],
		period: state.period,
		recent: state.recent,
		onUntilInputChange,
		onFromInputChange,
		onTermPickChange,
		onYearPickChange,
		onRangePickChange,
		onClear,
	}
}