import React, { useContext } from 'react'
import { GlobalContext, GlobalContextType } from '../../context/Global.Context';
import TagCloud from '../../components/ui/objects/TagCloud/TagCloud';

interface Props {
  idTest?:string
}

const ClausulasTagCloud: React.FC<Props> = ({idTest}: Props) => {
    const componentName = "clausulas"
    const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;

    const tags = [
        {
            label: "Delegaciones",
            text:  
            globalState[componentName]?.filters?.userDelegacion !== undefined
            ? ""
            : globalState[componentName]?.filters?.delegacionNombre 
            && globalState[componentName]?.filters?.delegacionNombre.length > 0 
            ? globalState[componentName]?.filters?.delegacionNombre : "",
            onClear: () => { 
              updateGlobalState(componentName, {filters: {...globalState[componentName]?.filters,"delegacionNombre": undefined, "delegacionIds": undefined}})
              updateGlobalState(componentName, {filters: {...globalState[componentName]?.filters,"delegacionNombre": undefined, "delegacionIds": undefined}})
            }
        },
        {
            label: "Nombre",
            text: globalState[componentName]?.filters?.nombre?.length > 0 
            && globalState[componentName]?.filters?.nombre !== undefined 
            ? globalState[componentName]?.filters?.nombre 
            : "",
            onClear: () => {
              updateGlobalState(componentName, {filters: {...globalState[componentName]?.filters,"nombre": undefined}})
            }
        },
        {
            label: "Descripción",
            text: globalState[componentName]?.filters?.descripcion?.length > 0 
            && globalState[componentName]?.filters?.descripcion !== undefined 
            ? globalState[componentName]?.filters?.descripcion 
            : "",
            onClear: () => {
              updateGlobalState(componentName, {filters: {...globalState[componentName]?.filters,"descripcion": undefined}})
            }
        },
    ]


  return (
    <TagCloud tags={tags} idTest={idTest}/>
  )
}

export default ClausulasTagCloud