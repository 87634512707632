import { FormaPagoEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ApiRequest from "../../../../domain/ApiRequest";
import FormaPago, { FormaPagoReadingDto, FormaPagoUpdateDto } from "../../domain/model/FormaPago";

export default class FormaPagoRequestUpdate extends ApiRequest<
FormaPagoUpdateDto,
FormaPagoReadingDto
>
{
	constructor(data: FormaPagoUpdateDto, token: string) {
		super(
			"PUT",
			FormaPagoEndpoints.UPDATE,
			{ Authorization: `${token}`},
			data,
		)
	}
}