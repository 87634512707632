import { BaseRepository } from "@pavabits/components";
import DelegacionesRepository from "../../domain/DelegacionesRepository";
import Delegacion, { DelegacionDetalle, DelegacionDetalleDto, DelegacionDto } from "../../domain/model/Delegacion";
import DelegacionRequestById from "../ws/Delegacion.RequestById";
import DelegacionesRequestListByFilter from "../ws/Delegaciones.RequestListByFilter";

export default class DelegacionesApiRepository extends BaseRepository<
	DelegacionDto[],
	Delegacion[]
> implements DelegacionesRepository {

	fetchDelegaciones(data: any): Promise<Delegacion[]> {
		return new Promise((resolve, reject) => {
			return new DelegacionesRequestListByFilter(data, this.authToken)
				.request()
				.then((response) => {
					if (response.success) {
						resolve(this.transformDtoIntoModel(response.data.items));
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error) => {
					reject(error);
				})
		})
	}

	fetchDelegacionesDto(data: any): Promise<DelegacionDto[]> {
		return new Promise((resolve, reject) => {
			return new DelegacionesRequestListByFilter(data, this.authToken)
				.request()
				.then((response) => {
					if (response.success) {
						resolve(response.data.items);
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error) => {
					reject(error);
				})
		})
	}

	fetchDelegacion(id: string): Promise<DelegacionDetalle> {
		return new Promise((resolve, reject) => {
			return new DelegacionRequestById(id, this.authToken)
				.request()
				.then((response) => {
					if (response.success) {
						resolve(this.transformSimpleDtoIntoModel(response.data));
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error) => {
					reject(error);
				})
		})
	}


	protected transformDtoIntoModel(dto: DelegacionDto[]): Delegacion[] {
		if (dto && dto.length) {
			return dto.map(item => {
				return new Delegacion(
					item.id,
					item.descripcion
				)
			})
		}
		return []
	}

	protected transformSingleDtoIntoModel(dto: DelegacionDto): Delegacion {
		return new Delegacion(
			dto.id,
			dto.descripcion
		)

	}

	protected transformSimpleDtoIntoModel(dto: DelegacionDetalleDto): DelegacionDetalle {
		return new DelegacionDetalle(
			{
				id: dto.id,
				descripcion: dto.descripcion,
				direccion: dto.direccion,
				poblacion: dto.poblacion,
				codigoPostal: dto.codigoPostal,
				provincia: dto.provincia,
				telefono: dto.telefono,
				email: dto.email,
				cif: dto.cif
			}
		)
	}

}