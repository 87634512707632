

interface EstudioDetalleAvanzadoTramoEntity {
	id?: string;
	tramoId?:string;
	tarifa:string;
	descripcion?:string;
	tarifaOriginal?:string;
}


export default class EstudioDetalleAvanzadoTramo implements EstudioDetalleAvanzadoTramoEntity {
	id?: string;
	tramoId?:string;
	tarifa:string;
	descripcion?: string;
	tarifaOriginal?:string;

	constructor(entity: EstudioDetalleAvanzadoTramoEntity) {
		this.id = entity.id;
		this.tramoId=entity.tramoId
		this.tarifa=entity.tarifa;
		this.descripcion=entity.descripcion;
		this.tarifaOriginal=entity.tarifaOriginal;
	}

	public static toArray(entities: EstudioDetalleAvanzadoTramoEntity[]): EstudioDetalleAvanzadoTramo[] {
		return entities.map(entity => { return new EstudioDetalleAvanzadoTramo(entity) });
	}
}

export interface EstudioDetalleAvanzadoTramoDto extends EstudioDetalleAvanzadoTramo {
	errors?: string[]
};
