import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { UnidadObraApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { UnidadReadDto } from "../../domain/model/Unidad";

export default class UnidadesRequestGetAllSinPaginacion extends ApiRequest<any, UnidadReadDto[]> {
    public constructor(token: string, params?: { [key: string]: number | string }) {
        super(
            "GET",
            UnidadObraApiEndpoints.GETALLSINPAGINCACION,
            { Authorization: `${token}` },
            {},
            params ?? ""
        )
    }
}