
import ApiRequest from "../../../../domain/ApiRequest";
import { SeguimientoOfertaEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { SeguimientoOfertaDeleteDto } from "../../domain/model/SeguimientoOferta";

export default class SeguimientoOfertaRequestDelete extends ApiRequest<
{},
SeguimientoOfertaDeleteDto
>
{
	constructor(id: string, token: string) {
		super(
			"DELETE",
			SeguimientoOfertaEndpoints.DELETE+id,
			{ Authorization: `${token}`},
			{},
		)
	}
}