import { AutoCompleteSearchFilter, BiasedType } from "../../../../domain/BaseFilter";
import { DelegacionDetalle } from "../../../../domain/model/Delegacion";
import Ofertas from "../../../Ofertas/domain/model/Ofertas";
import FicherosAdjuntos from "./FicherosAdjuntos";
import PresupuestoClausula from "./PresupuestoClausula";
import PresupuestoContacto from "./PresupuestoContacto";


interface PresupuestoEntity  {
	id?: string,
	ofertaId: string,
    clienteId: string,
    promotorId: string,
	fechaGeneracion: string,
	fechaEnvio?: string,
    estudiosId: string[],
	presupuestoClausulas: PresupuestoClausula[],
	presupuestoContactos: PresupuestoContacto[];
	delegacion:DelegacionDetalle,
	totalPresupuesto:string,
	presupuestoId:number,
	presupuestoVersion: number,
	estudioIds:string;
	estudiosGuids?: string[];
	urlPdfGenerado: string;
	contratado: boolean;
	lastModificationTime: string;
	porOpciones: boolean;
	estudioContratado?: string;
	updateToneladasOportunidad?: boolean;
}


export default class Presupuesto implements PresupuestoEntity {

	id?: string;
	ofertaId: string;
    clienteId: string;
    promotorId: string;
	fechaGeneracion: string;
	fechaEnvio?: string;
    estudiosId: string[];
	presupuestoClausulas: PresupuestoClausula[];
	presupuestoContactos: PresupuestoContacto[];
	delegacion:DelegacionDetalle;
	totalPresupuesto:string;
	presupuestoId: number;
	presupuestoVersion: number;
	estudiosGuids?: string[];
	estudioIds:string;
	urlPdfGenerado: string;
	contratado: boolean;
	lastModificationTime: string;
	porOpciones: boolean;
    estudioContratado?: string;
	updateToneladasOportunidad?: boolean;

	constructor(entity: PresupuestoEntity) {
		
		this.id = entity.id;
		this.ofertaId = entity.ofertaId;
		this.clienteId = entity.clienteId;
		this.promotorId = entity.promotorId;
		this.fechaGeneracion = entity.fechaGeneracion;
		this.fechaEnvio = entity.fechaEnvio;
		this.estudiosId = entity.estudiosId;
		this.presupuestoClausulas = entity.presupuestoClausulas;
		this.presupuestoContactos = entity.presupuestoContactos;
		this.delegacion=entity.delegacion;
		this.totalPresupuesto=entity.totalPresupuesto;
		this.presupuestoId = entity.presupuestoId;
		this.presupuestoVersion = entity.presupuestoVersion;
		this.estudioIds = entity.estudioIds;
		this.urlPdfGenerado = entity.urlPdfGenerado;
		this.contratado = entity.contratado;
		this.lastModificationTime = entity.lastModificationTime;
		this.estudiosGuids = entity.estudiosGuids;
		this.porOpciones = entity.porOpciones;
		this.estudioContratado = entity.estudioContratado;
		this.updateToneladasOportunidad = entity.updateToneladasOportunidad;
	}

	public static toArray(entities: PresupuestoEntity[]): Presupuesto[] {
		return entities ? entities.map(entity => { return new Presupuesto(entity) }) : [];
	}
}

type FilterExcludedProperties =
"id"
| "text"
| "clienteId"
| "promotorId"
| "fechaGeneracion"
| "fechaEnvio"
| "estudiosId"
| "presupuestoClausulas"
| "delegacion"
| "totalPresupuesto"
| "presupuestoId"
| "presupuestoVersion"
| "estudioIds"
| "presupuestoContactos"
| "urlPdfGenerado"
| "contratado"
| "lastModificationTime"
| "porOpciones"
| "estudioContratado"

type BiasedPresupuesto = BiasedType<Presupuesto, FilterExcludedProperties>;

export type PresupuestoFilter = AutoCompleteSearchFilter<BiasedPresupuesto>;

export type PresupuestoSend = {
	presupuestoId: string;
	ofertaId: string;
	ficherosAdjuntos?: FicherosAdjuntos[];
}

export interface PresupuestoDto extends Presupuesto {
	// lastModificationTime?: string;
	lastModificationUsername?: string;
}
// export type PresupuestoCreationDto = Presupuesto;
export interface PresupuestoCreationDto extends Presupuesto {
	// lastModificationTime?: string;
	lastModificationUsername?: string;
};
export interface PresupuestoUpdateDto extends Presupuesto {
	// lastModificationTime?: string;
	lastModificationUsername?: string;
};
export interface PresupuestoDeleteDto  { 
	errors:string[]
	};


export const NumeroPresupuestoFormat=(oferta:Ofertas, presupuesto:Presupuesto):string=>{
	return oferta?.codigo + "/" + presupuesto.presupuestoId + "-" + presupuesto.presupuestoVersion;
}