import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import IconButton from "../../../../../../components/ui/atoms/Buttons/IconButton";
import { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
import CustomTextInput from "../../../../../../components/CustomTextInput";
import BottomButtons from "../../../../../../components/ui/molecules/Bottom-buttons/BottomButtons";
import LabelButton from "../../../../../../components/ui/atoms/Buttons/LabelButton";
import UnidadesMedidaRepository from "../../../../../../domain/UnidadesMedidaRepository";
import UnidadesMedidaApiRepository from "../../../../../../infraestructure/api/UnidadesMedida.ApiRepository";
import { AuthContext } from "../../../../../Login/AuthContextProvider";
import { toast } from "react-toastify";
import UnidadMedida, { UnidadMedidaDto } from "../../../../../../domain/model/UnidadMedida";
import { UnidadReadDto, DescripcionUnidadObra } from "../../../../../Unidades/domain/model/Unidad";
import Dropdown, { DropdownProps } from "../../../../../../components/ui/atoms/Dropdown/Dropdown";
import FormField from "../../../../../../components/ui/molecules/Form-field/FormField";

interface Props {
    openModal: boolean,
    handleCloseModal: () => void,
    handleAddUnidadObra: (unidadObra: UnidadReadDto) => void,
}

const CrearUnidadObra: React.FC<Props> = ({ openModal, handleCloseModal, handleAddUnidadObra }) => {

    const { getToken } = useContext(AuthContext);

    const [newDescripcionUnidadObras, setNewDescripcionUnidadObras] = useState<DescripcionUnidadObra>();
    const [newUnidadObraCustom, setNewUnidadObraCustom] = useState<UnidadReadDto>(
        {
            id: "",
            delegacion: null,
            delegacionId: null,
            descripcion: "",
            descripcionUnidadObra: [],
        }
    );
    const formId = "unidadObra-form";
    const [medidas, setMedidas] = useState<UnidadMedidaDto[]>([]);
    
    useEffect(() => {
        saveDisabled(newUnidadObraCustom);
    }, [newDescripcionUnidadObras, newUnidadObraCustom, medidas]);

    const handleCloseModalUnidad = () => {
        handleAddUnidadObra(newUnidadObraCustom)
        handleCloseModal()
        setNewUnidadObraCustom({
            id: "",
            delegacion: null,
            delegacionId: null,
            descripcion: "",
            descripcionUnidadObra: [],
        })
    }


    const handleNewUnidadObra = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setNewDescripcionUnidadObras({ ...newDescripcionUnidadObras!, unidadMedida: medidas[0] as UnidadMedida })
        setNewUnidadObraCustom(prev => ({ ...prev, [name]: value }));
    }

    const handleNewUnidadObraDescripcion = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setNewDescripcionUnidadObras({ ...newDescripcionUnidadObras!, [name]: value})
    }

    const [newMedida, setNewMedida] = useState({
        id: 1,
        descripcion: ""
    })

    const handleSelectMedida = (medidaObj: any) => {
        setNewMedida(medidaObj);
        setNewDescripcionUnidadObras({ ...newDescripcionUnidadObras!, unidadMedida: medidaObj as UnidadMedida });
    }

    const fetchMedidas = useCallback(() => {
        const repo: UnidadesMedidaRepository = new UnidadesMedidaApiRepository(getToken());
        return repo.getConFiltro({}).then((data) => {
            setMedidas(data);
        })
            .catch(message => toast.error(message));
    }, []);

    useEffect(() => {
        setNewUnidadObraCustom({ ...newUnidadObraCustom, descripcionUnidadObra: [newDescripcionUnidadObras!] })
    }, [newDescripcionUnidadObras]);

    useEffect(() => {
        fetchMedidas();
    }, [newUnidadObraCustom]);

    const [addDisabled, setAddDisabled] = useState<boolean>(true)

    const saveDisabled = (newUnidad: UnidadReadDto) => {
        if (newUnidad.descripcion == "" ||
            newDescripcionUnidadObras?.descripcion == undefined ||
            newDescripcionUnidadObras.descripcion == "" ||
            medidas == null) {
            return setAddDisabled(true);
        } else {
            return setAddDisabled(false);
        }
    }

    return (
        <div className="modal-section">
            <div className="fields-modal-container">
                <Modal
                    className="filters-modal modal-l"
                    handleCloseModal={handleCloseModal}
                    show={openModal}
                >
                    <ModalHeader>
                        <ModalTitle>
                            Crear Unidad Obra
                        </ModalTitle>
                        <IconButton
                            icon="close"
                            onClick={() => handleCloseModal()}
                        />
                    </ModalHeader>
                    <ModalBody>

                        <div className="add-form-container">
                            <div className="double-col-grid">
                                <CustomTextInput
                                    label="Nombre"
                                    name="descripcion"
                                    value={newUnidadObraCustom?.descripcion}
                                    onChange={handleNewUnidadObra}
                                />

                                <FormField<DropdownProps>
                                    label="Medida"
                                    options={medidas.map(
                                        (element) => {
                                            return {
                                                text: element.descripcion,
                                                id: element.id,
                                                selected: +element.id === newMedida.id,
                                                onClick: () => { handleSelectMedida(element) }
                                            }
                                        })}
                                    required={true}
                                    disabled={false}
                                    singleSelection={true}
                                    component={Dropdown}
                                />
                            </div>

                            <CustomTextInput
                                label="Descripción"
                                name="descripcion"
                                value={newDescripcionUnidadObras?.descripcion}
                                onChange={handleNewUnidadObraDescripcion}
                            />
                        </div>

                        <BottomButtons>
                            <LabelButton
                                label="Guardar"
                                type="submit"
                                formId={formId}
                                onClick={handleCloseModalUnidad}

                                disabled={addDisabled}
                            />

                        </BottomButtons>
                    </ModalBody>
                </Modal>
            </div>
        </div>
    );
}


export default CrearUnidadObra;