import BaseResponse from "../../../../domain/BaseResponse";
import { ContactoClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ApiRequest from "../../../../domain/ApiRequest";
import { ContactoReadDto } from "../../domain/model/Contacto";

export default class ContactosRequestFetchAll extends ApiRequest<any, BaseResponse<ContactoReadDto[]>> 
{
    public constructor (token: string, params?: {[key: string]: number}) {
        super(
            "GET", 
            ContactoClienteEndpoints.ALL,
            {Authorization: `${token}`},
            {},
            params ?? ""
        )
    }
}