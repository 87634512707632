function useRouter(push: (route: string) => void) {

	function generateRoute(route: string, params: {[key: string] : string}): string {
		let routeStr = route;
		for (const key in params) {
			if (key && params[key]) {
				routeStr = routeStr.replace(String(':' + key).trim(), String(params[key]).trim());
			}
		}
		return routeStr;
	}

	function goToRoute(route: string, params?: {[key: string] : string}): void {
		const routeWithParams = generateRoute(route, params ?? {});
		push(routeWithParams);
	}

	return {
		generateRoute,
		goToRoute,
	}
}

export default useRouter;