import React, { ChangeEvent, useContext, useState } from 'react'
import { GlobalContext, GlobalContextType } from '../../../context/Global.Context';
import FormFieldGroup from '../../ui/objects/FormFieldGroup/FormField.Group';

interface TextInputFilterSearchFieldProps {
    componentName: string;
    field: string;
    label: string;
    idTest?:string
}

const TextInputFilterSearchField: React.FC<TextInputFilterSearchFieldProps> = ({componentName, field, label, idTest}) => {
    const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;
    const [value, setValue] = useState(globalState[componentName]?.filters?.[field] ?? "")

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setValue(value);
      updateGlobalState(componentName, 
        {
          filters: 
          {
            ...globalState[componentName]?.filters, 
            [field]: e.target.value.length !== 0 ? e.target.value : undefined},
            pagination: {
              skipCount: 0
            }
          })
  }

  return (
    <div className='input-container'>
    <FormFieldGroup>
    <label>{label}</label>
    <input
        value={value}
        onChange={handleInputChange}
        data-cy={idTest}
    >
    </input>
</FormFieldGroup >
</div>
  )
}

export default TextInputFilterSearchField