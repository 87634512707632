import { useContext } from "react";
import { ClienteFieldContext } from "../../../context/FilterFields.Context";
import OportunidadesSuggestionInput from "./Oportunidades.SuggestionInput";

const ClienteSuggestionInput: React.FC<{ disabled: boolean, idTest?:string }> = ({ disabled, idTest }) => {

	const field = useContext(ClienteFieldContext);

	return (
		<div className="search">
			<OportunidadesSuggestionInput
				label="Buscar Cliente"
				disabled={disabled}
				{...field}
				idTest={idTest}
			/>
		</div>
	)
}

export default ClienteSuggestionInput;