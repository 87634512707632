import EstudioDetalle from "./EstudioDetalle";

interface EstudioEntity {
	id: string | undefined,
	estudioId?: number,
	ofertaId: string,
	totalVenta?: number,
	totalCoste?: number,
	creationTime: string,
	descripcion: string,
	descripcionPresupuesto?: string;
	estudioDetalle: EstudioDetalle[],
	numeroPresupuestoYVersion: string,
	presupuestoId: string,
	coeficiente?: number
}


export default class Estudio implements EstudioEntity {

	id: string;
	estudioId?: number;
	ofertaId: string;
	totalVenta?: number;
	totalCoste?: number;
	creationTime: string;
	descripcion: string;
	descripcionPresupuesto?: string;
	estudioDetalle: EstudioDetalle[];
	numeroPresupuestoYVersion: string;
	presupuestoId: string;
	coeficiente?: number;

	constructor(entity: EstudioEntity) {
		this.id = entity.id!;
		this.estudioId = entity.estudioId;
		this.ofertaId = entity.ofertaId;
		this.totalVenta = entity.totalVenta;
		this.totalCoste = entity.totalCoste;
		this.creationTime = entity.creationTime;
		this.descripcion = entity.descripcion;
		this.descripcionPresupuesto = entity.descripcionPresupuesto;
		this.estudioDetalle = entity.estudioDetalle;
		this.numeroPresupuestoYVersion = entity.numeroPresupuestoYVersion;
		this.presupuestoId = entity.presupuestoId;
		this.coeficiente = entity.coeficiente;
	}

	public static toArray(entities: EstudioEntity[]): Estudio[] {
		return entities ? entities.map(entity => { return new Estudio(entity) }) : [];
	}
}


export interface EstudioDto extends Estudio {
	errors: string[]
};


export interface EstudioCreationDto extends Estudio {
	errors: string[]
};
export type EstudioUpdateDto = Estudio;