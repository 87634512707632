import { useMemo, useState } from "react";
import debounce from "lodash.debounce";
import "./MinMaxInput.scss";

interface Props {
	minInputChange: (min: number) => void
	maxInputChange: (max: number) => void
	idTestMin?: string
	idTestMax?: string
}

const MinMaxInput: React.FC<Props> = ({ minInputChange, maxInputChange, idTestMax, idTestMin }) => {

	const [minValue, setMinValue] = useState(undefined);
	const [maxValue, setMaxValue] = useState(undefined);

	const debouncedMinUpdate = useMemo(() => { return debounce((value: number) => minInputChange(value), 2000) }, [minInputChange]);
	const onMinChange = (e: any) => {
		const value = e.target.value;
		setMinValue(value);
		debouncedMinUpdate(value);
	}

	const debouncedMaxUpdate = useMemo(() => { return debounce((value: number) => maxInputChange(value), 2000) }, [maxInputChange]);
	const onMaxChange = (e: any) => {
		const value = e.target.value;
		setMaxValue(value);
		debouncedMaxUpdate(value);
	}

	return (
		<div className="minmax-input">
			<input
				// className="op-input form-control min-input"
				type="number"
				value={minValue}
				placeholder="Mínimo"
				onKeyDown={(e) => {
					if (e.key.toUpperCase() === "E" || e.key === "-") {
					  e.preventDefault();
					}
				  }}
				onChange={onMinChange}
				data-cy={idTestMin}
			/>
			<label>-</label>
			<input
				// className="op-input form-control max-input"
				type="number"
				value={maxValue}
				placeholder="Máximo"
				onKeyDown={(e) => {
					if (e.key.toUpperCase() === "E" || e.key === "-") {
					  e.preventDefault();
					}
				  }}
				onChange={onMaxChange}
				data-cy={idTestMax}
			/>
		</div>
	)
};

export default MinMaxInput;