
import ApiRequest from "../../../../domain/ApiRequest";
import { EstudioDetalleApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class EstudioDetalleDelete extends ApiRequest<
{},
boolean
>
{
	constructor(id: string, token: string) {
		super(
			"DELETE",
			EstudioDetalleApiEndpoints.DELETE+id,
			{ Authorization: `${token}`},
			{},
		)
	}
}