import { useContext } from "react";

import Dropdown from "../../../../../components/ui/atoms/Dropdown/Dropdown";
import FormFieldGroup from "../../../../../components/ui/objects/FormFieldGroup/FormField.Group";
import { TipoOfertasDropdownContext } from "../../../../Oportunidades/context/FilterFields.Context";


interface TipoOfertasDropdownProps {
    disabled?: boolean;
	idTest?:string
}
    
const TipoOfertasDropdown: React.FC<TipoOfertasDropdownProps> = ({disabled, idTest}) => {

	const options = useContext(TipoOfertasDropdownContext);

	return (
		<div className='input-container'>
		<FormFieldGroup>
			<label>Tipo Oferta</label>
			<Dropdown
				disabled={disabled}
				options={options}
				idTest={idTest}
			/>
		</FormFieldGroup>
		</div>
	)
}

export default TipoOfertasDropdown;