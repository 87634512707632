import ApiRequest from "../../../../domain/ApiRequest";
import { UnidadObraEquipoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { UnidadEquipoCreateDto, UnidadEquipoReadDto } from "../../domain/model/UnidadEquipo";

export default class UnidadesEquipoRequestAdd extends ApiRequest<UnidadEquipoCreateDto, UnidadEquipoReadDto[]>
{
    constructor(data: UnidadEquipoCreateDto, token: string) {
        super(
            "POST",
            UnidadObraEquipoApiEndpoints.ADD_LIST,
            { Authorization: `${token}`},
            data
        )
    }
}