import { useContext } from "react"
import MinMaxInput from "../../../../components/ui/atoms/MinMaxInput/MinMaxInput"
import { OportunidadesFilterHandlersContext } from "../../context/Oportunidades.Context"
import FormFieldGroup from "../../../../components/ui/objects/FormFieldGroup/FormField.Group";

const ToneladasMinMax: React.FC<{}> = () => {

	const { onRangeField } = useContext(OportunidadesFilterHandlersContext);

	return (
		<FormFieldGroup>
			<div className="input-container">
			<label>Toneladas</label>
			<MinMaxInput
				minInputChange={onRangeField("minToneladas").onChange}
				maxInputChange={onRangeField("maxToneladas").onChange}
				idTestMin="MinToneladasFiltro"
				idTestMax="MaxToneladasFiltro"
			/>
			</div>
		</FormFieldGroup>
	)
}

export default ToneladasMinMax;