import BaseResponse from "../../../../domain/BaseResponse";
import { UnidadObraElementoTipo } from "../../domain/model/ElementoEquipo";
import { ElementoTipoEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ApiRequest from "../../../../domain/ApiRequest";

export default class ElementoTipoRequestGetALl extends ApiRequest<
	any,
	BaseResponse<UnidadObraElementoTipo[]>
> {
	public constructor(token: string) {
		super(
			"GET",
			ElementoTipoEndpoints.ALL,
			{ Authorization: token },
            undefined,
            {
            }
		)
	}
}