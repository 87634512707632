import React, { useContext, useEffect, useState } from 'react'
import FormFieldGroup from '../../components/ui/objects/FormFieldGroup/FormField.Group'
import { UnidadObraElementoTipo } from './domain/model/ElementoEquipo';
import { GlobalContext, GlobalContextType } from '../../context/Global.Context';
import Dropdown from '../../components/ui/atoms/Dropdown/Dropdown';

interface EquiposTipoDropdownProps {
  unidadObraElementoTipos: UnidadObraElementoTipo[];
  idTest?: string;
}

const EquiposTipoDropdown: React.FC<EquiposTipoDropdownProps> = ({unidadObraElementoTipos, idTest}) => {

  const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;
  const [values, setValues] = useState<UnidadObraElementoTipo[]>(globalState?.elementos?.filters?.tipoIds && globalState?.elementos?.filters?.tipoIds.length > 0 ? 
    unidadObraElementoTipos?.filter(x => globalState?.elementos?.filters?.tipoIds.includes(x.id)) : [])


    useEffect(() => {
      updateGlobalState("elementos",
      {filters: {...globalState.elementos?.filters,
      "tipoIds": values.map( (elem) => {return elem.id} ), 
      "tipoNombre": values.map( 
          (elem) => {
              return elem.descripcion + ", "} ) }}
        )
  }, [values, unidadObraElementoTipos])

  const options = unidadObraElementoTipos?.map((unidadObraElementoTipo: UnidadObraElementoTipo, index) => {
    return {
      id: unidadObraElementoTipo.id.toString(),
      text: unidadObraElementoTipo.descripcion,
      selected: globalState?.elementos?.filters?.tipoIds?.includes(unidadObraElementoTipo.id),
      onClick: () => {
        if (globalState?.elementos?.filters?.tipoIds?.includes(unidadObraElementoTipo.id)) {
          setValues(values.filter(tipo => tipo.id !== unidadObraElementoTipo.id))
       } else {
           setValues([...values, unidadObraElementoTipo])
       }
      }
    }
  })

  return (
    <div className='input-container' data-cy={idTest}>

    <FormFieldGroup>
        <label>Tipo</label>
        {options && <Dropdown
            options={options}
            label="TipoFiltro"
        />}
        </FormFieldGroup>
        </div>
  )
}

export default EquiposTipoDropdown