import BaseResponse from "../../../../domain/BaseResponse";
import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";
import EstudioDetalleAvanzadoTraslado, { EstudioDetalleAvanzadoTrasladoDto } from "../../../Estudios/domain/model/EstudioDetalleAvanzadoTraslado";
import EstudioDetalleAvanzadoTrasladoRepository from "../../domain/EstudioDetalleAvanzadoTrasladoRepository";
import EstudioDetalleAvanzadoTrasladoRequestDelete from "../ws/EstudioDetalleAvanzadoTraslado.Request.Delete";


export default class EstudioDetalleAvanzadoTrasladoApiRepository 
	extends ApiRepositoryBase<EstudioDetalleAvanzadoTrasladoDto, EstudioDetalleAvanzadoTraslado> 
	implements EstudioDetalleAvanzadoTrasladoRepository {

	async delete(id: string): Promise<EstudioDetalleAvanzadoTrasladoDto> {
		return await new EstudioDetalleAvanzadoTrasladoRequestDelete(id, this.authToken).executeAsPromise();		
	}

	buildModelFromItems(item:  EstudioDetalleAvanzadoTrasladoDto):  EstudioDetalleAvanzadoTraslado {
		return new  EstudioDetalleAvanzadoTraslado(item);
	}

	protected transformDtoIntoModel(dto: BaseResponse<EstudioDetalleAvanzadoTrasladoDto>): BaseResponse<EstudioDetalleAvanzadoTraslado> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}
}