import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { ContactoClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { ContactoClienteDto } from "../../domain/model/ContactosCliente";

export default class ContactoClientesRequestGetByClienteId extends ApiRequest<{}, BaseResponse<ContactoClienteDto[]>>
{
	constructor(id: number, token: string) {
		super(
			"POST",
			ContactoClienteEndpoints.CON_FILTRO,
			{ Authorization: token },
			{ "filter": {
				"clienteId": id
			  }
			}
		)
	}
}