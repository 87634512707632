import classNames from 'classnames';
import { CSSProperties, FC } from 'react';

import './Icon.scss';

export type MaterialIcons =
    'add'
    | 'account_circle'
    | 'arrow_drop_down'
    | 'arrow_drop_up'
    | 'close'
    | 'construction'
    | 'done'
    | 'download'
    | 'fact_check'
    | 'file_download'
    | 'groups'
    | 'keyboard_double_arrow_left'
    | 'keyboard_double_arrow_right'
    | 'lock'
    | 'navigate_before'
    | 'navigate_next'
    | 'people'
    | 'person'
    | 'receipt'
    | 'view_list'
    | 'vpn_key'
    | 'engineering'
    | 'payments'
    | 'delete'
    | 'info'
    | 'drive_file_rename_outline'
    | 'remove'
    | 'table_chart'
    | 'visibility'
    | 'edit_note'
    | 'favorite'
    | 'bookmark'
    | 'file_copy'

export type IconProps = {
    icon: MaterialIcons | string,
    id?: string,
    children?: never,
    style?: CSSProperties,
    className?: string,
    onClick?: (e: any) => void,
    idCypress?: string
    
}

export const Icon: FC<IconProps> = ({id, icon, style, className, idCypress, onClick}) => {

    const classComponent = classNames('material-icons', 'icon', className);

    return (
        <i
            id={id}
            className={classComponent}
            style={style}
            children={icon}
            onClick={onClick}
            data-cy={idCypress}
            />
    );
}
