import ApiRequest from "../../../../domain/ApiRequest";
import { BaseFilter } from "../../../../domain/BaseFilter";
import BaseResponse from "../../../../domain/BaseResponse";
import { ElementosEquipoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { UnidadReadDto } from "../../domain/model/Unidad";

export default class UnidadesRequestListByFilter extends ApiRequest<
BaseFilter<any>,
BaseResponse<UnidadReadDto[]>>
{
	constructor(data: any, token: string) {
		super(
			"POST",
			ElementosEquipoApiEndpoints.CON_FILTRO,
			{ Authorization: token },
			data
		)
	}
}