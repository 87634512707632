import './LabelButton.scss';

interface Props {
	label: string
	type?: 'submit' | 'reset' | 'button'
	formId?: string
	idTest?: string
	onClick?: () => void
	disabled?: boolean
}

const LabelButton: React.FC<Props> = (
	{
		label,
		onClick,
		formId,
		idTest,
		type = 'button',
		disabled
	}: Props
) => {
	return (
		<button
			form={formId}
			type={type}
			className="btn-primary"
			disabled={disabled}
			onClick={onClick}
			data-cy={idTest == undefined ? label : idTest}
		>
			{label}
		</button>
	)
}

export default LabelButton;