

import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import IconButton from '../../../components/ui/atoms/Buttons/IconButton';

const DropZone = ({ className, handleFicherosAdjuntados }) => {
  const [files, setFiles] = useState([])
  const [rejected, setRejected] = useState([])

  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    
    if (acceptedFiles?.length) {
        
      acceptedFiles.map((file) => {
        const reader = new FileReader()
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
        const buffer = Buffer.from(reader.result);
        const binaryStr = buffer?.toString('base64')
        setFiles(previousFiles => [
        ...previousFiles,
          {
            name: file.name,
            transformacion: binaryStr
          }])
        }
        reader.readAsArrayBuffer(file)
    })}

    if (rejectedFiles?.length) {
      toast.error("Extensión de fichero no soportada: " + rejectedFiles[0].file.name);
      //setRejected(previousFiles => [...previousFiles, ...rejectedFiles])
    }

  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
        'image/*,.pdf': ['application/pdf']
        // '*.pdf': ['application/pdf']
    },
    multiple: true,
    onDrop
  })

  useEffect(() => {
    handleFicherosAdjuntados(files)
  }, [files])

  const removeFile = name => {
    setFiles(files => files.filter(file => file.name !== name))
  }

  return (
    <div>
      <div
        {...getRootProps({
          className: className
        })}
      >
        <input {...getInputProps()} />
        <div className='flex flex-col items-center justify-center gap-4'>
          
          {isDragActive ? (
            <p>Dejalos caer por aquí</p>
          ) : (
            <p>Arrastra y suelta tus ficheros aqui, o haz click para seleccionarlos</p>
          )}
        </div>
      </div>

      { files !== null && files.length !== 0 ?(
        <div className='double-col-grid'>
          <table className="mini-table">
            <thead>
              <tr className='table-header'>
                <th className='large'>Ficheros Seleccionados</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {files.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td className="flex-horizontal">
                    <IconButton
                      icon="delete"
                      onClick={() => { removeFile(item.name) }}
                    />
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (<div></div>) }
    </div>
  )
}

export default DropZone