import { LibroEstandarApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ApiRequest from "../../../../domain/ApiRequest";
import LibroEstandar from "../../domain/model/LibroEstandar";

export default class LibroEstandarRequestAdd extends ApiRequest<LibroEstandar, LibroEstandar> {
    public constructor(data: LibroEstandar, token: string) {
        super(
            "POST",
            LibroEstandarApiEndpoints.CREAR,
            { Authorization: `${token}` },
            data
        )
    }
}