import React, { useContext } from 'react'
import { GlobalContext, GlobalContextType } from '../../context/Global.Context';
import TagCloud from '../../components/ui/objects/TagCloud/TagCloud';

const EquiposTagCloud = () => {

    const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;
    
    const tags = [
        {
            label: "Nombre",
            text: globalState?.equipos?.filters?.nombre?.length > 0 
            && globalState?.equipos?.filters?.nombre !== undefined 
            ? globalState?.equipos?.filters?.nombre 
            : "",
            onClear: () => {
              updateGlobalState("equipos", {filters: {...globalState.equipos?.filters,"nombre": undefined}})
              updateGlobalState("elementos", {filters: {...globalState.elementos?.filters,"nombre": undefined}})
            }
        },
        {
            label: "Descripción",
            text:  globalState?.equipos?.filters?.descripcion ?? "",
            onClear: () => { 
              updateGlobalState("equipos", {filters: {...globalState.equipos?.filters,"descripcion": undefined}})
              updateGlobalState("elementos", {filters: {...globalState.elementos?.filters,"descripcion": undefined}})
            }
        },
        {
            label: "Delegaciones",
            text: 
            globalState.equipos?.filters?.userDelegacion !== undefined
            ? ""
            : globalState?.equipos?.filters?.delegacionNombre 
            && globalState?.equipos?.filters?.delegacionNombre.length > 0 
            ? globalState?.equipos?.filters?.delegacionNombre 
            :   globalState.elementos?.filters?.userDelegacion !== undefined
            ? ""
            : globalState?.elementos?.filters?.delegacionNombre 
            && globalState?.elementos?.filters?.delegacionNombre.length > 0 
            ? globalState?.elementos?.filters?.delegacionNombre 
            : "",
            onClear: () => { 
              updateGlobalState("equipos", {filters: {...globalState.equipos?.filters,"delegacionNombre": undefined, "delegacionIds": undefined}})
              updateGlobalState("elementos", {filters: {...globalState.elementos?.filters,"delegacionNombre": undefined, "delegacionIds": undefined}})
            }
        },
        {
          label: "Tipos",
          text:  globalState?.elementos?.filters?.tipoNombre && globalState?.elementos?.filters?.tipoNombre.length > 0 ? globalState?.elementos?.filters?.tipoNombre : "",
          onClear: () => { 
            updateGlobalState("elementos", {filters: {...globalState.elementos?.filters,"tipoNombre": undefined, "tipoIds": undefined}})
          }
      },
    ]

  return (
    <TagCloud tags={tags} />
  )
}

export default EquiposTagCloud