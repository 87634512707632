import RowMapper from "../../../../components/TableGridView/model/RowMapper";
import { quitarFechasVacias } from "../../../../utils/DateUtil";
import { formatNumber } from "../../../../utils/NumberUtil";
import { nameof } from "../../../../utils/Utilities";
import AccionComercial, { TipoAccionComercial } from "../../domain/model/AccionComercial";

class LicitacionesRow extends RowMapper<AccionComercial> {
	objectMap(item: AccionComercial): Map<keyof AccionComercial, string> {

		if(item.accionComercialTipo===TipoAccionComercial.AJUSTE)
			{
				return new Map<keyof AccionComercial, string>(
					[
						[nameof<AccionComercial>(item, "accionComercialTipo") as keyof AccionComercial, item.accionComercialTipo],
						[nameof<AccionComercial>(item, "codigoAccion") as keyof AccionComercial, item.codigoAccion?.toString() + "-" + item.accionComercialTipo ?? ""],
						[nameof<AccionComercial>(item, "delegacion") as keyof AccionComercial, item.delegacion],
						[nameof<AccionComercial>(item, "promotor") as keyof AccionComercial, item.promotor],
						[nameof<AccionComercial>(item, "obra") as keyof AccionComercial, item.obra],
						[nameof<AccionComercial>(item, "presupuesto") as keyof AccionComercial,  " - "],
						[nameof<AccionComercial>(item, "toneladas") as keyof AccionComercial, item.toneladas.toString() ?? ""],
						[nameof<AccionComercial>(item, "porcentajeAsfalto") as keyof AccionComercial, " - "],
						[nameof<AccionComercial>(item, "fechaAdjudicacion") as keyof AccionComercial, quitarFechasVacias(item.fechaAdjudicacion?.toLocaleDateString('es-es', { month: '2-digit', day: '2-digit', year: 'numeric', hour12: false })) ?? ""],
						[nameof<AccionComercial>(item, "fecha") as keyof AccionComercial, quitarFechasVacias(item.fecha?.toLocaleDateString('es-es', { month: '2-digit', day: '2-digit', year: 'numeric', hour12: false })) ?? ""],
						[nameof<AccionComercial>(item, "porcentajeBaja") as keyof AccionComercial, " - "],
						[nameof<AccionComercial>(item, "estadoAccionDescripcion") as keyof AccionComercial, item.estadoAccionDescripcion ?? " - "],
					]
				)
			}
			else
			{
				return new Map<keyof AccionComercial, string>(
					[
						[nameof<AccionComercial>(item, "accionComercialTipo") as keyof AccionComercial, item.accionComercialTipo],
						[nameof<AccionComercial>(item, "codigoAccion") as keyof AccionComercial, item.codigoAccion?.toString() + "-" + item.accionComercialTipo ?? ""],
						[nameof<AccionComercial>(item, "delegacion") as keyof AccionComercial, item.delegacion],
						[nameof<AccionComercial>(item, "promotor") as keyof AccionComercial, item.promotor],
						[nameof<AccionComercial>(item, "obra") as keyof AccionComercial, item.obra],
						[nameof<AccionComercial>(item, "presupuesto") as keyof AccionComercial, item.presupuestoFormatted?.toString() ?? ""],
						[nameof<AccionComercial>(item, "toneladas") as keyof AccionComercial, formatNumber(item.toneladas).toString() ?? ""],
						[nameof<AccionComercial>(item, "porcentajeAsfalto") as keyof AccionComercial, item.porcentajeAsfalto?.toString() ?? ""],
						[nameof<AccionComercial>(item, "fechaAdjudicacion") as keyof AccionComercial, quitarFechasVacias(item.fechaAdjudicacion?.toLocaleDateString()) ?? ""],
						[nameof<AccionComercial>(item, "fecha") as keyof AccionComercial, quitarFechasVacias(item.fecha?.toLocaleDateString()) ?? ""],
						[nameof<AccionComercial>(item, "porcentajeBaja") as keyof AccionComercial, item.porcentajeBaja?.toString() ?? ""],
						[nameof<AccionComercial>(item, "estadoAccionDescripcion") as keyof AccionComercial, item.estadoAccionDescripcion ?? " - "]
					]
				)
			}
	}

}

export default LicitacionesRow;