// import { useAuth } from "@pavabits/components";
import { useCallback, useContext } from "react";
import { ConfiguracionGrid } from "../domain/model/ConfiguracionGrid/ConfiguracionGrid";
import ConfiguracionGridRepository from "../domain/ConfiguracionGridsRepository";
import ApiConfiguracionGridsRepository from "../infraestructure/api/ApiConfiguracionGridsRepository";
import { mapEntityArrayToDest } from "../utils/Utilities";
import { usePsQuery } from "@pavabits/components";
import { AuthContext } from "../modules/Login/AuthContextProvider";

export type ColumnTraits = {
	columnName: string,
	sortable: boolean,
	type: string
}

export default function useColumnasGrid(proyecto: string, entity: string) {

	const {getToken} = useContext(AuthContext)

	const recuperaConfiguracion = useCallback(() => {
		const gridConfigRepo: ConfiguracionGridRepository = new ApiConfiguracionGridsRepository(getToken());
		return gridConfigRepo.retrieveConfig({ proyecto, entity });
	}, [entity, proyecto]);

	const { data } = usePsQuery(recuperaConfiguracion);

	const mapDataToDict = (data: Array<ConfiguracionGrid>) => {
		let a = mapEntityArrayToDest<ConfiguracionGrid, ColumnTraits>(data, "attributeName", ["columnName", "sortable", "type"]);
		return a;
	}

	return {
		columns: mapDataToDict(data ?? []),
		cols: data
	}
}