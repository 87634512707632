import { BaseEntity } from "@pavabits/components";
import { set } from "lodash";
import { useEffect, useState } from "react";

export type SelectorType = {
	id: string,
	text: string,
	selected: boolean,
	onClick: (selected: boolean, value: any) => void,
	clearAll:()=>void
}

export default function useSingleSelectorItems<T extends BaseEntity>(
	items: T[],
	currentIds: string[],
	onSuggestionClick: (value: any) => void,
	handleChanged: (value: boolean) => void
) {

	const [selectedItems, setSelectedItems] = useState<Partial<SelectorType>[]>([]);
	const [clickComponent, setclickComponent] = useState<boolean>(false);
	
	
    useEffect(() => {
		if (selectedItems.length > 0) {
			setclickComponent(false);
		}
	 }, [onclick])

	function elaborateText() {
		let text = "";
		selectedItems.forEach((selectedItem, index) => {
			//if (selectedItems[index] !== undefined) {
			if (items != undefined && items.length > 0 && selectedItem !== undefined && selectedItem.id !== "") {
				text += items.find(x => x.id == selectedItem.id)?.text;
			}
			//}
		});
		return text;
	}

	function elaboratePayload(selected: boolean, id: string) {
		if (selected) {
			currentIds =  currentIds.filter((item: any) => item != id);
			return currentIds.filter((item: any) => item != id);
		} else {
			return [id];
		}

	}

	function handleClick(selected: boolean, id: string, text: string) {
		const payload = elaboratePayload(selected, id);
		onSuggestionClick(payload);
		handleChanged(false);
		if (payload.length > 0) {
			//HAY QUE COMPROBAR PRIMERO SI ES QUITAR O PONER
			if (!selected) {
				setSelectedItems([{ id, text }]); 
			} else {
				setSelectedItems([]);
			}

		}
		else {
			setSelectedItems([]);
		} 
	}

	function elaborateSuggestions() {
		let temporalSuggestions: SelectorType[] = [];

		items && items.forEach(item => {
			const id = currentIds;
			const isSelected = currentIds.map(i => i.toString()).includes(item.id.toString());
			
			const nextSuggestion = {
				id: item.id,
				text: item.text,
				selected: isSelected,
				onClick: (selected: boolean) => handleClick(isSelected, item.id, item.text),
				clearAll
			}

			if (isSelected) {
				temporalSuggestions.unshift(nextSuggestion);
			} else {
				temporalSuggestions.push(nextSuggestion);
			}
			
		});

		return temporalSuggestions;
	}

	function clearAll() {
		setSelectedItems([]);
	}

	return {
		suggestions: elaborateSuggestions(),
		text: elaborateText(),
		clearAll,
		selected:currentIds?.length>0
	}
}