import { useContext } from 'react';
import Dropdown from '../../../../components/ui/atoms/Dropdown/Dropdown';
import FormFieldGroup from '../../../../components/ui/objects/FormFieldGroup/FormField.Group';
import { EstadoOportunidadDropdownContext } from "../../context/FilterFields.Context";

interface EstadoOportunidadDropdownProps {
  excludedId?: number ;
  idTest?: string;
}

const EstadoOportunidadDropdown : React.FC<EstadoOportunidadDropdownProps> = ({excludedId, idTest}) => {

  const options = useContext(EstadoOportunidadDropdownContext);

  return (
    <div className="input-container"> 
    <FormFieldGroup>
      <label>Estado de Oportunidad</label>
      <Dropdown
        options={options.filter(p=>p.id!=excludedId?.toString())}
        label={idTest}
      />
    </FormFieldGroup>
</div>
  )
}

export default EstadoOportunidadDropdown