interface ComercialEntity  {
	id: number,
	descripcion:string,
	delegacionId:number,
	mail?:string,
}


export default class Comercial implements ComercialEntity {

	id: number;
	descripcion:string;
	delegacionId:number;
	mail?:string;


	constructor(entity: ComercialEntity) {
		
		this.id = entity.id;
		this.descripcion = entity.descripcion;
		this.delegacionId = entity.delegacionId;
		this.mail = entity.mail;
	}

	public static toArray(entities: ComercialEntity[]): Comercial[] {
		return entities.map(entity => { return new Comercial(entity) });
	}
}

export type ComercialDtoFilter = {
    filter: {
        descripcion?: string;
		delegacionId: number;
    }
};
export type ComercialDto = Comercial;
export type ComercialCreationDto = Comercial;
export type ComercialUpdateDto = Comercial;
export interface ComercialDeleteDto  { 
	errors:string[]
	};