import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { UnidadObraApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { UnidadReadDto } from "../../domain/model/Unidad";

export default class UnidadesRequestFetchAll extends ApiRequest<any, BaseResponse<UnidadReadDto[]>>
{
    public constructor (token: string, params?: {[key: string]: number | string}) {
        super (
            "GET",
            UnidadObraApiEndpoints.ALL,
            {Authorization: `${token}`},
            {},
            params ?? ""
        )
    }
}