import ApiRequest from "../../../../domain/ApiRequest";
import { UnidadObraApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { UnidadReadDto } from "../../domain/model/Unidad";

export default class UnidadesRequestGetById extends ApiRequest<{}, UnidadReadDto>
{
	constructor(id: string, token: string) {
		super(
			"GET",
			UnidadObraApiEndpoints.CON_ID + id,
			{ Authorization: token },
			{}
		)
	}
}