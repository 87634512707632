import BaseResponse from "../../../../domain/BaseResponse";
import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";
import EstudioDetalleAvanzadoEquipo, { EstudioDetalleAvanzadoEquipoDto } from "../../../Estudios/domain/model/EstudioDetalleAvanzadoEquipo";
import EstudioDetalleAvanzadoEquipoRepository from "../../domain/EstudioDetalleAvanzadoEquipoRepository";
import EstudioDetalleAvanzadoEquipoRequestDelete from "../ws/EstudioDetalleAvanzadoEquipo.Request.Delete";


export default class EstudioDetalleAvanzadoEquipoApiRepository
	extends ApiRepositoryBase<
		EstudioDetalleAvanzadoEquipoDto,
		EstudioDetalleAvanzadoEquipo
	> implements  EstudioDetalleAvanzadoEquipoRepository {

	
	
	async delete(id: string): Promise<EstudioDetalleAvanzadoEquipoDto> {
		return await new EstudioDetalleAvanzadoEquipoRequestDelete(id, this.authToken).executeAsPromise();
		
	}

	buildModelFromItems(item:  EstudioDetalleAvanzadoEquipoDto):  EstudioDetalleAvanzadoEquipo {
		return new  EstudioDetalleAvanzadoEquipo(item);
	}

	protected transformDtoIntoModel(dto: BaseResponse< EstudioDetalleAvanzadoEquipoDto>): BaseResponse<EstudioDetalleAvanzadoEquipo> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}
}