import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { toast } from 'react-toastify';
import CustomTextInput from '../../../components/CustomTextInput';
import FormTemplate from '../../../components/common/FormTemplate/FormTemplate';
import IconButton from '../../../components/ui/atoms/Buttons/IconButton';
import Dropdown, { DropdownProps } from '../../../components/ui/atoms/Dropdown/Dropdown';
import FormField from '../../../components/ui/molecules/Form-field/FormField';
import { useLoading } from '../../../context/Loading.Context';
import DelegacionesRepository from '../../../domain/DelegacionesRepository';
import UnidadesMedidaRepository from '../../../domain/UnidadesMedidaRepository';
import { DelegacionDto } from '../../../domain/model/Delegacion';
import { UnidadMedidaDto } from '../../../domain/model/UnidadMedida';
import DelegacionesApiRepository from '../../../infraestructure/api/Delegaciones.ApiRepository';
import UnidadesMedidaApiRepository from '../../../infraestructure/api/UnidadesMedida.ApiRepository';
import { AuthContext } from '../../Login/AuthContextProvider';
import UnidadesRepository from '../domain/UnidadesRepository';
import { UnidadReadDto } from '../domain/model/Unidad';
import UnidadesApiRepository from '../infraestructure/api/UnidadesApiRepository';
import EquiposUnidadView from './common/EquiposUnidadView';
import MaterialesUnidadView from './common/MaterialesUnidadView';
import { VentanaPermiso } from '../../Login/domain/model/VentanaPermiso';
import { COD_DELEGACIONES_SIN_TODAS, USER_ROLES } from '../../../assets/constants/Constantes';
import TooltipComponent from '../../../components/ui/molecules/Tooltip/TooltipComponent';

interface Props {
  permisoVentana: VentanaPermiso
}

const UnidadesEdicion: React.FC<Props> = ({ permisoVentana }: Props) => {
  const location = useLocation();
  const [medidas, setMedidas] = useState<UnidadMedidaDto[]>([]);
  const [descripciones, setDescripciones] = useState<any>([]);
  const [delegaciones, setDelegaciones] = useState<DelegacionDto[]>([]);
  const { getToken, getDelegacion, hasRole } = useContext(AuthContext);
  const [permisoModificacion, setPermisoModificacion] = useState<boolean>(permisoVentana.permisoModificacion);
  const { setLoading } = useLoading();
  const url = location.pathname.split("/")
  const id = url[url.length - 1];
  const [unidadObra, setUnidadObra] = useState<UnidadReadDto>({
    descripcionUnidadObra: [],
    delegacionId: +(getDelegacion()?.id),
    delegacion: null,
    descripcion: ""
  });

  const [newDescripcion, setNewDescripcion] = useState({
    unidadMedidaId: 1,
    unidadObraId: "",
    descripcion: ""
  })

  const formId = "unidades-edit-form";
  const fetchUnidadObra = useCallback((id: string) => {
    const repo: UnidadesRepository = new UnidadesApiRepository(getToken());
    setLoading(true);
    return repo.getById(id).then((data) => {
      setUnidadObra({ ...data, delegacionId: data.delegacion ? +data.delegacion?.id : -1 });
      // Si el usuario no es Admin puede ver datos de todas las delegaciones pero sólo crear y editar datos de su delegación
      setPermisoModificacion(permisoModificacion && (hasRole(USER_ROLES.ADMIN) || data.delegacion?.id === getDelegacion()?.id));
    })
      .catch(message => toast.error(message))
      .finally(() => { setLoading(false); });
  }, [getToken()]);

  useEffect(() => {
    fetchMedidas()
    fetchDelegaciones()
    fetchDescripciones(id)
  }, [id])

  useEffect(() => { // Side Effect
    fetchUnidadObra(id)
  }
    , [id, delegaciones]);

  const fetchMedidas = useCallback(() => {
    const repo: UnidadesMedidaRepository = new UnidadesMedidaApiRepository(getToken());
    return repo.getConFiltro({}).then((data) => {
      setMedidas(data);
    })
      .catch(message => toast.error(message));
  }, []);

  const fetchDelegaciones = useCallback(() => {
    const repo: DelegacionesRepository = new DelegacionesApiRepository(getToken());
    return repo.fetchDelegacionesDto({ filter: { ids: COD_DELEGACIONES_SIN_TODAS } }).then((data) => {
      const delegacionesUpd = data;
      delegacionesUpd.push({ descripcion: "TODAS", id: "-1" })
      setDelegaciones(delegacionesUpd);
    })
      .catch(message => toast.error(message));
  }, []);


  const fetchDescripciones = async (id: string) => {
    const repo: UnidadesRepository = new UnidadesApiRepository(getToken());
    try {
      const res = await repo.getDescripcionesByUnidad(id);
      setDescripciones(res?.items)
    } catch (error) {
      console.error(error)
    }
  }

  const handleEditUnidad = (event: ChangeEvent<HTMLInputElement>) => {
    setUnidadObra(prev => ({ ...prev, descripcion: event.target.value }))
  }

  const handleNewDescripcion = (event: ChangeEvent<HTMLInputElement>) => {
    setNewDescripcion(prev => ({ ...prev, descripcion: event.target.value }))
  }

  const handleSelectMedida = (unidadMedida: UnidadMedidaDto) => {
    const medidaObj: any = medidas.find((item: any) => item.id === unidadMedida.id);
    setNewDescripcion(prev => ({ ...prev, unidadMedidaId: medidaObj.id }))
  }

  const onAddDescripcion = async () => {
    const repo: UnidadesRepository = new UnidadesApiRepository(getToken());
    try {
      if (newDescripcion.descripcion == "") {
        toast.error("La descripción no puede estar vacía");
        return;
      }
      await repo.addDescripcionAUnidad({ ...newDescripcion, unidadObraId: id }).then((resp) => {
        toast.success("Descripción añadida correctamente.");
      })
    } catch (err) {
      toast.error("No ha sido posible añadir la descripción.");
      console.error(err)
    }
    setNewDescripcion({
      unidadMedidaId: 1,
      unidadObraId: "",
      descripcion: ""
    }
    )
    fetchDescripciones(id)
  }

  const onDeleteDescription = async (removeId: string) => {
    try {
      const repo: UnidadesRepository = new UnidadesApiRepository(getToken());
      await repo.deleteDescripcionUnidad(removeId).then((resp) => {
        toast.success("Descripción eliminada correctamente.");
      })
    } catch (err) {
      toast.error("No se ha podido eliminar la descripción.");
      console.error(err);
    }

    fetchDescripciones(id)
  }

  const editDelegacion = (delegacion: DelegacionDto) => {
    const delegacionObj: DelegacionDto | undefined = delegaciones.find(((item: DelegacionDto) => item.id === delegacion.id));
    const unidadUpd = { ...delegacionObj, delegacionId: delegacionObj?.id ? +delegacionObj.id : null }
    setUnidadObra(prev => ({ ...prev, delegacionId: unidadUpd.delegacionId }))
  }

  const handleSubmit = async (event: any) => {

    if (event.target?.id == 'equipos-form')
      return;

    event.preventDefault();
    let delegacionSelected;
    if (!unidadObra.delegacion) {
      delegacionSelected = -1;
    } else {
      delegacionSelected = unidadObra.delegacion.id;
    }

    const repo: UnidadesRepository = new UnidadesApiRepository(getToken())
    try {
      const unidadUpd = { ...unidadObra, delegacionId: unidadObra && unidadObra?.delegacionId != -1 ? unidadObra.delegacionId : null };
      setLoading(true);
      repo.update(unidadUpd)
        .then(() => {
          toast.success("Unidad actualizada con éxito");
          setUnidadObra(unidadObra)
        })
        .finally(() => {
          setLoading(false);
        })
    } catch (err) {
      console.error(err)
      toast.error("Error al actualizar la unidad");
    }
  }

  return (
    <div className='add-edit-view-container'>
      <div className='add-edit-header'>
        <h4>{permisoModificacion ? "Editar " : ""}Unidad de Obra</h4>
      </div>
      <div className="add-edit-body">
        <FormTemplate
          formId={formId}
          onSubmit={(handleSubmit)}
          readOnly={!permisoModificacion}>

          <div className="row-of-two">

            <CustomTextInput
              label="Unidad de obra"
              name="descripcion"
              value={unidadObra.descripcion}
              onChange={handleEditUnidad}
              idTest="UnidadObraEditar"
              required />

            {delegaciones &&
              <FormField<DropdownProps>
                label="Delegación"
                options={delegaciones.filter(x => hasRole(USER_ROLES.ADMIN) || +x.id === unidadObra.delegacionId).map(
                  (element) => {
                    return {
                      text: element.descripcion,
                      id: element.id,
                      selected: unidadObra.delegacionId == +element.id ? true : false,
                      onClick: () => { editDelegacion(element); }
                    }
                  })}
                required={true}
                disabled={unidadObra && !!unidadObra.calculoAvanzadoId}
                singleSelection={true}
                component={Dropdown}
              />
            }
          </div>
          <div className="row-of-two">
           
              <CustomTextInput
                label="Descripción"
                idTest="DescripcionUnidadObraEditar"
                name="descripcion1"
                value={newDescripcion.descripcion}
                onChange={handleNewDescripcion}
                required={false} />
            <div className='unidad-medida'>
              <FormField<DropdownProps>
                label="Unidad Medida"
                options={medidas.map(
                  (element) => {
                    return {
                      text: element.descripcion,
                      id: element.id,
                      selected: newDescripcion.unidadMedidaId == +element.id ? true : false,
                      onClick: () => { handleSelectMedida(element); }
                    }
                  })}
                required={true}
                disabled={false}
                singleSelection={true}
                component={Dropdown}
              />
              <div className="abs-right unidad-medida-save-icon">
                <IconButton
                  icon="done"
                  onClick={onAddDescripcion}
                />
              </div>
            </div>

          </div>

          {
            descripciones &&
            (
              <table className='mini-table'>
                <thead>
                  <tr className='table-header'>
                    <th>Descripción</th>
                    <th>Medida</th>
                  </tr>
                </thead>
                <tbody>
                  {descripciones.map((item: any, index: number) => (
                    <tr key={index}>
                      <td data-cy={"DescripcionTabla"+index}>
                        <TooltipComponent text={item.descripcion}>
                          {item.descripcion}
                        </TooltipComponent>
                      </td>
                      <td style={{ width: "60px" }} data-cy={"MedidaTabla"+index}>{item.unidadMedida.descripcion}</td>
                      <td style={{ width: "20px" }}><IconButton
                        icon="delete"
                        index={"Tabla"+index}
                        onClick={() => onDeleteDescription(item.id)}
                        disabled={descripciones.length == 1}
                      /></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )
          }
          <div className="tabs-container">


            <Tabs>
              <TabList>
                {(unidadObra.calculoAvanzadoId != 0 && unidadObra.calculoAvanzadoId != 2 && unidadObra.calculoAvanzadoId != 3 && unidadObra.calculoAvanzadoId != 4) && <Tab>Material</Tab>}
                <Tab data-cy="EquiposTab">Equipos</Tab>                {/* <Tab>Transporte</Tab> */}
              </TabList>
              {(unidadObra.calculoAvanzadoId != 0 && unidadObra.calculoAvanzadoId != 2 && unidadObra.calculoAvanzadoId != 3 && unidadObra.calculoAvanzadoId != 4) &&
                <TabPanel>
                  <div className="tabs-unidadObra">
                    <MaterialesUnidadView
                      handleOnSubmit={() => { }}
                      unidadObraId={id}
                    ></MaterialesUnidadView>
                  </div>
                </TabPanel>
              }
              <TabPanel>
                <div className="tabs-unidadObra">
                  <EquiposUnidadView
                    handleOnSubmit={() => { }}
                    delegacionId={unidadObra.delegacionId!}
                    unidadObraId={id} />
                </div>
              </TabPanel>
              <TabPanel>
                {
                  <p>Transporte</p>
                }
              </TabPanel>
            </Tabs>
          </div>
        </FormTemplate>
      </div>
    </div>
  )
}

export default UnidadesEdicion