import React, { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DropZone from '../../../../../components/common/DragDrop/DropZone';
import IconButton from '../../../../../components/ui/atoms/Buttons/IconButton';
import Dropdown, { DropdownProps } from '../../../../../components/ui/atoms/Dropdown/Dropdown';
import FormInput from '../../../../../components/ui/atoms/Input/FormInput/FormInput';
import ModalPage from '../../../../../components/ui/atoms/ModalPage/ModalPage';
import FormField from '../../../../../components/ui/molecules/Form-field/FormField';
import { useLoading } from '../../../../../context/Loading.Context';
import { CustomButton } from '../../../../../shared/components/Buttons/CustomButton';
import { formatDate2input, formatUsDate2String } from '../../../../../utils/DateUtil';
import { validateEmail } from '../../../../../utils/Utilities';
import ClausulasOfertaRepository from '../../../../ClausulasOferta/domain/ClausulasOfertaRepository';
import ClausulaOferta from '../../../../ClausulasOferta/domain/model/ClausulaOferta';
import ClausulasOfertaApiRepository from '../../../../ClausulasOferta/infrastructure/api/ClausulasOfertaApiRepository';
import ContactosRepository from '../../../../Clientes/domain/ContactosRepository';
import ContactosCliente from '../../../../Clientes/domain/model/ContactosCliente';
import ContactosClientesApiRepository from '../../../../Clientes/infraestructure/api/ContactosClientes.ApiRepository';
import ContactoModalCreateSection from '../../../../Contactos/ContactoModalCreateSection';
import Estudio from '../../../../Estudios/domain/model/Estudio';
import EstudioDetalle from '../../../../Estudios/domain/model/EstudioDetalle';
import { AuthContext } from '../../../../Login/AuthContextProvider';
import PresupuestoApiRepository from '../../../../Presupuestos/Infraestructure/api/Presupuesto.ApiRepository';
import PresupuestoRepository from '../../../../Presupuestos/domain/PresupuestoRepository';
import FicherosAdjuntos from '../../../../Presupuestos/domain/model/FicherosAdjuntos';
import Presupuesto, { PresupuestoDto } from '../../../../Presupuestos/domain/model/Presupuesto';
import PresupuestoClausula from '../../../../Presupuestos/domain/model/PresupuestoClausula';
import PresupuestoContacto from '../../../../Presupuestos/domain/model/PresupuestoContacto';
import { CalculoAvanzadoTipo } from '../../../../Unidades/domain/model/Unidad';
import ClonarOfertas, { ClienteContactoOferta } from '../../../domain/model/ClonarOfertas';
import Ofertas from '../../../domain/model/Ofertas';
import TooltipComponent from '../../../../../components/ui/molecules/Tooltip/TooltipComponent';

interface GenerarPresupuestoModalSectionProps {
	opened?: boolean;
	estudios?: Estudio[];
	oferta: Ofertas;
	presupuestoId?: string;
	handleCloseModal: () => void;
	handleRefresh: (number?: any) => void;
}

const GenerarPresupuestoModalSection: React.FC<GenerarPresupuestoModalSectionProps> = ({ opened, estudios, oferta, presupuestoId, handleCloseModal, handleRefresh }) => {
	const UNIDAD_MEDIDA_TM: string = "1";
	const { getToken, getDelegacion } = useContext(AuthContext);
	const { setLoading } = useLoading();
	const [loadedNewContacto, setLoadedNewContacto] = useState<boolean>(false);
	const [loaded, setLoaded] = useState<boolean>(true);
	const [presupuesto, setPresupuesto] = useState<PresupuestoDto>(new Presupuesto({
		ofertaId: oferta.id,
		promotorId: oferta.promotorId,
		clienteId: oferta.clienteId,
		fechaGeneracion: formatUsDate2String(new Date()),
		presupuestoClausulas: [],
		presupuestoContactos: [],
		estudiosId: [],
		delegacion: getDelegacion(),
		totalPresupuesto: '0',
		presupuestoId: 0,
		estudioIds: "",
		presupuestoVersion: 0,
		urlPdfGenerado: "",
		contratado: false,
		lastModificationTime: "",
		porOpciones: false,
		updateToneladasOportunidad: false
	}));
	const [contactos, setContactos] = useState<ContactosCliente[]>([])
	const [clausulasPresupuesto, setClausulasPresupuesto] = useState<PresupuestoClausula[]>([])
	const [clausulasOferta, setClausulasOferta] = useState<ClausulaOferta[]>([]);
	const [clausulasDrop, setClausulasDrop] = useState<ClausulaOferta[]>([])
	const [borradorPdfUrl, setBorradorPdfUrl] = useState<string>();
	const [openContactoModal, setOpenContactoModal] = useState<boolean>(false);
	const [contactosGrid, setContactosGrid] = useState<PresupuestoContacto[]>([])
	const [ficherosAdjunto, setFicherosAdjunto] = useState<FicherosAdjuntos[]>([]);
	const [clienteContactoOferta, setClienteContactoOferta] = useState<ClienteContactoOferta[]>([]);
	const [openUpdateToneladasOportunidad, setOpenUpdateToneladasOportunidad] = useState<boolean>(false);
	const [envioPresupuesto, setEnvioPresupuesto] = useState<boolean>(false);
	const setFecha = (newDate: string) => {
		setPresupuesto({ ...presupuesto, fechaGeneracion: newDate });
	};

	const fetchClausulasOferta = useCallback(
		(id: string) => {
			const repo: ClausulasOfertaRepository = new ClausulasOfertaApiRepository(getToken());
			return repo.getByOfertaId({ filters: { ofertaId: id }, sortingCriteria: "orden, clausula.delegacionId desc, descripcion", maxResultCount: 100 }).then((clausulas: ClausulaOferta[]) => {
				setLoading(true)
				setClausulasOferta(clausulas);
				let delegacionId = +presupuesto.delegacion.id;

				if (delegacionId > 0) {
					let clausulaObjs = [...clausulasPresupuesto];
					clausulas.map((clausula) => {
						let pc = mapToPresupuestoClausulaByClausulaOferta(clausula);
						clausulaObjs.push(pc);
					})

					setClausulasPresupuesto(clausulaObjs);
				}
			}).finally(() => setLoading(false));
		}
		, [getToken, clausulasOferta]);

	const fetchContactos = useCallback((id?: string) => {
		const repo: ContactosRepository = new ContactosClientesApiRepository(getToken());
		return repo.getByClienteId(+oferta.clienteId)
			.then((items) => {
				if (id) {
					let newItem = items.find(x => x.id === id);
					setContactosGrid([...contactosGrid, { presupuestoId: presupuesto.id, contactoId: id, personaContacto: newItem?.personaContacto ?? "", mail: newItem?.mail ?? "" }]);
				} else {
					setContactos(items);
				}
			});
	}, [getToken, contactosGrid]);

	useEffect(() => {
		const fetchData = async () => {
			fetchClausulasOferta(oferta.id);
			fetchContactos();
		};

		fetchData();
	}, []);

	useEffect(() => {
		if (!presupuestoId) {
			setLoaded(true);
			return;
		}

		if (clausulasOferta && contactos) {
			fetchPresupuesto(presupuestoId, contactos);
		}
	}, [clausulasOferta]);

	const fetchPresupuesto = (presupuestoId: string, contactosCliente: ContactosCliente[]) => {
		const repo: PresupuestoRepository = new PresupuestoApiRepository(getToken());
		setLoading(true);

		return repo.getById(presupuestoId)
			.then((resp) => {
				let presupuestoUpd = { ...resp, fechaGeneracion: formatDate2input(resp.fechaGeneracion), lastModificationTime: resp.lastModificationTime };
				setPresupuesto(presupuestoUpd);
				setClausulasDrop(clausulasOferta?.filter(x => !resp.presupuestoClausulas.find((p) => x.id === p.clausulaId)));
				setClausulasPresupuesto(resp.presupuestoClausulas);
				filterContactos(contactosCliente, resp.presupuestoContactos);
				setLoaded(true);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	function filterContactos(contactos: ContactosCliente[], presupuestoContactos: PresupuestoContacto[]) {
		for (const contacto of presupuestoContactos) {
			let index = contactos.findIndex(x => x.id === contacto.contactoId);
			if (index >= 0) {
				contacto.personaContacto = contactos[index].personaContacto;
				contacto.mail = contactos[index].mail;
				contactos.splice(index, 1);
			}
		}

		setContactos(contactos);
		if (presupuestoContactos.length > 0) {
			setContactosGrid(presupuestoContactos);
		}
	}

	const mapToPresupuestoClausulaByClausulaOferta = (entity: ClausulaOferta): PresupuestoClausula => {
		return new PresupuestoClausula({
			clausulaId: entity.id!,
			delegacionId: entity.clausulaId ? entity.clausula?.delegacionId : oferta.delegacionId,
			delegacionNombre: entity.clausulaId ? entity.clausula?.delegacionNombre : oferta.delegacionDescripcion,
			descripcion: entity.descripcion,
			nombre: entity.clausula?.nombre ?? entity.nombre,
			presupuestoId: presupuestoId,
			orden: entity.clausula?.orden ?? entity.orden,
			negrita: entity.negrita
		});
	}

	const handleClickClausulas = (clausula: ClausulaOferta) => {
		setClausulasDrop(clausulasDrop.filter(x => x.id != clausula.id));
		setClausulasPresupuesto([...clausulasPresupuesto, mapToPresupuestoClausulaByClausulaOferta(clausula)]);
	}

	const borrarClausula = (id: string) => {
		setClausulasPresupuesto(clausulasPresupuesto.filter(x => x.clausulaId !== id));
		setClausulasDrop([...clausulasDrop, clausulasOferta.find(x => x.id == id)!])

		toast.success(`Cláusula eliminada correctamente`);
	}

	const borrarPresupuestoContacto = (item: PresupuestoContacto, index: number) => {
		setContactos([...contactos, {
			id: item.contactoId, personaContacto: item.personaContacto, mail: item.mail,
			nombreCompleto: '',
			cargo: '',
			telefono: '',
			usuario: '',
			municipio: '',
			direccion: '',
			clienteId: 0,
			clienteNombre: '',
			zona: '',
			lastModificationTime: '',
			lastModificationUsername: '',
			firmante: false,
			nif: ''
		}]);

		setContactosGrid(contactosGrid.filter((x, i) => i !== index));

		toast.success(`Contacto eliminado correctamente`);
	}

	const handleClickContactos = (contacto: ContactosCliente) => {
		setContactos(contactos.filter(x => x.id !== contacto.id));
		setContactosGrid([...contactosGrid, { presupuestoId: presupuesto.id, contactoId: contacto.id, id: undefined, personaContacto: contacto.personaContacto, mail: contacto.mail }]);
	}

	const calculoTotalPresupuesto = (estudios: Estudio[]) => {
		const totalVentas = (estudios && estudios.reduce((partialSum, a) => partialSum + (a.totalVenta ?? 0), 0)) ?? '0';
		const gastos = (totalVentas) * (oferta.gastos !== undefined && oferta.gastos > 0 ? (oferta.gastos / 100) : 0);
		const beneficio = (totalVentas) * (oferta.beneficio !== undefined && oferta.beneficio > 0 ? oferta.beneficio / 100 : 0);
		const baseImponible = ((totalVentas) + (beneficio) + (gastos));
		const descuento = (baseImponible) * (oferta.descuento !== undefined && oferta.descuento > 0 ? oferta.descuento / 100 : 0);
		const baseImpDescuento = ((baseImponible) - descuento);
		const totalVentaIva = (baseImpDescuento) * (oferta.iva !== undefined && oferta.iva > 0 ? oferta.iva / 100 : 0);

		return Number((baseImpDescuento) + totalVentaIva).toFixed(2)
	}

	const handleGenerarOEnviar = (actualizarTonOportunidad: boolean, enviar: boolean = false) => {
		if (envioPresupuesto || enviar) {
			guardarYEnviar(actualizarTonOportunidad);
		} else {
			generarPresupuestoCall(actualizarTonOportunidad);
		}
	}

	const generarPresupuestoCall = (actualizarTonOportunidad: boolean) => {
		const presupuestoRepo: PresupuestoRepository = new PresupuestoApiRepository(getToken());
		let totalPresupuesto = calculoTotalPresupuesto(estudios!);

		if (presupuestoId) {
			setLoading(true);
			presupuestoRepo.update({ ...presupuesto, presupuestoClausulas: clausulasPresupuesto, presupuestoContactos: contactosGrid, estudiosId: estudios!.map(item => { return item.id }), id: presupuestoId, totalPresupuesto: totalPresupuesto, updateToneladasOportunidad: actualizarTonOportunidad })
				.then((resp) => {
					toast.success('Presupuesto actualizado correctamente');
					handleRefresh();
				}).finally(() => {
					setLoading(false);
				});
		} else {
			setLoading(true);
			presupuestoRepo.add({ ...presupuesto, fechaEnvio: undefined, presupuestoClausulas: clausulasPresupuesto, estudiosId: estudios!.map(item => { return item.id }), totalPresupuesto: totalPresupuesto, presupuestoContactos: contactosGrid, updateToneladasOportunidad: actualizarTonOportunidad })
				.then((resp) => {
					toast.success('Presupuesto generado correctamente');
					handleRefresh(2);
				}).finally(() => {
					setLoading(false);
				});
		}
		setOpenUpdateToneladasOportunidad(false);
	}

	const guardarYEnviar = (actualizarTonOportunidad: boolean) => {
		const presupuestoRepo: PresupuestoRepository = new PresupuestoApiRepository(getToken());
		var totalPresupuesto = calculoTotalPresupuesto(estudios!);
		if (presupuestoId) {
			setLoading(true);
			presupuestoRepo.update({ ...presupuesto, presupuestoClausulas: clausulasPresupuesto, presupuestoContactos: contactosGrid, estudiosId: estudios!.map(item => { return item.id }), id: presupuestoId, totalPresupuesto: totalPresupuesto, updateToneladasOportunidad: actualizarTonOportunidad })
				.then((resp) => {
					toast.success('Presupuesto actualizado correctamente');
					presupuestoRepo.enviar(resp.id!, oferta.id, ficherosAdjunto).then((resp) => {
						toast.success('Presupuesto enviado correctamente');
						handleRefresh();
					});
				}).finally(() => {
					setLoading(false);
				});
		} else {
			setLoading(true);
			presupuestoRepo.add({ ...presupuesto, presupuestoClausulas: clausulasPresupuesto, estudiosId: estudios!.map(item => { return item.id }), totalPresupuesto: totalPresupuesto, presupuestoContactos: contactosGrid, updateToneladasOportunidad: actualizarTonOportunidad })
				.then((resp) => {
					toast.success('Presupuesto generado correctamente');
					presupuestoRepo.enviar(resp.id!, oferta.id, ficherosAdjunto)
						.then((resp) => {
							toast.success('Presupuesto enviado correctamente');
							handleRefresh(2);
						});
				}).finally(() => {
					setLoading(false);
				});
		}
		setOpenUpdateToneladasOportunidad(false);
	}

	const generarBorrador = () => {
		const repo: PresupuestoRepository = new PresupuestoApiRepository(getToken());
		setLoading(true);

		let clienteContacto: ClienteContactoOferta[] = clienteContactoOferta
		contactosGrid.forEach((element, index) => {
			var contacto: ClienteContactoOferta = new ClienteContactoOferta(+oferta.clienteId, oferta.clienteDescripcion, element.contactoId, element.personaContacto)
			clienteContacto.push(contacto)
		});

		setClienteContactoOferta(clienteContacto);
		repo.borradorPresupuesto(new ClonarOfertas(oferta.id, presupuestoId === undefined || presupuestoId === null ? estudios!.map(item => { return item.id }) : presupuesto.estudiosGuids!, clausulasPresupuesto.map(item => { return item.clausulaId }), false, presupuesto.porOpciones, clienteContacto)).then((response) => {
			setBorradorPdfUrl(response.replace("http://", "https://"));
			toast.success(`Borrador generado correctamente`)
		}).finally(() => {
			setLoading(false);
		});
	}

	const handleNewContacto = () => {
		setOpenContactoModal(!openContactoModal);
		setLoadedNewContacto(!loadedNewContacto)
	}

	const handleCreateContact = (id: string) => {
		setOpenContactoModal(false);
		fetchContactos(id);
	}

	const handleFicheroAdjuntoSelected = (adjuntos: FicherosAdjuntos[]) => {
		setFicherosAdjunto(adjuntos)
	};

	const handlePorOpciones = async () => {
		setPresupuesto({ ...presupuesto, porOpciones: !presupuesto.porOpciones })
	};

	// Sort clausulas by order and then by delegacion, descripcion
	const sortClausulas = (a: PresupuestoClausula, b: PresupuestoClausula) => {
		let sortResult = a.orden! - b.orden!;

		if (sortResult === 0) {
			if (!a.delegacionId && b.delegacionId) {
				sortResult = -1;
			} else if (a.delegacionId && !b.delegacionId) {
				sortResult = 1;
			}
		}
		
		if (sortResult === 0) sortResult = a.descripcion.localeCompare(b.descripcion);

		return sortResult;
	};

	const calcularToneladas = () => {
		let sumatorio = 0;

		if (presupuesto.porOpciones) {
			estudios?.map(item => {
				let sumInd = 0;
				if (item.estudioDetalle.find(x => x.unidadObra?.calculoAvanzadoId == CalculoAvanzadoTipo.MBC)) {
					item.estudioDetalle.map(subitem => subitem.unidadObra?.calculoAvanzadoId == CalculoAvanzadoTipo.MBC ? sumInd += getToneladasEstudioDetalle(subitem) : sumInd);
					sumatorio = Math.max(sumatorio, sumInd);
				}

			});
		} else {
			estudios?.map(item => {
				if (item.estudioDetalle.find(x => x.unidadObra?.calculoAvanzadoId == CalculoAvanzadoTipo.MBC)) {
					item.estudioDetalle.map((subitem => subitem.unidadObra?.calculoAvanzadoId == CalculoAvanzadoTipo.MBC ? sumatorio += getToneladasEstudioDetalle(subitem) : sumatorio));
				}
			});
		}

		return sumatorio;
	};

	const getToneladasEstudioDetalle = (estudioDetalle: EstudioDetalle) => {
		let toneladas: number = 0;

		if (estudioDetalle.estudioDetalleAvanzado) {
			const toneladasMaterial = estudioDetalle.estudioDetalleAvanzado.material?.filter(x => x.orden === estudioDetalle.orden || estudioDetalle.estudioDetalleAvanzado?.totalizar)?.reduce(function (acc, obj) { return acc + +obj.toneladas; }, 0);
			toneladas = toneladasMaterial ? +toneladasMaterial : 0;
		} else if (estudioDetalle.unidadMedidaId.toString() === UNIDAD_MEDIDA_TM) {
			toneladas = estudioDetalle.medicion;
		}

		return toneladas;
	};

	const guardarPresupuesto = (enviar: boolean): void => {
		setEnvioPresupuesto(enviar);
		const toneladasEstudio = calcularToneladas();

		if (toneladasEstudio > 0 && oferta?.oportunidadToneladas !== toneladasEstudio) {
			if (oferta.tipoOfertaId != 5) {
				setOpenUpdateToneladasOportunidad(true);
			} else {
				handleGenerarOEnviar(true, enviar);
			}
		} else {
			handleGenerarOEnviar(false, enviar);
		}
	};

	return (
		<div>
			{loaded &&
				<ModalPage
					handleCloseModal={handleCloseModal}
					opened={opened}
					fields={
						<>
							<div className="double-col-grid">
								<div className='estudios-selected'>
									<label>Estudios Seleccionados</label>
									<div>
										{estudios ?
											estudios?.map((item: Estudio, index: number) => <p key={index}>{item.estudioId}</p>)
											: <p key="h">{presupuesto?.estudioIds}</p>}
									</div>
								</div>

								<FormInput
									label="Fecha"
									value={presupuesto.fechaGeneracion}
									onChange={(e) => setFecha(e.target.value)}
									type="date"
								/>

								{estudios?.length! > 1 ? <div className="import-study">
									<label>Presupuesto por opciones</label>
									<input
										onChange={handlePorOpciones}
										type="checkbox"
										checked={presupuesto.porOpciones}
									/>
								</div>
									:
									<div></div>
								}
							</div>

							<div className="double-col-grid">
								{clausulasDrop &&
									<FormField<DropdownProps>
										label="Cláusulas"
										options={clausulasDrop!.map(
											(element) => {
												return {
													text: element.clausula?.nombre ?? element.nombre,
													id: element.id,
													onClick: () => { handleClickClausulas(element); }
												}
											})}
										required
										disabled={false}
										singleSelection={true}
										component={Dropdown}
									/>
								}

								{contactos &&
									<FormField<DropdownProps>
										label="Contactos"
										options={contactos!.map(
											(element) => {
												return {
													text: element.personaContacto,
													id: element.id,
													onClick: () => { handleClickContactos(element); }
												}
											})}
										createNewItem={true}
										titleNewItem='Nuevo Contacto'
										handleNewItem={handleNewContacto}
										disabled={false}
										singleSelection
										component={Dropdown}
									/>
								}
							</div>

							<div className="justify-end">
								{
									openContactoModal &&
									<ContactoModalCreateSection
										clienteId={oferta.clienteId}
										handleCloseModal={handleCreateContact}
									/>
								}
							</div>

							<div className='double-col-grid'>
								<div>
									<h3>Cláusulas Seleccionadas:</h3>
									<table className="mini-table">
										<thead>
											<tr className='table-header'>
												<th>Orden</th>
												<th>Nombre de la cláusula</th>
												<th>Delegación</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{clausulasPresupuesto && clausulasPresupuesto.sort(sortClausulas)
												.map((item: PresupuestoClausula, index: number) => (
													<tr key={index}>
														<td>{item.orden}</td>
														<td>
															<TooltipComponent text={item.descripcion}>
																{item.nombre}
															</TooltipComponent>
														</td>
														<td>{item?.delegacionId ? item?.delegacionNombre : "Todas"}</td>
														<td>
															<IconButton
																disabled={clausulasOferta.length == 1 || item.delegacionId == null}
																icon="delete"
																onClick={() => { borrarClausula(item.clausulaId) }}
																label="BorrarClausulaModal"
																index={index}
															/>
														</td>

													</tr>
												))}
										</tbody>
									</table>
								</div>
								{contactosGrid &&
									<div>
										<h3>Contactos Seleccionados:</h3>
										<table className="mini-table">
											<thead>
												<tr className='table-header'>
													<th>Razón social</th>
													<th>Email</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{contactosGrid.map((item: PresupuestoContacto, index: number) => (
													<tr key={index}>
														<td data-cy={`ContactoGridModal` + index}>{item.personaContacto}</td>
														<td data-cy={`MailGridModal` + index}>{item.mail}</td>
														<td className="flex-horizontal">
															<IconButton
																icon="delete"
																onClick={() => { borrarPresupuestoContacto(item, index) }}
																label="BorrarContactoModal"
																index={index}
															/>
														</td>

													</tr>
												))}
											</tbody>
										</table>
									</div>
								}

							</div>
							<div>
								{
									ficherosAdjunto &&
									<div>
										<h3>Ficheros Adjuntos:</h3>
										<DropZone className={""} handleFicherosAdjuntados={handleFicheroAdjuntoSelected} />
									</div>}
							</div>
							{
								borradorPdfUrl &&
								<embed width={"100%"} height={"400px"} src={borradorPdfUrl} ></embed>
							}

							<div className="buttons-right">
								<CustomButton
									key={`Borrador`}
									title={'Borrador'}
									className={'btn-primary'}
									btnStyle="accept"
									onButtonClick={() => { generarBorrador(); }}
									type="button"
									text="Borrador"
								/>
								<CustomButton
									key={`Guardar`}
									title={'Guardar'}
									className={'btn-primary'}
									btnStyle="accept"
									onButtonClick={() => guardarPresupuesto(false)}
									type="button"
									text="Guardar"
								/>
								<CustomButton
									key={`GuardarYEnviar`}
									title={'Guardar y Enviar'}
									className={'btn-primary'}
									btnStyle="accept"
									onButtonClick={() => guardarPresupuesto(true)}
									type="button"
									text="Guardar y Enviar"
									disabled={contactosGrid.length === 0 || contactosGrid.some(p=>!validateEmail(p.mail))}
								/>
							</div>
						</>
					}
					modalTitle={presupuestoId ? "Editar presupuesto" : "Añadir presupuesto"}
					modalSubtitle={presupuestoId ? "" : "Complete los campos necesarios para añadir un presupuesto"}
					bodyClassName='--large'
				>
				</ModalPage>
			}
			{
				openUpdateToneladasOportunidad && oferta.tipoOfertaId != 5 &&
				<ModalPage
					modalTitle="Actualizar toneladas de oportunidades"
					modalSubtitle={""}
					bodyClassName='--medium'
					handleCloseModal={() => { setOpenUpdateToneladasOportunidad(false) }}
					opened={openUpdateToneladasOportunidad}
					fields={
						<>
							<p>Las toneladas se van a actualizar en la Oportunidad (y no en el resto de las ofertas relacionadas) ¿quiere continuar con el cambio?</p>
							<p>Se van a actualizar las actuales toneladas de la oportunidad: <strong>{oferta?.oportunidadToneladas}</strong> con las de la oferta <strong>{calcularToneladas().toFixed(2)}.</strong></p>
							<p>Si elige que no, solo se actualizaran las toneladas de la actual oferta.</p>
							<div className="bottom-buttons">
								<CustomButton
									key={`Actualizar toneladas oportunidades`}
									title={'SI'}
									onButtonClick={() => {
										handleGenerarOEnviar(true);
									}}
									text="Si"
								/>
								<CustomButton
									key={`Actualizar toneladas oportunidades`}
									title={'NO'}
									onButtonClick={() => {
										handleGenerarOEnviar(false);
									}}
									text="No"
								/>
								<CustomButton
									key={`Actualizar toneladas oportunidades`}
									title={'Cancelar'}
									onButtonClick={() => { setOpenUpdateToneladasOportunidad(false) }}
									text="Cancelar"
								/>
							</div>
						</>
					}

				>
				</ModalPage>
			}
		</div>
	)
}

export default GenerarPresupuestoModalSection




