import React, { useCallback, useContext, useEffect, useState } from 'react'
import CustomGridView from '../../components/CustomGridView'
import { AuthContext } from '../Login/AuthContextProvider';
import PaginationComponent from '../../components/ui/molecules/Pagination/PaginationComponent';
import { toast } from 'react-toastify';
import FilterModal from '../../components/ui/atoms/FilterModal/FilterModal';
import ClausulasRepository from './domain/ClausulasRepository';
import ClausulasApiRepository from './infrastructure/api/ClausulasApiRepository';
import Clausula, { ClausulaReadDto } from '../Clausulas/domain/model/Clausula'
import BaseResponse from '../../domain/BaseResponse';
import { GlobalContext, GlobalContextType } from '../../context/Global.Context';
import { useLoading } from '../../context/Loading.Context';
import useColumnasGrid from '../../hooks/useColumnasGrid';
import DelegacionDropdownField from '../../components/common/Filters/DelegacionDropdownField';
import useDebounce from '../../hooks/useDebounce';
import ClausulasTagCloud from './ClausulasTagCloud';
import TextInputFilterSearchField from '../../components/common/Filters/TextInputFilterSearchField';
import DelegacionesRepository from '../../domain/DelegacionesRepository';
import { COD_DELEGACIONES_CON_TODAS } from '../../assets/constants/Constantes';
import DelegacionesApiRepository from '../../infraestructure/api/Delegaciones.ApiRepository';
import Delegacion from '../../domain/model/Delegacion';
import DeleteModal from '../../shared/components/DeleteModal/DeleteModal';

const Clausulas = () => {
	const [clausulas, setClausulas] = useState<Clausula[]>([]);
	const [clausulasResponse, setClausulasResponse] = useState<BaseResponse<Clausula[]>>()
	const { globalState, updateGlobalState } = useContext(GlobalContext) as GlobalContextType;
	const { getToken, getDelegacion, getEmail } = useContext(AuthContext);
	const { setLoading } = useLoading();
	const componentName = "clausulas";
	const clausulasRepo: ClausulasRepository = new ClausulasApiRepository(getToken());
	const maxResultCount = globalState[componentName]?.pagination?.maxResultCount ?? 10;
	const skipCount = globalState[componentName]?.pagination?.skipCount ?? 0;
	const sorting = globalState[componentName]?.order ? globalState[componentName]?.order?.sortingCriteria + (globalState[componentName]?.order?.desc ? " DESC" : "") : "orden, delegacionId desc, descripcion";
	const { cols } = useColumnasGrid("BACKENDOPORTUNIDADES", "CLAUSULAS");
	const [isChecked, setIsChecked] = useState<boolean>(true);
	const [delegaciones, setDelegaciones] = useState<Delegacion[]>();
	const [openDelete, setOpenDelete] = useState<boolean>(false);

	const filterValues = {
		verTodas: true,
		userDelegacion: isChecked && getDelegacion().id ? getDelegacion().id : undefined,
		delegacionIds: globalState[componentName]?.filters?.delegacionIds,
		nombre: globalState[componentName]?.filters?.nombre,
		descripcion: globalState[componentName]?.filters?.descripcion,
	}
	const debouncedDelegacionIdsSearchValue = useDebounce(filterValues.delegacionIds, 1000)
	const debouncedNombreSearchValue = useDebounce(filterValues.nombre, 1000)
	const debouncedDescripcionSearchValue = useDebounce(filterValues.descripcion, 1000)

	useEffect(() => {
		fetchClausulasResponse()
	}, [
		maxResultCount,
		skipCount,
		sorting,
		debouncedDelegacionIdsSearchValue,
		debouncedNombreSearchValue,
		debouncedDescripcionSearchValue,
		filterValues.userDelegacion
	])

	useEffect(() => {
		fetchDelegaciones()
	}, [getToken()])

	const [deletionId, setDeletionId] = useState<string>();
	const openConfirmationModal = (id: string) => {
		setDeletionId(id);
		setOpenDelete(true);
	}

	const handleCloseModalDelete = (remove: boolean) => {
		setOpenDelete(false);
		if (remove) {
			handleIsDelete(deletionId!);
		}
	}

	const handleIsDelete = (id: string) => {
		return clausulasRepo.getById(id)
			.then((data) => {
				let clausula = data
				clausula.isDeleted = true;
				clausula.deletionUsername = getEmail()

				clausulasRepo.update(clausula)
					.then(resp => {
						fetchClausulasResponse();
					}).finally(()=>{
						toast.success("Cláusula eliminada con éxito");
					})
			})
			.catch(message => toast.error(message))
			.finally(() => { setLoading(false); });
	}

	const fetchClausulasResponse = async () => {
		setLoading(true);

		if (isChecked) {
			filterValues.delegacionIds = [Number(getDelegacion().id)];
		} else {
			filterValues.delegacionIds = globalState[componentName]?.filters?.delegacionIds;
		}

		return clausulasRepo.fetchClausulasByFilter({
			filter: filterValues,
			maxResultCount: maxResultCount,
			skipCount: skipCount,
			sortingCriteria: sorting,
		}).then((res: BaseResponse<ClausulaReadDto[]>) => {
			setClausulasResponse(res)
			setClausulas(res?.items)
		}).finally(() => {
			setLoading(false);
		})
	}

	const handleSetMaxCount = (count: number) => {
		updateGlobalState(componentName, { pagination: { ...globalState[componentName]?.pagination, maxResultCount: count } })
	}

	const handlePageSelect = (page: number) => {
		updateGlobalState(componentName, { pagination: { ...globalState[componentName]?.pagination, skipCount: page * maxResultCount } })
	}

	const handlePageBackwards = () => {
		updateGlobalState(componentName, { pagination: { ...globalState[componentName]?.pagination, skipCount: (skipCount / maxResultCount - 1) * maxResultCount } })
	}

	const onCheckChange = async () => {
		setIsChecked(!isChecked);
		if (!isChecked) {
			filterValues.delegacionIds = [Number(getDelegacion().id)];
			filterValues.userDelegacion = getDelegacion().id;
			updateGlobalState(componentName, {
				filters: {
					...globalState[componentName]?.filters,
					userDelegacion: getDelegacion()
				},
				pagination: {
					skipCount: 0
				}
			})
		} else {
			filterValues.delegacionIds = globalState[componentName]?.filters?.delegacionIds;
			filterValues.userDelegacion = "";
			updateGlobalState(componentName, { filters: { ...globalState[componentName]?.filters, userDelegacion: undefined } })
		}
	}


	const fetchDelegaciones = useCallback(
		() => {
			const repo: DelegacionesRepository = new DelegacionesApiRepository(getToken());
			return repo.fetchDelegaciones({
				filter: {
					ids: COD_DELEGACIONES_CON_TODAS
				}
			}).then((resp: Delegacion[]) => {
				setDelegaciones(resp);
			})
		}, [getToken])

	return (
		<div className="grid-view-container">
			<h3>Cláusulas</h3>
			<div className="filters-container">
				<div className="filters-modal-container">
					<FilterModal
						titleShow="Filtros"
						modalTitle="Selección de filtros"
						label={"TituloModal"}
						filters={
							<>
								<TextInputFilterSearchField
									componentName={componentName}
									field='nombre'
									label="nombre"
									idTest="NombreFiltroClausulas" />
								<TextInputFilterSearchField
									componentName={componentName}
									field='descripcion'
									label="descripción"
									idTest="DescripcionFiltroClausulas" />
								<DelegacionDropdownField
									disabled={isChecked}
									userDelegacion={filterValues.userDelegacion}
									componentName={componentName}
									delegaciones={delegaciones ? delegaciones : []}
									idTest="DelegacionFiltroClausulas" />
							</>
						}

					/>
					<label>Mis datos</label>
					<input
						checked={isChecked}
						onChange={() => { onCheckChange() }}
						type="checkbox"
						data-cy="MisDatosCheckbox"
					/>
				</div>
				<ClausulasTagCloud idTest="FiltroTag"/>
			</div>

			{clausulas && cols && <CustomGridView
				title={componentName}
				columns={cols}
				data={clausulas}
				onDelete={openConfirmationModal}
				controlButtons
				addButton
				sorting={sorting}
				functionalityPath='/mantenimiento/clausulas'
			/>
			}

			<div className="footer-section">
				{clausulasResponse &&
					<PaginationComponent
						currentPage={clausulasResponse.currentPage}
						maxCount={maxResultCount}
						totalCount={clausulasResponse.totalCount}
						onPageSelect={handlePageSelect}
						onSetMaxCount={handleSetMaxCount}
						onPageBackwards={handlePageBackwards}
					/>
				}
			</div>

			{
				openDelete &&
				<DeleteModal
					open={openDelete}
					handleCloseModal={handleCloseModalDelete}
					field="cláusula"
				>
				</DeleteModal>
			}


		</div>
	)
}

export default Clausulas