import React, { useCallback, useContext, useEffect, useState } from "react";
import FormField from "../../../../../components/ui/molecules/Form-field/FormField";
import Dropdown, {
  DropdownProps,
} from "../../../../../components/ui/atoms/Dropdown/Dropdown";
import {
  GlobalContext,
  GlobalContextType,
} from "../../../../../context/Global.Context";
import { UnidadMedidaDto } from "../../../../../domain/model/UnidadMedida";
import { AuthContext } from "../../../../Login/AuthContextProvider";
import UnidadesMedidaRepository from "../../../../../domain/UnidadesMedidaRepository";
import UnidadesMedidaApiRepository from "../../../../../infraestructure/api/UnidadesMedida.ApiRepository";
import { toast } from "react-toastify";
import FormFieldGroup from "../../../../../components/ui/objects/FormFieldGroup/FormField.Group";

interface UnidadObraUnidadMedidaDropdownFieldProps {
  idTest?: string;
}

const UnidadObraUnidadMedidaDropdownField = ({
  idTest,
}: UnidadObraUnidadMedidaDropdownFieldProps) => {
  const { globalState, updateGlobalState } = useContext(
    GlobalContext
  ) as GlobalContextType;
  const [medidas, setMedidas] = useState<UnidadMedidaDto[]>([]);
  const componentName = "unidades";
  const [value, setValue] = useState(
    globalState[componentName]?.filters?.unidadMedidaId ?? null
  );
  const { getToken } = useContext(AuthContext);

  useEffect(() => {
    fetchMedidas();
  }, [value]);

  const fetchMedidas = useCallback(() => {
    const repo: UnidadesMedidaRepository = new UnidadesMedidaApiRepository(
      getToken()
    );
    return repo
      .getConFiltro({})
      .then((data) => {
        setMedidas(data);
      })
      .catch((message) => toast.error(message));
  }, [getToken]);

  const handleInputChange = (medida: UnidadMedidaDto) => {
    setValue(medida.id);
    const medidaObj: UnidadMedidaDto = medidas.find(
      (item: any) => item.id === medida.id
    )!;
    if (medidaObj) {
      updateGlobalState(componentName, {
        filters: {
          ...globalState[componentName]?.filters,
          unidadMedidaId: +medidaObj.id,
          unidadMedidaDescripcion: medidaObj.descripcion,
        },
        pagination: {
          skipCount: 0,
        },
      });
    }
  };

  return (
    <div className="input-container">
      <FormFieldGroup>
        <FormField<DropdownProps>
          label="Unidad Medida"
          options={medidas.map((element) => {
            return {
              text: element.descripcion,
              id: element.id,
              selected: value == +element.id ? true : false,
              onClick: () => {
                handleInputChange(element);
              },
            };
          })}
          disabled={false}
          singleSelection={false}
          component={Dropdown}
          idTest={idTest}
        />
      </FormFieldGroup>
    </div>
  );
};

export default UnidadObraUnidadMedidaDropdownField;
