import { SeguimientoOfertaEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ApiRequest from "../../../../domain/ApiRequest";
import SeguimientoOferta, { SeguimientoOfertaUpdateDto } from "../../domain/model/SeguimientoOferta";

export default class SeguimientoOfertaRequestUpdate extends ApiRequest<
SeguimientoOfertaUpdateDto,
SeguimientoOferta
>
{
	constructor(data: SeguimientoOfertaUpdateDto, token: string) {
		super(
			"PUT",
			SeguimientoOfertaEndpoints.UPDATE,
			{ Authorization: `${token}`},
			data,
		)
	}
}