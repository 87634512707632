import { useContext } from "react";
import { AsfalteroDropdownContext } from "../../context/FilterFields.Context";
import FormFieldGroup from "../../../../components/ui/objects/FormFieldGroup/FormField.Group";
import Dropdown from "../../../../components/ui/atoms/Dropdown/Dropdown";

interface AsfalteroDropdownProps {
	idTest?:string
}

const AsfalteroDropdown: React.FC<AsfalteroDropdownProps> = ({idTest}) => {

	const options = useContext(AsfalteroDropdownContext);

	return (
		<div className="input-container" >
		<FormFieldGroup>	
			<label>Asfaltera</label>
			<Dropdown
			label={idTest}
				options={options}
			/>
			
		</FormFieldGroup>
		</div>
	)
}

export default AsfalteroDropdown;