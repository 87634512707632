import { useCallback, useContext } from "react";
import { toast } from "react-toastify";
import { HistoryHandler } from "../../../../context/History.Context";
import { useLoading } from "../../../../context/Loading.Context";
import { FormErrors } from "../../../../hooks/use-form";
import { Routes } from "../../../../router/routes/Routes";
import { AuthContext } from "../../../Login/AuthContextProvider";
import ClienteRepository from "../../domain/ClienteRepository";
import {ClienteCreationDto } from "../../domain/model/Cliente";
import ClienteApiRepository from "../../infraestructure/api/Cliente.ApiRepository";
import ClienteFormTemplate from "../common/Cliente.FormTemplate";
import { Severity } from "../../../../utils/ErrorSeverity";

const ClienteGrabacion: React.FC<{}> = () => {

	const { goToRoute } = useContext(HistoryHandler);
	const {getToken} = useContext(AuthContext);
	const {setLoading} = useLoading();
	
	const onSubmit = useCallback((
		valid: boolean,
		data: ClienteCreationDto,
		errors: FormErrors<ClienteCreationDto>
	) => {
		const repo: ClienteRepository = new ClienteApiRepository(getToken());

		if (valid) {
			setLoading(true);
			repo.addCliente(data)
				.then((res) => {
					goToRoute(Routes.CLIENTES_EDICION, {id: res?.id});
					toast.success("Cliente creado con éxito");

					if (res?.errores.length > 0) {
						if (res.errores[0].severidad === Severity.Warning) {
							toast.warning(res.errores[0].descripcion);
						} else {
							toast.error(res.errores[0].descripcion);
						}
					}
				})
				.catch(message => {
					toast.error(message);
				}
				)
				.finally(() => {setLoading(false)});
		}
	}, [goToRoute, getToken]);

	return (
		<ClienteFormTemplate
			type="add"
			handleOnSubmit={onSubmit}
		/>

	)
}

export default ClienteGrabacion;