import TipoOferta, { TipoOfertaDto } from "../../domain/model/TipoOferta";
import TipoOfertaRepository from "../../domain/TipoOfertaRepository";
import { BaseRepository } from "@pavabits/components";
import TipoOfertaGetAll from "../ws/TipoOfertaGetAll";

export default class TipoOfertaApiRepository
	extends BaseRepository<
		TipoOfertaDto[],
		TipoOferta[]
	> implements TipoOfertaRepository {

	async getAll(): Promise<TipoOferta[]> {
		return (await new TipoOfertaGetAll(this.authToken).executeAsPromise()).items;

	}

	fetchTipoOfertas(): Promise<TipoOferta[]> {
		return new Promise((resolve, reject) => {
			return new TipoOfertaGetAll(this.authToken)
				.request()
				.then((response) => {
					if (response.success) {
						resolve(this.transformDtoIntoModel(response.data.items));
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error) => {
					reject(error);
				})
		})
	}

	protected transformDtoIntoModel(dto: TipoOfertaDto[]): TipoOferta[] {
		if (dto && dto.length) {
			return dto.map(item => {
				return new TipoOferta(item.id, item.descripcion)
			})
		}
		return [];
	}
	// protected transformDtoIntoModel(dto[]: TipoOfertaDto): TipoOferta {
	// 	
	// }


}