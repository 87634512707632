import { useEffect, useRef } from "react";

export default function useComponentVisible(
  onClickOut: (isTargetItem?: string) => void,
  closeOnItemClick?: boolean
) {
  const ref = useRef(null);

  useEffect(() => {
    const handleHideDropdown = (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.key === "Tab") {
        onClickOut();
      }
    };
    const handleClickOutside = (event: any) => {
      const isTargetItem =
        event.target && event.target.id.includes("PbDropdownItem");
      if (closeOnItemClick && isTargetItem) {
        onClickOut(event.target.id);
      }

      if (!isTargetItem) {
        onClickOut();
      }
    };

    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOut, closeOnItemClick]);

  return { ref };
}
