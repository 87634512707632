import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import 'react-tabs/style/react-tabs.scss';
import { toast } from "react-toastify";
import Constantes from "../../../../assets/constants/Constantes";
import SelectableItem from "../../../../components/ui/atoms/SuggestionInput/model/SuggestionInput.Model";
import { DirtyForm } from "../../../../domain/model/DirtyForm";
import { CustomButton } from "../../../../shared/components/Buttons/CustomButton";
import DropdownOptions, { DropdownOption } from "../../../../shared/components/Dropdowns/dropdown-options/DropdownOptions";
import CustomInput from "../../../../shared/components/Inputs/CustomInput/CustomInput";
import { fechaValida, formatDate2input, formatDate2String, formatDDMMYYYY, formatUsDate2String, formatYYYYMMDD } from "../../../../utils/DateUtil";
import EstadoOfertaRepository from "../../../Clientes/domain/EstadoOfertaRepository";

import TooltipComponent from "../../../../components/ui/molecules/Tooltip/TooltipComponent";
import SeguimientoClienteRepository from "../../../Clientes/domain/SeguimientoClienteRepository";
import TipoSeguimientoClienteRepository from "../../../Clientes/domain/TipoSeguimientoClienteRepository";
import ContactoCliente from "../../../Clientes/domain/model/ContactosCliente";
import EstadoOferta from "../../../Clientes/domain/model/EstadoOferta";
import SeguimientoCliente from "../../../Clientes/domain/model/SeguimientoCliente";
import TipoSeguimientoCliente from "../../../Clientes/domain/model/TipoSeguimientoCliente";
import EstadoOfertaApiRepository from "../../../Clientes/infraestructure/api/EstadoOferta.ApiRepository";
import SeguimientoClienteApiRepository from "../../../Clientes/infraestructure/api/SeguimientoCliente.ApiRepository.";
import TipoSeguimientoClienteApiRepository from "../../../Clientes/infraestructure/api/TipoSeguimientoCliente.ApiRepository.";
import { AuthContext } from "../../../Login/AuthContextProvider";
import SeguimientoOfertaRepository from "../../domain/SeguimientoOfertaRepository";
import SeguimientoOferta from "../../domain/model/SeguimientoOferta";
import SeguimientoOfertaApiRepository from "../../infraestructure/api/SeguimientoOferta.ApiRepository";
import DeleteModal from "../../../../shared/components/DeleteModal/DeleteModal";

interface Props extends DirtyForm {
    type: "edit" | "add"
    title: string
    handleOnSubmit: () => void
    contactosCliente: ContactoCliente[],
    seguimientoOfertas?: SeguimientoOferta[],
    idPadre: string,
    estadoOfertaId: number,
    clienteId: number,
    readOnly?: boolean
}

const SeguimientoOfertaView: FC<Props> = ({ type,
    handleOnSubmit,
    contactosCliente,
    idPadre,
    estadoOfertaId,
    title,
    clienteId,
    hadleDirtyForm,
    readOnly
}) => {
    const ID_TIPO_SEGUIMIENTO_CLIENTE_AUTOMATICO = 6;
    //modal open
    //session
    const { getToken, username } = useContext(AuthContext);

    const { t } = useTranslation<['main']>(['main']);

    const [seguimientoOfertas, setSeguimientoOfertas] = useState<SeguimientoOferta[]>();

    const fetchSeguimientoOferta = useCallback(
        (id: string) => {
            const repo: SeguimientoOfertaRepository = new SeguimientoOfertaApiRepository(getToken());
            return repo.getByClienteId(id).then((a) => {

                const auxFechas = a.map((seguimiento) => {
                    return { ...seguimiento, fechaSeguimiento: formatDate2String(formatDDMMYYYY(seguimiento.fechaSeguimiento.split("T")[0])) }
                });
                setSeguimientoOfertas(auxFechas);
                setSeguimientoOfertaForm(auxFechas);
                setSeguimientoOfertaIni(auxFechas);
            });
        }
        , [getToken]);

    const isDirty = (seguimientoOferta: SeguimientoOferta): boolean => {
        if (seguimientoOferta.id == undefined || seguimientoOferta.id == '') {
            hadleDirtyForm(true);
            return true;
        }
        else {
            let seguimientoOfertaEncontrado = seguimientoOfertaIni?.filter(p => p.id === seguimientoOferta.id);
            const arrayProperties = ["fechaSeguimiento", "observacionesTipoSeguimiento", "observaciones", "tipoSeguimientoClienteId", "estadoOfertaId", "contactoId"]
            if (seguimientoOfertaEncontrado && seguimientoOfertaEncontrado.length > 0 && JSON.stringify(seguimientoOfertaEncontrado[0], arrayProperties) == JSON.stringify(seguimientoOferta, arrayProperties)) {
                hadleDirtyForm(false);
                return false;
            }
            else {
                hadleDirtyForm(true);
                return true;
            }
        }
    }

    //delete
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [deletionIndex, setDeletionIndex] = useState<number>(-1);
    const openConfirmationModal = (index: number) => {
        setDeletionIndex(index);
        setOpenDelete(true);
    }

    const handleCloseModalDelete = (remove: boolean) => {
        setOpenDelete(false);
        if (remove) {
            handleRemoveSeguimientoOferta(deletionIndex!);
        }
    }

    const handleRemoveSeguimientoOferta = (index: number) => {
        if (!seguimientoOfertaForm[index].id) {
            seguimientoOfertaForm.splice(index, 1);
            toast.success(`Eliminado correctamente`);
            setUserAddingContact(false);
            setOpenDelete(false);
            return;
        }

        const repo: SeguimientoOfertaRepository = new SeguimientoOfertaApiRepository(getToken());
        repo.delete(seguimientoOfertaForm[index].id)
            .then((resp) => {
                if (resp.errors && resp.errors.length > 0) {
                    toast.error(resp.errors[0]);
                }
                else {
                    toast.success(`Eliminado correctamente`);
                    const aux = seguimientoOfertaForm.filter(item => item.id !== seguimientoOfertaForm[index].id);
                    setSeguimientoOfertaForm(aux);
                    setSeguimientoOfertaIni(aux);
                }
            }).catch((ex) => {
                toast.success(`Error: ${ex}`);
            });
        setOpenDelete(false);
    }

    const initOptionsDelegaciones: DropdownOption[] = [];
    const [optionsTipoSeguimientoCliente, setOptionsTipoSeguimientoCliente] = useState<TipoSeguimientoCliente[]>([]);
    const [optionsEstadoOferta, setOptionsEstadoOferta] = useState<DropdownOption[]>(initOptionsDelegaciones);

    const [tipoSeguimientoClienteSelected/*, setTipoSeguimientoClienteSelected*/] = useState<TipoSeguimientoCliente>({ id: '0', descripcion: "Seleccionar" } as SelectableItem);
    const [estadoOfertaSelected/*, setEstadoOfertaSelected*/] = useState<EstadoOferta>({ id: '0', descripcion: "Seleccionar" } as SelectableItem);
    const [userAddingContact, setUserAddingContact] = useState<boolean>(false);

    const [seguimientoOfertaForm, setSeguimientoOfertaForm] = useState<SeguimientoOferta[]>(seguimientoOfertas ?? []);
    const [seguimientoOfertaIni, setSeguimientoOfertaIni] = useState<SeguimientoOferta[]>(seguimientoOfertas ?? []);
    const [errores, setErrores] = useState<string[]>([]);
    const [lineSelected, setLineSelected] = useState<number>();

    

    const seguimientoOfertaValidation = (index: number): boolean => {
        const errores: string[] = [];

        const contacto = seguimientoOfertaForm[index];

        if (contacto.tipoSeguimientoClienteId < 0) errores[0] = 'El tipo tiene que estar seleccionado';

        if (!fechaValida(contacto.fechaSeguimiento)) errores[1] = 'La fecha no es valida';

        if (contacto.observaciones && contacto.observaciones?.length > Constantes.OBSERVACIONES_MAX_LENGTH) {
            errores[2] = 'Máximo 200 caracteres.';
        }

        setErrores(errores);

        return errores.length === 0;
    }

    //save
    const onSubmitContactForm = (i: number) => {
        const repo: SeguimientoOfertaRepository = new SeguimientoOfertaApiRepository(getToken());
        const repoCliente: SeguimientoClienteRepository = new SeguimientoClienteApiRepository(getToken());
        let seguimiento = i > -1 ? seguimientoOfertaForm[i] : undefined;

        if (seguimiento !== undefined) {
            setLineSelected(i);
            if (seguimientoOfertaValidation(i)) {
                //update
                if (!!seguimiento?.id) {
                    repo.update({...seguimiento, fechaSeguimiento: formatYYYYMMDD(seguimiento.fechaSeguimiento)} as SeguimientoOferta)
                        .then((responseOferta) => {
                            const contactoUpdated = seguimientoOfertaForm.map((seguimiento: SeguimientoOferta, j: number) => {

                                if (i === j) return { ...seguimiento, lastModificationTime: responseOferta.lastModificationTime };
                                //ya hemos guardado, dejamos que se añadan nuevos
                                setUserAddingContact(false);

                                return seguimiento;

                            });

                            repoCliente.getBySeguimientoOfertaId(responseOferta.id)
                                .then((seguimientoCliente) => {

                                    if (seguimientoCliente && seguimientoCliente.length > 0) {
                                        const seguimientoClienteUpd: SeguimientoCliente = {
                                            id: seguimientoCliente[0].id,
                                            fechaSeguimiento: formatYYYYMMDD(seguimiento?.fechaSeguimiento!),
                                            //observacionesTipoSeguimiento: seguimiento?.observacionesSeguimiento!,
                                            observaciones: seguimiento?.observaciones!,
                                            tipoSeguimientoClienteId: seguimiento?.tipoSeguimientoClienteId!,
                                            estadoOfertaId: estadoOfertaId,
                                            clienteId: clienteId,
                                            lastModificationTime: seguimientoCliente[0].lastModificationTime,
                                            contactoId: seguimiento?.contactoId!,
                                            ofertaId: idPadre,
                                            seguimientoOfertaId: seguimiento?.id!
                                        };

                                        repoCliente.update(seguimientoClienteUpd as SeguimientoCliente)
                                            .then((response) => {
                                                toast.success(`Seguimiento actualizado con éxito`);

                                            })
                                            .catch(() => {
                                                toast.error("Error al insertar seguimiento de cliente asociado al seguimiento de oferta");
                                            })
                                    }
                                    else {
                                        toast.success(`Seguimiento actualizado con éxito`);
                                    }

                                });

                            setSeguimientoOfertaForm(contactoUpdated);
                            setSeguimientoOfertaIni(contactoUpdated)
                        })
                        .catch((error) => {
                            toast.error(`Error al guardar el seguimiento`);
                        });
                }
                else //add
                {
                    repo.add({...seguimiento, fechaSeguimiento: formatYYYYMMDD(seguimiento.fechaSeguimiento)} as SeguimientoOferta)
                        .then((response) => {

                            //recogemos el nuevo id, ya no es nuevo, es editable!!!
                            const personaCont = seguimientoOfertaForm.map((seguimiento: SeguimientoOferta, j: number) => {
                                if (i === j) return { ...seguimiento, id: response.id, lastModificationTime: response.lastModificationTime };

                                return seguimiento;
                            });
                            setSeguimientoOfertaForm(personaCont);
                            setSeguimientoOfertaIni(personaCont)

                            const seguimientoCliente: SeguimientoCliente = {
                                id: '',
                                fechaSeguimiento: formatYYYYMMDD(seguimiento?.fechaSeguimiento!),
                                //observacionesTipoSeguimiento: seguimiento?.observacionesSeguimiento!,
                                observaciones: seguimiento?.observaciones!,
                                tipoSeguimientoClienteId: seguimiento?.tipoSeguimientoClienteId!,
                                estadoOfertaId: estadoOfertaId,
                                clienteId: clienteId,
                                lastModificationTime: '',
                                contactoId: seguimiento?.contactoId!,
                                ofertaId: idPadre,
                                seguimientoOfertaId: response?.id!
                            };

                            repoCliente.add(seguimientoCliente as SeguimientoCliente)
                                .then((response) => {
                                    toast.success(`Seguimiento añadido con éxito`);
                                    //ya hemos guardado, dejamos que se añadan nuevos
                                    setUserAddingContact(false);
                                })
                                .catch(() => {
                                    toast.error("Error al insertar seguimiento de cliente asociado al seguimiento de oferta");
                                })
                        })
                        .catch(() => {
                            toast.error(`Error al añadir el seguimiento`);
                        });
                }
            }
        }
    }

    const handleAddContacto = () => {
        setUserAddingContact(true);
        let newSeg = {
            id: '',
            fechaSeguimiento: formatDate2String(formatDDMMYYYY(new Date())),
            observacionesSeguimiento: '',
            observaciones: '',
            tipoSeguimientoClienteId: 1,
            estadoOfertaId: 1,
            ofertaId: idPadre,
            lastModificationTime: '',
            contactoId: undefined
        }
        setSeguimientoOfertaForm([newSeg, ...seguimientoOfertaForm])
    }

    const fetchTipoSeguimiento = useCallback(
        () => {
            const repo: TipoSeguimientoClienteRepository = new TipoSeguimientoClienteApiRepository(getToken());
            return repo.getAll().then((resp) => {
                setOptionsTipoSeguimientoCliente(resp);
            })
        }
        , [getToken, tipoSeguimientoClienteSelected]);

    const fetchEstadoOferta = useCallback(
        () => {
            const repo: EstadoOfertaRepository = new EstadoOfertaApiRepository(getToken());
            return repo.getAll().then((a) => {
                let array: any = a.map(tipo => {
                    return {
                        id: tipo.id,
                        text: `${tipo.descripcion}`,
                        icon: 'done',
                        selected: estadoOfertaSelected.id === tipo.id
                    };
                })
                setOptionsEstadoOferta(array);
            })
        }
        , [getToken, estadoOfertaSelected]);


    useEffect(() => {
        fetchSeguimientoOferta(idPadre !== undefined ? idPadre : "");
        fetchTipoSeguimiento();
        fetchEstadoOferta();
    }
        , [fetchTipoSeguimiento, fetchEstadoOferta]);


    const handleTipoSeguimientoChange = (delegacionSelected: TipoSeguimientoCliente, index: any) => {

        const personaCont = seguimientoOfertaForm.map((contacto: SeguimientoOferta, j: number) => {
            if (index === j) return { ...contacto, tipoSeguimientoClienteId: + delegacionSelected.id };

            return contacto;
        });
        setSeguimientoOfertaForm(personaCont);
    }

    const handleContactoChange = (contactoSelectedId: string, index: any) => {

        const personaCont = seguimientoOfertaForm.map((contacto: SeguimientoOferta, j: number) => {
            if (index === j) return { ...contacto, contactoId: contactoSelectedId };

            return contacto;
        });
        setSeguimientoOfertaForm(personaCont);
    }

    const handleEstadoOfertaChange = (estadoOfertaSelectedId: number, index: any) => {

        const personaCont = seguimientoOfertaForm.map((contacto: SeguimientoOferta, j: number) => {
            if (index === j) return { ...contacto, estadoOfertaId: estadoOfertaSelectedId };

            return contacto;
        });
        setSeguimientoOfertaForm(personaCont);
    }

    const handlePropertyChange = (property: string, newValue: string | number, i: number) => {
        const personaCont = seguimientoOfertaForm.map((contacto: any, j: number) => {
            if (i === j) return { ...contacto, [property]: newValue, Usuario: username };

            return contacto;
        });
        setSeguimientoOfertaForm(personaCont);
    }

    const handleFechaChange = (newValue: string, i: number) => {
        const personaCont = seguimientoOfertaForm.map((contacto: any, j: number) => {
            if (i === j) return { ...contacto, fechaSeguimiento: newValue };

            return contacto;
        });
        setSeguimientoOfertaForm(personaCont);
    }


    return (
        <>
            <div className="inline-grid">
                <div className="inline-grid-header">
                    <label>Fecha</label>
                    <label>Tipo</label>
                    <label>Contacto</label>
                    <label>Observaciones</label>

                    <div className="inline-button">
                        {!readOnly && !userAddingContact &&
                            <CustomButton
                                id={"add-btn"}
                                icon="add"
                                title="Añadir contacto"
                                className={"contact-btn"}
                                onButtonClick={() => { handleAddContacto() }}
                            />
                        }
                    </div>
                </div>

                <div className="inline-grid-container">
                    {seguimientoOfertaForm?.map((contacto: SeguimientoOferta, i: number) => {
                        return (
                            <div className="inline-grid-item">
                                <CustomInput
                                    key={`$fechaSeguimiento-${i}`}
                                    value={contacto.fechaSeguimiento}
                                    className={'platform-input secondary-input'}
                                    onChange={(e) => { handleFechaChange(e, i); }}
                                    error={lineSelected === i ? errores[1] : ""}
                                    type={'date'}
                                    formClassName={"secondary-input"}
                                    disabled={readOnly || contacto.tipoSeguimientoClienteId == ID_TIPO_SEGUIMIENTO_CLIENTE_AUTOMATICO}
                                />

                                { // tipo seguimiento
                                    optionsTipoSeguimientoCliente.length !== 0 &&
                                    <DropdownOptions
                                        className={'contact-dropdown'}
                                        idTest="LlamadaDropdown"
                                        contentClassName={'contact-dropdown-content'}
                                        itemsClassName={'dropdown-item'}
                                        options={optionsTipoSeguimientoCliente.filter(x => x.visible && (contacto.tipoSeguimientoClienteId != 6 || (+x.id == contacto.tipoSeguimientoClienteId))).map(option => {
                                            return {
                                                id: option.id,
                                                text: option.descripcion,
                                                icon: 'done',
                                                selected: contacto.tipoSeguimientoClienteId === +option.id,
                                                Usuario: username,
                                                onClick: () => { handleTipoSeguimientoChange(option, i) },
                                            }
                                        })}
                                    >

                                        {optionsTipoSeguimientoCliente.find(item => +item.id === contacto.tipoSeguimientoClienteId)?.descripcion ?? 'Seleccionar'}
                                    </DropdownOptions>
                                }

                                { // contactosCliente
                                    contactosCliente.length !== 0 ?
                                        <DropdownOptions
                                            className={'contact-dropdown'}
                                            idTest="ContactoDropdown"
                                            contentClassName={'contact-dropdown-content'}
                                            itemsClassName={'dropdown-item'}
                                            options={contacto.tipoSeguimientoClienteId == ID_TIPO_SEGUIMIENTO_CLIENTE_AUTOMATICO ? [] : contactosCliente.map(option => {
                                                return {
                                                    ...option,
                                                    selected: contacto.tipoSeguimientoClienteId.toString() === option.id,
                                                    Usuario: username,
                                                    text: option.personaContacto,
                                                    icon: '',
                                                    onClick: () => handleContactoChange(option.id, i)
                                                }
                                            })}
                                        >
                                            {contactosCliente.find(item =>
                                                item.id === contacto.contactoId)?.personaContacto ??
                                                (contacto.tipoSeguimientoClienteId == ID_TIPO_SEGUIMIENTO_CLIENTE_AUTOMATICO ? 'Sin Asignar' : 'Seleccionar')}
                                        </DropdownOptions>
                                        :
                                        <CustomInput
                                            key={`$contacto-${i}`}
                                            value={'Sin Contactos'}
                                            disabled={true}
                                            className={'platform-input secondary-input'}
                                            onChange={(e) => { }}
                                            type={"text"}
                                            formClassName={"secondary-input"}
                                        />
                                }
                                <TooltipComponent
                                    text={contacto.observaciones}
                                    showLengthZero={true}>
                                    <CustomInput
                                        key={`$observaciones-${i}`}
                                        formClassName={"secondary-input"}
                                        value={contacto.observaciones}
                                        className={'platform-input secondary-input'}
                                        onChange={(e) => { handlePropertyChange('observaciones', e, i); }}
                                        error={lineSelected === i ? errores[2] : ""}
                                        disabled={readOnly || contacto.tipoSeguimientoClienteId == ID_TIPO_SEGUIMIENTO_CLIENTE_AUTOMATICO}
                                        resizable
                                        idCypress="ObservacionesOfertaGrid"
                                    />
                                </TooltipComponent>
                                <div className="inline-buttons">
                                    <CustomButton
                                        key={`confirm-${i}`}
                                        title={'GuardarGrid'}
                                        icon={isDirty(contacto) ? 'save' : 'done'}
                                        className={'contact-btn'}
                                        onButtonClick={() => { onSubmitContactForm(i) }}
                                        type='button'
                                        disabled={readOnly || contacto.tipoSeguimientoClienteId == ID_TIPO_SEGUIMIENTO_CLIENTE_AUTOMATICO}
                                    />
                                    <CustomButton
                                        key={`remove-${i}`}
                                        title={'BorrarGrid'}
                                        icon={'delete'}
                                        className={'contact-btn remove'}
                                        onButtonClick={() => { openConfirmationModal(i) }}
                                        type='button'
                                        disabled={readOnly || contacto.tipoSeguimientoClienteId == ID_TIPO_SEGUIMIENTO_CLIENTE_AUTOMATICO}
                                    />
                                </div>

                            </div>
                        )
                    }
                    )}
                </div>

                {
                    openDelete &&
                    <DeleteModal
                        open={openDelete}
                        handleCloseModal={handleCloseModalDelete}
                        field="seguimiento de oferta"
                    >
                    </DeleteModal>
                }

               
            </div>
        </>
    )
}

export default SeguimientoOfertaView;