import { MouseEventHandler } from "react";
import { BaseFilter, SortingField } from "../../../../domain/BaseFilter";

export interface ConfigFilters<T> {
	filtering?: BaseFilter<T>,
	sorting?: SortingField[]
}

export type SelectorFilter = {
	value: string[],
	onChange: (value: React.MouseEvent<HTMLButtonElement, MouseEventHandler>) => void,
	onClear: () => void,
}

export type SelectorFilterHandler<T> = (key: keyof T) => SelectorFilter;

export type SearchFilter = {
	value: string,
	onChange: (value: string) => void,
	onClear: () => void
}

export type SearchFilterHandler<T> = (key: keyof T) => SearchFilter;

export type DateFilter = {
	value: string[],
	onChange: (value: string[]) => void,
	onClear: () => void,
}

export type DateFilterHandler<T> = (key: keyof T, secondaryKey: keyof T) => DateFilter;

export type ValueRangeFilter = {
	value: number,
	onChange: (value: number) => void,
	onClear: () => void
}

export type ValueRangeFilterHandler<T> = (key: keyof T) => ValueRangeFilter;

	export type OutputFilters<T> = {
		filter: BaseFilter<T>,
		sorting: SortingField[],
		onSelectField: SelectorFilterHandler<T>,
		onSearchField: SearchFilterHandler<T>,
		onDateField: DateFilterHandler<T>,
		onRangeField: ValueRangeFilterHandler<T>,
		onReset: () => () => void,
		onSetMaxCount: (filterKey: keyof BaseFilter<T>) => (value: number) => void,
		onSort: (filterKey: keyof BaseFilter<T>) => (field: SortingField[]) => void,
		onPageSelect: (value: number) => void,
		onPageBackwards: () => void,
	}

type FilteringFunctions<T, Actions extends OutputFilters<T>> = {
	onSelectField: Actions["onSelectField"],
	onSearchField: Actions["onSearchField"],
	onDateField: Actions["onDateField"],
	onRangeField: Actions["onRangeField"],
	onReset: Actions["onReset"],
}

export type FilterHandler<T> = FilteringFunctions<T, OutputFilters<T>>;

type SortingFunctions<T, Actions extends OutputFilters<T>> = {
	onSetMaxCount: Actions["onSetMaxCount"],
	onSort: Actions["onSort"],
	onPageSelect: Actions["onPageSelect"],
	onPageBackwards: Actions["onPageBackwards"],
}

export type SortingHandler<T> = SortingFunctions<T, OutputFilters<T>>;

export type FilterReducer<S, A> = (prevState: S, action: A) => S;

export type FilterState<T> = {
	filtering: BaseFilter<T>,
	sorting?: SortingField[],
}

export type FilterAction<T> = {
	type: ActionType,
	filterKey?: keyof BaseFilter<T>,
	typeKey?: keyof T,
	secondaryKey?: keyof T,
	payload: any,
}

export enum ActionType {
	PAGE_BACKWARDS,
	DATE_SELECTED,
	UPDATE_FILTER,
	MIN_MAX_INPUT,
	SET_MAX_COUNT,
	CLEAR_MINMAX,
	CLEAR_FIELD,
	CLEAR_DATE,
	SET_PAGE,
	RESET,
	SORT,
}
