interface EstudioDetalleAvanzadoTrasladoEntity {
	id?: string;
	unidadObraElementoId:string,
	descripcion: string,
	tarifa: number,
	unidades:number,
	dias: string,
	coste: number
}

export default class EstudioDetalleAvanzadoTraslado implements EstudioDetalleAvanzadoTrasladoEntity {
	id?: string;
	unidadObraElementoId:string;
	descripcion: string;
	tarifa: number;
	unidades:number;	
	dias: string;
	coste: number;
	
	constructor(entity: EstudioDetalleAvanzadoTrasladoEntity) {
		this.id = entity.id;
		this.unidadObraElementoId=entity.unidadObraElementoId;
		this.tarifa=entity.tarifa;
		this.descripcion=entity.descripcion
		this.unidades=entity.unidades;
		this.dias=entity.dias;
		this.coste=entity.coste;
	}
	
	public static toArray(entities: EstudioDetalleAvanzadoTrasladoEntity[]): EstudioDetalleAvanzadoTraslado[] {
		return entities.map(entity => { return new EstudioDetalleAvanzadoTraslado(entity) });
	}
}

export interface EstudioDetalleAvanzadoTrasladoDto extends EstudioDetalleAvanzadoTraslado {
	errors: string[]
};
