import { ReactNode } from "react";
import TableComponent, { TableComponentProps } from "../../Table/Table.Component";
import TablePrintableObject from "../../Table/types/TablePrintableObject";
import PaginationComponent, { PaginationComponentProps } from "../../ui/molecules/Pagination/PaginationComponent";
import TableScreen from "../TableScreen/Table.Screen";
import "./Table.Pagination.Template.scss";


interface Props<T> {
	className?: string
	title?: string
	grid: TableComponentProps<T>
	pagination: PaginationComponentProps
	filterSection?: ReactNode
}

const TablePaginationTemplate = <T extends TablePrintableObject>(
	{
		className,
		grid,
		title,
		pagination,
		filterSection,
	}: Props<T>
) => {

	return (
		<TableScreen
			title={title}
			filterSection={filterSection}>
			<TableComponent {...grid} />
				<div className={`grid-template__footer-section grid-template__ footer-section--${className}`}>
						<PaginationComponent {...pagination} />
				</div>
		</TableScreen>
	)
}

export default TablePaginationTemplate;