import { Icon } from "@pavabits/components"
import "./Tag.scss";

interface Props {
	label: string
	text: string
	idTest?:string
	handleClear: () => void
}

const Tag: React.FC<Props> = ({
	label,
	text,
	idTest,
	handleClear,
}: Props) => {

	return (
		<span className="tag" data-cy={idTest}>
			<label>{`${label}: `}</label>
			{text}
			<button className={"btn btn--small cancel"} onClick={handleClear} data-cy="BorrarFiltroTag">
				<Icon icon="close"/>
			</button>
		</span>
	)
}

export default Tag;