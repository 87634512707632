import ApiRequest from "../../../../domain/ApiRequest";
import { EstudioDetalleApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import EstudioDetalle, { EstudioDetalleUpdateDto } from "../../domain/model/EstudioDetalle";

export default class EstudioDetalleAdd extends ApiRequest<
EstudioDetalleUpdateDto,
EstudioDetalle
>
{
	constructor(data: EstudioDetalleUpdateDto, token: string) {
		super(
			"POST",
			EstudioDetalleApiEndpoints.CREAR,
			{ Authorization: `${token}`},
			data,
		)
	}
}