import { Icon } from "@pavabits/components";
import { Link } from "react-router-dom";
import { IconDefinition, faRoad } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from '../../../../assets/images/icono-oportunidades-gris.png';

interface NavItem {
	id: string,
	icon?: string,
	label: string,
	navigateTo: string,
	selected: boolean,
	isImage?: boolean
}

interface Props {
	item: NavItem,
	className?: string,
}

const SidebarNavigationItem: React.FC<Props> = (
	{
		item: {
			id,
			label,
			navigateTo,
			icon,
			selected,
			isImage
		},
		className,
	}
) => {

	const baseClass = "nav-item";

	const classes = [baseClass, `${selected ? baseClass + '--selected' : ''}`, className].join(" ").trimEnd();


	let iconoSelect: IconDefinition = faRoad;
	if (icon!.startsWith("fa"))
		switch (icon) {
			case 'faRoad':
				iconoSelect = faRoad;
		}

	return (
		<li
			className={classes}
			data-cy={label.replace(" ","")}
		>
			<Link className="nav-item-content" to={navigateTo}>
				{
					isImage ?
						<img src={logo} width={20} alt='Logo oportunidades'></img>
						:
						(icon && icon!.startsWith("fa")) ? <FontAwesomeIcon icon={iconoSelect}></FontAwesomeIcon> : <Icon icon={icon!} />
				}
				<div>
					{label}
				</div>
			</Link>
		</li>
	)
}

export default SidebarNavigationItem;