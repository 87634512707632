import ApiRequest from "../../../../domain/ApiRequest";
import { OfertasEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { OfertasCreationDto } from "../../domain/model/Ofertas";

export default class OfertasRequesCreate extends ApiRequest<OfertasCreationDto, boolean> {
	constructor(data: OfertasCreationDto, token: string) {
		super(
			"POST",
			OfertasEndpoints.CREATE_UPDATE,
			{ Authorization: token},
			data
		)
	}
}