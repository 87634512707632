import EstudioDetalleAvanzadoEquipo from "./EstudioDetalleAvanzadoEquipo";
import EstudioDetalleAvanzadoMaterial from "./EstudioDetalleAvanzadoMaterial";
import EstudioDetalleAvanzadoPrecios from "./EstudioDetalleAvanzadoPrecios";
import EstudioDetalleAvanzadoTramo from "./EstudioDetalleAvanzadoTramo";
import EstudioDetalleAvanzadoTraslado from "./EstudioDetalleAvanzadoTraslado";

interface EstudioDetalleAvanzadoEntity {
	id?: string;
	material?: EstudioDetalleAvanzadoMaterial[];
	equipo?: EstudioDetalleAvanzadoEquipo[];
	traslado?: EstudioDetalleAvanzadoTraslado[];
	tramo?: EstudioDetalleAvanzadoTramo[];
	totales?: EstudioDetalleAvanzadoPrecios[];
	dias?: number;
	totalizar?: boolean;
}

export default class EstudioDetalleAvanzado implements EstudioDetalleAvanzadoEntity {
	id?: string;
	material?: EstudioDetalleAvanzadoMaterial[];
	equipo?: EstudioDetalleAvanzadoEquipo[];
	tramo?: EstudioDetalleAvanzadoTramo[];
	traslado?: EstudioDetalleAvanzadoTraslado[];
	dias?: number;
	totalizar?: boolean;

	constructor(entity: EstudioDetalleAvanzadoEntity) {
		this.id = entity.id;
		this.material = entity.material;
		this.equipo = entity.equipo;
		this.tramo = entity.tramo;
		this.traslado = entity.traslado;
		this.dias = entity.dias;
		this.totalizar = entity.totalizar;
	}
}

export class EstudioDetalleAvanzadoTotales {
	totales?: EstudioDetalleAvanzadoPrecios[];

	constructor(entity: EstudioDetalleAvanzadoEntity) {
		this.totales = entity.totales;
	}
}