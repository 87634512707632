import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { UnidadObraElementoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { UnidadObraElementoReadDto } from "../../domain/model/UnidadObraElemento";

export default class UnidadObraElementoRequestListByFilter extends ApiRequest<any, BaseResponse<UnidadObraElementoReadDto[]>>
{
	constructor(filter:any, token: string) {
		super(
			"POST",
            UnidadObraElementoApiEndpoints.CON_FILTRO,
            { Authorization: token },
            {
                filter : filter
            }
		)
	}
}