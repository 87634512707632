import { EstudioApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ApiRequest from "../../../../domain/ApiRequest";
import { EstudioDetalleDto } from "../../domain/model/EstudioDetalle";

export default class EstudioUpdateTarifas extends ApiRequest<any, EstudioDetalleDto[]>
{
	constructor(id: string, token: string) {
		super(
			"PUT",
			EstudioApiEndpoints.UPDATE_TARIFAS + `/${id}/update-tarifas`,
			{ Authorization: `${token}`},
			undefined
		)
	}
}