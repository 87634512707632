import React, { ChangeEvent, useContext, useState } from 'react'
import { GlobalContext, GlobalContextType } from '../../context/Global.Context'
import FormFieldGroup from '../../components/ui/objects/FormFieldGroup/FormField.Group';

interface EquiposNombreSearchFieldProps {
  idTest: string;
}

const EquiposNombreSearchField: React.FC <EquiposNombreSearchFieldProps> = ({ idTest }) => {
    const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;
    const [value, setValue] = useState(globalState.equipos?.filters?.nombre ?? "")

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setValue(value);
        updateGlobalState("equipos", 
          {filters: 
          {...globalState.equipos?.filters,
            "nombre": e.target.value.length !== 0 ? e.target.value : undefined},
            pagination: {
              skipCount: 0
            }})
        updateGlobalState("elementos", {filters: 
          {...globalState.elementos?.filters,
            "nombre": e.target.value.length !== 0 ? e.target.value : undefined},
            pagination: {
              skipCount: 0
            }})
    }

  return (
    <div className='input-container'>
    <FormFieldGroup>
    <label>Nombre</label>
    <input
        // className="op-input"
        value={value}
        onChange={handleInputChange}
        data-cy={idTest}
    >
    </input>
</FormFieldGroup >
</div>
  )
}

export default EquiposNombreSearchField