import { useContext } from "react"
import Dropdown from "../../../../components/ui/atoms/Dropdown/Dropdown"
import { DelegacionesDropdownContext } from "../../context/FilterFields.Context";
import FormFieldGroup from "../../../../components/ui/objects/FormFieldGroup/FormField.Group";

interface DelegacionesDropdownProps {
	idTest?:string
}

const DelegacionesDropdown: React.FC<DelegacionesDropdownProps> = ({idTest}) => {

	const options = useContext(DelegacionesDropdownContext);

	return (
		 <div className="input-container" data-cy={idTest}> 
		<FormFieldGroup>
			
			<label>Delegación</label>
			<Dropdown
				label={idTest}
				options={options}
			/>
			 
		</FormFieldGroup>
		</div> 
	)
}

export default DelegacionesDropdown;