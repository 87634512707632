import { useContext } from "react";
import OportunidadesSuggestionInput from "../../../../Oportunidades/views/atoms/SuggestionInputs/Oportunidades.SuggestionInput";
import { PromotorFieldContext } from "../../../context/ModalFields.Context";


const PromotorSuggestionInput: React.FC<{ idTest?:string }> = ({idTest}) => {

	const field = useContext(PromotorFieldContext);

	return (
		<OportunidadesSuggestionInput
			label="Promotor"
			input={field.input}
			suggestions={field.suggestions}
			onInputChange={field.onInputChange}
			idTest={idTest}
		/>
	)
}

export default PromotorSuggestionInput;