import ApiRequest from "../../../../domain/ApiRequest";
import { BaseFilter } from "../../../../domain/BaseFilter";
import BaseResponse from "../../../../domain/BaseResponse";
import { ElementosEquipoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ElementoEquipo from "../../domain/model/ElementoEquipo";
import { EquiposFilter } from "../../domain/model/Equipo";

export default class EquipoElementosRequestListByFilter extends ApiRequest<
any,
BaseResponse<ElementoEquipo[]>>
{
	constructor(data: BaseFilter<EquiposFilter>, token: string) {
		super(
			"GET",
			ElementosEquipoApiEndpoints.ALL_ELEMENTOS+"?sortingCriteria=lastModificationTime+DESC&MaxResultCount="+data.maxResultCount+"&SkipCount="+data.skipCount,
			{ Authorization: token },
			undefined,
			{
             
            }
		)
	}
}