import ApiRequest from "../../../../domain/ApiRequest";
import { ContactoClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ContactoCliente, { ContactoClienteUpdateDto } from "../../domain/model/ContactosCliente";

export default class ContactoClienteRequestAdd extends ApiRequest<
ContactoClienteUpdateDto,
ContactoCliente
>
{
	constructor(data: ContactoClienteUpdateDto, token: string) {
		super(
			"POST",
			ContactoClienteEndpoints.CREAR,
			{ Authorization: `${token}`},
			data,
		)
	}
}