import { ElementosEquipoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ApiRequest from "../../../../domain/ApiRequest";
import ElementoEquipo, { ElementoEquipoUpdateDto } from "../../domain/model/ElementoEquipo";

export default class EquipoElementoRequestUpdate extends ApiRequest<
ElementoEquipoUpdateDto,
ElementoEquipo
>
{
	constructor(data: ElementoEquipoUpdateDto, token: string) {
		super(
			"PUT",
			ElementosEquipoApiEndpoints.UPDATE_ELEMENTO,
			{ Authorization: `${token}`},
			data,
		)
	}
}