import { BaseApiRequest } from "@pavabits/components";
import BaseReponse from "../../../../domain/BaseResponse";
import { ProvinciasEndpoints } from "../oportunidades.api-endpoints";
import { ProvinciaDto, ProvinciaFilter } from "../../domain/model/Provincia";

export default class ProvinciaRequestListBySearch extends BaseApiRequest<
ProvinciaFilter,
BaseReponse<ProvinciaDto[]>
> {
	public constructor(data: ProvinciaFilter, token: string) {
		super(
			"POST",
			ProvinciasEndpoints.PROVINCIAS_CON_FILTRO,
			data,
			{ Authorization: `${token}`}
		)
	}
}