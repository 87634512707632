import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";
import BaseResponse from "../../../../domain/BaseResponse";
import ClonarOfertas from "../../../Ofertas/domain/model/ClonarOfertas";
import PresupuestoRepository from "../../domain/PresupuestoRepository";
import FicherosAdjuntos from "../../domain/model/FicherosAdjuntos";
import Presupuesto, { PresupuestoDto, PresupuestoFilter, PresupuestoUpdateDto } from "../../domain/model/Presupuesto";
import PresupuestoClausula from "../../domain/model/PresupuestoClausula";
import PresupuestBorrador from "../ws/Presupuesto.Borrador";
import PresupuestoAdd from "../ws/Presupuesto.Request.Add";
import PresupuestoDelete from "../ws/Presupuesto.Request.Delete";
import PresupuestoRequestGetById from "../ws/Presupuesto.Request.GetById";
import PresupuestoRequestGetByOfertaId from "../ws/Presupuesto.Request.GetByOfertaId";
import PresupuestoRequestSend from "../ws/Presupuesto.Request.Send";
import PresupuestoUpdate from "../ws/Presupuesto.Request.Update";
import PresupuestoSimpleUpdate from "../ws/Presupuesto.Request.SimpleUpdate";
import PresupuestoClausulaDelete from "../ws/PresupuestoClausula.Request.Delete";
import PresupuestoClausulaGetByPresupuestoId from "../ws/PresupuestoClausula.Request.GetByPresupuestoId";


export default class PresupuestoApiRepository
	extends ApiRepositoryBase<
		PresupuestoDto,
		Presupuesto
	> implements PresupuestoRepository {
	async add(data: Presupuesto): Promise<Presupuesto> {
		const res = await new PresupuestoAdd(data, this.authToken).executeAsPromise();
		return res;
	}

	async update(data: PresupuestoUpdateDto): Promise<Presupuesto> {
		return await new PresupuestoUpdate(data, this.authToken).executeAsPromise();
	}

	async delete(id: string): Promise<boolean> {
		return await new PresupuestoDelete(id, this.authToken).executeAsPromise();
	}
	
	async simpleUpdate(data: PresupuestoUpdateDto): Promise<Presupuesto> {
		return await new PresupuestoSimpleUpdate(data, this.authToken).executeAsPromise();
	}

	async getById(id: string): Promise<PresupuestoDto> {
		
		const res = await new PresupuestoRequestGetById(id, this.authToken).executeAsPromise();
		return res;
	}
	
	async getByOfertaId(filter: PresupuestoFilter): Promise<Presupuesto[]> {
		
		const res = await new PresupuestoRequestGetByOfertaId(filter, this.authToken).executeAsPromise();
		return Presupuesto.toArray(res?.items);
	}

	async enviar(id: string, ofertaId: string,ficherosAdjuntos: FicherosAdjuntos[]): Promise<PresupuestoDto> {
		
		const res = await new PresupuestoRequestSend({presupuestoId: id, ofertaId: ofertaId, ficherosAdjuntos }, this.authToken).executeAsPromise();
		return res;
	}

	buildModelFromItems(item: PresupuestoDto): Presupuesto {
		return new Presupuesto(item);
	}

	protected transformDtoIntoModel(dto: BaseResponse<PresupuestoDto>): BaseResponse<Presupuesto> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}

	// CLAUSULAS PRESUPUESTOS
	async deletePresupuestoClausula(id: string): Promise<boolean> {
		await new PresupuestoClausulaDelete(id, this.authToken).executeAsPromise();
		return true;
	}

	async getByPresupuestoId(id: string): Promise<PresupuestoClausula[]> {
		
		const res = await new PresupuestoClausulaGetByPresupuestoId(id, this.authToken).executeAsPromise();
		return PresupuestoClausula.toArray(res?.items);
	}

	
	async borradorPresupuesto(data: ClonarOfertas): Promise<string> {
		
		return  await new PresupuestBorrador(data, this.authToken).executeAsPromise();
	}

}