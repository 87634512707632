import React, { useContext } from 'react'
import { GlobalContext, GlobalContextType } from '../../../context/Global.Context';
import TagCloud from '../../../components/ui/objects/TagCloud/TagCloud';

interface Props {
  idTest?:string
}

const SeguimientoClienteTagCloud: React.FC<Props> = ({idTest}: Props) => {
    const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;

    const tags = [
        {
            label: "Cliente",
            text: String(globalState?.seguimientoCliente?.filters?.clienteDescripcion).length > 0 
            && globalState?.seguimientoCliente?.filters?.clienteDescripcion !== undefined 
            ? String(globalState?.seguimientoCliente?.filters?.clienteDescripcion) 
            : "",
            onClear: () => updateGlobalState("seguimientoCliente", {filters: {...globalState.seguimientoCliente?.filters,"clienteId": undefined, "clienteDescripcion": undefined}})
        },
        {
            label: "Tipo",
            text: String(globalState?.seguimientoCliente?.filters?.tipoDescripcion).length > 0 
            && globalState?.seguimientoCliente?.filters?.tipoDescripcion !== undefined 
            ? String(globalState?.seguimientoCliente?.filters?.tipoDescripcion) 
            : "",
            onClear: () => updateGlobalState("seguimientoCliente", {filters: {...globalState.seguimientoCliente?.filters,"tipoId": undefined, "tipoDescripcion": undefined}})
        },
        {
          label: "Delegacion",
          text: String(globalState?.seguimientoCliente?.filters?.delegacionDescripcion).length > 0 
          && globalState?.seguimientoCliente?.filters?.delegacionDescripcion !== undefined 
          ? String(globalState?.seguimientoCliente?.filters?.delegacionDescripcion) 
          : "",
          onClear: () => updateGlobalState("seguimientoCliente", {filters: {...globalState.seguimientoCliente?.filters,"delegacionId": undefined, "delegacionDescripcion": undefined}})
      },
        {
          label: "Fecha",
          text: String(globalState?.seguimientoCliente?.filters?.tipoId).length > 0 
          && globalState?.seguimientoCliente?.filters?.fechaSeguimientoDesde !== undefined 
          ? new Date(String(globalState?.seguimientoCliente?.filters?.fechaSeguimientoDesde)).toLocaleDateString('es-es', { month: '2-digit', day: '2-digit', year: 'numeric', hour12: false }) + " - " +
            new Date(String(globalState?.seguimientoCliente?.filters?.fechaSeguimientoHasta)).toLocaleDateString('es-es', { month: '2-digit', day: '2-digit', year: 'numeric', hour12: false })
          : "",
          onClear: () => updateGlobalState("seguimientoCliente", {filters: {...globalState.seguimientoCliente?.filters,"fechaSeguimientoDesde": undefined, "fechaSeguimientoHasta": undefined }})
      },
      {
        label: "Oportunidad",
        text: String(globalState?.seguimientoCliente?.filters?.oportunidadCodigo).length > 0 
        && globalState?.seguimientoCliente?.filters?.oportunidadCodigo !== undefined 
        ? String(globalState?.seguimientoCliente?.filters?.oportunidadCodigo) 
        : "",
        onClear: () => updateGlobalState("seguimientoCliente", {filters: {...globalState.seguimientoCliente?.filters,"oportunidadCodigo": undefined}})
    },
      {
        label: "Oferta",
        text: String(globalState?.seguimientoCliente?.filters?.ofertaCodigo).length > 0 
        && globalState?.seguimientoCliente?.filters?.ofertaCodigo !== undefined 
        ? String(globalState?.seguimientoCliente?.filters?.ofertaCodigo) 
        : "",
        onClear: () => updateGlobalState("seguimientoCliente", {filters: {...globalState.seguimientoCliente?.filters,"ofertaCodigo": undefined}})
    },
      {
        label: "Contacto",
        text: String(globalState?.seguimientoCliente?.filters?.contactoNombre).length > 0 
        && globalState?.seguimientoCliente?.filters?.contactoNombre !== undefined 
        ? String(globalState?.seguimientoCliente?.filters?.contactoNombre) 
        : "",
        onClear: () => updateGlobalState("seguimientoCliente", {filters: {...globalState.seguimientoCliente?.filters,"contactoNombre": undefined}})
    }
    ]

  return (
    <TagCloud tags={tags} idTest={idTest}/>
  )
}

export default SeguimientoClienteTagCloud