import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import Dropdown, { DropdownProps } from '../../../../../components/ui/atoms/Dropdown/Dropdown';
import FormInput from '../../../../../components/ui/atoms/Input/FormInput/FormInput';
import ModalPage from '../../../../../components/ui/atoms/ModalPage/ModalPage';
import FormField from '../../../../../components/ui/molecules/Form-field/FormField';
import { useLoading } from '../../../../../context/Loading.Context';
import { CustomButton } from '../../../../../shared/components/Buttons/CustomButton';
import { ClausulaReadDto } from '../../../../Clausulas/domain/model/Clausula';
import { AuthContext } from '../../../../Login/AuthContextProvider';
import ClausulaOferta from '../../../../ClausulasOferta/domain/model/ClausulaOferta';
import ClausulasOfertaRepository from '../../../../ClausulasOferta/domain/ClausulasOfertaRepository';
import ClausulasOfertaApiRepository from '../../../../ClausulasOferta/infrastructure/api/ClausulasOfertaApiRepository';
import CustomTextInput from '../../../../../components/CustomTextInput';

interface ClausulasOfertaModalSectionProps {
  opened?: boolean;
  currentClausulaOferta: ClausulaOferta;
  clausulas?: ClausulaReadDto[];
  handleCloseModal: () => void;
  handleRefresh: () => void;
}

const ClausulaOfertaModalSection: React.FC<ClausulasOfertaModalSectionProps> = ({ opened, currentClausulaOferta, clausulas, handleCloseModal, handleRefresh }) => {
  const { getToken } = useContext(AuthContext);
  const { setLoading } = useLoading();
  const [clausulaOferta, setClausulaOferta] = useState<ClausulaOferta>(currentClausulaOferta);
  const [selectedClausula, setSelectedClausula] = useState<ClausulaReadDto>();

  const setClausulaOfertaValue = (property: string, newValue: any) => {
    setClausulaOferta({ ...clausulaOferta, [property]: newValue });
  };

  const setNegrita = async () => {
		setClausulaOferta({ ...clausulaOferta, negrita: !clausulaOferta.negrita });
	}

  const isValid = () => {
    return clausulaOferta.nombre && clausulaOferta.descripcion && clausulaOferta.orden;
  }
  
  const generarClausulaOferta = () => {
    if (!isValid()) return;
    
    const clausulaOfertaRepository: ClausulasOfertaRepository = new ClausulasOfertaApiRepository(getToken());
    setLoading(true);

    if (clausulaOferta.id && clausulaOferta.id !== '') {      
      clausulaOfertaRepository.update(clausulaOferta)
        .then((resp) => {
          toast.success('Cláusula actualizada correctamente');
          handleRefresh();
        }
        ).finally(() => {
          setLoading(false);
        });
    } else {
      let creationDto = { 
        ofertaId: clausulaOferta.ofertaId, 
        clausulaId: clausulaOferta.clausulaId, 
        orden: clausulaOferta.orden, 
        negrita: clausulaOferta.negrita,
        descripcion: clausulaOferta.descripcion,
        nombre: clausulaOferta.nombre
      };
      clausulaOfertaRepository.add(creationDto)
        .then(() => {
          toast.success('Cláusula añadida correctamente');
          handleRefresh();
        }
        ).finally(() => {
          setLoading(false);
        });;
    }
  }

  const mapToClausulaOferta = (clausula: ClausulaReadDto | undefined) => {
    if (clausula) {
      setClausulaOferta({ ...clausulaOferta, nombre: clausula.nombre, descripcion: clausula.descripcion, clausulaId: clausula.id, clausula: clausula });    
    } else {
      setClausulaOferta({ ...clausulaOferta, clausulaId: undefined, clausula: undefined });
    }
  }

  const handleClickClausulas = (clausula: ClausulaReadDto | undefined) => {
    if (clausula?.id === selectedClausula?.id) {
      clausula = undefined;      
    }

    setSelectedClausula(clausula);
    mapToClausulaOferta(clausula);
  }

  return (
    <div>
        {
          <ModalPage
            handleCloseModal={handleCloseModal}
            opened={opened}
            fields={
              <>
                {clausulas && clausulas.length > 0 && 
                  <div className='double-col-grid'>
                  {
                    clausulaOferta.id === '' && clausulas &&
                    <FormField<DropdownProps>
                      label="Cláusulas"
                      options={clausulas.map(
                        (element) => {
                          return {
                            text: element.nombre,
                            id: element.id,
                            selected: selectedClausula?.id === element.id,
                            onClick: () => { handleClickClausulas(element); }
                          }
                        })}
                      disabled={false}
                      singleSelection={true}
                      component={Dropdown}
                    />
                  }                    
                </div>}
                <div className='full-width'>
                  <CustomTextInput 
                    label="Nombre" 
                    name="nombre"
                    value={clausulaOferta.nombre}
                    onChange={(e: { target: { name: string; value: string; }; }) => 
                      setClausulaOfertaValue(e.target.name, e.target.value)}
                    required
                    disabled={clausulaOferta?.clausulaId !== undefined}
                  />
                <div className='row-of-three' style={{marginTop: '18px'}}>
                  <FormInput
                      label="Orden"
                      value={clausulaOferta.orden.toString()}
                      onChange={(e) => setClausulaOfertaValue("orden", e.target.value)}
                      type='number'
                      min={1}
                      required
                    />  
                  <div className="flex-horizontal">
                    <label>Negrita</label>
                    <input
                      style={{cursor: 'pointer'}}
                      checked={clausulaOferta.negrita}
                      onChange={setNegrita} 
                      type="checkbox"
                    />
                  </div>  
                </div>
                </div>
                <div className='full-width'>
                  <CustomTextInput
                    label="Descripción"
                    name="descripcion"
                    value={clausulaOferta.descripcion}
                    onChange={(e: { target: { name: string; value: string; }; }) => 
                      setClausulaOfertaValue(e.target.name, e.target.value)}
                    resizable
                    required
                  />
                </div>
                <div className="buttons-right">
                  <CustomButton
                    key={`Guardar`}
                    title={'Guardar'}
                    className={'btn-primary'}
                    btnStyle="accept"
                    onButtonClick={() => { generarClausulaOferta(); }}
                    type="button"
                    text="Guardar"
                  />
                </div>
              </>
            }
            
            modalTitle={`${clausulaOferta.id !== '' ? "Editar" : "Añadir"} cláusula`}
            modalSubtitle={`Complete los campos necesarios para ${clausulaOferta.id === '' ? "añadir" : "editar"} una cláusula`}
          >
          </ModalPage>
        }
    </div>
  )
}

export default ClausulaOfertaModalSection

