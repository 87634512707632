import { error } from "console";
import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";
import ClausulasRepository from "../../domain/ClausulasRepository";
import ClausulasRequestFetchAll from "../ws/ClausulasRequestFetchAll";
import { BaseRepository } from "@pavabits/components";
import BaseResponse from "../../../../domain/BaseResponse";
import Clausula, { ClausulaCreateDto, ClausulaReadDto, ClausulaUpdateDto } from "../../domain/model/Clausula";
import ClausulaAdd from "../ws/Clausula.Request.Add";
import ClausulaDelete from "../ws/Clausula.Request.Delete";
import ClausulaUpdate from "../ws/Clausula.Request.Update";
import ClausulasRequestGetById from "../ws/Clausula.Request.GetById";
import ClausulasRequestListByFilter from "../ws/Clausula.Request.ListByFilter";

export default class ClausulasApiRepository
extends ApiRepositoryBase<ClausulaReadDto, Clausula> implements ClausulasRepository {

     async fetchClausulasByFilter(data: any): Promise<BaseResponse<ClausulaReadDto[]>> {
        const res = await new ClausulasRequestListByFilter(data, this.authToken).executeAsPromise();
		return res;
    }

	async fetchClausulas(): Promise<ClausulaReadDto[]> {
        const res = await new ClausulasRequestFetchAll(this.authToken).executeAsPromise();
		return res?.items;
    }

	async add(data: ClausulaCreateDto): Promise<Clausula> {
		const res = await new ClausulaAdd(data, this.authToken).executeAsPromise();
		return res;
	}

	async delete(id: string): Promise<boolean> {
		await new ClausulaDelete(id, this.authToken).executeAsPromise();
		return true;  
	}

	async update(data: ClausulaUpdateDto): Promise<ClausulaUpdateDto> {
		return await new ClausulaUpdate(data, this.authToken).executeAsPromise();
	}

	async fetchClausulasResponse(data: {maxResultCount: number, skipCount: number, sortingCriteria: string}): Promise<BaseResponse<Clausula[]>> {
		const res = await new ClausulasRequestFetchAll(this.authToken, data).executeAsPromise();
		return res;
	}

	async getById(id: string): Promise<ClausulaReadDto> {
		return await new ClausulasRequestGetById(id, this.authToken).executeAsPromise()
	}

    buildModelFromItems(item: ClausulaReadDto): Clausula{
		return new Clausula(item);
	}

	protected mapResponseItems(dto: BaseResponse<ClausulaReadDto[]>): BaseResponse<Clausula[]> {
		return (
			{
				items: dto.items.map(item => this.buildModelFromItems(item)),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}

	protected transformDtoIntoModel(dto: BaseResponse<ClausulaReadDto>): BaseResponse<Clausula> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}
}