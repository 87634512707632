import { filter } from "lodash";
import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { UnidadObraApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { UnidadReadDto } from "../../domain/model/Unidad";

export default class UnidadesObraRequestByDelegacionId extends ApiRequest<any, BaseResponse<UnidadReadDto[]>>
{
    public constructor (id: string,token: string) {
        super (
            "POST",
            UnidadObraApiEndpoints.CON_FILTRO,
            { Authorization: token },
            {
                filter : {
                    delegacionIds : [id]
                }
            }
        )


    }
}