import React, { FormEvent, useCallback, useContext, useEffect, useRef, useState } from 'react'
import FormTemplate from '../../components/common/FormTemplate/FormTemplate'
import CustomTextInput from '../../components/CustomTextInput';
import { EquipoUpdateDto, EquipoDetalle } from './domain/model/Equipo';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../Login/AuthContextProvider';
import EquiposRepository from './domain/EquiposRepository';
import EquipoApiRepository from './infrastructure/api/Equipo.ApiRepository';
import Dropdown, { DropdownProps } from '../../components/ui/atoms/Dropdown/Dropdown';
import FormField from '../../components/ui/molecules/Form-field/FormField';
import { DelegacionDto } from '../../domain/model/Delegacion';
import DelegacionesRepository from '../../domain/DelegacionesRepository';
import DelegacionesApiRepository from '../../infraestructure/api/Delegaciones.ApiRepository';
import SuggestionInputField, { SuggestionInputFieldProps } from '../../components/ui/atoms/SuggestionInput/SuggestionInput.Field';
import { ElementoEquipoDto } from './domain/model/ElementoEquipo';
import IconButton from '../../components/ui/atoms/Buttons/IconButton';
import { toast } from 'react-toastify';
import { useLoading } from '../../context/Loading.Context';
import { formatNumber } from '../../utils/NumberUtil';
import { VentanaPermiso } from '../Login/domain/model/VentanaPermiso';
import { COD_DELEGACIONES_CON_TODAS, USER_ROLES } from '../../assets/constants/Constantes';
import TooltipComponent from '../../components/ui/molecules/Tooltip/TooltipComponent';

interface Props {
  permisoVentana: VentanaPermiso
}

const EquiposEdicion = ({ permisoVentana }: Props) => {
  const formId = "equipos-edit-form";
  const location = useLocation();
  const url = location.pathname.split("/")
  const selectedEEquipooId = url[url.length - 1];
  const { getToken, hasRole, getDelegacion } = useContext(AuthContext);
  const [permisoModificacion, setPermisoModificacion] = useState<boolean>(permisoVentana.permisoModificacion);
  const [elementos, setElementos] = useState<ElementoEquipoDto[]>([]);

  const [elementosSelected, setElementosSelected] = useState<ElementoEquipoDto[]>([])
  const [selectedElementos, setSelectedElementos] = useState<string[]>([]);
  const [elementosList, setElementosList] = useState<ElementoEquipoDto[]>([]);

  const [optionsDelegaciones, setOptionsDelegaciones] = useState<DelegacionDto[]>([]);
  const [selectedDelegacion, setSelectedDelegacion] = React.useState<DelegacionDto>({ id: "", descripcion: "" });
  const delegacionRef = useRef(0);

  const [tarifaTotal, setTarifaTotal] = useState(0);
  const { setLoading } = useLoading();
  const equiposRepo: EquiposRepository = new EquipoApiRepository(getToken());

  const [equipo, setEquipo] = useState<EquipoUpdateDto>({
    id: "",
    delegacionId: -1,
    unidadObraElementoTipoId: 4,
    nombre: "",
    elemento: [],
    descripcion: "",
    equipoDetalles: []
  })

  useEffect(() => {
    fetchDelegaciones();
    getEquipoById(selectedEEquipooId);
  }, [])

  const getEquipoById = useCallback((id: string) => {
    setLoading(true);
    return equiposRepo.getEquipoById(id)
      .then((res) => {
        if (res) {
          setEquipo(res as EquipoUpdateDto);
          delegacionRef.current = res.delegacionId;
          setSelectedDelegacion({ id: res.delegacionId.toString(), descripcion: res.descripcion })
          if (res.equipoDetalles) {
            setSelectedElementos(res.equipoDetalles.map(x => { return x.unidadObraElementoId }));
            setElementosSelected(mapToElementoEquipoDto(res.equipoDetalles));
          }
          // Si el usuario no es Admin puede ver datos de todas las delegaciones pero sólo crear y editar datos de su delegación
          setPermisoModificacion(permisoModificacion && (hasRole(USER_ROLES.ADMIN) || res.delegacionId === +getDelegacion()?.id));
        }
      })
      .finally(() => {
        setLoading(false);
      })
  }, [])

  const mapToElementoEquipoDto = (detalles: EquipoDetalle[]): ElementoEquipoDto[] => {
    return detalles.map(elemento => {
      return {
        id: elemento.id,
        elementoId: elemento.unidadObraElementoId,
        descripcion: elemento.unidadObraElementoDescripcion,
        unidadTipoDescripcion: elemento.unidadTipoDescripcion,
        unidadMedidaDescripcion: elemento.unidadMedidaDescripcion,
        text: elemento.unidadObraElementoDescripcion,
        tarifa: elemento.tarifa,
        rendimiento: elemento.rendimiento.toString()
      } as ElementoEquipoDto
    })

  }

  useEffect(() => {
    const elementosDto = elementosSelected.map((el) => {
      return {
        id: el.id == el.elementoId ? undefined : el.id,
        unidadObraElementoId: el.elementoId,
        equipoId: equipo.id,
        rendimiento: el.rendimiento,
        tarifa: el.tarifa,
        unidadMedidaDescripcion: el.unidadMedidaDescripcion,
        unidadTipoDescripcion: el.unidadTipoDescripcion
      }
    })

    setEquipo({ ...equipo, equipoDetalles: elementosDto })
  }, [elementosSelected])

  useEffect(() => {
    calculateTarifa()
  }, [elementosSelected, equipo.equipoDetalles])

  const calculateTarifa = () => {
    let total = 0
    equipo.equipoDetalles.map((el) => {
      let elRendimiento = el.rendimiento
      if (elRendimiento) {
        total = total + (el.tarifa * +elRendimiento)
      }
    })
    setTarifaTotal(+total.toFixed(2))
  }

  const fetchElementos = useCallback(
    (search: string) => {

      var delegaciones = [+delegacionRef.current, 50];

      return equiposRepo.getConFiltro({
        filter: {
          nombre: search,
          delegacionIds: delegaciones
        }
      }).then((resp) => {
        setElementos(resp)
        return resp;
      });
    },
    [selectedDelegacion])

  const fetchDelegaciones = useCallback(
    () => {
      const repo: DelegacionesRepository = new DelegacionesApiRepository(getToken());
      return repo.fetchDelegaciones({
        filter: {
          ids: COD_DELEGACIONES_CON_TODAS
        }
      }).then((resp) => {
        setOptionsDelegaciones(resp);
      })
    }, [])

  const handleClickDelegaciones = (delegacion: DelegacionDto) => {
    if ((selectedDelegacion.id !== delegacion.id)) {
      delegacionRef.current = +delegacion.id;
      setSelectedDelegacion(delegacion);
      setEquipo({ ...equipo, delegacionId: +delegacion.id })
    } else {
      setSelectedDelegacion({ id: "", descripcion: "" })
      delegacionRef.current = 0;
    }
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateEquipo(equipo)
    setEquipo(equipo)
  }

  const updateEquipo = useCallback((data: EquipoUpdateDto) => {
    setLoading(true);
    equiposRepo.updateEquipo(data)
      .then((res) => {
        toast.success("Equipo actualizado con éxito");
      })
      .catch(err => {
        toast.error(err);
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  const handleEquipo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEquipo(prev => ({ ...prev, [name]: value }))
  }

  const onChangeElemento = (s: string[]) => {
    let listaActualizada: ElementoEquipoDto[] = [];
    let elementosActualizados: ElementoEquipoDto[] = [...elementosSelected, ...elementos] as ElementoEquipoDto[]
    setSelectedElementos(s);

    s.forEach(element => {
      if (element) {
        let obj: ElementoEquipoDto | undefined = elementosActualizados.find(x => x.id === element || x.elementoId === element);

        if (obj && !listaActualizada.find(x => x.elementoId === obj?.id)) {
          listaActualizada.push({
            ...obj,
            rendimiento: obj.rendimiento ?? "1",
            elementoId: obj.elementoId ?? obj.id,
            unidadMedidaDescripcion: obj.tipoUnidad ?? obj.unidadMedidaDescripcion,
            unidadTipoDescripcion: obj.tipoDescripcion ?? obj.unidadTipoDescripcion
          });
        }
      }
    });

    setElementosSelected(listaActualizada);
  }

  const onDeleteElemento = (id: string) => {
    setSelectedElementos(selectedElementos.filter((item) => item !== id));
    setElementosSelected(elementosSelected.filter((item) => item.elementoId !== id));
  }

  const handleRendimiento = (e: React.ChangeEvent<HTMLInputElement>, item: any) => {
    let updatedIndex = equipo.equipoDetalles?.findIndex((el) => el.unidadObraElementoId === item.id)

    let updatedDetalle = equipo.equipoDetalles?.map((el, index) => {
      if (updatedIndex === index) {
        return {
          unidadObraElementoId: el.unidadObraElementoId,
          rendimiento: (e.target.value),
          tarifa: el.tarifa
        }
      } else {
        return el
      }
    })

    setEquipo({ ...equipo, equipoDetalles: updatedDetalle })
    setElementosSelected(elementosSelected.map((el) => {
      if (item.elementoId == el.elementoId) {
        return {
          ...el, rendimiento: (e.target.value)
        }
      }
      else {
        return el;
      }
    }));
  }


  return (
    <div className='add-edit-view-container'>
      <div className='add-edit-header'>
        <h4>{permisoModificacion ? "Editar " : ""}Equipo</h4>
      </div>
      <div className="add-edit-body">
        <FormTemplate
          formId={formId}
          onSubmit={handleSubmit}
          backUrl={true}
          readOnly={!permisoModificacion}
        >
          <div className="row-of-two">
            <div className='input-container'>

              <CustomTextInput
                label="Nombre"
                name="nombre"
                value={equipo.nombre}
                onChange={handleEquipo}
                required
                idTest="Nombre"
              />
            </div>

            <div className='input-container'>
              <FormField<DropdownProps>
                label="Delegación"
                options={optionsDelegaciones.filter(x => hasRole(USER_ROLES.ADMIN) || +x.id === equipo.delegacionId).map(
                  (element) => {
                    return {
                      text: element.descripcion,
                      id: element.id,
                      selected: selectedDelegacion.id == element.id ? true : false,
                      onClick: () => { handleClickDelegaciones(element); }
                    }
                  })}
                required={true}
                disabled={false}
                singleSelection={true}
                component={Dropdown}
              />
            </div>
            <div className='input-container'>
              <CustomTextInput
                label="Descripción"
                name="descripcion"
                value={equipo.descripcion}
                onChange={handleEquipo}
                required
                idTest="Descri"
              />
            </div>
            <div className='input-container'>

              <CustomTextInput
                label="Tarifa"
                name="tarifa"
                value={String(formatNumber(tarifaTotal))}
                onChange={handleEquipo}
                disabled
                idTest="Tarifa"
              />
            </div>
          </div>

          <div className='row-of-three'>
            <FormField<SuggestionInputFieldProps>
              label="Elementos"
              value={
                {
                  id: selectedElementos?.join(","),
                  text: ""
                }
              }
              className={"op-input95"}
              disabled={!selectedDelegacion}
              required
              onChange={(e) => onChangeElemento(e)}
              searchCallback={fetchElementos}
              singleSelection={false}
              component={SuggestionInputField}
              idTest="ElementosInput"
            />
          </div>

          <div className='single-row'>
            <div className='table-container'>
            {elementosSelected && <table className='mini-table'>
              <thead>
                <tr className='table-header'>
                  <th>Elemento</th>
                  <th>Tipo</th>
                  <th>Tarifa</th>
                  <th>Unidad</th>
                  <th>Rendimiento</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {elementosSelected.map((item: ElementoEquipoDto, index: number) => (
                  <tr key={item.id}>
                    <td>
                      <TooltipComponent text={item.descripcion}>
                        {item.descripcion}
                      </TooltipComponent>
                    </td>
                    <td>{item.unidadTipoDescripcion}</td>
                    <td>{formatNumber(item.tarifa)}</td>
                    <td>{item.unidadMedidaDescripcion}</td>
                    <td>
                      <input className='inline-input'
                        onChange={(e) => handleRendimiento(e, item)}
                        defaultValue={1}
                        value={item.rendimiento}
                        step="any"
                        type='number'
                        min={0}
                        required />
                    </td>

                    <td><IconButton
                      icon="delete"
                      onClick={() => onDeleteElemento(item.elementoId)}
                      index={index}
                    /></td>
                  </tr>
                ))}
              </tbody>
            </table>}
          </div>
          </div>

        </FormTemplate>
      </div>
    </div>
  )
}

export default EquiposEdicion