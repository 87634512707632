import { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useState } from 'react'
import FormTemplate from '../../components/common/FormTemplate/FormTemplate'
import CustomTextInput from '../../components/CustomTextInput';
import { AuthContext } from '../Login/AuthContextProvider';
import { toast } from 'react-toastify';
import { ClausulaCreateDto } from './domain/model/Clausula';
import ClausulasRepository from './domain/ClausulasRepository';
import ClausulasApiRepository from './infrastructure/api/ClausulasApiRepository';
import DelegacionesApiRepository from '../../infraestructure/api/Delegaciones.ApiRepository';
import DelegacionesRepository from '../../domain/DelegacionesRepository';
import { DelegacionDto } from '../../domain/model/Delegacion';
import { useLoading } from '../../context/Loading.Context';
import { HistoryHandler } from '../../context/History.Context';
import { Routes } from '../../router/routes/Routes';
import FormField from '../../components/ui/molecules/Form-field/FormField';
import Dropdown, { DropdownProps } from '../../components/ui/atoms/Dropdown/Dropdown';
import { VentanaPermiso } from '../Login/domain/model/VentanaPermiso';
import { COD_DELEGACIONES_SIN_TODAS, USER_ROLES } from '../../assets/constants/Constantes';

interface Props {
  permisoVentana: VentanaPermiso
}

const ClausulasGrabacion = ({ permisoVentana }: Props) => {
  const COD_DELEGACION_TODAS = -1;
  const formId = "clausulas-form";
  const [delegaciones, setDelegaciones] = useState<DelegacionDto[]>([{ descripcion: "TODAS", id: "-1" }])
  const [newClausula, setNewClausula] = useState<ClausulaCreateDto>({
    descripcion: '',
    nombre: '',
    delegacionId: 1,
    orden: null,
    negrita: false
  })

  const { setLoading } = useLoading();
  const { getToken, getDelegacion, hasRole } = useContext(AuthContext);
  const { goToRoute } = useContext(HistoryHandler);

  useEffect(() => {
    fetchDelegaciones()
  }, [])

  const fetchDelegaciones = useCallback(() => {
    const repo: DelegacionesRepository = new DelegacionesApiRepository(getToken());
    setLoading(true);
    const showDelegaciones = hasRole(USER_ROLES.ADMIN);
    return repo.fetchDelegacionesDto({ filter: { ids: showDelegaciones ? COD_DELEGACIONES_SIN_TODAS : [getDelegacion()?.id] } }).then((data) => {
      const delegacionesUpd = data;
      if (showDelegaciones) delegacionesUpd.push({ descripcion: "TODAS", id: "-1" })
      setDelegaciones(delegacionesUpd);
      setNewClausula(prev => ({ ...prev, delegacionId: Number.parseInt(getDelegacion().id), orden: null }));

    })
      .catch(message => toast.error(message))
      .finally(() => { setLoading(false); });
  }, [getToken()]);

  const handleSelectDelegacion = (delegacion: DelegacionDto) => {
    const delegacionObj: DelegacionDto | undefined = delegaciones.find(((item: DelegacionDto) => item.id === delegacion.id));
    if (delegacionObj) {
      const clausulaUpd: DelegacionDto = { ...delegacionObj }
      setNewClausula(prev => ({ ...prev, delegacionId: +clausulaUpd.id}));
    }
  }

  const saveClausula = useCallback((data: ClausulaCreateDto) => {
    setLoading(true);
    const repo: ClausulasRepository = new ClausulasApiRepository(getToken());

    if (data.delegacionId && data.delegacionId === COD_DELEGACION_TODAS) {
      data.delegacionId = null;
    }

    repo.add(data).then((res) => {
      goToRoute(Routes.CLAUSULAS_EDICION, { id: res?.id })
      toast.success("Cláusula añadida con éxito");
    })
      .catch(err => {
        toast.error(err);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    saveClausula(newClausula)
    setNewClausula({
      descripcion: '',
      delegacionId: null,
      nombre: '',
      orden: 0,
      negrita: false
    });
  }

  const handleNewClausula = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewClausula(prev => ({ ...prev, [name]: value }));
  }

  const setNegrita = async () => {
  	setNewClausula({ ...newClausula, negrita: !newClausula.negrita });
  }

  return (
    <div className='add-edit-view-container'>
      <div className='add-edit-header'>
        <h4>Nueva Cláusula</h4>
      </div>
      <div className="add-edit-body">
        <FormTemplate
          formId={formId}
          onSubmit={handleSubmit}
        >

          <div className="row-of-two">
            {delegaciones &&
              <FormField<DropdownProps>
                label="Delegación"
                options={delegaciones.map(
                  (element) => {
                    return {
                      text: element.descripcion,
                      id: element.id,
                      selected: (Number.isNaN(newClausula.delegacionId) ? Number.parseInt(getDelegacion().id) : newClausula.delegacionId) == +element.id ? true : false,
                      onClick: () => { handleSelectDelegacion(element); },
                    }
                  })}
                required={true}
                disabled={false}
                singleSelection={true}
                component={Dropdown}

              />
            }
            <CustomTextInput
              label="Nombre"
              name="nombre"
              value={newClausula.nombre}
              onChange={handleNewClausula}
              required
            />
          </div>
          <div className='row-of-three' style={{ marginTop: '18px' }}>
            <CustomTextInput
              label="Orden"
              name="orden"
              value={newClausula.orden }
              onChange={handleNewClausula}
              type='number'
              required
              min={1}
            />
            <div className="flex-horizontal">
              <label>Negrita</label>
              <input
                style={{ cursor: 'pointer' }}
                checked={newClausula.negrita!}
                name="negrita"
                onChange={setNegrita}
                type="checkbox"
                data-cy="NegritaClausula"
              />
            </div>
          </div>
          <div className='single-row'>
            <CustomTextInput
              label="Cláusula"
              name="descripcion"
              value={newClausula.descripcion}
              onChange={handleNewClausula}
              resizable
              required />
          </div>
        </FormTemplate>
      </div>
    </div>
  )
}

export default ClausulasGrabacion