import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { MotivosEndpoints, TipoOfertasEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import Motivo from "../../domain/model/Motivo";

export default class MotivoGetAll extends ApiRequest<undefined, BaseResponse<Motivo[]>>
{
	constructor(token: string) {
		super(
			"GET",
			MotivosEndpoints.BASE_ENPOINT + "?MaxResultCount=100",
			{ Authorization: token },
			undefined,
			{ }
		)
	}
}