import SelectableItem, { SelectableItemDto } from "../../components/ui/atoms/SuggestionInput/model/SuggestionInput.Model";
import { BaseFilter } from "../BaseFilter";

export type EstadoOportunidadesesFilter = BaseFilter<EstadoOportunidades>;

export interface EstadoOportunidadesDto extends SelectableItemDto { };

export default class EstadoOportunidades extends SelectableItem { };


export interface EstadoOportunidadesDetalleDto extends EstadoOportunidadesEntity { };


interface EstadoOportunidadesEntity {
    id: string,
    descripcion: string
}


export class EstadoOportunidadesDetalle implements EstadoOportunidadesEntity {

    id: string;
    descripcion: string;


    constructor(entity: EstadoOportunidadesEntity) {

        this.id = entity.id;
        this.descripcion = entity.descripcion
    }
    
}
