import { useContext } from "react";
import {LicitamosDropdownContext } from "../../context/FilterFields.Context";
import FormFieldGroup from "../../../../components/ui/objects/FormFieldGroup/FormField.Group";
import Dropdown from "../../../../components/ui/atoms/Dropdown/Dropdown";

enum LicitamosOptions {
	SI = "S",
	NO = "N",
	SIN_DETERMINAR = " ",
}

interface LicitamosDropdownProps {
	idTest?: string;
}

const LicitamosDropdown: React.FC<LicitamosDropdownProps> = ({ idTest }) => {

	const options = useContext(LicitamosDropdownContext);

	return (
			<FormFieldGroup>
				<div className="input-container">
				<label>Licitamos</label>
				<Dropdown
				label={idTest}
					options={options}
				/>
				</div>
			</FormFieldGroup>
	)
}

export default LicitamosDropdown;