import { BaseRepository } from "@pavabits/components";
import UsuarioRepository from "../../domain/PermisoVentanaRepository";
import FuncionalidadesRequestGet from "../ws/Funcionalidades.Request.Get";
import { VentanaPermiso, VentanaPermisoDto } from "../../domain/model/VentanaPermiso";

export default class PermisoVentanaApiRepository extends BaseRepository<any,any> implements UsuarioRepository {
	protected transformDtoIntoModel(dto: VentanaPermisoDto[]): VentanaPermiso[] {
		if (dto && dto.length) {
			return dto.map(item => new VentanaPermiso(item))
		}
		return [];
	}

	fetchUserModules(): Promise<VentanaPermiso[]> {
		return new Promise((resolve, reject) => {
			return new FuncionalidadesRequestGet(this.authToken)
				.request()
				.then((response: any) => {
					if (response.success) {
						resolve(this.transformDtoIntoModel(response.data.items));
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error: any) => {
					reject(error);
				})
		});
	}
}