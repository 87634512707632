import React, { ChangeEvent, useContext, useState } from 'react'
import FormFieldGroup from '../../components/ui/objects/FormFieldGroup/FormField.Group'
import { GlobalContext, GlobalContextType } from '../../context/Global.Context';

const ContactosNameSearchField: React.FC = () => {
  const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;
  const componentName = "contactos"

  const [value, setValue] = useState(globalState.contactos?.filters?.personaContacto ?? "")

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setValue(value);
        updateGlobalState(componentName, {
          filters: {...globalState.contactos?.filters,
            "personaContacto":  e.target.value.length !== 0 ? e.target.value : undefined},
            pagination: {
              skipCount: 0
            }
          })
    }

  return (
    <FormFieldGroup>
      <div className="input-container">
    <label>Nombre y apellidos</label>
    <input
        value={value}
        onChange={handleInputChange}
        data-cy="NombreApellidosContactoFiltro"
    >
    </input>
    </div>
</FormFieldGroup >
  )
}

export default ContactosNameSearchField