import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import ModalPage from "../../../../../../components/ui/atoms/ModalPage/ModalPage";
import UnidadMedida from "../../../../../../domain/model/UnidadMedida";
import EquiposRepository from "../../../../../Equipos/domain/EquiposRepository";
import { EquipoCalculo, EquipoDetalle, EquipoUpdateDto } from "../../../../../Equipos/domain/model/Equipo";
import EquipoApiRepository from "../../../../../Equipos/infrastructure/api/Equipo.ApiRepository";
import EstudioDetalle from "../../../../../Estudios/domain/model/EstudioDetalle";
import EstudioDetalleAvanzado from "../../../../../Estudios/domain/model/EstudioDetalleAvanzado";
import EstudioDetalleAvanzadoEquipo from "../../../../../Estudios/domain/model/EstudioDetalleAvanzadoEquipo";
import { AuthContext } from "../../../../../Login/AuthContextProvider";
import UnidadesRepository from "../../../../../Unidades/domain/UnidadesRepository";
import { UnidadEquipoReadDto } from "../../../../../Unidades/domain/model/UnidadEquipo";
import UnidadesApiRepository from "../../../../../Unidades/infraestructure/api/UnidadesApiRepository";
import EstudioDetalleAvanzadoEquipoRepository from "../../../../domain/EstudioDetalleAvanzadoEquipoRepository";
import EstudioDetalleAvanzadoEquipoApiRepository from "../../../../infraestructure/api/EstudioDetalleAvanzadoEquipo.ApiRepository";
import "./CalculoAvanzado.scss";
import { updateEquipos, } from "./Calculos";
import FormField from "../../../../../../components/ui/molecules/Form-field/FormField";
import SuggestionInputField, { SuggestionInputFieldProps } from "../../../../../../components/ui/atoms/SuggestionInput/SuggestionInput.Field";
import { ElementoEquipoDto } from "../../../../../Equipos/domain/model/ElementoEquipo";
import { CustomButton } from "../../../../../../shared/components/Buttons/CustomButton";
import { formatNumber } from "../../../../../../utils/NumberUtil";
import IconButton from "../../../../../../components/ui/atoms/Buttons/IconButton";
import CustomTextInput from "../../../../../../components/CustomTextInput";
import { useLoading } from "../../../../../../context/Loading.Context";
import { DelegacionDto } from "../../../../../../domain/model/Delegacion";
import TooltipComponent from "../../../../../../components/ui/molecules/Tooltip/TooltipComponent";
import EstudioDetalleAvanzadoElemento from "../../../../../Estudios/domain/model/EstudioDetalleAvanzadoElemento";
import EstudioDetalleAvanzadoElementoRepository from "../../../../domain/EstudioDetalleAvanzadoElementoRepository";
import EstudioDetalleAvanzadoElementoApiRepository from "../../../../infraestructure/api/EstudioDetalleAvanzadoElemento.ApiRepository";
import { auto } from "@popperjs/core";
import Input from "../../../../../../components/ui/atoms/Input/FormInput/Input";

interface Props {
	openModal: boolean
	handleCloseModal: (elementos: EstudioDetalleAvanzadoElemento[], indexEquipo: number, equipo: EquipoCalculo) => void
	handleCloseCancelModal: () => void
	equipoCalculo: EquipoCalculo
	indexEquipo: number
	unidadObraId?: string
	unidadObraDescripcion?: string
	estudioDetalleDescripcion?: string[]
	unidadMedida?: UnidadMedida
	estudioDetalleAvanzado?: EstudioDetalleAvanzado,
	estudioDetalleId?: string,
	estudioDetalleAvanzadoId?: string,
	coeficienteEstudio?: number,
	delegacionId: string,
	readOnly?: boolean,
}


const CalculoAvanzadoElementosEquipo: React.FC<Props> = ({ openModal,
	handleCloseModal,
	handleCloseCancelModal,
	estudioDetalleAvanzado,
	indexEquipo,
	estudioDetalleAvanzadoId,
	delegacionId,
	unidadObraId,
	readOnly,
	equipoCalculo }) => {


	const { getToken } = useContext(AuthContext);

	const [equiposUnidad, setEquiposUnidad] = useState<UnidadEquipoReadDto[]>([]);
	const [equiposCalculos, setEquiposCalculos] = useState<EquipoCalculo[]>(
		estudioDetalleAvanzado?.equipo != undefined ?
			estudioDetalleAvanzado?.equipo?.map(entity => {
				return {
					id: entity.id,
					equipoId: entity.equipoId,
					descripcion: entity.equipoDescripcion,
					tarifa: entity.tarifa,
					unidades: entity.unidades,
					elementos: entity.elementos,
					costeEquipo: 0,
					dias: entity.dias.toString()
				}
			})
			: []
	);

	const mapToElementoAvanzadoEquipoDto = (detalles: ElementoEquipoDto[]): EstudioDetalleAvanzadoElemento[] => {
		return detalles.map(elemento => {
			return {
				descripcion: elemento.descripcion,
				unidadObraElementoId: elemento.elementoId,
				rendimiento: elemento.rendimiento,
				tarifa: elemento.tarifa.toString(),
				tipoDescripcion: elemento.tipoDescripcion,
				tipoUnidad: elemento.tipoUnidad
			} as EstudioDetalleAvanzadoElemento
		})
	}
	const [elementosSelected, setElementosSelected] = useState<ElementoEquipoDto[]>([]);
	const [elementoCalculos, setElementoCalculos] = useState<EstudioDetalleAvanzadoElemento[]>(
		equipoCalculo?.elementos != undefined ?
			equipoCalculo?.elementos?.map(entity => {
				return {
					id: entity.id,
					unidadObraElementoId: entity.unidadObraElementoId,
					descripcion: entity.descripcion,
					tarifa: entity.tarifa,
					rendimiento: entity.rendimiento,
					tipoDescripcion: entity.tipoDescripcion,
					tipoUnidad: entity.tipoUnidad
				}
			})
			:
			[]
	);

	const [equipo, setEquipo] = useState<EquipoUpdateDto>({
		id: "",
		delegacionId: -1,
		unidadObraElementoTipoId: 4,
		nombre: "",
		descripcion: "",
		equipoDetalles: []
	})
	const [tarifaTotal, setTarifaTotal] = useState(0);
	const [selectedElementos, setSelectedElementos] = useState<string[]>([]);
	const [elementosList, setElementosList] = useState<ElementoEquipoDto[]>([]);
	const [elementos, setElementos] = useState<ElementoEquipoDto[]>([]);
	const equiposRepo: EquiposRepository = new EquipoApiRepository(getToken());
	const { setLoading } = useLoading();
	const delegacionRef = useRef(0);
	const [selectedDelegacion, setSelectedDelegacion] = useState<DelegacionDto>({ id: "", descripcion: "" });


	useEffect(() => {
		fetchUnidadObraEquipos(unidadObraId ?? "");

		// calculos equipos
		for (let stepEquipos = 0; stepEquipos < equiposCalculos.length; stepEquipos++) {
			updateEquipo(equiposCalculos[stepEquipos].dias, equiposCalculos[stepEquipos].unidades, stepEquipos);
		}

		// calculos traslados
	}, [unidadObraId])


	const fetchElementos = useCallback(
		(search: string) => {
			return equiposRepo.getConFiltro({
				filter: {
					nombre: search,
					delegacionIds: [+delegacionId]
				}
			}).then((resp) => {
				
				setElementos(resp)
				return resp;
			});
		}, [])

	useEffect(() => {
		getEquipoById(equipoCalculo.equipoId);
	}, [])

	const getEquipoById = useCallback((id: string) => {
		setLoading(true);
		return equiposRepo.getEquipoById(id)
			.then((res) => {
				if (res) {
					setEquipo(res as EquipoUpdateDto);
					delegacionRef.current = res.delegacionId;
					setSelectedDelegacion({ id: res.delegacionId.toString(), descripcion: res.descripcion })
					if (res.equipoDetalles) {
						setSelectedElementos(res.equipoDetalles.map(x => { return x.id ? x.id : "" }))
						setElementosSelected(mapToElementoEquipoDto(res.equipoDetalles))
					}
					// Si el usuario no es Admin puede ver datos de todas las delegaciones pero sólo crear y editar datos de su delegación
					// setPermisoModificacion(permisoModificacion && (hasRole(USER_ROLES.ADMIN) || res.delegacionId === +getDelegacion()?.id));
				}
			})
			.finally(() => {
				setLoading(false);
			})
	}, [])

	useEffect(() => {
		if (elementoCalculos.length == 0) {
			setElementoCalculos(mapToElementoAvanzadoEquipoDto(elementosSelected))
			calculateTarifa()
		}
	}, [elementosSelected])



	const mapToElementoEquipoDto = (detalles: EquipoDetalle[]): ElementoEquipoDto[] => {
		return detalles.map(elemento => {
			return {
				id: elemento.id,
				elementoId: elemento.unidadObraElementoId,
				descripcion: elemento.unidadObraElementoDescripcion,
				text: elemento.unidadObraElementoDescripcion,
				tarifa: elemento.tarifa,
				rendimiento: elemento.rendimiento.toString()
			} as ElementoEquipoDto
		})
	}

	const fetchUnidadObraEquipos = useCallback(
		(id: string) => {
			const repo: UnidadesRepository = new UnidadesApiRepository(getToken());

			repo.getEquiposUnidadConFiltro(id).then((resp) => {
				setEquiposUnidad([...equiposUnidad, ...resp]);
			})
		}, []);

	const updateEquipo = (dias: string | undefined = undefined, unidades: string | undefined = undefined, index: number) => {
		let equiposCalc = updateEquipos(dias, unidades, index, [...equiposCalculos])
		setEquiposCalculos(equiposCalc);
	}

	const updateTarifa = (tarifa: number, index: number) => {
		const elementos = [...elementoCalculos]
		elementos[index].tarifa = tarifa > 0 || tarifa.toString() == "" ? tarifa.toString() : ""

		let total = 0
		elementos.map(elemento => {
			total += +elemento.tarifa * +elemento.rendimiento
		})
		setElementoCalculos(elementos)
		setTarifaTotal(total)
	}

	const updateRendimiento = (rendimiento: number, index: number) => {
		const elementos = [...elementoCalculos]
		elementos[index].rendimiento = rendimiento > 0 || rendimiento.toString() == "" ? rendimiento.toString() : ""

		let total = 0
		elementos.map(elemento => {
			total += +elemento.tarifa * +elemento.rendimiento
		})
		setElementoCalculos(elementos)
		setTarifaTotal(total)
	}

	const updateDescripcion = (desc: any, index: number) => {
		const elementos = [...elementoCalculos]
		elementos[index].descripcion = desc
		setElementoCalculos(elementos)
	}

	const onDeleteElemento = (id: string, index: number) => {
		const repoDetalleElemento: EstudioDetalleAvanzadoElementoRepository = new EstudioDetalleAvanzadoElementoApiRepository(getToken());

		if (elementoCalculos.length > 1) {
			setSelectedElementos(selectedElementos.filter((item) => item !== id))
			setElementosSelected(elementosSelected.filter((item) => item.id !== id))
			if (elementoCalculos[index].id) {
				repoDetalleElemento.delete(elementoCalculos[index].id!).then((resp) => {
					const elemento = [...elementoCalculos];
					elemento.splice(index, 1);
					setElementoCalculos(elemento);
					// setElementoCalculos(elementoCalculos.filter((item) => item.id !== id))
					toast.success("Elemento eliminado correctamente");
				});
			} else {
				const elementos = [...elementoCalculos]
				elementos.splice(index, 1)
				setElementoCalculos(elementos)
				// setElementoCalculos(elementoCalculos.filter((item) => item.id !== id))
			}
		} else {
			toast.warning("El equipo tiene que tener elementos");
		}
	}

	const onChangeElemento = (arrayIds: string[]) => {
		var listaActualizada: ElementoEquipoDto[] = [];

		var elementosActualizados: ElementoEquipoDto[] = [...elementos, ...elementoCalculos] as ElementoEquipoDto[]
		setSelectedElementos(arrayIds);
		arrayIds.forEach(element => {
			if (element != "") {
				var obj: ElementoEquipoDto = elementosActualizados.find(x => x.id == element) ?? { id: "", elementoId: "", descripcion: "", rendimiento: "0", tarifa: 0, text: "",tipoDescripcion: "", tipoUnidad: "" };

				if (obj && !elementoCalculos.find(x => x.unidadObraElementoId === obj?.id)) {
					listaActualizada.push({ ...obj, rendimiento: obj.rendimiento ?? "1", elementoId: obj.elementoId ?? obj.id, tipoDescripcion: obj.tipoDescripcion, tipoUnidad: obj.tipoUnidad });
				}
			}

		});

		setElementoCalculos([...elementoCalculos, ...mapToElementoAvanzadoEquipoDto(listaActualizada)]);
		setElementosSelected([]);

	}

	const handleEquipo = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setEquipo(prev => ({ ...prev, [name]: value }))
	}

	useEffect(() => {
		calculateTarifa()
	}, [elementoCalculos, equipo.equipoDetalles])


	const calculateTarifa = () => {
		let total = 0
		elementoCalculos.map((el) => {
			let elRendimiento = el.rendimiento
			if (elRendimiento) {
				total = total + (+el.tarifa * +elRendimiento)
			}
		})
		setTarifaTotal(+total.toFixed(2))
	}

	const closeModal = () => {

		handleCloseModal(elementoCalculos, indexEquipo, equipoCalculo);

	}

	const comprobarRendimiento = () => {
		let rendimientoNull = false
		let rendimiento = elementoCalculos.filter(x => x.rendimiento == "" || +x.rendimiento == 0)
		let tarifa = elementoCalculos.filter(x => x.tarifa == "" || +x.tarifa == 0)

		if (rendimiento.length > 0 || tarifa.length > 0) {
			rendimientoNull = true
		}

		return rendimientoNull
	}

	const [finalizar, setFinalizar] = useState(false)

	useEffect(() => {
		setFinalizar(comprobarRendimiento())
	})

	return (
		<div>
			<ModalPage
				handleCloseModal={closeModal}
				handleCloseModalCustom={handleCloseCancelModal}
				opened={openModal}
				fields={
					<>
						<div className="row-of-three">
							<FormField<SuggestionInputFieldProps>
								label="Elementos"
								value={
									{
										id: elementosList.find(x => x.id == selectedElementos[0]) ? elementosList.find(x => x.id == selectedElementos[0])!.id : "",
										text: elementosList.find(x => x.id == selectedElementos[0]) ? elementosList.find(x => x.id == selectedElementos[0])!.text : ""
									}
								}
								className={"op-input95"}
								required
								onChange={(e) => onChangeElemento(e)}
								searchCallback={fetchElementos}
								singleSelection={false}
								component={SuggestionInputField}
							/>

							<div></div>

							<div className='input-container'>
								<CustomTextInput
									label="Tarifa"
									name="tarifa"
									value={String(formatNumber(tarifaTotal))}
									onChange={handleEquipo}
									disabled
								/>
							</div>
						</div>
						<div className="elementos-table">
							{elementosSelected &&
								<table className='mini-table' style={{margin: '0'}}>
									<thead>
										<tr className='table-header'>
											<th>Elemento</th>
											<th>Tipo</th>
											<th>Tarifa</th>
											<th>Unidad</th>
											<th>Rendimiento</th>
										</tr>
									</thead>
									<tbody>
										{elementoCalculos.map((item: any, index: number) => (
											<tr key={index}>
												<td>
													<TooltipComponent text={item.descripcion}>
														<input className='inline-input'
															value={item.descripcion}
															onChange={(e) => updateDescripcion(e.target.value, index)}
															required
														/>
													</TooltipComponent>
												</td>
												<td>{item.tipoDescripcion ?? equipo.equipoDetalles.find((el) => el.unidadObraElementoId === item.unidadObraElementoId)?.unidadTipoDescripcion}</td>
												<td >
													<Input 
														className='inline-input'
														onChange={(e) => updateTarifa(+e.target.value, index)}
														value={item.tarifa}
														min={0}
														required
														type='number'
													/>
												</td>
												<td>{item.tipoUnidad ?? equipo.equipoDetalles.find((el) => el.unidadObraElementoId === item.unidadObraElementoId)?.unidadMedidaDescripcion}</td>
												<td>
													<Input 
														className='inline-input'
														onChange={(e) => updateRendimiento(+e.target.value, index)}
														value={item.rendimiento}
														min={0}
														required
														type='number'
													/>
												</td>
												<td style={{ width: '70px' }}>
													<div className="icons-horizontal-inlinegrid">
														<IconButton
															icon="delete"
															onClick={() => onDeleteElemento(item.id, index)}
														/>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							}
						</div>

						<div className="buttons-right">
							<button
								className="btn-primary"
								onClick={() => handleCloseCancelModal()}
							>
								Cancelar
							</button>

							<CustomButton
								title={"Guardar"}
								className="btn-primary"
								btnStyle="accept"
								onButtonClick={() => { closeModal(); }}
								type="button"
								text="Finalizar"
								disabled={finalizar}
							/>
						</div>
					</>
				}
				modalTitle={"Elementos del equipo"}
				bodyClassName="--medium"
				modalDisable={readOnly}
			>
			</ModalPage>
		</div >
	)
}

export default CalculoAvanzadoElementosEquipo;