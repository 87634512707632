import React from "react";
import { UseCheckOutput, UseCheckStrOutput, UseDateOutput, UseDropdownOutput, UseFieldOutput } from "../hooks/useFilterFields";

type IFieldContext = UseFieldOutput["field"];

const defaultFieldContext: IFieldContext = {
	input: "",
	suggestions: [],
	onInputChange: () => {}
};


export const ProvinciaFieldContext = React.createContext(defaultFieldContext);
export const PromotorFieldContext = React.createContext(defaultFieldContext);
export const ContratistaFieldContext = React.createContext(defaultFieldContext);
export const ClienteFieldContext = React.createContext(defaultFieldContext);

const defaultFieldSearchContext = {
	onSearch: (value:string) => {},
	onFilter: (value:FilterTag) => {},
	onClear: () => {}
}

export const ObraDescripcionFieldContext = React.createContext(defaultFieldSearchContext);
export const OportunidadFieldContext = React.createContext(defaultFieldSearchContext);
export const CodigoLicitacionFieldContext = React.createContext(defaultFieldSearchContext);
export const CodigoOfertaFieldContext = React.createContext(defaultFieldSearchContext);

export const BancoFieldContext = React.createContext(defaultFieldContext);


export type FilterTag = {
	label: string,
	text?: string,
	onClear: () => void,
}

interface ITagsReadContext {
	tags: FilterTag[],
}

const defaultTagsReadContext: ITagsReadContext = {
	tags: []
}
export const TagsReadContext = React.createContext(defaultTagsReadContext);

type IDropdownContext = UseDropdownOutput["options"];

const defaultDropdownContext: IDropdownContext = [];
export const DelegacionesDropdownContext = React.createContext(defaultDropdownContext);
export const TipoOfertasDropdownContext = React.createContext(defaultDropdownContext);
export const OfertasDelegacionesDropdownContext = React.createContext(defaultDropdownContext);
export const OfertaEstadoDropdownContext = React.createContext(defaultDropdownContext);
export const ComercialesDropdownContext = React.createContext(defaultDropdownContext);
export const AsfalteroDropdownContext = React.createContext(defaultDropdownContext);
export const LicitamosDropdownContext = React.createContext(defaultDropdownContext);
export const EstadoOportunidadDropdownContext = React.createContext(defaultDropdownContext);
export const TipoOportunidadDropdownContext = React.createContext(defaultDropdownContext);

type ICheckContext = UseCheckOutput;
type ICheckStrContext = UseCheckStrOutput;



const defaultCheckContext: ICheckContext["field"] = {
	options: [],
	onCheck: () => {},
	onClear: () => {},
}

export const ContratistaDropdownContext = React.createContext(defaultDropdownContext);


const defaultCheckLicitamosContext: ICheckStrContext["field"] = {
	options: [],
	onCheck: () => {},
	onClear: () => {},
}






type IDateContext = UseDateOutput["field"];
const defaultDateContext: IDateContext = {
	onDateSelect: () => {}
}

export const FechaRegistroContext = React.createContext(defaultDateContext);

export const FechaPresupuestoContext = React.createContext(defaultDateContext);

export const FechaAdjudicacionContext = React.createContext(defaultDateContext);