import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { EstudioDetalleApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import EstudioDetalle from "../../domain/model/EstudioDetalle";

export default class EstudioDetalleRequestGetByEstudioId extends ApiRequest<any, BaseResponse<EstudioDetalle[]>>
{
	constructor(id: string, token: string) {
		super(
			"GET",
			EstudioDetalleApiEndpoints.DEFAULT_FILTER + "?Filters[0].Key=estudioId&Filters[0].Value=" + id,
			{ Authorization: token },
			undefined,
			{
			}
		)
	}
}