
import ApiRequest from "../../domain/ApiRequest";
import BaseResponse from "../../domain/BaseResponse";
import { UnidadMedidaDto } from "../../domain/model/UnidadMedida";
import { UnidadesMedidaEndpoints } from "../../modules/Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class UnidadesMedidaRequestListByFilter extends ApiRequest<any, BaseResponse<UnidadMedidaDto[]>>
{
	constructor(token: string) {
		super(
			"GET",
			UnidadesMedidaEndpoints.DEFAULT_FILTER + "?Filters[0].Key=Visible&Filters[0].Value=true",
			{ Authorization: token },
			undefined,
			{
			}
		)
	}
}