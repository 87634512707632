import { useCallback, useContext } from "react"
import OportunidadesRepository from "../domain/OportunidadesRepository"
import OportunidadesApiRepository from "../infraestructure/api/Oportunidades.ApiRepository"
import { usePsQuery } from "@pavabits/components"
import { BaseFilter } from "../../../domain/BaseFilter"
import { OportunidadFilter } from "../domain/model/Oportunidad"
import { AuthContext } from "../../Login/AuthContextProvider"

function useOportunidadesSumaToneladasPost(filter: BaseFilter<OportunidadFilter>): number {

	const {getToken} = useContext(AuthContext);

	const fetchOportunidadesSumaToneladas = useCallback(() => {
		const repo: OportunidadesRepository = new OportunidadesApiRepository(getToken());
		return repo.fetchOportunidadesSumaToneladas(filter);
	}, [
		filter,getToken
	]);

	const { data } = usePsQuery(fetchOportunidadesSumaToneladas);

	return data;
	
}

export default useOportunidadesSumaToneladasPost;