import ApiRequest from "../../../../domain/ApiRequest";
import { SeguimientoHistoricoClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class SeguimientoHistoricoClienteRequestAdd extends ApiRequest<any, any>
{
    constructor(data: any, token: string) {
        super(
            "POST",
            SeguimientoHistoricoClienteEndpoints.CREAR,
            { Authorization: `${token}`},
            data
        )
    }
}