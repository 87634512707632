import React from 'react'

export type CustomDropDownProps = {
  name?: string;
  inputLabel?: string;
  disabled?: boolean;
  onChange?: any;
  value?: string;
  options?: any;
  placeholder?: string;
  error?: string;
}

const CustomDropdownInput = (props: CustomDropDownProps) => {
  return (
    <div className='input-container'>
      <label htmlFor={props.name}>{props.inputLabel}</label>
      <select 
        onChange={props.onChange} 
        value={props.value || ''} 
        disabled={props.disabled ?? false}>
        {props.placeholder && props.value === "" && <option value="">{props.placeholder}</option>}
        {props.options && props.options.map((option: any, index: number) => (
          <option key={index} value={option.descripcion ?? option}>
            {option.descripcion ?? option}
          </option>
        ))}
      </select>
      {
              props.error && <span className="custom-input__label--error">{props.error}</span>
      }
    </div>
  )
}

export default CustomDropdownInput