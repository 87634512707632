import { useContext } from "react";
import { ContratistaFieldContext } from "../../../context/FilterFields.Context";
import OportunidadesSuggestionInput from "./Oportunidades.SuggestionInput";

interface ContratistaSuggestionInputProps {
	disabled: boolean
	idTest?:string
}

const ContratistaSuggestionInput: React.FC<ContratistaSuggestionInputProps> = ({disabled, idTest}) => {

	const field = useContext(ContratistaFieldContext);

	return (
		<div className="search">
			<OportunidadesSuggestionInput
				label="Buscar contratista"
				disabled={disabled}
				idTest={idTest}
				{...field}
			/>
		</div>
	)
}

export default ContratistaSuggestionInput;