import React, { useContext } from 'react'
import FormFieldGroup from '../../../components/ui/objects/FormFieldGroup/FormField.Group'
import { GlobalContext, GlobalContextType } from '../../../context/Global.Context'

const SeguimientoClienteOportunidadIdDropdownField = () => {
  const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;
  const componentName = "seguimientoCliente";

const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  updateGlobalState(componentName, {
	filters: {...globalState.seguimientoCliente?.filters,
		"oportunidadCodigo":  e.target.value.length !== 0 ? Number(e.target.value) : undefined},
		pagination: {
			skipCount: 0
		  }})
}

  return (
    <div className='input-container'>
		<FormFieldGroup>
			<label>Código Oportunidad</label>
			<input 
				   placeholder="Cod. Oportunidad"
				   onChange={handleInputChange}
				   data-cy='FiltroCodigoOportunidadSeguimientoCliente'>
			</input>
		</FormFieldGroup>
		</div>
  )
}

export default SeguimientoClienteOportunidadIdDropdownField