import ApiRequest from "../../../../domain/ApiRequest";
import { EstudioApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import Estudio, { EstudioUpdateDto } from "../../domain/model/Estudio";

export default class EstudioAdd extends ApiRequest<
EstudioUpdateDto,
Estudio
>
{
	constructor(data: EstudioUpdateDto, token: string) {
		super(
			"POST",
			EstudioApiEndpoints.CREAR,
			{ Authorization: `${token}`},
			data,
		)
	}
}