import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";
import BaseResponse from "../../../../domain/BaseResponse";
import ContactosRepository from "../../domain/ContactosRepository";
import { default as ContactoCliente, ContactoClienteDto, ContactoClienteSelectable, ContactoClienteUpdateDto, default as ContactosCliente } from "../../domain/model/ContactosCliente";
import ContactoClienteRequestAdd from "../ws/ContactoCliente.Request.Add";
import ContactoClienteRequestDelete from "../ws/ContactoCliente.Request.Delete";
import ContactoClientesRequestGetByClienteId from "../ws/ContactoCliente.Request.GetByClienteId";
import ContactoClientesRequestGetByClienteIdAndStrContacto from "../ws/ContactoCliente.Request.GetByClienteIdAndStrContacto";
import ContactoClienteRequestGetByContacto from "../ws/ContactoCliente.Request.GetByContacto";
import ContactoClientesRequestPostClientes from "../ws/ContactoCliente.Request.PostClientes";
import ContactoClienteRequestUpdate from "../ws/ContactoCliente.Request.Update";

export default class ContactoClienteApiRepository
	extends ApiRepositoryBase<
		ContactoClienteDto,
		ContactosCliente
	> implements ContactosRepository {

	
	async add(data: ContactosCliente): Promise<ContactoCliente> {
		return await new ContactoClienteRequestAdd(data, this.authToken).executeAsPromise();
	}

	async getByClienteId(id: number): Promise<ContactoCliente[]> {
		const res= await new ContactoClientesRequestGetByClienteId(id, this.authToken).executeAsPromise();
		return ContactoCliente.toArray(res?.items);
	}


	async postClientes(id: number, delegaciones: number[], firmante : boolean): Promise<ContactoCliente[]> {
		const res= await new ContactoClientesRequestPostClientes(id,delegaciones, firmante, this.authToken).executeAsPromise();
		return ContactoCliente.toArray(res?.items);
	}

	async getByClienteIdAndPersonaContacto(id: number, personaContacto:string): Promise<ContactoClienteSelectable[]> {
		const res= await new ContactoClientesRequestGetByClienteIdAndStrContacto(id,personaContacto,  this.authToken).executeAsPromise();
		return res.map((item)=>{return new ContactoClienteSelectable(item.id, item.personaContacto)});
	}

	async getByPersonaContacto(personaContacto: string): Promise<any[]> {
		const res= await new ContactoClienteRequestGetByContacto(personaContacto,  this.authToken).executeAsPromise();
		return res;
		// return res.map((item)=>{return new ContactoClienteSelectable(item.id, item.personaContacto)});
	}


	async update(data: ContactoClienteUpdateDto): Promise<ContactoCliente> {
		return await new ContactoClienteRequestUpdate(data, this.authToken).executeAsPromise();
	}

	async delete(id: string): Promise<boolean> {
		await new ContactoClienteRequestDelete(id, this.authToken).executeAsPromise();
		return true;
	}

	buildModelFromItems(item: ContactoClienteDto): ContactoCliente {
		return new ContactoCliente(item);
	}

	protected transformDtoIntoModel(dto: BaseResponse<ContactoClienteDto>): BaseResponse<ContactoCliente> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}


}