import { BaseEntity } from "@pavabits/components";

export class ConfiguracionGrid extends BaseEntity {
	readonly proyecto: string;
	readonly entity: string;
	readonly attributeName: string;
	readonly columnName: string;
	readonly sortable: boolean;
	readonly type: string;
	readonly width: string;

	constructor(
		id: string,
		proyecto: string,
		entity: string,
		attributeName: string,
		columnName: string,
		sortable: boolean,
		type: string,
		width: string
	) {
		super(id, "");
		this.proyecto = proyecto;
		this.entity = entity;
		this.attributeName = attributeName;
		this.columnName = columnName;
		this.sortable = sortable;
		this.type = type;
		this.width = width;
	}
}