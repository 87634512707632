import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import EquiposGrabacion from "./Equipos.Grabacion";
import IconButton from "../../components/ui/atoms/Buttons/IconButton";

interface Props {
    openParent: boolean,
    idTestBotonGuardar?:string,
    idTestDropDelegacion?:string,
    handleCloseModal: () => void
}


const EquiposModal: React.FC<Props> = (
    {
        openParent,
        idTestBotonGuardar,
        idTestDropDelegacion,
        handleCloseModal
    }: Props
) => {




    return (
        <Modal
            className="filters-modal modal-l"
            show={openParent}
            // onHide={() => { handleCloseModal() }}
            >
            <ModalHeader>
                <ModalTitle>
                    Añadir Equipo
                </ModalTitle>
                <IconButton
                    icon="close"
                    onClick={() => handleCloseModal()}
                />
            </ModalHeader>
            <ModalBody>
                <EquiposGrabacion idTestBotonGuardar={idTestBotonGuardar} idTestDropDelegacion={idTestDropDelegacion}redirectPageOnSave={false} extraActionOnClose={handleCloseModal}></EquiposGrabacion>
            </ModalBody>
        </Modal>
    )
}

export default EquiposModal;