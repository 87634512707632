import ApiRequest from "../../../../domain/ApiRequest";
import { EstudioApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class EstudioRequestGetById extends ApiRequest<
string
>
{
	constructor(id: string, token: string) {
		super(
			"GET",
			EstudioApiEndpoints.GET_BY_ID+id,
			{ Authorization: `${token}`},
			""
			
		)
	}
}