import React, { useContext } from 'react'
import { GlobalContext, GlobalContextType } from '../../../context/Global.Context';
import TagCloud from '../../../components/ui/objects/TagCloud/TagCloud';

const UnidadesTagCloud = () => {
    const componentName = "unidades"
    const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;

    const tags = [
        {
            label: "Delegaciones",
            text:  
            globalState[componentName]?.filters?.userDelegacion !== undefined
            ? ""
            : globalState[componentName]?.filters?.delegacionNombre 
            && globalState[componentName]?.filters?.delegacionNombre.length > 0 
            ? globalState[componentName]?.filters?.delegacionNombre : "",
            onClear: () => { 
              updateGlobalState(componentName, {filters: {...globalState[componentName]?.filters,"delegacionNombre": undefined, "delegacionIds": undefined}})
            }
        },
        {
            label: "Unidad de Obra",
            text: globalState[componentName]?.filters?.descripcion?.length > 0 
            && globalState[componentName]?.filters?.descripcion !== undefined 
            ? globalState[componentName]?.filters?.descripcion 
            : "",
            onClear: () => {
              updateGlobalState(componentName, {filters: {...globalState[componentName]?.filters,"descripcion": undefined}})
            }
        },
        {
            label: "Descripción",
            text: globalState[componentName]?.filters?.descripcionUnidadObra?.length > 0 
            && globalState[componentName]?.filters?.descripcionUnidadObra !== undefined 
            ? globalState[componentName]?.filters?.descripcionUnidadObra 
            : "",
            onClear: () => {
              updateGlobalState(componentName, {filters: {...globalState[componentName]?.filters,"descripcionUnidadObra": undefined}})
            }
        },
        {
            label: "Unidad de Medida",
            text: globalState[componentName]?.filters?.unidadMedidaDescripcion?.length > 0 
            && globalState[componentName]?.filters?.unidadMedidaDescripcion !== undefined 
            ? globalState[componentName]?.filters?.unidadMedidaDescripcion 
            : "",
            onClear: () => {
              updateGlobalState(componentName, {filters: {...globalState[componentName]?.filters,"unidadMedidaDescripcion": undefined, "unidadMedidaId": undefined}})
            }
        },
    ]


  return (
    <TagCloud tags={tags} />
  )
}

export default UnidadesTagCloud