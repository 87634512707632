import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { UnidadObraMaterialApiEndpoints, UnidadesMedidaEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { UnidadMaterialReadDto } from "../../domain/model/UnidadMaterial";



export default class UnidadesMaterialRequestListByFilter extends ApiRequest<any, BaseResponse<UnidadMaterialReadDto[]>>
{
	constructor(id:string, token: string) {
		super(
			"GET",
			UnidadObraMaterialApiEndpoints.DEFAULT_FILTER + "?Filters[0].Key=unidadObraId&Filters[0].Value=" + id+"&SortingCriteria=Id%20DESC&MaxResultCount=99&SkipCount=0",
			{ Authorization: token },
			undefined,
			{
			}
		)
	}
}