import SelectableItem, { SelectableItemDto } from "../../components/ui/atoms/SuggestionInput/model/SuggestionInput.Model";
import { AutoCompleteSearchFilter, BaseFilter, BiasedType } from "../BaseFilter";


type FilterExcludedProperties =
"id"
| "text";

type BiasedBanco = BiasedType<Banco, FilterExcludedProperties>;

export type BancoFilter = AutoCompleteSearchFilter<BiasedBanco>;

export interface BancoDto extends SelectableItemDto {
    nombre?:string
 };

export default class Banco extends SelectableItem {
	constructor(
		id: string,
		descripcion: string,
		private readonly _nombre?: string
	){
		super(id, `${id} - ${ _nombre}`);
	}

	get nombre() {
		return this._nombre;
	}
 };