import { useContext } from "react";
import LabelButton from "../../../../../components/ui/atoms/Buttons/LabelButton";
import FilterModal from "../../../../../components/ui/atoms/FilterModal/FilterModal";
import ClienteFilterFieldsProvider from "../../../context/Cliente.FilterFields.Provider";
import DescripcionSearchField from "../../atoms/Descripcion.SearchField";
import ClienteTagCloud from "../../molecules/Cliente.TagCloud";
import "./Cliente.FilterSection.scss";
import { HistoryHandler } from "../../../../../context/History.Context";
import { Routes } from "../../../../../router/routes/Routes";

const ClienteFilterSection: React.FC<{}> = () => {
	const { goToRoute } = useContext(HistoryHandler);
	return (
		<ClienteFilterFieldsProvider>
				<div className="filters-modal-container">
					<FilterModal filters={
						<>
							<DescripcionSearchField />
						</>
					}
						titleShow="Filtros"
						modalTitle="Selección de filtros"
					>
					</FilterModal>
					<LabelButton
					label="Contactos"
					type="submit"
					onClick={() => goToRoute(Routes.CONTACTOS)}
					/>
					 <LabelButton
            		label="Seguimiento Clientes"
            		type="submit"
            		onClick={() => goToRoute(Routes.SEGUIMIENTO_CLIENTES)}
            /> 
				</div>
				<ClienteTagCloud />
		</ClienteFilterFieldsProvider>
	)
}

export default ClienteFilterSection;