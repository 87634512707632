import { BaseRepository } from "@pavabits/components"
import ElementoTipoRepository from "../../domain/ElementoTipoRepository";
import { UnidadObraElementoTipo } from "../../domain/model/ElementoEquipo";
import ElementoTipoRequestGetALl from "../ws/ElementoTipo.Request.GetAll";
import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";
import ElementoTipo, { ElementoTipoDto } from "../../../../domain/model/ElementoTipo";

export default class ElementoTipoApiRepository
 extends BaseRepository<
ElementoTipoDto,
ElementoTipo
> implements ElementoTipoRepository {

    protected transformDtoIntoModel(dto: ElementoTipoDto): ElementoTipo {
        return new ElementoTipo(
			dto.id,
			dto.descripcion
		)
    }

    async fetchTipos(): Promise<UnidadObraElementoTipo[]> {
		const res= await new ElementoTipoRequestGetALl(this.authToken).executeAsPromise();
		return res?.items;
	}

}