import ApiRequest from "../../domain/ApiRequest";
import { MaterialReadingDto } from "../../domain/model/Material";
import { MaterialesEndpoints } from "../../modules/Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class MaterialesRequestGetById extends ApiRequest<
undefined, MaterialReadingDto
>
{
	constructor(id: string, token: string) {
		super(
			"GET",
			MaterialesEndpoints.GET_BY_ID+id,
			{ Authorization: `${token}`},
			undefined,
            { }
			
		)
	}
}