import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import CustomTextInput from "../../components/CustomTextInput";
import FormTemplate from "../../components/common/FormTemplate/FormTemplate";
import IconButton from "../../components/ui/atoms/Buttons/IconButton";
import Dropdown, {
  DropdownProps,
} from "../../components/ui/atoms/Dropdown/Dropdown";
import SuggestionInputField, {
  SuggestionInputFieldProps,
} from "../../components/ui/atoms/SuggestionInput/SuggestionInput.Field";
import FormField from "../../components/ui/molecules/Form-field/FormField";
import { HistoryHandler } from "../../context/History.Context";
import { useLoading } from "../../context/Loading.Context";
import DelegacionesRepository from "../../domain/DelegacionesRepository";
import { DelegacionDto } from "../../domain/model/Delegacion";
import DelegacionesApiRepository from "../../infraestructure/api/Delegaciones.ApiRepository";
import { Routes } from "../../router/routes/Routes";
import { formatNumber } from "../../utils/NumberUtil";
import { AuthContext } from "../Login/AuthContextProvider";
import EquiposRepository from "./domain/EquiposRepository";
import { ElementoEquipoDto } from "./domain/model/ElementoEquipo";
import { EquipoCreateDto } from "./domain/model/Equipo";
import EquipoApiRepository from "./infrastructure/api/Equipo.ApiRepository";
import {
  COD_DELEGACIONES_CON_TODAS,
  USER_ROLES,
} from "../../assets/constants/Constantes";
import { useLocation } from "react-router-dom";

interface Props {
  redirectPageOnSave?: boolean;
  idTestBotonGuardar?:string;
  idTestDropDelegacion?:string;
  extraActionOnClose?: () => void;
}

const EquiposGrabacion: FC<Props> = ({
  redirectPageOnSave,
  idTestBotonGuardar,
  idTestDropDelegacion,
  extraActionOnClose,
}) => {
  const formId = "equipos-form";
  const [newEquipo, setNewEquipo] = useState<EquipoCreateDto>({
    delegacionId: -1,
    unidadObraElementoTipoId: 4,
    nombre: "",
    descripcion: "",
    equipoDetalles: [],
  });

  const [elementos, setElementos] = useState<ElementoEquipoDto[]>([]);

  const [elementosSelected, setElementosSelected] = useState<
    ElementoEquipoDto[]
  >([]);
  const [selectedElementos, setSelectedElementos] = useState<string[]>([]);
  const [elementosList, setElementosList] = useState<ElementoEquipoDto[]>([]);

  const [optionsDelegaciones, setOptionsDelegaciones] = useState<
    DelegacionDto[]
  >([]);
  const [selectedDelegacion, setSelectedDelegacion] = useState<DelegacionDto>({
    id: "",
    descripcion: "",
  });
  const { getToken, getDelegacion, hasRole } = useContext(AuthContext);
  const delegacionRef = useRef(+getDelegacion().id);

  const { goToRoute } = useContext(HistoryHandler);
  const { setLoading } = useLoading();

  const [tarifaTotal, setTarifaTotal] = useState(0);
  const equiposRepo: EquiposRepository = new EquipoApiRepository(getToken());

  const fetchElementos = useCallback(
    (search: string) => {
      var delegaciones = [+delegacionRef.current, 50];

      return equiposRepo
        .getConFiltro({
          filter: {
            descripcion: search,
            delegacionIds: delegaciones,
          },
        })
        .then((resp) => {
          setElementos(resp);
          return resp;
        });
    },
    [selectedDelegacion]
  );

  const fetchDelegaciones = useCallback(() => {
    const repo: DelegacionesRepository = new DelegacionesApiRepository(
      getToken()
    );
    return repo
      .fetchDelegaciones({
        filter: {
          ids: hasRole(USER_ROLES.ADMIN)
            ? COD_DELEGACIONES_CON_TODAS
            : [getDelegacion()?.id],
        },
      })
      .then((resp) => {
        setOptionsDelegaciones(resp);
        setSelectedDelegacion(getDelegacion());
      });
  }, [getToken]);

  const handleClickDelegaciones = (delegacion: DelegacionDto) => {
    if (selectedDelegacion.id !== delegacion.id) {
      delegacionRef.current = +delegacion.id;
      setSelectedDelegacion(delegacion);
      setNewEquipo({ ...newEquipo, delegacionId: +delegacion.id });
    } else {
      setSelectedDelegacion({ id: "", descripcion: "" });
      delegacionRef.current = 0;
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // se actualiza el equipo con los elementos seleccionados
    let equipoDetalleUpdated = newEquipo.equipoDetalles?.filter((el) => {
      return elementosSelected.find(
        (item) => item.id === el.unidadObraElementoId
      );
    });
    saveEquipo({
      ...newEquipo,
      delegacionId: +selectedDelegacion.id,
      equipoDetalles: equipoDetalleUpdated,
    });
  };

  useEffect(() => {
    fetchDelegaciones();
  }, [getDelegacion]);

  useEffect(() => {
    calculateTarifa();
  }, [elementosSelected, newEquipo.equipoDetalles]);

  const calculateTarifa = () => {
    let total = 0;
    elementosSelected.map((el) => {
      let elRendimiento = newEquipo.equipoDetalles?.find(
        (item) => item.unidadObraElementoId === el.id
      )?.rendimiento;
      if (elRendimiento) {
        total = total + el.tarifa * +elRendimiento;
      }
    });
    setTarifaTotal(+total.toFixed(2));
  };

  const handleNewEquipo = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewEquipo((prev) => ({ ...prev, [name]: value }));
  };

  const saveEquipo = useCallback((data: EquipoCreateDto) => {
    setLoading(true);
    equiposRepo
      .addEquipo(data)
      .then((res) => {
        if (redirectPageOnSave || redirectPageOnSave == undefined) {
          goToRoute(Routes.EQUIPOS_EDICION + "?backUrl=1", { id: res?.id });
        }
        if (extraActionOnClose) {
          extraActionOnClose();
        }
        toast.success("Equipo añadido con éxito");
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onChangeElemento = (s: string[]) => {
    var listaActualizada: ElementoEquipoDto[] = [];

    var elementosActualizados = [...elementos, ...elementosSelected];
    setSelectedElementos(s);
    s.forEach((element) => {
      if (element != "") {
        var obj = elementosActualizados.find((x) => x.id == element);
        if (obj) {
          listaActualizada.push(obj);
        }
      }
    });

    setElementosSelected(listaActualizada);
    const elementosDto = listaActualizada.map((el, index) => {
      return {
        unidadObraElementoId: el.id,
        rendimiento: newEquipo.equipoDetalles?.find(
          (x) => x.unidadObraElementoId == el.id
        )
          ? newEquipo.equipoDetalles.find(
              (x) => x.unidadObraElementoId == el.id
            )!.rendimiento
          : "1",
        tarifa: 0,
      };
    });

    setNewEquipo({ ...newEquipo, equipoDetalles: elementosDto });
  };

  const onDeleteElemento = (id: string) => {
    setSelectedElementos(selectedElementos.filter((item) => item !== id));
    setElementosSelected(elementosSelected.filter((item) => item.id !== id));
  };

  const handleRendimiento = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: any
  ) => {
    let updatedIndex = newEquipo.equipoDetalles?.findIndex(
      (el) => el.unidadObraElementoId === item.id
    );

    let updatedDetalle = newEquipo.equipoDetalles?.map((el, index) => {
      if (updatedIndex === index) {
        return {
          unidadObraElementoId: el.unidadObraElementoId,
          rendimiento: e.target.value,
          tarifa: el.tarifa,
        };
      } else {
        return el;
      }
    });

    setNewEquipo({ ...newEquipo, equipoDetalles: updatedDetalle });
  };
  const location = useLocation();
  return (
    <div className="add-edit-view-container">
      {location.pathname === "/mantenimiento/equipos/grabacion" && (
        <div className="add-edit-header">
          <h4>Nuevo Equipo</h4>
        </div>
      )}

      <div className="add-edit-body">
        <FormTemplate
        idTestBotonGuardar={idTestBotonGuardar}
          formId={formId}
          onSubmit={handleSubmit}
          goBackVisible={redirectPageOnSave}
          backUrl={true}
        >
          <div className="row-of-two">
            <CustomTextInput
              label="Nombre"
              name="nombre"
              value={newEquipo.nombre}
              onChange={handleNewEquipo}
              required
              idTest="Nombre"
            />

            <FormField<DropdownProps>
              label="Delegación"
              options={optionsDelegaciones.map((element) => {
                return {
                  text: element.descripcion,
                  id: element.id,
                  selected:
                    (newEquipo.delegacionId < 0
                      ? Number.parseInt(getDelegacion().id)
                      : newEquipo.delegacionId) == +element.id
                      ? true
                      : false,
                  onClick: () => {
                    handleClickDelegaciones(element);
                  },
                };
              })}
              required={true}
              disabled={false}
              singleSelection={true}
              component={Dropdown}
              idTest={idTestDropDelegacion}
            />

            <CustomTextInput
              label="Descripción"
              name="descripcion"
              value={newEquipo.descripcion}
              onChange={handleNewEquipo}
              required
              idTest="Descri"
            />

            <CustomTextInput
              label="Tarifa"
              name="tarifa"
              value={String(tarifaTotal)}
              onChange={handleNewEquipo}
              disabled
              idTest="Tarifa"
            />
          </div>
          <div className="input-container">
            <FormField<SuggestionInputFieldProps>
              label="Elementos"
              value={{
                id: selectedElementos?.join(","),
                text: "",
              }}
              className={"op-input95"}
              disabled={!selectedDelegacion}
              required
              onChange={(e) => onChangeElemento(e)}
              searchCallback={fetchElementos}
              singleSelection={false}
              component={SuggestionInputField}
              idTest="ElementosInput"
            />
          </div>

          <div className="single-row">
            <div className="table-container">
              {elementosSelected && (
                <table className="mini-table">
                  <thead>
                    <tr className="table-header">
                      <th>Elemento</th>
                      <th>Tarifa</th>
                      <th>Rendimiento</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {elementosSelected.map(
                      (item: ElementoEquipoDto, index: number) => (
                        <tr key={item.id}>
                          <td>{item.descripcion}</td>
                          <td>{formatNumber(item.tarifa)}</td>
                          <td>
                            <input
                              className="inline-input"
                              onChange={(e) => handleRendimiento(e, item)}
                              value={item.rendimiento}
                              defaultValue={1}
                              type="number"
                              step="any"
                              min={0}
                              required
                            />
                          </td>
                          <td>
                            <IconButton
                              icon="delete"
                              onClick={() => onDeleteElemento(item.id)}
                            />
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </FormTemplate>
      </div>
    </div>
  );
};

export default EquiposGrabacion;
