import React, { useContext, useEffect } from 'react'
import FormFieldGroup from '../../../../../components/ui/objects/FormFieldGroup/FormField.Group'
import Dropdown from '../../../../../components/ui/atoms/Dropdown/Dropdown'
import { OfertaEstadoDropdownContext } from '../../../../Oportunidades/context/FilterFields.Context'

const EstadoOfertasDropdown: React.FC<{ idTest?:string }> = ({idTest}) => {

    const options = useContext(OfertaEstadoDropdownContext)

  return (
    <div className='input-container'>
    
    <FormFieldGroup>
    <label>Estado Oferta</label>
    <Dropdown
        options={options}
        idTest={idTest}
    />
</FormFieldGroup>
</div>
  )
}

export default EstadoOfertasDropdown