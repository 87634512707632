import ApiRequest from "../../../../domain/ApiRequest";
import { BaseFilter } from "../../../../domain/BaseFilter";
import BaseResponse from "../../../../domain/BaseResponse";
import { EquipoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { EquiposFilter, EquiposSelectable } from "../../domain/model/Equipo";

export default class EquipoGruposRequestPostConTarifa extends ApiRequest<
EquiposFilter,
EquiposSelectable[]>
{
	public constructor(data: EquiposFilter, token: string) {
		super(
			"POST",
			EquipoApiEndpoints.CON_TARIFA,
			{ Authorization: token },
			data
		)
	}
}