import ApiRequest from "../../../../domain/ApiRequest";
import { EquipoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { EquipoCreateDto } from "../../domain/model/Equipo";

export default class EquipoRequestAdd extends ApiRequest<
EquipoCreateDto
>
{
	constructor(data: EquipoCreateDto, token: string) {
		super(
			"POST",
			EquipoApiEndpoints.CREAR_EQUIPO,
			{ Authorization: `${token}`},
			data,
			{}
		)
	}
}