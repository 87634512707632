import SelectableItem, { SelectableItemDto } from "../../../../components/ui/atoms/SuggestionInput/model/SuggestionInput.Model";
import { AutoCompleteSearchFilter, BiasedType } from "../../../../domain/BaseFilter";

type FilterExcludedProperties =
"id"
| "text";

type BiasedContratista = BiasedType<Contratista, FilterExcludedProperties>;

export type ContratistaFilter = AutoCompleteSearchFilter<BiasedContratista>;

export interface ContratistaDto extends SelectableItemDto {
	asfaltero?:string
};

export default class Contratista extends SelectableItem {
	constructor(
		id: string,
		descripcion: string,
		private readonly _asfaltero?: string
	){
		super(id,descripcion);
	}

	get asfaltero() {
		return this._asfaltero;
	}

}

