import { ReactNode, createContext, useState } from "react"
import { FilterTag } from "../modules/Oportunidades/context/FilterFields.Context"

export interface FilterState {
    [key: string]: any
}

export interface TagState extends FilterTag {
    // [key: string]: any
}

export interface PaginationState {
    currentPage?: number
    totalCount?: number
    maxResultCount?: number
    skipCount?: number
}

export interface OrderState {
    sortingCriteria: string
    desc: boolean
}

export interface CustomDataState {
    [key: string]: any
}

export interface ComponentState {
    filters?: FilterState
    tags?: any
    pagination?: PaginationState
    order?: OrderState
    customData?: CustomDataState
}

export interface GlobalState {
    [key: string]: ComponentState
}

export interface GlobalContextType {
    
    globalState: GlobalState
    updateGlobalState: (componentKey: string, newState: ComponentState) => void
    resetState: (componentKey: string) => void
}

export const GlobalContext = createContext<GlobalContextType | null>(null);

export const GlobalProvider: React.FC<React.ReactNode> = ({children}) => {

    const [globalState, setGlobalState] = useState<GlobalState>({})

    const updateGlobalState = (componentKey: string, newState: ComponentState) => {
        setGlobalState(prev => ({
            ...prev,
            [componentKey]: {...prev[componentKey], ...newState}
        }))
    }

    const resetState = (componentKey: string) => {
        setGlobalState(prev => ({
            ...prev,
            [componentKey] : {}
        }))
    }

    return  <GlobalContext.Provider value={{globalState, updateGlobalState, resetState}}>{children}</GlobalContext.Provider>
}