import SelectableItem, { SelectableItemDto } from "../../../../components/ui/atoms/SuggestionInput/model/SuggestionInput.Model";
import { AutoCompleteSearchFilter, BiasedType } from "../../../../domain/BaseFilter";

type FilterExcludedProperties =
"id"
| "text"

type BiasedProvincia = BiasedType<Provincia, FilterExcludedProperties>;

export type ProvinciaFilter = AutoCompleteSearchFilter<BiasedProvincia>;

export interface ProvinciaDto extends SelectableItemDto {};

export default class Provincia extends SelectableItem {};