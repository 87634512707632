import { FC, useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../Login/AuthContextProvider";
import { toast } from "react-toastify";
import 'react-tabs/style/react-tabs.scss';
import { PlantaDto } from "../../../../domain/model/Planta";
import { UnidadMaterialReadDto } from "../../domain/model/UnidadMaterial";
import { MaterialDto } from "../../../../domain/model/Material";
import PlantasRepository from "../../../../domain/PlantasRepository";
import PlantasApiRepository from "../../../../infraestructure/api/Plantas.ApiRepository";
import MaterialesRepository from "../../../../domain/MaterialesRepository";
import MaterialesApiRepository from "../../../../infraestructure/api/Materiales.ApiRepository";
import UnidadesRepository from "../../domain/UnidadesRepository";
import UnidadesApiRepository from "../../infraestructure/api/UnidadesApiRepository";
import FormField from "../../../../components/ui/molecules/Form-field/FormField";
import Dropdown, { DropdownProps } from "../../../../components/ui/atoms/Dropdown/Dropdown";
import SuggestionInputField, { SuggestionInputFieldProps } from "../../../../components/ui/atoms/SuggestionInput/SuggestionInput.Field";
import IconButton from "../../../../components/ui/atoms/Buttons/IconButton";

interface Props {
  unidadObraId: string,
  handleOnSubmit: () => void
}

const MaterialesUnidadView: FC<Props> = ({
  unidadObraId,
  handleOnSubmit

}) => {

  const [plantas, setPlantas] = useState<PlantaDto[]>({} as PlantaDto[]);
  const [selectedPlanta, setSelectedPlanta] = useState<PlantaDto>({ id: "", descripcion: "", descripcionCodigo: "", codigo: "", instalacionId: "" })
  const [selectedMateriales, setSelectedMateriales] = useState<string[]>([]);
  const [materialesUnidad, setMaterialesUnidad] = useState<UnidadMaterialReadDto[]>([]);
  const [recargarMateriales, setRecargarMateriales] = useState<boolean>(false);
  const [materialesList, setMaterialesList] = useState<MaterialDto[]>([]);

  const { getToken, getDelegacion, getClaimValue } = useContext(AuthContext);

  useEffect(() => {
    fetchPlantas()
    fetchUnidadObraMateriales(unidadObraId)
  }, [unidadObraId, getDelegacion])

  const fetchPlantas = useCallback(
    () => {
      const repo: PlantasRepository = new PlantasApiRepository(getToken());
      return repo.getAll().then((resp) => {
        setPlantas(resp)
      })
    }
    , []);

  const handleClickPlantas = (planta: PlantaDto) => {
    setRecargarMateriales(false);
    if ((selectedPlanta.id !== planta.id)) {
      setSelectedPlanta(planta);
    } else {
      setSelectedPlanta({ id: "", descripcion: "", descripcionCodigo: "", codigo: "", instalacionId: "" })
    }
    setRecargarMateriales(true);
  }

  const fetchMateriales = useCallback(
    (search: string) => {
      const repo: MaterialesRepository = new MaterialesApiRepository(getToken());
      var list = repo.getConFiltro({
        filter: {
          planta: selectedPlanta.codigo,
          descripcion: search
        }
      }).then((resp) => {
        return resp;
      });
      return list;
    }
    , [selectedPlanta]);

  const fetchUnidadObraMateriales = useCallback(
    (id: string) => {
      const repo: UnidadesRepository = new UnidadesApiRepository(getToken());

      repo.getMaterialesUnidadConFiltro(id).then((resp) => {
        setMaterialesUnidad([...materialesUnidad, ...resp]);
      })
    }
    , [])

  const onAddMaterial = async () => {
    const repo: UnidadesRepository = new UnidadesApiRepository(getToken());

    var repetido = materialesUnidad.find(x => selectedMateriales.includes(x.materialId));
    if (repetido) {
      toast.error("Alguno de los materiales que se están intentando añadir ya pertenecen a la unidad. Por favor, revise la seleeción realizada.")
      setSelectedMateriales([]);
      return;
    }

    repo.addMaterialesAUnidad({
      materialesIds: selectedMateriales.filter(x => x != ""),
      unidadObraId: unidadObraId
    }).then((resp) => {
      if (resp) {
        setSelectedMateriales([]);
        repo.getMaterialesUnidadConFiltro(unidadObraId).then((respMateriales) => {
          setMaterialesUnidad(respMateriales);
        })
      }
      toast.success('Material añadido correctamente');
    })
  }

  const onDeleteMaterial = async (id: string) => {
    const repo: UnidadesRepository = new UnidadesApiRepository(getToken());

    repo.deleteMaterialUnidad(id).then((resp) => {

      if (resp) {
        var materialUpdList = materialesUnidad.filter(x => x.id !== id);
        if (materialUpdList && materialUpdList.length > 0) {
          setMaterialesUnidad(materialUpdList);
        } else {
          setMaterialesUnidad([]);
        }

        toast.success('Material eliminado correctamente');

      }

    })
  }

  const onChangeMaterial = (s: string[]) => {
    setSelectedMateriales(s);
  }

  return (
    <>
      <div className="row-of-four">
        <>{
          plantas && plantas.length > 0 &&
          <div className='input-container'>
            <FormField<DropdownProps>
              label="Plantas"
              options={plantas!.map(
                (element) => {
                  return {
                    text: element.descripcionCodigo,
                    id: element.id,
                    selected: selectedPlanta?.id == element.id ? true : false,
                    onClick: () => { handleClickPlantas(element); }
                  }
                })}
              required
              disabled={false}
              singleSelection={true}
              component={Dropdown}
            />
          </div>
        }

        </>
        <>{recargarMateriales &&
          <div className='input-container'>
            <FormField<SuggestionInputFieldProps>
              label="Materiales"
              value={
                {
                  id: selectedMateriales.join(","),
                  text: materialesList.find(x => x.id == selectedMateriales[0]) ? materialesList.find(x => x.id == selectedMateriales[0])!.text : ""
                }
              }
              className={"op-input95"}
              disabled={false}
              required
              onChange={(e) => onChangeMaterial(e)}
              searchCallback={fetchMateriales}
              singleSelection={false}
              component={SuggestionInputField}
              idTest="MaterialesEquiposTab"
            />
          </div>

        }
        </>
        <>
          {
            selectedMateriales.length > 0 &&
            <div className='input-container'>
              <div className="unidad-medida-save-icon">
                <IconButton
                  icon="done"
                  onClick={onAddMaterial}
                  label="DoneMaterialTab"
                />
              </div>
            </div>
          }
        </>
      </div>
      <h3>Materiales asignados:</h3>
      {

        materialesUnidad.length > 0 && (
          <div className="row-of-two">
            <table className='mini-table'>
              <thead>
                <tr className='table-header'>
                  <th>Descripción</th>
                </tr>
              </thead>
              <tbody>
                {materialesUnidad.map((item: any, index: number) => (
                  <tr key={index}>
                    <td style={{ width: "80px" }} data-cy={item.descripcion.substring(0,6)+"-"+index}>{item.descripcion}</td>
                    <td style={{ width: "20px" }}><IconButton
                      icon="delete"
                      onClick={() => onDeleteMaterial(item.id)}
                      label={"BorrarMaterial" + index}
                    />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )

      }
    </>
  )
}

export default MaterialesUnidadView;

