import NaturalezaCliente, { NaturalezaClienteDto } from "../../domain/model/NaturalezaCliente";
import NaturalezasClienteRepository from "../../domain/NaturalezasClienteRepository";
import { BaseRepository } from "@pavabits/components";
import NaturalezasClienteGetAll from "../ws/NaturalezasClienteGetAll";

export default class NaturalezasClienteApiRepository
	extends BaseRepository<
		NaturalezaClienteDto[],
		NaturalezaCliente[]
	> implements NaturalezasClienteRepository {

	async getAll(): Promise<NaturalezaCliente[]> {
		return (await new NaturalezasClienteGetAll(this.authToken).executeAsPromise()).items;

	}

	fetchNaturalezas(): Promise<NaturalezaCliente[]> {
		return new Promise((resolve, reject) => {
			return new NaturalezasClienteGetAll(this.authToken)
				.request()
				.then((response) => {
					if (response.success) {
						resolve(this.transformDtoIntoModel(response.data.items));
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error) => {
					reject(error);
				})
		})
	}

	protected transformDtoIntoModel(dto: NaturalezaClienteDto[]): NaturalezaCliente[] {
		if (dto && dto.length) {
			return dto.map(item => {
				return new NaturalezaCliente(item.id, item.descripcion)
			})
		}
		return [];
	}
}