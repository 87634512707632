import ApiRequest from "../../../../domain/ApiRequest";
import { OfertasEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class OfertasUpdateDriveUrl  extends ApiRequest<undefined, string> {
	constructor(url:string, id:string,descripcion:string, token: string) {
		super(
			"GET",
			OfertasEndpoints.DRIVE_URL,
			{ Authorization: token },
			undefined,
			{ 
				"ofertaId": id ,
				"updateUrl":url,
				"descripcion":descripcion
			}
			
		)
	}
}

