import ApiRequest from "../../../../domain/ApiRequest";
import { ClausulasOfertaApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ClausulaOferta, { ClausulaOfertaUpdateDto } from "../../domain/model/ClausulaOferta";

export default class ClausulaOfertaUpdate extends ApiRequest<ClausulaOfertaUpdateDto, ClausulaOferta>
{
    constructor(data: ClausulaOfertaUpdateDto, token: string) {
        super(
            "PUT",
            ClausulasOfertaApiEndpoints.UPDATE,
            {Authorization: `${token}`},
            data,
        )
    }
}