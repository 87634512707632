import { useContext } from "react";
import { ContratistaDropdownContext } from "../../context/FilterFields.Context";
import FormFieldGroup from "../../../../components/ui/objects/FormFieldGroup/FormField.Group";
import Dropdown from "../../../../components/ui/atoms/Dropdown/Dropdown";

interface ContratistaDropdownProps {
	idTest?:string
}

const ContratistaDropdown: React.FC<ContratistaDropdownProps> = ({idTest}) => {


	const options = useContext(ContratistaDropdownContext);

	return (
			<div className="input-container">
				<FormFieldGroup>
					<label>Contratista</label>
					<Dropdown
					label={idTest}
						options={options}
					/>
				</FormFieldGroup>
			</div>
	)
}

export default ContratistaDropdown;