import React, { useCallback, useContext, useEffect, useState } from 'react'
import FormFieldGroup from '../../../components/ui/objects/FormFieldGroup/FormField.Group'
import { GlobalContext, GlobalContextType } from '../../../context/Global.Context';
import { AuthContext } from '../../Login/AuthContextProvider';
import ContactosRepository from '../../Clientes/domain/ContactosRepository';
import ContactosClientesApiRepository from '../../Clientes/infraestructure/api/ContactosClientes.ApiRepository';

const SeguimientoClienteContactoDropdownField = () => {
  const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;
  const componentName = "seguimientoCliente";
  const [value, setValue] = useState(globalState.seguimientoCliente?.filters?.contactoId ?? "");
  const { getToken } = useContext(AuthContext);

  useEffect(() => {
    fetchContactos(value)
  }, [value])

  const fetchContactos = useCallback((search: string) => {
    const repo: ContactosRepository = new ContactosClientesApiRepository(getToken());
    return repo.getByPersonaContacto(search)
  }
    , [getToken, value]);

  const handleInputChange = (e: any) => {
    setValue(e.target.value)
    updateGlobalState(componentName, {
      filters: {...globalState.seguimientoCliente?.filters,
        "contactoNombre":  value.length !== 0 ? e.target.value : undefined},
        pagination: {
          skipCount: 0
        }
      })
}

  return (
    <FormFieldGroup>
    <div className="input-container">
    <label>Contacto</label>
    <input
      value={value}
      placeholder="Seleccione Contacto"
      onChange={handleInputChange}
      data-cy="ContactoFiltroSeguimientoHistoricoCliente"
    >
    </input>
    </div>
  </FormFieldGroup >
  )
}

export default SeguimientoClienteContactoDropdownField