import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";
import BaseResponse from "../../../../domain/BaseResponse";
import EstudioDetalleRepository from "../../domain/EstudioDetalleRepository";
import EstudioDetalle, { EstudioDetalleDto } from "../../domain/model/EstudioDetalle";
import EstudioExport from "../../domain/model/EstudioExport";
import EstudioDetalleAdd from "../ws/EstudioDetalle.Request.Add";
import EstudioDetalleAddList from "../ws/EstudioDetalle.Request.AddList";
import EstudioDetalleDelete from "../ws/EstudioDetalle.Request.Delete";
import EstudioDetalleExport from "../ws/EstudioDetalle.Request.Export";
import EstudioDetalleRequestGetByEstudioDetalleAvanzadoId from "../ws/EstudioDetalle.Request.GetByEstudioAvanzadoDetalleId";
import EstudioDetalleRequestGetByEstudioId from "../ws/EstudioDetalle.Request.GetByEstudioId";
import EstudioDetalleUpdate from "../ws/EstudioDetalle.Request.Update";
import EstudioUpdateDetalle from "../ws/EstudioDetalle.Request.UpdateDetalle";
import EstudioDetalleUpdateList from "../ws/EstudioDetalle.Request.UpdateList";

export default class EstudioDetalleApiRepository
	extends ApiRepositoryBase<
		EstudioDetalleDto,
		EstudioDetalle
	> implements EstudioDetalleRepository {
		async add(data:  EstudioDetalle): Promise<EstudioDetalle> {
			const res= await new  EstudioDetalleAdd(data, this.authToken).executeAsPromise();
			return res;
		}
		async addList(data:  EstudioDetalle[]): Promise<EstudioDetalle[]> {
			const res= await new  EstudioDetalleAddList(data, this.authToken).executeAsPromise();
			return res;
		}

		async updateList(data:  EstudioDetalle[]): Promise<EstudioDetalle[]> {
			const res= await new  EstudioDetalleUpdateList(data, this.authToken).executeAsPromise();
			return res;
		}

		async updateDetallePorEstudio(estudioDetalles: EstudioDetalle[]): Promise<EstudioDetalle[]> {
			return await new EstudioUpdateDetalle(estudioDetalles, this.authToken).executeAsPromise();
		}
	
		async update(data:  EstudioDetalle): Promise<EstudioDetalle> {
			return await new  EstudioDetalleUpdate(data, this.authToken).executeAsPromise();
		}
	
		async delete(id: string): Promise<boolean> {
			await new  EstudioDetalleDelete(id, this.authToken).executeAsPromise();
			return true;
		}

		async getByEstudioDetalleAvanzadoId(id: string): Promise<EstudioDetalle[]> {
			const res= await new EstudioDetalleRequestGetByEstudioDetalleAvanzadoId(id, this.authToken).executeAsPromise();
			return EstudioDetalle.toArray(res?.items);
		}

		async getByEstudioId(id: string): Promise<EstudioDetalle[]> {
			const res= await new EstudioDetalleRequestGetByEstudioId(id, this.authToken).executeAsPromise();
			return EstudioDetalle.toArray(res?.items);
		}

		async export(data:  EstudioExport[]): Promise<string> {
			return await new  EstudioDetalleExport(data, this.authToken).executeAsPromise();
		}


		buildModelFromItems(item:  EstudioDetalleDto):  EstudioDetalle {
			return new  EstudioDetalle(item);
		}

		protected transformDtoIntoModel(dto: BaseResponse< EstudioDetalleDto>): BaseResponse<EstudioDetalle> {
			return (
				{
					items: this.buildModelFromItems(dto.items),
					currentPage: dto.currentPage,
					totalCount: dto.totalCount,
				}
			)
		}
}