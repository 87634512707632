import ApiRequest from "../../../../domain/ApiRequest";
import { BaseFilter } from "../../../../domain/BaseFilter";
import BaseResponse from "../../../../domain/BaseResponse";
import { OfertasEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { OfertasDto, OfertasFilter } from "../../domain/model/Ofertas";

export default class OfertasRequestListByFilter extends ApiRequest<
BaseFilter<OfertasFilter>,
BaseResponse<OfertasDto[]>>
{
	constructor(data: BaseFilter<OfertasFilter>, token: string) {
		super(
			"POST",
			OfertasEndpoints.CON_FILTRO,
			{ Authorization: token },
			data
		)
	}
}