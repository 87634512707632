import ApiRequest from "../../../../domain/ApiRequest";
import { ClausulasApiEndpoints, UnidadObraApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";


export default class UnidadesRequestDelete extends ApiRequest<{}, boolean> 
{
    constructor(id: string, token: string) {
        super(
            "DELETE",
            UnidadObraApiEndpoints.DELETE+id,
            {Authorization: `${token}`},
             {}
        )
    }
}