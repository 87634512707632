import { PropsWithChildren, ReactNode } from "react"
import Card from "../../ui/objects/Card/Card"

interface Props {
	title?: string
	className?: string
	filterSection?: ReactNode
}

const TableScreen: React.FC<Props> = (props: PropsWithChildren<Props>) => {

	const { className, title, filterSection, children } = props;

	return (
		<Card
			title={title}
			type="background"
		>	
			{filterSection &&
				<div>
					{filterSection}
				</div>
			}
				{children}
		</Card>
	)
}

export default TableScreen;