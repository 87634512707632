import ApiRequest from "../../../../domain/ApiRequest";
import { ClausulasApiEndpoints, UnidadObraApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { ClausulaReadDto } from "../../domain/model/Clausula";

export default class ClausulasRequestGetById extends ApiRequest<{}, ClausulaReadDto>
{
	constructor(id: string, token: string) {
		super(
			"GET",
			ClausulasApiEndpoints.CON_ID + id,
			{ Authorization: token },
			{}
		)
	}
}