import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { LibroEstandarApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import LibroEstandar from "../../domain/model/LibroEstandar";

export default class LibroEstandarGetAllByFilter extends ApiRequest<any, BaseResponse<LibroEstandar[]>>
{
    public constructor (id: string, token: string, params?: {[key: string]: number | string}) {
        super (
            "GET",
            LibroEstandarApiEndpoints.ALL + "?Filters[0].Key=ofertaId&Filters[0].Value=" + id,
            {Authorization: `${token}`},
            {},
            params ?? ""
        )
    }
}