import { BaseRepository } from "@pavabits/components";
import BancosRepository from "../../domain/BancosRepository";
import BancosRequestListByFilter from "../ws/Bancos.RequestListByFilter";
import Banco, { BancoDto } from "../../domain/model/Banco";

export default class BancosApiRepository extends BaseRepository<
	BancoDto[],
	Banco[]
> implements BancosRepository {
	fetchBancos(data: any): Promise<Banco[]> {
		return new Promise((resolve, reject) => {
			return new BancosRequestListByFilter(data, this.authToken)
				.request()
				.then((response) => {
					if (response.success) {
						resolve(this.transformDtoIntoModel(response.data.items));
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error) => {
					reject(error);
				})
		})
	}

	protected transformDtoIntoModel(dto: BancoDto[]): Banco[] {
		if (dto && dto.length) {
			return dto.map(item => {
				return new Banco(
					item.id.toString(),
					item.descripcion,
					item.nombre
				)
			})
		}
		return []
	}

}