import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import IconButton from "../../../../../../components/ui/atoms/Buttons/IconButton";
import { ChangeEvent,  useContext,  useState } from "react";
import BottomButtons from "../../../../../../components/ui/molecules/Bottom-buttons/BottomButtons";
import LabelButton from "../../../../../../components/ui/atoms/Buttons/LabelButton";
import { AuthContext } from "../../../../../Login/AuthContextProvider";
import EstudioDetalle from "../../../../../Estudios/domain/model/EstudioDetalle";
import Input from "../../../../../../components/ui/atoms/Input/FormInput/Input";
import TooltipComponent from "../../../../../../components/ui/molecules/Tooltip/TooltipComponent";

interface Props {
    openModal: boolean,
    handleCloseModal: (estudioDetalle: EstudioDetalle[]) => void,
    handleCancelModal: () => void,
    estudioDetalleArray: EstudioDetalle[]
}

const EstudioDetalleOrden: React.FC<Props> = ({ openModal, handleCloseModal, handleCancelModal, estudioDetalleArray }) => {

    const { getToken } = useContext(AuthContext);

    const [estudioDetalles, setEstudioDetalles] = useState<EstudioDetalle[]>(estudioDetalleArray)


    const handleCloseModalOrdenar = () => {
        handleCloseModal(estudioDetalles)
    }


    const handleEditOrden = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;
        let detalles = [...estudioDetalles];
        detalles[index].ordenDetalle = +value;
        setEstudioDetalles(detalles)
    }

    return (
        <div className="modal-section">
            <div className="fields-modal-container">
                <Modal
                    className="filters-modal modal-l"
                    handleCloseModal={handleCancelModal}
                    show={openModal}
                >
                    <ModalHeader>
                        <ModalTitle>
                            Ordenar detalles del estudio
                        </ModalTitle>
                        <IconButton
                            icon="close"
                            onClick={() => handleCancelModal()}
                        />
                    </ModalHeader>
                    <ModalBody>
                        {
                            <table className="mini-table">

                                <thead>
                                    <tr className="table-header">
                                        <th style={{ width: "25%" }}>Orden</th>
                                        <th style={{ width: "75%" }}>Descripcion</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {estudioDetalles.map((item: EstudioDetalle, index: number) => (
                                        <tr key={index + (item?.id ?? "")}>
                                            <td>
                                                <Input className='inline-input number'
                                                    value={item.ordenDetalle ?? ''}
                                                    onChange={(e) => (handleEditOrden(e, index))}
                                                    type="number"
                                                    min={0}
                                                    data={`OrdenDetalleInput${index}`}
                                                />
                                            </td>
                                            <td>
                                                <TooltipComponent text={item.descripcion}>
                                                    <div>
                                                        {item.descripcion}
                                                    </div>
                                                </TooltipComponent>
                                            </td>
                                            {/* <td></td> */}
                                        </tr>
                                    ))}
                                </tbody>

                            </table>

                        }

                        <BottomButtons>
                            <LabelButton
                                label="Guardar"
                                type="submit"
                                onClick={handleCloseModalOrdenar}

                            // disabled={addDisabled}
                            />

                        </BottomButtons>
                    </ModalBody>
                </Modal>
            </div>
        </div>
    );
}


export default EstudioDetalleOrden;