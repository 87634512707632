import { FC, useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { toast } from "react-toastify";
import TableGridView from "../../../../components/TableGridView";
import IconButton from "../../../../components/ui/atoms/Buttons/IconButton";
import FormInput from "../../../../components/ui/atoms/Input/FormInput/FormInput";
import { CustomButton } from "../../../../shared/components/Buttons/CustomButton";
import { AuthContext } from "../../../Login/AuthContextProvider";
import { LicitacionesColumnsContext } from "../../context/Oportunidades.Context";
import AccionesComercialesRepository from "../../domain/AccionesComercialesRepository";
import AjusteRepository from "../../domain/AjusteRepository";
import AccionComercial, { TipoAccionComercial } from "../../domain/model/AccionComercial";
import Adjudicacion from "../../domain/model/Adjudicacion";
import Ajuste from "../../domain/model/Ajuste";
import AccionesComercialesApiRepository from "../../infraestructure/api/AccionesComerciales.ApiRepository";
import AjusteApiRepository from "../../infraestructure/api/Ajuste.ApiRepository";
import LicitacionesRow from "./Licitaciones.Row";

const LicitacionesGrid: FC<{ 
	id: string,
	oportunidadIdAjuste?:Adjudicacion[],
}> = ({ id, 
		oportunidadIdAjuste}) => {
	
	const { columns } = useContext(LicitacionesColumnsContext);
	const licitacionesRow: LicitacionesRow = new LicitacionesRow(undefined, []);
	const { getToken } = useContext(AuthContext);

	const [data,setData]=useState<AccionComercial[]>();
	const [open,setOpen]=useState<boolean>(false);
	const [ajusteActual, setAjusteActual]=useState<Ajuste>();

	const fetchAccionesComerciales =() => {
		const repo: AccionesComercialesRepository = new AccionesComercialesApiRepository(getToken());
		return repo.fetchAccionComercial(id);
	}

	useEffect(()=>{
		fetchAccionesComerciales().then((response)=>{
			setData(response);
		})

	},[getToken]);

	useEffect(() => {

		let ajusteFilter= data?.filter(accion=>accion._accionComercialTipo===TipoAccionComercial.AJUSTE);
		let licitacionFilter= data?.filter(accion=>accion._accionComercialTipo===TipoAccionComercial.LICITACION);

		if(ajusteFilter && ajusteFilter.length >0 && licitacionFilter && licitacionFilter.length>0)
		{
			setAjusteActual(new Ajuste({id:ajusteFilter[0].accionComercialId,licitacionId:ajusteFilter[0].accionComercialId,oportunidadId:ajusteFilter[0].oportunidadId,toneladas:ajusteFilter[0].ajusteToneladas?.toString()??"", motivo:ajusteFilter[0].motivo??""}));	
		}
		else
		{
			if(licitacionFilter && licitacionFilter.length>0){
				setAjusteActual(new Ajuste({id:undefined,licitacionId:licitacionFilter[0].accionComercialId,oportunidadId:licitacionFilter[0].oportunidadId,toneladas:"0", motivo:""}));
			}
		}
	 }, [data])



	const anyadirAjuste=()=>{
		setOpen(true);
	}

	const guardarToneladasAjuste=()=>{		
		const repo:AjusteRepository=new AjusteApiRepository(getToken());
		if(!ajusteActual)
		{
			toast.error("No se ha podido guardar el ajuste");
			return;
		}

		if(ajusteActual.id)
		{
			repo.update(ajusteActual).then((resp)=>{
				toast.success("Actualizacion ajuste correcta");
				setOpen(false);
				fetchAccionesComerciales().then((response)=>{
					setData(response);
				});
				}
			);
		}
		else
		{
			repo.add(ajusteActual).then((resp)=>{
				toast.success("Creación ajuste correcta");
				setOpen(false);
				fetchAccionesComerciales().then((response)=>{
					setData(response);
				});
			})
		}
	}

	const updateToneladas=(value:string)=>{
		if(ajusteActual){
			setAjusteActual({...ajusteActual, toneladas:value})
		}
	}

	const updateMotivo=(value:string)=>{
		if(ajusteActual){
			setAjusteActual({...ajusteActual, motivo:value})
		}
	}
	

	return (
		<div className="licitaciones">
			<>		
				{data &&
					oportunidadIdAjuste && oportunidadIdAjuste.length>0?
					<>
						<TableGridView
						data={data ?? []}
						columns={columns}
						tableBodyMapper={licitacionesRow}
						HeaderAction={<button onClick={()=>{anyadirAjuste()}}>Ajustar</button>}
						/>
							<Modal show={open}
								onHide={() => setOpen(false)}>
								<ModalHeader>
									<ModalTitle>
										Ajuste de toneladas { data && data.filter(accion=>accion._accionComercialTipo===TipoAccionComercial.LICITACION)[0]?.obra }
									</ModalTitle>
									<IconButton
										icon="close"
										onClick={() => setOpen(false)}
									/>
								</ModalHeader>
								<ModalTitle className="modal-subtitle">
									Ajuste de toneladas para la oportunidad 
								</ModalTitle>
								<ModalBody>
									<div className="double-col-grid">
										
												
									<FormInput
											type="text"
											label="Motivo "
											value={ajusteActual?.motivo??""}
											error={""}
											onChange={(e)=>{updateMotivo(e.target.value)}}
											></FormInput>
										<FormInput
											type="negativeNumber"
											label="Toneladas ajuste"
											value={ajusteActual?.toneladas??"0"}
											error={""}
											onChange={(e)=>{updateToneladas(e.target.value)}}
											></FormInput>
									</div>
									
									<CustomButton
										disabled={ajusteActual?.motivo?.length===0}
										key={`confirmDeleteModal`}
										title={'Guardar Ajuste'}
										onButtonClick={() => {guardarToneladasAjuste(); }}
										text={"Guardar Ajuste"}
									/>
								</ModalBody>
							</Modal>
					</>
				:

				<TableGridView
					data={data ?? []}
					columns={columns}
					tableBodyMapper={licitacionesRow}
				/>
			
				}
			</>
		</div>
	)
}

export default LicitacionesGrid;