import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { ComercialEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import Comercial, { ComercialDtoFilter } from "../../domain/model/Comercial";

export default class ComercialListByDelegacion extends ApiRequest<ComercialDtoFilter, BaseResponse<Comercial[]>>
{
	constructor(data : ComercialDtoFilter, token: string) {
		super(
			"POST",
			ComercialEndpoints.CON_FILTRO,
			{ Authorization: token },
			data,
			{ }
		)
	}
}
