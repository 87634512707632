interface VentanaPermisoEntity {
    id: number;
    nombrePadre?: string;
    nombreVentana?: string;
    permisoEntrada: boolean;
    permisoModificacion: boolean;
    permisoBaja: boolean;
    permisoAlta: boolean;
    permisoEspecial: boolean;
    orden: number,
    url: string,
    icon: string,
    iconIsImage: boolean
}

export interface VentanaPermisoDto extends VentanaPermisoEntity {};

export class VentanaPermiso implements VentanaPermisoEntity {
    id: number;
    nombrePadre?: string;
    nombreVentana?: string;
    permisoEntrada: boolean;
    permisoModificacion: boolean;
    permisoBaja: boolean;
    permisoAlta: boolean;
    permisoEspecial: boolean;
    orden: number;
    url: string;
    icon: string = "";
    iconIsImage: boolean = false;

    constructor(entity: VentanaPermisoEntity) {
        this.id = entity.id;
        this.nombrePadre = entity.nombrePadre;
        this.nombreVentana = entity.nombreVentana;
        this.permisoEntrada = entity.permisoEntrada;
        this.permisoModificacion = entity.permisoModificacion;
        this.permisoBaja = entity.permisoBaja;
        this.permisoAlta = entity.permisoAlta;
        this.permisoEspecial = entity.permisoEspecial;
        this.orden = entity.orden;
        this.url = entity.url;
        this.icon = entity.icon ?? "";
        this.iconIsImage = !entity.icon ? true : false;
    }        
}
