import React, { useContext, useEffect, useState } from 'react'
import FormFieldGroup from '../../components/ui/objects/FormFieldGroup/FormField.Group'
import Dropdown from '../../components/ui/atoms/Dropdown/Dropdown';
import { AuthContext } from '../Login/AuthContextProvider';
import Delegacion from '../../domain/model/Delegacion';
import { GlobalContext, GlobalContextType } from '../../context/Global.Context';


interface EquiposDelegacionesDropdownProps {
    delegaciones: Delegacion[];
    disabled?: boolean;
    idTest?:string;
  }

const EquiposDelegacionesDropdown: React.FC<EquiposDelegacionesDropdownProps> = ({delegaciones, disabled, idTest}) => {
    const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;
    const [updatedValues, setUpdatedValues] = useState<Delegacion[]>(
        globalState?.elementos?.filters?.delegacionIds 
        && globalState?.elementos?.filters?.delegacionIds.length > 0 
        ? delegaciones?.filter(x => globalState?.elementos?.filters?.delegacionIds.includes(x.id)) 
        : [])

    useEffect(() => {
        updateGlobalState(
            "elementos", {filters: {
                ...globalState.elementos?.filters,
            "delegacionIds": updatedValues.map( (elem) => {return elem.id} ), 
            "delegacionNombre": updatedValues.map( 
            (elem) => {
                return elem.text + ", "} ) },
                pagination: {
                    skipCount: 0
                  }},
                
        )
        updateGlobalState(
            "equipos", {filters: {...globalState.equipos?.filters,
            "delegacionIds": updatedValues.map( (elem) => {return elem.id} ), 
            "delegacionNombre": updatedValues.map( 
            (elem) => {return elem.text + ", "} ) },
            pagination: {
                skipCount: 0
              }}
    )
    }, [updatedValues, delegaciones])

    const options = delegaciones?.map((delegacion: Delegacion, index) => {
        return {
            id: delegacion.id,
            text: delegacion.text,
            selected: globalState?.elementos?.filters?.delegacionIds?.includes(delegacion.id),
            onClick: () => {
                if (globalState?.elementos?.filters?.delegacionIds?.includes(delegacion.id)) {
                   setUpdatedValues(updatedValues.filter(del => del.id !== delegacion.id))
                } else {
                    setUpdatedValues([...updatedValues, delegacion])
                }
            }
        }
    })
    
  return (
    <div className='input-container'>
    <FormFieldGroup>
    <label>Delegación</label>
    {options && <Dropdown
        options={options}
        singleSelection={false}
        disabled={disabled}
        label="DelegacionFiltro"
    />}
    </FormFieldGroup>
</div>
  )
}

export default EquiposDelegacionesDropdown

