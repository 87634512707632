import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";
import LibroEstandar, { LibroEstandarReadDto } from "../../domain/model/LibroEstandar";
import BaseResponse from "../../../../domain/BaseResponse";
import LibroEstandarDetalleRepository from "../../domain/LibroEstandarDetalleRepository";
import LibroEstandarDetalle, { LibroEstandarDetalleReadDto } from "../../domain/model/LibroEstandarDetalle";
import LibroEstandarDetalleRequestGetByLibroEstandarId from "../ws/LibroEstandarDetalle.Request.GetByLibroEstandarId";
import LibroEstandarDetalleRequestAdd from "../ws/LibroEstandarDetalle.Request.Add";
import LibroEstandarDetalleRequestAddList from "../ws/LibroEstandarDetalle.Request.AddList";
import LibroEstandarDetalleRequestUpdate from "../ws/LibroEstandarDetalle.Request.Update";
import LibroEstandarDetalleRequestDelete from "../ws/LibroEstandarDetalle.Request.Delete";


export default class LibroEstandarDetalleApiRepository
	extends ApiRepositoryBase<
		LibroEstandarDetalleReadDto,
		LibroEstandarDetalle
	> implements LibroEstandarDetalleRepository {

	async add(data: LibroEstandarDetalle): Promise<LibroEstandarDetalle> {
		const res = await new LibroEstandarDetalleRequestAdd(data, this.authToken).executeAsPromise();
		return res;
	}

	async update(data:  LibroEstandarDetalle): Promise<LibroEstandarDetalle> {
		return await new  LibroEstandarDetalleRequestUpdate(data, this.authToken).executeAsPromise();
	}

	async addList(data:  LibroEstandarDetalle[]): Promise<LibroEstandarDetalle[]> {
		const res= await new  LibroEstandarDetalleRequestAddList(data, this.authToken).executeAsPromise();
		return res;
	}

	async delete(id: string): Promise<boolean> {
		await new  LibroEstandarDetalleRequestDelete(id, this.authToken).executeAsPromise();
		return true;
	}

	async getByLibroEstandarId(id: string): Promise<LibroEstandarDetalle[]> {
		const res = await new LibroEstandarDetalleRequestGetByLibroEstandarId(id, this.authToken).executeAsPromise();
		return LibroEstandarDetalle.toArray(res?.items);
	}

	buildModelFromItems(item: LibroEstandarDetalleReadDto): LibroEstandarDetalle {
		return new LibroEstandarDetalle(item);
	}

	protected transformDtoIntoModel(dto: BaseResponse<LibroEstandarDetalleReadDto>): BaseResponse<LibroEstandarDetalle> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}
}