import { useMemo, useRef, useState } from "react";
import { BaseEntity } from "@pavabits/components";

function useSearchCallback<TEntity extends BaseEntity>(callback: (search: string) => Promise<TEntity[]>, initialInput?: string) {

	const [suggestions, setSuggestions] = useState<TEntity[]>([]);

	const [input, setInput] = useState<string>(initialInput ?? "");

	function onInputChange(event: any) {
		setInput(event.target.value);
	}

	const minInputReached = input.length >= 2;

	const refTimer = useRef<NodeJS.Timeout>();

	useMemo(() => {
	
		const execute = () => {
			refTimer.current && clearTimeout(refTimer.current);
			refTimer.current = setTimeout(() => {
				if (minInputReached) {

					callback(input).then(resp =>
						{ 
							setSuggestions(resp); 
						}
					)
				}
			}, 1000);
		}

		execute();
		return () => {
			refTimer.current && clearTimeout(refTimer.current);
		}

	}, [input, minInputReached, callback])


	return {
		input,
		results: suggestions,
		onInputChange
	}
}

export default useSearchCallback;