import React, { useContext, useEffect, useState } from 'react'
import FormFieldGroup from '../../../../../components/ui/objects/FormFieldGroup/FormField.Group'
import Dropdown from '../../../../../components/ui/atoms/Dropdown/Dropdown'
import Delegacion from '../../../../../domain/model/Delegacion'
import { OfertasDelegacionesDropdownContext } from '../../../../Oportunidades/context/FilterFields.Context'

interface OfertasDelegacionesDropdownProps {
  delegaciones?: Delegacion[];
  idTest?:string;
}
const OfertasDelegacionesDropdown: React.FC<OfertasDelegacionesDropdownProps> = ({delegaciones, idTest}) => {
  const options = useContext(OfertasDelegacionesDropdownContext);

	return (
		 <div className="input-container" data-cy={idTest}> 
		<FormFieldGroup>
			
			<label>Delegación</label>
			{options && <Dropdown
				options={options}
				singleSelection={false}
			/>}
			 
		</FormFieldGroup>
		</div> 
	)
}

export default OfertasDelegacionesDropdown