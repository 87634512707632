import ApiRequest from "../../../../domain/ApiRequest";
import { ClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ClienteDto from "../../domain/model/Cliente";

export default class ClienteRequestGetById extends ApiRequest<{}, ClienteDto>
{
	constructor(id: number, token: string) {
		super(
			"GET",
			ClienteEndpoints.CON_ID + id,
			{ Authorization: token },
			{}
		)
	}
}