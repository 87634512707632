import { useContext, useEffect, useState } from "react";
import { GlobalContext, GlobalContextType } from "../../../context/Global.Context";
import { FilterTag } from "../../Oportunidades/context/FilterFields.Context";

export default function useClientesTags() {
	const { globalState, updateGlobalState } = useContext(GlobalContext) as GlobalContextType;
	const [tags, setTags] = useState<FilterTag[]>(globalState?.cliente?.tags ?? []);

	useEffect(() => {
			setTags(globalState?.cliente?.tags ?? [])
	}, [globalState?.cliente?.tags])

	const updateTags = (tag: FilterTag) => {
		let updatedTags = tags.filter(x => x.label !== tag.label);
		setTags([...updatedTags, tag]);
		updateGlobalState("cliente", {tags: [...updatedTags, tag]})
	}

	return (
		{
			tags,
			updateTags,
		}
	)
};