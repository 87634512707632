import React, { useContext, useEffect, useState } from "react";
import "./PaginationComponent.scss";
import { Icon } from "@pavabits/components";
import { GlobalContext, GlobalContextType } from "../../../../context/Global.Context";

export interface PaginationComponentProps {
	currentPage: number
	totalCount: number,
	maxCount: number,
	onPageSelect: (value: number) => void,
	onSetMaxCount: (value: number) => void,
	onPageBackwards: () => void,
}

interface PageSelector {
	value: number,
	page: number,
}

const PaginationComponent: React.FC<PaginationComponentProps> = (props: PaginationComponentProps) => {
	const { currentPage, maxCount, totalCount, onPageSelect, onSetMaxCount, onPageBackwards } = props;

	const lastPage = Math.trunc(totalCount / maxCount);
	
	function handleMaxCount(value: number) {
		onSetMaxCount(value);
	}

	function goToFirstPage() {
		onPageSelect(currentPage - currentPage);
	}

	function goToLastPage() {
		onPageSelect((totalCount % maxCount) !== 0 ? lastPage : (lastPage - 1));
	}

	function pageBackwards() {
		onPageBackwards();
	}

	function pageForward() {
		onPageSelect(currentPage);
	}

	function handleSetPage(value: number) {
		onPageSelect(value-1)
	}

	function preparePageSelector() {
		let pageSelectors: PageSelector[] = [];
		for (let index = 1; index <= lastPage + ((totalCount % maxCount) !== 0 ? 1 : 0) ; index++) {
			pageSelectors.push({ value: index, page: index });
		}
		return pageSelectors;
	}

	const currentMinEntry = (1 + (currentPage * maxCount) - (maxCount));
	const currentMaxEntry = (1 + (currentPage -1 === lastPage ? totalCount - 1 : (currentPage * maxCount - 1)));

	const currentPaginationText = `Mostrando de la ${totalCount !== 0 ? currentMinEntry : "0"} a la ${currentMaxEntry} de un total de ${totalCount} entradas`;

	const backwardDisabled = (currentPage - 1) === 0;
	const forwardDisabled = (currentPage + 1) > Math.ceil(totalCount / maxCount);

	return (
		<div className="pagination">
			<div className="results-controller">
				Entradas:
				<select 
				defaultValue={ maxCount ?? 10}
				data-cy="PaginationSelect"
				className="pagination-select" 
				onChange={(e: any) => handleMaxCount(e.target.value)}>
					<option value={10}>10</option>
					<option value={25}>25</option>
					<option value={50}>50</option>
				</select>
			</div>
			<div className="arrow-btns" data-cy="PaginationArrowButtons">
				<button className={`btn btn--icon${backwardDisabled ? " disabled" : ""}`} disabled={backwardDisabled} onClick={goToFirstPage}>
					<Icon icon="keyboard_double_arrow_left" />
				</button>
				<button className={`btn btn--icon${backwardDisabled ? " disabled" : ""}`} disabled={backwardDisabled} onClick={pageBackwards}>
					<Icon icon="keyboard_arrow_left" />
				</button>
				{currentPaginationText}
				<button className={`btn btn--icon${forwardDisabled ? " disabled" : ""}`} disabled={forwardDisabled} onClick={pageForward}>
					<Icon icon="keyboard_arrow_right" />
				</button>
				<button className={`btn btn--icon${forwardDisabled ? " disabled" : ""}`} disabled={forwardDisabled} onClick={goToLastPage}>
					<Icon icon="keyboard_double_arrow_right" />
				</button>
			</div>
			<div className="page-selector">
				Ir a la página: 
				{currentPage && 
					<select 
					value={currentPage} 
					className="pagination-select" 
					onChange={(e: any) => handleSetPage(e.target.value)}>
						{
							preparePageSelector().map(selector => {
								return <option key={selector.value}  value={selector.value}>{selector.page}</option>
							})
						}
					</select>
				}
			</div>

		</div>
	)
}

export default PaginationComponent;