import LibroEstandarDetalle from "./LibroEstandarDetalle";

interface ILibroEstandar {
    id: string | undefined;
    ofertaId: string;
    presupuestoId: string;
    articulo: string;    
    descripcion: string;    
    libroEstandarDetalle: LibroEstandarDetalle[];
    lastModificationTime?: string;
    codigoObra?:string;
}

export default class LibroEstandar implements ILibroEstandar {
    id: string | undefined;
    ofertaId: string;
    presupuestoId: string;
    articulo: string;
    descripcion: string;
    libroEstandarDetalle: LibroEstandarDetalle[];
    lastModificationTime?: string;
    codigoObra?:string;


    constructor(entity: ILibroEstandar) {
        this.id = entity.id != null ? entity.id : "";
        this.ofertaId = entity.ofertaId;
        this.presupuestoId = entity.presupuestoId;
        this.articulo = entity.articulo;
        this.descripcion = entity.descripcion;
        this.libroEstandarDetalle = entity.libroEstandarDetalle
        this.codigoObra=entity.codigoObra;
    }

    // public static toArray(entities: UnidadObraEntity[]): UnidadObra[] {
	// 	return entities.map(entity => { return new UnidadObra(entity) });
	// }

}

export interface LibroEstandarReadDto extends LibroEstandar {
    errors?: string[]    
    creationTime?: string;
    creationUsername?: string;    
    lastModificationTime?: string;
    lastModificationUsername?: string;
}

export type LibroEstandarUpdateDto = LibroEstandar;

export interface LibroEstandarDto extends LibroEstandar {
	errors: string[]
};