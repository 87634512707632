
interface FormaPagoEntity  {
	id: string,
    bancoId: string,
	bancoNombre: string;
    sucursalId: string,
	sucursalNombre: string;
    telefono: string,
    gestorCuentas : string,
    clienteId:number,
	creationUsername: string;
	creationTime:string;
	lastModificationTime:string,
	lastModificationUsername:string,
}

export default class FormaPago implements FormaPagoEntity {
    id: string;
    bancoId: string;
	bancoNombre: string;
    sucursalId: string;
	sucursalNombre: string;
    telefono: string;
    gestorCuentas: string;
    clienteId:number;
	creationUsername: string;
	creationTime:string;
	lastModificationTime:string;
	lastModificationUsername:string;


	constructor(entity: FormaPagoEntity) {
		
		this.id = entity.id;
		this.bancoId = entity.bancoId;
		this.bancoNombre = entity.bancoNombre
		this.sucursalId=entity.sucursalId;
		this.sucursalNombre=entity.sucursalNombre;
		this.telefono = entity.telefono;
		this.gestorCuentas = entity.gestorCuentas;
        this.clienteId=entity.clienteId;
		this.creationUsername = entity.creationUsername;
		this.creationTime = entity.creationTime;
		this.lastModificationTime=entity.lastModificationTime;
		this.lastModificationUsername=entity.lastModificationUsername;
	}

    public static toArray(entities: FormaPagoEntity[]): FormaPago[] {
		return entities.map(entity => { return new FormaPago(entity) });
	}

}

export interface FormaPagoDto extends FormaPago{};
export interface FormaPagoReadingDto extends FormaPago{};
export interface FormaPagoCreationDto {
    bancoId: string,
    sucursalId: string,
    telefono: string,
    gestorCuentas : string,
    clienteId: number
};
export interface FormaPagoUpdateDto {

	id: string,
    bancoId: string,
    sucursalId: string,
    telefono: string,
    gestorCuentas : string,
    clienteId: number,
	creationUsername: string;
	creationTime:string;
	lastModificationTime: string,
	lastModificationUsername: string,
};