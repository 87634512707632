import { Icon } from "@pavabits/components";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import 'react-tabs/style/react-tabs.scss';
import DirtyModal from "../../../../components/common/DirtyModal/DirtyModal";
import FormTemplate from "../../../../components/common/FormTemplate/FormTemplate";
import Dropdown, { DropdownProps } from "../../../../components/ui/atoms/Dropdown/Dropdown";
import FormInput from "../../../../components/ui/atoms/Input/FormInput/FormInput";
import FormField from "../../../../components/ui/molecules/Form-field/FormField";
import { FormErrors } from "../../../../hooks/use-form";
import { CustomButton } from "../../../../shared/components/Buttons/CustomButton";
import { formatNumber } from "../../../../utils/NumberUtil";
import { AuthContext } from "../../../Login/AuthContextProvider";
import NaturalezasClienteRepository from "../../domain/NaturalezasClienteRepository";
import { ClienteDto } from "../../domain/model/Cliente";
import NaturalezaCliente from "../../domain/model/NaturalezaCliente";
import useClienteForm from "../../hooks/useClienteForm";
import NaturalezasClienteApiRepository from "../../infraestructure/api/NaturalezasCliente.ApiRepository";
import ClientesView from "./ClientesView";
import FormaPagoView from "./FormaPagoView";
import SeguimientoClientesView from "./SeguimientoClientesView";
import { PAVASAL_CLIENTEID } from "../../../../assets/constants/Constantes";
import CustomInput from "../../../../shared/components/Inputs/CustomInput/CustomInput";
const NATURALEZA_PRIVADOS = "P";
    
interface Props {
	type: "edit" | "add"
	handleOnSubmit: 
	( 	valid: boolean, 
		data: ClienteDto, 
		errors: FormErrors<ClienteDto> ) => void
	cliente?: ClienteDto,
	readOnly?: boolean
}

const ClienteFormTemplate: React.FC<Props> = (
	{
		type,
		handleOnSubmit,
		cliente,
		readOnly
	}: Props
) => {

	const clienteInit: Partial<ClienteDto> = {
		contacts: [{
			id: "",
			nombreCompleto: "",
			cargo: "",
			telefono: "",
			mail: "",
			usuario: "",
			direccion: "",
			lastModificationTime: "",
			lastModificationUsername: "",
			municipio: "",
			personaContacto: "",
			clienteId: 0,
			clienteNombre: "",
			zona: "",
			firmante: false,
			nif: ""
			
		}]
	}
	const { t } = useTranslation<['main']>(['main']);

	//region dirtyform
	const [dirtyContactos, setDirtyContactos] = useState<boolean>(false);
	const [dirtySeguimientoClientes, setdirtySeguimientoClientes] = useState<boolean>(false);
	const [dirtyFormaPago, setdirtyFormaPago] = useState<boolean>(false);

	const handleDirtyContactos = (value: boolean) => setDirtyContactos(value);
	const handleDirtySeguimientoClientes = (value: boolean) => setdirtySeguimientoClientes(value);
	const handleDirtyFormaPago = (value: boolean) => setdirtyFormaPago(value);

	const [tabIndex, setTabIndex] = useState(0);
	const [tabIndex2Change, setTabIndex2Change] = useState(0);
	const changeTab = (index: number) => {
		if (!dirtyContactos && !dirtyFormaPago && !dirtySeguimientoClientes) {
			setTabIndex(index)
		}
		else {
			setTabIndex2Change(index);
			setOpenDirtyTab(true);
		}
	}
	const [openDirty, setOpenDirty] = useState<boolean>(false);
	const [openDirtyTab, setOpenDirtyTab] = useState<boolean>(false);
	const [validForm, setValid] = useState<boolean>(false);
	const [dataForm, setData] = useState<any>();
	const [errorsForm, setErrors] = useState<any>();
	const checkDirtyForm = (valid: boolean, data: ClienteDto, errors: FormErrors<ClienteDto>) => {
		if (dirtyContactos || dirtySeguimientoClientes || dirtyFormaPago) {
			setOpenDirty(true);
			setValid(valid);
			setData(data);
			setErrors(errors);
		}
		else
			handleOnSubmit(valid, data, errors);
	}
	//endregion dirtyform
	const [optionsNaturaleza, setOptionsNaturaleza] = useState<NaturalezaCliente[]>([]);

	const { onField, onSubmitForm } = useClienteForm({ initialValues: (type === "add") ? { ...clienteInit } : cliente, handleOnSubmit: checkDirtyForm });

	const titleFromType = readOnly ? "" : type === "add" ? "Nuevo" : "Editar";

	const formId = "promotores-ofertas-form";

	const hasRefContabilidad = cliente &&
		cliente.refContabilidad !== undefined &&
		cliente.refContabilidad !== null &&
		cliente.refContabilidad > 0;

	const { getToken, hasClaim } = useContext(AuthContext);

	const handleCloseModalDirty = () => {
		setOpenDirty(false);
		setOpenDirtyTab(false);
	}

	const fetchNaturalezas = useCallback(
		() => {
			const repo: NaturalezasClienteRepository = new NaturalezasClienteApiRepository(getToken());
			repo.fetchNaturalezas().then((resp) => {
				setOptionsNaturaleza(resp);
				if (onField("naturalezaId").value == null) {
					onField("naturalezaId").onSelectValue(NATURALEZA_PRIVADOS);
				}

			});
		}
		, [getToken]);

	useEffect(() => { fetchNaturalezas() }, []);

	const columns = [
        {
			attributeName: "personaContacto",
            columnName: "Nombre y Apellidos",
            width: "16%"
        }, {
            attributeName: "cargo",
            columnName: "Cargo",
            width: "10%"
        }, {
            attributeName: "telefono",
            columnName: "Teléfono/s",
            width: "10%"
        }, {
            attributeName: "mail",
            columnName: "Email",
            width: "15%"
        }, {
            attributeName: "direccion",
            columnName: "Dirección",
            width: "10%"
        }, {
            attributeName: "zona",
            columnName: "Zona",
            width: "7%"
        }, {
            attributeName: "firmante",
            columnName: "Firmante",
            width: "5%",
			type: "checkbox"
        }, {
            attributeName: "nif",
            columnName: "NIF",
            width: "7%"
        }
    ]

	return (
		<div className='add-edit-view-container'>
			<div className='add-edit-header'>
				<h4>{`${titleFromType} Cliente`}</h4>
			</div>
			<div className="add-edit-body">
				<FormTemplate
					formId={formId}
					className={formId}
					onSubmit={onSubmitForm}
					readOnly={readOnly}
				>
					{(type === "add" || (cliente !== undefined)) &&
						<>
							<div className="row-of-two">
								<FormInput
									label="Razón social"
									value={onField("descripcion").value}
									error={onField("descripcion").error}
									required
									disabled={hasRefContabilidad}
									onChange={onField("descripcion").onChangeValue}
									idTest="RazonSocial"
								/>
								<FormInput
									label="Nombre Corto"
									value={onField("descripcionCorta").value}
									error={onField("descripcionCorta").error}
									disabled={hasRefContabilidad}
									onChange={onField("descripcionCorta").onChangeValue}
									idTest="NombreCorto"
								/>
								<FormInput
									label="CIF"
									value={onField("cif").value}
									error={onField("cif").error}
									disabled={hasRefContabilidad}
									required
									onChange={onField("cif").onChangeValue}
									idTest="Cif"
								/>
								<FormInput
									label="Riesgo"
									type="number"
									value={onField("riesgo").value}
									error={onField("riesgo").error}
									onChange={onField("riesgo").onChangeValue}
									idTest="Riesgo"
								/>
								<FormInput
									type="number"
									label="Referencia de contabilidad"
									value={onField("refContabilidad").value}
									error={onField("refContabilidad").error}
									onChange={onField("refContabilidad").onChangeValue}
									idTest="ReferenciaDeContabilidad"
								/>
								<FormInput
									type="number"
									label="Id Proveedor"
									value={onField("proveedorId").value}
									error={onField("proveedorId").error}
									onChange={onField("proveedorId").onChangeValue}
									disabled={cliente !== undefined && cliente.proveedorId !== undefined && cliente.proveedorId > 0}
									idTest="IdProveedor"
								/>
								<FormField<DropdownProps>
									label="Naturaleza"
									options={optionsNaturaleza.map(
										(element) => {
											return {
												text: element.descripcion,
												id: element.id,
												selected: onField("naturalezaId").value === element.id,
												onClick: () => { onField("naturalezaId").onSelectValue(element.id) }
											}
										})}
									singleSelection={true}
									component={Dropdown}
									required
								/>
								<FormInput
									type="number"
									label="Iva"
									value={onField("iva").value	}
									error={onField("iva").error}
									onChange={onField("iva").onChangeValue}
									idTest="Iva"
								/>
								<FormInput
									// type="text"
									label="Observaciones"
									value={ onField("observaciones").value}
									error={onField("observaciones").error}
									onChange={onField("observaciones").onChangeValue}
									idTest="Observaciones"
									// required
								/>
								    {/* <CustomInput
                                    value={onField("observaciones").value}
                                    className={'platform-input secondary-input'}
                                    formClassName={"secondary-input"}
                                    onChange={ onField("observaciones").onChangeValue }
                                    error= {onField("observaciones").error}
                                /> */}
							</div>
							{(type === "edit" && (cliente !== undefined)) &&
								<div className="tabs-container">
									<Tabs selectedIndex={tabIndex} onSelect={(index) => changeTab(index)}>
										<TabList>
											<Tab data-cy='TabClienteContactos'>Contactos{dirtyContactos ? <label title={t("existenCambiosSinGuardar")}><Icon icon="warning" /></label> : <></>}</Tab>
											<Tab data-cy='TabClienteSeguimientoClientes'>Seguimiento Clientes{dirtySeguimientoClientes ? <label title={t("existenCambiosSinGuardar")}><Icon icon="warning" /></label> : <></>}</Tab>
											<Tab data-cy='TabClienteFormaPago'>Forma Pago{dirtyFormaPago ? <label title={t("existenCambiosSinGuardar")}><Icon icon="warning" /></label> : <></>}</Tab>
										</TabList>

										<TabPanel>
											{
												<ClientesView 
													type='add'
													handleOnSubmit={() => { }}
													cliente={cliente}
													title=""
													idPadre={cliente?.id !== undefined ? +cliente?.id : 0}
													cabecera={columns}
													body={columns.map((element) => ({
															id: element.attributeName,
															value: "",
															type: "text",
															disabled: element.attributeName === "firmante" && cliente?.id == PAVASAL_CLIENTEID.toString() ? true : false,
															onChange: (value: any) => { },		
															style: {width: element.width},												
														}))}
													hadleDirtyForm={handleDirtyContactos}
												>													
												</ClientesView>
											}
										</TabPanel>
										<TabPanel>
											{<SeguimientoClientesView type='add'
												handleOnSubmit={() => { }}
												cliente={cliente}
												title=""
												idPadre={cliente?.id !== undefined ? +cliente?.id : 0}
												hadleDirtyForm={handleDirtySeguimientoClientes}
											>
											</SeguimientoClientesView>
											}
										</TabPanel>
										<TabPanel>
											<FormaPagoView type="add"
												handleOnSubmit={() => { }}
												cliente={cliente}
												title=""
												idPadre={cliente?.id !== undefined ? + cliente?.id : 0}
												cabecera={["Banco", "Sucursal", "Teléfono", "Nombre y Apellidos"]}
												body={["telefono", "gestorCuentas", "usuario"]}
												bodyDisabled={[false, false, false]}
												hadleDirtyForm={handleDirtyFormaPago}
												idTest=""
											>
											</FormaPagoView>
										</TabPanel>
									</Tabs>
								</div>
							}
						</>
					}
				</FormTemplate>
			</div>
			{(openDirty || openDirtyTab) && <DirtyModal open={(openDirty || openDirtyTab)} handleCloseModal={handleCloseModalDirty}>
				{
					openDirty ?
						<CustomButton
							key={`confirmDeleteModal`}
							title={'Borrar'}
							btnStyle="delete"
							onButtonClick={() => { handleOnSubmit(validForm, dataForm, errorsForm); }}
							text={t("si")}
						/>
						:
						<CustomButton
							key={`confirmDeleteModal`}
							title={'Borrar'}
							btnStyle="delete"
							onButtonClick={() => { setTabIndex(tabIndex2Change); setOpenDirtyTab(false); handleDirtyContactos(false); handleDirtyFormaPago(false); handleDirtySeguimientoClientes(false) }}
							text={t("si")}
						/>
				}
			</DirtyModal>}
		</div>
	)

}

export default ClienteFormTemplate;