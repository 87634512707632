import ApiRequest from "../../../../domain/ApiRequest";
import { ClausulasApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import Clausula, { ClausulaCreateDto } from "../../domain/model/Clausula";

export default class ClausulaAdd extends ApiRequest<ClausulaCreateDto, Clausula>
{
    constructor(data: ClausulaCreateDto, token: string) {
        super(
            "POST",
            ClausulasApiEndpoints.CREAR,
            { Authorization: `${token}`},
            data
        )
    }
}