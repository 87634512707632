import "./IconButtonFontAwesome.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, SizeProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
	icon: IconDefinition
	className?: string
	onClick?: () => void
    title?: string
    size?: SizeProp
}	

const capitalizeFirstLetter = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
  };

const IconButtonFontAwesome: React.FC<Props> = (
	{
		icon,
		className,
		onClick,
        title,
        size
	}
) => {

	const classNames = ['icon-button-fa', className].join(" ").trimEnd();

	return (
		<button
			type="button"
			className={classNames}
			onClick={onClick}
            title={title ?? ""}
			data-cy={capitalizeFirstLetter(icon.iconName)+"Boton"}
		>
			<FontAwesomeIcon  icon={icon} size={size ?? "lg"} />
		</button>
	)
}

export default IconButtonFontAwesome;