import React, { ChangeEvent, useContext, useState } from 'react'
import { GlobalContext, GlobalContextType } from '../../context/Global.Context'
import FormFieldGroup from '../../components/ui/objects/FormFieldGroup/FormField.Group';

interface EquipoDescripcionSearchFieldProps {
  idTest?: string;
}

const EquipoDescripcionSearchField: React.FC<EquipoDescripcionSearchFieldProps> = ({idTest}) => {
    const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;
    const [value, setValue] = useState(globalState.equipos?.filters?.descripcion ?? "")

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setValue(value);
        updateGlobalState("equipos", {filters: {...globalState.equipos?.filters,"descripcion":  e.target.value.length !== 0 ? e.target.value : undefined},
          pagination: {
            skipCount: 0
          }})
        updateGlobalState("elementos", {filters: {...globalState.elementos?.filters,"descripcion": e.target.value.length !== 0 ? e.target.value : undefined},
          pagination: {
            skipCount: 0
          }})
    }

  return (
    <div className='input-container'>
    <FormFieldGroup>
    <label>Descripción</label>
    <input
        value={value}
        onChange={handleInputChange}
        data-cy={idTest}
    >
    </input>
</FormFieldGroup >
</div>
  )
}

export default EquipoDescripcionSearchField