import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { DescripcionUnidadObraApiEndpoints, UnidadObraApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { DescripcionUnidadObra, UnidadReadDto } from "../../domain/model/Unidad";

export default class UnidadDescripcionRequestGetByUnidadObraId extends ApiRequest<any, BaseResponse<DescripcionUnidadObra[]>>
{
	constructor(id:string, token: string) {
		super(
			"GET",
			DescripcionUnidadObraApiEndpoints.DEFAULT_FILTER + "?Filters[0].Key=unidadObraId&Filters[0].Value=" + id+"&SortingCriteria=Id%20DESC&MaxResultCount=99&SkipCount=0",
			{ Authorization: token },
			undefined,
			{
			}
		)
	}
}