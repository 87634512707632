import SelectableItem, { SelectableItemDto } from "../../components/ui/atoms/SuggestionInput/model/SuggestionInput.Model";
import { AutoCompleteSearchFilter, BaseFilter, BiasedType } from "../BaseFilter";


type FilterExcludedProperties =
"id"
| "text";

type BiasedSucursal = BiasedType<Sucursal, FilterExcludedProperties>;

export type SucursalFilter = AutoCompleteSearchFilter<BiasedSucursal>;

export interface SucursalDto extends SelectableItemDto {
    municipio?:string
	entidad?:string
	direccion?:string
 };

export default class Sucursal extends SelectableItem {
	constructor(
		id: string,
		descripcion: string,
		private readonly _municipio?: string,
		private readonly _entidad?: string,
		private readonly _direccion?: string
	){
		super(id, `${ _municipio} - ${ _direccion}`);
	}

	get municipio() {
		return this._municipio;
	}

	get entidad() {
		return this._entidad;
	}

	get direccion() {
		return this._direccion;
	}

	get nombreCompuesto() {
		return `${ this.municipio} - ${ this.direccion}`
	}
 };