import ApiRequest from "../../../../domain/ApiRequest";
import { OfertasEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import {  OfertasUpdateDto } from "../../domain/model/Ofertas";

export default class OfertasRequesUpdate extends ApiRequest<OfertasUpdateDto, OfertasUpdateDto> {
	constructor(data: OfertasUpdateDto, token: string) {
		super(
			"PUT",
			OfertasEndpoints.CREATE_UPDATE,
			{ Authorization: token},
			data
		)
	}
}