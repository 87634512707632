import { useCallback, useContext } from "react"
import { usePsQuery } from "@pavabits/components"
import { BaseFilter } from "../../../domain/BaseFilter"
import { AuthContext } from "../../Login/AuthContextProvider"
import { OfertasFilter } from "../domain/model/Ofertas";
import OfertasApiRepository from "../infraestructure/api/Ofertas.ApiRepository";
import OfertasRepository from "../domain/OfertasRepository";

function useOfertasSumaToneladasPost(filter: BaseFilter<OfertasFilter>): number {

	const {getToken} = useContext(AuthContext);

	const fetchOfertasSumaToneladas = useCallback(() => {
		const repo: OfertasRepository = new OfertasApiRepository(getToken());
		return repo.fetchOfertasSumaToneladas(filter);
	}, [
		filter,getToken
	]);

	const { data } = usePsQuery(fetchOfertasSumaToneladas);

	return data;
	
}

export default useOfertasSumaToneladasPost;