


interface Props {
	
}

const Unauthorized: React.FC<Props> = (/*{}: Props*/) => {

	return (
		<>
			<h2>No tiene permisos para ver esta página</h2>
		</>
	)
}

export default Unauthorized;