import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import IconButton from '../../../../components/ui/atoms/Buttons/IconButton';
import SuggestionInputField, { SuggestionInputFieldProps } from '../../../../components/ui/atoms/SuggestionInput/SuggestionInput.Field';
import FormField from '../../../../components/ui/molecules/Form-field/FormField';
import { useLoading } from '../../../../context/Loading.Context';
import { formatNumber } from '../../../../utils/NumberUtil';
import EquiposModal from '../../../Equipos/EquiposModal';
import EquiposRepository from '../../../Equipos/domain/EquiposRepository';
import Equipo, { EquiposSelectable } from '../../../Equipos/domain/model/Equipo';
import EquipoApiRepository from '../../../Equipos/infrastructure/api/Equipo.ApiRepository';
import { AuthContext } from '../../../Login/AuthContextProvider';
import UnidadesRepository from '../../domain/UnidadesRepository';
import { UnidadEquipoReadDto } from '../../domain/model/UnidadEquipo';
import UnidadesApiRepository from '../../infraestructure/api/UnidadesApiRepository';
import UnidadObraElementosEquiposModal from '../UnidadObraElementosEquiposModal';
import { Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap';
import TooltipComponent from '../../../../components/ui/molecules/Tooltip/TooltipComponent';

interface Props {
    unidadObraId: string,
    delegacionId: number,
    handleOnSubmit: () => void//(valid: boolean, data: PromotorOfertasDto, errors: FormErrors<PromotorOfertasDto>) => void
}

    // TOFIX: mirar value en input
    // TOFIX: en "asignados" array de strings, filtrar equipos por ids dentro de selected o cambiar type de selected

const EquiposUnidadView: FC<Props> = ({  unidadObraId, delegacionId,
    handleOnSubmit}) => {
    const [equipos, setEquipos] = useState<EquiposSelectable[]>([]);
    const [openNewEquipo, setOpenNewEquipo] = useState<boolean>(false);
    const [selectedEquipos, setSelectedEquipos] = useState<string[]>([]);
    const [equiposUnidad, setEquiposUnidad] = useState<UnidadEquipoReadDto[]>([]);
    const {setLoading} = useLoading();
    const {getToken, getDelegacion} = useContext(AuthContext);
    const repo: UnidadesRepository = new UnidadesApiRepository(getToken());
    const [showElementos, setShowElementos] = useState(false);
    const [selectedEquipoId, setSelectedEquipoId] = useState<string>("");
    const [selectedEquipo, setSelectedEquipo] = useState<Equipo>();

    const equiposRepo: EquiposRepository = new EquipoApiRepository(getToken());

    useEffect(() => {
        fetchUnidadObraEquipos(unidadObraId);
    }, [])

    const fetchUnidadObraEquipos = useCallback(
      (id: string) => {
        const repo: UnidadesRepository = new UnidadesApiRepository(getToken());
  
        repo.getEquiposUnidadConFiltro(id).then((resp) => {
          setEquiposUnidad([...equiposUnidad, ...resp]);
        })
      }
    ,[])

    useEffect(() => {
      getEquipoById(selectedEquipoId);
    }, [selectedEquipoId])

    const getEquipoById = useCallback((id: string) => {
      setLoading(true);
      return equiposRepo.getEquipoById(id)
        .then((res) => {
          if (res) {
            setSelectedEquipo(res as Equipo);
          }
        })
        .finally(() => {
          setLoading(false);
        })
    }, [])

    const fetchEquipos = useCallback(
      (search: string) => {
        setLoading(true)
        const repo: EquiposRepository = new EquipoApiRepository(getToken());
        var list = repo.fetchEquiposConTarifas({
        filter: {
          nombre: search,
          delegacionIds: [0, delegacionId]
				}})
        .then((res) => {
          setEquipos(res);
          return res;
        })
        .finally(() => {
            setLoading(false)
          })
          return list;
    }, [])

    const onAddEquipo = () => {
      const repo: UnidadesRepository = new UnidadesApiRepository(getToken());

      var repetido = equiposUnidad.find(x => selectedEquipos.includes(x.equipoId));
      if (repetido) {
        toast.error("Alguno de los equipos que se están intentando añadir ya pertenecen a la unidad. Por favor, revise la seleeción realizada.")
        return;
      }
      setSelectedEquipos([]);
      setEquiposUnidad(equiposUnidad);
      repo.addEquiposAUnidad({
        equipoIds: selectedEquipos.filter(x => x != ""),
        unidadObraId: unidadObraId
      }).then((resp) =>{
  
        if (resp) {
            setSelectedEquipos([]);
            repo.getEquiposUnidadConFiltro(unidadObraId).then((respEquipos) => {
              setEquiposUnidad(respEquipos);
            })
          }
          toast.success('Equipo añadido correctamente');
        })
    }

    const onDeleteEquipo = (id: any) => {
      const repo: UnidadesRepository = new UnidadesApiRepository(getToken());

      repo.deleteEquipoUnidad(id).then((resp) =>{
        if (resp) {
          var equipoUpdList = equiposUnidad.filter(x => x.id !== id);
          if (equipoUpdList && equipoUpdList.length > 0) {
            setEquiposUnidad(equipoUpdList);
          } else {
            setEquiposUnidad([]);
          }
          toast.success('Equipo eliminado correctamente');
        }
      })
    }
    
    const onChangeEquipo = (s: string[]) => {
        setSelectedEquipos(s);    
    }

    const handleOpenNewEquipo=()=>
    { setOpenNewEquipo(true);}

    const handlCloseNewEquipo=()=>
    { setOpenNewEquipo(false);}

    const onClickEquipo = (equipoId: string) => {
      setSelectedEquipoId(equipoId)
      setShowElementos(true)
    }

    const renderElementos = () => {
return   (
<table className='mini-table'>
<thead>
<tr className='table-header'>
  <th>Elemento</th>
  <th>Tipo</th>
  <th>Rendimiento</th>
  <th>Unidad</th>
  <th>Tarifa</th>
</tr>
</thead>
<tbody>
  {selectedEquipo?.equipoDetalles?.map((item: any, index: number) => (
    <tr key={index}>
      <td>{item.unidadObraElementoDescripcion}</td>
      <td>{item.unidadTipoDescripcion}</td>
      <td>{item.rendimiento}</td>
      <td>{item.unidadMedidaDescripcion}</td>
      <td>{item.tarifa}</td>

    </tr>
  )) }
</tbody>
</table>)
    }

  return (
    <>
    {//create new equipo
        <EquiposModal openParent={openNewEquipo} handleCloseModal={handlCloseNewEquipo} idTestBotonGuardar="GuardarEquipoModal" idTestDropDelegacion="Modal"></EquiposModal>
    }
    <div className="row-of-three">
      <div className='relative'>
        <div className='unidad-medida'>
          { equipos &&   
            <FormField<SuggestionInputFieldProps>
              label="Equipos"
              value={
                {
                    id: selectedEquipos.join(","),
                    text: equipos.find(x => x.id == selectedEquipos[0]) ? equipos.find(x => x.id == selectedEquipos[0])!.descripcion : ""
                }
              }
              className={"op-input95"}
              createNewItem={true}
              titleNewItem='Nuevo Equipo'
              handleNewItem={handleOpenNewEquipo}
              disabled = {false}
              required
              onChange={(e) => onChangeEquipo(e)}
              searchCallback={fetchEquipos}
              singleSelection={false}
              component={SuggestionInputField}
              idTest="EquiposTabEditar"
            />
          }
        </div>

          <div className='abs-right unidad-medida-save-icon'>
              { selectedEquipos.length > 0 &&
                    <IconButton
                    icon="done"
                    onClick={onAddEquipo}
                    label="DoneEquiposTab"
                    />
            }
          </div>

      </div>
      </div>
      <UnidadObraElementosEquiposModal 
      open={showElementos}
      children={renderElementos()}
      equipo={selectedEquipo?.nombre}
      onHide={() => setShowElementos(false)}
      />
        <h3>Equipos asignados:</h3>
        {equiposUnidad.length > 0 && (
            <div className="row-of-two">
            <table className='mini-table'>
              <thead>
              <tr className='table-header'>
                <th>Nombre</th>
                <th>Tarifa</th>
              </tr>
              </thead>
              <tbody>
                {equiposUnidad.map((item: any, index: number) => (
                  <tr key={index}>
                    <td className="pointer" onClick={() => onClickEquipo(item.equipoId)} data-cy={"NombreEqAsignado"+index}>
                    <TooltipComponent text= {item.nombre}>
                      {item.nombre}
                      </TooltipComponent>
                      </td>
                    <td style={{ width: "80px" }}data-cy={"TarifaEqAsignado"+index}>{formatNumber(item.tarifa)}</td>
                    <td style={{ width: "20px" }}><IconButton
                      icon="delete"
                      index={index}
                      onClick={() => onDeleteEquipo(item.id)}
                      label={"BorrarEquipo"}
                      />
                    </td>
                  </tr>
                )) }
              </tbody>
            </table>
            </div>
          )

        }

        
    </>
  )
}

export default EquiposUnidadView