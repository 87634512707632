import { FC, useCallback, useContext, useEffect, useState } from "react";
import CustomCheckInput from "../../../components/common/CustomCheckInput";
import IconButton from "../../../components/ui/atoms/Buttons/IconButton";
import TooltipComponent from "../../../components/ui/molecules/Tooltip/TooltipComponent";
import { useLoading } from "../../../context/Loading.Context";
import { CustomButton } from "../../../shared/components/Buttons/CustomButton";
import CustomInput from "../../../shared/components/Inputs/CustomInput/CustomInput";
import { formatDate2input, formatDate2String, formatDDMMYYYY } from "../../../utils/DateUtil";
import { formatNumber } from "../../../utils/NumberUtil";
import EstudioRepository from "../../Estudios/domain/EstudioRepository";
import Estudio from "../../Estudios/domain/model/Estudio";
import EstudioApiRepository from "../../Estudios/Infraestructure/api/Estudio.ApiRepository";
import { AuthContext } from "../../Login/AuthContextProvider";
import Ofertas from "../../Ofertas/domain/model/Ofertas";
import GenerarPresupuestoModalSection from "../../Ofertas/views/objects/Modal section/GenerarPresupuesto.ModalSection";
import Presupuesto, { NumeroPresupuestoFormat } from "../domain/model/Presupuesto";
import SelectPorOpcionModalSection from "./ModalSelectEstudio/SelectPorOpcion.ModalSection";
import "./Presupuestos.scss";
import PresupuestoRepository from "../domain/PresupuestoRepository";
import PresupuestoApiRepository from "../Infraestructure/api/Presupuesto.ApiRepository";
import { toast } from "react-toastify";
import DeleteModal from "../../../shared/components/DeleteModal/DeleteModal";

interface Props {
    title: string,
    presupuestos: Presupuesto[],
    idPadre: string,
    oferta: Ofertas,
    handleRefreshPresupuestos: () => void,
    handleContratado: (presupuesto: Presupuesto) => void,
    contratada: boolean,
    disabled?: boolean
    isOfertada?: boolean
}

const PresupuestosView: FC<Props> = ({
    presupuestos,
    idPadre,
    title,
    oferta,
    handleRefreshPresupuestos,
    handleContratado,
    contratada,
    disabled,
    isOfertada,
}) => {
    const { getToken, getDelegacion } = useContext(AuthContext);
    const { setLoading } = useLoading();
    const [selectedPresupuesto, setSelectedPresupuesto] = useState<Presupuesto>();
    const [selectedPresupuestoEdit, setSelectedPresupuestoEdit] = useState<Presupuesto>();
    const [selectedPresupuestos, setSelectedPresupuestos] = useState<Presupuesto[]>([]);
    const [openModalPorOpciones, setOpenModalPorOpciones] = useState<boolean>(false)
    const [estudio, setEstudio] = useState<Estudio>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    

    const editarPresupuesto = (presupuesto: Presupuesto) => {
        setSelectedPresupuestoEdit(presupuesto);
        setOpenModal(true);
    }

    const descargarPresupuesto = (presupuesto: Presupuesto) => {
        window.open(presupuesto.urlPdfGenerado);
    }

    const [deletionId, setDeletionId] = useState<string>();
    const openConfirmationModal = (id: string) => {
        setDeletionId(id);
        setOpenDelete(true);
    }

    const handleCloseModalDelete = (remove: boolean) => {
        setOpenDelete(false);
        if (remove) {
            deletePresupuesto(deletionId!);
        }
    }

    const deletePresupuesto = (presupuestoId: string) => {
        const repo: PresupuestoRepository = new PresupuestoApiRepository(getToken());
        repo.delete(presupuestoId)
            .then(resp => {
                toast.success('Presupuesto eliminado correctamente');
                handleRefreshPresupuestos();
            })
            .catch(error => {
                toast.error('Error al intentar eliminar el presupuesto')
            });
    }

    useEffect(() => {
        fetchEstudios(presupuestos)
    }, [presupuestos])

    const handleRefresh = () => {
        handleRefreshPresupuestos();
        setOpenModal(false);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    const handleCloseModalPorOpciones = (estudioId?: string) => {
        if (estudioId) {
            fetchEstudio(estudioId!, selectedPresupuesto)
        }
        setOpenModalPorOpciones(false);
    }

    const handleOpenModal = (presupuesto: Presupuesto) => {
        if (presupuesto.porOpciones) {
            setOpenModalPorOpciones(true);
        }
    }

    useEffect(() => {
        handleContratado(selectedPresupuesto!);
    }, [selectedPresupuesto])

    const handleChecked = (checked: boolean, presupuesto: Presupuesto) => {
        if (checked) {
            setSelectedPresupuestos(prev => prev.includes(presupuesto) ? prev : [presupuesto])
            setSelectedPresupuesto(presupuesto)
            handleOpenModal(presupuesto)
            if (!presupuesto.porOpciones) {
                handleContratado(presupuesto);
            }
        } else {
            setSelectedPresupuestos(prev => prev.filter(item => item !== presupuesto))
        }
    }

    const calculoTotalPresupuesto = (estudio: Estudio) => {
        if (estudio.totalVenta) {
            const totalVentas = estudio.totalVenta;
            const gastos = totalVentas * (oferta.gastos !== undefined && oferta.gastos > 0 ? oferta.gastos / 100 : 0);
            const beneficio = totalVentas * (oferta.beneficio !== undefined && oferta.beneficio > 0 ? oferta.beneficio / 100 : 0);
            const baseImponible = totalVentas + beneficio + gastos;
            const descuento = baseImponible * (oferta.descuento !== undefined && oferta.descuento > 0 ? oferta.descuento / 100 : 0);
            const baseImpDescuento = baseImponible - descuento;
            const totalVentaIva = baseImpDescuento * (oferta.iva !== undefined && oferta.iva > 0 ? oferta.iva / 100 : 0);

            return Number((baseImpDescuento) + totalVentaIva).toFixed(2)
        }
    }

    const fetchEstudio = useCallback((id: string, selectedPresupuesto?: Presupuesto) => {
        const repo: EstudioRepository = new EstudioApiRepository(getToken());
        return repo.getById(id).then((item) => {
            setEstudio(item);
            var totalPresupuesto = calculoTotalPresupuesto(item)
            setSelectedPresupuesto({ ...selectedPresupuesto!, totalPresupuesto: totalPresupuesto!, estudioContratado: item.id })
        })
    }, [getToken]);

    const [estudios, setEstudios] = useState<Estudio[]>([])

    const fetchEstudios = useCallback((presupuestos: Presupuesto[]) => {
        const repo: EstudioRepository = new EstudioApiRepository(getToken());
        let estudioAdd: Estudio[] = [...estudios]
        presupuestos.forEach(element => {
            return repo.getByPresupuestoId(element.id!).then((estudiosList) => {
                estudiosList.map((estudio) => {
                    estudioAdd.push(estudio)
                    return { ...estudio, creationTime: estudio.creationTime ? formatDate2input(estudio.creationTime) : '' }
                });    
            })

        });

        setEstudios(estudioAdd);

    }, [getToken]);

    return (
        <>
            <div className="inline-grid">
                <div className="inline-grid-header">
                    {contratada && oferta.estadoOfertaId != 5 && <label className="inline-header-checkbox"></label>}
                    <label>Nº Presupuesto</label>
                    <label>Nº Estudios</label>
                    <label>Fecha Presupuesto</label>
                    <label>Fecha Envío</label>
                    <label>Total Presupuesto</label>

                    <div className="inline-button">
                        <CustomButton
                            id={"add-btn"}
                            icon="add"
                            title={'NuevoPresupuestoGrid'}
                            className={"contact-btn"}
                            btnStyle="accept"
                            onButtonClick={() => { }}
                            type="button"
                            disabled={true}
                        />
                    </div>

                </div>

                <div className="inline-grid-container">

                    {presupuestos?.map((presupuesto: Presupuesto, i: number) => {
                        //const fechaEnvio = isOfertada ? presupuesto.fechaGeneracion : "";
                        
                        return (
                            <div className={selectedPresupuestos.includes(presupuesto) ? "inline-grid-item selected" : (presupuesto.contratado ? "inline-grid-item selected" : "inline-grid-item")}>
                                {contratada && oferta.estadoOfertaId != 5
                                    ?
                                    <div className="inline-grid-checkbox">
                                        <CustomCheckInput
                                            checked={selectedPresupuestos.includes(presupuesto)}
                                            icon={selectedPresupuestos.includes(presupuesto) ? "check" : undefined}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleChecked(e.target.checked, presupuesto); }}
                                        />
                                    </div>
                                    : ""
                                }

                                <CustomInput
                                    key={`$presupuestoId-${i}`}
                                    value={NumeroPresupuestoFormat(oferta, presupuesto)}
                                    className={'platform-input secondary-input'}
                                    formClassName={"secondary-input"}
                                    onChange={(e) => { }}
                                    disabled={true}

                                />
                                <TooltipComponent 
                                                text={presupuesto.estudioIds}>
                                <CustomInput
                                    key={`$nestudios-${i}`}
                                    value={presupuesto.estudioIds}
                                    className={'platform-input secondary-input'}
                                    formClassName={"secondary-input"}
                                    onChange={(e) => { }}
                                    disabled={true}
                                /></TooltipComponent>
                                <CustomInput
                                    key={`$fechaGeneracion-${i}`}
                                    value={formatDate2String(formatDDMMYYYY(presupuesto.fechaGeneracion))}
                                    className={'platform-input secondary-input'}
                                    formClassName={"secondary-input"}
                                    onChange={(e) => { }}
                                    disabled={true}
                                    type="date"
                                />
                                
                                <CustomInput
                                    key={`$fechaEnvio-${i}`}
                                    value={( presupuesto.fechaEnvio != "" ) ? formatDate2String(formatDDMMYYYY(presupuesto.fechaEnvio!)) : isOfertada ? formatDate2String(formatDDMMYYYY(presupuesto.fechaGeneracion)) : ""}
                                    className={'platform-input secondary-input'}
                                    formClassName={"secondary-input"}
                                    onChange={(e) => { }}
                                    disabled={true}
                                    type="date"
                                />

                                <CustomInput
                                    key={`presupuesto-${i}`}
                                    formClassName={"secondary-input"}
                                    value={formatNumber(presupuesto.totalPresupuesto? +presupuesto.totalPresupuesto:0)}
                                    onChange={() => { }}
                                    disabled={true}
                                    type="number"
                                />
                                <div className="inline-buttons">
                                    <IconButton icon='edit'
                                        disabled={disabled}
                                        index={i}
                                        label="EditarGrid"
                                        onClick={() => { editarPresupuesto(presupuesto); }} />
                                    {   
                                    presupuesto.urlPdfGenerado !== '' 
                                      ? <IconButton 
                                          icon='picture_as_pdf' 
                                          index={i} 
                                          label="PDFGrid" 
                                          onClick={() => { descargarPresupuesto(presupuesto); }} 
                                        />
                                      : <IconButton 
                                          icon='delete' 
                                          index={i} 
                                          label="BorrarGrid" 
                                          onClick={() => { openConfirmationModal(presupuesto.id ?? ''); }}
                                        />
                                    }                                    
                                </div>
                            </div>
                        )
                    }
                    )}

                </div>
            </div>
            {
                openDelete &&
                <DeleteModal
                    open={openDelete}
                    handleCloseModal={handleCloseModalDelete}
                    field="presupuesto"
                >
                </DeleteModal>
            }
            {
                openModal && selectedPresupuestoEdit &&
                <GenerarPresupuestoModalSection
                    opened={openModal}
                    oferta={oferta}
                    estudios={estudios.filter(est => est.presupuestoId === selectedPresupuestoEdit?.id)}
                    presupuestoId={selectedPresupuestoEdit?.id}
                    handleCloseModal={handleCloseModal}
                    handleRefresh={handleRefresh}
                />

            }
            {
                openModalPorOpciones && selectedPresupuesto &&
                <SelectPorOpcionModalSection
                    presupuestoId={selectedPresupuesto?.id!}
                    opened={openModalPorOpciones}
                    estudiosGuids={selectedPresupuesto?.estudiosGuids}
                    oferta={oferta}
                    handleCloseModal={handleCloseModalPorOpciones}
                />
            }
        </>
    )
}

export default PresupuestosView;