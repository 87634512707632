import { SuggestionInput } from "../../../../../components/ui/atoms/SuggestionInput/SuggestionInput";
import FormFieldGroup from "../../../../../components/ui/objects/FormFieldGroup/FormField.Group";
import { SelectorType } from "../../../../../components/ui/atoms/SuggestionInput/hooks/useSelectorItems";

interface Props {
	label: string
	input: string
	suggestions: SelectorType[]
	disabled?: boolean
	idTest?: string
	onInputChange: (event: any) => void
}

const OportunidadesSuggestionInput: React.FC<Props> = (props: Props) => {

	const { disabled, label, input, suggestions, onInputChange, idTest } = props;

	return (
		<div className="input-container">
		<FormFieldGroup>
			<label>{label}</label>
			<SuggestionInput
				// className="op-input"
				input={input}
				disabled={disabled}
				suggestions={suggestions}
				onInputChange={onInputChange}
				idTest={idTest}
			/>
		</FormFieldGroup>
		</div>
	)
}

export default OportunidadesSuggestionInput;