import { OportunidadDto, OportunidadFilter } from "../../domain/model/Oportunidad";
import { OportunidadesEndpoints } from "../oportunidades.api-endpoints";
import BaseRequest from "../../../../domain/BaseRequest";
import { BaseFilter } from "../../../../domain/BaseFilter";
import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";

export default class OportunidadesRequestListByFilter extends ApiRequest<
	BaseFilter<OportunidadFilter>,
	BaseResponse<OportunidadDto[]>>
 {
	public constructor(data: BaseFilter<OportunidadFilter>, token: string) {
		super(
			"POST",
			OportunidadesEndpoints.OPORTUNIDADES_CON_FILTRO,
			{ Authorization: token },
			data,
		)
	}
}