import { BaseFilter } from "../../../../domain/BaseFilter";
import BaseResponse from "../../../../domain/BaseResponse";

import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";
import EquiposRepository from "../../domain/EquiposRepository";
import Equipo, { ElementosDtoFilter, EquipoCreateDto, EquipoDto, EquipoReadDto, EquipoUpdateDto,EquiposConTarifaDto,EquiposFilter, EquiposSelectable } from "../../domain/model/Equipo";
import EquipoAdd from "../ws/Equipo.Request.Add";
import EquipoUpdate from "../ws/EquipoElemento.Request.Update";
import EquipoDelete from "../ws/Equipo.Request.Delete";
import EquipoRequestListByFilter from "../ws/Equipo.Request.ListByFilter";
import EquipoElementosRequestGetAll from "../ws/EquipoElementos.Request.GetAll";
import EquipoGruposRequestGetAll from "../ws/EquipoGrupos.Request.GetAll";
import EquipoElementoRequestAdd from "../ws/EquipoElemento.Request.Add";
import ElementoEquipo, { ElementoEquipoCreateDto, ElementoEquipoDto, ElementoEquipoReadDto, ElementoEquipoUpdateDto } from "../../domain/model/ElementoEquipo";
import EquipoElementoRequestDelete from "../ws/EquipoElemento.Request.Delete";
import EquipoRequestAdd from "../ws/Equipo.Request.Add";
import EquipoElementoRequestGetById from "../ws/EquipoElemento.Request.GetById";
import EquipoElementoRequestUpdate from "../ws/EquipoElemento.Request.Update";
import EquipoRequestDelete from "../ws/Equipo.Request.Delete";
import EquipoRequestGetById from "../ws/Equipo.Request.GetById";
import EquipoElementoRequestListByFilter from "../ws/EquipoElemento.RequestListByFilter";
import EquipoRequestUpdate from "../ws/Equipo.Request.Update";
import EquipoGruposRequestPostConTarifa from "../ws/EquipoGrupos.Request.PostConTarifa";
import { formatNumber } from "../../../../utils/NumberUtil";
import EquipoElementoRequestGetByFilter from "../ws/EquipoElemento.Request.GetByFilter";

export default class EquipoApiRepository
	extends ApiRepositoryBase<
		EquipoDto,
		Equipo
	> implements EquiposRepository {

    async delete(id: string): Promise<boolean> {
        await new EquipoDelete(id, this.authToken).executeAsPromise();
        return true;
    }

    async fetchEquiposByFilter(data: BaseFilter<EquiposFilter>): Promise<BaseResponse<EquipoReadDto[]>> {
		const res = await new EquipoRequestListByFilter(data, this.authToken).executeAsPromise();
        return res;
	}

    // Elementos

    async fetchElementosByFilter(data: BaseFilter<EquiposFilter>): Promise<BaseResponse<ElementoEquipoReadDto[]>> {
		const res = await new EquipoElementoRequestGetByFilter(data, this.authToken).executeAsPromise();
        return res;
	}

    protected fillTextFieldElementos (dto: ElementoEquipoDto[]): ElementoEquipoDto[] {
		if (dto && dto.length) {
			return dto.map((item: any) => {
				return {...item, text: (item.descripcion + " - " + item.tarifa)}
			});
		}
		return []
	}

    async fetchAllElementosEquipos(data: BaseFilter<EquiposFilter>): Promise<BaseResponse<ElementoEquipo[]>> {
		const res = await new EquipoElementosRequestGetAll(data, this.authToken).executeAsPromise();
        return (
			{
				...res,
				items: ElementoEquipo.toArray(res.items)
			}
		);
	}

    async getConFiltro(data: ElementosDtoFilter): Promise<ElementoEquipoDto[]> {
		return new Promise((resolve, reject) => {
			return new EquipoElementoRequestListByFilter(data, this.authToken)
			.request()
			.then((response) => {
				if (response.success) {
					resolve(this.fillTextFieldElementos(response.data.items));
				} else {
					reject(`Error ${response.errorCode}: ${response.errorMessage}`);
				}
			})
			.catch((error) => {
				reject(error);
			})
		})
	}

    async addElemento(data: ElementoEquipoCreateDto): Promise<any> {
        const res = await new EquipoElementoRequestAdd(data, this.authToken).executeAsPromise();
        return res;
    }

    async deleteElemento(id: string): Promise<boolean> {
        await new EquipoElementoRequestDelete(id, this.authToken).executeAsPromise();
        return true;
    }

    async updateElemento(data: ElementoEquipoUpdateDto): Promise<any> {
        const res = await new EquipoElementoRequestUpdate(data, this.authToken).executeAsPromise()
        return res;
    }

    async getElementoById(id: string): Promise<any> {
        const res = await new EquipoElementoRequestGetById(id, this.authToken).executeAsPromise();
        return res;
    }


    // Equipos

    async fetchAllGruposEquipos(data: BaseFilter<EquiposFilter>): Promise<BaseResponse<Equipo[]>> {
        const res = await new EquipoGruposRequestGetAll(data, this.authToken).executeAsPromise();
        return (
            {
				...res,
				items: Equipo.toArray(res.items)
			}
        )
    }

    protected fillTextFieldEquipo (dto: EquiposSelectable[]): EquiposSelectable[] {
		if (dto && dto.length) {
			return dto.map(item => {
				return {...item, text: item.nombre + " - " + formatNumber(+item.tarifa)}
			});
		}
		return []
	}
    

    async fetchEquiposConTarifas(data: EquiposFilter): Promise<EquiposSelectable[]> {
        const res = await new EquipoGruposRequestPostConTarifa(data, this.authToken).executeAsPromise();
        return this.fillTextFieldEquipo(res);
    }

    async addEquipo(data: EquipoCreateDto): Promise<any> {
        const res = await new EquipoRequestAdd(data, this.authToken).executeAsPromise();
        return res
    }

    async updateEquipo(data: EquipoUpdateDto): Promise<any> {
        const res = await new EquipoRequestUpdate(data, this.authToken).executeAsPromise();
        return res
    }

    async deleteEquipo(id: string): Promise<boolean> {
        await new EquipoRequestDelete(id, this.authToken).executeAsPromise();
        return true;
    }

    async getEquipoById(id: string): Promise<any> {
        const res = await new EquipoRequestGetById(id, this.authToken).executeAsPromise();
        return res;
    }



   
    
    buildModelFromItems(item: EquipoDto): Equipo {
        return new Equipo(item);
    }

    protected transformDtoIntoModel(dto: BaseResponse<EquipoDto>): BaseResponse<Equipo> {
        return (
            {
                items: this.buildModelFromItems(dto.items),
                currentPage: dto.currentPage,
                totalCount: dto.totalCount,
            }
        )
    }
}