import SelectableItem, { SelectableItemDto } from "../../components/ui/atoms/SuggestionInput/model/SuggestionInput.Model";

export type TramoFilter = {
        instalacionId: number;
        nombre?: string;
};

export interface TramoDto extends SelectableItemDto { text: string };

export default class Tramo extends SelectableItem { };

export interface TramoEntity {
    tramoId:string,
    descripcion:string, 
    tarifa:number
}

interface TramoReadingDtoEntity {
    id: string;
    instalacionId: string;
    nombre: string;
    precioTonelada: number;
    descripcion: string;
}


export class TramoReadingDto {
    id: string;
    instalacionId: string;
    nombre: string;
    precioTonelada: number;
    descripcion:string;

    constructor(entity: TramoReadingDtoEntity) {

        this.id = entity.id;
        this.instalacionId = entity.instalacionId
        this.nombre = entity.nombre;
        this.precioTonelada = entity.precioTonelada;
        this.descripcion = entity.descripcion;

    }
};
