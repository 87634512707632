import React, { ChangeEvent, useContext, useState } from 'react'
import FormFieldGroup from '../../components/ui/objects/FormFieldGroup/FormField.Group'
import { GlobalContext, GlobalContextType } from '../../context/Global.Context';

const ContactosPhoneSearchField: React.FC = () => {
    const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;

    const componentName = "contactos";

    const [value, setValue] = useState(globalState.contactos?.filters?.telefono ?? "");

    const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setValue(value);
        updateGlobalState(componentName, 
          {
            filters: {...globalState.contactos?.filters, 
              "telefono":  e.target.value.length !== 0 ? Number(e.target.value) : undefined},
              pagination: {
                skipCount: 0
              }
            })
    }

  return (
    <FormFieldGroup>
      <div className="input-container">
      <label>Número de Teléfono</label>
      <input
        type="number"
        // className="op-input"
        value={value}
        onKeyDown={(e) => {
          if (e.key.toUpperCase() === "E" || e.key === "-") {
            e.preventDefault();
          }
          }}
        data-cy="TelefonoContactoFiltro"
        onChange={handleInputChange}>
      </input>
      </div>
    </FormFieldGroup >
  )
}

export default ContactosPhoneSearchField