import { BaseRepository } from "@pavabits/components";
import SeguimientoHistoricoCliente from "../../modules/SeguimientoHistoricoClientes/domain/model/SeguimientoHistoricoCliente";
import EventosCalendarApiRepository from "../../domain/EventosCalendarApiRepository";
import EventosCalendarSendEvents from "../ws/EventosCalendar.SendEvents";


export default class EventoCalendarApiRepository
	extends BaseRepository<
    SeguimientoHistoricoCliente
	> implements EventosCalendarApiRepository {
    postSendEventsCalendar(data: SeguimientoHistoricoCliente[]): Promise<SeguimientoHistoricoCliente[]> {
        return new Promise((resolve, reject) => {
			return new EventosCalendarSendEvents(data, this.authToken)
			.request()
			.then((response) => {
				if (response.success) {
					resolve(response.data);
				} else {
					reject(`Error ${response.errorCode}: ${response.errorMessage}`);
				}
			})
			.catch((error) => {
				reject(error);
			})
		})
    }
    protected transformDtoIntoModel(dto: SeguimientoHistoricoCliente): SeguimientoHistoricoCliente {
        return dto;
    }
    }