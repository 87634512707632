
import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { PresupuestoClausulasApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import PresupuestoClausula from "../../domain/model/PresupuestoClausula";

export default class PresupuestoClausulaDelete extends ApiRequest<
{},
boolean
>
{
	constructor(id: string, token: string) {
		super(
			"DELETE",
			PresupuestoClausulasApiEndpoints.DELETE+id,
			{ Authorization: `${token}`},
			{},
		)
	}
}