import { FC, ReactElement } from 'react';
import Dropdown, { DropdownPosition } from "../dropdown/Dropdown";
import { Icon, MaterialIcons } from '../../Icon/Icon';
import '../scss/DropdownOptions.scss';

export interface DropdownOption {
    icon: MaterialIcons | string,
    text: string,
    id: any,
    idTest?: string,
    onClick?: (element: any) => void,
    selected: boolean,
    itemsChildren?: any,
    idCypress?: string
}


export interface DropdownOptionsProps {
    options: DropdownOption[],
    position?: DropdownPosition,
    className?: string,
    contentClassName?: string,
    itemsClassName?: string,
    idTest?:string;
}

const DropdownOptions: FC<DropdownOptionsProps> = ({
    children,
    options,
    className,
    contentClassName,
    itemsClassName,
    position = DropdownPosition.LEFT,
    idTest
}) => {
    const optionsElement: ReactElement = (
        <div className='dropdown-options-container' data-cy={idTest+'dropdown'}>
            {options.map((option, index) => (
                <div
                    key={index}
                    className={`dropdown-options__option ${itemsClassName}`} 
                >
                    <div className='dropdown-options__option-click'
                        onClick={option.onClick}
                        data-cy={idTest+'Opcion'+option.text}
                    >
                        <Icon icon={option.icon} className={option.selected ? 'icon-visible' : 'icon--hide'} />
                        <span>{option.text}</span>
                    </div>
                    {option.itemsChildren}
                </div>
            ))}
        </div>
    );

    return (
        <Dropdown
            classRoot={className}
            dropdownContent={optionsElement}
            position={position}
            classRootContent={contentClassName}
            idCypress={idTest}
        >
            {children}
        </Dropdown>
    )
}

export default DropdownOptions;
