
export type ContactoFilter = {
    nombre: string,
    personaContacto: string,
};

interface ContactoEntity {
    id: string;
    personaContacto: string;
    cargo: string;
    mail: string;
    telefono: string;
    direccion: string;
    municipio: string;
    delegacionNombre: string;
    clienteNombre: string;
    cif: string;
    zona: string;

}

export default class Contacto implements ContactoEntity {
    id: string;
    personaContacto: string;
    cargo: string;
    mail: string;
    telefono: string;
    direccion: string;
    municipio: string;
    delegacionNombre: string;
    clienteNombre: string;
    cif: string;
    zona: string;

    constructor(entity: ContactoEntity) {
        this.id = entity.id;
        this.personaContacto = entity.personaContacto;
        this.cargo = entity.cargo;
        this.mail = entity.mail;
        this.telefono = entity.telefono;
        this.direccion = entity.direccion;
        this.municipio = entity.municipio;
        this.delegacionNombre = entity.delegacionNombre != "" ? entity.delegacionNombre : "Todas"
        this.clienteNombre = entity.clienteNombre;
        this.cif = entity.cif;
        this.zona = entity.zona;
    }

    public static toArray(entities: ContactoEntity[]): Contacto[] {
        return entities.map(entity => {return new Contacto(entity)})
    }
}

export interface ContactoReadDto extends Contacto {
    errors: string[]
    id: string;
    personaContacto: string;
    cargo: string;
    mail: string;
    telefono: string;
    direccion: string;
    municipio: string;
    delegacionNombre: string;
    clienteNombre: string;
    cif: string;
    zona: string;
}