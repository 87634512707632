import ApiRequest from "../../../../domain/ApiRequest";
import { BaseFilter } from "../../../../domain/BaseFilter";
import BaseResponse from "../../../../domain/BaseResponse";
import { EquipoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { EquipoDto, EquiposFilter } from "../../domain/model/Equipo";

export default class EquipoGruposRequestListByFilter extends ApiRequest<
any,
BaseResponse<EquipoDto[]>>
{
	constructor(data: BaseFilter<EquiposFilter>, token: string) {
		super(
			"GET",
			EquipoApiEndpoints.ALL_EQUIPOS+"?sortingCriteria=lastModificationTime+DESC&MaxResultCount="+data.maxResultCount+"&SkipCount="+data.skipCount,
			{ Authorization: token },
			undefined,
			{
				
			}
		)
	}
}