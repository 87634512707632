import ApiRequest from "../../../../domain/ApiRequest";
import { UnidadObraMaterialApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { UnidadMaterialCreateDto, UnidadMaterialReadDto } from "../../domain/model/UnidadMaterial";

export default class UnidadesMaterialRequestAdd extends ApiRequest<UnidadMaterialCreateDto, UnidadMaterialReadDto[]>
{
    constructor(data: UnidadMaterialCreateDto, token: string) {
        super(
            "POST",
            UnidadObraMaterialApiEndpoints.ADD_LIST,
            { Authorization: `${token}`},
            data
        )
    }
}