import ApiRequest from "../../../../domain/ApiRequest";
import { BaseFilter } from "../../../../domain/BaseFilter";
import BaseResponse from "../../../../domain/BaseResponse";
import { ElementosEquipoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { ElementoEquipoReadDto } from "../../domain/model/ElementoEquipo";
import { EquipoReadDto, EquiposFilter } from "../../domain/model/Equipo";

export default class EquipoElementoRequestGetByFilter extends ApiRequest<
BaseFilter<EquiposFilter>,
BaseResponse<ElementoEquipoReadDto[]>>
{
	constructor(data: BaseFilter<EquiposFilter>, token: string) {
		super(
			"POST",
			ElementosEquipoApiEndpoints.ELEMENTO_CON_FILTRO,
			{ Authorization: token },
			data
		)
	}
}