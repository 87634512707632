import { VentanaPermiso } from "../Login/domain/model/VentanaPermiso";
import "./Cliente.scss";
import ClienteProvider from "./context/Cliente.Provider";
import ClienteGrid from "./views/objects/Clientes.Grid";

interface Props {
	permisoVentana: VentanaPermiso
}

const Cliente: React.FC<Props> = ({permisoVentana}: Props) => {
	return (
		<ClienteProvider>
			<div className='view-container'>
				<ClienteGrid permisoVentana={permisoVentana} />
			</div>
		</ClienteProvider>
	)
}

export default Cliente;