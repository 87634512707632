import ApiRequest from "../../../../domain/ApiRequest";
import { PresupuestoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { PresupuestoDto } from "../../domain/model/Presupuesto";

export default class PresupuestoRequestGetById extends ApiRequest<{}, PresupuestoDto>
{
	constructor(id: string, token: string) {
		super(
			"GET",
			PresupuestoApiEndpoints.CON_ID + id,
			{ Authorization: token },
			{}
		)
	}
}