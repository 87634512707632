import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";
import { BaseFilter } from "../../../../domain/BaseFilter";
import BaseResponse from "../../../../domain/BaseResponse";
import { SeguimientoClienteDto, SeguimientoClienteUpdateDto } from "../../../Clientes/domain/model/SeguimientoCliente";
import SeguimientoHistoricoClienteRepository from "../../domain/SeguimientoHistoricoClienteRepository";
import SeguimientoHistoricoCliente, { SeguimientoHistoricoClienteDto, SeguimientoHistoricoClienteFilter } from "../../domain/model/SeguimientoHistoricoCliente";
import SeguimientoHistoricoClienteRequestAdd from "../ws/SeguimientoHistoricoClienteRequestAdd";
import SeguimientoHistoricoClienteRequestConFiltro from "../ws/SeguimientoHistoricoClienteRequestConFiltro";
import SeguimientoHistoricoClienteRequestDelete from "../ws/SeguimientoHistoricoClienteRequestDelete";
import SeguimientoHistoricoClienteRequestDownloadExcel from "../ws/SeguimientoHistoricoClienteRequestDownloadExcel";
import SeguimientoHistoricoClienteRequestGetById from "../ws/SeguimientoHistoricoClienteRequestGetById";
import SeguimientoHistoricoClienteRequestUpdate from "../ws/SeguimientoHistoricoClienteRequestUpdate";

export default class SeguimientoHistoricoClienteApiRepository extends ApiRepositoryBase<any, any> implements SeguimientoHistoricoClienteRepository {
    async fetchSeguimientosByFilter(data: BaseFilter<SeguimientoHistoricoClienteFilter>): Promise<BaseResponse<any[]>> {
		const res = await new SeguimientoHistoricoClienteRequestConFiltro(data, this.authToken).executeAsPromise();
		return (
			{
				...res,
				items: SeguimientoHistoricoCliente.toArray(res.items)
			}
		);
	}

    async add(data: any): Promise<BaseResponse<any[]>> {
		const res = await new SeguimientoHistoricoClienteRequestAdd(data, this.authToken).executeAsPromise();
		return res;
	}

	async delete(id: string): Promise<boolean> {
		await new SeguimientoHistoricoClienteRequestDelete(id, this.authToken).executeAsPromise();
		return true;  
	}

	async getById(id: string): Promise<any> {
		return await new SeguimientoHistoricoClienteRequestGetById(id, this.authToken).executeAsPromise()
	}

	async update(data:  SeguimientoClienteUpdateDto): Promise<SeguimientoClienteDto> {
		return await new  SeguimientoHistoricoClienteRequestUpdate(data, this.authToken).executeAsPromise();
	}

	downloadExcel(data: BaseFilter<SeguimientoHistoricoClienteFilter>): Promise<string> {
		return new Promise((resolve, reject) => {
			new SeguimientoHistoricoClienteRequestDownloadExcel(data, this.authToken)
				.request()
				.then((response) => {
					if (response.success) {
						resolve(response.data);
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error) => {
					reject(error);
				})
		})
	}

    protected mapResponseItems(dto: BaseResponse<any[]>): BaseResponse<any[]> {
		return (
			{
				items: dto.items.map(item => this.buildModelFromItems(item)),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}

	buildModelFromItems(item: any): SeguimientoHistoricoCliente {
		return new SeguimientoHistoricoCliente(item);
	}

	protected transformDtoIntoModel(dto: BaseResponse<any>): BaseResponse<any> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}
}