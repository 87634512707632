
import ApiRequest from "../../../../domain/ApiRequest";
import { EstudioDetalleAvanzadoTrasladoDto } from "../../../Estudios/domain/model/EstudioDetalleAvanzadoTraslado";
import { EstudioDetalleAvanzadoTrasladoEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class EstudioDetalleAvanzadoTrasladoRequestDelete extends ApiRequest<{}, EstudioDetalleAvanzadoTrasladoDto>
{
	constructor(id: string, token: string) {
		super(
			"DELETE",
			EstudioDetalleAvanzadoTrasladoEndpoints.DELETE+id,
			{ Authorization: `${token}`},
			{},
		)
	}
}