import { BaseEntity } from "@pavabits/components";
import Contratista from "./Contratista";

export default class Adjudicacion extends BaseEntity {
	constructor(
		private readonly _codigoPlica: number,
		private readonly _codigoLicitacion: number,
		private readonly _contratista: Contratista,
		private readonly _asfaltero: string,
		private readonly _cif:string,
		private readonly _contratistaId:number,
	) {
		super(_codigoPlica.toString() + _codigoLicitacion.toString() + _contratista.id, "");
	}

	get codigoPlica() {
		return this._codigoPlica;
	}

	get codigoLicitacion() {
		return this._codigoLicitacion;
	}

	get contratista() {
		return this._contratista;
	}

	get asfaltero(){
		return this._asfaltero;
	}

	get cif(){
		return this._cif;
	}

	get contratistaId(){
		return this._contratistaId;
	}

}
