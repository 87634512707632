import TablePrintableObject from "../../../../components/Table/types/TablePrintableObject";
import ContactosCliente from "./ContactosCliente";

export type ClienteFilter = { descripcion: string };

export type ClienteParams = { [id: string]: any };

interface ClienteEntity {
	id: string
	cif: string
	descripcion: string
	descripcionCorta: string
	riesgo?: number
	lastModificationTime: string
	lastModificationUsername: string
	refContabilidad?: number
	contacts?: ContactosCliente[]
	proveedorId?: number
	naturalezaId: string;
	iva?: number;
	tieneObservaciones: boolean;
	observaciones?: string;
}

export default class Cliente extends TablePrintableObject {

	id: string;
	cif: string;
	descripcion: string;
	descripcionCorta: string;
	riesgo?: number;
	lastModificationTime: string;
	lastModificationUsername: string;
	refContabilidad?: number;
	proveedorId?: number;
	naturalezaId: string;
	iva?: number;
	tieneObservaciones: boolean;
	observaciones?: string;
	controllerName: string;

	constructor(entity: ClienteEntity) {
		super(entity.id, entity.descripcion);
		this.id = entity.id;
		this.cif = entity.cif;
		this.descripcion = entity.descripcion;
		this.descripcionCorta = entity.descripcionCorta;
		this.riesgo = entity.riesgo;
		this.lastModificationTime = entity.lastModificationTime;
		this.lastModificationUsername = entity.lastModificationUsername;
		this.refContabilidad = entity.refContabilidad;
		this.proveedorId = entity.proveedorId;
		this.naturalezaId = entity.naturalezaId;
		this.iva = entity.iva;
		this.tieneObservaciones = entity.tieneObservaciones;
		this.observaciones = entity.observaciones;
		this.controllerName="/clientes/"
	}

	toMap = () => {
		return new Map<keyof Cliente, string>(
			[
				["id", this.id],
				["refContabilidad", this.refContabilidad?.toString() ?? ""],
				["cif", this.cif],
				["descripcion", this.descripcion],
				["descripcionCorta", this.descripcionCorta],
				["proveedorId", this.proveedorId?.toString() ?? "0"],
				["naturalezaId", this.naturalezaId],
				["tieneObservaciones", this.tieneObservaciones ? "true" : "false"],
				["observaciones", this.observaciones ?? ""]
			]
		)
	};

	public static toArray(entities: ClienteEntity[]): Cliente[] {
		return entities.map(entity => { return new Cliente(entity) });
	}
}

export type ClienteDto = ClienteCreationDto;

export interface ClienteCreationDto extends ClienteEntity { 
	id: string;
	cif: string;
	descripcion: string;
	descripcionCorta: string;
	riesgo?: number;
	lastModificationTime: string;
	lastModificationUsername: string;
	refContabilidad?: number;
	proveedorId?: number;
	iva?: number;
	tieneObservaciones: boolean;
	observaciones?: string;
	};

export interface ClienteUpdateDto extends ClienteEntity { 
};

export interface ClienteDropdown {
	id: string;
	descripcion: string;
}