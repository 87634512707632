import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";


interface Props {
    openParent: boolean,
    handleCloseModal: () => void
}


const Pavaconfeti= () => {

    const { width, height } = useWindowSize();

    


    return (
        <Confetti width={width} height={height} recycle={true} />
    )
}

export default Pavaconfeti;