import BaseResponse from "../../../../domain/BaseResponse";
import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";

import SeguimientoOferta, { SeguimientoOfertaDeleteDto, SeguimientoOfertaDto, SeguimientoOfertaUpdateDto } from "../../domain/model/SeguimientoOferta";
import SeguimientoOfertaRepository from "../../domain/SeguimientoOfertaRepository";
import SeguimientoOfertaRequestAdd from "../ws/SeguimientoOferta.Request.Add";
import SeguimientoOfertaRequestDelete from "../ws/SeguimientoOferta.Request.Delete";
import SeguimientoOfertaRequestGetByClienteId from "../ws/SeguimientoOferta.Request.GetByClienteId";
import SeguimientoOfertaRequestUpdate from "../ws/SeguimientoOferta.Request.Update";

export default class SeguimientoOfertaApiRepository
	extends ApiRepositoryBase<
		SeguimientoOfertaDto,
		SeguimientoOferta
	> implements  SeguimientoOfertaRepository {

	
	async add(data:  SeguimientoOferta): Promise< SeguimientoOferta> {
		const res= await new  SeguimientoOfertaRequestAdd(data, this.authToken).executeAsPromise();
		return res;
	}

	async getByClienteId(id: string): Promise<SeguimientoOferta[]> {
		const res= await new SeguimientoOfertaRequestGetByClienteId(id, this.authToken).executeAsPromise();
		return SeguimientoOferta.toArray(res?.items);
	}


	async update(data:  SeguimientoOfertaUpdateDto): Promise<SeguimientoOferta> {
		return await new  SeguimientoOfertaRequestUpdate(data, this.authToken).executeAsPromise();
	}

	async delete(id: string): Promise<SeguimientoOfertaDeleteDto> {
		return await new  SeguimientoOfertaRequestDelete(id, this.authToken).executeAsPromise();
		
	}

	buildModelFromItems(item:  SeguimientoOfertaDto):  SeguimientoOferta {
		return new  SeguimientoOferta(item);
	}

	protected transformDtoIntoModel(dto: BaseResponse< SeguimientoOfertaDto>): BaseResponse<SeguimientoOferta> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}
}