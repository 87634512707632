import { PresupuestoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ApiRequest from "../../../../domain/ApiRequest";
import { PresupuestoDto, PresupuestoUpdateDto } from "../../domain/model/Presupuesto";

export default class PresupuestoSimpleUpdate extends ApiRequest<
PresupuestoUpdateDto,
PresupuestoDto
>
{
	constructor(data: PresupuestoUpdateDto, token: string) {
		super(
			"PUT",
			PresupuestoApiEndpoints.SIMPLEUPDATE,
			{ Authorization: `${token}`},
			data,
		)
	}
}