
import ApiRequest from "../../../../domain/ApiRequest";
import { EstudioDetalleAvanzadoEquipoDto } from "../../../Estudios/domain/model/EstudioDetalleAvanzadoEquipo";
import { EstudioDetalleAvanzadoMaterialDto } from "../../../Estudios/domain/model/EstudioDetalleAvanzadoMaterial";
import { EstudioDetalleAvanzadoEquipoEndpoints, SeguimientoOfertaEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { SeguimientoOfertaDeleteDto } from "../../domain/model/SeguimientoOferta";

export default class EstudioDetalleAvanzadoEquipoRequestDelete extends ApiRequest<
{},
EstudioDetalleAvanzadoEquipoDto
>
{
	constructor(id: string, token: string) {
		super(
			"DELETE",
			EstudioDetalleAvanzadoEquipoEndpoints.DELETE+id,
			{ Authorization: `${token}`},
			{},
		)
	}
}