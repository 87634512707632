import { BaseEntity } from "@pavabits/components";
import { useState } from "react";

export type SelectorType = {
	id: string,
	text: string,
	selected: boolean,
	onClick: (selected: boolean, value: any) => void,
	clearAll:()=>void
}

export default function useSelectorItems<T extends BaseEntity>(
	items: T[],
	currentIds: string[],
	onSuggestionClick: (value: any) => void,
	singleSelection?: boolean
) {

	const [selectedItems, setSelectedItems] = useState<Partial<SelectorType>[]>([]);

	function elaborateText() {
		let text = "";
		selectedItems.forEach((selectedItem, index) => {
			if (selectedItems != undefined && selectedItems.length > 0 && selectedItem !== undefined && selectedItem.id !== "") {
				if (index !== currentIds.length - 1) text += selectedItems.find(x => x.id == selectedItem.id)?.text + ", ";
				else text += selectedItems.find(x => x.id == selectedItem.id)?.text;
			}
		});
		return text;
	}

	function elaboratePayload(selected: boolean, id: string) {
		if (selected) {
			currentIds =  currentIds.filter((item: any) => item != id);
			return currentIds.filter((item: any) => item != id);
		} else {
			if (!!singleSelection)
			{
				return [id];
			} else {
				return [...currentIds, id];
			}
		}

	}

	function handleClick(selected: boolean, id: string, text: string) {
		const payload = elaboratePayload(selected, id);
		onSuggestionClick(payload);

		if (payload.length > 0) {
			if (singleSelection) {
				// HAY QUE COMPROBAR PRIMERO SI ES QUITAR O PONER
				if (!selected) {
					setSelectedItems([{ id, text }]); 
				} else {
					setSelectedItems([]);
				}

			} else {
				// HAY QUE COMPROBAR PRIMERO SI ES QUITAR O PONER
				if (selected) {
					let updateSelectedItems = selectedItems.filter(item => item.id != id)
					setSelectedItems(updateSelectedItems)
				} else {
					setSelectedItems([...selectedItems, { id, text }]);
				}
			}
		}
		else {
			setSelectedItems([]);
		} 
	}

	function elaborateSuggestions() {
		let temporalSuggestions: SelectorType[] = [];
		// DESARROLLO-5285 - Los elementos seleccionados no se actualizaban al quitarlos desde fuera del componente
		let temporalSelectedItems = singleSelection ? selectedItems : selectedItems.filter(item => currentIds.includes(item.id!.toString()));

		items && items.forEach(item => {
			var isSelected = false;

			currentIds.forEach(id => {
				if(id.toString() == (item.id.toString()))
				{
					isSelected = true;
				}
				
			})
			
			const nextSuggestion = {
				id: item.id,
				text: item.text,
				selected: isSelected,
				onClick: () => handleClick(isSelected, item.id, item.text),
				clearAll
			}

			if (isSelected) {
				temporalSuggestions.unshift(nextSuggestion);
			} else {
				temporalSuggestions.push(nextSuggestion);
			}
			
		});

		temporalSelectedItems.forEach(itemSelected => {
			const exists = temporalSuggestions.find( i => i.id == itemSelected.id?.toString());

			if (!exists) {
				const addSelected = {
					id: itemSelected.id!,
					text: itemSelected.text!,
					selected: true,
					onClick: () => handleClick(true, itemSelected.id!, itemSelected.text!),
					clearAll
				}
				temporalSuggestions.unshift(addSelected);
			}

		});

		return temporalSuggestions;
	}

	function clearAll() {
		setSelectedItems([]);
	}

	return {
		suggestions: elaborateSuggestions(),
		text: elaborateText(),
		clearAll,
		selected:selectedItems?.length>0
	}
}