import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { UnidadObraEquipoApiEndpoints} from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { UnidadEquipoReadDto } from "../../domain/model/UnidadEquipo";



export default class UnidadesEquipoRequestListByFilter extends ApiRequest<any, BaseResponse<UnidadEquipoReadDto[]>>
{
	constructor(id:string, token: string) {
		super(
			"GET",
			UnidadObraEquipoApiEndpoints.DEFAULT_FILTER + "?Filters[0].Key=unidadObraId&Filters[0].Value=" + id+"&SortingCriteria=Id%20DESC&MaxResultCount=99&SkipCount=0",
			{ Authorization: token },
			undefined,
			{
			}
		)
	}
}