import { AxiosGenericRequest } from "@pavabits/components";
import GenericRequest from "@pavabits/components/dist/base/GenericRequest";
import { toast } from "react-toastify";

abstract class ApiRequest<TRequest = {}, TResponse = {}>
	extends AxiosGenericRequest<TRequest, TResponse>
	implements GenericRequest<TResponse> {

	executeAsPromise(): Promise<TResponse> {
		return new Promise<TResponse>((resolve, reject) => {
			return this.request()
				.then((response) => {
					if (response.success) {
						resolve(response.data);
					}
					else {
						reject(`${response.errorCode} : ${response.errorMessage}`);
					}
				})
				.catch((error) => {
					//pavabitsError
					if (error && error.response) {
						switch(error?.response.status)
						{
							case 520:
								toast.error(error.response?.data?.errors?.join(','));
							break;
							case 400:
								if (error.response?.data?.errors.length > 0) {
									toast.error(error.response.data.errors[0]);
								} else {
									toast.error("Ha ocurrido un error. Por favor, contacte con soporte para informar del problema.");
								}									
							break;
							default:
								toast.error(error?.response?.data?.title);
								console.log(`error=>${error.response?.data?.errors?.join(',')}`);
							break;
						}
					}

					reject(error);
				})
		})
	}
}

export default ApiRequest;
