import { BaseRepository } from "@pavabits/components";
import AccionesComercialesRepository from "../../domain/AccionesComercialesRepository";
import AccionComercial, { AccionComercialDto } from "../../domain/model/AccionComercial";
import AccionesComercialesRequestById from "../ws/AccionesComerciales.RequestById";

export default class AccionesComercialesApiRepository extends BaseRepository<
AccionComercialDto[],
AccionComercial[]
> implements AccionesComercialesRepository {

	fetchAccionComercial(oportunidadId: string): Promise<AccionComercial[]> {
		return new Promise((resolve, reject) => {
			return new AccionesComercialesRequestById(oportunidadId, this.authToken)
			.request()
			.then((response) => {
				if (response.success) {
					resolve(this.transformDtoIntoModel(response.data.items))
				} else {
					reject(`Error ${response.errorCode} : ${response.errorMessage}`);
				}
			})
			.catch((error) => {
				reject(error);
			})
		})
	}

	protected transformDtoIntoModel(dto: AccionComercialDto[]): AccionComercial[] {
		if (dto && dto.length) {
			return dto.map(item => {
				return new AccionComercial(
					item.accionComercialId,
					item.codigo,
					item.delegacionDescripcion,
					item.obraDescripcion,
					item.promotorDescripcion,
					new Date(item.fecha),
					item.oportunidadId,
					item.presupuesto,
					item.toneladas,
					item.accionComercialTipo,
					item.porcentajeAsfalto,
					new Date(item.fechaAdjudicacion),
					item.porcentajeBaja,
					item.estadoAccionDescripcion,
					item.ajusteToneladas,
					item.motivo
				)
			})
		}
		return []
	}
	
}