import { useContext } from "react";
import { ProvinciaFieldContext } from "../../../context/FilterFields.Context";
import OportunidadesSuggestionInput from "./Oportunidades.SuggestionInput";

const ProvinciaSuggestionInput: React.FC<{}> = () => {
	const field = useContext(ProvinciaFieldContext);

	return (
		<OportunidadesSuggestionInput
			label="Provincia"
			idTest="ProvinciaFiltro"
			{...field}
		/>
	)
}

export default ProvinciaSuggestionInput;