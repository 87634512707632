import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "react-bootstrap";
import useDateReducer from "./useDateReducer";
import FormFieldGroup from "../../objects/FormFieldGroup/FormField.Group";
import "./DateSelector.scss";
import { useState } from "react";

interface Props {
	onDateSelect: (dates: string[]) => void
	idTest?: string
}

const DateSelector: React.FC<Props> = ({ onDateSelect, idTest }) => {
	const [show, setShow] = useState(false);

	const onOpen = () => {
		setShow(true);
	}

	const onClose = () => {
		setShow(false);
	}

	const {
		dates,
		period,
		recent,
		onFromInputChange,
		onRangePickChange,
		onTermPickChange,
		onUntilInputChange,
		onYearPickChange,
		onClear,
	} = useDateReducer();

	const [from, until] = dates;

	const maxDate = '3000-1-11';

	function handleCancel() {
		onClear();
		onClose();
	}

	function handleAccept() {
		onDateSelect([from!, until!]);
		// onClear();
		onClose();
	}

	return (
		<div
			className="date-selector-container"
		>
			<label
				onClick={onOpen}
				data-cy={idTest === undefined ? "" : idTest}
			>
				{dates && dates[0] !== undefined && dates[1] !== undefined
				? new Date(dates[0]).toLocaleDateString('es-es', { month: '2-digit', day: '2-digit', year: 'numeric', hour12: false }) + " - " + 
					new Date(dates[1]).toLocaleDateString('es-es', { month: '2-digit', day: '2-digit', year: 'numeric', hour12: false })
				: "Seleccionar fecha"
				}
			</label>
			<Modal
				className="date-selector"
				show={show}
			>
				<ModalHeader>
					<ModalTitle>Selector de fechas</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<h4>Introducir</h4>
					<div className="inputs">
						<FormFieldGroup>
							<label>Desde</label>
							<input
								max={maxDate}
								type="date"
								value={from}
								placeholder="dd/mm/aaaa"
								pattern="\d{2}/\d{2}/\d{4}"
								onChange={(e: any) => onFromInputChange(e.target.value)}
								data-cy="FechaDesdeModal"
							/>
						</FormFieldGroup>
						<FormFieldGroup>
							<label>Hasta</label>
							<input
								max={maxDate}
								type="date"
								value={until}
								placeholder="dd/mm/aaaa"
								onChange={(e: any) => onUntilInputChange(e.target.value)}
								data-cy="FechaHastaModal"
							/>

						</FormFieldGroup>
					</div>
					<h4>Reciente</h4>
					<div className="range-checks">
						<div>
							<input
								type="checkbox"
								checked={recent?.lastSevenDays}
								onChange={() => onRangePickChange("lastSevenDays")(7)}
							/>
							<label>Últimos 7 días</label>
						</div>
						<div>
							<input
								type="checkbox"
								checked={recent?.lastMonth}
								onChange={() => onRangePickChange("lastMonth")(30)}
							/>
							<label>Último mes</label>
						</div>
						<div>
							<input
								type="checkbox"
								checked={recent?.lastThreeMonths}
								onChange={() => onRangePickChange("lastThreeMonths")(90)}
							/>
							<label>Últimos 3 meses</label>
						</div>
						<div>
							<input
								type="checkbox"
								checked={recent?.lastYear}
								onChange={() => onRangePickChange("lastYear")(365)}
							/>
							<label>Último año</label>
						</div>
					</div>
					<div className="year-periods">
						<div className="years">
							<h4>Año</h4>
							<div className="checks">
								<div>
									<input
										type="checkbox"
										checked={period?.year?.currentYear ?? false}
										onChange={() => onYearPickChange("currentYear")("current")}
									/>
									<label>{new Date().getFullYear()}</label>

								</div>
								<div>
									<input
										type="checkbox"
										checked={period?.year?.prevYear ?? false}
										onChange={() => onYearPickChange("prevYear")("previous")}
									/>
									<label>{new Date().getFullYear() - 1}</label>

								</div>
								<div>
									<input
										type="checkbox"
										checked={period?.year?.prevTwoYears ?? false}
										onChange={() => onYearPickChange("prevTwoYears")("2-previous")}
									/>
									<label>{new Date().getFullYear() - 2}</label>

								</div>
								<div>
									<input
										type="checkbox"
										checked={period?.year?.prevThreeYears ?? false}
										onChange={() => onYearPickChange("prevThreeYears")("3-previous")}
									/>
									<label>{new Date().getFullYear() - 3}</label>

								</div>
								<div>
									<input
										type="checkbox"
										checked={period?.year?.prevFourYears ?? false}
										onChange={() => onYearPickChange("prevFourYears")("4-previous")}
									/>
									<label>{new Date().getFullYear() - 4}</label>

								</div>
							</div>
						</div>
						<div className="periods">
							<h4>Periodo</h4>
							<div className="checks">
								<div>
									<input
										type="checkbox"
										checked={period?.term?.wholeYear ?? false}
										onChange={() => onTermPickChange("wholeYear")("whole")}
									/>
									<label>Todo el año</label>
								</div>
								<div>
									<input
										type="checkbox"
										checked={period?.term?.firstTerm ?? false}
										onChange={() => onTermPickChange("firstTerm")("first")}
									/>
									<label>Primer trimestre</label>

								</div>
								<div>
									<input
										type="checkbox"
										checked={period?.term?.secondTerm ?? false}
										onChange={() => onTermPickChange("secondTerm")("second")}
									/>
									<label>Segundo trimestre</label>
								</div>
								<div>
									<input
										type="checkbox"
										checked={period?.term?.thirdTerm ?? false}
										onChange={() => onTermPickChange("thirdTerm")("third")}
									/>
									<label>Tercer trimestre</label>
								</div>
								<div>
									<input
										type="checkbox"
										checked={period?.term?.fourthTerm ?? false}
										onChange={() => onTermPickChange("fourthTerm")("fourth")}
									/>
									<label>Cuarto trimestre</label>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<div className="footer-actions">
						<button
							className='btn-primary'
							onClick={handleCancel}
							data-cy="CancelarFechaModal"
						>
							Cancelar
						</button>
						<button
							className='btn-primary'
							disabled={from === undefined || until === undefined }
							onClick={handleAccept}
							data-cy="AceptarFechaModal"
						>
							Aceptar
						</button>
					</div>
				</ModalFooter>
			</Modal>
		</div>
	)
}

export default DateSelector;