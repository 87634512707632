import { Icon } from "@pavabits/components";
import Config from "../../../../Config";
import logo from "../../../../assets/images/logo-sidebar.png";
import SidebarNavigation from "./Sidebar.Navigation";
import { useContext } from "react";
import { AuthContext } from "../../../../modules/Login/AuthContextProvider";
import { ReactComponent as UserIcon} from '../../../../assets/icons/user-icon.svg';

interface Props {
	isSidebarHidden: boolean
	onHideSidebar: () => void
}

const Sidebar: React.FC<Props> = ({ isSidebarHidden, onHideSidebar }) => {

	const classes = () => { return ["sidebar", isSidebarHidden ? "sidebar--hidden" : ""].join(" ").trimEnd() }
	
	const {username, logout, getDelegacion} = useContext(AuthContext);

	return (
		<div className={classes()}>
			<div className="sidebar__brand" data-cy="SidebarAplicacion">
				<img src={logo} alt="Pavasal" />
				<Icon icon="navigate_before" className="icon--pointer" onClick={onHideSidebar}/>
				
				<div>
				<UserIcon />
				<label className="font-user">{username}</label>
				<label className="font-user">{getDelegacion === undefined ? 'Sin delegación' : getDelegacion().descripcion}</label>
				</div>
				<button className="logout-button " data-cy="btn-logout" onClick={logout}>Logout</button>
			</div>
			<SidebarNavigation />
			<div className="sidebar__version">
				<a href="https://www.pavasal.com/">
					&copy; 2024 Pavasal
				</a>
				<div children={`v${Config.VERSION}`} />
			</div>
		</div>
	)
}

export default Sidebar;