import { BaseRepository } from "@pavabits/components";
import Material, { MaterialDto, MaterialFilter, MaterialReadingDto } from "../../domain/model/Material";
import MaterialesRepository from "../../domain/MaterialesRepository";
import MaterialesRequestListByFilter from "../ws/Materiales.RequestListByFilter";
import MaterialesRequestGetById from "../ws/Materiales.Request.GetById";



export default class MaterialesApiRepository
	extends BaseRepository<
		MaterialDto,
		Material
	> implements MaterialesRepository {
        
    protected transformDtoIntoModel(dto: MaterialDto): Material {
        return new Material(
			dto.id,
			dto.descripcion
		)
    }

	protected fillTextField (dto: MaterialDto[]): MaterialDto[] {
		if (dto && dto.length) {
			return dto.map(item => {
				return {...item, text: item.descripcion}
			});
		}
		return []
	}

	getConFiltro(data: MaterialFilter): Promise<MaterialDto[]> {
		return new Promise((resolve, reject) => {
			return new MaterialesRequestListByFilter(data, this.authToken)
			.request()
			.then((response) => {
				if (response.success) {
					resolve(this.fillTextField(response.data.items));
				} else {
					reject(`Error ${response.errorCode}: ${response.errorMessage}`);
				}
			})
			.catch((error) => {
				reject(error);
			})
		})
	}

	async getMaterialById(id: string): Promise<MaterialReadingDto> {
        const res = await new MaterialesRequestGetById(id, this.authToken).executeAsPromise();
        return res;
    }
}
