import { BaseApiRequest } from "@pavabits/components";
import { EventCalendarEndpoints } from "../../modules/Oportunidades/infraestructure/oportunidades.api-endpoints";
import SeguimientoHistoricoCliente from "../../modules/SeguimientoHistoricoClientes/domain/model/SeguimientoHistoricoCliente";
import BaseResponse from "../../domain/BaseResponse";



export default class EventosCalendarSendEvents extends BaseApiRequest<SeguimientoHistoricoCliente[],SeguimientoHistoricoCliente[]> {
    public constructor (data : SeguimientoHistoricoCliente[], token : string){
        super(
            "POST",
            EventCalendarEndpoints.ENVIAR_EVENTO,
            data,
            {Authorization: `${token}`}
        )
    }
}