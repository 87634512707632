import { SuggestionInput } from "./SuggestionInput"
import useSearchCallback from "./hooks/useSearchCallback"
import useSelectorItems from "./hooks/useSelectorItems";

export interface SuggestionInputFieldProps {
	value?: { id: string, text: string }
	disabled?: boolean
	className?: string
	singleSelection?: boolean
	idTest?:string
	label?:string
	onChange: (value: any) => void
	searchCallback: (search: string) => Promise<any>
	set?: ()=>void
}

const SuggestionInputField: React.FC<SuggestionInputFieldProps> = (
	{
		value,
		disabled,
		className,
		singleSelection,
		onChange,
		searchCallback,
		label,
		idTest,
	}
) => {

	const { input, results, onInputChange } = useSearchCallback(searchCallback, value?.text);

	const { suggestions, text, selected } = useSelectorItems(results, ((value && value.id != undefined) ? 
																	   (value.id.toString().includes(",")) ? 
																		value.id.split(",") : [value.id] 
																		: []), onChange, singleSelection);

																		
	return (<>
		<SuggestionInput
			input={(selected===false || !singleSelection)? input:text}
			disabled={disabled}
			className={className}
			suggestions={suggestions}
			onInputChange={onInputChange}
			cleanButton={true}
			idTest={idTest}
		/>
		</>
	)
}

export default SuggestionInputField;