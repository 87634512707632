import BaseResponse from "../../../../domain/BaseResponse";
import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";

import EstudioDetalleAvanzadoTramo, { EstudioDetalleAvanzadoTramoDto } from "../../../Estudios/domain/model/EstudioDetalleAvanzadoTramo";
import EstudioDetalleAvanzadoTramoRepository from "../../domain/EstudioDetalleAvanzadoTramoRepository";
import EstudioDetalleAvanzadoTramoRequestDelete from "../ws/EstudioDetalleAvanzadoTramo.Request.Delete";


export default class EstudioDetalleAvanzadoTramoApiRepository 
	extends ApiRepositoryBase<EstudioDetalleAvanzadoTramoDto, EstudioDetalleAvanzadoTramo> 
	implements EstudioDetalleAvanzadoTramoRepository {

	async delete(id: string): Promise<EstudioDetalleAvanzadoTramoDto> {
		return await new EstudioDetalleAvanzadoTramoRequestDelete(id, this.authToken).executeAsPromise();		
	}

	buildModelFromItems(item:  EstudioDetalleAvanzadoTramoDto):  EstudioDetalleAvanzadoTramo {
		return new  EstudioDetalleAvanzadoTramo(item);
	}

	protected transformDtoIntoModel(dto: BaseResponse<EstudioDetalleAvanzadoTramoDto>): BaseResponse<EstudioDetalleAvanzadoTramo> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}
}