import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthContext } from "../Login/AuthContextProvider";
import EquiposRepository from "./domain/EquiposRepository";
import EquipoApiRepository from "./infrastructure/api/Equipo.ApiRepository";
import FormTemplate from "../../components/common/FormTemplate/FormTemplate";
import CustomTextInput from "../../components/CustomTextInput";
import { toast } from "react-toastify";
import {
  ElementoEquipoCreateDto,
  UnidadObraElementoTipo,
} from "./domain/model/ElementoEquipo";
import FormField from "../../components/ui/molecules/Form-field/FormField";
import Dropdown, {
  DropdownProps,
} from "../../components/ui/atoms/Dropdown/Dropdown";
import { DelegacionDto } from "../../domain/model/Delegacion";
import DelegacionesRepository from "../../domain/DelegacionesRepository";
import DelegacionesApiRepository from "../../infraestructure/api/Delegaciones.ApiRepository";
import { HistoryHandler } from "../../context/History.Context";
import { Routes } from "../../router/routes/Routes";
import { useLoading } from "../../context/Loading.Context";
import { UnidadMedidaDto } from "../../domain/model/UnidadMedida";
import UnidadesMedidaRepository from "../../domain/UnidadesMedidaRepository";
import UnidadesMedidaApiRepository from "../../infraestructure/api/UnidadesMedida.ApiRepository";
import ElementoTipoApiRepository from "./infrastructure/api/ElementoTipo.Api.Repository";
import ElementoTipoRepository from "./domain/ElementoTipoRepository";
import {
  COD_DELEGACIONES_CON_TODAS,
  USER_ROLES,
} from "../../assets/constants/Constantes";

const EquiposElementosGrabacion = () => {
  const formId = "elementos-form";
  const [newElemento, setNewElemento] = useState<ElementoEquipoCreateDto>({
    delegacionId: 1,
    unidadObraElementoTipoId: 3,
    nombre: "",
    descripcion: "",
    tipoUnidad: "",
    tarifa: 0,
    unidadMedidaId: 1,
  });

  const [optionsDelegaciones, setOptionsDelegaciones] = useState<
    DelegacionDto[]
  >([]);
  const [optionsElementoTipo, setOptionsElementoTipo] = useState<
    UnidadObraElementoTipo[]
  >([]);
  const [selectedDelegacion, setSelectedDelegacion] = useState<DelegacionDto>({
    id: "1",
    descripcion: "",
  });
  const [selectedElementoTipo, setSelectedElementoTipo] =
    useState<UnidadObraElementoTipo>({} as UnidadObraElementoTipo);
  const [optionsMedidas, setOptionsMedidas] = useState<UnidadMedidaDto[]>([]);
  const { getToken, getDelegacion, hasRole } = useContext(AuthContext);
  const { goToRoute } = useContext(HistoryHandler);
  const { setLoading } = useLoading();

  const equiposRepo: EquiposRepository = new EquipoApiRepository(getToken());

  useEffect(() => {
    fetchDelegaciones();
  }, [getDelegacion]);

  useEffect(() => {
    fetchMedidas();
    fetchElementoTipos();
  }, [getToken]);

  const fetchDelegaciones = useCallback(() => {
    const repo: DelegacionesRepository = new DelegacionesApiRepository(
      getToken()
    );
    return repo
      .fetchDelegaciones({
        filter: {
          ids: hasRole(USER_ROLES.ADMIN)
            ? COD_DELEGACIONES_CON_TODAS
            : [getDelegacion()?.id],
        },
      })
      .then((resp) => {
        setOptionsDelegaciones(resp);
        handleClickDelegaciones(getDelegacion());
      });
  }, [getToken]);

  const fetchElementoTipos = useCallback(() => {
    const repo: ElementoTipoRepository = new ElementoTipoApiRepository(
      getToken()
    );
    return repo.fetchTipos().then((resp) => {
      setOptionsElementoTipo(resp.filter((x) => x.id == 3 || x.id == 5));
      setSelectedElementoTipo(resp.find((x) => x.id == 3)!);
    });
  }, [getToken]);

  const fetchMedidas = useCallback(() => {
    const repo: UnidadesMedidaRepository = new UnidadesMedidaApiRepository(
      getToken()
    );
    return repo
      .getConFiltro({})
      .then((data) => {
        setOptionsMedidas(data);
        //setNewElemento({...newElemento, unidadMedidaId:+data.find(x => x.id == "1")?.id!})
      })
      .catch((message) => toast.error(message));
  }, []);

  const handleClickDelegaciones = (delegacion: DelegacionDto) => {
    if (selectedDelegacion.id !== delegacion.id) {
      setSelectedDelegacion(delegacion);
      setNewElemento({ ...newElemento, delegacionId: +delegacion.id });
    } else {
      setSelectedDelegacion({ id: "", descripcion: "" });
    }
  };

  const handleClickTipoElemento = (tipoElemento: UnidadObraElementoTipo) => {
    if (selectedElementoTipo.id !== tipoElemento.id) {
      setSelectedElementoTipo(tipoElemento);
      setNewElemento({
        ...newElemento,
        unidadObraElementoTipoId: tipoElemento.id,
      });
    } else {
      setSelectedElementoTipo({} as UnidadObraElementoTipo);
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (newElemento.tarifa <= 0) {
      toast.error("La tarifa introducida no puede ser menor o igual a 0");
      return;
    }
    saveEquipoElemento(newElemento);
    setNewElemento({
      delegacionId: 1,
      unidadObraElementoTipoId: 3,
      nombre: "",
      descripcion: "",
      tipoUnidad: "",
      tarifa: 0,
      unidadMedidaId: 1,
    });
    // history.goBack();
  };

  const handleNewElemento = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewElemento((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectMedida = (unidadMedida: UnidadMedidaDto) => {
    const medidaObj: UnidadMedidaDto | undefined = optionsMedidas.find(
      (item: UnidadMedidaDto) => item.id === unidadMedida.id
    );
    setNewElemento((prev) => ({
      ...prev,
      unidadMedidaId: medidaObj ? +medidaObj.id : 1,
    }));
  };

  const saveEquipoElemento = useCallback((data: ElementoEquipoCreateDto) => {
    setLoading(true);
    equiposRepo
      .addElemento(data)
      .then((res) => {
        goToRoute(Routes.ELEMENTOS_EDICION, { id: res?.id ?? "" });
        toast.success("Equipo añadido con éxito");
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="add-edit-view-container">
      <div className="add-edit-header">
        <h4>Nuevo Elemento de Equipos</h4>
      </div>
      <div className="add-edit-body">
        <FormTemplate formId={formId} onSubmit={handleSubmit} backUrl={false}>
          <div className="row-of-two">
            <CustomTextInput
              label="Nombre"
              name="nombre"
              value={newElemento.nombre}
              onChange={handleNewElemento}
              required
            />

            <FormField<DropdownProps>
              label="Delegación"
              options={optionsDelegaciones.map((element) => {
                return {
                  text: element.descripcion,
                  id: element.id,
                  selected: selectedDelegacion.id == element.id ? true : false,
                  onClick: () => {
                    handleClickDelegaciones(element);
                  },
                };
              })}
              required={true}
              disabled={false}
              singleSelection={true}
              component={Dropdown}
            />

            <CustomTextInput
              label="Descripción"
              name="descripcion"
              value={newElemento.descripcion}
              onChange={handleNewElemento}
              required
            />

            <FormField<DropdownProps>
              label="Tipo"
              options={optionsElementoTipo.map((element) => {
                return {
                  text: element.descripcion,
                  id: element.id.toString(),
                  selected:
                    selectedElementoTipo.id == element.id ? true : false,
                  onClick: () => {
                    handleClickTipoElemento(element);
                  },
                };
              })}
              required={true}
              disabled={false}
              singleSelection={true}
              component={Dropdown}
            />

            <CustomTextInput
              label="Tarifa"
              name="tarifa"
              value={newElemento.tarifa.toString()}
              onKeyDown={(e) => {
                if (e.key.toUpperCase() === "E" || e.key === "-") {
                  e.preventDefault();
                }
              }}
              onChange={handleNewElemento}
              required
              disabled={Number(newElemento.unidadObraElementoTipoId) === 4}
            />

            {optionsMedidas && (
              <FormField<DropdownProps>
                label="Unidad Medida"
                options={optionsMedidas.map((element) => {
                  return {
                    text: element.descripcion,
                    id: element.id,
                    selected:
                      newElemento.unidadMedidaId == +element.id ? true : false,
                    onClick: () => {
                      handleSelectMedida(element);
                    },
                  };
                })}
                required={true}
                disabled={false}
                singleSelection={true}
                component={Dropdown}
              />
            )}
          </div>
        </FormTemplate>
      </div>
    </div>
  );
};

export default EquiposElementosGrabacion;
