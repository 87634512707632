import { BaseRepository } from "@pavabits/components";
import CodigoPostal, { CodigoPostalDetalle, CodigoPostalDetalleDto, CodigoPostalDto } from "../../domain/model/CodigoPostal";
import CodigoPostalRepository from "../../domain/CodigoPostalRepository";
import CodigoPostalRequestListByFilter from "../ws/CodigoPostal.RequestListByFilter";
import CodigoPostalRequestById from "../ws/CodigoPostal.RequestById";

export default class CodigoPostalApiRepository extends BaseRepository<
	CodigoPostalDto[],
	CodigoPostal[]
> implements CodigoPostalRepository {

	fetchCodigoPostal(data: any): Promise<CodigoPostal[]> {
		return new Promise((resolve, reject) => {
			return new CodigoPostalRequestListByFilter(data, this.authToken)
				.request()
				.then((response) => {
					if (response.success) {
						resolve(this.transformDtoIntoModel(response.data.items));
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error) => {
					reject(error);
				})
		})
	}

	getCodigoPostalById(id: string): Promise<CodigoPostalDetalle> {
		return new Promise((resolve, reject) => {
			return new CodigoPostalRequestById(id, this.authToken)
				.request()
				.then((response) => {
					if (response.success) {
						resolve(this.transformDetalleDtoIntoModel(response.data));
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error) => {
					reject(error);
				})
		})
	}

	protected transformDtoIntoModel(dto: CodigoPostalDto[]): CodigoPostal[] {
		if (dto && dto.length) {
			return dto.map(item => {
				return new CodigoPostal(
					item.id,
					item.descripcion
				)
			})
		}
		return []
	}

	protected transformDetalleDtoIntoModel(dto: CodigoPostalDetalleDto): CodigoPostalDetalle {
				return new CodigoPostalDetalle(dto);
	}

}