import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { TipoOfertasEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import TipoOferta from "../../domain/model/TipoOferta";

export default class TipoOfertaGetAll extends ApiRequest<undefined, BaseResponse<TipoOferta[]>>
{
	constructor(token: string) {
		super(
			"GET",
			TipoOfertasEndpoints.BASE_ENPOINT,
			{ Authorization: token },
			undefined,
			{ }
		)
	}
}