import React from "react";
import { BaseFilter, SortingField } from "../../../domain/BaseFilter";
import BaseResponse from "../../../domain/BaseResponse";
import { ColumnTraits } from "../../../hooks/useColumnasGrid";
import { FilterTag } from "../../Oportunidades/context/FilterFields.Context";
import { FilterHandler, SearchFilter, SearchFilterHandler, SortingHandler } from "../../Oportunidades/hooks/useFilters/FilterTypes";
import Ofertas, { OfertasFilter } from "../domain/model/Ofertas";

interface TableData {
	columns: { [key: string]: ColumnTraits },
	data: BaseResponse<Ofertas[]>,
	downloadExcel?:()=>void
	sumaToneladas: number
	//misDatos?: boolean
}

const defaultData = {} as TableData;
export const OfertasTableData = React.createContext<TableData>(defaultData);

interface FilterState {
	filter: BaseFilter<OfertasFilter>
}

const defaultFilter = {} as FilterState;
export const OfertasFilterState = React.createContext<FilterState>(defaultFilter);

interface SearchHandlerContext {
	onSearchField: SearchFilterHandler<OfertasFilter>;
}
const searchHandler: SearchHandlerContext = {} as SearchHandlerContext;

export const OfertasSearchHandler =	React.createContext<SearchHandlerContext>(searchHandler);

type FiltersHandlers = FilterHandler<OfertasFilter>;
const defaultFilterHandlers = {} as FiltersHandlers;
export const OfertasFilterContext = React.createContext<FiltersHandlers>(defaultFilterHandlers);

interface Tags {
	tags: FilterTag[]
}

export const OfertasTags = React.createContext<Tags>({ tags: [] });

interface TagsHandler {
	onFilter: (tag: FilterTag) => void
}

export const OfertasTagHandler = React.createContext<TagsHandler>({} as TagsHandler);

interface DescripcionSearch extends SearchFilter { };

const defaultDescripcion = {} as DescripcionSearch
export const OfertasDescripcionSearch = React.createContext<DescripcionSearch>(defaultDescripcion);


interface SortingAndPagination extends SortingHandler<OfertasFilter> {
	maxResultCount: number
	sorting: SortingField[]
}

const defaultPagination = {} as SortingAndPagination;
export const OfertasPagination = React.createContext<SortingAndPagination>(defaultPagination);
