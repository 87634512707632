import { ContactoClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ApiRequest from "../../../../domain/ApiRequest";
import ContactoCliente, { ContactoClienteUpdateDto } from "../../domain/model/ContactosCliente";

export default class ContactoClienteRequestUpdate extends ApiRequest<
ContactoClienteUpdateDto,
ContactoCliente
>
{
	constructor(data: ContactoClienteUpdateDto, token: string) {
		super(
			"PUT",
			ContactoClienteEndpoints.UPDATE,
			{ Authorization: `${token}`},
			data,
		)
	}
}