import BaseResponse from "../../../../domain/BaseResponse";
import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";

import FormaPago, { FormaPagoDto, FormaPagoReadingDto, FormaPagoUpdateDto } from "../../domain/model/FormaPago";
import FormaPagoRepository from "../../domain/FormaPagoRepository";
import FormaPagoRequestAdd from "../ws/FormaPago.Request.Add";
import FormaPagoRequestUpdate from "../ws/FormaPago.Request.Update";
import FormaPagoRequestDelete from "../ws/FormaPago.Request.Delete";
import FormaPagoRequestGetByClienteId from "../ws/FormaPago.Request.GetByClienteId";

export default class FormaPagoApiRepository
	extends ApiRepositoryBase<
		FormaPagoDto,
		FormaPago
	> implements  FormaPagoRepository {

	
	async add(data:  FormaPago): Promise<FormaPagoReadingDto> {
		const res= await new  FormaPagoRequestAdd(data, this.authToken).executeAsPromise();
		return res;
	}

	async getByClienteId(id: number): Promise<FormaPago[]> {
		const res= await new FormaPagoRequestGetByClienteId(id, this.authToken).executeAsPromise();
		return FormaPago.toArray(res?.items);
	}


	async update(data:  FormaPagoUpdateDto): Promise<FormaPagoReadingDto> {
		return await new FormaPagoRequestUpdate(data, this.authToken).executeAsPromise();
	}

	async delete(id: string): Promise<boolean> {
		await new  FormaPagoRequestDelete(id, this.authToken).executeAsPromise();
		return true;
	}

	buildModelFromItems(item:  FormaPagoDto):  FormaPago {
		return new  FormaPago(item);
	}

	protected transformDtoIntoModel(dto: BaseResponse< FormaPagoDto>): BaseResponse<FormaPago> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}
}