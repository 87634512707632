import React, { FormEvent, useReducer } from "react";
import { valueof } from "../utils/Utilities";

export type FormErrors<T> = { [key in keyof Partial<T>]: string | undefined };

interface UseFormReturn<T> {
  values: T;
  errors: FormErrors<T>;
  onField: (key: keyof T) => {
  onSelectValue: (value: any) => void;
    onChangeValue: (value: any) => void;
    onFocus: () => void;
    onBlur: () => void;
    value: any;
    error: string | undefined;
  };
  valid: boolean;
  onSubmitForm: (event: FormEvent<HTMLFormElement>) => void;
  reset: () => void;
}

interface ConfigForm<T> {
  initialValues?: Partial<T>;
  validate?: ValidateFunc<T>;
  handleOnSubmit: (valid: boolean, values: T, errors: FormErrors<T>) => void;
}

type Reducer<S, A> = (prevState: S, action: A) => S;

type TouchedKeys = string[];

type StateReducer<T> = {
  values: { [key in keyof T]: T[key] };
  touchedKeys: TouchedKeys;
  submit: boolean;
};

type ActionReducer<T> = {
  type: ActionType;
  key?: keyof T;
  payload: any;
};

enum ActionType {
  UPDATE_VALUE,
  TOUCH,
  SUBMIT,
  RESET,
}

function reducer<T>(
  state: StateReducer<T>,
  action: ActionReducer<T>
): StateReducer<T> {
  switch (action.type) {
    case ActionType.UPDATE_VALUE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.key!]: action.payload,
        },
      };
    case ActionType.TOUCH:
      if (action.payload) {
        const { touchedKeys } = state;
        touchedKeys.push(action.key!.toString());
        return {
          ...state,
          //@ts-ignore
          touchedKeys: [...new Set(touchedKeys)],
        };
      }
      return {
        ...state,
        touchedKeys: state.touchedKeys.filter(
          (key) => action.key!.toString() === key
        ),
      };

    case ActionType.SUBMIT:
      return {
        ...state,
        submit: action.payload,
      };
    case ActionType.RESET:
      return {
        ...state,
        touchedKeys: [],
        submit: false,
      };
    default:
      return state;
  }
}

type ValidateFunc<T> = (data: T) => FormErrors<T>;

function validateForm<T>(
  state: StateReducer<T>,
  validate: ValidateFunc<T>
): FormErrors<T> {
  return validate(state.values);
}

export default function useForm<T>({
  initialValues = {},
  validate,
  handleOnSubmit,
}: ConfigForm<T>): UseFormReturn<T> {

  const [state, dispatch] = useReducer<
    Reducer<StateReducer<T>, ActionReducer<T>>
  >(reducer, {
    values: initialValues as T,
    touchedKeys: [],
    submit: false,
  });

  const onChange = (key: keyof T) => {
    return (value: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: ActionType.UPDATE_VALUE,
        key,
        payload: value.target.value,
      });
    };
  };

  const onSelect = (key: keyof T) => {
    return (value: any) => {
      dispatch({
        type: ActionType.UPDATE_VALUE,
        key,
        payload: value,
      });
    };
  };

  const onFocus = (key: keyof T) => {
    return () => {};
  };

  const onBlur = (key: keyof T) => {
    return () => {
      dispatch({
        type: ActionType.TOUCH,
        key,
        payload: true,
      });
    };
  };

  let errors = {} as FormErrors<T>;
  if (validate) {
    errors = validateForm(state, validate);
  }

  const valid = Object.keys(errors).length === 0;

  const onField = (key: keyof T) => {

    return {
      onSelectValue: onSelect(key),
      onChangeValue: onChange(key),
      onFocus: onFocus(key),
      onBlur: onBlur(key),
      value: valueof(state.values, key),
      error:
        state.submit || state.touchedKeys.includes(key.toString())
          ? errors[key]
          : undefined,
    };
  };

  const onSubmitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch({
      type: ActionType.SUBMIT,
      payload: true,
    });
    handleOnSubmit(valid, state.values, errors);
  };

  const reset = () => {
    dispatch({
      type: ActionType.RESET,
      payload: true,
    });
  };

  return {
    onField,
    values: state.values,
    errors,
    valid,
    onSubmitForm,
    reset,
  };
}
