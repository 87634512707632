import TablePrintableObject from "../../../../components/Table/types/TablePrintableObject";
import { SelectableItemDto } from "../../../../components/ui/atoms/SuggestionInput/model/SuggestionInput.Model";
export interface UnidadObraElementoTipo {
	descripcion: string,
	editable: boolean,
	visible: boolean,
	id: number
}
interface ElementoEquipoEntity {
	id: string,
	delegacionId: number,
	unidadObraElementoTipoId: number, 
	nombre: string,
	descripcion: string,
	tarifa: string,
	tipoUnidad?: string,
	rendimiento?: number,
	editable: boolean,
	delegacionNombre: string,
	tipoDescripcion: string,
    unidadMedidaId?: number;
}

export default class ElementoEquipo extends TablePrintableObject {
    id: string;
	delegacionId: number;
	unidadObraElementoTipoId: number; 
	nombre: string;
	descripcion: string;
	tarifa: string;
	tipoUnidad?: string;
	rendimiento?: number;
	delegacionNombre: string;
	editable: boolean;
	tipoDescripcion: string;
	unidadMedidaId?: number;
	controllerName: string;

    constructor (entity: ElementoEquipoEntity) {
        super(entity.id, entity.descripcion);
        this.id = entity.id;
        this.delegacionId = entity.delegacionId;
        this.unidadObraElementoTipoId = entity.unidadObraElementoTipoId;
	    this.nombre = entity.nombre;
	    this.descripcion = entity.descripcion;
	    this.tarifa = entity.tarifa;
		this.tipoUnidad = entity.tipoUnidad;
	    this.rendimiento = entity.rendimiento;
		this.delegacionNombre = entity.delegacionNombre;
		this.editable = entity.editable;
		this.tipoDescripcion = entity.tipoDescripcion;
		this.unidadMedidaId = entity.unidadMedidaId;
		this.controllerName="/mantenimiento/elementos/edicion/";
    }

    public static toArray(entities: ElementoEquipoEntity[]): ElementoEquipo[] {
		return entities ? entities.map(entity => { return new ElementoEquipo(entity) }) : [];
	}

	override toMap = () => {
		return new Map<keyof ElementoEquipo, string>(
			[
			]
		);
	};
}

export interface ElementoEquipoDto extends SelectableItemDto { 
	elementoId: string;
	unidadTipoDescripcion?: string;
	unidadMedidaDescripcion?: string;
	text: string;
	tarifa: number;
	rendimiento: string; 
	tipoUnidad?: string;
	tipoDescripcion?: string;
};

export interface ElementoEquipoCreateDto {
    delegacionId: number,
	unidadObraElementoTipoId: number, 
	nombre: string,
	descripcion: string,
	tarifa: number,
	tipoUnidad?: string;
	unidadMedidaId?: number; 
}

export interface ElementoEquipoReadDto extends ElementoEquipo {
    errors?: string[]
}

export interface ElementoEquipoUpdateDto {
	id: string
    delegacionId: number
    unidadObraElementoTipoId: number
    nombre: string
    descripcion: string
    tarifa: string
    errors?: string[]
	tipoUnidad?: string
	unidadMedidaId?: number;
}