import { FC, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Constantes from "../../../../assets/constants/Constantes";
import SelectableItem from "../../../../components/ui/atoms/SuggestionInput/model/SuggestionInput.Model";
import { HistoryHandler } from "../../../../context/History.Context";
import { DirtyForm } from "../../../../domain/model/DirtyForm";
import { Routes } from "../../../../router/routes/Routes";
import { CustomButton } from "../../../../shared/components/Buttons/CustomButton";
import DropdownOptions, { DropdownOption } from "../../../../shared/components/Dropdowns/dropdown-options/DropdownOptions";
import CustomInput from "../../../../shared/components/Inputs/CustomInput/CustomInput";
import { fechaValida, formatDate2String, formatDDMMYYYY, formatUsDate2String, formatYYYYMMDD } from "../../../../utils/DateUtil";
import { AuthContext } from "../../../Login/AuthContextProvider";
import EstadoOfertaRepository from "../../domain/EstadoOfertaRepository";
import ContactosRepository from "../../domain/ContactosRepository";
import SeguimientoClienteRepository from "../../domain/SeguimientoClienteRepository";
import { ClienteDto } from "../../domain/model/Cliente";
import ContactoCliente from "../../domain/model/ContactosCliente";
import EstadoOferta from "../../domain/model/EstadoOferta";
import SeguimientoCliente from "../../domain/model/SeguimientoCliente";
import TipoSeguimientoCliente from "../../domain/model/TipoSeguimientoCliente";
import ContactoClienteApiRepository from "../../infraestructure/api/ContactosClientes.ApiRepository";
import EstadoOfertaApiRepository from "../../infraestructure/api/EstadoOferta.ApiRepository";
import SeguimientoClienteApiRepository from "../../infraestructure/api/SeguimientoCliente.ApiRepository.";
import TipoSeguimientoClienteApiRepository from "../../infraestructure/api/TipoSeguimientoCliente.ApiRepository.";
import TipoSeguimientoClienteRepository from "../../domain/TipoSeguimientoClienteRepository";
import CustomCheckInput from "../../../../components/common/CustomCheckInput";
import DeleteModal from "../../../../shared/components/DeleteModal/DeleteModal";

interface Props extends DirtyForm {
    type: "edit" | "add"
    title: string
    handleOnSubmit: () => void
    cliente: ClienteDto
    seguimientoClientes?: SeguimientoCliente[],
    idPadre: number,
}

const SeguimientoClientesView: FC<Props> = ({ type,
    handleOnSubmit,
    cliente,
    idPadre,
    title,
    hadleDirtyForm

}) => {


    const { goToRoute } = useContext(HistoryHandler);
    //modal open
    //session
    const { getToken, username } = useContext(AuthContext);

    const [seguimientoClientes, setSeguimientoClientes] = useState<SeguimientoCliente[]>();
    const [openDelete, setOpenDelete] = useState<boolean>(false);

    const fetchSeguimientoClientes = useCallback(
        (id: number) => {
            const repo: SeguimientoClienteRepository = new SeguimientoClienteApiRepository(getToken());
            return repo.getByClienteId(id).then((a) => {
                const auxFechas = a.map((seguimiento) => {
                    return { ...seguimiento, fechaSeguimiento: formatDate2String(formatDDMMYYYY(seguimiento.fechaSeguimiento.split("T")[0])) }
                });
                setSeguimientoClientes(auxFechas);
                setSeguimientoClientesForm(auxFechas);
                setSeguimientoClientesIni(auxFechas);
            });
        }
        , [getToken]);

    useEffect(() => { // Side Effect
        if (idPadre != 0) {
            fetchSeguimientoClientes(cliente?.id !== undefined ? +cliente?.id : 0);
        }
    }
        , [fetchSeguimientoClientes]);

    //delete
    const [deletionIndex, setDeletionIndex] = useState<number>(-1);
    const openConfirmationModal = (index: number) => {
        setDeletionIndex(index);
        setOpenDelete(true);
    }

    const handleCloseModalDelete = (remove: boolean) => {
        setOpenDelete(false);
        if (remove) {
            handleRemoveSeguimientoCliente(deletionIndex!);
        }
    }

    const handleRemoveSeguimientoCliente = (index: number) => {
        if (!seguimientoClientesForm[index].id) {
            seguimientoClientesForm.splice(index, 1);
            toast.success(`Eliminado correctamente;`);
            setUserAddingContact(false);
            setOpenDelete(false);
            return;
        }

        const repo: SeguimientoClienteRepository = new SeguimientoClienteApiRepository(getToken());
        repo.delete(seguimientoClientesForm[index].id)
            .then((resp) => {
                toast.success(`Eliminado correctamente;`);
                const aux = seguimientoClientesForm.filter(item => item.id !== seguimientoClientesForm[index].id);
                setSeguimientoClientesForm(aux);
                setSeguimientoClientesIni(aux);
            }).catch((ex) => {
                toast.success(`Error: ${ex}`);
            });
        setOpenDelete(false);
    }

    const initOptionsDelegaciones: DropdownOption[] = [];
    const [optionsTipoSeguimientoCliente, setOptionsTipoSeguimientoCliente] = useState<TipoSeguimientoCliente[]>([]);
    const [optionsEstadoOferta, setOptionsEstadoOferta] = useState<DropdownOption[]>(initOptionsDelegaciones);

    const [contactosClientes, setContactosClientes] = useState<ContactoCliente[]>();
    const [tipoSeguimientoClienteSelected/*, setTipoSeguimientoClienteSelected*/] = useState<TipoSeguimientoCliente>({ id: '0', descripcion: "Seleccionar" } as SelectableItem);
    const [estadoOfertaSelected/*, setEstadoOfertaSelected*/] = useState<EstadoOferta>({ id: '0', descripcion: "Seleccionar" } as SelectableItem);
    const [userAddingContact, setUserAddingContact] = useState<boolean>(false);


    const [seguimientoClientesForm, setSeguimientoClientesForm] = useState<SeguimientoCliente[]>([]);
    const [seguimientoClientesIni, setSeguimientoClientesIni] = useState<SeguimientoCliente[]>([]);
    const [errores, setErrores] = useState<string[]>([]);
    const [lineSelected, setLineSelected] = useState<number>();

    const seguimientoClienteValidation = (index: number): boolean => {
        const errores: string[] = [];

        const contacto = seguimientoClientesForm[index];

        if (contacto.tipoSeguimientoClienteId < 0) errores[0] = 'El tipo tiene que estar seleccionado';

        if (!fechaValida(contacto.fechaSeguimiento)) errores[1] = 'La fecha no es valida';

        if (contacto.observaciones && contacto.observaciones?.length > Constantes.OBSERVACIONES_MAX_LENGTH) {
            errores[2] = 'Máximo 200 caracteres.';
        }

        setErrores(errores);

        return errores.length === 0;
    }

    //save
    const onSubmitContactForm = (i: number) => {
        const repo: SeguimientoClienteRepository = new SeguimientoClienteApiRepository(getToken());
        let seguimiento = i > -1 ? seguimientoClientesForm[i] : undefined;

        if (seguimiento !== undefined) {
            setLineSelected(i);
            if (seguimientoClienteValidation(i)) {
                //update
                if (!!seguimiento?.id) {
                    repo.update({...seguimiento, fechaSeguimiento: formatYYYYMMDD(seguimiento.fechaSeguimiento)} as SeguimientoCliente)
                        .then((response) => {
                            const contactoUpdated = seguimientoClientesForm.map((seguimiento: SeguimientoCliente, j: number) => {
                                if (i === j) return { ...seguimiento, lastModificationTime: response.lastModificationTime };

                                return seguimiento;
                            });
                            setSeguimientoClientesForm(contactoUpdated);
                            setSeguimientoClientesIni(contactoUpdated);
                            toast.success(`Seguimiento guardado con éxito`);
                        })
                        .catch(() => {
                            toast.error(`Error al guardar el seguimiento`);
                        });
                }
                else //add
                {
                    repo.add({...seguimiento, fechaSeguimiento: formatYYYYMMDD(seguimiento.fechaSeguimiento)}  as SeguimientoCliente)
                        .then((response) => {
                            toast.success(`Seguimiento añadido con éxito`);

                            //recogemos el nuevo id, ya no es nuevo, es editable!!!
                            const personaCont = seguimientoClientesForm.map((seguimiento: SeguimientoCliente, j: number) => {
                                if (i === j) return { ...seguimiento, id: response.id, lastModificationTime: response.lastModificationTime };

                                return seguimiento;
                            });
                            setSeguimientoClientesForm(personaCont);
                            setSeguimientoClientesIni(personaCont);

                            //ya hemos guardado, dejamos que se añadan nuevos
                            setUserAddingContact(false);
                        })
                        .catch(() => {
                            toast.error(`Error al añadir el seguimiento`);
                        });
                }
            }
        }
    }

    const handleAddContacto = () => {
        setUserAddingContact(true);
        let newSeg = {
            id: '',
            fechaSeguimiento: formatDate2String(formatDDMMYYYY((new Date()))),
            //observacionesTipoSeguimiento: '',
            observaciones: '',
            tipoSeguimientoClienteId: 1,
            estadoOfertaId: 1,
            clienteId: idPadre,
            lastModificationTime: '',
            contactoId: '',
            ofertaId: '',
            seguimientoOfertaId: '',
            clienteNombre: ""
        }
        setSeguimientoClientesForm([newSeg, ...seguimientoClientesForm])
    }
    const { t } = useTranslation<['main']>(['main']);

    const fetchTipoSeguimiento = useCallback(
        () => {
            const repo: TipoSeguimientoClienteRepository = new TipoSeguimientoClienteApiRepository(getToken());
            return repo.getAll().then((resp: TipoSeguimientoCliente[]) => {
                setOptionsTipoSeguimientoCliente(resp);
            })
        }
        , [getToken, tipoSeguimientoClienteSelected]);

    const fetchEstadoOferta = useCallback(
        () => {
            const repo: EstadoOfertaRepository = new EstadoOfertaApiRepository(getToken());
            return repo.getAll().then((a) => {
                let array: any = a.map(tipo => {
                    return {
                        id: tipo.id,
                        text: `${tipo.descripcion}`,
                        icon: 'done',
                        selected: estadoOfertaSelected.id === tipo.id
                    };
                })
                setOptionsEstadoOferta(array);
            })
        }
        , [getToken, estadoOfertaSelected]);


    const fetchContactosClientes = useCallback(
        (id: number) => {
            const repo: ContactosRepository = new ContactoClienteApiRepository(getToken());
            return repo.getByClienteId(id).then((a) => {
                setContactosClientes(a);
            });
        }
        , [getToken]);


    useEffect(() => {
        fetchContactosClientes(+cliente.id)
        fetchTipoSeguimiento();
        fetchEstadoOferta();
    }
        , [fetchTipoSeguimiento, fetchEstadoOferta]);


    const handleTipoSeguimientoChange = (delegacionSelected: TipoSeguimientoCliente, index: any) => {

        const personaCont = seguimientoClientesForm.map((contacto: SeguimientoCliente, j: number) => {
            if (index === j) return { ...contacto, tipoSeguimientoClienteId: + delegacionSelected.id };

            return contacto;
        });
        setSeguimientoClientesForm(personaCont);
    }

    const handleContactoChange = (contactoSelectedId: string, index: any) => {

        const personaCont = seguimientoClientesForm.map((contacto: SeguimientoCliente, j: number) => {
            if (index === j) return { ...contacto, contactoId: contactoSelectedId };

            return contacto;
        });
        setSeguimientoClientesForm(personaCont);
    }

    const handleEstadoOfertaChange = (estadoOfertaSelectedId: number, index: any) => {

        const personaCont = seguimientoClientesForm.map((contacto: SeguimientoCliente, j: number) => {
            if (index === j) return { ...contacto, estadoOfertaId: estadoOfertaSelectedId };

            return contacto;
        });
        setSeguimientoClientesForm(personaCont);
    }

    const handlePropertyChange = (property: string, newValue: string | number, i: number) => {
        const personaCont = seguimientoClientesForm.map((contacto: any, j: number) => {
            if (i === j) return { ...contacto, [property]: newValue, Usuario: username };

            return contacto;
        });
        setSeguimientoClientesForm(personaCont);
    }

    const handleFechaChange = (newValue: string, i: number) => {
        const personaCont = seguimientoClientesForm.map((contacto: any, j: number) => {
            if (i === j) return { ...contacto, fechaSeguimiento: newValue };

            return contacto;
        });
        setSeguimientoClientesForm(personaCont);
    }

    const isDirty = (seguimientoCliente: SeguimientoCliente): boolean => {
        if (seguimientoCliente.id == undefined || seguimientoCliente.id == '') {
            hadleDirtyForm(true);
            return true;
        }
        else {
            let seguimientoClienteEncontrado = seguimientoClientesIni?.filter(p => p.id === seguimientoCliente.id);
            const arrayProperties = ["fechaSeguimiento", "observaciones", "tipoSeguimientoClienteId", "estadoOfertaId", "contactoId", "seguimientoOfertaId"];
            if (seguimientoClienteEncontrado && seguimientoClienteEncontrado.length > 0 && JSON.stringify(seguimientoClienteEncontrado[0], arrayProperties) == JSON.stringify(seguimientoCliente, arrayProperties)) {
                hadleDirtyForm(false);
                return false;
            }
            else {
                hadleDirtyForm(true);
                return true;
            }
        }
    }

    return (
        <>
            <div className="inline-grid" >
                <div className="inline-grid-header">
                    <label>Tipo</label>
                    <label>Contacto</label>
                    <label>Fecha</label>
                    {/* <label>Observaciones Seguimiento</label> */}
                    <label>Observaciones</label>
                    <label>Oferta Asociada</label>
                    <label>Estado Oferta</label>
                    {/* <label>Calendar</label> */}

                    <div className="inline-button" >
                        {!userAddingContact &&
                            <CustomButton
                                id={"add-btn"}
                                icon="add"
                                title="Añadir Seguimiento Cliente"
                                className={"contact-btn"}
                                onButtonClick={() => { handleAddContacto() }}
                            />}
                    </div>
                </div>

                <div className="inline-grid-container">
                    {seguimientoClientesForm?.map((seguimientoCliente: SeguimientoCliente, i: number) => {

                        // let down = (seguimientoClientesForm.length - i) > (seguimientoClientesForm.length / 2)
                        return (
                            <div className="inline-grid-item">
                                {//tipo seguimiento
                                    optionsTipoSeguimientoCliente.length !== 0 &&
                                    <>
                                        <DropdownOptions
                                            className={'contact-dropdown'}
                                            contentClassName={'contact-dropdown-content'}
                                            itemsClassName={'dropdown-item'}
                                            idTest="SeguimientoGridTipo"
                                            options={optionsTipoSeguimientoCliente.filter(x => x.visible).map(option => {
                                                return {
                                                    id: option.id,
                                                    text: option.descripcion,
                                                    icon: 'done',
                                                    selected: seguimientoCliente.tipoSeguimientoClienteId === +option.id,
                                                    Usuario: username,
                                                    onClick: () => handleTipoSeguimientoChange(option, i)
                                                }
                                            })}
                                        >
                                            {optionsTipoSeguimientoCliente.find(item => +item.id === seguimientoCliente.tipoSeguimientoClienteId)?.descripcion ?? 'Seleccionar'}
                                        </DropdownOptions>
                                        {/* <label className={"delegacion-label-error"}>{lineSelected === i ? errores[0] : ""}</label> */}

                                    </>
                                }

                                {//contactosCliente
                                    contactosClientes && contactosClientes.length !== 0 ?

                                        <DropdownOptions
                                            className={'contact-dropdown'}
                                            contentClassName={'contact-dropdown-content'}
                                            itemsClassName={'dropdown-item'}
                                            idTest="SeguimientoGridContactoDropdown"
                                            options={contactosClientes.map(option => {
                                                return {
                                                    ...option,
                                                    selected: seguimientoCliente.tipoSeguimientoClienteId.toString() === option.id,
                                                    Usuario: username,
                                                    text: option.personaContacto,
                                                    icon: '',
                                                    onClick: () => handleContactoChange(option.id, i)
                                                }
                                            })}
                                        >
                                            {contactosClientes.find(item => item.id === seguimientoCliente.contactoId)?.personaContacto ?? 'Seleccionar'}
                                        </DropdownOptions>


                                        :
                                        <CustomInput
                                            key={`$contacto-${i}`}
                                            value={'Sin Contactos'}
                                            disabled={true}
                                            className={'platform-input'}
                                            formClassName="secondary-input"
                                            onChange={(e) => { }}
                                            type={"text"}
                                        />
                                }

                                <CustomInput
                                    key={`$fechaSeguimiento-${i}`}
                                    value={seguimientoCliente.fechaSeguimiento}
                                    className={'platform-input secondary-input'}
                                    onChange={(e) => { handleFechaChange(e, i); }}
                                    error={lineSelected === i ? errores[1] : ""}
                                    formClassName={"secondary-input"}
                                    type={'date'}
                                    idTest="SeguimientoGridFecha"
                                />
                                {/* <CustomInput
                                        key={`$observacionesTipoSeguimiento-${i}`}
                                        value={contacto.observacionesTipoSeguimiento}
                                        className={'platform-input secondary-input'}
                                        formClassName={"secondary-input"}
                                        disabled={contacto.tipoSeguimientoClienteId !== 5}
                                        onChange={(e) => { handlePropertyChange('observacionesTipoSeguimiento', e, i); }}
                                        error={lineSelected === i ? errores[] : ""}
                                    /> */
                                }
                                <CustomInput
                                    key={`$observaciones-${i}`}
                                    value={seguimientoCliente.observaciones}
                                    idTest="SeguimientoGridObservaciones"
                                    className={'platform-input secondary-input'}
                                    formClassName={"secondary-input"}
                                    onChange={(e) => { handlePropertyChange('observaciones', e, i); }}
                                    error={lineSelected === i ? errores[2] : ""}
                                />
                                {
                                    seguimientoCliente.ofertaId !== undefined && seguimientoCliente.ofertaId !== null && seguimientoCliente.ofertaId !== '' ?
                                        <a className="link" href={Routes.OFERTAS_EDICION.replace(':id', seguimientoCliente.ofertaId)}>{seguimientoCliente.ofertaCodigo}</a> :
                                        <label>No Oferta Asociada</label>
                                }
                                {//estados ofertas
                                    optionsEstadoOferta && (seguimientoCliente.ofertaId !== undefined && seguimientoCliente.ofertaId !== null && seguimientoCliente.ofertaId !== '') ?
                                        <div className="dropdown-cell">
                                            <DropdownOptions
                                                className={'contact-dropdown'}
                                                contentClassName={'contact-dropdown-content'}
                                                itemsClassName={'dropdown-item'}
                                                options={optionsEstadoOferta.map(option => {
                                                    return {
                                                        ...option,
                                                        selected: seguimientoCliente.estadoOfertaId.toString() === option.id.toString(),
                                                        Usuario: username,
                                                        text: option.text,
                                                        icon: '',
                                                        onClick: () => handleEstadoOfertaChange(option.id, i)
                                                    }
                                                })}
                                            >
                                                {optionsEstadoOferta.find(item => item.id === seguimientoCliente.estadoOfertaId)?.text ?? 'Seleccionar'}
                                            </DropdownOptions>

                                        </div>
                                        :
                                        <label>No Aplica</label>
                                }

                                {/* <div className='input-container'>
                                        <div className="inline-grid-checkbox">
                                            <CustomCheckInput
                                                checked={seguimientoCliente.eventoCalendar}
                                                icon={contacto[element.id!] ? "check" : undefined}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePropertyChange(element.id!, e.target.checked, i)}
                                                disabled={element.disabled}
                                            />
                                        </div>
                                    </div>  */}
                                <div className="inline-buttons">

                                    <CustomButton
                                        key={`confirm-${i}`}
                                        title={'GuardarGrid'}
                                        icon={isDirty(seguimientoCliente) ? 'save' : 'done'}
                                        // className={'btn-primary'}
                                        className={'contact-btn'}
                                        onButtonClick={() => { onSubmitContactForm(i) }}
                                        type='button'
                                    />
                                    {seguimientoCliente.ofertaId !== undefined && seguimientoCliente.ofertaId !== null && seguimientoCliente.ofertaId !== '' ?
                                        <></>
                                        :
                                        <CustomButton
                                            key={`remove-${i}`}
                                            title={'BorrarGrid'}
                                            icon={'delete'}
                                            // className={'btn-primary'}
                                            className={'contact-btn'}
                                            onButtonClick={() => { openConfirmationModal(i) }}
                                            type='button'
                                        />
                                    }
                                </div>
                            </div>
                        )
                    }
                    )}
                </div>

                {
                    openDelete &&
                    <DeleteModal
                        open={openDelete}
                        handleCloseModal={handleCloseModalDelete}
                        field="seguimiento del cliente"
                    >
                    </DeleteModal>
                }
            </div>
        </>
    )
}

export default SeguimientoClientesView;