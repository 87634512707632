import Clausula from "../../../Clausulas/domain/model/Clausula";

interface PresupuestoClausulaEntity {
    id?: string | undefined;
    presupuestoId?: string | null | undefined;
    clausulaId: string
    nombre: string;
    descripcion: string;
    delegacionId?: number | null | undefined;
    delegacionNombre: string | null | undefined;
    orden?: number | null | undefined;
    negrita?: boolean | null | undefined;
}

export default class PresupuestoClausula implements PresupuestoClausulaEntity {
    id: string;
    presupuestoId: string | null | undefined;
    clausulaId: string
    nombre: string;
    descripcion: string;
    delegacionId: number | null | undefined;
    delegacionNombre: string | null | undefined;
    orden?: number | null | undefined;
    negrita?: boolean | null | undefined;

    constructor(entity: PresupuestoClausulaEntity) {
        this.id = entity.id!;
        this.presupuestoId = entity.presupuestoId;
        this.clausulaId = entity.clausulaId;
        this.nombre = entity.nombre;
        this.descripcion = entity.descripcion;
        this.delegacionId = entity.delegacionId;
        this.delegacionNombre = entity.delegacionNombre != "" ? entity.delegacionNombre : "Todas"
        this.orden = entity.orden
        this.negrita = entity.negrita
    }

    public static toArray(entities: PresupuestoClausulaEntity[]): PresupuestoClausula[] {
        return entities.map(entity => { return new PresupuestoClausula(entity) })
    }

}

export interface PresupuestoClausulaCreateDto extends PresupuestoClausula {
    errors: string[]
}

export interface PresupuestoClausulaUpdateDto extends PresupuestoClausula {
    errors: string[]
}

export interface PresupuestoClausulaReadDto extends PresupuestoClausula {
    errors: string[]
}