import { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useState } from 'react'
import FormTemplate from '../../../components/common/FormTemplate/FormTemplate'
import '../../../assets/styles/components/inputs.scss'
import CustomTextInput from '../../../components/CustomTextInput';
import { AuthContext } from '../../Login/AuthContextProvider';
import { toast } from 'react-toastify';
import { UnidadCreateDto } from '../domain/model/Unidad';
import UnidadesRepository from '../domain/UnidadesRepository';
import UnidadesApiRepository from '../infraestructure/api/UnidadesApiRepository';
import DelegacionesRepository from '../../../domain/DelegacionesRepository';
import DelegacionesApiRepository from '../../../infraestructure/api/Delegaciones.ApiRepository';
import { DelegacionDto } from '../../../domain/model/Delegacion';
import UnidadesMedidaApiRepository from '../../../infraestructure/api/UnidadesMedida.ApiRepository';
import UnidadesMedidaRepository from '../../../domain/UnidadesMedidaRepository';
import { UnidadMedidaDto } from '../../../domain/model/UnidadMedida';
import { HistoryHandler } from '../../../context/History.Context';
import { Routes } from '../../../router/routes/Routes';
import { useLoading } from '../../../context/Loading.Context';
import Dropdown, { DropdownProps } from '../../../components/ui/atoms/Dropdown/Dropdown';
import FormField from '../../../components/ui/molecules/Form-field/FormField';
import { COD_DELEGACIONES_SIN_TODAS, USER_ROLES } from '../../../assets/constants/Constantes';

const UnidadesGrabacion = () => {
  const [delegaciones, setDelegaciones] = useState<DelegacionDto[]>([{ descripcion: "TODAS", id: "-1" }])
  const [medidas, setMedidas] = useState<UnidadMedidaDto[]>([])
  const [newUnidad, setNewUnidad] = useState<UnidadCreateDto>({
    descripcion: "",
    delegacionId: 1
  })
  const [newDescripcion, setNewDescripcion] = useState({
    unidadMedidaId: 1,
    unidadObraId: "",
    descripcion: ""
  })
   const formId = "unidades-form";
  const {goToRoute} = useContext(HistoryHandler);
  const {getToken, getDelegacion, hasRole} = useContext(AuthContext);
  const {setLoading} = useLoading();

  useEffect(() => {
    fetchMedidas()
  }, [])

  useEffect(() => {
    fetchDelegaciones()
  }, [getDelegacion().id])

  const fetchMedidas = useCallback(() => {
    const repo: UnidadesMedidaRepository = new UnidadesMedidaApiRepository(getToken());
    return repo.getConFiltro({}).then((data) => {
      setMedidas(data);
    })
      .catch(message => toast.error(message));
  }, []);

  const fetchDelegaciones = useCallback(() => {
    const repo: DelegacionesRepository = new DelegacionesApiRepository(getToken());
    const showDelegaciones = hasRole(USER_ROLES.ADMIN);
		return repo.fetchDelegacionesDto({ filter: { ids: showDelegaciones ? COD_DELEGACIONES_SIN_TODAS : [getDelegacion()?.id] } }).then((data) => {
      const delegacionesUpd = data;
      if (showDelegaciones) delegacionesUpd.push({ descripcion: "TODAS", id: "-1" })
      setDelegaciones(delegacionesUpd);
      handleSelectDelegacion(getDelegacion())
      setNewUnidad(prev => ({ ...prev, delegacionId: Number.parseInt(getDelegacion().id) }))
    })
      .catch(message => toast.error(message));
  }, []);

  const handleNewUnidad = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewUnidad(prev => ({ ...prev, [name]: value }));
  }

  const handleSelectDelegacion = (delegacion: DelegacionDto) => {
    const delegacionObj: DelegacionDto | undefined = delegaciones.find(((item: DelegacionDto) => item.id === delegacion.id));
    const unidadUpd = { ...delegacionObj, delegacionId: delegacionObj?.id ? +delegacionObj.id : null }
    setNewUnidad(prev => ({ ...prev, delegacionId: unidadUpd.delegacionId }))
  }

  const handleSelectMedida = (unidadMedida: UnidadMedidaDto) => {
    const medidaObj: UnidadMedidaDto | undefined = medidas.find((item: UnidadMedidaDto) => item.id === unidadMedida.id);
    setNewDescripcion(prev => ({ ...prev, unidadMedidaId: medidaObj ? +medidaObj.id : 1 }))
  }

  const handleNewDescripcion = (event: ChangeEvent<HTMLInputElement>) => {
    setNewDescripcion(prev => ({ ...prev, descripcion: event.target.value }))
  }

  const saveUnidad = useCallback((data: UnidadCreateDto, dataDescripcion: any) => {
    const repo: UnidadesRepository = new UnidadesApiRepository(getToken());
    setLoading(true);
    const unidadAdd = { ...data, delegacionId: data && data?.delegacionId != -1 ? data.delegacionId : null };
    repo.add(unidadAdd).then((res) => {
      try {
        repo.addDescripcionAUnidad({ ...dataDescripcion, unidadObraId: res.id }).then((resp) => {
          toast.success("Descripción añadida correctamente.");
        })
      } catch (err) {
        toast.error("No ha sido posible añadir la descripción.");
        console.error(err)
      }
      setNewDescripcion({
        unidadMedidaId: 1,
        unidadObraId: "",
        descripcion: ""
      }
      )
      goToRoute(Routes.UNIDADES_EDICION, { id: res?.id ?? "" })
      toast.success("Unidad añadida con éxito");
    })
      .catch((err) => {
        toast.error(err)
      })
      .finally(() => { setLoading(false); })

  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    saveUnidad(newUnidad, newDescripcion)
    setNewUnidad({
      descripcion: "",
      delegacionId: null
    })
  }

  return (
    <div className='add-edit-view-container'>
      <div className='add-edit-header'>
        <h4>Nueva Unidad de Obra</h4>
      </div>
      <div className="add-edit-body">
        <FormTemplate
          formId={formId}
          onSubmit={handleSubmit}
        >
          <div className="row-of-two">
            <CustomTextInput
              label="Unidad de obra"
              idTest="NombreUnidadObra"
              name="descripcion"
              value={newUnidad.descripcion}
              onChange={handleNewUnidad}
              required />

            {delegaciones &&
              <FormField<DropdownProps>
                label="Delegación"
                options={delegaciones.map(
                  (element) => {
                    return {
                      text: element.descripcion,
                      id: element.id,
                      selected: (Number.isNaN(newUnidad.delegacionId) ? Number.parseInt(getDelegacion().id) : newUnidad.delegacionId) == +element.id ? true : false,
                      onClick: () => { handleSelectDelegacion(element); }
                    }
                  })}
                required={true}
                disabled={false}
                singleSelection={true}
                component={Dropdown}
              />
            }

            <CustomTextInput
              label="Descripción"
              idTest="DescripcionUnidadObra"
              name="descripcion1"
              value={newDescripcion.descripcion}
              onChange={handleNewDescripcion}
              required />

            <FormField<DropdownProps>
              label="Unidad Medida"
              options={medidas.map(
                (element) => {
                  return {
                    text: element.descripcion,
                    id: element.id,
                    selected: newDescripcion.unidadMedidaId == +element.id ? true : false,
                    onClick: () => { handleSelectMedida(element); }
                  }
                })}
              required={true}
              disabled={false}
              singleSelection={true}
              component={Dropdown}
            />
          </div>
        </FormTemplate>
      </div>

    </div>
  )
}

export default UnidadesGrabacion