import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { PermisoVentanaApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { VentanaPermiso } from "../../domain/model/VentanaPermiso";

export default class FuncionalidadesRequestGet extends ApiRequest<any, BaseResponse<VentanaPermiso[]>>
{
	constructor(token: string) {
		super(
			"GET",
			PermisoVentanaApiEndpoints.GET + "?MaxResultCount=100&SortingCriteria=orden",
			{ Authorization: token },
			undefined,
			{						
			}
		)
	}
}