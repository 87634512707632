import { debounce } from "lodash";
import { useContext, useMemo, useState } from "react";
import FormFieldGroup from "../../../../components/ui/objects/FormFieldGroup/FormField.Group";
import { ClienteDescripcionSearch, ClienteTagHandler } from "../../context/Cliente.Context";

const DescripcionSearchField: React.FC<{}> = () => {

	const { value: filter, onChange, onClear } = useContext(ClienteDescripcionSearch);

	const { onFilter } = useContext(ClienteTagHandler);

	const [value, setValue] = useState<string>(filter ?? "");
	
	const debouncedValueUpdate = useMemo(() => {
		const baseTag = {
			label: "Descripción",
			onClear: () => {},
		}
	
		function handleClear() {
			onClear();
			onFilter({ ...baseTag, text: undefined });
		}
		const handleFilterChange = (value: string) => {
			onChange(value)
			onFilter({ ...baseTag, text: value, onClear: handleClear });
		};

		return debounce((value: string) => handleFilterChange(value), 1000)

	}, [onChange, onFilter, onClear]);

	const handleInputChange = (e: any) => {
		const value = e.target.value;
		setValue(value);
		debouncedValueUpdate(value);
	}

	return (
		<FormFieldGroup>
			<div className="input-container">
			<label>Descripción</label>
			<input
				// className="op-input"
				value={value}
				placeholder="NIF, Razón Social o Nombre Corto"
				onChange={handleInputChange}
				data-cy="DescripcionFiltroCliente"
			>
			</input>
			</div>
		</FormFieldGroup >
	)
}

export default DescripcionSearchField;