import { BaseEntity } from "@pavabits/components";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext, GlobalContextType } from "../../../context/Global.Context";
import { HistoryHandler, HistoryLocation } from "../../../context/History.Context";
import { AuthContext } from "../../../modules/Login/AuthContextProvider";
import OfertasRepository from "../../../modules/Ofertas/domain/OfertasRepository";
import OfertasApiRepository from "../../../modules/Ofertas/infraestructure/api/Ofertas.ApiRepository";
import { Routes } from "../../../router/routes/Routes";
import TooltipComponent from "../../ui/molecules/Tooltip/TooltipComponent";

export type CellChild<TObject extends BaseEntity> = {
	key: keyof TObject,
	childRender: (id: TObject["id"]) => React.ReactNode
}

interface BodyCellProps {
	cellValue?: string
	id: string
	className?: string
	colSpan?: number
	cellKey?: string
}

const BodyCell: React.FC<BodyCellProps> = (props: React.PropsWithChildren<BodyCellProps>) => {

	const { cellValue, className, children, colSpan, id, cellKey} = props;
	const [ident, setIdent] = useState<string>();
	const { goToRoute } = useContext(HistoryHandler);
	const { pathname } = useContext(HistoryLocation);
	const { getToken } = useContext(AuthContext);
	
	const ofertasRepo: OfertasRepository = new OfertasApiRepository(getToken());
		const { globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;

	const isLink = 
	pathname.includes(Routes.OFERTAS) && cellKey === "codigo" || 
	pathname.includes(Routes.OFERTAS) && cellKey === "clienteDescripcion" || 
	pathname.includes(Routes.OPORTUNIDADES) && (cellKey === "codigoAccion" && cellValue?.split("-")[1] == "oferta") || 
	pathname.includes(Routes.OFERTAS) && cellKey ===  "oportunidadCodigo" 
	? true : false

	useEffect(() => {
		getValuesHiperLink()
	  }, [id])

	const getValuesHiperLink = () => { 
		// OFERTAS → edición cliente 
		if (pathname.includes(Routes.OFERTAS) && cellKey === "clienteDescripcion") {
			let code = ofertasRepo.getById(id)
			.then((res) => {
				setIdent(res.clienteId);
			})
			return code
		}

		// OFERTAS → linea oportunidad (filtrar por código oportunidad)
		if (pathname.includes(Routes.OFERTAS) && cellKey ===  "oportunidadCodigo") {
			//updateGlobalState("oportunidades", {filters: {...globalState.oportunidades?.filters, "codigoOportunidad": cellValue}})
			setIdent(cellValue);
		}

		// OPORTUNIDADES → edición oferta
		if (pathname.includes(Routes.OPORTUNIDADES) && cellKey === "codigoAccion" && cellValue?.split("-")[1] == "oferta") {
			let oferta = ofertasRepo.fetchOfertasByFilter({
				"filter": {
				  codigo: cellValue?.split("-")[0]
				},
				"sortingCriteria": "",
				"maxResultCount": 0,
				"skipCount": 0
			  }, undefined)
			  .then((res) => {
				if (res.items.length > 0) {
					setIdent(res.items[0].id);
				}
			})
			return oferta
		}
	}

	const truncate = (item: any) => {
		if (item !== null && item !== undefined)
		return String(item).length > 25 ? String(item).substring(0, 22) + "..." : String(item);
	}

	return (
		<td
			//onClick={onClickCell}
			key={`cell-${id}`}
			className={isLink ? `link truncate-opp` : cellKey === "toneladas" || cellKey === "presupuesto" || cellKey === "importePresupuesto" ? `truncate-opp-number` : `truncate-opp`}
			colSpan={colSpan}
			data-cy={(cellKey?.charAt(0).toLocaleUpperCase() ?? '') + (cellKey?.slice(1) ?? '') + id}
		>
			<TooltipComponent text={cellValue && cellValue?.length > 25 && cellKey !== "contratistaDescripcion" ? cellValue : ""}>
				{isLink ?
					pathname.includes(Routes.OFERTAS) && cellKey === "codigo" ?
					ident != "" && <a className="link" href={(Routes.OFERTAS_EDICION.replace(":id", id))}>{children ? children : truncate(cellValue)}</a>
					:
					pathname.includes(Routes.OFERTAS) && cellKey === "clienteDescripcion" ?
					ident != "" && <a className="link" href={(Routes.CLIENTES_EDICION.replace(":id", ident!))}>{children ? children : truncate(cellValue)}</a>
					:
					pathname.includes(Routes.OFERTAS) && cellKey ===  "oportunidadCodigo" && cellValue !== "0" ?
					ident != "" && <a className="link" href={(Routes.OPORTUNIDADES+'?filter='+cellValue)}>{children ? children : truncate(cellValue)}</a>
					:
					pathname.includes(Routes.OFERTAS) && cellKey ===  "oportunidadCodigo" && cellValue === "0" ? ""
					:
					pathname.includes(Routes.OPORTUNIDADES) && cellKey === "codigoAccion" && cellValue?.split("-")[1] == "oferta" ?
					ident != "" && <a className="link" href={(Routes.OFERTAS_EDICION.replace(":id", ident!))}>{children ? children : cellKey == "codigoAccion" ? cellValue?.split("-")[0] : truncate(cellValue)}</a>
					:
					(children ? children : cellKey == "codigoAccion" ? cellValue?.split("-")[0] : truncate(cellValue))
					:
					(children ? children : 
						cellKey == "codigoAccion" ? cellValue?.split("-")[0] 
						: cellValue !== undefined ? truncate(cellValue) :
						cellValue)
				}

			{/* <Icon icon="info"/> */}
			</TooltipComponent>
		</td>
	)
}

export default BodyCell;