const envSettings = window as any;
//solo para recuperar la config de las columnas
export const KEYCLOAK_LOGIN= envSettings.KEYCLOAK_LOGIN;
export const KEYCLOAK_LOGOUT=envSettings.KEYCLOAK_LOGOUT;
export const KEYCLOAK_REALM= envSettings.KEYCLOAK_REALM;
export const KEYCLOAK_CLIENTID= envSettings.KEYCLOAK_CLIENTID;

export const NIL_UUID = '00000000-0000-0000-0000-000000000000';

export const PAVASAL_CLIENTEID=100;
export const PAVASAL_CONTRATISTAID=1;

// export const TOKEN = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnYiOiJYNSIsImxpYiI6IlFURU1QLFBBVk01Rlg1LFBBVk9YNSxQQVZNNU8sUEFWTTRGWDUsUEFWTTRPMSxCU0NNNEZYNSxCU0NNNE8xLEFETVBHTVBWLEFETVg1LEFETVBHTSxRR1BMLFMwNjkxQzNQIiwibW9kIjoiTEUiLCJBViI6IkNPTlNVTFRBIiwiQkgiOiJNQVNURVIiLCJCUiI6Ik1BU1RFUiIsIkNNIjoiTUFTVEVSIiwiQ08iOiJNQVNURVIiLCJDUCI6Ik1BU1RFUiIsIkVWIjoiTUFTVEVSIiwiRVgiOiJNQVNURVIiLCJGQyI6Ik1BU1RFUiIsIkZNIjoiTUFTVEVSIiwiRlAiOiJNQVNURVIiLCJHQSI6Ik1BU1RFUiIsIkdDIjoiTUFTVEVSIiwiR0YiOiJNQVNURVIiLCJHUyI6Ik1BU1RFUiIsIkdWIjoiTUFTVEVSIiwiSU4iOiJDT05TVUxUQSIsIklQIjoiTUFTVEVSIiwiTEUiOiJESVJFQ1RPUiIsIkxJIjoiTUFTVEVSIiwiTFAiOiJNQVNURVIiLCJMUiI6Ik1BU1RFUiIsIk1RIjoiTUFTVEVSIiwiTVoiOiJNQVNURVIiLCJPRiI6Ik1BU1RFUiIsIlBDIjoiTUFTVEVSIiwiU1MiOiJNQVNURVIiLCJWUCI6Ik1BU1RFUiIsIm5iZiI6MCwiZXhwIjoxOTIyOTQwNDAyLCJpYXQiOjExMjI5NDA0MDIsInN1YiI6Ilg1TUFTVEVSIiwia2lkIjoiNGI2ODliZTItMjBjNS00MmU2LThmYmUtM2RhMzkyNTNmOTc4In0.fsDuwWST05f9tBCwYznWNx74w12ZTuQPXh3cpwmKDvI";
//export const TOKEN= "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ3UC1WQzZyamJHbWtjYjF0cWZ0Y1dueEs3YUhPbEtKVXcxWU5RQThnY0xrIn0.eyJleHAiOjE2OTE0MTQ5MTEsImlhdCI6MTY5MTQxMzExMSwianRpIjoiMGU4OWQyMjQtMmEzOC00YTIwLWE2YWYtOGJlODY4ZmQ1NjY3IiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo4MDgxL3JlYWxtcy9tYXN0ZXIiLCJhdWQiOlsibWFzdGVyLXJlYWxtIiwiYWNjb3VudCJdLCJzdWIiOiJmNGNiNjc4ZS1jMmJkLTRlMmEtYjAzYi0yOTBiOWI1MjQ1YzciLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJvcG9ydHVuaWRhZGVzLXByZSIsInNlc3Npb25fc3RhdGUiOiJlMzM2MTIyNi03MzkzLTRlODMtOWZlNS0yMjA4M2FiYTU0ODciLCJhY3IiOiIxIiwiYWxsb3dlZC1vcmlnaW5zIjpbIioiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbImNyZWF0ZS1yZWFsbSIsImRlZmF1bHQtcm9sZXMtbWFzdGVyIiwib2ZmbGluZV9hY2Nlc3MiLCJhZG1pbiIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsibWFzdGVyLXJlYWxtIjp7InJvbGVzIjpbInZpZXctaWRlbnRpdHktcHJvdmlkZXJzIiwidmlldy1yZWFsbSIsIm1hbmFnZS1pZGVudGl0eS1wcm92aWRlcnMiLCJpbXBlcnNvbmF0aW9uIiwiY3JlYXRlLWNsaWVudCIsIm1hbmFnZS11c2VycyIsInF1ZXJ5LXJlYWxtcyIsInZpZXctYXV0aG9yaXphdGlvbiIsInF1ZXJ5LWNsaWVudHMiLCJxdWVyeS11c2VycyIsIm1hbmFnZS1ldmVudHMiLCJtYW5hZ2UtcmVhbG0iLCJ2aWV3LWV2ZW50cyIsInZpZXctdXNlcnMiLCJ2aWV3LWNsaWVudHMiLCJtYW5hZ2UtYXV0aG9yaXphdGlvbiIsIm1hbmFnZS1jbGllbnRzIiwicXVlcnktZ3JvdXBzIl19LCJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6InByb2ZpbGUgZW1haWwiLCJzaWQiOiJlMzM2MTIyNi03MzkzLTRlODMtOWZlNS0yMjA4M2FiYTU0ODciLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsInByZWZlcnJlZF91c2VybmFtZSI6ImFkbWluIn0.AvVoW_EybggZU9Jz9BKhjovzxQiFTcS5j0E183HRo3WM9os3X-r82sNafiJgxWL3mexyJG36glZapJwNzVNhBXdkXErZZn9YO6uxPUIE8Z3YUnagfy3AQ3AeaV58cBxxXuCQ2LZgXZMkRpbcNRbWO0RmQrTCJNts3SVOJvxQFzb845cUc5BVZSj8-kysPyavQKP98yt23Lflcgxg4AtyGlUPivfVbtqplW8GHfwfxYqOr1K99X2ZF9fk2w78abYA4TtNjZhSkuPcwFf2siBqyjd19igUoQfyfaCnDHc2BpqUIH0NrBA_O6nWwrllvCQQVqSqK728tV_kKijAYpWW6w"
export const NUMBER_FORMAT: Intl.NumberFormatOptions = {
	style: 'decimal',
	maximumSignificantDigits: 7,
};

export const LOCALES_NUMBER_FORMAT: string = 'de-DE';

export const ROUTES = {
    GRABACION: '/grabacion',
    EDICION: '/edicion'
} 

export const USER_ROLES = {
    ADMIN: 'Administrador',
    COMERCIAL: 'Comercial',
    CONSULTA: 'Consulta'
}

export const COD_DELEGACIONES_SIN_TODAS = [1, 2, 3, 4, 5, 6, 7];
export const COD_DELEGACIONES_CON_TODAS = [0, 1, 2, 3, 4, 5, 6, 7];

const Constantes = {
    NO_AUTH : '401',
    URL_FORMAT_REGEX: /(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    EMAIL_FORMAT_REGEX: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
    PHONE_NUMBER_REGEX: /^(\+?34)?(6\d{2}|7[1-9]\d{1}|\d{3})\d{6}$/g,
    DNI_REGEX : /^(\d{8})([A-Za-z])$/g,
    CIF_REGEX : /^([ABCDEFGHJKLMNPQRSUVWabcdefghjklmnpqrsuvw])(\d{7})([0-9A-Ja-j])$/g,
    NIE_REGEX : /^[XYZxyz]\d{7,8}[A-Za-z]$/g,
	OBSERVACIONES_MAX_LENGTH: 200,
    localesDateOptions: 'es-ES',
    dateOptions: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    } as Intl.DateTimeFormatOptions,
    backendDateFormat: 'yyyy-MM-DD',
    minChainLengthSuggestedInputs: 3,
    SINO: ["SÍ", "NO"]
}

export default Constantes;