import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoading } from "../../../../context/Loading.Context";
import { FormErrors } from "../../../../hooks/use-form";
import { AuthContext } from "../../../Login/AuthContextProvider";
import ClienteRepository from "../../domain/ClienteRepository";
import { ClienteDto, ClienteParams, ClienteUpdateDto } from "../../domain/model/Cliente";
import ClienteApiRepository from "../../infraestructure/api/Cliente.ApiRepository";
import ClienteFormTemplate from "../common/Cliente.FormTemplate";
import { Severity } from "../../../../utils/ErrorSeverity";
import { VentanaPermiso } from "../../../Login/domain/model/VentanaPermiso";

interface Props {
	permisoVentana: VentanaPermiso
}

const ClienteEdicion: React.FC<Props> = ({permisoVentana}: Props) => {
	const params = useParams<ClienteParams>();
	const requestedPromotorId = params["id"];
	const {getToken} = useContext(AuthContext);
	const {setLoading} = useLoading();
	const [cliente, setCliente] = useState<ClienteDto>();

	const fetchClienteFromApi = useCallback((id: number) => {
		const repo: ClienteRepository = new ClienteApiRepository(getToken());
		setLoading(true);
		return repo.getById(id).then((data) => {
			setCliente(data);
		})
			.catch(message => toast.error(message))
			.finally(() => {setLoading(false)});
	}, [getToken]);

	useEffect(() => { // Side Effect
		fetchClienteFromApi(requestedPromotorId)
	}
		, [fetchClienteFromApi, requestedPromotorId]);

	const onSubmit = (
		valid: boolean,
		data: ClienteUpdateDto,
		errors: FormErrors<ClienteUpdateDto>
	) => {
		const repo: ClienteRepository = new ClienteApiRepository(getToken());
		data.lastModificationTime = cliente?.lastModificationTime ?? data.lastModificationTime;
		if (valid) {
			setLoading(true);

			repo.update(data)
				.then((res) => {
					toast.success("Cliente modificado con éxito");
					setCliente(res);
					if (res?.errores.length > 0) {
						if (res.errores[0].severidad === Severity.Warning) {
							toast.warning(res.errores[0].descripcion);
						} else {
							toast.error(res.errores[0].descripcion);
						}
					}
				})
				.catch(message => toast.error(message))
				.finally(() => {setLoading(false)});
		}
	};

	return (
<>
			{cliente !== undefined ?
				<>
					<ClienteFormTemplate
						type="edit" cliente={cliente} handleOnSubmit={onSubmit} readOnly={!permisoVentana?.permisoModificacion}
					/>
				</>
				: 
				<>
				</>
			}
		</>
	)
}

export default ClienteEdicion;