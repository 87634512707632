
import { BaseApiRequest } from "@pavabits/components";
import BaseResponse from "../../domain/BaseResponse";
import { TramoDto, TramoFilter, TramoReadingDto } from "../../domain/model/Tramo";
import { TramosEndpoints } from "../../modules/Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class TramosRequestListByFilter extends BaseApiRequest
<
	TramoFilter, 
	TramoReadingDto[]
>
{
	public constructor (data : TramoFilter, token : string){
        super(
            "POST",
            TramosEndpoints.CON_FILTRO,
            data,
            {Authorization: `${token}`}
        )
    }
}