import { BaseApiRequest } from "@pavabits/components";
import { SucursalDto, SucursalFilter } from "../../domain/model/Sucursal";
import BaseResponse from "../../domain/BaseResponse";
import { SucursalEndpoints } from "../../modules/Oportunidades/infraestructure/oportunidades.api-endpoints";


export default class SucursalesRequestListByFilter extends BaseApiRequest<SucursalFilter,BaseResponse<SucursalDto[]>>
{
    public constructor (data : SucursalFilter, token : string){
        super(
            "POST",
            SucursalEndpoints.SUCURSALES_CON_FILTRO,
            data,
            {Authorization: `${token}`}
        )
    }
}