import { useMemo, useState } from "react";
import FormInput from "../../../../components/ui/atoms/Input/FormInput/FormInput";
import { debounce } from "lodash";

interface CodigoLicitacionSearchFieldProps {
	fetchLicitacion: (codigo: number) => void
}

const CodigoLicitacionSearchField: React.FC<CodigoLicitacionSearchFieldProps> = (
	{
		fetchLicitacion
	}
) => {

	const [value, setValue] = useState<string>("");
	
	const [error, setError] = useState<string>();

	const debouncedFetch = useMemo(() => {
		return debounce((value: number) => fetchLicitacion(value), 1500);
	}, [fetchLicitacion]);

	const handleInputChange = (e: any) => {
		const value = e.target.value;

		if (value != "" && !parseInt(value)) {
			setError("Sólo códigos numéricos");
			return;
		}
		setError(undefined);
		setValue(value);
		debouncedFetch(value);
	}

	return (
		<FormInput
			label="Buscar"
			type="number"
			placeholder="Código de la licitación asociada a la oferta"
			value={value}
			error={error}
			onChange={handleInputChange}
		/>
	)

}

export default CodigoLicitacionSearchField;