import SelectableItem, { SelectableItemDto } from "../../components/ui/atoms/SuggestionInput/model/SuggestionInput.Model";
import EstudioDetalleAvanzadoMaterial from "../../modules/Estudios/domain/model/EstudioDetalleAvanzadoMaterial";
import UnidadMedida from "./UnidadMedida";


export type MaterialFilter = {
    filter: {
        planta: string;
        descripcion?: string;
    }
};

export interface MaterialDto extends SelectableItemDto { text: string };

export default class Material extends SelectableItem { };


interface MaterialReadingDtoEntity {
    id: string;
    descripcion: string;
    densidad: number;
    planta: string;
    precio: string;
    fechaSincronizacion: Date;
}
export class MaterialReadingDto {
    id: string;
    descripcion: string;
    densidad: number;
    planta: string;
    precio: string;
    fechaSincronizacion: Date;

    constructor(entity: MaterialReadingDtoEntity) {
        this.id = entity.id;
        this.densidad = entity.densidad
        this.descripcion = entity.descripcion;
        this.planta = entity.planta;
        this.precio = entity.precio;
        this.fechaSincronizacion = entity.fechaSincronizacion;
    }
};
interface MaterialCalculoEntity {
    id?: string;
    codigoArticulo: number;
    materialId:string|undefined;
    mezcla: string;
    densidad: string;
    espesor: string;
    superficie: string;
    tmNecesarias: string;
    precio: string;
    precioOriginal?: string;
    preciom2:number;
    riego: number;
    costeMezcla: number;
    totalEurosM2: number;
    coeficiente: number;
    descripcionDetalle: string;
    unidadMedida: UnidadMedida;
    estudioDetalleId?: string;
    orden: number;
    volumen?: number;
    precioCosteM2?: number;
    precioCosteTm?: number;
    importeCoste?: number;
    precioVentaM2?: number;
    precioVentaTm?: number;
    importeVenta?: number;
    precioCosteM3?: number;
    precioVentaM3?: number;
    importeCosteM3?: number;
    importeVentaM3?: number;
    importeVentaTm?: number;
    importeCosteTm?: number;
    importeCosteM2?: number;
    importeVentaM2?: number;
    fechaSincronizacion?: Date;
}
export class MaterialCalculo {
    id?: string;
    codigoArticulo: number;
    materialId:string| undefined;
    mezcla: string;
    densidad: string;
    espesor: string;
    superficie: string;
    tmNecesarias: string;
    precio: string;
    precioOriginal?: string;
    preciom2:number;
    riego: number;
    costeMezcla: number;
    totalEurosM2: number;
    coeficiente: number;
    descripcionDetalle: string;
    unidadMedida: UnidadMedida;
    estudioDetalleId?: string;
    orden: number;
    volumen?: number;
    precioCosteM2?: number;
    precioCosteTm?: number;
    importeCoste?: number;
    precioVentaM2?: number;
    precioVentaTm?: number;
    importeVenta?: number;
    precioCosteM3?: number;
    precioVentaM3?: number;
    importeCosteM3?: number;
    importeVentaM3?: number;
    importeVentaTm?: number;
    importeCosteTm?: number;
    importeCosteM2?: number;
    importeVentaM2?: number;
    fechaSincronizacion?: Date;

    constructor(entity: MaterialCalculoEntity) {
        this.id = entity.id;
        this.codigoArticulo = entity.codigoArticulo;
        this.materialId=entity.materialId;
        this.mezcla = entity.mezcla;
        this.densidad = entity.densidad
        this.espesor = entity.espesor;
        this.superficie = entity.superficie;
        this.tmNecesarias = entity.tmNecesarias;
        this.precio = entity.precio;
        this.precioOriginal = entity.precioOriginal;
        this.preciom2 = entity.preciom2;
        this.riego = entity.riego;
        this.costeMezcla = entity.costeMezcla;
        this.totalEurosM2 = entity.totalEurosM2;
        this.coeficiente = entity.coeficiente;
        this.descripcionDetalle = entity.descripcionDetalle;
        this.unidadMedida = entity.unidadMedida;
        this.estudioDetalleId = entity.estudioDetalleId;
        this.orden = entity.orden;
        this.volumen = entity.volumen;
        this.fechaSincronizacion = entity.fechaSincronizacion;
    }
    
};