import ApiRequest from "../../../../domain/ApiRequest";
import { BaseFilter } from "../../../../domain/BaseFilter";
import BaseResponse from "../../../../domain/BaseResponse";
import { EstadoOfertaEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import EstadoOferta, { EstadoOfertaFilter } from "../../domain/model/EstadoOferta";


export default class EstadoOfertaRequestListByFilter extends ApiRequest<
EstadoOfertaFilter,
BaseResponse<EstadoOferta[]>>
{
	constructor(data: EstadoOfertaFilter, token: string) {
		super(
			"POST",
			EstadoOfertaEndpoints.CON_FILTRO,
			{ Authorization: token },
			data,
		)
	}
}
