import ApiRequest from "../../../../domain/ApiRequest";
import { DescripcionUnidadObraApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { DescripcionUnidadObra } from "../../domain/model/Unidad";

export default class UnidadesDescripcionRequestAdd extends ApiRequest<any, DescripcionUnidadObra>
{
    constructor(data: any, token: string) {
        super(
            "POST",
            DescripcionUnidadObraApiEndpoints.CREAR,
            { Authorization: `${token}`},
            data
        )
    }
}