import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { PresupuestoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import Presupuesto, { PresupuestoDto, PresupuestoFilter, PresupuestoSend } from "../../domain/model/Presupuesto";

export default class PresupuestoRequestSend extends ApiRequest
<PresupuestoSend, 
PresupuestoDto
>
{
	constructor(data: PresupuestoSend, token: string) {
		super(
			"POST",
			PresupuestoApiEndpoints.ENVIAR,
			{  Authorization: `${token}` },
			data,
		)
	}
}