import ApiRequest from "../../../../domain/ApiRequest";
import { ClausulasOfertaApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ClausulaOferta, { ClausulaOfertaCreateDto } from "../../domain/model/ClausulaOferta";

export default class ClausulaAdd extends ApiRequest<ClausulaOfertaCreateDto, ClausulaOferta>
{
    constructor(data: ClausulaOfertaCreateDto, token: string) {
        super(
            "POST",
            ClausulasOfertaApiEndpoints.CREAR,
            { Authorization: `${token}`},
            data
        )
    }
}