import ApiRequest from "../../../../domain/ApiRequest";
import { BaseFilter } from "../../../../domain/BaseFilter";
import BaseResponse from "../../../../domain/BaseResponse";
import { UnidadObraApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import Unidad, { UnidadFilter } from "../../domain/model/Unidad";

export default class UnidadObraRequestListByFilter extends ApiRequest<any, BaseResponse<Unidad[]>>
{
	constructor(filter:BaseFilter<UnidadFilter>, token: string) {
		super(
			"POST",
            UnidadObraApiEndpoints.CON_FILTRO,
            { Authorization: token },
            {
                filter : filter
            }
		)
	}
}