import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { PresupuestoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import Presupuesto, { PresupuestoFilter } from "../../domain/model/Presupuesto";

export default class PresupuestoRequestGetByOfertaId extends ApiRequest
<PresupuestoFilter, 
BaseResponse<Presupuesto[]>
>
{
	constructor(data: PresupuestoFilter, token: string) {
		super(
			"POST",
			PresupuestoApiEndpoints.CON_FILTRO,
			{  Authorization: `${token}` },
			data,
		)
	}
}