
import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { PresupuestoClausulasApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import PresupuestoClausula from "../../domain/model/PresupuestoClausula";

export default class PresupuestoClausulaGetByPresupuestoId extends ApiRequest<any, BaseResponse<PresupuestoClausula[]>>
{
	constructor(id: string, token: string) {
		super(
			"GET",
			PresupuestoClausulasApiEndpoints.DEFAULT_FILTER + "?Filters[0].Key=presupuestoId&Filters[0].Value=" + id,
			{ Authorization: token },
			undefined,
			{
			}
		)
	}
}