
import { BaseApiRequest } from "@pavabits/components";
import BaseResponse from "../../domain/BaseResponse";
import { PlantaDto, PlantaFilter } from "../../domain/model/Planta";
import { PlantasEndpoints } from "../../modules/Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class PlantasRequestConFiltro extends BaseApiRequest
	<
		PlantaFilter,
		BaseResponse<PlantaDto[]>
	> {
	constructor(data: PlantaFilter, token: string) {
		super(
			"POST",
			PlantasEndpoints.CONFILTRO,
			data,
			{ Authorization: `${token}` }
		)
	}
}
