import { FC, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import CustomCheckInput from "../../../components/common/CustomCheckInput";
import IconButton from "../../../components/ui/atoms/Buttons/IconButton";
import { CustomButton } from "../../../shared/components/Buttons/CustomButton";
import CustomInput from "../../../shared/components/Inputs/CustomInput/CustomInput";
import { formatDate2String, formatDDMMYYYY, formatUsDate2String } from "../../../utils/DateUtil";
import { AuthContext } from "../../Login/AuthContextProvider";
import Ofertas from "../../Ofertas/domain/model/Ofertas";
import EstudiosOfertasModalSection from "../../Ofertas/views/objects/Modal section/EstudiosOfertas.ModalSection";
import GenerarPresupuestoModalSection from "../../Ofertas/views/objects/Modal section/GenerarPresupuesto.ModalSection";
import EstudioApiRepository from "../Infraestructure/api/Estudio.ApiRepository";
import EstudioRepository from "../domain/EstudioRepository";
import Estudio from "../domain/model/Estudio";
import "./Estudios.scss";
import { formatNumber } from "../../../utils/NumberUtil";
import TooltipComponent from "../../../components/ui/molecules/Tooltip/TooltipComponent";
import EstudioDetalle from "../domain/model/EstudioDetalle";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import Loading from "../../../shared/components/Loading/Loading";
import { useLoading } from "../../../context/Loading.Context";
import DeleteModal from "../../../shared/components/DeleteModal/DeleteModal";


interface Props {
    title: string
    estudios: Estudio[],
    idPadre: string,
    ofertaId?: string,
    oferta: Ofertas
    handleRefreshEstudios: (number?: any) => void,
    disabled?: boolean,
    readOnly?: boolean
}

const EstudiosView: FC<Props> = ({
    estudios,
    idPadre,
    title,
    ofertaId,
    oferta,
    handleRefreshEstudios,
    disabled,
    readOnly
}) => {
    disabled = disabled || readOnly;
    const { getToken } = useContext(AuthContext);
    const [selectedEstudio, setSelectedEstudio] = useState<Estudio>();
    const [openEstudioModal, setOpenEstudioModal] = useState<boolean>(false);
    const [selectedEstudios, setSelectedEstudios] = useState<Estudio[]>([])
    const [openPresupuestoModal, setOpenPresupuestoModal] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const { setLoading } = useLoading();


    const editarEstudios = (estudio: Estudio) => {
        setSelectedEstudio(estudio);
        setOpenEstudioModal(true);
    }

    useEffect(() => {
        if (!openPresupuestoModal || openEstudioModal) {
            setSelectedEstudios([]);
        }
    }, [openPresupuestoModal, openEstudioModal])


    //delete
    const [deletionId, setDeletionId] = useState<string>();
    const openConfirmationModal = (id: string) => {
        setDeletionId(id);
        setOpenDelete(true);
    }

    const handleCloseModalDelete = (remove: boolean) => {
        setOpenDelete(false);
        if (remove) {
            deleteEstudios(deletionId!);
        }
    }

    const deleteEstudios = (estudioId: string) => {
        const repo: EstudioRepository = new EstudioApiRepository(getToken());
        setLoading(true);
        repo.delete(estudioId)
            .then(respo => {
                toast.success('Eliminado correctamente');
                handleRefreshEstudios();
            })
            .catch(error => {
                toast.success('Error al intentar eliminar')
            })
            .finally(() => {
                setLoading(false)
            });
    }

    const handleRefresh = (number?: any) => {
        handleRefreshEstudios(number);
        setOpenEstudioModal(false);
        setOpenPresupuestoModal(false);
    }

    const handleCloseModal = () => {
        setOpenEstudioModal(false);
        setOpenPresupuestoModal(false);
    }

    const handleCheck = (checked: boolean, estudio: Estudio) => {
        if (checked) {
            setSelectedEstudios(prev => prev.includes(estudio) ? prev : [...prev, estudio])
        } else {
            setSelectedEstudios(prev => prev.filter(item => item !== estudio))
        }
    }

    const onGenerarPresupuesto = (e: any) => {
        e.preventDefault();
        setOpenPresupuestoModal(true)
    }

    const getRendimientoMedio = (estudioDetalle: EstudioDetalle[]): string => {
        let rdoMedio: string = "";

        if (estudioDetalle.length > 0) {
            let detalleAvanzado = estudioDetalle.find((detalle) => {
                return detalle.estudioDetalleAvanzado !== undefined && detalle.estudioDetalleAvanzado !== null && detalle.unidadObra !== null  && detalle.unidadObra?.calculoAvanzadoId == 1;
            })?.estudioDetalleAvanzado;

            let totalToneladas = detalleAvanzado?.material?.reduce((acc, item) => {
                return acc + +item.toneladas;
            }, 0);

            if (totalToneladas && detalleAvanzado?.dias) {
                rdoMedio = (totalToneladas / detalleAvanzado?.dias).toFixed(2).toString();
            }
        }

        return rdoMedio;
    };

    return (
        <>
            <div className="inline-grid">
                <div className="inline-grid-header">
                    <label
                        className="inline-header-checkbox"
                        style={{ minWidth: "34px", width: "34px" }}>
                    </label>
                    <label>Nº Estudio</label>
                    <label>Fecha</label>
                    <label>Nombre</label>
                    <label>Total Coste</label>
                    <label>Total Venta</label>
                    <label>Nº Presupuesto</label>
                    <label>Rdo Medio</label>

                    <div className={selectedEstudios.length > 0 ? "inline-buttons" : "inline-button"}>
                        <CustomButton
                            id={"add-btn"}
                            icon="add"
                            title={'Nuevo estudio'}
                            className=""
                            btnStyle="accept"
                            disabled={disabled}
                            onButtonClick={() => {
                                editarEstudios(new Estudio({
                                    id: undefined,
                                    descripcion: '',
                                    creationTime: formatUsDate2String(new Date()),
                                    totalVenta: 0,
                                    totalCoste: 0,
                                    ofertaId: idPadre,
                                    estudioDetalle: [],
                                    numeroPresupuestoYVersion: "",
                                    presupuestoId: ''
                                }));
                            }}
                            type="button"
                        />
                        {
                            selectedEstudios.length > 0 ? <CustomButton
                                icon="note_add"
                                className={"contact-btn"}
                                onButtonClick={onGenerarPresupuesto}
                                title="GenerarPresupuestoGrid"
                            />
                                : <label></label>
                        }
                    </div>
                </div>
                <div className="inline-grid-container">
                    {estudios?.map((estudio: Estudio, i: number) => {
                        return (
                            <div key={`estudio-${estudio.id}`} className="inline-grid-item">
                                <div className="inline-grid-checkbox" style={{ width: "180px" }}>
                                    <CustomCheckInput
                                        checked={selectedEstudios.includes(estudio)}
                                        icon={selectedEstudios.includes(estudio) ? "check" : undefined}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheck(e.target.checked, estudio)}
                                        disabled={readOnly || estudio.numeroPresupuestoYVersion ? true : false}
                                        idTest={`CheckboxEstudioGrid`+i}
                                    />
                                </div>

                                <CustomInput
                                    key={`$estudioId-${estudio.id}`}
                                    value={estudio.estudioId?.toString()}
                                    className={'platform-input secondary-input'}
                                    formClassName={"secondary-input"}
                                    onChange={(e) => { }}
                                    disabled={true}

                                />
                                <CustomInput
                                    key={`$fechaSeguimiento-${estudio.id}`}
                                    value={formatDate2String(formatDDMMYYYY(estudio.creationTime))}
                                    className={'platform-input secondary-input'}
                                    onChange={(e) => { }}
                                    disabled={true}
                                    formClassName={"secondary-input"}
                                    type="date"

                                />
                                <TooltipComponent
                                    text={estudio.descripcion}>
                                    <CustomInput
                                        key={`$descripcion-${estudio.id}`}
                                        value={estudio.descripcion}
                                        className={'platform-input secondary-input'}
                                        formClassName={"secondary-input"}
                                        onChange={(e) => { /*handlePropertyChange('observaciones', e, i); */ }}
                                        disabled={true}

                                    /></TooltipComponent>
                                <CustomInput
                                    key={`$totalCoste-${estudio.id}`}
                                    value={formatNumber(estudio.totalCoste ? estudio.totalCoste : 0)}
                                    className={'platform-input secondary-input'}
                                    formClassName={"secondary-input"}
                                    onChange={(e) => { /*handlePropertyChange('observaciones', e, i); */ }}
                                    disabled={true}
                                    type="number"
                                />

                                <CustomInput
                                    key={`$totalVenta-${estudio.id}`}
                                    value={formatNumber(estudio.totalVenta ? estudio.totalVenta : 0)}
                                    className={'platform-input secondary-input'}
                                    formClassName={"secondary-input"}
                                    onChange={(e) => { /*handlePropertyChange('observaciones', e, i); */ }}
                                    disabled={true}
                                    type="number"

                                />

                                <CustomInput
                                    key={`presupuestoNumber-${estudio.id}`}
                                    formClassName={"secondary-input"}
                                    value={estudio.numeroPresupuestoYVersion ? ofertaId + "/" + estudio.numeroPresupuestoYVersion : ''}
                                    onChange={() => { }}
                                    disabled={true}
                                />

                                <CustomInput
                                    key={`rdoMedio-${estudio.id}`}
                                    formClassName={"secondary-input"}
                                    value={getRendimientoMedio(estudio.estudioDetalle)}
                                    onChange={() => { }}
                                    disabled={true}
                                />

                                <div className="inline-buttons">
                                    <IconButton icon={readOnly || estudio.numeroPresupuestoYVersion ? 'visibility' : 'edit'} 
                                        disabled={!readOnly && (!estudio.numeroPresupuestoYVersion && disabled)}
                                        onClick={() => { editarEstudios(estudio); }}
                                        index={i}
                                        label={readOnly || estudio.numeroPresupuestoYVersion ? 'VerGrid' : 'EditarGrid'} 
                                    />
                                    <IconButton icon='delete'
                                        onClick={() => { openConfirmationModal(estudio.id); }}
                                        disabled={estudio.numeroPresupuestoYVersion || disabled ? true : false}
                                        index={i}
                                        label="BorrarGrid"
                                    />
                                </div>
                            </div>
                        )
                    }
                    )}
                </div>

            </div>

            {
                openDelete &&
                <DeleteModal
                    open={openDelete}
                    handleCloseModal={handleCloseModalDelete}
                    field="estudio"
                >
                </DeleteModal>
            }
            {
                selectedEstudio && openEstudioModal &&
                <EstudiosOfertasModalSection
                    opened={openEstudioModal}
                    estudio={selectedEstudio}
                    oferta={oferta}
                    handleCloseModal={handleCloseModal}
                    handleRefresh={handleRefresh}
                    readOnly={readOnly || Boolean(selectedEstudio.id && selectedEstudio.numeroPresupuestoYVersion != "")}>
                </EstudiosOfertasModalSection>
            }
            {
                openPresupuestoModal && ofertaId &&
                <GenerarPresupuestoModalSection
                    opened={openPresupuestoModal}
                    oferta={oferta}
                    estudios={selectedEstudios}
                    handleCloseModal={handleCloseModal}
                    handleRefresh={handleRefresh}
                />
            }

        </>
    )
}

export default EstudiosView;