import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { EstudioApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import Estudio from "../../domain/model/Estudio";

export default class EstudioRequestGetByPresupuestoId extends ApiRequest<any, BaseResponse<Estudio[]>>
{
	constructor(id: string, token: string) {
		super(
			"GET",
			EstudioApiEndpoints.DEFAULT_FILTER + "?Filters[0].Key=presupuestoId&Filters[0].Value=" + id+"&SortingCriteria=estudioId%20DESC&MaxResultCount=99&SkipCount=0",
			{ Authorization: token },
			undefined,
			{
						
			}
		)
	}
}