import { LibroEstandarApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ApiRequest from "../../../../domain/ApiRequest";
import { LibroEstandarDto, LibroEstandarUpdateDto } from "../../domain/model/LibroEstandar";

export default class LibroEstandarRequestUpdate extends ApiRequest<
	LibroEstandarUpdateDto,
	LibroEstandarDto
> {
	constructor(data: LibroEstandarUpdateDto, token: string) {
		super(
			"PUT",
			LibroEstandarApiEndpoints.UPDATE,
			{ Authorization: `${token}` },
			data,
		)
	}
}