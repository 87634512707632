
import { BaseApiRequest } from "@pavabits/components";
import ApiRequest from "../../domain/ApiRequest";
import BaseResponse from "../../domain/BaseResponse";
import { MaterialDto, MaterialFilter } from "../../domain/model/Material";
import { MaterialesEndpoints } from "../../modules/Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class MaterialesRequestListByFilter extends BaseApiRequest
<
	MaterialFilter, 
	BaseResponse<MaterialDto[]>
>
{
	public constructor (data : MaterialFilter, token : string){
        super(
            "POST",
            MaterialesEndpoints.CON_FILTRO,
            data,
            {Authorization: `${token}`}
        )
    }
}