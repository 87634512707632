import { BaseEntity } from "@pavabits/components";

export interface ClienteEntityDto {
	id: string
	descripcion: string
	iva: number
}

export default class ClienteEntity extends BaseEntity {
	constructor(
		readonly id: string,
		readonly _descripcion: string,
		readonly _iva: number,
	) {
		super(id, _descripcion);
	}

	get descripcion(){
		return this._descripcion;
	}

	get iva(){
		return this._iva;
	}

}