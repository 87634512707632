import Contacto from "../../../Contactos/domain/model/Contacto";
import ContactoId from "./ContractoId";
import FormaPagoContrato from "./FormaPagoContrato";


interface ContratoEntity {
	id?: string | undefined,
	fechaContrato: string,
	fechaEnvio?: string | null,
	referenciaPresupuesto: string,
	condicionesParticulares: string,
	importePresupuesto: string,
	pdfUrl?: string,
	firmantes: ContactoId[],
	formaPagoContrato: FormaPagoContrato,
	ofertaId:string;
}



export default class Contrato implements ContratoEntity {

	id?: string ;
	fechaContrato: string;
	fechaEnvio?: string | null;
	referenciaPresupuesto: string;
	condicionesParticulares: string;
	importePresupuesto: string;
	pdfUrl?: string;
	firmantes:  ContactoId[];
	
	formaPagoContrato: FormaPagoContrato;
	ofertaId:string;


	constructor(entity: ContratoEntity) {
		this.id = entity.id!;
		this.fechaContrato = entity.fechaContrato;
		this.fechaEnvio = entity.fechaEnvio;
		this.referenciaPresupuesto = entity.referenciaPresupuesto;
		this.condicionesParticulares = entity.condicionesParticulares;
		this.importePresupuesto = entity.importePresupuesto;
		this.pdfUrl = entity.pdfUrl;
		this.firmantes = entity.firmantes;

		this.formaPagoContrato = entity.formaPagoContrato;
		this.ofertaId=entity.ofertaId;
	}

	public static toArray(entities: ContratoEntity[]): Contrato[] {
		return entities ? entities.map(entity => { return new Contrato(entity) }) : [];
	}
}

