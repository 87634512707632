import ApiRequest from "../../../../domain/ApiRequest";
import { LibroEstandarDetalleApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import LibroEstandarDetalle, { LibroEstandarDetalleUpdateDto } from "../../domain/model/LibroEstandarDetalle";

export default class LibroEstandarDetalleAddList extends ApiRequest<
	LibroEstandarDetalleUpdateDto[],
	LibroEstandarDetalle[]
> {
	constructor(data: LibroEstandarDetalleUpdateDto[], token: string) {
		super(
			"POST",
			LibroEstandarDetalleApiEndpoints.CREARLIST,
			{ Authorization: `${token}` },
			data,
		)
	}
}