import { BaseRepository } from "@pavabits/components";
import ClienteEntity, { ClienteEntityDto } from "../../domain/model/ClienteEntity";
import ClienteDetalleRequestById from "../ws/ClienteDetalle.RequestById";
import ClienteDetalleRepository from "../../domain/ClienteDetalleRepository";

export default class ClienteDetalleApiRepository extends BaseRepository<
    ClienteEntityDto,
    ClienteEntity
> implements ClienteDetalleRepository {

    searchCliente(id: number): Promise<ClienteEntity> {
        return new Promise((resolve, reject) => {
            return new ClienteDetalleRequestById(id, this.authToken)
                .request()
                .then((response) => {
                    if (response.success) {
                        resolve(this.transformDtoIntoModel(response.data))
                    } else {
                        reject(`Error ${response.errorCode} : ${response.errorMessage}`);
                    }
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    getById(id: number): Promise<ClienteEntityDto> {
        return new Promise((resolve, reject) => {
            return new ClienteDetalleRequestById(id, this.authToken)
                .request()
                .then((response) => {
                    if (response.success) {
                        resolve(response.data)
                    } else {
                        reject(`Error ${response.errorCode} : ${response.errorMessage}`);
                    }
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    protected transformDtoIntoModel(dto: ClienteEntityDto): ClienteEntity {
        return new ClienteEntity(
            dto.id,
            dto.descripcion,
            dto.iva
        )
    }

}