
import ApiRequest from "../../../../domain/ApiRequest";
import { EquipoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class EquipoRequestDelete extends ApiRequest<
{},
boolean
>
{
	constructor(id: string, token: string) {
		super(
			"DELETE",
			EquipoApiEndpoints.DELETE_EQUIPO+id,
			{ Authorization: `${token}`},
			{},
		)
	}
}