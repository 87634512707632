import { BaseRepository } from "@pavabits/components";
import SucursalsRepository from "../../domain/SucursalesRepository";
import SucursalsRequestListByFilter from "../ws/Sucursales.RequestListByFilter";
import Sucursal, { SucursalDto } from "../../domain/model/Sucursal";
import SucursalesRequestById from "../ws/Sucursales.RequestById";

export default class SucursalsApiRepository extends BaseRepository<
	SucursalDto,
	Sucursal
> implements SucursalsRepository {
	fetchSucursales(data: any): Promise<Sucursal[]> {
		return new Promise((resolve, reject) => {
			return new SucursalsRequestListByFilter(data, this.authToken)
				.request()
				.then((response) => {
					if (response.success) {
						resolve(this.transformListDtoIntoModel(response.data.items));
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error) => {
					reject(error);
				})
		})
	}

	fetchSucursal(id: string): Promise<Sucursal> {
		return new Promise((resolve, reject) => {
			return new SucursalesRequestById(id, this.authToken)
				.request()
				.then((response) => {
					if (response.success) {
						resolve(this.transformDtoIntoModel(response.data));
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error) => {
					reject(error);
				})
		})
	}

	protected transformListDtoIntoModel(dto: SucursalDto[]): Sucursal[] {
		if (dto && dto.length) {
			return dto.map(item => {
				return new Sucursal(
					item.id.toString(),
					item.descripcion,
					item.municipio,
					item.entidad,
					item.direccion
				)
			})
		}
		return []
	}

	protected transformDtoIntoModel(dto: SucursalDto): Sucursal {
			return new Sucursal(
				dto.id.toString(),
				dto.descripcion,
				dto.municipio,
				dto.entidad,
				dto.direccion
				)
	}

}