import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../Login/AuthContextProvider";
import {
  ElementoEquipoUpdateDto,
  UnidadObraElementoTipo,
} from "./domain/model/ElementoEquipo";
import FormTemplate from "../../components/common/FormTemplate/FormTemplate";
import CustomTextInput from "../../components/CustomTextInput";
import EquiposRepository from "./domain/EquiposRepository";
import EquipoApiRepository from "./infrastructure/api/Equipo.ApiRepository";
import { toast } from "react-toastify";
import FormField from "../../components/ui/molecules/Form-field/FormField";
import Dropdown, {
  DropdownProps,
} from "../../components/ui/atoms/Dropdown/Dropdown";
import DelegacionesRepository from "../../domain/DelegacionesRepository";
import DelegacionesApiRepository from "../../infraestructure/api/Delegaciones.ApiRepository";
import { DelegacionDto } from "../../domain/model/Delegacion";
import { useLoading } from "../../context/Loading.Context";
import UnidadesMedidaRepository from "../../domain/UnidadesMedidaRepository";
import UnidadesMedidaApiRepository from "../../infraestructure/api/UnidadesMedida.ApiRepository";
import { UnidadMedidaDto } from "../../domain/model/UnidadMedida";
import ElementoTipoRepository from "./domain/ElementoTipoRepository";
import ElementoTipoApiRepository from "./infrastructure/api/ElementoTipo.Api.Repository";
import {
  COD_DELEGACIONES_CON_TODAS,
  USER_ROLES,
} from "../../assets/constants/Constantes";
import { VentanaPermiso } from "../Login/domain/model/VentanaPermiso";

interface Props {
  permisoVentana: VentanaPermiso;
}

const EquiposElementosEdicion = ({ permisoVentana }: Props) => {
  const location = useLocation();
  const url = location.pathname.split("/");
  const selectedElementoId = url[url.length - 1];
  const { getToken, hasRole, getDelegacion } = useContext(AuthContext);
  const [permisoModificacion, setPermisoModificacion] = useState<boolean>(
    permisoVentana.permisoModificacion
  );
  const [optionsDelegaciones, setOptionsDelegaciones] = useState<
    DelegacionDto[]
  >([]);
  const [optionsElementoTipo, setOptionsElementoTipo] = useState<
    UnidadObraElementoTipo[]
  >([]);
  const [selectedDelegacion, setSelectedDelegacion] = useState<DelegacionDto>({
    id: "",
    descripcion: "",
  });
  const [selectedElementoTipo, setSelectedElementoTipo] =
    useState<UnidadObraElementoTipo>({} as UnidadObraElementoTipo);
  const [optionsMedidas, setOptionsMedidas] = useState<UnidadMedidaDto[]>([]);
  const { setLoading } = useLoading();
  const formId = "elementos-edit-form";
  const equiposRepo: EquiposRepository = new EquipoApiRepository(getToken());
  const [elemento, setElemento] = useState<ElementoEquipoUpdateDto>({
    id: "",
    delegacionId: -1,
    unidadObraElementoTipoId: 3,
    nombre: "",
    descripcion: "",
    tipoUnidad: "",
    tarifa: "0",
  });

  useEffect(() => {
    fetchMedidas();
    fetchElementoTipos();
    fetchDelegaciones();
  }, [getToken]);

  const fetchDelegaciones = useCallback(() => {
    const repo: DelegacionesRepository = new DelegacionesApiRepository(
      getToken()
    );
    return repo
      .fetchDelegaciones({
        filter: {
          ids: COD_DELEGACIONES_CON_TODAS,
        },
      })
      .then((resp) => {
        setOptionsDelegaciones(resp);
      });
  }, [getToken]);

  const fetchElementoTipos = useCallback(() => {
    const repo: ElementoTipoRepository = new ElementoTipoApiRepository(
      getToken()
    );
    return repo.fetchTipos().then((resp) => {
      setOptionsElementoTipo(resp.filter((x) => x.id == 3 || x.id == 5));
      getElementoById(selectedElementoId, resp);
    });
  }, [getToken]);

  const fetchMedidas = useCallback(() => {
    const repo: UnidadesMedidaRepository = new UnidadesMedidaApiRepository(
      getToken()
    );
    return repo
      .getConFiltro({})
      .then((data) => {
        setOptionsMedidas(data);
      })
      .catch((message) => toast.error(message));
  }, []);

  const handleClickDelegaciones = (delegacion: DelegacionDto) => {
    if (selectedDelegacion.id !== delegacion.id) {
      setSelectedDelegacion(delegacion);
      setElemento({ ...elemento, delegacionId: +delegacion.id });
    } else {
      setSelectedDelegacion({ id: "", descripcion: "" });
    }
  };

  const handleClickTipoElemento = (tipoElemento: UnidadObraElementoTipo) => {
    if (selectedElementoTipo.id !== tipoElemento.id) {
      setSelectedElementoTipo(tipoElemento);
      setElemento({ ...elemento, unidadObraElementoTipoId: tipoElemento.id });
    } else {
      setSelectedElementoTipo({} as UnidadObraElementoTipo);
    }
  };

  const getElementoById = useCallback(
    (id: string, elementoTipos: UnidadObraElementoTipo[]) => {
      setLoading(true);
      return equiposRepo
        .getElementoById(id)
        .then((res) => {
          setElemento(res);
          setSelectedDelegacion({
            id: res.delegacionId.toString(),
            descripcion: res.descripcion,
          });
          setSelectedElementoTipo(
            elementoTipos.find((x) => x.id == +res.unidadObraElementoTipoId)!
          );
          // Si el usuario no es Admin puede ver datos de todas las delegaciones pero sólo crear y editar datos de su delegación
          setPermisoModificacion(
            permisoModificacion &&
              (hasRole(USER_ROLES.ADMIN) ||
                res.delegacionId === +getDelegacion()?.id)
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  const updateEquipoElemento = useCallback((data: ElementoEquipoUpdateDto) => {
    setLoading(true);
    equiposRepo
      .updateElemento(data)
      .then((res) => {
        toast.success("Elemento editado con éxito");
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (+elemento.tarifa <= 0) {
      toast.error("La tarifa introducida no puede ser menor o igual a 0");
      return;
    }
    updateEquipoElemento(elemento);
    setElemento(elemento);
  };

  const handleElemento = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setElemento((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleSelectMedida = (unidadMedida: UnidadMedidaDto) => {
    const medidaObj: UnidadMedidaDto | undefined = optionsMedidas.find(
      (item: UnidadMedidaDto) => item.id === unidadMedida.id
    );
    setElemento((prev) => ({
      ...prev,
      unidadMedidaId: medidaObj ? +medidaObj.id : 1,
    }));
  };

  return (
    <div className="add-edit-view-container">
      <div className="add-edit-header">
        <h4>{permisoModificacion ? "Editar " : ""}Elemento de Equipos</h4>
      </div>
      <div className="add-edit-body">
        <FormTemplate
          formId={formId}
          onSubmit={handleSubmit}
          backUrl={false}
          readOnly={!permisoModificacion}
        >
          <div className="row-of-two">
            <CustomTextInput
              label="Nombre"
              name="nombre"
              value={elemento.nombre}
              onChange={handleElemento}
              required
            />

            <FormField<DropdownProps>
              label="Delegación"
              options={optionsDelegaciones
                .filter(
                  (x) =>
                    hasRole(USER_ROLES.ADMIN) || +x.id === elemento.delegacionId
                )
                .map((element) => {
                  return {
                    text: element.descripcion,
                    id: element.id,
                    selected:
                      selectedDelegacion.id == element.id ? true : false,
                    onClick: () => {
                      handleClickDelegaciones(element);
                    },
                  };
                })}
              required={true}
              disabled={false}
              singleSelection={true}
              component={Dropdown}
            />

            <CustomTextInput
              label="Descripción"
              name="descripcion"
              value={elemento.descripcion}
              onChange={handleElemento}
              required
            />

            {optionsElementoTipo.length > 0 && (
              <FormField<DropdownProps>
                label="Tipo"
                options={optionsElementoTipo.map((element) => {
                  return {
                    text: element.descripcion,
                    id: element.id.toString(),
                    selected:
                      selectedElementoTipo.id == element.id ? true : false,
                    onClick: () => {
                      handleClickTipoElemento(element);
                    },
                  };
                })}
                required={true}
                disabled={false}
                singleSelection={true}
                component={Dropdown}
              />
            )}

            <CustomTextInput
              label="Tarifa"
              name="tarifa"
              type="number"
              onKeyDown={(e) => {
                if (e.key.toUpperCase() === "E" || e.key === "-") {
                  e.preventDefault();
                }
              }}
              value={elemento.tarifa}
              onChange={handleElemento}
              required
            />

            <FormField<DropdownProps>
              label="Unidad Medida"
              options={optionsMedidas.map((element) => {
                return {
                  text: element.descripcion,
                  id: element.id,
                  selected:
                    elemento.unidadMedidaId == +element.id ? true : false,
                  onClick: () => {
                    handleSelectMedida(element);
                  },
                };
              })}
              required={true}
              disabled={false}
              singleSelection={true}
              component={Dropdown}
            />
          </div>
        </FormTemplate>
      </div>
    </div>
  );
};

export default EquiposElementosEdicion;
