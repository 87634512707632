
export type ClausulaFilter = {
    nombre: string,
    descripcion: string,
};

interface ClausulaEntity {
    id: string | undefined;
    nombre: string;
    descripcion: string;
    delegacionId: number | null | undefined;
    delegacionNombre: string | null | undefined;
    orden: number | null | undefined;
    negrita: boolean | null;
}

export default class Clausula implements ClausulaEntity {
    id: string;
    nombre: string;
    descripcion: string;
    delegacionId: number | null | undefined;
    delegacionNombre: string | null | undefined;
    negrita: boolean | null;
    orden: number | null | undefined;

    constructor(entity: ClausulaEntity) {
        this.id = entity.id != null ? entity.id : "";
        this.nombre = entity.nombre;
        this.descripcion = entity.descripcion;
        this.delegacionId = entity.delegacionId;
        this.delegacionNombre = entity.delegacionNombre != "" ? this.delegacionNombre : "Todas";
        this.orden = entity.orden;
        this.negrita = entity.negrita;
        
    }

    public static toArray(entities: ClausulaEntity[]): Clausula[] {
        return entities.map(entity => {return new Clausula(entity)})
    }
}

export interface ClausulaCreateDto {
    descripcion: string, 
    nombre: string,
    delegacionId: number | null,
    orden: number | null,
    negrita: boolean | null,
    errors?: string[]
}

export interface ClausulaUpdateDto extends Clausula {
    errors?: string[]
    lastModificationTime?: string;
    lastModificationUsername?: string;
}

export interface ClausulaReadDto extends Clausula {
    errors: string[]
    delegacionNombre: string;
    clienteNombre: string;
    isDeleted: boolean;
    deletionUsername: string;
    lastModificationTime?: string;
    lastModificationUsername?: string;
}