import { BaseApiRequest } from "@pavabits/components";
import BaseResponse from "../../../../domain/BaseResponse";
import { ClienteEndpoints } from "../oportunidades.api-endpoints";
import { ClienteEntityDto } from "../../domain/model/ClienteEntity";

export default class ClienteDetalleRequestById extends BaseApiRequest<
{},
ClienteEntityDto
> {
	public constructor(id: number, token: string) {
		super(
			"GET",
			ClienteEndpoints.CON_ID + id,
			{},
			{ Authorization: `${token}`},
			undefined,
		)
	}
}