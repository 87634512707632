import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { USER_ROLES } from "../../../../assets/constants/Constantes";
import ModalPage from "../../../../components/ui/atoms/ModalPage/ModalPage";
import { useLoading } from "../../../../context/Loading.Context";
import { CustomButton } from "../../../../shared/components/Buttons/CustomButton";
import { AuthContext } from "../../../Login/AuthContextProvider";
import { VentanaPermiso } from "../../../Login/domain/model/VentanaPermiso";
import PresupuestoApiRepository from "../../../Presupuestos/Infraestructure/api/Presupuesto.ApiRepository";
import PresupuestoRepository from "../../../Presupuestos/domain/PresupuestoRepository";
import Presupuesto from "../../../Presupuestos/domain/model/Presupuesto";
import OfertasRepository from "../../domain/OfertasRepository";
import { EstadoOfertaEnum, OfertasCreationDto, OfertasDto } from "../../domain/model/Ofertas";
import OfertasApiRepository from "../../infraestructure/api/Ofertas.ApiRepository";
import OfertasFormTemplateEdit from "../common/Ofertas.FormTemplate.Edit";

interface Props {
	permisoVentana: VentanaPermiso
}

const OfertasEdicion: React.FC<Props> = ({ permisoVentana }: Props) => {
	const { getToken, getDelegacion, hasRole } = useContext(AuthContext);
	const [permisoModificacion, setPermisoModificacion] = useState<boolean>(permisoVentana.permisoModificacion);
	const { setLoading } = useLoading();
	const location = useLocation();
	const url = location.pathname.split("/")
	const id = url[url.length - 1];
	const [oferta, setOferta] = useState<OfertasDto>();
	const [tab, setTab] = useState(0)
	const [openUpdateToneladasOportunidad, setOpenUpdateToneladasOportunidad] = useState<boolean>(false);

	// datos que vienen de la edicion
	const [ofertaSave, setOfertaSave] = useState<OfertasCreationDto>();
	const [presupuestoSave, setPresupuestoSave] = useState<Presupuesto>();	

	const repo: OfertasRepository = useMemo(() => {
		return new OfertasApiRepository(getToken());
	}, []);

	const fetchOferta = (id: string) => {
		setLoading(true);
		repo.getById(id)
			.then(resp => {
				if (resp.lastModificationTime == undefined) {
					resp.lastModificationTime = "";
					resp.lastModificationUsername = "";
				}
				setOferta(resp);
				// Si el usuario no es Admin puede ver datos de todas las delegaciones pero sólo crear y editar datos de su delegación
				setPermisoModificacion(permisoModificacion && (hasRole(USER_ROLES.ADMIN) || resp.delegacionId === +getDelegacion()?.id));
			}
			)
			.finally(() => setLoading(false));
	};

	const handleRefreshOferta = (tabIndex: number) => {
		setOferta(undefined);
		fetchOferta(id);
		setTab(tabIndex);
	}

	useEffect(() => { // Side Effect
		fetchOferta(id)
	}, [id]);

	const updateOferta = (data: OfertasCreationDto, updateToneladasOportunidad: boolean, presupuesto?: Presupuesto) => {
		if (data.estadoOfertaId == EstadoOfertaEnum.Contratada) {
			if (!presupuesto) {
				toast.info("Selecciona el presupuesto contratado");
				return;
			} else {
				data.numeroPresupuesto = presupuesto ? (data.codigo + "/" + presupuesto.presupuestoId + "-" + presupuesto.presupuestoVersion) : "";
				data.presupuestoContratadoId = presupuesto?.id;
				data.conctactoId = presupuesto?.presupuestoContactos?.[0]?.contactoId;
			}

			// DESARROLLO-5106: Si la oferta es contratada, se actualiza el campo de toneladas de la oportunidad
			updateToneladasOportunidad = true;
		}

		setLoading(true);
		if (presupuesto) {
			const presupuestoRepo: PresupuestoRepository = new PresupuestoApiRepository(getToken());
			if (presupuesto.id) {
				setLoading(true);
				
				if (data.estadoOfertaId == EstadoOfertaEnum.Ofertada) {
					presupuestoRepo.simpleUpdate({...presupuesto}).then((resp) => {
						toast.success('Presupuesto actualizado correctamente');
					}).finally(() => {
						repo.updateOfertas(data)
						.then((resp) => {
							if (resp.errors) {
								toast.error(resp.errors?.join(', '));
							}
							else {
								toast.success("Oferta actualizada con éxito");
								repo.getById(id)
									.then(resp => {
										if (resp.lastModificationTime == undefined) {
											resp.lastModificationTime = "";
											resp.lastModificationUsername = "";
										}
										setOferta(resp);
									}
									)
									.finally(() => {

									});
							}
						})
						.catch(error => {
							toast.error(error);
						})
						.finally(() => {
							setLoading(false);
							handleRefreshOferta(tab);
						});
						})
				} else {
				
					presupuestoRepo.simpleUpdate({ ...presupuesto, contratado: true }).then((resp) => {
						toast.success('Presupuesto actualizado correctamente');
						handleRefreshOferta(tab);
					}).finally(() => {
						repo.updateOfertas(updateToneladasOportunidad ? { ...data, oportunidadToneladas: data.toneladas } : data)
							.then((resp) => {
								if (resp.errors) {
									toast.error(resp.errors?.join(', '));
								}
								else {
									toast.success("Oferta actualizada con éxito");
									repo.getById(id)
										.then(resp => {
											if (resp.lastModificationTime == undefined) {
												resp.lastModificationTime = "";
												resp.lastModificationUsername = "";
											}
											setOferta(resp);
										}
										)
										.finally(() => {

										});
								}
							})
							.catch(error => {
								toast.error(error);
							})
							.finally(() => {
								setLoading(false);
								handleRefreshOferta(tab);
								setOpenUpdateToneladasOportunidad(false);
							});
						setLoading(false);
					});
				}}
		} else {

			repo.updateOfertas(updateToneladasOportunidad ? { ...data, oportunidadToneladas: data.toneladas } : data)
				.then((resp) => {
					if (resp.errors) {
						toast.error(resp.errors?.join(', '));
					}
					else {
						toast.success("Oferta actualizada con éxito");
						repo.getById(id)
							.then(resp => {
								if (resp.lastModificationTime == undefined) {
									resp.lastModificationTime = "";
									resp.lastModificationUsername = "";
								}
								setOferta(resp);
								handleRefreshOferta(tab);
							})
							.finally(() => {

							});
					}
				})
				.catch(error => {
					toast.error(error);
				})
				.finally(() => {
					setLoading(false);
					setOpenUpdateToneladasOportunidad(false);
				});
		}
	}

	const fetchUpdate = useCallback((data: OfertasCreationDto, presupuesto?: Presupuesto) => {
		if (data.estadoOfertaId !== EstadoOfertaEnum.Contratada && data.estadoOfertaId !== EstadoOfertaEnum.Perdida &&
			data.oportunidadToneladas != 0 && +data.toneladas !== data.oportunidadToneladas
			&& (data.licitacionEstado == undefined || (data.licitacionEstado != undefined && data.licitacionEstado > 2))) {
			setOfertaSave(data);
			setPresupuestoSave(presupuesto);
			setOpenUpdateToneladasOportunidad(true);

		} else {
			updateOferta(data, false, presupuesto);

		}
	}, []);

	return (
		<div className='add-edit-view-container'>
			<div className='add-edit-header'>
				<h4>{permisoModificacion ? "Editar " : ""}Oferta (Código: {oferta?.codigo})</h4>
			</div>
			{oferta &&
				<>
					<OfertasFormTemplateEdit
						ofertas={oferta}
						onSubmit={fetchUpdate}
						handleRefreshOferta={handleRefreshOferta}
						tab={tab}
						readOnly={!permisoModificacion}
					/>

					{openUpdateToneladasOportunidad &&
						<ModalPage
							modalTitle="Actualizar toneladas de oportunidades"
							bodyClassName="--medium"
							modalSubtitle={""}
							handleCloseModal={() => { setOpenUpdateToneladasOportunidad(false) }}
							opened={openUpdateToneladasOportunidad}
							fields={

								<>
									<p> Las toneladas se van a actualizar en la Oportunidad (y no en el resto de las ofertas relacionadas) ¿quiere continuar con el cambio?</p>
									<p> Se van a actualizar las actuales toneladas de la oportunidad:<strong> {ofertaSave?.oportunidadToneladas} </strong> con las de la oferta <strong>{ofertaSave?.toneladas}.</strong></p>
									<p>	Si elige que no, solo se actualizaran las toneladas de la actual oferta.</p>
									<div className="bottom-buttons">
										<CustomButton
											key={`Actualizar toneladas oportunidades`}
											title={'SI'}
											onButtonClick={() => { updateOferta(ofertaSave!, true, presupuestoSave) }}
											text="Si"
										/>
										<CustomButton
											key={`Actualizar toneladas oportunidades`}
											title={'NO'}
											onButtonClick={() => { updateOferta(ofertaSave!, false, presupuestoSave) }}
											text="No"
										/>
										<CustomButton
											key={`Actualizar toneladas oportunidades`}
											title={'Cancelar'}
											onButtonClick={() => { setOpenUpdateToneladasOportunidad(false) }}
											text="Cancelar"
										/>
									</div>
								</>
							}

						>
						</ModalPage>
					}
				</>

			}
		</div>
	)
}

export default OfertasEdicion;