import { BaseApiRequest } from "@pavabits/components";
import BaseReponse from "../../domain/BaseResponse";
import { CodigoPostalEndpoints } from "../../modules/Oportunidades/infraestructure/oportunidades.api-endpoints";
import { CodigoPostalDetalleDto, CodigoPostalDto, CodigoPostalFilter } from "../../domain/model/CodigoPostal";
import ApiRequest from "../../domain/ApiRequest";

export default class CodigoPostalRequestById extends ApiRequest<undefined,CodigoPostalDetalleDto>
{
    public constructor (id : string, token : string){
        super(
            "GET",
            CodigoPostalEndpoints.CODIGDOPOSTAL_BY_ID + id,
            { Authorization: token },
            undefined,
            { }
        )
    }
}