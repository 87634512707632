import { BaseRepository } from "@pavabits/components";
import ContratoRepository from "../../domain/ContratoRepository";
import Contrato from "../../domain/model/Contrato";
import TipoFormaPagoContrato from "../../domain/model/TipoFormaPagoContrato";
import ContratoRequestAdd from "../ws/Contrato.Request.Add";
import ContratoRequestUpdate from "../ws/Contrato.Request.Update";


export default class ContratoApiRepository
	extends BaseRepository<
	Contrato,
	Contrato

	> implements ContratoRepository {
	protected transformDtoIntoModel(dto: Contrato): Contrato {
		return dto;
	}

	buildModelFromItems(dtoItems: TipoFormaPagoContrato): TipoFormaPagoContrato {
		return dtoItems;
	}


	
	



	async add( contrato:Contrato): Promise<Contrato> {
		const res = await new ContratoRequestAdd(contrato, this.authToken).executeAsPromise();
		return res;
	}

		async update(contrato:Contrato): Promise<Contrato> {
			const res = await new ContratoRequestUpdate(contrato, this.authToken).executeAsPromise();
		return res;
	}


/*
	async add(data: Estudio): Promise<Estudio> {
		const res = await new EstudioAdd(data, this.authToken).executeAsPromise();
		return res;
	}

	async update(data: Estudio): Promise<Estudio> {
		return await new EstudioUpdate(data, this.authToken).executeAsPromise();
	}

	async delete(id: string): Promise<boolean> {
		await new EstudioDelete(id, this.authToken).executeAsPromise();
		return true;
	}

	async getById(id: string): Promise<any> {
		const res = await new EstudioRequestGetById(id, this.authToken).executeAsPromise();
		return res;
	}

	async getByOfertaId(id: string): Promise<Estudio[]> {
		const res = await new EstudioRequestGetByOfertaId(id, this.authToken).executeAsPromise();
		return Estudio.toArray(res?.items);
	}

	async getByPresupuestoId(id: string): Promise<Estudio[]> {
		const res = await new EstudioRequestGetByPresupuestoId(id, this.authToken).executeAsPromise();
		return Estudio.toArray(res?.items);
	}

	async updateTarifas(id: string): Promise<EstudioDetalle[]> {
		const res = await new EstudioUpdateTarifas(id, this.authToken).executeAsPromise();
		return EstudioDetalle.toArray(res);
	}

	buildModelFromItems(item: EstudioDto): Estudio {
		return new Estudio(item);
	}

	protected transformDtoIntoModel(dto: BaseResponse<EstudioDto>): BaseResponse<Estudio> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}*/
}