import Clausula, { ClausulaReadDto } from "../../../Clausulas/domain/model/Clausula";

interface IClausulaOferta {
    id: string | undefined;
    ofertaId: string;
    clausulaId?: string;
    orden: number;
    negrita: boolean;
    nombre: string;
    descripcion: string;    
    clausula?: Clausula;
    lastModificationTime?: string;
}

export default class ClausulaOferta implements IClausulaOferta {
    id: string | undefined;
    ofertaId: string;
    clausulaId?: string;
    orden: number;
    negrita: boolean;
    nombre: string;
    descripcion: string;    
    clausula?: Clausula;
    lastModificationTime?: string;
    
    constructor(entity: IClausulaOferta) {
        this.id = entity.id != null ? entity.id : "";
        this.nombre = entity.nombre;
        this.descripcion = entity.descripcion;
        this.ofertaId = entity.ofertaId;
        this.clausulaId = entity.clausulaId;
        this.orden = entity.orden;
        this.negrita = entity.negrita;   
        this.clausula = entity.clausula;
        this.lastModificationTime = entity.lastModificationTime;
    }

    public static toArray(entities: IClausulaOferta[]): ClausulaOferta[] {
        return entities.map(entity => {return new ClausulaOferta(entity)})
    }
}

export interface ClausulaOfertaCreateDto {
    ofertaId: string;
    clausulaId?: string;
    orden: number;
    negrita: boolean;
    nombre: string;
    descripcion: string;
    errors?: string[]
}

export interface ClausulaOfertaUpdateDto extends ClausulaOferta {
    errors?: string[]
    lastModificationTime?: string;
    lastModificationUsername?: string;
}

export interface ClausulaOfertaReadDto extends ClausulaOferta {
    errors?: string[]    
    creationTime?: string;
    creationUsername?: string;    
    lastModificationTime?: string;
    lastModificationUsername?: string;
}