import { BaseEntity } from "@pavabits/components";

export interface SelectableItemDto {
	id: string
	descripcion: string
}

export default class SelectableItem extends BaseEntity {
	constructor (
		readonly id: string,
		private readonly _descripcion: string,
	) {
		super(id, _descripcion);
	}

	get descripcion() {
		return this._descripcion;
	}
}