import { useContext } from "react";
import TagCloud from "../../../../components/ui/objects/TagCloud/TagCloud";
import { TagsReadContext } from "../../../Oportunidades/context/FilterFields.Context";

const OfertasTagCloud: React.FC<{}> = () => {

	const { tags } = useContext(TagsReadContext);

	return (
		<TagCloud tags={tags} />
	)
}

export default OfertasTagCloud;