import { Icon } from "@pavabits/components";
import { ReactNode, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap"
import "./ModalPage.scss";
import IconButton from "../Buttons/IconButton";

interface Props {
	fields?: ReactNode
	titleShow?: string,//Más filtros
	modalTitle?: string, //Selección de filtros
	modalSubtitle?: string //Introduzca los parámetros para filtrar
	opened?: boolean
	modalDisable?: boolean //Para poner en modal en modo visualizacion
	handleCloseModal: () => void
	className?: string
	bodyClassName?: string
	handleCloseModalCustom?: () => void
	idtest?: string
}

const ModalPage: React.FC<Props> = ({ fields, titleShow, modalTitle, modalSubtitle, opened, handleCloseModal, handleCloseModalCustom, modalDisable, className, bodyClassName, idtest }) => {

	const [open, setOpen] = useState<boolean>(opened ?? false);

	return (
		<>
			{/* <button type="button"
				className="open-btn"
				onClick={() => setOpen(true)}
			>
				<Icon icon="add" />{titleShow}
			</button> */}
			<Modal
				className={className ?? ""}
				show={open}
				onHide={() => handleCloseModal()}
				backdrop="static"
				keyboard={false}>
				<ModalHeader>
					<ModalTitle
						data-cy="TituloModal">
						{modalTitle}
					</ModalTitle>
					<IconButton
						icon="close"
						onClick={() => handleCloseModalCustom != undefined ? handleCloseModalCustom() : handleCloseModal()}
					/>
				</ModalHeader>
				{modalSubtitle && <ModalTitle className="modal-title--subtitle">
					{modalSubtitle}
				</ModalTitle>}
				<ModalBody className={bodyClassName ? "modal-content" + bodyClassName : ""}>
					<div className={modalDisable ? "disable-modal" : "flex-section"}>
						{fields}
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}

export default ModalPage;