import React, { useContext, useMemo } from 'react'
import { debounce } from "lodash";
import FormFieldGroup from '../../../../components/ui/objects/FormFieldGroup/FormField.Group'
import { CodigoOfertaFieldContext } from '../../context/FilterFields.Context'

interface CodigoOfertaInputProps {
	  idTest?:string
}

const CodigoOfertaInput : React.FC<CodigoOfertaInputProps> = ({idTest}) => {

    const field = useContext(CodigoOfertaFieldContext);

    const debouncedSearch = useMemo(() => { 
		
		const baseTag = {
			label: "Código Oferta",
			onClear: () => {},
		}

		function handleClear() {
			field.onClear();
			field.onFilter({ ...baseTag, text: undefined });
		}

		const handleFilterChange = (value: string) => {
			field.onSearch(value); 
			field.onFilter({...baseTag, text: value, onClear: handleClear})
		};

		return debounce((value: string) => handleFilterChange(value), 1000)
	}, [field]);

    const handleInputChange = (e: any) => {
		const value = e.target.value;
		debouncedSearch(value);
	}

  return (
    <FormFieldGroup>
		<div className="input-container">
    <label> Código Oferta</label>
    <input className="op-input form-control" 
           placeholder=""
           onChange={handleInputChange}
		   data-cy={idTest}
    >
    </input>
	</div>
</FormFieldGroup>
  )
}

export default CodigoOfertaInput