import classNames from 'classnames';
import { FC, ReactElement, useState } from 'react';
import useComponentVisible from '../../../../hooks/useComponentVisible';

import '../scss/Dropdown.scss';

export enum DropdownPosition {
    LEFT = 'left',
    RIGHT = 'right',
}

export interface DropdownProps {
    dropdownContent: ReactElement;
    position?: DropdownPosition;
    classRoot?: string;
    classRootContent?: string;
    idCypress?: string
}


const Dropdown: FC<DropdownProps> = ({
    children,
    dropdownContent,
    position = DropdownPosition.LEFT,
    classRoot,
    classRootContent,
    idCypress
}) => {
    const [visible, setVisible] = useState<boolean>(false);

    const hideDropdown = () => {
        setVisible(false);
    };

    const handleClick = () => {
        setVisible(!visible);
    };

    const { ref } = useComponentVisible(hideDropdown);

    return (
        <div
            ref={ref}
            className={!!classRoot ? classRoot : 'dropdownInline'}
            data-cy={idCypress}
            onClick={handleClick}
        >
            <div className='dropdownInline__label'>{children}</div>
            <div
                data-cy={'dropdownInline__content'}
                //onClick={() => setVisible(false)}
                className={classNames(
                    'dropdownInline__content',
                    `dropdownInline__content--${position}`,
                    visible ? 'dropdownInline__content--visible' : 'dropdownInline__content--hide',
                    classRootContent
                )}
            >
                {dropdownContent}
            </div>
        </div>
    );
};

export default Dropdown;
