import Sidebar from "../../molecules/Sidebar/Sidebar";
import { useState } from "react";
import { Icon } from "@pavabits/components";
import HistoryProvider from "../../../../context/History.Provider";

const Dashboard: React.FC<{}> = ({ children }) => {

	const [hiddenSidebar, setHiddenSidebar] = useState<boolean>(false);
	const onHideSidebar = () => setHiddenSidebar(true);
	const onDisplaySidebar = () => setHiddenSidebar(false);

	const classes = () => { return ["dashboard", hiddenSidebar ? "dashboard--hidden-sidebar" : ""].join(" ").trimEnd() }
	return (
		<HistoryProvider>
			<div className={classes()}>
				<Sidebar isSidebarHidden={hiddenSidebar} onHideSidebar={onHideSidebar} />
					{hiddenSidebar &&
						<div className="open-sidebar">
							<Icon icon="navigate_next" onClick={onDisplaySidebar} />
						 </div>
					}
				<div className="board">
					{children}
				</div>
			</div >
		</HistoryProvider>
	)
}

export default Dashboard;