import { BaseApiRequest } from "@pavabits/components";
import { SucursalDto, SucursalFilter } from "../../domain/model/Sucursal";
import BaseResponse from "../../domain/BaseResponse";
import { SucursalEndpoints } from "../../modules/Oportunidades/infraestructure/oportunidades.api-endpoints";
import ApiRequest from "../../domain/ApiRequest";


export default class SucursalesRequestById extends ApiRequest<undefined,SucursalDto>
{
    public constructor (id : string, token : string){
        super(
            "GET",
            SucursalEndpoints.SUCURSAL_BY_ID + id,
            { Authorization: token },
            undefined,
            { }
        )
    }
}