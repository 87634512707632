import { BaseApiRequest } from "@pavabits/components";
import { ContratistaDto, ContratistaFilter } from "../../domain/model/Contratista";
import BaseReponse from "../../../../domain/BaseResponse";
import { ContratistasEndpoints } from "../oportunidades.api-endpoints";

export default class ContratistaRequestListBySearch extends BaseApiRequest<
ContratistaFilter,
BaseReponse<ContratistaDto[]>
> {
	public constructor(data: ContratistaFilter, token: string) {
		super(
			"POST",
			ContratistasEndpoints.CONTRATISTAS_CON_FILTRO,
			data,
			{ Authorization: `${token}`}
		)
	}
}