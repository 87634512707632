import { useCallback, useContext, useEffect, useState } from 'react'
import { GlobalContext, GlobalContextType } from '../../../context/Global.Context';
import DelegacionesRepository from '../../../domain/DelegacionesRepository';
import DelegacionesApiRepository from '../../../infraestructure/api/Delegaciones.ApiRepository';
import Delegacion from '../../../domain/model/Delegacion';
import FormField from '../../../components/ui/molecules/Form-field/FormField';
import Dropdown, { DropdownProps } from '../../../components/ui/atoms/Dropdown/Dropdown';
import FormFieldGroup from '../../../components/ui/objects/FormFieldGroup/FormField.Group';
import { AuthContext } from '../../../modules/Login/AuthContextProvider';

interface DelegacionDropdownFieldProps {
    componentName: string;
    userDelegacion?: string;
    disabled?: boolean;
    idTest?:string;
    delegaciones: Delegacion[];
}

const DelegacionDropdownField: React.FC<DelegacionDropdownFieldProps> = ({ componentName, userDelegacion, disabled, delegaciones, idTest }) => {
    const { globalState, updateGlobalState } = useContext(GlobalContext) as GlobalContextType;

    const [updatedValues, setUpdatedValues] = useState<Delegacion[]>(
        globalState[componentName]?.filters?.delegacionIds
            && globalState[componentName]?.filters?.delegacionIds.length > 0
            ? delegaciones?.filter(x => globalState[componentName]?.filters?.delegacionIds.includes(x.id))
            : [])

    useEffect(() => {
        updateGlobalState(componentName,
            {
                filters: {
                    ...globalState[componentName]?.filters,
                    "delegacionIds": updatedValues.map((elem) => { return elem.id }),
                    "delegacionNombre": updatedValues.map(
                        (elem) => {
                            return elem.text + ", "
                        })
                },
                pagination: {
                    skipCount: 0
                }
            }
        )
    }, [
        updatedValues,
        delegaciones
    ])

    const options = delegaciones?.map((delegacion: Delegacion, index) => {
        return {
            id: delegacion.id,
            text: delegacion.text,
            selected: globalState[componentName]?.filters?.delegacionIds?.includes(delegacion.id),
            onClick: () => {
                if (globalState[componentName]?.filters?.delegacionIds?.includes(delegacion.id)) {
                    setUpdatedValues(updatedValues.filter(del => del.id !== delegacion.id))
                } else {
                    setUpdatedValues([...updatedValues, delegacion])
                }
            }
        }
    })

    return (
        <div className='input-container' data-cy={idTest}>
            <FormFieldGroup>
                <label>Delegación</label>
                {options && <Dropdown
                    options={options}
                    singleSelection={false}
                    disabled={disabled}
                    label="Delegación"
                />}
            </FormFieldGroup>
        </div>
    )
}

export default DelegacionDropdownField