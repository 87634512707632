import React from 'react';
import { Icon } from '../../shared/components/Icon/Icon';

interface CustomCheckInputProps {
    checked?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    icon?: string;
    disabled?:boolean;
    idTest?:string;
}

const CustomCheckInput: React.FC<CustomCheckInputProps> = ({checked, onChange, icon, disabled, idTest}) => {
  return (
    <div className='check-input'>
    <input
    title={disabled ? "Estudio con presupuesto asociado" : "Seleccionar estudio para incluir en presupesto"}
    type="checkbox"
    checked={checked}
    onChange={onChange}
    disabled={disabled}
    data-cy={idTest}
    />
    {icon && <Icon icon={icon} />}
    </div>

  )
}

export default CustomCheckInput