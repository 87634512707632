import React, { useContext, useMemo } from "react";
import FormFieldGroup from "../../../../../components/ui/objects/FormFieldGroup/FormField.Group";
import { IdOfertaFieldContext } from "../../../context/ModalFields.Context";
import { debounce } from "lodash";

const IdOfertaInput: React.FC<{ idTest?: string }> = ({idTest}) => {
  const field = useContext(IdOfertaFieldContext);

  const debouncedSearch = useMemo(() => {
    const baseTag = {
      label: "Cod. Oferta",
      onClear: () => {},
    };

    function handleClear() {
      field.onClear();
      field.onFilter({ ...baseTag, text: undefined });
    }

    const handleFilterChange = (value: string) => {
      field.onSearch(value);
      field.onFilter({ ...baseTag, text: value, onClear: handleClear });
    };

    return debounce((value: string) => handleFilterChange(value), 1000);
  }, [field]);

  const handleInputChange = (e: any) => {
    const value = e.target.value.trim() == "" ? 0 : e.target.value;
    debouncedSearch(value);
  };

  return (
    <div className="input-container">
      <FormFieldGroup>
        {idTest == "CodOfertaFiltro" && <label>Código Oferta</label>}
        <input
          // className="op-input form-control"
          placeholder="Cod. Oferta"
          onChange={handleInputChange}
          data-cy={idTest}
        ></input>
      </FormFieldGroup>
    </div>
  );
};

export default IdOfertaInput;
