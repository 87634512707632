import ApiRequest from "../../../../domain/ApiRequest";
import { PresupuestoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import Presupuesto, { PresupuestoUpdateDto } from "../../domain/model/Presupuesto";

export default class PresupuestoAdd extends ApiRequest<
PresupuestoUpdateDto,
Presupuesto
>
{
	constructor(data: PresupuestoUpdateDto, token: string) {
		super(
			"POST",
			PresupuestoApiEndpoints.CREAR,
			{ Authorization: `${token}`},
			data,
		)
	}
}