import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import Constantes from "../../../../assets/constants/Constantes";
import CustomCheckInput from "../../../../components/common/CustomCheckInput";
import TooltipComponent from "../../../../components/ui/molecules/Tooltip/TooltipComponent";
import { useLoading } from "../../../../context/Loading.Context";
import { DirtyForm } from "../../../../domain/model/DirtyForm";
import { CustomButton } from "../../../../shared/components/Buttons/CustomButton";
import CustomInput, { CustomInputProps } from "../../../../shared/components/Inputs/CustomInput/CustomInput";
import { GridColumn } from "../../../../types";
import { AuthContext } from "../../../Login/AuthContextProvider";
import ContactosRepository from "../../domain/ContactosRepository";
import SeguimientoClienteRepository from "../../domain/SeguimientoClienteRepository";
import { ClienteDto } from "../../domain/model/Cliente";
import ContactoCliente from "../../domain/model/ContactosCliente";
import SeguimientoCliente from "../../domain/model/SeguimientoCliente";
import ContactoClienteApiRepository from "../../infraestructure/api/ContactosClientes.ApiRepository";
import SeguimientoClienteApiRepository from "../../infraestructure/api/SeguimientoCliente.ApiRepository.";
import DeleteModal from "../../../../shared/components/DeleteModal/DeleteModal";


interface Props extends DirtyForm {
    type: "edit" | "add"
    title: string
    handleOnSubmit: () => void
    cliente?: ClienteDto
    contactosClientes?: ContactoCliente[],
    idPadre: number,
    cabecera: GridColumn[]
    body: CustomInputProps[]
}

const ClientesView: FC<Props> = ({ type,
    handleOnSubmit,
    cliente,
    idPadre,
    title,
    cabecera,
    body,
    hadleDirtyForm

}) => {
    const ID_CLIENTE_PAVASAL = 100;
    //modal open
    //session
    const { getToken, username } = useContext(AuthContext);
    const { setLoading } = useLoading();

    const [contactosClientes, setContactosClientes] = useState<ContactoCliente[]>();
    const [seguimientoClientes, setSeguimientoClientes] = useState<SeguimientoCliente[]>();
    const [openDelete, setOpenDelete] = useState<boolean>(false);


    const fetchContactosClientes = useCallback(
        (id: number) => {
            const repo: ContactosRepository = new ContactoClienteApiRepository(getToken());
            return repo.getByClienteId(id).then((a) => {
                setContactosClientes(a);
                setContactosClientesForm(a);
                setContactosClientesIni(a);
            });
        }
        , [getToken]);

    const fetchSeguimientoClientes = useCallback(
        (id: number) => {
            const repo: SeguimientoClienteRepository = new SeguimientoClienteApiRepository(getToken());
            return repo.getByClienteId(id).then((a) => {
                setSeguimientoClientes(a);
            });
        }
        , [getToken]);

    useEffect(() => { // Side Effect
        if (idPadre != 0)
            fetchContactosClientes(idPadre != 0 ? idPadre : 0);
        fetchSeguimientoClientes(idPadre != 0 ? idPadre : 0);
    }
        , [fetchContactosClientes]);


    //delete
    const [deletionIndex, setDeletionIndex] = useState<number>(-1);
    const openConfirmationModal = (index: number) => {
        setDeletionIndex(index);
        setOpenDelete(true);
    }

    const handleCloseModalDelete = (remove: boolean) => {
        setOpenDelete(false);
        if (remove) {
            handleRemoveContacto(deletionIndex!);
        }
    }

    const handleRemoveContacto = (index: number) => {
        if (!contactosClientesForm[index].id) {
            contactosClientesForm.splice(index, 1);
            toast.success(`Eliminado correctamente;`);
            setUserAddingContact(false);
            setOpenDelete(false);
            hadleDirtyForm(false);
            return;
        }

        if (seguimientoClientes && seguimientoClientes.length > 0 &&
            seguimientoClientes?.find(x => x.contactoId == contactosClientesForm[index].id)) {
            toast.error("No se puede eliminar el contacto ya que esta asignado a un Seguimiento");
            return
        }

        const repo: ContactosRepository = new ContactoClienteApiRepository(getToken());
        setLoading(true);
        repo.delete(contactosClientesForm[index].id)
            .then((resp) => {
                toast.success(`Eliminado correctamente`);
                const aux = contactosClientesForm.filter(item => item.id !== contactosClientesForm[index].id);
                setContactosClientesForm(aux);
                setContactosClientesIni(aux);

            }).catch((ex) => {
                toast.success(`Error: ${ex}`);
            }).finally(() => { setLoading(false) });
        setOpenDelete(false);
    }

    const [userAddingContact, setUserAddingContact] = useState<boolean>(false);

    const [contactosClientesForm, setContactosClientesForm] = useState<ContactoCliente[]>([]);
    const [contactosClientesIni, setContactosClientesIni] = useState<ContactoCliente[]>([]);

    const [errores, setErrores] = useState<string[]>([]);
    const [lineSelected, SetLineSelected] = useState<number>();

    const contactsValidation = (index: number): boolean => {
        const errores: string[] = [];

        const contacto = contactosClientesForm[index];

        if (!contacto.personaContacto || contacto.personaContacto.length === 0) {
            errores[1] = 'Este campo es requerido';
        }

        if (contacto.cargo && contacto.cargo.length > Constantes.OBSERVACIONES_MAX_LENGTH) {
            errores[2] = 'Máximo 200 caracteres.';
        }

        if (contacto.mail && contacto.mail.length > 0) {
            if (!contacto?.mail.match(Constantes.EMAIL_FORMAT_REGEX)) {
                errores[4] = 'Indique un email válido';
            }
        }

        if (contacto.direccion && contacto.direccion.length > Constantes.OBSERVACIONES_MAX_LENGTH) {
            errores[5] = 'Máximo 200 caracteres.';
        }

        if (!contacto.nif && contacto.firmante) {
            errores[8] = 'Este campo es requerido';
        }

        if (contacto.nif && !contacto.nif.match(Constantes.DNI_REGEX)) {
            errores[8] = 'Indique un NIF válido';
        }

        setErrores(errores);

        return errores.length === 0;
    }

    //save
    const onSubmitContactForm = (i: number) => {
        const repo: ContactosRepository = new ContactoClienteApiRepository(getToken());
        let contacto = i > -1 ? contactosClientesForm[i] : undefined;

        if (contacto !== undefined) {
            SetLineSelected(i);
            if (contactsValidation(i)) {
                //update
                if (!!contacto?.id) {
                    repo.update(contacto as ContactoCliente)
                        .then((response) => {
                            const contactoUpdated = contactosClientesForm.map((contacto: ContactoCliente, j: number) => {
                                if (i === j) return { ...contacto, lastModificationTime: response.lastModificationTime };

                                return contacto;
                            });
                            setContactosClientesForm(contactoUpdated);
                            setContactosClientesIni(contactoUpdated);
                            toast.success(`Contacto ${contacto?.personaContacto} guardado con éxito`);
                        })
                        .catch(() => {
                            toast.error(`Error al guardar el contacto  ${contacto?.personaContacto ?? ''}`);
                        });
                }
                else //add
                {
                    repo.add(contacto as ContactoCliente)
                        .then((response) => {
                            toast.success(`Contacto ${contacto?.personaContacto} añadido con éxito`);

                            //recogemos el nuevo id, ya no es nuevo, es editable!!!
                            const personaCont = contactosClientesForm.map((contacto: ContactoCliente, j: number) => {
                                if (i === j) return { ...contacto, id: response.id, lastModificationTime: response.lastModificationTime };

                                return contacto;
                            });
                            setContactosClientesForm(personaCont);
                            setContactosClientesIni(personaCont);

                            //ya hemos guardado, dejamos que se añadan nuevos
                            setUserAddingContact(false);
                        })
                        .catch(() => {
                            toast.error(`Error al añadir el contacto  ${contacto?.personaContacto ?? ''}`);
                        });
                }
            }
        }
    }


    const { t } = useTranslation<['main']>(['main']);


    const handleAddContacto = () => {
        setUserAddingContact(true);
        let newContacto = {
            id: "",
            nombreCompleto: "",
            cargo: "",
            telefono: "",
            mail: "",
            usuario: username,
            municipio: "",
            direccion: "",
            personaContacto: "",
            clienteId: idPadre,
            zona: "",
            clienteNombre: "",
            lastModificationTime: "",
            lastModificationUsername: username,
            firmante: false,
            nif: ""
        }
        setContactosClientesForm([newContacto, ...contactosClientesForm]);
    }

    const handlePropertyChange = (property: string, newValue: string | number | boolean, i: number) => {
        const personaCont = contactosClientesForm.map((contacto: any, j: number) => {
            if (i === j) return { ...contacto, [property]: newValue, Usuario: username };

            return contacto;
        });
        setContactosClientesForm(personaCont);
    }

    const isDirty = (contacto: ContactoCliente): boolean => {
        if (contacto.id == undefined || contacto.id == '') {
            hadleDirtyForm(true);
            return true;
        }
        else {
            let contactoEncontrado = contactosClientesIni?.filter(p => p.id === contacto.id);
            const arrayProperties = ["nombreCompleto", "cargo", "telefono", "mail", "municipio", "direccion", "personaContacto", "clienteId", "zona", "firmante", "nif"];
            if (contactoEncontrado && contactoEncontrado.length > 0 && JSON.stringify(contactoEncontrado[0], arrayProperties) == JSON.stringify(contacto, arrayProperties)) {
                hadleDirtyForm(false);
                return false;
            }
            else {
                hadleDirtyForm(true);
                return true;
            }
        }
    }

    function capitalizeFirstLetter(texto:string | undefined) {
        if (!texto) return ''; // Verificar que no sea una cadena vacía
        // Convertir la primera letra en mayúscula y el resto en minúsculas
        return texto.charAt(0).toUpperCase() + texto.slice(1);
    }

    return (
        <>
            <div className="inline-grid">
                <div className="inline-grid-header">
                    {cabecera.map((cabecera: any) => {
                        return <label style={{ width: cabecera.width }}>{cabecera.columnName}</label>
                    })}
                    <div className="inline-button">
                        {!userAddingContact &&
                            <CustomButton
                                id={"add-btn"}
                                icon="add"
                                title="Añadir contacto"
                                className={"contact-btn"}
                                onButtonClick={() => { handleAddContacto() }}
                            />}
                    </div>
                </div>
                <div className="inline-grid-container">
                    {contactosClientesForm?.map((contacto: any, i: number) => {
                        return (
                            <>
                                <div className="inline-grid-item">
                                    {body.map((element: CustomInputProps, j: number) => {
                                        return element.id == "firmante" ?
                                            <div className='input-container' style={element.style}>
                                                <div className="inline-grid-checkbox">
                                                    <CustomCheckInput
                                                        key={`${element.id}-${i}-${j}`}
                                                        checked={contacto[element.id!]}
                                                        icon={contacto[element.id!] ? "check" : undefined}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePropertyChange(element.id!, e.target.checked, i)}
                                                        disabled={element.disabled}
                                                        idTest="CheckFirmanteContacto"
                                                    />
                                                </div>
                                            </div>
                                            : (element.id == "personaContacto"
                                                || element.id == "cargo"
                                                || element.id == "direccion")
                                                ?
                                                <div style={{ width: element.style?.width }}>
                                                    <TooltipComponent
                                                        text={contacto[element.id!]?.toString()}
                                                        children={
                                                            <CustomInput
                                                                key={`${element.id}-${i}-${j}`}
                                                                value={contacto[element.id!]?.toString()}
                                                                type={element.type}
                                                                className={'platform-input'}
                                                                formClassName={"secondary-input"}
                                                                onChange={(e) => { handlePropertyChange(element.id!, e, i); }}
                                                                error={lineSelected == i ? errores[j + 1] : ""}
                                                                disabled={idPadre === ID_CLIENTE_PAVASAL && contacto.firmante ? true : element.disabled}
                                                                idTest={capitalizeFirstLetter(element.id)}
                                                            />}
                                                    />
                                                </div>
                                        : <CustomInput
                                                key={`${element.id}-${i}-${j}`}
                                                value={contacto[element.id!]?.toString()}
                                                type={element.type}
                                                className={'platform-input'}
                                                formClassName={"secondary-input"}
                                                onChange={(e) => { handlePropertyChange(element.id!, e, i); }}
                                                error={lineSelected == i ? errores[j+1] : ""}
                                                disabled={idPadre === ID_CLIENTE_PAVASAL && contacto.firmante ? true : element.disabled}
                                                style={element.style}
                                                idTest={capitalizeFirstLetter(element.id)}
                                            />
                                    })
                                    }
                                    <div className="inline-buttons">
                                        <CustomButton
                                            key={`confirm-${i}`}
                                            title={'GuardarGrid'}
                                            icon={isDirty(contacto) ? 'save' : 'done'}
                                            className={'contact-btn'}
                                            onButtonClick={() => { onSubmitContactForm(i) }}
                                            type='button'
                                            disabled={idPadre === ID_CLIENTE_PAVASAL && contacto.firmante}
                                        />
                                        <CustomButton
                                            key={`remove-${i}`}
                                            title={'BorrarGrid'}
                                            icon={'delete'}
                                            className={'contact-btn remove'}
                                            onButtonClick={() => { openConfirmationModal(i) }}
                                            type='button'
                                            disabled={idPadre === ID_CLIENTE_PAVASAL && contacto.firmante}
                                        />
                                    </div>
                                </div>
                            </>
                        )
                    }
                    )}
                </div>

                {
                    openDelete &&
                    <DeleteModal
                        open={openDelete}
                        handleCloseModal={handleCloseModalDelete}
                        field="contacto"
                    >
                    </DeleteModal>
                }
            </div>
        </>
    )
}

export default ClientesView