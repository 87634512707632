import { PropsWithChildren } from "react";
import "./Card.scss";

type CardType = "background"
  | "container";

interface CardProps {
  type: CardType
  className?: string
  title?: string
}

/**
 * Componente funcional para envolver formularios y otros elementos.
 * @param props Propiedades admitidas
 * @returns Un elemento JSX
 */
const Card: React.FC<CardProps> = (props: PropsWithChildren<CardProps>) => {

  const { children, className, title, type } = props;

  return (
    // <div className={['card card-background', `card--${type}`, className].join(" ").trimEnd()}>
    //   <h3 className="card-header">{title}</h3>
    // <div className="card-container">
    <>
     {title && <h3>{title}</h3>}
      {children}
    </>
  )
}

export default Card;