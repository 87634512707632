import React, { useContext } from 'react'
import FormFieldGroup from '../../../components/ui/objects/FormFieldGroup/FormField.Group'
import DateSelector from '../../../components/ui/molecules/Date-selector/DateSelector'
import { GlobalContext, GlobalContextType } from '../../../context/Global.Context';

const SeguimientoClienteFechaDateSelectorField = () => {
	const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;
	const componentName = "seguimientoCliente";

    const onDateSelect = (date: string[]) => {
		updateGlobalState(componentName, {
			filters: {...globalState.seguimientoCliente?.filters,
				"fechaSeguimientoDesde":  date[0], "fechaSeguimientoHasta":  date[1]},
				pagination: {
					skipCount: 0
				  }
			})
	}

    return (
		<div className="input-container">
		<FormFieldGroup>
			<label>Fecha</label>			
			<DateSelector
				onDateSelect={onDateSelect}
				idTest='FiltroFechaSeguimientoCliente'
			/>
			
		</FormFieldGroup>
		</div>
	)
}

export default SeguimientoClienteFechaDateSelectorField