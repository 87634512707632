import SelectableItem, { SelectableItemDto } from "../../components/ui/atoms/SuggestionInput/model/SuggestionInput.Model";

export interface PlantaDto extends SelectableItemDto { descripcionCodigo: string, codigo: string; instalacionId: string };

export default class Planta extends SelectableItem { };

export type PlantaFilter = {
    filter: {
        descripcion?: string;
    }
};

