import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { TipoFormaPagoContratoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import TipoFormaPagoContrato from "../../domain/model/TipoFormaPagoContrato";

export default class TipoFromaPagoContratoRequestGetAll extends ApiRequest<string, BaseResponse<TipoFormaPagoContrato[]>>
{
	constructor( token: string) {
		super(
			"GET",
			TipoFormaPagoContratoApiEndpoints.ALL+"?MaxResultCount=99&SkipCount=0",
			{ Authorization: `${token}`},
			""
		)
	}
}