import ApiRequest from "../../../../domain/ApiRequest";
import { BaseFilter } from "../../../../domain/BaseFilter";
import BaseResponse from "../../../../domain/BaseResponse";
import { ContactoClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { ContactoFilter, ContactoReadDto } from "../../domain/model/Contacto";

export default class ContactosRequestListByFilter extends ApiRequest<
BaseFilter<ContactoFilter>,
BaseResponse<ContactoReadDto[]>>
{
	constructor(data: BaseFilter<ContactoFilter>, token: string) {
		super(
			"POST",
			ContactoClienteEndpoints.CON_FILTRO,
			{ Authorization: token },
			data,
		)
	}
}