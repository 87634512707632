import { useContext, useState } from "react";
import { toast } from "react-toastify";
import Constantes from "../../assets/constants/Constantes";
import FormInput from "../../components/ui/atoms/Input/FormInput/FormInput";
import { useLoading } from "../../context/Loading.Context";
import { CustomButton } from "../../shared/components/Buttons/CustomButton";
import ContactosRepository from "../Clientes/domain/ContactosRepository";
import { default as ContactoCliente, default as ContactosCliente } from "../Clientes/domain/model/ContactosCliente";
import ContactoClienteApiRepository from "../Clientes/infraestructure/api/ContactosClientes.ApiRepository";
import { AuthContext } from "../Login/AuthContextProvider";



interface ContactoModalCreateSectionProps {
    clienteId: string;
    idAnyadido?: string;
    handleCloseModal: (id: string) => void;
}




const ContactoModalCreateSection: React.FC<ContactoModalCreateSectionProps> = ({ clienteId, idAnyadido, handleCloseModal }) => {

    const { setLoading } = useLoading();
    const { getToken, username } = useContext(AuthContext);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [selectedContacto, setSelectedContacto] = useState<ContactosCliente>()
    const [newContacto, setNewContacto] = useState<ContactosCliente>({
        id: "",
        nombreCompleto: "",
        cargo: "",
        telefono: "",
        mail: "",
        usuario: username,
        municipio: "",
        direccion: "",
        personaContacto: "",
        clienteId: +clienteId,
        zona: "",
        clienteNombre:"",
        lastModificationTime: "",
        lastModificationUsername: username,
        firmante: false,
        nif: ""

    })

    const [errors, setErrors] = useState<string[]>([]);

    const anyadirContacto = () => {
        const repo: ContactosRepository = new ContactoClienteApiRepository(getToken());
        repo.add(newContacto as ContactoCliente)
            .then((response) => {
                toast.success(`Contacto ${newContacto?.personaContacto} añadido con éxito`);
                handleCloseModal(response.id);
            })
            .catch(() => {
                toast.error(`Error al añadir el contacto  ${newContacto?.personaContacto ?? ''}`);
                handleCloseModal("");
            });
      }

    function validarContacto() {
        let errores: string[] = []; 

        if (!newContacto?.personaContacto) {
            errores[0] = 'Campo obligatorio.'; 
        }

        if (!newContacto?.mail) {
            errores[1] = 'Campo obligatorio.'; 
        } else if (!RegExp(Constantes.EMAIL_FORMAT_REGEX).exec(newContacto?.mail)) {
            errores[1] = 'Indique un email válido';
        }

        setErrors(errores);

        return errores.length === 0;
    }

    return <>
    <div>
        <div className="flex-horizontal">
            <FormInput
                label="Contacto"
                value={newContacto?.personaContacto!}
                onChange={(e) => setNewContacto({...newContacto, personaContacto : e.target.value})}
                required
                error={errors[0]}
            />
            <FormInput
                label="Email del contacto"
                value={newContacto?.mail!}
                onChange={(e) => setNewContacto({...newContacto, mail: e.target.value,})}
                required
                error={errors[1]}
            />
            <CustomButton
                key={`AnyadirContacto`}
                title={'Añadir'}
                icon="save"
                className={'contact-btn'}
                btnStyle="accept"
                onButtonClick={() => { validarContacto() && anyadirContacto() }}
                type="button"
            /> 
        </div>
    </div>

        </>

}
export default ContactoModalCreateSection
