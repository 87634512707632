import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import Constantes from "../../../../assets/constants/Constantes";
import SingleSuggestionInputField, { SingleSuggestionInputFieldProps } from "../../../../components/ui/atoms/SingleSuggestionInput/SingleSuggestionInput.Field";
import FormField from "../../../../components/ui/molecules/Form-field/FormField";
import BancosRepository from "../../../../domain/BancosRepository";
import SucursalesRepository from "../../../../domain/SucursalesRepository";
import Banco from "../../../../domain/model/Banco";
import { DirtyForm } from "../../../../domain/model/DirtyForm";
import Sucursal from "../../../../domain/model/Sucursal";
import BancosApiRepository from "../../../../infraestructure/api/Bancos.ApiRepository";
import SucursalesApiRepository from "../../../../infraestructure/api/Sucursales.ApiRepository";
import { CustomButton } from "../../../../shared/components/Buttons/CustomButton";
import CustomInput from "../../../../shared/components/Inputs/CustomInput/CustomInput";
import { AuthContext } from "../../../Login/AuthContextProvider";
import FormaPagoRepository from "../../domain/FormaPagoRepository";
import { ClienteDto } from "../../domain/model/Cliente";
import FormaPago, { FormaPagoReadingDto, FormaPagoUpdateDto } from "../../domain/model/FormaPago";
import FormaPagoApiRepository from "../../infraestructure/api/FormaPago.ApiRepository";
import TooltipComponent from "../../../../components/ui/molecules/Tooltip/TooltipComponent";
import DeleteModal from "../../../../shared/components/DeleteModal/DeleteModal";

interface Props extends DirtyForm {
    type: "edit" | "add"
    title: string
    handleOnSubmit: () => void
    cliente?: ClienteDto
    idPadre: number,
    cabecera: string[]
    body: string[]
    bodyDisabled: boolean[],
    idTest:string
}

const FormaPagoView: FC<Props> = ({
    idPadre,
    title,
    cabecera,
    body,
    idTest,
    hadleDirtyForm
}) => {
    //modal open
    //session
    const { getToken, username } = useContext(AuthContext);

    //delete
    const [deletionIndex, setDeletionIndex] = useState<number>(-1);


    const [formaPago, setFormaPago] = useState<FormaPago[]>();

    const [recargarSucursal, setRecargarSucursal] = useState<boolean>(idPadre != 0 ? false : true);
    const [bancoSelected, setBancoSelected] = useState<Banco>({ id: '0', descripcion: "", nombre: "Seleccionar", text: '' } as Banco);
    const [sucursalSelected, setSucursalSelected] = useState<Sucursal>({ id: '0', descripcion: "", municipio: "Seleccionar" } as Sucursal);
    const [sucursales, setSucursales] = useState<Sucursal[]>([]);
    const [userAddFormaPago, setUserAddFormaPago] = useState<boolean>(false);
    const [errores, setErrores] = useState<string[]>([]);
    const [formaPagoForm, setFormaPagoForm] = useState<FormaPagoReadingDto[]>((formaPago && formaPago.length > 0) ? formaPago : []);
    const [formaPagoFormIni, setFormaPagoFormIni] = useState<FormaPagoReadingDto[]>((formaPago && formaPago.length > 0) ? formaPago : []);
    const [lineSelected, SetLineSelected] = useState<number>();
    const [openDelete, setOpenDelete] = useState<boolean>(false);

    const fetchFormaPago = useCallback((id: number) => {
        const repo: FormaPagoRepository = new FormaPagoApiRepository(getToken());
        return repo.getByClienteId(id).then((a) => {
            setFormaPago(a);
            setFormaPagoForm(a);
            setFormaPagoFormIni(a);
            a.map((fp: FormaPagoUpdateDto, j: number) => {
                if (fp.bancoId) {
                    fetchSucursal(fp.sucursalId)
                        .then((resp: Sucursal) => {
                            sucursales.push(resp);
                            setSucursales(sucursales);
                            if (sucursales.length == a.length) {
                                setRecargarSucursal(true);
                            }
                        })
                }
            });
        })
    }, [getToken()])

    useEffect(() => { // Side Effect

        if (idPadre != 0) {
            fetchFormaPago(idPadre !== undefined ? +idPadre : 0);
        }

    }, []);

    const onChangeBanco = (s: string, index: number) => {

        if (!s || s.length == 0) {
            return;
        }

        setRecargarSucursal(false);
        setBancoSelected({ id: s && s.length > 0 ? s[0].toString() : "", descripcion: "", nombre: "" } as Banco)
        const formaPagoUpdated = formaPagoForm.map((formaPago: FormaPagoReadingDto, j: number) => {
            if (index === j) {
                return { ...formaPago, bancoId: s && s.length > 0 ? s[0].toString() : "", bancoNombre: s.toString() == "" ? "" : formaPago.bancoNombre };
            }

            return formaPago;
        });
        setFormaPagoForm(formaPagoUpdated);
        setRecargarSucursal(true);
        // if (bancoSelected.id === "0") {
        //     onChangeSucursal("", index);
        // }

    }

    const onChangeSucursal = (s: string, index: number) => {
        if (!s || s.length == 0) {
            return;
        }
        setSucursalSelected({ id: s && s.length > 0 ? s[0].toString() : "", descripcion: "", municipio: "Seleccionar", text: s.toString() } as Sucursal)
        const formaPagoUpdated = formaPagoForm.map((formaPago: FormaPagoReadingDto, j: number) => {
            if (formaPago.bancoId != "" && (index === j)) {
                return { ...formaPago, sucursalId: s && s.length > 0 ? s[0].toString() : "" };
            }

            return formaPago;
        });
        setFormaPagoForm(formaPagoUpdated);
        setRecargarSucursal(true);
    }


    const fetchBancos = useCallback(
        (search: string) => {
            const repo: BancosRepository = new BancosApiRepository(getToken());
            return repo.fetchBancos({
                filter: {
                    busqueda: search
                }
            })
        }
        , []);


    const fetchSucursales = useCallback(
        (search: string, index: number) => {
            const repo: SucursalesRepository = new SucursalesApiRepository(getToken());
            return repo.fetchSucursales({
                filter: {
                    codigoBanco: formaPagoForm.length > 0 ? formaPagoForm[index]?.bancoId?.toString() : "0",
                    busqueda: formaPagoForm.length > 0 ? formaPagoForm[index]?.sucursalId ? formaPagoForm[index].sucursalId : search : ""
                }
            })
        }
        , [bancoSelected]);

    const fetchSucursal = useCallback(
        (id: string) => {
            const repo: SucursalesRepository = new SucursalesApiRepository(getToken());
            return repo.fetchSucursal(id)
        }
        , [bancoSelected]);


    const openConfirmationModal = (index: number) => {
        setDeletionIndex(index);
        setOpenDelete(true);
    }

    const handleCloseModalDelete = (remove: boolean) => {
        setOpenDelete(false);
        if (remove) {
            handleRemoveFormaPago(deletionIndex!);
        }
    }


    const handleRemoveFormaPago = (index: number) => {
        if (!formaPagoForm[index].id) {
            formaPagoForm.splice(index, 1);
            toast.success(`Eliminado correctamente;`);
            setUserAddFormaPago(false);
            setOpenDelete(false);
            return;
        }

        const repo: FormaPagoRepository = new FormaPagoApiRepository(getToken());
        repo.delete(formaPagoForm[index].id)
            .then((resp) => {
                toast.success(`Eliminado correctamente;`);
                const aux = formaPagoForm.filter(item => item.id !== formaPagoForm[index].id);
                setFormaPagoForm(aux);
                setFormaPagoFormIni(aux);
            }).catch((ex) => {
                toast.success(`Error: ${ex}`);
            });
        setOpenDelete(false);
    }

    const handlePropertyChange = (property: string, newValue: string | number, i: number) => {
        const formaPagoUpdated = formaPagoForm.map((formaPago: FormaPagoReadingDto, j: number) => {
            if (i === j) return { ...formaPago, [property]: newValue, Usuario: username };

            return formaPago;
        });
        setFormaPagoForm(formaPagoUpdated);
    }



    const fpValidation = (index: number): boolean => {
        const errores: string[] = [];

        const formaPago = formaPagoForm[index];

        if (formaPago.telefono && formaPago.telefono.toString().length > 0) {
            if (!formaPago?.telefono.toString().match(Constantes.PHONE_NUMBER_REGEX)) {
                errores[index] = 'Indique un telefono válido';
            }
        }

        setErrores(errores);

        return errores.length === 0;

    }


    const onSubmitFormaPagoForm = (i: number) => {
        const repo: FormaPagoRepository = new FormaPagoApiRepository(getToken());

        let body: FormaPagoUpdateDto;
        body = {
            gestorCuentas: (formaPagoForm && formaPagoForm[i].gestorCuentas) ? formaPagoForm[i].gestorCuentas : '',
            bancoId: (formaPagoForm && formaPagoForm[i].bancoId) ? formaPagoForm[i].bancoId : '',
            sucursalId: (formaPagoForm && formaPagoForm[i].sucursalId) ? formaPagoForm[i].sucursalId.toString() : '',
            clienteId: idPadre,
            telefono: (formaPagoForm && formaPagoForm[i].telefono) ? formaPagoForm[i].telefono : '',
            creationTime: (formaPagoForm && formaPagoForm[i].creationTime) ? formaPagoForm[i].creationTime : '',
            creationUsername: (formaPagoForm && formaPagoForm[i].creationUsername) ? formaPagoForm[i].creationUsername : '',
            lastModificationTime: (formaPagoForm && formaPagoForm[i].lastModificationTime) ? formaPagoForm[i].lastModificationTime : '',
            lastModificationUsername: (formaPagoForm && formaPagoForm[i].lastModificationUsername) ? formaPagoForm[i].lastModificationUsername : '',
            id: formaPagoForm && (formaPagoForm[i].id ?? '')
        };

        SetLineSelected(i);
        if (fpValidation(i)) {
            if (body !== null) {
                //update
                if (!!body?.id) {
                    repo.update(body as FormaPagoUpdateDto)
                        .then((response) => {
                            const formaPagoUpdated = formaPagoForm.map((formaPago: FormaPagoReadingDto, j: number) => {
                                if (i === j) return { ...formaPago, lastModificationTime: response.lastModificationTime };

                                return formaPago;
                            });
                            setFormaPagoForm(formaPagoUpdated);
                            setFormaPagoFormIni(formaPagoUpdated);
                            toast.success(`Forma de pago guardada con éxito`);
                        })
                        .catch(() => {
                            toast.error(`Error al guardar la forma de pago`);
                        });
                }
                else //add
                {
                    repo.add(body as FormaPagoUpdateDto)
                        .then((response) => {
                            toast.success(`Forma de pago añadida con éxito`);

                            //recogemos el nuevo id, ya no es nuevo, es editable!!!
                            const formaPagoAdd = formaPagoForm.map((formaPago: FormaPagoReadingDto, j: number) => {
                                if (i === j) return { ...formaPago, id: response.id, lastModificationTime: response.lastModificationTime };

                                return formaPago;
                            });
                            setFormaPagoForm(formaPagoAdd);
                            setFormaPagoFormIni(formaPagoAdd);
                            //ya hemos guardado, dejamos que se añadan nuevos
                            setUserAddFormaPago(false);
                        })
                        .catch(() => {
                            toast.error(`Error al añadir la forma de pago`);
                        });
                }
            }
        }

    }

    const handleAddFormaPago = () => {
        setUserAddFormaPago(true);
        let newFormaPago = {
            id: '',
            bancoId: '',
            bancoNombre: '',
            gestorCuentas: '',
            sucursalId: '',
            sucursalNombre: '',
            telefono: '',
            creationTime: '',
            creationUsername: '',
            lastModificationTime: '',
            lastModificationUsername: '',
            clienteId: idPadre
        }
        setFormaPagoForm([...formaPagoForm, newFormaPago]);
    }

    const isDirty = (formaPago: FormaPago): boolean => {
        if (formaPago.id == undefined || formaPago.id == '') {
            hadleDirtyForm(true);
            return true;
        }
        else {
            let formaPagoEncontrado = formaPagoFormIni?.filter(p => p.id === formaPago.id);
            const arrayProperties = ["bancoId", "bancoNombre", "gestorCuentas", "sucursalId", "sucursalNombre", "telefono"];
            if (formaPagoEncontrado && formaPagoEncontrado.length > 0 && JSON.stringify(formaPagoEncontrado[0], arrayProperties) == JSON.stringify(formaPago, arrayProperties)) {
                hadleDirtyForm(false);
                return false;
            }
            else {
                hadleDirtyForm(true);
                return true;
            }
        }
    }

    return (
        <>
            <div className="inline-grid">
                <div className="inline-grid-header">
                    <label>Banco</label>
                    <label>Sucursal</label>
                    <label>Teléfono</label>
                    <label>Gestor Cuentas</label>

                    <div className="inline-button">
                        {!userAddFormaPago &&
                            <CustomButton
                                id={"add-btn"}
                                icon="add"
                                title="Añadir Forma de Pago"
                                className={"contact-btn"}
                                onButtonClick={() => { handleAddFormaPago() }}
                            />}
                    </div>
                </div>
                <div className="inline-grid-container">
                    {formaPagoForm?.map((formaPago: FormaPagoReadingDto, i: number) => {
                        return (
                            <div className="inline-grid-item">
                                {//Banco
                                    <TooltipComponent 
                                    text={formaPago.bancoNombre}>
                                    <FormField<SingleSuggestionInputFieldProps>
                                        // className={'platform-input secondary-input'}
                                        type="inline"
                                        value={
                                            {
                                                id: formaPago.bancoId,
                                                text: formaPago.bancoNombre
                                            }
                                        }
                                        disabled = {formaPago.id ? true : false}
                                        required
                                        onChange={(e) => {onChangeBanco(e,i)}}
                                        searchCallback={fetchBancos}
                                        component={SingleSuggestionInputField}
                                        idTest="Banco"
                                    />
                                     </TooltipComponent>
                                }

                                {(recargarSucursal) ?
                                    <TooltipComponent
                                        text={(sucursales.find(x => x.id == formaPago.sucursalId)?.nombreCompuesto ?? '')}>
                                        <FormField<SingleSuggestionInputFieldProps>
                                            type="inline"
                                            value={
                                                {
                                                    id: formaPago.sucursalId,
                                                    //text: formaPago.sucursalNombre
                                                    text: (sucursales.find(x => x.id == formaPago.sucursalId)?.nombreCompuesto ?? '')
                                                }
                                            }
                                            disabled={formaPago.id ? true : false}
                                            required
                                            onChange={(e) => onChangeSucursal(e, i)}
                                            searchCallback={(e) => fetchSucursales(e, i)}
                                            component={SingleSuggestionInputField}
                                            idTest="SucursalBanco"
                                        /> </TooltipComponent>
                                    : <div></div>
                                }

                                <CustomInput
                                    key={`$telefono-${i}`}
                                    value={(formaPagoForm && formaPagoForm.length > 0) && (formaPagoForm[i].telefono) ? formaPagoForm[i].telefono : ''}
                                    className={'platform-input secondary-input'}
                                    formClassName={"secondary-input"}
                                    error={lineSelected == i ? errores[i] : ""}
                                    onChange={(e) => {handlePropertyChange('telefono', e, i)}}
                                    idTest="Telefono"
                                />

                                <CustomInput
                                    key={`$gestorCuentas-${i}`}
                                    value={(formaPagoForm && formaPagoForm.length > 0) && (formaPagoForm[i].gestorCuentas) ? formaPagoForm[i].gestorCuentas : ''}
                                    className={'platform-input secondary-input'}
                                    formClassName={"secondary-input"}
                                    onChange={(e) => { handlePropertyChange('gestorCuentas', e, i)}}
                                    idTest="GestorCuentas"
                                />

                                <div className="inline-buttons">
                                    <CustomButton
                                        key={`confirm-${i}`}
                                        title={'GuardarGrid'}
                                        icon={isDirty(formaPago) ? 'save' : 'done'}
                                        className={'contact-btn'}
                                        onButtonClick={() => { onSubmitFormaPagoForm(i) }}
                                        type='button'
                                    />
                                    <CustomButton
                                        key={`remove-${i}`}
                                        title={'BorrarGrid'}
                                        icon={'delete'}
                                        className={'contact-btn remove'}
                                        onButtonClick={() => { openConfirmationModal(i) }}
                                        type='button'
                                    />
                                </div>
                            </div>
                        )
                    }
                    )}
                </div>

                {
                    openDelete &&
                    <DeleteModal
                        open={openDelete}
                        handleCloseModal={handleCloseModalDelete}
                        field="forma de pago"
                    >
                    </DeleteModal>
                }

            </div>
        </>
    )
}

export default FormaPagoView