import { useHistory } from "react-router-dom";
import { HistoryHandler, HistoryLocation } from "./History.Context"
import useRouter from "../hooks/useRouter";

const HistoryProvider: React.FC<{}> = ({ children }) => {

	const { location, push } = useHistory();

	const { goToRoute } = useRouter(push);

	return (
		<HistoryLocation.Provider value={{ pathname: location.pathname }}>
			<HistoryHandler.Provider value={{ goToRoute }}>
				{children}
			</HistoryHandler.Provider>
		</HistoryLocation.Provider>
	)
}

export default HistoryProvider;