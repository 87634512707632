
export type FormFieldGroupType =
	"none"
	| "input"
	| "selector"
	| "inline"

const FormFieldGroup: React.FC<{
	type?: FormFieldGroupType,
	error?: boolean,
	className?: string
}> = (
	{
		type = "none",
		error = false,
		children,
		className
	}
) => {
		return (
			<div className={`form-input ${className ?? ""}`}>
				{children}
			</div>
		)
	}

export default FormFieldGroup;