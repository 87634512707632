import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { UnidadObraElementoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { UnidadObraElementoReadDto } from "../../domain/model/UnidadObraElemento";

export default class UnidadObraElementoRequestGetById extends ApiRequest<{}, UnidadObraElementoReadDto>
{
	constructor(id: string, token: string) {
		super(
			"GET",
			UnidadObraElementoApiEndpoints.CON_ID + id,
			{ Authorization: token },
			{}
		)
	}
}
