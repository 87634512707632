import useFilterFields from "../hooks/useFilterFields";
import useOportunidadesTags from "../hooks/useOportunidadesTags";
import { AsfalteroDropdownContext, CodigoLicitacionFieldContext, CodigoOfertaFieldContext, ContratistaDropdownContext, ContratistaFieldContext, DelegacionesDropdownContext, EstadoOportunidadDropdownContext, FechaAdjudicacionContext, FechaRegistroContext, LicitamosDropdownContext, ObraDescripcionFieldContext, OportunidadFieldContext, PromotorFieldContext, ProvinciaFieldContext, TagsReadContext, TipoOportunidadDropdownContext } from "./FilterFields.Context";

const FilterFieldsProvider: React.FC<{}> = ({ children }) => {
	const { tags, updateTags } = useOportunidadesTags();

	const {
		promotorFilter,
		obraDescripcionFilter,
		provinciaFilter,
		delegacionesFilter,
		contratistaFilter,
		asfalteroFilter,
		licitamosFilter,
		fechaRegistroFilter,
		fechaAdjudicacionFilter,
		contratistaSuggestionFilter,
		toneladasFilter,
		oportunidadCodigoFilter,
		licitacionCodigoFilter,
		ofertaCodigoFilter,
		estadoOportunidadFilter,
		tipoOportunidadFilter
	} = useFilterFields();

	return (
		<PromotorFieldContext.Provider value={promotorFilter.field}>
			<ObraDescripcionFieldContext.Provider value={{ onSearch: obraDescripcionFilter.onChange, onFilter: updateTags, onClear: () => {obraDescripcionFilter.onClear()}}}>
				<ProvinciaFieldContext.Provider value={provinciaFilter.field}>
					<DelegacionesDropdownContext.Provider value={delegacionesFilter.options}>
						<ContratistaDropdownContext.Provider value={contratistaFilter.options}>
							<AsfalteroDropdownContext.Provider value={asfalteroFilter.options}>
								<LicitamosDropdownContext.Provider value={licitamosFilter.options}>
									<EstadoOportunidadDropdownContext.Provider value={estadoOportunidadFilter.options}>
								<TipoOportunidadDropdownContext.Provider value={tipoOportunidadFilter.options}> 
									<FechaRegistroContext.Provider value={fechaRegistroFilter.field}>
									<FechaAdjudicacionContext.Provider value={fechaAdjudicacionFilter.field}>
										<ContratistaFieldContext.Provider value={contratistaSuggestionFilter.field}>
											<OportunidadFieldContext.Provider value={{ onSearch: oportunidadCodigoFilter.onChange, onFilter: updateTags, onClear: () => {oportunidadCodigoFilter.onClear()}}}>
												<CodigoLicitacionFieldContext.Provider value={{ onSearch: licitacionCodigoFilter.onChange, onFilter: updateTags, onClear: () => {licitacionCodigoFilter.onClear()}}}>
													<CodigoOfertaFieldContext.Provider value={{ onSearch: ofertaCodigoFilter.onChange, onFilter: updateTags, onClear: () => {ofertaCodigoFilter.onClear()}}}>
													<TagsReadContext.Provider value={
												{
													tags: tags.concat(promotorFilter.tag,
																	  provinciaFilter.tag,
																	  delegacionesFilter.tag,
																	  contratistaFilter.tag,
																	  contratistaSuggestionFilter.tag,
																	  asfalteroFilter.tag,
																	  licitamosFilter.tag,
																	  fechaRegistroFilter.tag,
																	  fechaAdjudicacionFilter.tag,
																	  toneladasFilter.tag,
																	  estadoOportunidadFilter.tag,
																	  tipoOportunidadFilter.tag
																	  )
													}
													}>
												{children}
											</TagsReadContext.Provider>
											</CodigoOfertaFieldContext.Provider>
											</CodigoLicitacionFieldContext.Provider>
											</OportunidadFieldContext.Provider>
										</ContratistaFieldContext.Provider>
										</FechaAdjudicacionContext.Provider>
									</FechaRegistroContext.Provider>
									</TipoOportunidadDropdownContext.Provider>
 									</EstadoOportunidadDropdownContext.Provider> 
								</LicitamosDropdownContext.Provider>
							</AsfalteroDropdownContext.Provider>
						</ContratistaDropdownContext.Provider>
					</DelegacionesDropdownContext.Provider>
				</ProvinciaFieldContext.Provider>
			</ObraDescripcionFieldContext.Provider>
		</PromotorFieldContext.Provider>
	)
}

export default FilterFieldsProvider;

