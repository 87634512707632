import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import ClausulaOferta from "../../domain/model/ClausulaOferta";
import { ClausulasOfertaApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class ClausulaOfertaGetByOfertaId extends ApiRequest
<{}, BaseResponse<ClausulaOferta[]>
>
{
	constructor(data: {}, token: string) {
		super(
			"POST",
			ClausulasOfertaApiEndpoints.CON_FILTRO,
			{  Authorization: `${token}` },
			data,
		)
	}
}