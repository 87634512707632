import { Icon } from "@pavabits/components";
import { useState } from "react";
import useComponentVisible from "../../../../hooks/useComponentVisible";
import { SelectorType } from "../SuggestionInput/hooks/useSelectorItems";
import "./Dropdown.scss";
import TooltipComponent from "../../molecules/Tooltip/TooltipComponent";

export interface DropdownProps {
	options: Partial<SelectorType>[]
	singleSelection?: boolean
	disabled?: boolean
	value?: string
	label?: string
	idTest?:string
}

const Dropdown: React.FC<DropdownProps> = (
	{
		options,
		singleSelection,
		disabled, value,
		label,
		idTest
	}
) => {

	const [targetItem, setTargetItem] = useState<string>("");

	const [visible, setVisible] = useState<boolean>(false);

	function onFocusLost(target?: string) {
		setVisible(false);
		setTargetItem(target ?? "");
	}

	const { ref } = useComponentVisible(onFocusLost, singleSelection);

	const unorderedListClassNames = () => {
		const classNamesString = ["dropdown__list"];
		if (visible && options.length) {
			classNamesString.push("dropdown__list--visible");
		}
		else {
			classNamesString.push("dropdown__list--hide");
		}
		return classNamesString.length ? classNamesString.join(" ") : classNamesString[0];
	}

	const listItemClassNames = (index: number) => {
		const classNamesString = ["dropdown__item"];
		if (index === 0) classNamesString.push("dropdown__item--first");
		if (index === options.length - 1) classNamesString.push("dropdown__item--last");

		return classNamesString.length ? classNamesString.join(" ") : classNamesString[0];
	}

	function showOptions() {
		setVisible(
			!singleSelection
				? true
				: targetItem === "" ||
				(targetItem.length > 0 && !targetItem.includes("PbDropdownItem"))
		);
		setTargetItem("");

	}

	function handleSelectChange(e: any) {
		const value = e.target.value?.toString() ?? "";
		const option = options.find(opt => opt.id?.toString() === value);
		if (option) option?.onClick!(option.selected!, option.id);
	}

	const selectedOption = options.find(opt => opt.selected)?.text?.toUpperCase();

	const dropdownPlaceHolder = singleSelection ?
		selectedOption ?? 'Seleccionar'
		:
		'Seleccionar';

	return (
		<div className="dropdown">
			<button
				type="button"
				className={`op-btn form-control${disabled ? " disabled" : ""}`}
				disabled={disabled}
				onClick={showOptions}
				data-cy={idTest + "DropdownButton"}
			>
				<TooltipComponent text={dropdownPlaceHolder ?? ""}>
				<label data-cy={(idTest === undefined 
				? label + 'Dropdown' 
				: idTest )
                	}>
					{value?.substring(0, 25) ?? dropdownPlaceHolder.substring(0, 25)}
				</label>
				</TooltipComponent>
				<Icon icon="keyboard_arrow_down" />
			</button>
			<ul
				ref={ref}
				className={unorderedListClassNames()}
				style={{ listStyleType: "none" }}
				data-cy={idTest + "Dropdown"}
			>
				{options && options.map((option, index) => {
					return (
						<li id={`PbDropdownItem-${index}`} key={index} className={listItemClassNames(index)}>
							<button
								type="button"
								id={`PbDropdownItem-${index}`}
								className="btn btn--selector"
								value={option.id}
								onClick={handleSelectChange}
							>
								{option.selected && <Icon icon="done" />}
								{option.text}
							</button>
						</li>
					)
				})
				}
			</ul>
		</div>
	)
}

export default Dropdown;