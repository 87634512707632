
import ApiRequest from "../../../../domain/ApiRequest";
import { EstudioDetalleAvanzadoTramoDto } from "../../../Estudios/domain/model/EstudioDetalleAvanzadoTramo";
import { EstudioDetalleAvanzadoTramoEndpoints, EstudioDetalleAvanzadoTrasladoEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class EstudioDetalleAvanzadoTramoRequestDelete extends ApiRequest<{}, EstudioDetalleAvanzadoTramoDto>
{
	constructor(id: string, token: string) {
		super(
			"DELETE",
			EstudioDetalleAvanzadoTramoEndpoints.DELETE+id,
			{ Authorization: `${token}`},
			{},
		)
	}
}