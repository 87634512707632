import ApiRequest from "../../../../domain/ApiRequest";
import { BaseFilter } from "../../../../domain/BaseFilter";
import { SeguimientoHistoricoClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { SeguimientoHistoricoClienteFilter } from "../../domain/model/SeguimientoHistoricoCliente";

export default class SeguimientoHistoricoClienteRequestDownloadExcel extends ApiRequest<
BaseFilter<any>,
string>
{
	constructor(data: BaseFilter<SeguimientoHistoricoClienteFilter>, token: string) {
		super(
			"POST",
			SeguimientoHistoricoClienteEndpoints.DOWNLOAD_EXCEL,
			{ Authorization: token },
			data,
		)
	}
}