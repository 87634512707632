import jwt_decode from "jwt-decode";
import Keycloak, { KeycloakConfig, KeycloakInitOptions } from "keycloak-js";
import { createContext, useEffect, useState } from "react";
import { KEYCLOAK_CLIENTID, KEYCLOAK_LOGIN, KEYCLOAK_LOGOUT, KEYCLOAK_REALM } from "../../assets/constants/Constantes";
import DelegacionesRepository from "../../domain/DelegacionesRepository";
import { DelegacionDetalle } from "../../domain/model/Delegacion";
import DelegacionesApiRepository from "../../infraestructure/api/Delegaciones.ApiRepository";
import PermisoVentanaApiRepository from "./infraestructure/api/PermisoVentana.ApiRepository";
import PermisoVentanaRepository from "./domain/PermisoVentanaRepository";
import { VentanaPermiso } from "./domain/model/VentanaPermiso";

/**
 * KeycloakConfig configures the connection to the Keycloak server.
 */
const keycloakConfig: KeycloakConfig = {
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENTID,
  url: KEYCLOAK_LOGIN,
};

/**
 * KeycloakInitOptions configures the Keycloak client.
 */
const keycloakInitOptions: KeycloakInitOptions = {
  // Configure that Keycloak will check if a user is already authenticated (when opening the app or reloading the page). If not authenticated the user will be send to the login form. If already authenticated the webapp will open.
  onLoad: "login-required",
};

// Create the Keycloak client instance
const keycloak = Keycloak(keycloakConfig)

/* Event when token expires,
 renew token*/
keycloak.onTokenExpired = () => {
  console.log('token expired', keycloak.token);
  keycloak.updateToken(5);
}
/**
 * AuthContextValues defines the structure for the default values of the {@link AuthContext}.
 */
interface AuthContextValues {
  /**
   * Whether or not a user is currently authenticated
   */
  isAuthenticated: boolean;
  /**
   * The name of the authenticated user
   */
  username: string;
  /**
 * The email of the authenticated user
 */
  email: string;
  /*
    token
  */
  token: string;
  getToken: () => string;
  /*
    check token has claims
  */
  hasClaim: (claim: string) => boolean;
  /*
      get claims value from token
    */
  getClaimValue: (claim: string) => string;
  /*
    get delegacion data
  */
  getDelegacion: () => DelegacionDetalle;
  /*
   * Function to initiate the logout
   */
  logout: () => void;
  /*
  * Token expired
  */
  isTokenExpired: () => void;
  /**
   * Check if the user has the given role
   */
  hasRole: (role: string) => boolean;
  /*
  get email data
*/
  getEmail: () => string;
  /*
    get user functionalities
  */
  getUserFunctionalities: () => VentanaPermiso[];
}

/**
 * Default values for the {@link AuthContext}
 */
const defaultAuthContextValues: AuthContextValues = {
  isAuthenticated: false,
  username: "",
  email: "",
  token: "",
  getToken: () => "",
  hasClaim: (claim: string) => false,
  getClaimValue: (claim: string) => '',
  getDelegacion: () => new DelegacionDetalle({ id: '', descripcion: '', cif: '', codigoPostal: '', direccion: '', email: '', poblacion: '', provincia: '', telefono: '' }),
  getEmail: () => "",
  logout: () => { },
  isTokenExpired: () => { },
  hasRole: (role) => false,
  getUserFunctionalities: () => []
};

/**
 * Create the AuthContext using the default values.
 */
export const AuthContext = createContext<AuthContextValues>(
  defaultAuthContextValues
);

/**
 * The props that must be passed to create the {@link AuthContextProvider}.
 */
interface AuthContextProviderProps {
  /**
   * The elements wrapped by the auth context.
   */
  children: JSX.Element;
}

/**
 * AuthContextProvider is responsible for managing the authentication state of the current user.
 *
 * @param props
 */
const AuthContextProvider = (props: AuthContextProviderProps) => {
  console.log("rendering AuthContextProvider");

  // Create the local state in which we will keep track if a user is authenticated
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  // Local state that will contain the users name once it is loaded
  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [delegacion, setDelegacionDetalle] = useState<DelegacionDetalle>(new DelegacionDetalle({ id: '', descripcion: '', cif: '', codigoPostal: '', direccion: '', email: '', poblacion: '', provincia: '', telefono: '' }));
  const [userModules, setUserModules] = useState<VentanaPermiso[]>([]);

  // Effect used to initialize the Keycloak client. It has no dependencies so it is only rendered when the app is (re-)loaded.
  useEffect(() => {
    /**
     * Initialize the Keycloak instance
     */
    async function initializeKeycloak() {
      console.log("initialize Keycloak");
      try {
        const isAuthenticatedResponse = await keycloak.init(
          keycloakInitOptions
        );

        // If the authentication was not successfull the user is send back to the Keycloak login form
        if (!isAuthenticatedResponse) {
          console.log(
            "user is not yet authenticated. forwarding user to login."
          );
          keycloak.login();
        }
        // If we get here the user is authenticated and we can update the state accordingly
        console.log("user already authenticated");
        setAuthenticated(isAuthenticatedResponse);
      } catch {
        console.log("error initializing Keycloak");
        setAuthenticated(false);
      }
    }

    initializeKeycloak();
  }, []);

  // This effect loads the users profile in order to extract the username
  useEffect(() => {
    /**
     * Load the profile for of the user from Keycloak
     */
    async function loadProfile() {
      try {
        const profile = await keycloak.loadUserProfile();

        if (keycloak.token !== undefined)
          setToken("Bearer " + keycloak.token);

        if (profile.firstName) {
          setUsername(profile.firstName);
        } else if (profile.username) {
          setUsername(profile.username);
        }

        if (profile.email) {
          setEmail(profile.email);
        }

        getDelegacionDetalle();
        fetchUserModules();
      } catch {
        console.log("error trying to load the users profile");
      }
    }

    // Only load the profile if a user is authenticated
    if (isAuthenticated) {
      loadProfile();
    }
  }, [isAuthenticated]);

  const isTokenExpired = () => {
    alert(keycloak.isTokenExpired())
  };


  const getToken = () => {
    return "Bearer " + keycloak.token ?? "";
  };

  const getDelegacion = () => {
    return delegacion;
  };

  const getUserModules = () => {
    return userModules;
  };

  const getEmail = () => {
    return email;
  };

  const hasClaim = (claim: string) => {
    var decoded: any = jwt_decode(keycloak.token ?? '');
    return decoded[claim] ?? false;
  }

  const getClaimValue = (claim: string) => {
    var decoded: any = jwt_decode(keycloak.token ?? '');
    return decoded[claim];
  }

  const getDelegacionDetalle = () => {
    if (hasClaim('Delegacion')) {
      let delegacionId = getClaimValue('Delegacion');
      fetchDelegaciones(delegacionId);
    }
  }

  const fetchDelegaciones =
    (delegacionId: string) => {
      const repo: DelegacionesRepository = new DelegacionesApiRepository(getToken());
      return repo.fetchDelegacion(delegacionId).then((resp) => {
        if (resp)
          setDelegacionDetalle(resp);
      })
    };

  const fetchUserModules = () => {
      const repo: PermisoVentanaRepository = new PermisoVentanaApiRepository(getToken());
      return repo.fetchUserModules().then((resp: VentanaPermiso[]) => {
        setUserModules(resp);
      });
    };

  /**
   * Initiate the logout
   */
  const logout = () => {
    window.location.replace(KEYCLOAK_LOGOUT);
  };

  /**
   * Check if the user has the given role
   * @param role to be checked
   * @returns whether or not if the user has the role
   */
  const hasRole = (role: string) => {
    return keycloak.hasRealmRole(role);
  };

  // Setup the context provider
  return (
    <AuthContext.Provider
      value={{ isAuthenticated, username, email, token, logout, isTokenExpired, getToken, hasClaim, getClaimValue, getDelegacion, hasRole, getEmail, getUserFunctionalities: getUserModules }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
