import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { FormaPagoEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { FormaPagoDto } from "../../domain/model/FormaPago";

export default class FormaPagoRequestGetByClienteId extends ApiRequest<{}, BaseResponse<FormaPagoDto[]>>
{
	constructor(id: number, token: string) {
		super(
			"POST",
			FormaPagoEndpoints.CON_FILTRO,
			{ Authorization: token },
			{ "filter": {
				"clienteId": id
			  }
			}
		)
	}
}