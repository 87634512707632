import ApiRequest from "../../../../domain/ApiRequest";
import { BaseFilter } from "../../../../domain/BaseFilter";
import BaseResponse from "../../../../domain/BaseResponse";
import { ClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { ClienteDto, ClienteFilter } from "../../domain/model/Cliente";

export default class ClienteRequestListByFilter extends ApiRequest<
BaseFilter<ClienteFilter>,
BaseResponse<ClienteDto[]>>
{
	constructor(data: BaseFilter<ClienteFilter>, token: string) {
		super(
			"POST",
			ClienteEndpoints.CON_FILTRO,
			{ Authorization: token },
			data,
		)
	}
}
