
import { Toast } from './components/ui/atoms/Toast';
import "./i18n";
import AppRouter from './router/AppRouter';

function round(value: string, decimals: number) {
    return +(Math.round(+(value + "e+" + decimals)) + "e-" + decimals);
}

function OportunidadesAsfaltoApp() {
  Number.prototype.toFixed = function (fractionDigits: number) {
    let number = String(this);
    let output = round(number, fractionDigits);
    return output.toString();
  }

  return (
    <>
      <AppRouter />
      <Toast />
    </>
  );
}

export default OportunidadesAsfaltoApp;
