import { BaseRepository } from "@pavabits/components";
import Promotor, { PromotorDto, PromotorFilter } from "../../domain/model/Promotor";
import PromotorRepository from "../../domain/PromotorRepository";
import PromotorRequestListByFilter from "../ws/Promotor.RequestListBySearch";

export default class PromotorApiRepository extends BaseRepository<
PromotorDto[],
Promotor[]
> implements PromotorRepository {
	
	searchPromotores(data: PromotorFilter): Promise<Promotor[]> {
		return new Promise((resolve, reject) => {
			new PromotorRequestListByFilter(data, this.authToken)
			.request()
			.then((response) => {
				if (response.success) {
					resolve(this.transformDtoIntoModel(response.data.items));
				} else {
					reject(`Error ${response.errorCode}: ${response.errorMessage}`);
				}
			})
			.catch((error) => {
				reject(error);
			})
		})
	}

	protected transformDtoIntoModel(dto: PromotorDto[]): Promotor[] {
		if (dto && dto.length) {
			return dto.map(item => {
				return new Promotor(
					item.id.toString(),
					item.descripcion
				)
			})
		}
		return [];
	}

}