import { useContext, useEffect, useState } from "react"
import { FilterTag } from "../../Oportunidades/context/FilterFields.Context";
import { GlobalContext, GlobalContextType } from "../../../context/Global.Context";

export default function useOportunidadesTags() {
	const { globalState, updateGlobalState } = useContext(GlobalContext) as GlobalContextType;
	const [tags, setTags] = useState<FilterTag[]>(globalState?.oportunidades?.tags ?? []);
	const [state, setState] = useState({});

	useEffect(() => {
		if (globalState?.oportunidades?.tags) {
			setTags(globalState?.oportunidades?.tags)
		}
		return () => {
			setState({});
		  };
	}, [globalState?.oportunidades?.tags])
	
	const updateTags = (tag: FilterTag) => {
		let updatedTags = tags.filter(x => x.label !== tag.label);
		setTags([...updatedTags, tag]);
		updateGlobalState("oportunidades", {tags: [...updatedTags, tag]})
	}

	return (
		{
			tags,
			updateTags,
		}
	)
};