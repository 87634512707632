import ApiRequest from "../../../../domain/ApiRequest";
import { SeguimientoClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import SeguimientoCliente, { SeguimientoClienteUpdateDto } from "../../domain/model/SeguimientoCliente";

export default class SeguimientoClienteRequestAdd extends ApiRequest<
SeguimientoClienteUpdateDto,
SeguimientoCliente
>
{
	constructor(data: SeguimientoClienteUpdateDto, token: string) {
		super(
			"POST",
			SeguimientoClienteEndpoints.CREAR,
			{ Authorization: `${token}`},
			data,
		)
	}
}