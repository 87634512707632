import React from "react";

interface IHistoryLocation {
	pathname: string
}

const defaultLocation: IHistoryLocation = { pathname: ""};
export const HistoryLocation = React.createContext<IHistoryLocation>(defaultLocation);

interface IHistoryHandler {
 goToRoute: (route: string, params?: {[key: string] : string}) => void,
}

const defaultHistoryHandler = {} as IHistoryHandler;
export const HistoryHandler = React.createContext<IHistoryHandler>(defaultHistoryHandler);