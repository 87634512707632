import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { ComercialEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import Comercial from "../../domain/model/Comercial";
import TipoOferta from "../../domain/model/TipoOferta";

export default class ComercialGetAll extends ApiRequest<undefined, BaseResponse<Comercial[]>>
{
	constructor(token: string) {
		super(
			"GET",
			ComercialEndpoints.BASE_ENPOINT+"?&MaxResultCount=99&SkipCount=0",
			{ Authorization: token },
			undefined,
			{ }
		)
	}
}