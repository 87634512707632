import { useContext } from "react";
import { PromotorFieldContext } from "../../../context/FilterFields.Context";
import OportunidadesSuggestionInput from "./Oportunidades.SuggestionInput";

const PromotorSuggestionInput: React.FC<{}> = () => {

	const field = useContext(PromotorFieldContext);

	return (
		<OportunidadesSuggestionInput
			label="Promotor"
			input={field.input}
			suggestions={field.suggestions}
			onInputChange={field.onInputChange}
			idTest="PromotorFiltro"
		/>
	)
}

export default PromotorSuggestionInput;