import Constantes from "../../../assets/constants/Constantes";
import useForm, { FormErrors } from "../../../hooks/use-form";
import { ClienteCreationDto, ClienteDto, ClienteUpdateDto } from "../domain/model/Cliente";

type ClienteFormDto = ClienteCreationDto | ClienteUpdateDto;

export interface UseClienteInput {
	initialValues?: Partial<ClienteDto>,
	handleOnSubmit: (valid: boolean, data: ClienteFormDto, errors: FormErrors<ClienteFormDto>) => void
}

function useClienteForm(
	{
		initialValues,
		handleOnSubmit,
	}: UseClienteInput
) {

	function validate(data: ClienteCreationDto): FormErrors<ClienteCreationDto> {
		let errors: FormErrors<ClienteCreationDto> = {};
		const fieldIsRequired = "Campo obligatorio.";
		if (data.cif === undefined || !data.cif.length) {
			errors = { ...errors, cif: fieldIsRequired };
		} else {
			// if (data.cif.toString().match(Constantes.CIF_REGEX)) {

			// }
		}

		if(data.cif !== undefined && !data.cif.toString().match(Constantes.CIF_REGEX) && !data.cif.toString().match(Constantes.DNI_REGEX) && !data.cif.toString().match(Constantes.NIE_REGEX))
		{
			errors = { ...errors, cif: "CIF inválido" }
		}

		if (data.descripcion === undefined || !data.descripcion.length) {
			errors = { ...errors, descripcion: fieldIsRequired }
		}

		// if (data.riesgo === undefined) {
		// 	errors = { ...errors, riesgo: fieldIsRequired }
		// }

		return errors;
	}

	return useForm<ClienteCreationDto>({ initialValues, validate, handleOnSubmit });

}

export default useClienteForm;