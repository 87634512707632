import BaseResponse from "../../../../domain/BaseResponse";
import { ClausulasApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ApiRequest from "../../../../domain/ApiRequest";
import Clausula, { ClausulaReadDto } from "../../domain/model/Clausula";

export default class ClausulasRequestFetchAll extends ApiRequest<any, BaseResponse<ClausulaReadDto[]>> 
{
    public constructor (token: string, params?: {[key: string]: number | string}) {
        super(
            "GET", 
            ClausulasApiEndpoints.ALL,
            {Authorization: `${token}`},
            {},
            params ?? ""
        )
    }
}