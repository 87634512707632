import { BaseApiRequest } from "@pavabits/components";
import { BancoDto, BancoFilter } from "../../domain/model/Banco";
import BaseResponse from "../../domain/BaseResponse";
import { BancosEndpoints } from "../../modules/Oportunidades/infraestructure/oportunidades.api-endpoints";


export default class BancosRequestListByFilter extends BaseApiRequest<BancoFilter,BaseResponse<BancoDto[]>>
{
    public constructor (data : BancoFilter, token : string){
        super(
            "POST",
            BancosEndpoints.BANCOS_CON_FILTRO,
            data,
            {Authorization: `${token}`}
        )
    }
}