import ApiRequest from "../../../../domain/ApiRequest";
import { ElementosEquipoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { EquipoCreateDto } from "../../domain/model/Equipo";

export default class EquipoElementoRequestAdd extends ApiRequest<
EquipoCreateDto
>
{
	constructor(data: EquipoCreateDto, token: string) {
		super(
			"POST",
			ElementosEquipoApiEndpoints.CREAR_ELEMENTO,
			{ Authorization: `${token}`},
			data,
			
		)
	}
}