
import ApiRequest from "../../../../domain/ApiRequest";
import { FormaPagoEndpoints, SeguimientoClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class SeguimientoClienteRequestDelete extends ApiRequest<
{},
boolean
>
{
	constructor(id: string, token: string) {
		super(
			"DELETE",
			FormaPagoEndpoints.DELETE+id,
			{ Authorization: `${token}`},
			{},
		)
	}
}