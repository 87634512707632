import { useContext } from "react";
import { Redirect, BrowserRouter as Router, Switch } from "react-router-dom";
import Unauthorized from "../components/ui/atoms/Unauthorized/Unauthorized";
import Clausulas from "../modules/Clausulas/Clausulas";
import ClausulasEdicion from "../modules/Clausulas/ClausulasEdicion";
import ClausulasGrabacion from "../modules/Clausulas/ClausulasGrabacion";
import Cliente from "../modules/Clientes/Cliente";
import ClienteEdicion from "../modules/Clientes/views/objects/Clientes.Edicion";
import ClienteGrabacion from "../modules/Clientes/views/objects/Clientes.Grabacion";
import Contactos from "../modules/Contactos/Contactos";
import Equipos from "../modules/Equipos/Equipos";
import EquiposEdicion from "../modules/Equipos/Equipos.Edicion";
import EquiposElementosEdicion from "../modules/Equipos/Equipos.Elementos.Edicion";
import EquiposElementosGrabacion from "../modules/Equipos/Equipos.Elementos.Grabacion";
import EquiposGrabacion from "../modules/Equipos/Equipos.Grabacion";
import { AuthContext } from "../modules/Login/AuthContextProvider";
import Ofertas from "../modules/Ofertas/Ofertas";
import OfertasEdicion from "../modules/Ofertas/views/objects/Ofertas.Edicion";
import OfertasGrabacion from "../modules/Ofertas/views/objects/Ofertas.Grabacion";
import OportunidadesAsfalto from "../modules/Oportunidades/OportunidadesAsfalto";
import Unidades from "../modules/Unidades/views/Unidades";
import UnidadesEdicion from "../modules/Unidades/views/UnidadesEdicion";
import UnidadesGrabacion from "../modules/Unidades/views/UnidadesGrabacion";
import RouteDashboard from "./routes/Route.Dashboard";
import { Routes } from "./routes/Routes";
import ClientesSeguimientoGrid from "../modules/SeguimientoHistoricoClientes/views/Clientes.Seguimiento.Grid";
import ClientesSeguimientoGrabacion from "../modules/SeguimientoHistoricoClientes/views/Clientes.Seguimiento.Grabacion";
import ClientesSeguimientoEdicion from "../modules/SeguimientoHistoricoClientes/views/Clientes.Seguimiento.Edicion";
import { ROUTES } from "../assets/constants/Constantes";
import { VentanaPermiso } from "../modules/Login/domain/model/VentanaPermiso";

const AppRouter: React.FC<{}> = () => {
	const { isAuthenticated, getUserFunctionalities: permisosVentana } = useContext(AuthContext);
		
	/// <summary>
	/// Renderiza una ruta con el componente correspondiente y verifica si el usuario tiene permisos para acceder a la misma.
	/// </summary>
	const renderRoute = (route: string, component: React.ComponentType<any>) => {
		let permisos = permisosVentana().filter(p => route.includes(p.url));
		let permisoVentana: VentanaPermiso | undefined;
		const isAdd = route.includes(ROUTES.GRABACION);
		const isEdit = !isAdd && route.includes(ROUTES.EDICION);
		
		if (permisos.length > 0) {
			if (isAdd || isEdit) {
				permisoVentana = permisos.find(x => ((isAdd && x.permisoAlta) || (isEdit && (x.permisoModificacion || x.permisoEntrada))));
			} else {
				permisoVentana = permisos.find(x => x.permisoEntrada || x.permisoModificacion || x.permisoAlta || x.permisoBaja);
			}
		}
		
		return permisoVentana ?
			<RouteDashboard
				path={route}
				exact
				Component={component}
				permisoVentana={permisoVentana}
			/>
			:
			<RouteDashboard
				path={route}
				exact
				Component={Unauthorized}
			/>
	}

	return (
		<>
			{
				isAuthenticated ?
					<Router>
						<Switch>						
							{renderRoute(Routes.OPORTUNIDADES, OportunidadesAsfalto)}
							{renderRoute(Routes.CLIENTES, Cliente)}
							{renderRoute(Routes.CLIENTES_GRABACION, ClienteGrabacion)}
							{renderRoute(Routes.CLIENTES_EDICION, ClienteEdicion)}
							{renderRoute(Routes.SEGUIMIENTO_CLIENTES, ClientesSeguimientoGrid)}
							{renderRoute(Routes.SEGUIMIENTO_CLIENTES_GRABACION, ClientesSeguimientoGrabacion)}
							{renderRoute(Routes.SEGUIMIENTO_CLIENTES_EDICION, ClientesSeguimientoEdicion)}
							{renderRoute(Routes.CONTACTOS, Contactos)}
							{renderRoute(Routes.OFERTAS, Ofertas)}
							{renderRoute(Routes.OFERTAS_GRABACION, OfertasGrabacion)}
							{renderRoute(Routes.OFERTAS_EDICION, OfertasEdicion)}
							{renderRoute(Routes.UNIDADES, Unidades)}
							{renderRoute(Routes.UNIDADES_GRABACION, UnidadesGrabacion)}
							{renderRoute(Routes.UNIDADES_EDICION, UnidadesEdicion)}
							{renderRoute(Routes.CLAUSULAS, Clausulas)}
							{renderRoute(Routes.CLAUSULAS_GRABACION, ClausulasGrabacion)}
							{renderRoute(Routes.CLAUSULAS_EDICION, ClausulasEdicion)}
							{renderRoute(Routes.ELEMENTOS_GRABACION, EquiposElementosGrabacion)}
							{renderRoute(Routes.ELEMENTOS_EDICION, EquiposElementosEdicion)}
							{renderRoute(Routes.EQUIPOS, Equipos)}
							{renderRoute(Routes.EQUIPOS_GRABACION, EquiposGrabacion)}
							{renderRoute(Routes.EQUIPOS_EDICION, EquiposEdicion)}							
							<Redirect to={Routes.OFERTAS} />
						</Switch>
					</Router>
					:
					<></>
			}
		</>
	)
}

export default AppRouter;