import BaseResponse from "../../../../domain/BaseResponse";
import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";

import SeguimientoCliente, { SeguimientoClienteDto, SeguimientoClienteUpdateDto } from "../../domain/model/SeguimientoCliente";
import SeguimientoClienteRepository from "../../domain/SeguimientoClienteRepository";
import SeguimientoClienteRequestAdd from "../ws/SeguimientoCliente.Request.Add";
import SeguimientoClienteRequestUpdate from "../ws/SeguimientoCliente.Request.Update";
import SeguimientoClienteRequestDelete from "../ws/SeguimientoCliente.Request.Delete";
import SeguimientoClienteRequestGetByClienteId from "../ws/SeguimientoCliente.Request.GetByClienteId";
import SeguimientoClienteRequestGetBySeguimientoOfertaId from "../ws/SeguimientoCliente.Request.GetBySeguimientoOfertaId";

export default class SeguimientoClienteApiRepository
	extends ApiRepositoryBase<
		SeguimientoClienteDto,
		SeguimientoCliente
	> implements  SeguimientoClienteRepository {

	
	async add(data:  SeguimientoCliente): Promise< SeguimientoCliente> {
		const res= await new  SeguimientoClienteRequestAdd(data, this.authToken).executeAsPromise();
		return res;
	}

	async getByClienteId(id: number): Promise<SeguimientoCliente[]> {
		const res= await new SeguimientoClienteRequestGetByClienteId(id, this.authToken).executeAsPromise();
		return SeguimientoCliente.toArray(res?.items);
	}

	async getBySeguimientoOfertaId(id: string): Promise<SeguimientoCliente[]> {
		const res= await new SeguimientoClienteRequestGetBySeguimientoOfertaId(id, this.authToken).executeAsPromise();
		return SeguimientoCliente.toArray(res?.items);
	}


	async update(data:  SeguimientoClienteUpdateDto): Promise<SeguimientoClienteDto> {
		return await new  SeguimientoClienteRequestUpdate(data, this.authToken).executeAsPromise();
	}

	async delete(id: string): Promise<boolean> {
		await new  SeguimientoClienteRequestDelete(id, this.authToken).executeAsPromise();
		return true;
	}

	buildModelFromItems(item:  SeguimientoClienteDto):  SeguimientoCliente {
		return new  SeguimientoCliente(item);
	}

	protected transformDtoIntoModel(dto: BaseResponse< SeguimientoClienteDto>): BaseResponse<SeguimientoCliente> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}
}