
import ApiRequest from "../../../../domain/ApiRequest";
import { LibroEstandarApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class LibroEstandarRequestDelete extends ApiRequest<
	{},
	boolean
> {
	constructor(id: string, token: string) {
		super(
			"DELETE",
			LibroEstandarApiEndpoints.DELETE + id,
			{ Authorization: `${token}` },
			{},
		)
	}
}