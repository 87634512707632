import TablePrintableObject from "../../../../components/Table/types/TablePrintableObject";
import { formatDate2String } from "../../../../utils/DateUtil";

export type SeguimientoHistoricoClienteFilter = { 
    clienteId?: number
    seguimientoOfertaId?: string
    ofertaId?: string
    oportunidadId?: string
    contactoId?: string
    busquedaNifNombre?: string
    tipoId?: number
    estadoId?: number
    fechaSeguimientoDesde?: string
    fechaSeguimientoHasta?: string
    contactoNombre?: string
    ofertaCodigo?: number
    oportunidadCodigo: string
    delegacionId?: number;
};

interface SeguimientoHistoricoClienteEntity {
    id: string
    fechaSeguimiento: Date
    observacionesTipoSeguimiento: string
    tipoSeguimientoDescripcion: string
    observaciones?: string
    contactoId: string
    nombreContacto: string
    clienteId: number
    clienteNombre: string
    tipoSeguimientoClienteId: number
    estadoOfertaId: number
    estadoOfertaDescripcion: string
    ofertaId?: string
    ofertaCodigo?: number
    oportunidadCodigo: string
    creationUsername: string;
    eventoCalendar: boolean;
} 

export default class SeguimientoHistoricoCliente extends TablePrintableObject {
    id: string;
    fechaSeguimiento: Date;
    observacionesTipoSeguimiento: string;
    tipoSeguimientoDescripcion: string;
    observaciones?: string;
    contactoId: string;
    nombreContacto: string;
    clienteId: number;
    clienteNombre: string;
    tipoSeguimientoClienteId: number;
    estadoOfertaId: number;
    estadoOfertaDescripcion: string;
    ofertaId?: string;
    ofertaCodigo?: number;
    oportunidadCodigo: string;
    creationUsername: string;
    eventoCalendar: boolean;
    eventSelected: boolean;
    controllerName: string;

    constructor(entity: SeguimientoHistoricoClienteEntity) {
        super(entity.id, entity.contactoId );
        this.id = entity.id;
        this.fechaSeguimiento = entity.fechaSeguimiento;
        this.observacionesTipoSeguimiento = entity.observacionesTipoSeguimiento;
        this.observaciones = entity.observaciones;
        this.observacionesTipoSeguimiento = entity.observacionesTipoSeguimiento;
        this.contactoId = entity.contactoId;
        this.nombreContacto = entity.nombreContacto;
        this.clienteId = entity.clienteId;
        this.clienteNombre = entity.clienteNombre;
        this.tipoSeguimientoClienteId = entity.tipoSeguimientoClienteId;
        this.tipoSeguimientoDescripcion = entity.tipoSeguimientoDescripcion;
        this.estadoOfertaId = entity.estadoOfertaId;
        this.estadoOfertaDescripcion = entity.estadoOfertaDescripcion;
        this.ofertaId = entity.ofertaId;
        this.ofertaCodigo = entity.ofertaCodigo;
        this.oportunidadCodigo = entity.oportunidadCodigo;
        this.creationUsername = entity.creationUsername;
        this.eventoCalendar = entity.eventoCalendar;
        this.eventSelected = entity.eventoCalendar;
        this.controllerName='/seguimientoclientes/edicion/'
    }

    toMap = () => {
		return new Map<keyof SeguimientoHistoricoCliente, string>(
			[
				["id", this.id],
				["tipoSeguimientoDescripcion", this.tipoSeguimientoDescripcion],
				["clienteNombre", this.clienteNombre.toString()],
				["nombreContacto", this.nombreContacto],
				["fechaSeguimiento", formatDate2String(this.fechaSeguimiento)],
				["observaciones", this.observaciones ?? ""],
				["ofertaCodigo", this.ofertaCodigo?.toString() ?? ""],
                ["ofertaId", this.ofertaId?.toString() ?? ""],
                ["oportunidadCodigo", this.oportunidadCodigo ?? ""],
                ["estadoOfertaDescripcion", this.estadoOfertaDescripcion],
                ["eventoCalendar", this.eventoCalendar.toString()],
                ["creationUsername", this.creationUsername],
                ["controllerName", this.controllerName]
			]
		)
	};

    public static toArray(entities: SeguimientoHistoricoClienteEntity[]): SeguimientoHistoricoCliente[] {
		return entities.map(entity => { return new SeguimientoHistoricoCliente(entity) });
	}

}

export type SeguimientoHistoricoClienteDto = SeguimientoHistoricoClienteCreationDto;

export interface SeguimientoHistoricoClienteCreationDto extends SeguimientoHistoricoClienteEntity {
    id: string;
    fechaSeguimiento: Date;
    observacionesTipoSeguimiento: string;
    observaciones?: string;
    contactoId: string;
    clienteId: number;
    tipoSeguimientoClienteId: number;
    estadoOfertaId: number;
    ofertaId?: string;
}

export interface SeguimientoHistoricoClienteUpdateDto extends SeguimientoHistoricoClienteEntity {
    
}