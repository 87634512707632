import SelectableItem from "../../../../components/ui/atoms/SuggestionInput/model/SuggestionInput.Model";

interface ContactoClienteEntity  {
	id: string,
	nombreCompleto: string,
	cargo: string,
	telefono: string,
	mail: string,
	usuario: string,
	municipio:string,
	direccion:string,
	personaContacto:string;
	clienteId:number;
	clienteNombre:string;
	zona:string;
	lastModificationTime:string;
	lastModificationUsername:string;
	firmante:boolean;
	nif:string;
	
}

export class ContactoClienteSelectable extends SelectableItem{}


export default class ContactoCliente implements ContactoClienteEntity {

	id: string;
	nombreCompleto: string;
	cargo: string;
	telefono: string;
	mail: string;
	usuario: string;
	municipio:string;
	direccion:string;
	personaContacto:string;
	clienteId:number;
	clienteNombre:string;
	zona:string;
	lastModificationTime:string;
	lastModificationUsername:string;
	firmante:boolean=false;
	nif:string;

	constructor(entity: ContactoClienteEntity) {
		
		this.id = entity.id;
		this.nombreCompleto = entity.nombreCompleto;
		this.cargo = entity.cargo;
		this.telefono = entity.telefono;
		this.mail = entity.mail;
		this.usuario = entity.lastModificationUsername;
		this.municipio=entity.municipio;
		this.direccion=entity.direccion;
		this.personaContacto=entity.personaContacto;
		this.clienteId=entity.clienteId;
		this.zona=entity.zona;
		this.clienteNombre=entity.clienteNombre;
		this.lastModificationTime=entity.lastModificationTime;
		this.lastModificationUsername=entity.lastModificationUsername;
		this.firmante=entity.firmante;
		this.nif=entity.nif;
		
	}

	public static toArray(entities: ContactoClienteEntity[]): ContactoCliente[] {
		return entities.map(entity => { return new ContactoCliente(entity) });
	}

}

export type ContactoClienteDto = ContactoCliente;
export type ContactoClienteCreationDto = ContactoCliente;
export type ContactoClienteUpdateDto = ContactoCliente;