import { error } from "console";
import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";
import { BaseRepository } from "@pavabits/components";
import BaseResponse from "../../../../domain/BaseResponse";
import Contacto, { ContactoFilter, ContactoReadDto } from "../../domain/model/Contacto";
import ContactosRepository from "../../domain/ContactosRepository";
import { ClausulaReadDto } from "../../../Clausulas/domain/model/Clausula";
import ContactosRequestFetchAll from "../ws/Contactos.Request.FetchAll";
import { BaseFilter } from "../../../../domain/BaseFilter";
import ContactosRequestListByFilter from "../ws/Contactos.Request.ListByFilter";


export default class ContactosApiRepository
extends ApiRepositoryBase<ContactoReadDto, Contacto> implements ContactosRepository {

	async fetchContactosResponse(data: {maxResultCount: number, skipCount: number}): Promise<BaseResponse<Contacto[]>> {
        const res = await new ContactosRequestFetchAll(this.authToken, data).executeAsPromise();
		return res;
    }
    
    async fetchContactosByFilter(data: BaseFilter<ContactoFilter>): Promise<BaseResponse<ContactoReadDto[]>> {
		const res = await new ContactosRequestListByFilter(data, this.authToken).executeAsPromise();
		return (
			{
				...res,
				items: res.items
			}
		);
	}

    buildModelFromItems(item: ContactoReadDto): Contacto{
		return new Contacto(item);
	}

	protected transformDtoIntoModel(dto: BaseResponse<ContactoReadDto>): BaseResponse<Contacto> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}
}