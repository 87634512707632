import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";
import { BaseFilter } from "../../../../domain/BaseFilter";
import BaseResponse from "../../../../domain/BaseResponse";
import { formatDDMMYYYY } from "../../../../utils/DateUtil";
import Licitacion from "../../../Oportunidades/domain/model/Licitacion";
import OfertasRepository from "../../domain/OfertasRepository";
import ClonarOfertas from "../../domain/model/ClonarOfertas";
import Ofertas, { CopiarOfertasResponse, OfertasCreationDto, OfertasDto, OfertasFilter, OfertasUpdateDto } from "../../domain/model/Ofertas";
import LicitacionesRequestGetByCodigo from "../ws/Licitaciones.Request.GetByCodigo";
import OfertasRequestClone from "../ws/Ofertas.Request.Clone";
import OfertasRequestCreate from "../ws/Ofertas.Request.Create";
import OfertasRequestDownloadExcel from "../ws/Ofertas.Request.DownloadExcel";
import OfertasRequestGetById from "../ws/Ofertas.Request.GetById";
import OfertasRequestGetSumaToneladas from "../ws/Ofertas.Request.GetSumToneladas";
import OfertasRequestListByFilter from "../ws/Ofertas.Request.ListByFilter";
import OfertasRequesUpdate from "../ws/Ofertas.Request.Update";
import OfertasUpdateDriveUrl from "../ws/Ofertas.Update.DriveUrl";

export default class OfertasApiRepository
	extends ApiRepositoryBase<
		OfertasDto,
		Ofertas
	> implements OfertasRepository {

	async getLicitacionByCodigo(codigo: number): Promise<Licitacion> {
		return await new LicitacionesRequestGetByCodigo(codigo, this.authToken).executeAsPromise();
	}

	async OfertasUpdateDriveUrl(ofertaId: string, url: string, descripcion:string): Promise<string> {
		
		return await new OfertasUpdateDriveUrl(ofertaId, url, descripcion, this.authToken).executeAsPromise();
	}

	async getById(id: string): Promise<Ofertas> {
		return await new OfertasRequestGetById(id, this.authToken).executeAsPromise();
	}

	addOfertas(data: OfertasCreationDto): Promise<any> {
		const data2backend={...data, 
			promotorId: data.promotorId?.toString(),
			delegacionId:data.delegacionId,
			codigoPostalId: data.codigoPostalId?.toString(),
			clienteId:data.clienteId?.toString(),
			motivoId:data.motivoId?.toString(),
			clientePerdidaId:data.clientePerdidaId?.toString(),
			provinciaId:data.provinciaId?.toString(),
			fechaOferta: formatDDMMYYYY(data.fechaOferta)
		}
			
		return new OfertasRequestCreate(data2backend, this.authToken).executeAsPromise();
	}
	
	updateOfertas(data: OfertasUpdateDto): Promise<OfertasUpdateDto> {
				
		const data2backend={...data, 
			promotorId: data.promotorId?.toString(),
			delegacionId:data.delegacionId,
			codigoPostalId: data.codigoPostalId?.toString(),
			clienteId:data.clienteId?.toString(),
			motvioId:data.motivoId?.toString(),
			clientePerdidaId:data.clientePerdidaId?.toString(),
			provinciaId:data.provinciaId?.toString(),
			fechaOferta: formatDDMMYYYY(data.fechaOferta)
		}
			
		return new OfertasRequesUpdate(data2backend, this.authToken).executeAsPromise();
	}

	cloneOfertas(data: ClonarOfertas): Promise<CopiarOfertasResponse[]> {
		return new OfertasRequestClone(data, this.authToken).executeAsPromise();
	}

	async fetchOfertasByFilter(data: BaseFilter<OfertasFilter>,  misdatos?:number| undefined): Promise<BaseResponse<Ofertas[]>> {
		let text= misdatos!=undefined? {...data, filter: {...data.filter, delegacionIds:[misdatos]}} as BaseFilter<OfertasFilter>:data;

		const res = await new OfertasRequestListByFilter(text,this.authToken).executeAsPromise();
		return (
			{
				...res,
				items: Ofertas.toArray(res.items)
			}
		);
	}

	fetchOfertasSumaToneladas(data: BaseFilter<OfertasFilter>): Promise<number> {
		return new Promise((resolve, reject) => {
			new OfertasRequestGetSumaToneladas(data, this.authToken)
				.request()
				.then((response) => {
					if (response.success) {
						resolve(response.data);
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error) => {
					reject(error);
				})
		})
	}

	protected mapResponseItems(dto: BaseResponse<OfertasDto[]>): BaseResponse<Ofertas[]> {
		return (
			{
				items: dto.items.map(item => this.buildModelFromItems(item)),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}

	buildModelFromItems(item: OfertasDto): Ofertas {
		return new Ofertas(item);
	}

	protected transformDtoIntoModel(dto: BaseResponse<OfertasDto>): BaseResponse<Ofertas> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}

	fetchOfertasDownloadExcel(data: BaseFilter<OfertasFilter>): Promise<string> {
		return new Promise((resolve, reject) => {
			new OfertasRequestDownloadExcel(data, this.authToken)
				.request()
				.then((response) => {
					if (response.success) {
						resolve(response.data);
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error) => {
					reject(error);
				})
		})
	}
}