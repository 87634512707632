import { LibroEstandarDetalleApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ApiRequest from "../../../../domain/ApiRequest";
import LibroEstandarDetalle from "../../domain/model/LibroEstandarDetalle";

export default class LibroEstandarDetalleRequestAdd extends ApiRequest<LibroEstandarDetalle, LibroEstandarDetalle> {
    public constructor(data: LibroEstandarDetalle, token: string) {
        super(
            "POST",
            LibroEstandarDetalleApiEndpoints.CREAR,
            { Authorization: `${token}` },
            data
        )
    }
}