import { useContext } from 'react';
import Dropdown from '../../../../components/ui/atoms/Dropdown/Dropdown';
import FormFieldGroup from '../../../../components/ui/objects/FormFieldGroup/FormField.Group';
import { TipoOportunidadDropdownContext } from "../../context/FilterFields.Context";


interface TipoOportunidadDropdownProps {
  excludedId?: number ;
  idTest?:string
}
  
const TipoOportunidadDropdown : React.FC<TipoOportunidadDropdownProps> = ({excludedId, idTest}) => {

  const options = useContext(TipoOportunidadDropdownContext);

  return (
    <div className="input-container"> 
     <FormFieldGroup>
			<label>Tipo Oportunidad</label>
			<Dropdown
				options={options.filter(p=>p.id!=excludedId?.toString())}
        singleSelection={true}
        label={idTest}
			/>
		</FormFieldGroup>
    </div>
  )
}

export default TipoOportunidadDropdown;
