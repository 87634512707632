import useForm, { FormErrors } from "../../../hooks/use-form";
import { fechaValida } from "../../../utils/DateUtil";
import { OfertasCreationDto, TipoOfertaEnum } from "../domain/model/Ofertas";

export type OfertasFormDto = OfertasCreationDto;

export interface UseOfertasInput {
	initialValues?: Partial<OfertasFormDto>,
	handleOnSubmit: (data: OfertasFormDto) => void
}

function useOfertasForm(
	{
		initialValues,
		handleOnSubmit,
	}: UseOfertasInput
) {

	function onSubmit(valid: boolean, data: OfertasFormDto, errors: FormErrors<OfertasFormDto>) {
		if (valid) {
			handleOnSubmit(data);
		}
	}

	function validate(data: OfertasFormDto): FormErrors<OfertasFormDto> {
		const COD_ESTADO_OFERTA_PERDIDA = 6;
		let errors: FormErrors<OfertasFormDto> = {};

		const {
			delegacionId,
			obraDescripcion,
			provinciaId,
			toneladas,
			iva,
			codigoPostalId,
			fechaOferta,
			licitacionCodigo,
			tipoOfertaId,
			clienteId,
			promotorId,
			estadoOfertaId,
			motivoId,
			// comercialId,
		} = data;

		const fieldIsRequired = "Campo obligatorio.";
		const fieldFormatError = " Formato incorrecto"
		if (delegacionId===undefined) {
			errors = { ...errors, delegacionId: fieldIsRequired };
		}

		if (!obraDescripcion || !obraDescripcion.length) {
			errors = { ...errors, obraDescripcion: fieldIsRequired }
		}

		if (provinciaId === undefined ) {
			errors = { ...errors, provinciaId: fieldIsRequired }
		}

		if (toneladas == null || toneladas == undefined || toneladas.toString() == "") {
			errors = { ...errors, toneladas: fieldIsRequired }
		}
		
		if (iva == null || iva == undefined || iva.toString() == "") {
			errors = { ...errors, iva: fieldIsRequired }
		}
		
		if (codigoPostalId == null || codigoPostalId == undefined || codigoPostalId.toString() == "") {
			errors = { ...errors, codigoPostalId: fieldIsRequired }
		}

		if (tipoOfertaId==TipoOfertaEnum.Licitacion && (licitacionCodigo== undefined ||  licitacionCodigo== 0 )) {
			errors = { ...errors, licitacionCodigo: fieldIsRequired }
		}
		// if (comercialId === undefined ) {
		// 	errors = { ...errors, comercialId: fieldIsRequired }
		// }

		if ((!promotorId || promotorId == "") && tipoOfertaId==TipoOfertaEnum.Licitacion) {
			errors = { ...errors, promotorId: fieldIsRequired }
		}

		if (!clienteId || clienteId == "") {
			errors = { ...errors, clienteId: fieldIsRequired }
		}

		if (!fechaValida(fechaOferta.toString()))
			errors = { ...errors, fechaOferta: fieldFormatError };

		if (estadoOfertaId === COD_ESTADO_OFERTA_PERDIDA && (!motivoId)) {
			errors = { ...errors, motivoId: fieldIsRequired }
		}

		return errors;
	}

	return useForm<OfertasFormDto>({ initialValues, validate, handleOnSubmit: onSubmit });

}

export default useOfertasForm;