import { BaseEntity, usePsQuery } from "@pavabits/components";
import { useCallback, useContext } from "react";
import { COD_DELEGACIONES_CON_TODAS } from "../../../assets/constants/Constantes";
import useSearchCallback from "../../../components/ui/atoms/SuggestionInput/hooks/useSearchCallback";
import useSelectorItems, { SelectorType } from "../../../components/ui/atoms/SuggestionInput/hooks/useSelectorItems";
import BancosRepository from "../../../domain/BancosRepository";
import DelegacionesRepository from "../../../domain/DelegacionesRepository";
import EstadosOportunidadesRepository from "../../../domain/EstadosOportunidadesRepository";
import Banco from "../../../domain/model/Banco";
import BancosApiRepository from "../../../infraestructure/api/Bancos.ApiRepository";
import DelegacionesApiRepository from "../../../infraestructure/api/Delegaciones.ApiRepository";
import EstadoOportuniadesApiRepository from "../../../infraestructure/api/EstadoOportunidades.ApiRepository";
import UnidadObraApiRepository from "../../Estudios/Infraestructure/api/UnidadObra.ApiRepository";
import UnidadObraRepository from "../../Estudios/domain/UnidadObraRepository";
import { AuthContext } from "../../Login/AuthContextProvider";
import { FilterTag } from "../context/FilterFields.Context";
import { OportunidadesFilterHandlersContext } from "../context/Oportunidades.Context";
import ContratistaRepository from "../domain/ContratistaRepository";
import PromotorRepository from "../domain/PromotorRepository";
import ProvinciaRepository from "../domain/ProvinciaRepository";
import Contratista from "../domain/model/Contratista";
import Promotor from "../domain/model/Promotor";
import Provincia from "../domain/model/Provincia";
import ContratistaApiRepository from "../infraestructure/api/Contratista.ApiRepository";
import PromotorApiRepository from "../infraestructure/api/Promotor.ApiRepository";
import ProvinciaApiRepository from "../infraestructure/api/ProvinciaApiRepository";
import { DateFilter, SelectorFilter, ValueRangeFilter } from "./useFilters/FilterTypes";

interface UseSelectorInput extends SelectorFilter { };

interface UseDateInput extends DateFilter { };

interface UseValueRangeInput {
	rangeMin: ValueRangeFilter,
	rangeMax: ValueRangeFilter,
};

export interface UseCheckOutput {
	field: {
		options: { id: string, text: string }[],
		onCheck: (value: any) => void,
		onClear: () => void,
	}
}

export interface UseCheckStrOutput {
	field: {
		options: { id: string, text: string }[],
		onCheck: (value: string) => void,
		onClear: () => void,
	}
}

export interface UseFieldOutput {
	tag: FilterTag,
	field: { input: string, suggestions: SelectorType[], onInputChange: (event: any) => void },
}

export interface UseDropdownOutput {
	tag: FilterTag,
	options: SelectorType[]
}

export interface UseDateOutput {
	tag: FilterTag,
	field: { onDateSelect: (dates: string[]) => void }
}

function useSuggestionSelector<T extends BaseEntity>(fieldInput: UseSelectorInput, callback: (search: string) => Promise<T[]>) {

	const { value, onClear, onChange } = fieldInput;

	const { input, results, onInputChange } = useSearchCallback(callback);

	const { suggestions, text, clearAll } = useSelectorItems(results, value, onChange, false);

	const handleClear = () => {
		clearAll();
		onClear();
	}

	return {
		tag: { text, onClear: handleClear },
		field: { input, suggestions, onInputChange },
	}
}

function useDropdownSelector<T extends BaseEntity>(fieldInput: UseSelectorInput, entities: T[]) {

	const { value, onChange, onClear } = fieldInput;

	const { suggestions, text, clearAll } = useSelectorItems(entities, value, onChange, false);

	function handleClear() {
		clearAll();
		onClear();
	}
	return {
		tag: { text, onClear: handleClear },
		options: suggestions
	}
}

function useDateSelector(fieldInput: UseDateInput, label: string) {

	const { value, onChange, onClear } = fieldInput;

	const [desde, hasta] = value;

	const text = desde && desde.length && hasta && hasta.length ?
		`${Intl.DateTimeFormat("es-ES").format(new Date(desde))} - ${Intl.DateTimeFormat("es-ES").format(new Date(hasta))}`
		: "";
	return {
		tag: { text, label, onClear },
		field: {
			onDateSelect: onChange,
		}
	}
}

function usePromotorField(fieldInput: UseSelectorInput): UseFieldOutput {

	const { getToken } = useContext(AuthContext);

	const searchPromotores = useCallback((search: string) => {
		const repo: PromotorRepository = new PromotorApiRepository(getToken());
		return repo.searchPromotores({ filter: { descripcion: search } });
	}, [getToken]);

	const { field, tag } = useSuggestionSelector<Promotor>(fieldInput, searchPromotores);

	return {
		tag: { ...tag, label: "Promotores" },
		field,
	}
}


function useProvinciaField(fieldInput: UseSelectorInput): UseFieldOutput {

	const { getToken } = useContext(AuthContext);

	const searchProvincias = useCallback((search: string) => {
		const repo: ProvinciaRepository = new ProvinciaApiRepository(getToken());
		return repo.searchProvincias({ filter: { descripcion: search } });
	}, [getToken]);

	const { field, tag } = useSuggestionSelector<Provincia>(fieldInput, searchProvincias);

	return {
		tag: { ...tag, label: "Provincias" },
		field,
	}
}

function useEstadoOportunidadesDropdown(fieldInput: UseSelectorInput) {

	const { getToken } = useContext(AuthContext);

	const fetchEstadoOportunidades = useCallback(
		() => {
			const repo: EstadosOportunidadesRepository = new EstadoOportuniadesApiRepository(getToken());
			return repo.fetchEstadosOportunidades({
			})
		}, [getToken])

	const { data } = usePsQuery(fetchEstadoOportunidades);

	const { options, tag } = useDropdownSelector(fieldInput, data);

	return {
		tag: { ...tag, label: "Estados" },
		options
	}
}


function useDelegacionesDropdown(fieldInput: UseSelectorInput) {

	const { getToken } = useContext(AuthContext);

	const fetchDelegaciones = useCallback(
		() => {
			const repo: DelegacionesRepository = new DelegacionesApiRepository(getToken());
			return repo.fetchDelegaciones({
				filter: {
					ids: COD_DELEGACIONES_CON_TODAS
				}
			})
		}, [getToken])

	const { data } = usePsQuery(fetchDelegaciones);

	const { options, tag } = useDropdownSelector(fieldInput, data);

	return {
		tag: { ...tag, label: "Delegaciones" },
		options
	}
}

export function useUnidadesObraDropdown(fieldInput: UseSelectorInput) {

	const { getToken } = useContext(AuthContext);

    const fetchUnidadesObra = useCallback(
        () => {
            const repo: UnidadObraRepository = new UnidadObraApiRepository(getToken());
            return repo.getConFiltro("")
        }, [getToken])
		
		const { data } = usePsQuery(fetchUnidadesObra);

		const { options, tag } = useDropdownSelector(fieldInput, data);

		return {
			options
		}
	}


function useContratistaDropdown(
	fieldInput: UseSelectorInput
) {

	const { value, onChange, onClear } = fieldInput;
	const data = [
		{
			id: "0",
			text: "No",
			//value: value && value.includes(ContratistaOptions.SI),
			onClick: onChange,
		},
		{
			id: "1",
			text: "Sí",
			//value: value && value.includes(ContratistaOptions.NO),
			onClick: onChange,
		}
	]

	const { options, tag } = useDropdownSelector({ value: value ?? [], onChange: onChange, onClear }, data);

	return {
		options,
		tag: { ...tag, label: "Con Contratista" },
	}
}


function useAsfalteroDropdown(
	fieldInput: UseSelectorInput
) {

	const { value, onChange, onClear } = fieldInput;

	enum AsfalteroOptions {
		SI = "S",
		NO = "N",
		No_Informado = "V"
	}

	const data = [
		{
			id: AsfalteroOptions.SI,
			text: "SI",
			selected: value && value.includes(AsfalteroOptions.SI),
			onClick: onChange,
		},
		{
			id: AsfalteroOptions.NO,
			text: "NO",
			selected: value && value.includes(AsfalteroOptions.NO),
			onClick: onChange,
		},
		{
			id: AsfalteroOptions.No_Informado,
			text: "No Informado",
			selected: value && value.includes(AsfalteroOptions.No_Informado),
			onClick: onChange,
		}
	]

	const { options, tag } = useDropdownSelector({ value: value ?? [], onChange: onChange, onClear }, data);

	return {
		options,
		tag: { ...tag, label: "Asfaltera" },
	}
}

function useLicitamosDropdown(fieldInput: UseSelectorInput
	) {
	
		const { value, onChange, onClear } = fieldInput;
	
		enum LicitamosOptions {
			SI = "S",
			NO = "N",
			NO_INFORMADO = " ",
		}
	
		const data = [
			{
				id: LicitamosOptions.SI,
				text: "SI",
				selected: value && value.includes(LicitamosOptions.SI),
				onClick: onChange,
			},
			{
				id: LicitamosOptions.NO,
				text: "NO",
				selected: value && value.includes(LicitamosOptions.NO),
				onClick: onChange,
			},
			{
				id: LicitamosOptions.NO_INFORMADO,
				text: "NO INFORMADO",
				selected: value && value.includes(LicitamosOptions.NO_INFORMADO),
				onClick: onChange,
			}
		]
	
		const { options, tag } = useDropdownSelector({ value: value ?? [], onChange: onChange, onClear }, data);
	
		return {
			options,
			tag: { ...tag, label: "Licitamos" },
		}
}

function useTipoOportunidadDropdown(fieldInput: UseSelectorInput
	) {
	
		const { value, onChange, onClear } = fieldInput;
	
		enum tipoOportunidadOptions {
			Licitacion = "1",
			Oferta = "2"
		}
	
		const data = [
			{
				id: tipoOportunidadOptions.Licitacion,
				text: "Licitación",
				selected: value && value.includes(tipoOportunidadOptions.Licitacion.toString()),
				onClick: onChange,
			},
			{
				id: tipoOportunidadOptions.Oferta,
				text: "Oferta",
				selected: value && value.includes(tipoOportunidadOptions.Oferta.toString()),
				onClick: onChange,
			}
		]
	
		const { options, tag } = useDropdownSelector({ value: value ?? [], onChange: onChange, onClear }, data);
	
		return {
			options,
			tag: { ...tag, label: "Tipo Oportunidad" },
		}
}

function useContratistaField(fieldInput: UseSelectorInput) {

	const { getToken } = useContext(AuthContext);

	const searchContratista = useCallback((search: string) => {
		const repo: ContratistaRepository = new ContratistaApiRepository(getToken());
		return repo.searchContratista({ filter: { descripcion: search, asfaltero: "" } });
	}, [getToken]);

	const { field, tag } = useSuggestionSelector<Contratista>(fieldInput, searchContratista);

	return {
		tag: { ...tag, label: "Contratistas" },
		field,
	}
}

function useBancoField(fieldInput: UseSelectorInput) {

	const { getToken } = useContext(AuthContext);

	const searchBanco = useCallback((search: string) => {
		const repo: BancosRepository = new BancosApiRepository(getToken());
		return repo.fetchBancos({ filter: { busqueda: search } });
	}, [getToken]);

	const { field, tag } = useSuggestionSelector<Banco>(fieldInput, searchBanco);
	return {
		tag: { ...tag, label: "Bancos" },
		field,
	}
}

function useToneladasField(
	fieldInput: UseValueRangeInput,
) {

	const { rangeMin, rangeMax } = fieldInput;
	const closedRange = rangeMin.value && rangeMax.value;

	const toneladasRangeFilter = {
		tag: {
			label: "Toneladas",
			text:
				`${rangeMin.value ?
					`${closedRange ? '' : '>='} ${rangeMin.value} Tm`
					: ''}`
				+ `${closedRange
					? ' - '
					: ''}` +
				`${rangeMax.value ?
					`${closedRange ? '' : '<='} ${rangeMax.value} Tm`
					: ''
				}`,
			onClear: () => {
				rangeMin.onClear();
				rangeMax.onClear();
			}
		},
		onMinChange: rangeMin.onChange,
		onMaxChange: rangeMax.onChange,
	}

	return toneladasRangeFilter;
}

function useFilterFields() {

	const {
		onSelectField,
		onSearchField,
		onRangeField,
		onDateField,
	} = useContext(OportunidadesFilterHandlersContext);

	const promotorInput = onSelectField("promotorIds");
	const promotorFilter = usePromotorField(promotorInput);

	const obraDescripcionFilter = onSearchField("obraDescripcion");



	const provinciaInput = onSelectField("provinciaIds");
	const provinciaFilter = useProvinciaField(provinciaInput);

	const estadoOpoInput = onSelectField("estadoOportunidadIds");
	const estadoOportunidadFilter = useEstadoOportunidadesDropdown(estadoOpoInput);

	const delegacionInput = onSelectField("delegacionIds");
	const delegacionesFilter = useDelegacionesDropdown(delegacionInput);

	const fechaRegistroInput = onDateField("fechaRegistroDesde", "fechaRegistroHasta");

	const fechaRegistroFilter = useDateSelector(fechaRegistroInput, "Fecha Registro")

	const fechaAdjudicacionInput = onDateField("fechaAdjudicacionDesde", "fechaAdjudicacionHasta");

	const fechaAdjudicacionFilter = useDateSelector(fechaAdjudicacionInput, "Fecha Adjudicacion")

	const contratistaFieldInput = onSelectField("contratistaIds");
	const contratistaSuggestionFilter = useContratistaField(contratistaFieldInput);

	const bancoFieldInput = onSelectField("contratistaIds");
	const bancoSuggestionFilter = useBancoField(bancoFieldInput); 

	const conContratistaInput = onSelectField("conContratista");

	const contratistaFilter = useContratistaDropdown(conContratistaInput);

	const asfalteroInput = onSelectField("asfalteraIds")
	const asfalteroFilter = useAsfalteroDropdown(asfalteroInput);


	const tipoOpoInput = onSelectField("tipoOportunidadId");
	const tipoOportunidadFilter = useTipoOportunidadDropdown(tipoOpoInput);

	const licitamosInput = onSelectField("licitamos");
	const licitamosFilter = useLicitamosDropdown(licitamosInput);

	const toneladasFilter = useToneladasField(
		{
			rangeMin: onRangeField("minToneladas"),
			rangeMax: onRangeField("maxToneladas"),
		}
	);

	const oportunidadCodigoFilter = onSearchField("codigo");
	const licitacionCodigoFilter = onSearchField("licitacionCodigo");
	const ofertaCodigoFilter = onSearchField("ofertaCodigo");

	return {
		promotorFilter,
		obraDescripcionFilter,
		provinciaFilter,
		delegacionesFilter,
		contratistaFilter,
		asfalteroFilter,
		licitamosFilter,
		fechaRegistroFilter,
		fechaAdjudicacionFilter,
		contratistaSuggestionFilter,
		toneladasFilter,
		bancoSuggestionFilter,
		oportunidadCodigoFilter,
		licitacionCodigoFilter,
		ofertaCodigoFilter,
		estadoOportunidadFilter,
		tipoOportunidadFilter
	}
}

export default useFilterFields;

