import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../Login/AuthContextProvider';
import CustomGridView from '../../components/CustomGridView';
import PaginationComponent from '../../components/ui/molecules/Pagination/PaginationComponent';
import useDebounce from '../../hooks/useDebounce';
import FilterModal from '../../components/ui/atoms/FilterModal/FilterModal';
import ContactosNameSearchField from './ContactosNameSearchField';
import ContactosPhoneSearchField from './ContactosPhoneSearchField';
import { GlobalContext, GlobalContextType } from '../../context/Global.Context';
import ContactosTagCloud from './ContactosTagCloud';
import { useLoading } from '../../context/Loading.Context';
import ContactosRepository from './domain/ContactosRepository';
import ContactosApiRepository from './infrastructure/api/Contactos.ApiRepository';
import Contacto from './domain/model/Contacto';
import BaseResponse from '../../domain/BaseResponse';
import LabelButton from '../../components/ui/atoms/Buttons/LabelButton';
import { Routes } from '../../router/routes/Routes';
import { HistoryHandler } from '../../context/History.Context';
import useColumnasGrid from '../../hooks/useColumnasGrid';

const Contactos = () => {
    const [contactos, setContactos] = useState<Contacto[]>([]);
    const [contactosResponse, setContactosResponse] = useState<BaseResponse<Contacto[]>>();
    const {getToken} = useContext(AuthContext);
    const {setLoading} = useLoading();
    const componentName = "contactos"
    const contactosRepo: ContactosRepository = new ContactosApiRepository(getToken());
    const { goToRoute } = useContext(HistoryHandler);
    const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;

    const sorting = globalState[componentName]?.order ? globalState[componentName]?.order?.sortingCriteria + (globalState[componentName]?.order?.desc ? " DESC" : "") : "LastModificationTime DESC";;
    // const sorting = globalState?.contactos?.order ?? "LastModificationTime DESC";
    const maxResultCount = globalState[componentName]?.pagination?.maxResultCount ?? 10;
    const skipCount = globalState[componentName]?.pagination?.skipCount ?? 0;
    const { cols } = useColumnasGrid("BACKENDOPORTUNIDADES", "CONTACTOS");
    
    const filterValues = {
        telefono: globalState[componentName]?.filters?.telefono,
        personaContacto: globalState[componentName]?.filters?.personaContacto
    }

    const debouncedPhoneSearchValue = useDebounce(filterValues.telefono, 1000)
    const debouncedNameSearchValue = useDebounce(filterValues.personaContacto, 1000)


    const handleSearch = () => {
        setLoading(true);
        return contactosRepo.fetchContactosByFilter({
            filter: filterValues,
            sortingCriteria: sorting,
            maxResultCount: maxResultCount,
            skipCount: skipCount
        })
        .then((res) => {
            setContactos(res.items);
            setContactosResponse(res)
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        handleSearch()
    }, [debouncedPhoneSearchValue, debouncedNameSearchValue])

    useEffect(() => {
       handleSearch()
    }, [maxResultCount, skipCount, sorting])

    const handleSetMaxCount = (count: number) => {
        updateGlobalState(componentName, {pagination: {...globalState[componentName]?.pagination, maxResultCount: count}})
    }

    const handlePageSelect = (page: number) => {
        updateGlobalState(componentName, {pagination: {...globalState[componentName]?.pagination, skipCount: page * maxResultCount}})
    }
    
    const handlePageBackwards = () => {
        updateGlobalState(componentName, {pagination: {...globalState[componentName]?.pagination, skipCount: (skipCount / maxResultCount - 1) * maxResultCount}})
    }
    
  return (
		<div className="grid-view-container" data-cy="GridContactos">
            <h3>Contactos</h3>
            <div className="filters-container">

            <div className="filters-modal-container">
                        <FilterModal 
                        titleShow="Filtros" 
                        modalTitle="Selección de filtros"
                        label = {"TituloModal"}
						//modalSubtitle="Introduzca los parámetros para filtrar"
                        filters={
                        <>
                        <ContactosNameSearchField />
                        <ContactosPhoneSearchField />
                        </>
                        }
			            />
                        
                    
                    <LabelButton 
                    label="Clientes"
                    type="submit"
                    onClick={() => goToRoute(Routes.CLIENTES)}
                     />
                    <LabelButton
                    label="Seguimiento Clientes"
                    type="submit"
                    onClick={() => goToRoute(Routes.SEGUIMIENTO_CLIENTES)}
            /> 
            </div>
                    <ContactosTagCloud />
                 </div>

        {contactos && cols && <CustomGridView 
            title={componentName} 
            columns={cols}
            clickable
            data={contactos}
            sorting={sorting}
            />
        }
       
        {contactosResponse && 
            <div className="footer-section">
            <PaginationComponent
                totalCount={contactosResponse?.totalCount}
                currentPage={contactosResponse?.currentPage}
                maxCount={maxResultCount}
                onPageSelect={handlePageSelect}
                onSetMaxCount={handleSetMaxCount}
                onPageBackwards={handlePageBackwards}
            />
            </div>}
    </div>
  )
}

export default Contactos