import ApiRequest from "../../../../domain/ApiRequest";
import { OfertasEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import Ofertas from "../../domain/model/Ofertas";

export default class OfertasRequestGetById extends ApiRequest<undefined, Ofertas>
{
	constructor(id: string, token: string) {
		super(
			"GET",
			OfertasEndpoints.BASE_ENPOINT + id,
			{ Authorization: token },
			undefined,
			{ }
		)
	}
}