import { BaseApiRequest } from "@pavabits/components";
import BaseReponse from "../../domain/BaseResponse";
import { AdjudicacionDto } from "../../domain/model/Adjudicacion";
import { AdjudicacionEndpoints } from "../../modules/Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class AdjudicaciontListByFilter extends BaseApiRequest<
	{},
	BaseReponse<AdjudicacionDto[]>
> {
	public constructor(data: string, token: string) {
		super(
			"GET",
			AdjudicacionEndpoints.BASE+"?Filters%5B0%5D.Key=CodigoLicitacion&Filters%5B0%5D.Value="+data,
			{},
			{ Authorization: `${token}` }
		)
	}
}