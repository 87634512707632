import { EquipoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ApiRequest from "../../../../domain/ApiRequest";
import Equipo, { EquipoDto, EquipoUpdateDto } from "../../domain/model/Equipo";
import ElementoEquipo, { ElementoEquipoUpdateDto } from "../../domain/model/ElementoEquipo";

export default class EquipoRequestUpdate extends ApiRequest<
EquipoUpdateDto,
Equipo
>
{
	constructor(data: EquipoUpdateDto, token: string) {
		super(
			"PUT",
			EquipoApiEndpoints.UPDATE_EQUIPO,
			{ Authorization: `${token}`},
			data,
		)
	}
}