import { BaseRepository } from "@pavabits/components";
import Tramo, { TramoDto, TramoFilter, TramoReadingDto } from "../../domain/model/Tramo";
import TramosRepository from "../../domain/TramosRepository";
import TramosRequestListByFilter from "../ws/Tramos.RequestListByFilter";

export default class TramosApiRepository
	extends BaseRepository<
		TramoDto,
		Tramo
	> implements TramosRepository {
        
    protected transformDtoIntoModel(dto: TramoDto): Tramo {
        return new Tramo(
			dto.id,
			dto.descripcion
		)
    }

	protected fillTextField (dto: TramoReadingDto[]): TramoReadingDto[] {
		if (dto && dto.length) {
			return dto.map(item => {
				return {...item, text: item.nombre + " - " + item.precioTonelada}
			});
		}
		return []
	}

	getConFiltro(data: TramoFilter): Promise<TramoReadingDto[]> {
		return new Promise((resolve, reject) => {
			return new TramosRequestListByFilter(data, this.authToken)
			.request()
			.then((response) => {
				if (response.success) {
					resolve(this.fillTextField(response.data));
				} else {
					reject(`Error ${response.errorCode}: ${response.errorMessage}`);
				}
			})
			.catch((error) => {
				reject(error);
			})
		})
	}

	// async getTramoById(id: string): Promise<TramoReadingDto> {
    //     const res = await new TramosRequestGetById(id, this.authToken).executeAsPromise();
    //     return res;
    // }
}
