import ApiRequest from "../../../../domain/ApiRequest";
import { ClausulasOfertaApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class ClausulaOfertaDelete extends ApiRequest<{}, boolean> 
{
    constructor(id: string, token: string) {
        super(
            "DELETE",
            ClausulasOfertaApiEndpoints.DELETE+id,
            {Authorization: `${token}`},
             {}
        )
    }
}