

interface FormaPagoContratoEntity {
	id?: string ,
	formaPagoExtendida?: string,
	tipoFormaPagoContratoId: number,
	plazoPago?: number,
	plazoEjecucion:string,
	plazoGarantia:string,
	etapas:number,
	importeEtapa:number,
	retencion:number,
	diasDuplicadoActa:number,
}


export default class FormaPagoContrato implements FormaPagoContratoEntity {

	id?: string ;
	formaPagoExtendida?: string;
	tipoFormaPagoContratoId: number;
	plazoPago?: number;
	plazoEjecucion:string;
	plazoGarantia:string;
	etapas:number;
	importeEtapa:number;
	retencion:number;
	diasDuplicadoActa:number;

	constructor(entity: FormaPagoContratoEntity) {
		this.id = entity.id!;
		this.tipoFormaPagoContratoId = entity.tipoFormaPagoContratoId;
		this.plazoEjecucion = entity.plazoEjecucion;
		this.plazoGarantia = entity.plazoGarantia;
		this.etapas = entity.etapas;
		this.importeEtapa = entity.importeEtapa;
		this.retencion = entity.retencion;
		this.diasDuplicadoActa = entity.diasDuplicadoActa;
	}

	public static toArray(entities: FormaPagoContratoEntity[]): FormaPagoContrato[] {
		return entities ? entities.map(entity => { return new FormaPagoContrato(entity) }) : [];
	}
}

