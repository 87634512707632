import { useContext } from "react"
import MinMaxInput from "../../../../components/ui/atoms/MinMaxInput/MinMaxInput"
import FormFieldGroup from "../../../../components/ui/objects/FormFieldGroup/FormField.Group";
import { OfertasFilterContext } from "../../context/Ofertas.Context";

const ToneladasMinMax: React.FC<{idTestMin?:string, idTestMax?:string}> = ({idTestMin, idTestMax}) => {

	const { onRangeField } = useContext(OfertasFilterContext);

	return (
		<div className='input-container'>
		<FormFieldGroup>
			<label>Toneladas</label>
			<MinMaxInput
				minInputChange={onRangeField("minToneladas").onChange}
				maxInputChange={onRangeField("maxToneladas").onChange}
				idTestMin="MinToneladasOfertaFiltro"
				idTestMax="MaxToneladasOfertaFiltro"
			/>
		</FormFieldGroup>
		</div>
	)
}

export default ToneladasMinMax;