import { FC, useEffect, useState } from "react";
import IconButton from "../../../components/ui/atoms/Buttons/IconButton";
import { CustomButton } from "../../../shared/components/Buttons/CustomButton";
import CustomInput from "../../../shared/components/Inputs/CustomInput/CustomInput";
import { formatDate2input, formatDate2String, formatDDMMYYYY, noAplicaDate, quitarFechasVacias } from "../../../utils/DateUtil";
import { formatNumber } from "../../../utils/NumberUtil";
import Ofertas from "../../Ofertas/domain/model/Ofertas";
import ContratoOfertasModalSection from "../../Ofertas/views/objects/Modal section/ContratoOfertas.ModalSection";
import Presupuesto, { NumeroPresupuestoFormat } from "../../Presupuestos/domain/model/Presupuesto";

interface Props {
    title: string
    idPadre: string,
    ofertaId?: string,
    oferta: Ofertas,
    disabled?: boolean,
    presupuestoContratado?:Presupuesto
    updateData:()=>void;
}

const ContratosView: FC<Props> = ({
    idPadre,
    title,
    ofertaId,
    oferta,
    disabled,
    presupuestoContratado,
    updateData,

}) => {
    const [openPresupuestoModal, setOpenPresupuestoModal] = useState<boolean>(false);
    const [contratada, setContratada] = useState(false)
   
    const handleCloseModal = () => {
        setOpenPresupuestoModal(false);
        updateData();
    }

    useEffect(() => {
        setContratada(true)
    }, [presupuestoContratado])

    return (
        <>
            <div className="inline-grid">
                    <div className="inline-grid-header">
                        <label>Fecha Contrato</label>
                        <label>Fecha Envío</label>
                        <label>Ref. Presupuesto</label>
                        <label>Importe Presupuesto</label>

                         <div className="inline-button">

                    {contratada && !oferta.contrato ?
                    <>
                        <CustomButton icon="add"
                            type="button"
                            className={"contact-btn"}
                            onButtonClick={()=> setOpenPresupuestoModal(true) }
                            disabled={disabled}
                        />
                        </>
                    : ""}
                </div>
                    </div>

                    {oferta.contrato &&
                <div className="inline-grid-container">

                <div key={"a"} className="inline-grid-item">
                    <CustomInput
                                    key={""}
                                    value={formatDate2String(formatDDMMYYYY(oferta.contrato.fechaContrato.split('T')[0]))}
                                    className={'platform-input secondary-input'}
                                    formClassName={"secondary-input"}
                                    onChange={(e) => { }}
                                    disabled={true}
                                    type="date"
                                />
                            <CustomInput
                                    key={""}
                                    value={ oferta.contrato.fechaEnvio ? quitarFechasVacias(formatDate2String(formatDDMMYYYY(oferta.contrato.fechaEnvio.split('T')[0]))) : ""}
                                    className={'platform-input secondary-input'}
                                    formClassName={"secondary-input"}
                                    onChange={(e) => { }}
                                    disabled={true}
                                    type="date"
                                />
                         <CustomInput
                                    key={""}
                                    value={presupuestoContratado && NumeroPresupuestoFormat(oferta,  presupuestoContratado)}
                                    className={'platform-input secondary-input'}
                                    formClassName={"secondary-input"}
                                    onChange={(e) => { }}
                                    disabled={true}
                                />
                                <CustomInput
                                    key={""}
                                    value={ formatNumber(oferta.contrato.importePresupuesto?+oferta.contrato.importePresupuesto:0)}
                                    className={'platform-input secondary-input'}
                                    formClassName={"secondary-input"}
                                    onChange={(e) => { }}
                                    disabled={true}
                                />
                                
                              
                                 <div className="inline-buttons">
                                 {
                                    oferta.contrato.pdfUrl && <IconButton icon='picture_as_pdf'
                                            onClick={() => { window.open(oferta.contrato?.pdfUrl); }} />
                                
                                }
                                    <IconButton icon={'edit'}
                                        onClick={() => { setOpenPresupuestoModal(true) }}
                                        disabled={disabled}
                                    />
                                </div>
                        
                    </div>
                </div>}
                { !presupuestoContratado && 
                    <p> No existe ningún presupuesto contratado </p>
                }
            </div>
            
            {
                openPresupuestoModal && presupuestoContratado && oferta &&
                <ContratoOfertasModalSection
                    opened={openPresupuestoModal}
                    oferta={oferta}
                    handleCloseModal={handleCloseModal}
                    presupuestoContratado={presupuestoContratado}
                   handleRefresh={()=>{}}>
                </ContratoOfertasModalSection>   
            }

        </>
    )
}

export default ContratosView;