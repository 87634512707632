import { LibroEstandarDetalleApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ApiRequest from "../../../../domain/ApiRequest";
import { LibroEstandarDetalleDto, LibroEstandarDetalleUpdateDto } from "../../domain/model/LibroEstandarDetalle";

export default class LibroEstandarDetalleRequestUpdate extends ApiRequest<
	LibroEstandarDetalleUpdateDto,
	LibroEstandarDetalleDto
> {
	constructor(data: LibroEstandarDetalleUpdateDto, token: string) {
		super(
			"PUT",
			LibroEstandarDetalleApiEndpoints.UPDATE,
			{ Authorization: `${token}` },
			data,
		)
	}
}