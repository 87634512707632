
import ApiRequest from "../../domain/ApiRequest";
import BaseResponse from "../../domain/BaseResponse";
import { UnidadObraMaterialApiEndpoints } from "../../modules/Oportunidades/infraestructure/oportunidades.api-endpoints";
import { UnidadMaterialFilter, UnidadMaterialReadDto, UnidadObraMaterialDto } from "../../modules/Unidades/domain/model/UnidadMaterial";
import { BaseFilter } from "../../domain/BaseFilter";

export default class UnidadObraMaterialesRequestConFiltro extends ApiRequest
    <
        BaseFilter<any>,
        BaseResponse<UnidadObraMaterialDto[]>
    > {
    public constructor(data: any, token: string) {
        super(
            "POST",
            UnidadObraMaterialApiEndpoints.CON_FILTRO,
            { Authorization: token },
			data
        )
    }
}