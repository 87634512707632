import React, { useContext } from 'react'
import FormFieldGroup from '../../../../../components/ui/objects/FormFieldGroup/FormField.Group'
import Dropdown from '../../../../../components/ui/atoms/Dropdown/Dropdown'
import { ComercialesDropdownContext } from '../../../../Oportunidades/context/FilterFields.Context'

const ComercialDropdown: React.FC<{ idTest?:string }> = ({idTest}) => {

  const options = useContext(ComercialesDropdownContext)

  return (
    <div className='input-container'>

      <FormFieldGroup>
        <label>Comercial</label>
        <Dropdown
          options={options}
          idTest={idTest}
        />
      </FormFieldGroup>
    </div>
  )
}

export default ComercialDropdown