import { useCallback, useContext, useEffect, useState } from 'react'
import { GlobalContext, GlobalContextType } from '../../../context/Global.Context';
import { AuthContext } from '../../Login/AuthContextProvider';
import DelegacionesRepository from '../../../domain/DelegacionesRepository';
import DelegacionesApiRepository from '../../../infraestructure/api/Delegaciones.ApiRepository';
import Delegacion from '../../../domain/model/Delegacion';
import FormField from '../../../components/ui/molecules/Form-field/FormField';
import Dropdown, { DropdownProps } from '../../../components/ui/atoms/Dropdown/Dropdown';
import FormFieldGroup from '../../../components/ui/objects/FormFieldGroup/FormField.Group';
import { COD_DELEGACIONES_CON_TODAS } from '../../../assets/constants/Constantes';

const SeguimientoClienteDelegacionDropdownField = () => {
  const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;
  const [optionsDelegacion, setOptionsDelegacion] = useState<Delegacion[]>([]);
  const componentName = "seguimientoCliente";
  const [value, setValue] = useState(globalState.seguimientoCliente?.filters?.delegacionId ?? "");
  const { getToken } = useContext(AuthContext);

  useEffect(() => {
    fetchDelegaciones();
  }, [value])

  const fetchDelegaciones = useCallback(
    () => {
        const repo: DelegacionesRepository = new DelegacionesApiRepository(getToken());
        return repo.fetchDelegaciones({
            filter: {
                ids: COD_DELEGACIONES_CON_TODAS
            }
            }).then((resp: Delegacion[]) => {
                setOptionsDelegacion(resp);
        })
    }, [getToken])

    
  const handleInputChange = (delegacion: Delegacion) => {
    setValue(delegacion.id);
    const delegacionObj: Delegacion = optionsDelegacion.find(((item: any) => item.id === delegacion.id))!;
    if (delegacionObj) {
        updateGlobalState(componentName, {
            filters: {...globalState.seguimientoCliente?.filters,
            "delegacionId":  +delegacionObj.id,
            "delegacionDescripcion": delegacionObj.text},
            pagination: {
                skipCount: 0
              }
        })
        }
    }

  
    return (
        <div className='input-container'>
            <FormFieldGroup>
            <FormField<DropdownProps>
                label="Delegación"
                options={optionsDelegacion.map(
                (element) => {
                    return {
                    text: element.descripcion,
                    id: element.id,
                    selected: value == +element.id ? true : false,
                    onClick: () => {handleInputChange(element)}
                    }
                })}
                disabled={false}
                singleSelection={false}
                component={Dropdown}
                idTest='FiltroDelegacionSeguimientoCliente'
            />
            </FormFieldGroup>
        </div>
    )
}

export default SeguimientoClienteDelegacionDropdownField