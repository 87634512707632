interface Props {
	value: string | number | undefined,
	disabled?: boolean,
	placeholder?: string,
	type?:string,
	className?:string,
	onChange?: (value: any) => void,
	min?: number,
	required?: boolean,
	defaultValue?: string
	data?: string
}

const Input: React.FC<Props> = (
	{
		value,
		disabled = false,
		placeholder,
		type,
		className,
		onChange,
		min,
		required,
		defaultValue,
		data
	}
) => {

	return (
        <input 
            value={value}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            onChange={onChange}
            type={type}
            min={min}
            onKeyDown={
                (e) => {
                    if (type === "number" && (e.key.toUpperCase() === "E" || (e.key === "-" && (!min || min >= 0)))) {
                        e.preventDefault();
                    }							
                }
            }
			required={required}
			defaultValue={defaultValue}
			data-cy={data}
        />
	)
}

export default Input;