import { AutoCompleteSearchFilter, BiasedType } from "../../../../domain/BaseFilter";
import SelectableItem, { SelectableItemDto } from "../../../../components/ui/atoms/SuggestionInput/model/SuggestionInput.Model";

type FilterExcludedProperties =
"id"
| "text";

type BiasedPromotor = BiasedType<Promotor, FilterExcludedProperties>;

export type PromotorFilter = AutoCompleteSearchFilter<BiasedPromotor>;

export interface PromotorDto extends SelectableItemDto {};

export default class Promotor extends SelectableItem {
	constructor(
		id: string,
		descripcion: string,
	) {
		super(id, descripcion)
	}
}