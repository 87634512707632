import React, { useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import CustomGridView from '../../../components/CustomGridView';
import LabelButton from '../../../components/ui/atoms/Buttons/LabelButton';
import FilterModal from '../../../components/ui/atoms/FilterModal/FilterModal';
import PaginationComponent from '../../../components/ui/molecules/Pagination/PaginationComponent';
import { GlobalContext, GlobalContextType } from '../../../context/Global.Context';
import { HistoryHandler } from '../../../context/History.Context';
import { useLoading } from '../../../context/Loading.Context';
import EventosCalendarApiRepository from '../../../domain/EventosCalendarApiRepository';
import useColumnasGrid from '../../../hooks/useColumnasGrid';
import useDebounce from '../../../hooks/useDebounce';
import EventoCalendarApiRepository from '../../../infraestructure/api/EventoCalendar.ApiRepository';
import { Routes } from '../../../router/routes/Routes';
import DeleteModal from '../../../shared/components/DeleteModal/DeleteModal';
import { formatDate2String } from '../../../utils/DateUtil';
import { AuthContext } from '../../Login/AuthContextProvider';
import SeguimientoClienteTagCloud from '../components/SeguimientoClienteTagCloud';
import SeguimientoHistoricoClienteRepository from '../domain/SeguimientoHistoricoClienteRepository';
import SeguimientoHistoricoCliente from '../domain/model/SeguimientoHistoricoCliente';
import SeguimientoClienteClienteIdSearchField from '../filters/SeguimientoClienteClienteIdSearchField';
import SeguimientoClienteContactoDropdownField from '../filters/SeguimientoClienteContactoDropdownField';
import SeguimientoClienteDelegacionDropdownField from '../filters/SeguimientoClienteDelegacionDropdownField';
import SeguimientoClienteFechaDateSelectorField from '../filters/SeguimientoClienteFechaDateSelectorField';
import SeguimientoClienteOfertaIdSearchField from '../filters/SeguimientoClienteOfertaIdSearchField';
import SeguimientoClienteOportunidadIdDropdownField from '../filters/SeguimientoClienteOportunidadIdDropdownField';
import SeguimientoClienteTipoDropdownField from '../filters/SeguimientoClienteTipoDropdownField';
import SeguimientoHistoricoClienteApiRepository from '../infrastructure/api/SeguimientoHistoricoClienteApiRepository';

const ClientesSeguimientoGrid: React.FC<{}> = () => {
    const { goToRoute } = useContext(HistoryHandler);
    const [seguimientos, setSeguimientos] = useState<any[]>([])
    const [seguimientosResponse, setSeguimientosResponse] = useState<any>()
    const { getToken, email } = useContext(AuthContext)
    const { isLoading, setLoading } = useLoading();
    const componentName = "seguimientoCliente";
    const { globalState, updateGlobalState } = useContext(GlobalContext) as GlobalContextType;
    const maxResultCount = globalState[componentName]?.pagination?.maxResultCount ?? 10;
    const skipCount = globalState[componentName]?.pagination?.skipCount ?? 0;
    const [showButtonCalendar, setShowButtonCalendar] = useState<boolean>(false);
    const repo: SeguimientoHistoricoClienteRepository = new SeguimientoHistoricoClienteApiRepository(getToken());
    const [isChecked, setIsChecked] = useState(true);
    const [listaEventos, setListaEventos] = useState<SeguimientoHistoricoCliente[]>([]);
    const sorting = globalState[componentName]?.order
        ? (globalState[componentName]?.order?.sortingCriteria + (globalState[componentName]?.order?.desc ? " DESC" : "")) : "lastModificationTime DESC";
    const { cols } = useColumnasGrid("BACKENDOPORTUNIDADES", "SEGUIMIENTOCLIENTES");

    const filterValues: any = {
        clienteId: globalState[componentName]?.filters?.clienteId,
        tipoId: globalState[componentName]?.filters?.tipoId,
        fechaSeguimientoDesde: globalState[componentName]?.filters?.fechaSeguimientoDesde,
        fechaSeguimientoHasta: globalState[componentName]?.filters?.fechaSeguimientoHasta,
        oportunidadCodigo: globalState[componentName]?.filters?.oportunidadCodigo,
        ofertaCodigo: globalState[componentName]?.filters?.ofertaCodigo,
        contactoNombre: globalState[componentName]?.filters?.contactoNombre,
        delegacionId: globalState[componentName]?.filters?.delegacionId,
        creationUsername: email
    }

    const debouncedClienteIdSearchValue = useDebounce(filterValues.clienteId, 1000)
    const debouncedTipoIdSearchValue = useDebounce(filterValues.tipoId, 1000)
    const debouncedDelegacionIdSearchValue = useDebounce(filterValues.delegacionId, 1000)
    const debouncedFechaDesdeSearchValue = useDebounce(filterValues.fechaSeguimientoDesde, 1000)
    const debouncedFechaHastaSearchValue = useDebounce(filterValues.fechaSeguimientoHasta, 1000)
    const debouncedOportunidadCodigoSearchValue = useDebounce(filterValues.oportunidadCodigo, 1000)
    const debouncedOfertaCodigoSearchValue = useDebounce(filterValues.ofertaCodigo, 1000)
    const debouncedContactoNombreSearchValue = useDebounce(filterValues.contactoNombre, 1000)

    const [openDelete, setOpenDelete] = useState<boolean>(false);


    const repoEventos: EventosCalendarApiRepository = useMemo(() => {
        return new EventoCalendarApiRepository(getToken());
    }, []);


    const onShowCalendar = async (seguimiento: SeguimientoHistoricoCliente) => {

        //Actualizamos la lista de seguimientos con los valores actualizados
        let updSeguimientos = seguimientos.map(e => {
            if (e.id == seguimiento.id) {
                e.eventSelected = seguimiento.eventSelected;
            }
            return e;
        });

        // Seteamos los seguimientos actualizados
        setSeguimientos(updSeguimientos);

        // Seteamos a la lista de eventos los eventos con eventSelected a true y eventoCalendar a false
        setListaEventos(updSeguimientos.filter(x => x.eventSelected && !x.eventoCalendar));

        // Mostramos el botón dependiendo del estado de los seguimientos
        if (updSeguimientos.find(x => x.eventSelected && !x.eventoCalendar)) {
            setShowButtonCalendar(true);
        } else {
            setShowButtonCalendar(false);
        }

    }

    const handleSubmitSendEventCalendar = async () => {
        setLoading(true);
        let seguimientoPost= seguimientos.filter((x: SeguimientoHistoricoCliente) => {

            if (x.eventSelected === true && (x.eventoCalendar === false || x.eventoCalendar === null)) {
                x.observacionesTipoSeguimiento = "";
                return x;
            }

        })
        repoEventos.postSendEventsCalendar(seguimientoPost)
            .then((response) => {
                setSeguimientos(seguimientos.map((x: SeguimientoHistoricoCliente) => {
                    if (response.find(i => i.id === x.id) !== undefined) {
                        x.eventoCalendar = true
                    }
                    return x;
                }));
                toast.success("Eventos generados correctamente");
            })
            .catch((error) => {
                toast.error(error);
            })
            .finally(() => {
                setLoading(false);
                setShowButtonCalendar(false);
            });
    }

    const [deletionId, setDeletionId] = useState<string>();
    const openConfirmationModal = (index: string) => {
        setDeletionId(index);
        setOpenDelete(true);
    }

    const handleCloseModalDelete = (remove: boolean) => {
        setOpenDelete(false);
        if (remove) {
            handleRemoveSeguimiento(deletionId!);
        }
    }

    const handleRemoveSeguimiento = (id: string) => {
        setLoading(true);
        repo.delete(id)
            .then(() => {
                setOpenDelete(false);
                toast.success("Seguimiento eliminado con éxito");
                if (isChecked) {
                    filterValues.creationUsername = email;
                } else {
                    filterValues.creationUsername = "";
                }
                fetchSeguimientos();
            })
            .catch(err => {
                console.error(err)
                toast.error("Error al eliminar el Seguimiento");
            })
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        if (isChecked) {
            filterValues.creationUsername = email;
        } else {
            filterValues.creationUsername = "";
        }
        fetchSeguimientos();
    }, [maxResultCount,
        skipCount,
        sorting,
        debouncedClienteIdSearchValue,
        debouncedTipoIdSearchValue,
        debouncedFechaDesdeSearchValue,
        debouncedFechaHastaSearchValue,
        debouncedOportunidadCodigoSearchValue,
        debouncedOfertaCodigoSearchValue,
        debouncedContactoNombreSearchValue,
        debouncedDelegacionIdSearchValue,
        email
    ])

    const fetchSeguimientos = () => {
        setLoading(true);
        return repo.fetchSeguimientosByFilter({
            filter: filterValues,
            maxResultCount: maxResultCount,
            skipCount: skipCount,
            sortingCriteria: sorting,
        })
            .then((res) => {
                setSeguimientos(res.items);
                setSeguimientosResponse(res);
            })
            .finally(() => {
                setLoading(false)
            });
    }

    const handleSetMaxCount = (count: number) => {
        updateGlobalState(componentName, { pagination: { ...globalState[componentName]?.pagination, maxResultCount: count } })
    }

    const handlePageSelect = (page: number) => {
        updateGlobalState(componentName, { pagination: { ...globalState[componentName]?.pagination, skipCount: page * maxResultCount } })
    }

    const handlePageBackwards = () => {
        updateGlobalState(componentName, { pagination: { ...globalState[componentName]?.pagination, skipCount: (skipCount / maxResultCount - 1) * maxResultCount } })
    }

    const downloadExcel = () => {

        if (isChecked) {
            filterValues.creationUsername = email;
        } else {
            filterValues.creationUsername = "";
        }

        repo.downloadExcel({
            filter: filterValues,
            maxResultCount: maxResultCount,
            skipCount: skipCount,
            sortingCriteria: sorting
        }).then((data) => {
            window.open(data);
        });
    }

    const onCheckChange = async () => {
        setIsChecked(!isChecked);
        if (!isChecked) {
            filterValues.creationUsername = email;
            updateGlobalState(componentName, 
                {pagination: {
                  skipCount: 0}},  
              )
        } else {
            filterValues.creationUsername = "";
        }

        fetchSeguimientos();
    }

    return (
        <div className="grid-view-container">
            <h3>Seguimiento Histórico de Clientes</h3>
            <div className="filters-container">
                <div className="filters-modal-container">
                    <FilterModal
                        titleShow="Filtros"
                        modalTitle="Selección de filtros"
                        //modalSubtitle="Introduzca los parámetros para filtrar"
                        downloadExcel={downloadExcel}
                        label = {"TituloModal"}
                        filters={
                            <>
                                <SeguimientoClienteClienteIdSearchField />
                                <SeguimientoClienteDelegacionDropdownField />
                                <SeguimientoClienteTipoDropdownField />
                                <SeguimientoClienteContactoDropdownField />
                                <SeguimientoClienteFechaDateSelectorField />
                                <SeguimientoClienteOportunidadIdDropdownField />
                                <SeguimientoClienteOfertaIdSearchField />
                            </>
                        }
                    />
                    <LabelButton
                        label="Clientes"
                        type="submit"
                        onClick={() => goToRoute(Routes.CLIENTES)}
                    />
                    <LabelButton
                        label="Contactos"
                        type="submit"
                        onClick={() => goToRoute(Routes.CONTACTOS)}
                    />
                    {showButtonCalendar && <LabelButton
                        label="Calendar"
                        type="submit"
                        onClick={() => handleSubmitSendEventCalendar()}
                    />}
                    <label>Seguimientos propios</label>
                    <input
                        checked={isChecked}
                        onChange={() => { onCheckChange() }}
                        type="checkbox"
                        data-cy="CheckboxSeguimientosPropios"
                    />
                </div>

                <SeguimientoClienteTagCloud idTest="FiltroTag"/>
            </div>

            {!isLoading && cols &&
                <CustomGridView
                    sorting={sorting}
                    title={componentName}
                    columns={cols}
                    clickable
                    data={seguimientos.map((item: SeguimientoHistoricoCliente) => {
                        return ({
                            id: item.id,
                            tipoSeguimientoDescripcion: item.tipoSeguimientoDescripcion,
                            clienteNombre: item.clienteNombre,
                            clienteId: item.clienteId,
                            nombreContacto: item.nombreContacto,
                            fechaSeguimiento: formatDate2String(item.fechaSeguimiento),
                            observaciones: item.observaciones,
                            oportunidadCodigo: item.oportunidadCodigo,
                            ofertaCodigo: item.ofertaCodigo == 0 ? "" : item.ofertaCodigo,
                            ofertaId: item.ofertaId,
                            estadoOfertaDescripcion: item.estadoOfertaDescripcion,
                            eventoCalendar: item.eventoCalendar ?? false,
                            eventSelected: item.eventSelected,
                            creationUsername: item.creationUsername,
                            controllerName:item.controllerName
                        })
                    })}
                    onDelete={openConfirmationModal}
                    showButtonCalendar={onShowCalendar}
                    controlButtons
                    functionalityPath="/seguimientoclientes"
                    addButton />}

            <div className="footer-section">
                {seguimientosResponse &&
                    <PaginationComponent
                        totalCount={seguimientosResponse.totalCount}
                        currentPage={seguimientosResponse.currentPage}
                        maxCount={maxResultCount}
                        onPageSelect={handlePageSelect}
                        onSetMaxCount={handleSetMaxCount}
                        onPageBackwards={handlePageBackwards}
                    />}
            </div>


            {
                    openDelete &&
                    <DeleteModal
                        open={openDelete}
                        handleCloseModal={handleCloseModalDelete}
                        field="seguimiento del cliente"
                    >
                    </DeleteModal>
                }
        </div>
    )
}

export default ClientesSeguimientoGrid
