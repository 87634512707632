import ApiRequest from "../../../../domain/ApiRequest";
import { ClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { ClienteUpdateDto } from "../../domain/model/Cliente";

export default class ClienteRequestUpdate extends ApiRequest<
ClienteUpdateDto,
boolean
>
{
	constructor(data: ClienteUpdateDto, token: string) {
		super(
			"PUT",
			ClienteEndpoints.CREAR,
			{ Authorization: `${token}`},
			data,
		)
	}
}