import { BaseRepository } from "@pavabits/components";
import Planta, { PlantaDto, PlantaFilter } from "../../domain/model/Planta";
import PlantasRepository from "../../domain/PlantasRepository";
import PlantasRequestListByFilter from "../ws/Plantas.RequestListByFilter";
import PlantasRequestGetAll from "../ws/Plantas.RequestGetAll";
import PlantasRequestConFiltro from "../ws/Plantas.RequestConFiltro";


export default class PlantasApiRepository
	extends BaseRepository<
		PlantaDto,
		Planta
	> implements PlantasRepository {
        
    protected transformDtoIntoModel(dto: PlantaDto): Planta {
        return new Planta(
			dto.id,
			dto.descripcion
		)
    }

	protected fillTextField (dto: PlantaDto[]): PlantaDto[] {
		if (dto && dto.length) {
			return dto.map(item => {
				return {...item, text: item.descripcion}
			});
		}
		return []
	}

	async getByCodigo(codigo: string): Promise<PlantaDto[]> {
		const res= await new PlantasRequestListByFilter(codigo, this.authToken).executeAsPromise();
		return res?.items;
	}

	async getAll(): Promise<PlantaDto[]> {
		const res= await new PlantasRequestGetAll(this.authToken).executeAsPromise();
		return res;
	}

	//LLAMADA PARA EL SUGGESTION INPUT
	async conFiltro(data: PlantaFilter): Promise<PlantaDto[]> {
		return new Promise((resolve, reject) => {
			return new PlantasRequestConFiltro(data, this.authToken)
			.request()
			.then((response) => {
				if (response.success) {
					resolve(this.fillTextField(response.data.items));
				} else {
					reject(`Error ${response.errorCode}: ${response.errorMessage}`);
				}
			})
			.catch((error) => {
				reject(error);
			})
		})
	}


}