import { BaseRepository } from "@pavabits/components";
import AjusteRepository from "../../domain/AjusteRepository";
import Ajuste, { AjusteDto } from "../../domain/model/Ajuste";
import AjusteCreate from "../ws/AjusteCreate";
import AjusteUpdate from "../ws/AjusteUpdate";

export default class AjusteApiRepository extends BaseRepository<
AjusteDto[],
Ajuste[]
> implements AjusteRepository {
	
	update(ajuste:Ajuste): Promise<AjusteDto> {
		return new AjusteUpdate(ajuste,  this.authToken).executeAsPromise();

	}
	add(ajuste:Ajuste): Promise<AjusteDto> {
		return new AjusteCreate(ajuste,  this.authToken).executeAsPromise();
	}

	protected transformDtoIntoModel(dto: AjusteDto[]): Ajuste[] {
		return this.transformDtoIntoModel(dto);
	}

}