import ApiRequest from "../../../../domain/ApiRequest";
import { BaseFilter } from "../../../../domain/BaseFilter";
import BaseResponse from "../../../../domain/BaseResponse";
import { EquipoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { EquipoDto, EquipoReadDto, EquiposFilter } from "../../domain/model/Equipo";

export default class EquipoRequestListByFilter extends ApiRequest<
BaseFilter<EquiposFilter>,
BaseResponse<EquipoReadDto[]>>
{
	constructor(data: BaseFilter<EquiposFilter>, token: string) {
		super(
			"POST",
			EquipoApiEndpoints.CON_FILTRO,
			{ Authorization: token },
			data
		)
	}
}