import ApiRequest from "../../../../domain/ApiRequest";
import Ajuste, { AjusteDto } from "../../domain/model/Ajuste";
import { AjusteEndpoints } from "../oportunidades.api-endpoints";

export default class AjusteUpdate extends ApiRequest<
Ajuste,
AjusteDto>
 {
	public constructor(data: Ajuste, token: string) {
		super(
			"PUT",
			AjusteEndpoints.UPDATE,
			{ Authorization: `${token}`},
			data
		)
	}
}