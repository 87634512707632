const envSettings = window as any;

const ConfiguracionGridsApi = envSettings.RUNTIME_API_HOST + "/api/";
const ConfiguracionGridEndpoints = {
	CONFIGURACIONES: ConfiguracionGridsApi + "configuraciongrids/conFiltro"
};

const ApiEndpoints = {
	BASE: envSettings.RUNTIME_API_HOST,
	CONFIGURACION_GRID: ConfiguracionGridEndpoints,
}

export default ApiEndpoints;



