
import ApiRequest from "../../../../domain/ApiRequest";
import { PresupuestoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class PresupuestoDelete extends ApiRequest<
{},
boolean
>
{
	constructor(id: string, token: string) {
		super(
			"DELETE",
			PresupuestoApiEndpoints.DELETE+id,
			{ Authorization: `${token}`},
			{},
		)
	}
}