import SelectableItem, { SelectableItemDto } from "../../components/ui/atoms/SuggestionInput/model/SuggestionInput.Model";
import { BaseFilter } from "../BaseFilter";

export type  CodigoPostalFilter = BaseFilter<CodigoPostal>;

export interface CodigoPostalDto extends SelectableItemDto { };

export default class CodigoPostal extends SelectableItem { };

export interface CodigoPostalDetalleDto extends CodigoPostalEntity { };


interface CodigoPostalEntity {
    id: string,
    provinciaId: string,
    municipioId: string,
    codigoPostalId: string
    provinciaDescripcion: string;
}


export class CodigoPostalDetalle implements CodigoPostalEntity {

    id: string;
    provinciaId: string;
    municipioId: string;
    codigoPostalId: string;
    provinciaDescripcion: string;

    constructor(entity: CodigoPostalEntity) {

        this.id = entity.id;
        this.provinciaId = entity.provinciaId;
        this.municipioId = entity.municipioId;
        this.codigoPostalId = entity.codigoPostalId;
        this.provinciaDescripcion = entity.provinciaDescripcion;
    }
    
}
