import { SeguimientoClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ApiRequest from "../../../../domain/ApiRequest";
import SeguimientoCliente, { SeguimientoClienteDto, SeguimientoClienteUpdateDto } from "../../domain/model/SeguimientoCliente";

export default class SeguimientoClienteRequestUpdate extends ApiRequest<
SeguimientoClienteUpdateDto,
SeguimientoClienteDto
>
{
	constructor(data: SeguimientoClienteUpdateDto, token: string) {
		super(
			"PUT",
			SeguimientoClienteEndpoints.UPDATE,
			{ Authorization: `${token}`},
			data,
		)
	}
}