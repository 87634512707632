import React, { ReactNode } from 'react'
import { Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap'
import { CustomButton } from '../../../shared/components/Buttons/CustomButton';

interface UnidadObraElementosEquiposModalProps {
    open: boolean,
    children?: ReactNode,
    equipo?: string,
    onHide?: any
}

const UnidadObraElementosEquiposModal: React.FC<UnidadObraElementosEquiposModalProps> = ({open, children, equipo, onHide}) => {
  return (
    <Modal
    onHide={onHide}
    show={open}
    >
           <ModalHeader>
        <ModalTitle>
          Elementos de equipo {equipo ?? ""}
        </ModalTitle>
    </ModalHeader>
    <ModalBody>
    {children}
        <div className="bottom-buttons"> 
            <CustomButton
                className={'btn-primary'}
                onButtonClick={onHide}
                text="Cerrar"
            />
            
        </div>
    </ModalBody>
    </Modal>
  )
}

export default UnidadObraElementosEquiposModal