import { BaseRepository } from "@pavabits/components";
import Comercial, { ComercialDto, ComercialDtoFilter } from "../../domain/model/Comercial";
import ComercialGetAll from "../ws/ComercialGetAll";
import ComercialRepository from "../../domain/ComercialRepository";
import ComercialListByDelegacion from "../ws/ComercialListByDelegacion";

export default class ComercialApiRepository
	extends BaseRepository<
		ComercialDto[],
		Comercial[]
	> implements ComercialRepository {

	async getAll(): Promise<Comercial[]> {
		return (await new ComercialGetAll(this.authToken).executeAsPromise()).items;

	}

	async getConFiltro(data: ComercialDtoFilter): Promise<ComercialDto[]> {
		return new Promise((resolve, reject) => {
			return new ComercialListByDelegacion(data, this.authToken)
			.request()
			.then((response) => {
				if (response.success) {
					resolve(this.transformDtoIntoModel(response.data.items));
				} else {
					reject(`Error ${response.errorCode}: ${response.errorMessage}`);
				}
			})
			.catch((error) => {
				reject(error);
			})
		})
	}	

	protected transformDtoIntoModel(dto: Comercial[]): Comercial[] {
		if (dto && dto.length) {
			return dto.map(item => {
				return new Comercial(item)
			})
		}
		return [];
	}
	// protected transformDtoIntoModel(dto[]: ComercialDto): Comercial {
		
	// }


}