import { FC, useCallback, useContext, useEffect, useState } from "react";
import 'react-tabs/style/react-tabs.scss';
import { toast } from "react-toastify";
import IconButton from "../../../components/ui/atoms/Buttons/IconButton";
import { useLoading } from "../../../context/Loading.Context";
import { CustomButton } from "../../../shared/components/Buttons/CustomButton";
import CustomInput from "../../../shared/components/Inputs/CustomInput/CustomInput";
import { AuthContext } from "../../Login/AuthContextProvider";
import Ofertas from "../../Ofertas/domain/model/Ofertas";
import PresupuestoApiRepository from "../../Presupuestos/Infraestructure/api/Presupuesto.ApiRepository";
import PresupuestoRepository from "../../Presupuestos/domain/PresupuestoRepository";
import Presupuesto from "../../Presupuestos/domain/model/Presupuesto";
import LibroEstandarRepository from "../domain/LibroEstandarRepository";
import LibroEstandar from "../domain/model/LibroEstandar";
import LibroEstandarApiRepository from "../infrastructure/api/LibroEstandar.ApiRepository";
import LibroEstandarDetalleModalSection from "./LibroEstandarDetalle.ModalSection";
import DeleteModal from "../../../shared/components/DeleteModal/DeleteModal";

interface Props {
    oferta: Ofertas,
    librosEstandarProp: LibroEstandar[],
    disabled?: boolean,
    handleRefreshLibroEstandar: (number?: any) => void,
}

const LibroEstandarView: FC<Props> = ({
    oferta,
    librosEstandarProp,
    disabled,
    handleRefreshLibroEstandar,
}) => {
    const { getToken } = useContext(AuthContext);
    const { setLoading } = useLoading();
    const [librosEstandar, setLibrosEstandar] = useState<LibroEstandar[]>(librosEstandarProp)
    const [selectedLibroEstandar, setSelectedLibroEstandar] = useState<LibroEstandar>();
    const [openLibroEstandarModal, setOpenLibroEstandarModal] = useState<boolean>(false);
    const [presupuesto, setPresupuesto] = useState<Presupuesto>()
    const [openDelete, setOpenDelete] = useState<boolean>(false);

    const fetchPresupuestos = useCallback(
        (id: string) => {
            const repo: PresupuestoRepository = new PresupuestoApiRepository(getToken());
            setLoading(true);
            return repo.getByOfertaId({ filter: { ofertaId: id } }).then((presupuestos) => {
                if (!presupuestos || presupuestos.length == 0 || !presupuestos.find(x => x.contratado))
                {
                    toast.info("No es posible crear un libro estandar sin tener un presupuesto contratado.")
                    setLoading(false);
                    return;
                }
                presupuestos.map((presupuesto, index) => {
                    if (presupuesto.contratado === true) {
                        return repo.getById(presupuesto.id!)
                            .then((resp) => {
                                setPresupuesto(resp);
                            }).
                            finally(() => {
                                setLoading(false);
                            });
                    }
                });

            });
        }
        , [getToken]);

    useEffect(() => {

        fetchPresupuestos(oferta.id)

    }, librosEstandar)


    const editarLibroEstandar = (libroEstandar: LibroEstandar) => {
        setSelectedLibroEstandar(libroEstandar);
        setOpenLibroEstandarModal(true);
    }

    //delete
    const [deletionId, setDeletionId] = useState<string>();
    const openConfirmationModal = (id: string) => {
        setDeletionId(id);
        setOpenDelete(true);
    }

    const handleCloseModalDelete = (remove: boolean) => {
        setOpenDelete(false);
        if (remove) {
            deleteLibroEstandar(deletionId!);
        }
    }

    const deleteLibroEstandar = (id: string) => {
        const repo: LibroEstandarRepository = new LibroEstandarApiRepository(getToken());

        repo.delete(id).then(resp => {
            toast.success('Eliminado correctamente');
            handleRefreshLibroEstandar();
        })
            .catch(error => {
                toast.success('Error al intentar eliminar')
            });
    }

    const handleRefresh = (number?: any) => {
        handleRefreshLibroEstandar(number);
        setOpenLibroEstandarModal(false);
    }

    const handleCloseModal = () => {
        handleRefreshLibroEstandar();
        setOpenLibroEstandarModal(false);
    }

    return (
        <>

            <div className="inline-grid">
                <div className="inline-grid-header">
                    <label>Nº Presupuesto</label>
                    <label>Artículo</label>
                    <label>Descripción</label>
                    <div className="inline-button">
                        <CustomButton
                            id={"add-btn"}
                            icon="add"
                            className={"contact-btn"}
                            title={'Añadir LE'}
                            onButtonClick={() => {
                                editarLibroEstandar(new LibroEstandar({
                                    id: undefined,
                                    descripcion: '',
                                    articulo: '',
                                    libroEstandarDetalle: [],
                                    ofertaId: oferta.id,
                                    presupuestoId: presupuesto?.id ?? '',
                                    codigoObra: oferta.codigoObra && oferta.codigoObra != "" ? oferta.codigoObra : ''
                                }));
                            }}
                            btnStyle="accept"
                            type="button"
                            disabled={disabled || librosEstandar.length > 0 || !presupuesto ? true : false}
                        />
                    </div>
                </div>
                <div className="inline-grid-container">
                    {
                        librosEstandar?.map((libro: LibroEstandar, i: number) => {
                            return (
                                <div className="inline-grid-item">
                                    <CustomInput
                                        key={`$libroEstandarId-${i}`}
                                        value={presupuesto && libro.presupuestoId ? oferta?.codigo + "/" + presupuesto.presupuestoId + "-" + presupuesto.presupuestoVersion : ""}
                                        className={'platform-input secondary-input'}
                                        formClassName={"secondary-input"}
                                        onChange={(e) => { }}
                                        disabled={true}
                                    />
                                    <CustomInput
                                        key={`$libroEstandarId-${i}`}
                                        value={libro.articulo}
                                        className={'platform-input secondary-input'}
                                        formClassName={"secondary-input"}
                                        onChange={(e) => { }}
                                        disabled={true}
                                    />
                                    <CustomInput
                                        key={`$libroEstandarId-${i}`}
                                        value={libro.descripcion}
                                        className={'platform-input secondary-input'}
                                        formClassName={"secondary-input"}
                                        onChange={(e) => { }}
                                        disabled={true}
                                    />
                                    <div className="inline-buttons">
                                        <IconButton icon={'edit'}
                                            onClick={() => { editarLibroEstandar(libro); }}
                                            disabled={disabled}
                                        />
                                        <IconButton icon='delete'
                                            onClick={() => { openConfirmationModal(libro.id!); }}
                                            disabled={disabled}
                                        />
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </div >

            {
                openDelete &&
                <DeleteModal
                    open={openDelete}
                    handleCloseModal={handleCloseModalDelete}
                    field="libro estandar"
                >
                </DeleteModal>
            }

            {
                selectedLibroEstandar && openLibroEstandarModal &&
                <LibroEstandarDetalleModalSection
                    opened={openLibroEstandarModal}
                    libroEstandar={selectedLibroEstandar}
                    presupuesto={presupuesto!}
                    oferta={oferta}
                    handleCloseModal={handleCloseModal}
                    handleRefresh={handleRefresh}>
                </LibroEstandarDetalleModalSection>
            }

        </>
    )
}

export default LibroEstandarView;