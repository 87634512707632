interface SeguimientoOfertaEntity  {
	id: string,
	fechaSeguimiento: string,
	observacionesSeguimiento:string,
	observaciones:string,
	tipoSeguimientoClienteId:number,
	estadoOfertaId:number,
	lastModificationTime:string,
	contactoId:string|undefined,
	ofertaId:string,
}


export default class SeguimientoOferta implements SeguimientoOfertaEntity {

	id: string;
	fechaSeguimiento: string;
	observacionesSeguimiento:string;
	observaciones:string;
	tipoSeguimientoClienteId:number;
	ofertaId:string;
	estadoOfertaId:number;
	lastModificationTime:string;
	contactoId:string|undefined;

	constructor(entity: SeguimientoOfertaEntity) {
		
		this.id = entity.id;
		this.fechaSeguimiento = entity.fechaSeguimiento;
		this.observacionesSeguimiento = entity.observacionesSeguimiento;
		this.tipoSeguimientoClienteId = entity.tipoSeguimientoClienteId;
		this.observaciones = entity.observaciones;
		this.lastModificationTime = entity.lastModificationTime;
		this.contactoId=entity.contactoId;
		this.estadoOfertaId=entity.estadoOfertaId;
		this.ofertaId=entity.ofertaId;
	}

	public static toArray(entities: SeguimientoOfertaEntity[]): SeguimientoOferta[] {
		return entities.map(entity => { return new SeguimientoOferta(entity) });
	}
}


export type SeguimientoOfertaDto = SeguimientoOferta;
export type SeguimientoOfertaCreationDto = SeguimientoOferta;
export type SeguimientoOfertaUpdateDto = SeguimientoOferta;
export interface SeguimientoOfertaDeleteDto  { 
	errors:string[]
	};