

interface EstudioDetalleAvanzadoElementoEntity {

	id?: string;
	unidadObraElementoId:string;
	descripcion:string;
	tarifa:string;
	rendimiento:string;
	tipoDescripcion?: string;
	tipoUnidad?: string;
}


export default class EstudioDetalleAvanzadoElemento implements EstudioDetalleAvanzadoElementoEntity {

	id?: string;
	unidadObraElementoId:string;
	descripcion:string;
	tarifa:string;
	rendimiento:string;
	tipoDescripcion?: string;
	tipoUnidad?: string;

	constructor(entity: EstudioDetalleAvanzadoElementoEntity) {

		this.id = entity.id;
		this.unidadObraElementoId=entity.unidadObraElementoId;
		this.tarifa=entity.tarifa;
		this.descripcion=entity.descripcion
		this.rendimiento=entity.rendimiento;
		this.tipoDescripcion=entity.tipoDescripcion;
		this.tipoUnidad=entity.tipoUnidad;
	}

	public static toArray(entities: EstudioDetalleAvanzadoElementoEntity[]): EstudioDetalleAvanzadoElemento[] {
		return entities.map(entity => { return new EstudioDetalleAvanzadoElemento(entity) });
	}
}

export interface EstudioDetalleAvanzadoElementoDto extends EstudioDetalleAvanzadoElemento {
	errors: string[]
};
