import React from "react";
import { BaseFilter, SortingField } from "../../../domain/BaseFilter";
import BaseResponse from "../../../domain/BaseResponse";
import { ColumnTraits } from "../../../hooks/useColumnasGrid";
import { FilterTag } from "../../Oportunidades/context/FilterFields.Context";
import { SearchFilter, SearchFilterHandler, SortingHandler } from "../../Oportunidades/hooks/useFilters/FilterTypes";
import Cliente, { ClienteFilter } from "../domain/model/Cliente";

interface TableData {
	columns: { [key: string]: ColumnTraits },
	data: BaseResponse<Cliente[]>,
}

const defaultData = {} as TableData;
export const ClienteTableData = React.createContext<TableData>(defaultData);

interface FilterState {
	filter: BaseFilter<ClienteFilter>
}

const defaultFilter = {} as FilterState;
export const ClienteFilterState =
	React.createContext<FilterState>(defaultFilter);

interface SearchHandlerContext {
	onSearchField: SearchFilterHandler<ClienteFilter>;
}
const searchHandler: SearchHandlerContext = {} as SearchHandlerContext;

export const ClienteSearchHandler =
	React.createContext<SearchHandlerContext>(searchHandler);

interface Tags {
	tags: FilterTag[]
}

export const ClienteTags = React.createContext<Tags>({ tags: [] });

interface TagsHandler {
	onFilter: (tag: FilterTag) => void
}

export const ClienteTagHandler = React.createContext<TagsHandler>({} as TagsHandler);

interface DescripcionSearch extends SearchFilter { };

const defaultDescripcion = {} as DescripcionSearch
export const ClienteDescripcionSearch = React.createContext<DescripcionSearch>(defaultDescripcion);


interface SortingAndPagination extends SortingHandler<ClienteFilter> {
	maxResultCount: number
	sorting: SortingField[]
}

const defaultPagination = {} as SortingAndPagination;
export const ClientePagination = React.createContext<SortingAndPagination>(defaultPagination);
