import { useCallback, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { COD_DELEGACIONES_CON_TODAS } from '../../../assets/constants/Constantes'
import '../../../assets/styles/views/views.scss'
import DelegacionDropdownField from '../../../components/common/Filters/DelegacionDropdownField'
import TextInputFilterSearchField from '../../../components/common/Filters/TextInputFilterSearchField'
import CustomGridView from '../../../components/CustomGridView'
import FilterModal from '../../../components/ui/atoms/FilterModal/FilterModal'
import PaginationComponent from '../../../components/ui/molecules/Pagination/PaginationComponent'
import { GlobalContext, GlobalContextType } from '../../../context/Global.Context'
import { useLoading } from '../../../context/Loading.Context'
import BaseResponse from '../../../domain/BaseResponse'
import DelegacionesRepository from '../../../domain/DelegacionesRepository'
import Delegacion from '../../../domain/model/Delegacion'
import useColumnasGrid from '../../../hooks/useColumnasGrid'
import useDebounce from '../../../hooks/useDebounce'
import DelegacionesApiRepository from '../../../infraestructure/api/Delegaciones.ApiRepository'
import DeleteModal from '../../../shared/components/DeleteModal/DeleteModal'
import { AuthContext } from '../../Login/AuthContextProvider'
import Unidad from '../domain/model/Unidad'
import UnidadesRepository from '../domain/UnidadesRepository'
import UnidadesApiRepository from '../infraestructure/api/UnidadesApiRepository'
import UnidadObraUnidadMedidaDropdownField from './objects/filterSection/UnidadObraUnidadMedidaDropdownField'
import UnidadesTagCloud from './UnidadesTagCloud'

const Unidades = () => {
    const [unidades, setUnidades] = useState<Unidad[]>([])
    const [unidadesResponse, setUnidadesResponse] = useState<BaseResponse<Unidad[]>>()
    const [deletionIndex, setDeletionIndex] = useState<string>("");
    const { globalState, updateGlobalState } = useContext(GlobalContext) as GlobalContextType;
    const { getToken } = useContext(AuthContext);
    const { getDelegacion } = useContext(AuthContext);
    const { setLoading } = useLoading();
    const componentName = "unidades";
    const unidadesRepo: UnidadesRepository = new UnidadesApiRepository(getToken());
    const sorting = globalState?.unidades?.order
        ? (globalState[componentName]?.order?.sortingCriteria + (globalState[componentName]?.order?.desc ? " DESC" : "")) : "lastModificationTime DESC";
    const maxResultCount = globalState[componentName]?.pagination?.maxResultCount ?? 10;
    const skipCount = globalState[componentName]?.pagination?.skipCount ?? 0;
    const { cols } = useColumnasGrid("BACKENDOPORTUNIDADES", "UNIDADES");
    const [isChecked, setIsChecked] = useState<boolean>(true);
    const [delegaciones, setDelegaciones] = useState<Delegacion[]>();
    const [openDelete, setOpenDelete] = useState<boolean>(false);


    const filterValues = {
        userDelegacion: isChecked && getDelegacion().id ? getDelegacion().id : undefined,
        delegacionIds: globalState[componentName]?.filters?.delegacionIds,
        descripcion: globalState[componentName]?.filters?.descripcion,
        descripcionUnidadObra: globalState[componentName]?.filters?.descripcionUnidadObra,
        unidadMedidaId: globalState[componentName]?.filters?.unidadMedidaId,
    }

    const debouncedDelegacionIdsSearchValue = useDebounce(filterValues.delegacionIds, 1000)
    const debouncedDescripcionSearchValue = useDebounce(filterValues.descripcion, 1000)
    const debouncedDescripcionUnidadObraSearchValue = useDebounce(filterValues.descripcionUnidadObra, 1000)
    const debouncedUnidadMedidaIdSearchValue = useDebounce(filterValues.unidadMedidaId, 1000)

    useEffect(() => {
        fetchUnidadesResponse();
    }, [
        maxResultCount,
        skipCount,
        sorting,
        debouncedDelegacionIdsSearchValue,
        debouncedDescripcionSearchValue,
        debouncedDescripcionUnidadObraSearchValue,
        debouncedUnidadMedidaIdSearchValue,
        filterValues.userDelegacion
    ])

    useEffect(() => {
        fetchDelegaciones()
    }, [getToken()])

    

    const [deletionId, setDeletionId] = useState<string>();
    const openConfirmationModal = (id: string) => {
        setDeletionId(id);
        setOpenDelete(true);
    }

    const handleCloseModalDelete = (remove: boolean) => {
        setOpenDelete(false);
        if (remove) {
            handleRemoveUnidad(deletionId!);
        }
    }

    const handleRemoveUnidad = (id: string) => {
        unidadesRepo.delete(id)
            .then(resp => {
                toast.success("Unidad eliminada con éxito");
                fetchUnidadesResponse()
            })
            .catch(err => {
                console.log(err)
                toast.error("Error al eliminar la Unidad");
            })
        setOpenDelete(false);
    }

    const fetchUnidadesResponse = () => {
        setLoading(true);
        if (isChecked) {
            filterValues.delegacionIds = [Number(getDelegacion().id)];
        } else {
            filterValues.delegacionIds = globalState[componentName]?.filters?.delegacionIds;
        }

        return unidadesRepo.fetchUnidadesByFilter({
            filter: filterValues,
            maxResultCount: maxResultCount,
            skipCount: skipCount,
            sortingCriteria: sorting,
        }).then((res: BaseResponse<Unidad[]>) => {
            setUnidadesResponse(res)
            setUnidades(res?.items)
            const paginationState = {
                currentPage: res?.currentPage,
                totalCount: res?.totalCount,
                maxResultCount: maxResultCount,
                skipCount: skipCount
            }
            updateGlobalState(componentName, { pagination: paginationState })
        }).finally(() => { setLoading(false) })
    }

    const handleSetMaxCount = (count: number) => {
        updateGlobalState(componentName, { pagination: { ...globalState.unidades?.pagination, maxResultCount: count } })
    }

    const handlePageSelect = (page: number) => {
        updateGlobalState(componentName, { pagination: { ...globalState.unidades?.pagination, skipCount: page * maxResultCount } })
    }

    const handlePageBackwards = () => {
        updateGlobalState(componentName, { pagination: { ...globalState.unidades?.pagination, skipCount: (skipCount / maxResultCount - 1) * maxResultCount } })
    }

    const onCheckChange = async () => {
        setIsChecked(!isChecked);
        if (!isChecked) {
            filterValues.delegacionIds = [Number(getDelegacion().id)];
            filterValues.userDelegacion = getDelegacion().id;
            updateGlobalState(componentName, {
                filters: {
                    ...globalState[componentName]?.filters,
                    userDelegacion: getDelegacion()
                },
                pagination: {
                    skipCount: 0
                }
            })
        } else {
            filterValues.delegacionIds = globalState[componentName]?.filters?.delegacionIds;
            filterValues.userDelegacion = "";
            updateGlobalState(componentName, { filters: { ...globalState[componentName]?.filters, userDelegacion: undefined } })
        }
        //fetchUnidadesResponse();
    }

    const fetchDelegaciones = useCallback(
        () => {
            const repo: DelegacionesRepository = new DelegacionesApiRepository(getToken());
            return repo.fetchDelegaciones({
                filter: {
                    ids: COD_DELEGACIONES_CON_TODAS
                }
            }).then((resp: Delegacion[]) => {
                setDelegaciones(resp);
            })
        }, [getToken])

    return (
        <div className="grid-view-container">
            <h3>Unidades</h3>
            <div className="filters-container">
                <div className="filters-modal-container">
                    <FilterModal
                        titleShow="Filtros"
                        modalTitle="Selección de filtros"
                        label = {"TituloModal"}
                        filters={
                            <>
                                <TextInputFilterSearchField
                                    componentName={componentName}
                                    field="descripcion"
                                    idTest="UnidadObraFiltro"
                                    label='Unidad de Obra' />
                                <TextInputFilterSearchField
                                    componentName={componentName}
                                    field="descripcionUnidadObra"
                                    idTest="DescripcionUnidadObraFiltro"
                                    label='Descripción Unidad de Obra' />
                                <UnidadObraUnidadMedidaDropdownField/>
                                <DelegacionDropdownField
                                    disabled={isChecked}
                                    idTest="DelegacionFiltro"
                                    userDelegacion={filterValues.userDelegacion}
                                    componentName={componentName} 
                                    delegaciones={delegaciones ? delegaciones : []}/>
                            </>
                        }
                    />
                    <label>Mis datos</label>
                    <input
                        checked={isChecked}
						onChange={() => { onCheckChange() }}
                        type="checkbox"
                        data-cy="MisDatosCheckbox"
                    />
                </div>
                <UnidadesTagCloud />
            </div>

            {cols && <CustomGridView
                title={componentName}
                sorting={sorting}
                columns={cols}
                data={unidades?.map((unidad: Unidad) =>
                ({
                    id: unidad.id,
                    descripcion: unidad.descripcion,
                    descripcionUnidadObra: unidad.descripcionUnidadObra![0]?.descripcion,
                    descripcionMedida: unidad.descripcionUnidadObra![0]?.unidadMedida?.descripcion,
                    delegacion: unidad.delegacion?.descripcion ?? "TODAS",
                    delegacionId: unidad.delegacion?.id,
                    canDelete: unidad.canDelete
                }))}
                onDelete={openConfirmationModal}
                controlButtons
                functionalityPath="/mantenimiento/unidades"
                

                
                addButton />}

            <div className="footer-section">
                {unidadesResponse && <PaginationComponent
                    currentPage={unidadesResponse.currentPage}
                    maxCount={maxResultCount}
                    totalCount={unidadesResponse.totalCount}
                    onPageSelect={handlePageSelect}
                    onSetMaxCount={handleSetMaxCount}
                    onPageBackwards={handlePageBackwards}
                />}
            </div>

            {
                openDelete &&
                <DeleteModal
                    open={openDelete}
                    handleCloseModal={handleCloseModalDelete}
                    field="unidad de obra"
                >
                </DeleteModal>
            }   

        </div>

    )


}

export default Unidades