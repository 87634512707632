import { FC, useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap';
import { CustomButton } from '../Buttons/CustomButton';


export interface DeleteModalProps {
    open: boolean,
    handleCloseModal: (remove: boolean) => void
    field?: string;
}


const DeleteModal: FC<DeleteModalProps> = ({
    open,
    handleCloseModal,
    field
}) => {

    const [openModal, setOpenModal] = useState<boolean>(open);

    return (
        <Modal
            className="filters-modal modal-l"
            show={openModal}
            onHide={() => { }}>
            <ModalHeader>
                <ModalTitle>
                    Eliminar {field}
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <p>Se va a eliminar permanentemente {field}.</p>
                <p>¿Está seguro?</p>
                <div className="bottom-buttons">
                    <CustomButton
                        key={`removeCancelModal`}
                        title={'Cancelar'}
                        className={'btn-primary'}
                        onButtonClick={() => { handleCloseModal(false); setOpenModal(false); }}
                        text="No"
                    />
                    <CustomButton
                        key={`confirmDeleteModal`}
                        title={'Borrar'}
                        className={'btn-primary'}
                        btnStyle="delete"
                        onButtonClick={() => { handleCloseModal(true); setOpenModal(false); }}
                        text="Sí"
                    />
                </div>
            </ModalBody>
        </Modal>
    )
};

export default DeleteModal;
