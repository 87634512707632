import ApiRequest from "../../../../domain/ApiRequest";
import { UnidadObraApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import Unidad, { UnidadUpdateDto } from "../../domain/model/Unidad";

export default class UnidadesRequestUpdate extends ApiRequest<UnidadUpdateDto, Unidad>
{
    constructor(data: UnidadUpdateDto, token: string) {
        super(
            "PUT",
            UnidadObraApiEndpoints.UPDATE,
            {Authorization: `${token}`},
            data,
        )
    }
}