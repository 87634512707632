import { FC, useContext, useState } from "react";
import CustomInput from "../../../shared/components/Inputs/CustomInput/CustomInput";
import 'react-tabs/style/react-tabs.scss';
import { CustomButton } from "../../../shared/components/Buttons/CustomButton";
import IconButton from "../../../components/ui/atoms/Buttons/IconButton";
import ClausulaOferta from "../domain/model/ClausulaOferta";
import ClausulaOfertaModalSection from "../../Ofertas/views/objects/Modal section/ClausulasOferta.ModalSection";
import ClausulasOfertaRepository from "../domain/ClausulasOfertaRepository";
import ClausulasOfertaApiRepository from "../infrastructure/api/ClausulasOfertaApiRepository";
import { toast } from "react-toastify";
import { AuthContext } from "../../Login/AuthContextProvider";
import { ClausulaReadDto } from "../../Clausulas/domain/model/Clausula";
import TooltipComponent from "../../../components/ui/molecules/Tooltip/TooltipComponent";
import DeleteModal from "../../../shared/components/DeleteModal/DeleteModal";

interface Props {
    title: string
    clausulasOferta: ClausulaOferta[],
    ofertaId: string,
    delegacion?: string,
    handleRefreshClausulas: () => void,
    disabled?: boolean
    clausulas?: ClausulaReadDto[]
}

const ClausulasOfertaView: FC<Props> = ({
    clausulasOferta: clausulasOferta,
    ofertaId: ofertaId,
    delegacion: delegacion,
    handleRefreshClausulas: handleRefreshClausulasOferta,
    disabled,
    clausulas: clausulas
}) => {
    const { getToken, getDelegacion } = useContext(AuthContext);
    const [selectedClausulaOferta, setSelectedClausulaOferta] = useState<ClausulaOferta>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);

    const addClausula = () => {
        setSelectedClausulaOferta(new ClausulaOferta({
            id: '',
            orden: 1,
            negrita: false,
            ofertaId: ofertaId,
            descripcion: '',
            nombre: '',
        }));
        setOpenModal(true);
    }

    const editarClausula = (clausulaOferta: ClausulaOferta) => {
        setSelectedClausulaOferta(clausulaOferta);
        setOpenModal(true);
    }

    const [deletionId, setDeletionId] = useState<string>();
    const openConfirmationModal = (id: string) => {
        setDeletionId(id);
        setOpenDelete(true);
    }

    const handleCloseModalDelete = (remove: boolean) => {
        setOpenDelete(false);
        if (remove) {
            deleteClausula(deletionId!);
        }
    }

    const deleteClausula = (clausulaId: string) => {
        const repo: ClausulasOfertaRepository = new ClausulasOfertaApiRepository(getToken());
        repo.delete(clausulaId)
            .then(respo => {
                toast.success('Cláusula eliminada correctamente');
                handleRefreshClausulasOferta();
            })
            .catch(error => {
                toast.error('Error al intentar eliminar la cláusula')
            });
    }

    const handleRefresh = async () => {
        handleRefreshClausulasOferta();
        setOpenModal(false);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    return (
        <>
            <div className="inline-grid">
                <div className="inline-grid-header">
                    <label style={{ width: "10%" }}>Orden</label>
                    <label>Nombre</label>
                    <label>Descripción</label>
                    <label>Negrita</label>
                    <label>Delegación</label>
                    <div className="inline-button">
                        <CustomButton
                            id={"add-btn"}
                            icon="add"
                            title={'Añadir cláusula'}
                            btnStyle="accept"
                            className={"contact-btn"}
                            onButtonClick={addClausula}
                            type="button"
                            disabled={disabled}
                        />
                    </div>
                </div>

                <div className="inline-grid-container">
                    {clausulasOferta?.map((clausulaOferta: ClausulaOferta, i: number) => {
                        return (
                            <div key={clausulaOferta.id} className="inline-grid-item">
                                <CustomInput
                                    style={{ width: "13%" }}
                                    key={`$orden-${i}`}
                                    value={clausulaOferta.clausula?.orden?.toString() ?? clausulaOferta.orden?.toString()}
                                    className={'platform-input secondary-input'}
                                    formClassName={"secondary-input"}
                                    onChange={(e) => { }}
                                    disabled={true}
                                />
                                <TooltipComponent text={clausulaOferta?.clausula?.nombre ?? clausulaOferta?.nombre}>
                                    <CustomInput
                                        key={`$nombre-${i}`}
                                        value={clausulaOferta?.clausula?.nombre ?? clausulaOferta?.nombre}
                                        className={'platform-input secondary-input'}
                                        formClassName={"secondary-input"}
                                        onChange={(e) => { }}
                                        disabled={true}
                                    />
                                </TooltipComponent>
                                <TooltipComponent text={clausulaOferta.descripcion}>
                                    <CustomInput
                                        key={`$descripcion-${i}`}
                                        value={clausulaOferta.descripcion}
                                        className={'platform-input secondary-input'}
                                        formClassName={"secondary-input"}
                                        onChange={(e) => { }}
                                        disabled={true}
                                    />
                                </TooltipComponent>
                                <CustomInput
                                    key={`negrita-${i}`}
                                    className={'platform-input secondary-input'}
                                    formClassName={"secondary-input"}
                                    value={clausulaOferta.negrita ? "Sí" : "No"}
                                    onChange={() => { }}
                                    disabled={true}
                                />
                                <CustomInput
                                    key={`delegacion-${i}`}
                                    className={'platform-input secondary-input'}
                                    formClassName={"secondary-input"}
                                    value={clausulaOferta.clausula?.delegacionNombre ?? delegacion}
                                    onChange={() => { }}
                                    disabled={true}
                                />
                                <div className="inline-buttons">
                                    <IconButton icon='edit'
                                        disabled={disabled || (clausulaOferta.clausulaId !== null && !clausulaOferta.clausula?.delegacionId && clausulaOferta.clausula !== null)  }
                                        onClick={() => { editarClausula(clausulaOferta); }} />
                                    <IconButton icon='delete'
                                        onClick={() => { openConfirmationModal(clausulaOferta.id ?? ''); }}
                                        disabled={disabled || (clausulaOferta.clausulaId !== null && !clausulaOferta.clausula?.delegacionId && clausulaOferta.clausula !== null) }
                                    />
                                </div>
                            </div>
                        )
                    }
                    )}

                </div>
            </div>

            {
                openDelete &&
                <DeleteModal
                    open={openDelete}
                    handleCloseModal={handleCloseModalDelete}
                    field="cláusula"
                >
                </DeleteModal>
            }
            {
                openModal && selectedClausulaOferta &&
                <ClausulaOfertaModalSection
                    opened={openModal}
                    currentClausulaOferta={selectedClausulaOferta}
                    handleCloseModal={handleCloseModal}
                    handleRefresh={handleRefresh}
                    clausulas={clausulas}
                />
            }
        </>
    )
}

export default ClausulasOfertaView;