
import ApiRequest from "../../domain/ApiRequest";
import BaseResponse from "../../domain/BaseResponse";
import { PlantaDto } from "../../domain/model/Planta";
import { PlantasEndpoints } from "../../modules/Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class PlantasRequestGetAll extends ApiRequest<any, PlantaDto[]>
{
	constructor( token: string ,params?: {[key: string]: number}) {
		super(
			"GET",
			PlantasEndpoints.GETALLSINPAGINCACION ,
			{ Authorization: token },
			{},
            params ?? ""
			
		)
	}
}