import { EstudioApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ApiRequest from "../../../../domain/ApiRequest";
import { EstudioDto, EstudioUpdateDto } from "../../domain/model/Estudio";

export default class EstudioUpdate extends ApiRequest<
EstudioUpdateDto,
EstudioDto
>
{
	constructor(data: EstudioUpdateDto, token: string) {
		super(
			"PUT",
			EstudioApiEndpoints.UPDATE,
			{ Authorization: `${token}`},
			data,
		)
	}
}