import { BaseRepository } from "@pavabits/components";
import EstadosOportunidadesRepository from "../../domain/EstadosOportunidadesRepository";
import EstadoOportunidades, { EstadoOportunidadesDto } from "../../domain/model/EstadoOportunidades";
import EstadosOportunidadesRequestListGetAll from "../ws/EstadosOportunidades.RequestListGetAll";

export default class EstadoOportuniadesApiRepository extends BaseRepository<
	EstadoOportunidades[]
> implements EstadosOportunidadesRepository {

	protected transformDtoIntoModel(dto: EstadoOportunidadesDto[]): EstadoOportunidades[] {
		if (dto && dto.length) {
			return dto.map(item => {
				return new EstadoOportunidades(
					item.id,
					item.descripcion
				)
			})
		}
		return []
	}
	fetchEstadosOportunidades(data: any): Promise<EstadoOportunidades[]> {
		return new Promise((resolve, reject) => {
			return new EstadosOportunidadesRequestListGetAll(this.authToken)
				.request()
				.then((response) => {
					if (response.success) {
						resolve(this.transformDtoIntoModel(response.data.items));
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error) => {
					reject(error);
				})
		})
	}

}