import SelectableItem, { SelectableItemDto } from "../../components/ui/atoms/SuggestionInput/model/SuggestionInput.Model";

export interface UnidadMedidaDto extends SelectableItemDto { };

export default class UnidadMedida extends SelectableItem {
    constructor(dto: UnidadMedidaDto) {
        super(dto.id, dto.descripcion);
    }
};

