import ApiRequest from "../../../../domain/ApiRequest";
import { SeguimientoHistoricoClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class SeguimientoHistoricoClienteRequestGetById extends ApiRequest<{}, any>
{
	constructor(id: string, token: string) {
		super(
			"GET",
			SeguimientoHistoricoClienteEndpoints.CON_ID + id,
			{ Authorization: token },
			{}
		)
	}
}