import ApiRequest from "../../../../domain/ApiRequest";
import { ClausulasApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import Clausula, { ClausulaUpdateDto } from "../../domain/model/Clausula";

export default class ClausulaUpdate extends ApiRequest<ClausulaUpdateDto, Clausula>
{
    constructor(data: ClausulaUpdateDto, token: string) {
        super(
            "PUT",
            ClausulasApiEndpoints.UPDATE,
            {Authorization: `${token}`},
            data,
        )
    }
}