import { useContext, useEffect, useState } from "react"
import { FilterTag } from "../../Oportunidades/context/FilterFields.Context";
import { GlobalContext, GlobalContextType } from "../../../context/Global.Context";

export default function useOfertasTags() {
	const { globalState, updateGlobalState } = useContext(GlobalContext) as GlobalContextType;
	const [tags, setTags] = useState<FilterTag[]>(globalState?.ofertas?.tags ?? []);

	useEffect(() => {
			setTags(globalState?.ofertas?.tags ?? [])
	}, [globalState?.ofertas?.tags])

	const updateTags = (tag: FilterTag) => {
		let updatedTags = tags.filter(x => x.label !== tag.label);
		setTags([...updatedTags, tag]);
		//updateGlobalState("ofertas", {tags: [...updatedTags, tag]})
	}

	return (
		{
			tags,
			updateTags,
		}
	)
};