import { useContext } from "react";
import ContratistaDropdown from "../../atoms/Contratista.Dropdown";
import ContratistaSuggestionInput from "../../atoms/SuggestionInputs/Contratista.SuggestionInput";
import "./ContratistaFilter.scss";
import {  OportunidadesSearchHandler } from "../../../context/Oportunidades.Context";

const ContratistaFilter: React.FC<{}> = () => {

	const { onSearchField } = useContext(OportunidadesSearchHandler);
	const deshabilitarBuscador = onSearchField("conContratista") !== undefined &&
		onSearchField("conContratista").value == "0"
		? true
		: false;

	return (
		<div className="contratista-filter">
			<ContratistaDropdown idTest="ContratistaFiltroSiNo"/>
			
			<ContratistaSuggestionInput disabled={deshabilitarBuscador} idTest="ContratistaFiltroInput"/>
		</div>
	)
}

export default ContratistaFilter;