import BaseResponse from "../../../../domain/BaseResponse";
import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";

import TipoSeguimientoCliente, { TipoSeguimientoClienteDto } from "../../domain/model/TipoSeguimientoCliente";
import TipoSeguimientoClienteRequestGetAll from "../ws/TipoSeguimientoClienteRequestGetAll";
import TipoSeguimientoClienteRepository from "../../domain/TipoSeguimientoClienteRepository";

export default class TipoSeguimientoClienteApiRepository
	extends ApiRepositoryBase<
		TipoSeguimientoClienteDto,
		TipoSeguimientoCliente
	> implements  TipoSeguimientoClienteRepository {



	async getAll(): Promise<TipoSeguimientoCliente[]> {
		const res= await new TipoSeguimientoClienteRequestGetAll( this.authToken).executeAsPromise();
		return  TipoSeguimientoCliente.toArray(res?.items);
	}


	buildModelFromItems(item:  TipoSeguimientoClienteDto):  TipoSeguimientoCliente {
		return new  TipoSeguimientoCliente(item);
	}

	protected transformDtoIntoModel(dto: BaseResponse< TipoSeguimientoClienteDto>): BaseResponse<TipoSeguimientoCliente> {
		return (
			{
				items: this.buildModelFromItems(dto.items),
				currentPage: dto.currentPage,
				totalCount: dto.totalCount,
			}
		)
	}
}