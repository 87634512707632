import { Icon } from "@pavabits/components";
import { ReactNode, useContext, useState } from "react";
import { USER_ROLES } from "../../../assets/constants/Constantes";
import { AuthContext } from "../../../modules/Login/AuthContextProvider";
import IconButton from "../../ui/atoms/Buttons/IconButton";
import TooltipComponent from "../../ui/molecules/Tooltip/TooltipComponent";
import TablePrintableObject from "../types/TablePrintableObject";
import BodyCell from "./BodyCell";

export interface RowAction {
	icon: string
	onClick: (id: string) => void
}

interface RowCell<T extends TablePrintableObject> {
	key: keyof T
	id: T["id"]
	value: string
}

interface GridRowProps<T extends TablePrintableObject> {
	item: T
	sonIconos: number[]
	trClass?: string
	tdClass?: string
	expandedSection?: ReactNode
	rowAction?: RowAction
	cellsChildren?: { [Key in keyof T]: ReactNode }
	idTest?:number
}

const GridRow = <T extends TablePrintableObject>(
	{
		item,
		sonIconos,
		tdClass,
		trClass,
		rowAction,
		cellsChildren,
		idTest,
		expandedSection,
	}: GridRowProps<T>
) => {
	const [expand, setExpand] = useState<boolean>(false);
	const { hasRole, getDelegacion } = useContext(AuthContext);

	function renderCells() {
		const cells: RowCell<T>[] = [];
		item.toMap().forEach((value, key) => {
			cells.push(
				{
					key: key,
					id: item.id,
					value: value
				}
			)
		})
		

		return cells.map((cell, index) => {
			return (
				<>
				{
					!sonIconos.includes(index) ? 
					<BodyCell
						id={cell.id}
						cellValue={cell.value}
						className={tdClass}
						cellKey={cell.key.toString()}
					>
						{
							!!cellsChildren && cellsChildren[cell.key]
						}
					</BodyCell>
					:
					<BodyCell
					id="expand-btn-cell"
					className={tdClass}
					>
						{
						cell.value == "true" && cell.key === "tieneAviso" ?
						<Icon icon='error_outline'/> 
						: cell.value == "true" && cell.key === "tieneObservaciones" ?
						<TooltipComponent text={cells[index + 1].value}>
							<Icon icon="info" />
						</TooltipComponent>
						: ""
						}
						
						{/* <Icon icon={
							cell.value == "true" && cell.key === "tieneAviso" 
							? 'error_outline' 
							: cell.value == "true" && cell.key === "tieneObservaciones" 
							? 'info'
							: ''} /> */}
					</BodyCell>
				}
				</>
			)
		})
	}

	const renderIcon = (icon: string, delegacionId?: number): string => {
		// If the user is not admin, only edit data from their delegation
		if (icon === "edit" && !hasRole(USER_ROLES.ADMIN) && delegacionId && delegacionId !== +getDelegacion()?.id) 
			icon = "visibility";

		return icon;
	}

	return (
		<>
			<tr key={`tr-grid-${item.id}`} className={trClass}>
				{
					expandedSection ?
						<BodyCell
							id="expand-btn-cell"
							className={tdClass}
						>
							<button onClick={() => setExpand(!expand)}>
								<Icon icon={`${expand ? 'expand_less' : 'expand_more'}`} />
							</button>
						</BodyCell>
						: <BodyCell id="expand-btn-cell" className={tdClass}/>

				}
				{
					renderCells()
				}
				{
					<BodyCell
						id="empty-last"
						className={tdClass}
					>
						{
							!!rowAction &&
							<a style={{textDecoration:'none'}} href={item.controllerName+item.id}>
							<IconButton
								icon={renderIcon(rowAction.icon, item.delegacionId)}
								//onClick={() => rowAction.onClick(item.id)}
								label={rowAction.icon === "edit" ? "Editar" : ""}
								index={idTest}
							/>
							</a>
						}
					</BodyCell>
				}
			</tr>
			{
				!!expandedSection && expand &&
				<tr className={trClass}>
					<BodyCell
						id="expanded-section-container-cell"
						className={tdClass}
					>
						{
							expandedSection
						}
					</BodyCell>
				</tr>
			}
		</>
	)
}

export default GridRow;