import ApiRequest from "../../../../domain/ApiRequest";
import { ContactoClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { ContactoClienteDto } from "../../domain/model/ContactosCliente";

export default class ContactoClientesRequestGetByClienteIdAndStrContacto extends ApiRequest<{}, ContactoClienteDto[]>
{
	constructor(id: number,personaContacto:string, token: string) {
		super(
			"POST",
			ContactoClienteEndpoints.CON_FILTRO_CONTACTO,
			{ Authorization: token },
			{ "filter": {
				"clienteId": id,
				"personaContacto":personaContacto
			  }
			}
		)
	}
}