import ApiRequest from "../../../../domain/ApiRequest";
import { OfertasEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ClonarOfertas from "../../domain/model/ClonarOfertas";
import { CopiarOfertasResponse } from "../../domain/model/Ofertas";

export default class OfertasRequesClone extends ApiRequest<ClonarOfertas, CopiarOfertasResponse[]> {
	constructor(data: ClonarOfertas, token: string) {
		super(
			"POST",
			OfertasEndpoints.CLONE,
			{ Authorization: token},
			data
		)
	}
}