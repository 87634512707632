import Delegacion from "../../../../domain/model/Delegacion"
import UnidadMedida from "../../../../domain/model/UnidadMedida"

export enum CalculoAvanzadoTipo {
    MBC = 1,
    FRESADO = 2,
    ZAHORRA = 3,
    PLANTILLA = 4
}

export interface DescripcionUnidadObra {
    id: string | undefined
    unidadObraId: string
    descripcion: string
    unidadMedidaId: number
    unidadMedida: UnidadMedida
}

export type UnidadFilter = {
    descripcion?: string,
    descripcionUnidadObra?: string,
    unidadMedidaId?: number,
    delegacionIds?: number[],
};

interface UnidadEntity {
    id?: string | undefined
    descripcionUnidadObra: DescripcionUnidadObra[]
    delegacion: Delegacion | null | undefined
    descripcion: string
    delegacionId: number | null | undefined
    calculoAvanzadoId?: number | null
    canDelete?: boolean | null
}

export default class Unidad implements UnidadEntity {
    id?: string | undefined
    descripcionUnidadObra: DescripcionUnidadObra[]
    delegacion: Delegacion | null | undefined
    descripcion: string
    delegacionId: number | null | undefined
    calculoAvanzadoId?: number | null
    canDelete?: boolean | null

    constructor(entity: UnidadEntity) {

        this.id = entity.id;
        this.descripcionUnidadObra = entity.descripcionUnidadObra;
        this.delegacion = entity.delegacion;
        this.descripcion = entity.descripcion;
        this.delegacionId = entity.delegacionId;
        this.calculoAvanzadoId = entity.calculoAvanzadoId;
        this.canDelete = entity.canDelete;

    }

    public static toArray(entities: UnidadEntity[]): Unidad[] {
        return entities?.map(entity => { return new Unidad(entity) });
    }
}

export interface UnidadReadDto extends Unidad {
    errors?: string[]
};

export interface UnidadCreateDto {
    delegacionId: number | null | undefined
    descripcion: string
    errors?: string[]
}

export interface UnidadUpdateDto {
    id?: string | undefined
    delegacionId: number | null | undefined
    descripcion: string
    errors?: string[]
}
