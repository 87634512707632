import React from 'react';
import ReactDOM from 'react-dom';
import OportunidadesAsfaltoApp from './OportunidadesAsfaltoApp';
import './assets/styles/index.scss';
import './assets/styles/styles.scss';
import { GlobalProvider } from './context/Global.Context';
import { LoadingProvider } from './context/Loading.Context';

import AuthContextProvider from './modules/Login/AuthContextProvider';
import Loading from './shared/components/Loading/Loading';

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <GlobalProvider>
        <LoadingProvider>
        <OportunidadesAsfaltoApp />
        <Loading/>
        </LoadingProvider>
      </GlobalProvider>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
);

