import { ComponentState, useContext } from "react";
import ApiRepositoryBase from "../../../../domain/ApiRepositoryBase";
import BaseResponse from "../../../../domain/BaseResponse";
import UnidadesRepository from "../../domain/UnidadesRepository";
import Unidad, { DescripcionUnidadObra, UnidadCreateDto, UnidadFilter, UnidadReadDto, UnidadUpdateDto } from "../../domain/model/Unidad";
import UnidadRequestAdd from "../ws/UnidadRequestAdd";
import UnidadesRequestDelete from "../ws/UnidadesRequestDelete";
import UnidadesRequestFetchAll from "../ws/UnidadesRequestFetchAll";
import UnidadesRequestUpdate from "../ws/UnidadesRequestUpdate";
import UnidadesRequestGetById from "../ws/UnidadesRequestGetById";
import { UnidadMaterialCreateDto, UnidadMaterialFilter, UnidadMaterialReadDto, UnidadObraMaterialDto } from "../../domain/model/UnidadMaterial";
import UnidadesMaterialRequestAdd from "../ws/UnidadesMaterialRequestAdd";
import UnidadesMaterialRequestDelete from "../ws/UnidadesMaterialRequestDelete";
import UnidadesMaterialRequestListByFilter from "../ws/UnidadesMaterialRequestListByFilter";
import { UnidadEquipoCreateDto, UnidadEquipoReadDto } from "../../domain/model/UnidadEquipo";
import UnidadesEquipoRequestAdd from "../ws/UnidadesEquipoRequestAdd";
import UnidadesEquipoRequestDelete from "../ws/UnidadesEquipoRequestDelete";
import UnidadesEquipoRequestListByFilter from "../ws/UnidadesEquipoRequestListByFilter";
import UnidadesRequestGetAllSinPaginacion from "../ws/UnidadesRequestGetAllSinPaginacion";
import UnidadesDescripcionRequestAdd from "../ws/UnidadDescripcionRequestAdd";
import UnidadDescripcionRequestDelete from "../ws/UnidadDescripcionRequestDelete";
import UnidadDescripcionRequestGetByUnidadObraId from "../ws/UnidadDescripcionRequestGetByUnidadObraId";
import UnidadesObraRequestByDelegacionId from "../ws/UnidadObraRequestByDelegacionId";
import UnidadObraRequestListByFilter from "../ws/UnidadObraRequestListByFilter";
import { BaseFilter } from "../../../../domain/BaseFilter";
import UnidadesRequestListByFilter from "../ws/UnidadesRequestListByFilter";
import UnidadObraMaterialesRequestConFiltro from "../../../../infraestructure/ws/UnidadObraMateriales.RequestConFiltro";

export default class UnidadesApiRepository extends ApiRepositoryBase<UnidadReadDto, Unidad> implements UnidadesRepository {
    protected transformDtoIntoModel(dto: BaseResponse<UnidadReadDto>): BaseResponse<Unidad> {
        return (
            {
                items: this.buildModelFromItems(dto.items),
                currentPage: dto.currentPage,
                totalCount: dto.totalCount,
            }
        )

    }

    async getUnidadObraConFiltro(filter: BaseFilter<UnidadFilter>): Promise<BaseResponse<Unidad[]>> {
        const res = await new UnidadObraRequestListByFilter(filter, this.authToken).executeAsPromise();
        return res;
    }

    async fetchUnidades(): Promise<UnidadReadDto[]> {
        const res = await new UnidadesRequestFetchAll(this.authToken).executeAsPromise();
        return res?.items
    }
    async fetchUnidadesByFilter(data: any): Promise<BaseResponse<UnidadReadDto[]>> {
        const res = await new UnidadesRequestListByFilter(data, this.authToken).executeAsPromise();
        return res;
    }
    async getAllSinPaginacion(): Promise<UnidadReadDto[]> {
        const res = await new UnidadesRequestGetAllSinPaginacion(this.authToken).executeAsPromise();
        return res;
    }
    async getByDelegacion(id: string): Promise<UnidadReadDto[]> {
        const res = await new UnidadesObraRequestByDelegacionId(id, this.authToken).executeAsPromise();
        return res.items;
    }
    async fetchUnidadesResponse(data: { maxResultCount: number, skipCount: number, sortingCriteria: string }): Promise<BaseResponse<Unidad[]>> {
        const res = await new UnidadesRequestFetchAll(this.authToken, data).executeAsPromise();
        return res;
    }

    async add(data: UnidadCreateDto): Promise<any> {
        const res = await new UnidadRequestAdd(data, this.authToken).executeAsPromise();
        return res;
    }
    async delete(id: string): Promise<boolean> {
        await new UnidadesRequestDelete(id, this.authToken).executeAsPromise();
        return true;
    }
    async update(data: UnidadUpdateDto): Promise<UnidadUpdateDto> {
        return await new UnidadesRequestUpdate(data, this.authToken).executeAsPromise()
    }

    async getById(id: string): Promise<UnidadReadDto> {
        return await new UnidadesRequestGetById(id, this.authToken).executeAsPromise()
    }

    // MATERIALES A LA UNIDAD
    async addMaterialesAUnidad(data: UnidadMaterialCreateDto): Promise<UnidadMaterialReadDto[]> {
        const res = await new UnidadesMaterialRequestAdd(data, this.authToken).executeAsPromise();
        return res;
    }

    async deleteMaterialUnidad(id: string): Promise<boolean> {
        await new UnidadesMaterialRequestDelete(id, this.authToken).executeAsPromise();
        return true;
    }

    async getMaterialesUnidadConFiltro(unidadId: string): Promise<UnidadMaterialReadDto[]> {
        const res = await new UnidadesMaterialRequestListByFilter(unidadId, this.authToken).executeAsPromise();
        return res?.items;
    }

    async getConFiltro(unidadId: string): Promise<UnidadMaterialReadDto[]> {
        const res = await new UnidadesMaterialRequestListByFilter(unidadId, this.authToken).executeAsPromise();
        return res?.items;
    }

    // async materialConFiltro(data: UnidadMaterialFilter): Promise<UnidadMaterialReadDto[]> {
    //     const res = await new UnidadObraMaterialesRequestConFiltro(data, this.authToken).executeAsPromise();
    //     return res.items;
    // }

    async materialConFiltro(data: UnidadMaterialFilter): Promise<UnidadObraMaterialDto[]> {
        return new Promise((resolve, reject) => {
			return new UnidadObraMaterialesRequestConFiltro(data, this.authToken)
			.request()
			.then((response) => {
				if (response.success) {
					resolve(this.fillTextField(response.data.items));
				} else {
					reject(`Error ${response.errorCode}: ${response.errorMessage}`);
				}
			})
			.catch((error) => {
				reject(error);
			})
		})
    }


    protected fillTextField (dto: UnidadObraMaterialDto[]): UnidadObraMaterialDto[] {
		if (dto && dto.length) {
			return dto.map(item => {
				return {...item, text: item.descripcion}
			});
		}
		return []
	};

    // EQUIPOS A LA UNIDAD
    async addEquiposAUnidad(data: UnidadEquipoCreateDto): Promise<UnidadEquipoReadDto[]> {
        const res = await new UnidadesEquipoRequestAdd(data, this.authToken).executeAsPromise();
        return res;
    }

    async deleteEquipoUnidad(id: string): Promise<boolean> {
        await new UnidadesEquipoRequestDelete(id, this.authToken).executeAsPromise();
        return true;
    }

    async getEquiposUnidadConFiltro(unidadId: string): Promise<UnidadEquipoReadDto[]> {
        const res = await new UnidadesEquipoRequestListByFilter(unidadId, this.authToken).executeAsPromise();
        return res?.items;
    }

    async addDescripcionAUnidad(data: any): Promise<DescripcionUnidadObra> {
        const res = await new UnidadesDescripcionRequestAdd(data, this.authToken).executeAsPromise();
        return res;
    }

    async deleteDescripcionUnidad(id: string): Promise<boolean> {
        return await new UnidadDescripcionRequestDelete(id, this.authToken).executeAsPromise();
    }

    async getDescripcionesByUnidad(id: string): Promise<BaseResponse<DescripcionUnidadObra[]>> {
        return await new UnidadDescripcionRequestGetByUnidadObraId(id, this.authToken).executeAsPromise()
    }

    buildModelFromItems(item: UnidadReadDto): Unidad {
        return new Unidad(item)
    }


}