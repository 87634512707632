import classNames from 'classnames';
import React, { FC } from 'react'
import { Icon } from '../Icon/Icon'
import './CustomButton.scss'

type ButtonTypes = 'button'
    | 'submit'
    | 'reset'
    ;

type ButtonStyles =
    'accept'
    | 'cancel'
    | 'circle'
    | 'close'
    | 'mini'
    | 'small'
    | 'medium'
    | 'delete'
    | 'favorite'
    | 'filecopy'
    | 'note_add'
    | 'error_outline'

export type CustomButtonProps = {
    id?: string,
    text?: string,
    type?: ButtonTypes,
    icon?: string,
    className?: string,
    btnStyle?: ButtonStyles,
    data?: any,
    onButtonClick?: (e?: React.MouseEvent) => void,
    disabled?: boolean,
    title?: string,
    idCypress?: string
}

export const CustomButton: FC<CustomButtonProps> = ({ id, type, text, title, icon, idCypress, onButtonClick, className, data, btnStyle, disabled = false, children }) => {

    let classes = classNames(className);

    switch (btnStyle) {
        case 'circle':
            classes = `${className} custom-button--circle`;
            break;
        case 'close':
            classes = `${className} custom-button--close`
            break;
        case 'small':
            classes = `${className} custom-button--small`
            break;
        case 'mini':
            classes = `${className} custom-button--mini`
            break;
        case 'medium':
            classes = `${className} custom-button--medium`
            break;
        case 'delete':
            classes = `${className} custom-button--delete`
            break;
        case 'favorite':
            classes = `${className} custom-button--favorite`
            break;
        default:
            break;
    }

    return (
            <button
                id={id}
                className={className ?? 'btn-primary'}
                // className={classes + (disabled ? ' disabled' : '')}
                type={type}
                onClick={onButtonClick}
                data-id={data}
                disabled={disabled}
                data-cy={title === 'Complete el estudio para poder finalizarlo' ? idCypress : title}
            >
                {
                    icon && <Icon icon={icon} id={id} />
                }
                {text}
            {children}
            </button>
    )
}
