import { useContext } from "react";
import { ClienteDescripcionSearch, ClienteSearchHandler } from "./Cliente.Context";

const ClienteFilterFieldsProvider: React.FC<{}> = ({ children }) => {

	const { onSearchField } = useContext(ClienteSearchHandler);

	return (
		<ClienteDescripcionSearch.Provider value={ onSearchField("descripcion") }>
			{children}
		</ClienteDescripcionSearch.Provider>
	)
}

export default ClienteFilterFieldsProvider;