import ApiRequest from "../../../../domain/ApiRequest";
import { FormaPagoEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import FormaPago, { FormaPagoUpdateDto } from "../../domain/model/FormaPago";

export default class FormaPagoRequestAdd extends ApiRequest<
FormaPagoUpdateDto,
FormaPago
>
{
	constructor(data: FormaPagoUpdateDto, token: string) {
		super(
			"POST",
			FormaPagoEndpoints.CREAR,
			{ Authorization: `${token}`},
			data,
		)
	}
}