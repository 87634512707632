import { BaseApiRequest } from "@pavabits/components";
import { DelegacionDto, DelegacionesFilter } from "../../domain/model/Delegacion";
import BaseReponse from "../../domain/BaseResponse";
import { DelegacionesEndpoints } from "../../modules/Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class DelegacionesRequestListByFilter extends BaseApiRequest<
	DelegacionesFilter,
	BaseReponse<DelegacionDto[]>
> {
	public constructor(data: DelegacionesFilter, token: string) {
		super(
			"POST",
			DelegacionesEndpoints.DELEGACIONES_CON_FILTRO,
			data,
			{ Authorization: `${token}` }
		)
	}
}