import { Icon } from '@pavabits/components'
import React, { PropsWithChildren, useContext } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES, USER_ROLES } from '../assets/constants/Constantes'
import '../assets/styles/components/buttons.scss'
import '../assets/styles/components/grids.scss'
import '../assets/styles/elements/_headers.scss'
import { HistoryHandler, HistoryLocation } from '../context/History.Context'
import { AuthContext } from '../modules/Login/AuthContextProvider'
import '../modules/Ofertas/views/objects/Filter section/Ofertas.FilterSection.scss'
import SeguimientoHistoricoCliente from '../modules/SeguimientoHistoricoClientes/domain/model/SeguimientoHistoricoCliente'
import { Routes } from '../router/routes/Routes'
import { GridColumn } from '../types'
import IconButton from './ui/atoms/Buttons/IconButton'
import SortingArrows from './ui/molecules/Sorting/SortingArrows'
import TooltipComponent from './ui/molecules/Tooltip/TooltipComponent'

interface CustomGridViewProps {
  columns: GridColumn[]
  data: any[]
  title?: string
  clickable?: boolean
  addButton?: boolean
  controlButtons?: boolean
  editButton?: boolean
  onDelete?: ((id: string) => void)
  showButtonCalendar?: ((element: SeguimientoHistoricoCliente) => void)
  description?: string
  functionalityPath?: string
  sorting?: any
}

const CustomGridView: React.FC<CustomGridViewProps> = ({
  title,
  columns,
  data,
  clickable,
  addButton,
  controlButtons,
  editButton,
  onDelete,
  showButtonCalendar,
  functionalityPath: functionalityPath,
  sorting,
}: PropsWithChildren<CustomGridViewProps>) => {
  const { pathname } = useContext(HistoryLocation);
  const { goToRoute } = useContext(HistoryHandler);
  const { getUserFunctionalities: permisosVentana, hasRole, getDelegacion } = useContext(AuthContext);
  const colWidths = columns.map((col: any) => col.width);
  const permisoVentana = permisosVentana().find(x => x.url === (functionalityPath ?? pathname));
  
  const truncate = (item: any) => {
    if (item !== null && item !== undefined)
      return String(item).length > 30 ? String(item).substring(0, 27) + "..." : String(item);
  }

  const handlePropertyChange = (property: string, newValue: string | number | boolean, i: number) => {
    data[i].eventSelected = Boolean(newValue);

    if(showButtonCalendar) {
      showButtonCalendar(data[i]);
    }
  }

  const renderAddButton = (label:string) => {
    if (addButton && permisoVentana?.permisoAlta) {
      return <td className='table-btns'>
          <Link className="btn--link" to={permisoVentana.url + ROUTES.GRABACION}>
            <IconButton icon='add' label={label}/>
          </Link>
      </td>;
    }
  }  

  const renderEditButton = (disabled: boolean, item: any, index:number) => {
    if (permisoVentana?.permisoModificacion || permisoVentana?.permisoEntrada) {
      //let route = `${permisoVentana.url + ROUTES.EDICION}/${item.id + (functionalityPath?.includes("equipos") ? "?backUrl=1" : "")}`;
      let permisoModificacion = permisoVentana?.permisoModificacion;

      if (item.delegacionId) {
        permisoModificacion = permisoModificacion && (hasRole(USER_ROLES.ADMIN) || item.delegacionId === getDelegacion()?.id);
      }

      return <a style={{textDecoration:'none'}} href={functionalityPath+"/edicion/"+item.id}>
        <IconButton
          icon={permisoModificacion ? "edit" : "visibility"}
          disabled={item.editable != null ? !item.editable : false}
          //onClick={() => goToRoute(route)}
          index={index}
          label="Editar"
        /></a>;
    }
  }
  
  const renderDeleteButton = (disabled: boolean, item: any, index:number) => {
    let permisoBaja = permisoVentana?.permisoBaja;

    if (item.delegacionId) {
      permisoBaja = permisoBaja && (hasRole(USER_ROLES.ADMIN) || item.delegacionId === getDelegacion()?.id);
    }

    return permisoBaja && <IconButton
      icon="delete"
      disabled = {item.editable != null ? !item.editable : item.canDelete != null ? !item.canDelete : false}
      onClick={onDelete && (() => onDelete(item.id))}
      index={index}
    />
  }

  return (
    <div className='grid-container'>
      <table>
        <thead> 
        <tr className='table-header'>
          {columns?.map((column: any) => (
            <th key={column.id} style={{width: column.width}}>
              <div className='header-cell'>
              {column.columnName}
              {
                column.sortable && <SortingArrows 
                columnKey={column.attributeName}
                component={title}
                sorted={[{field: sorting?.split(" ")[0], desc: sorting?.split(" ")[1] === "DESC"}]}
                // sortingField={sorting ?? {}}
                onSort={() => {}}
              />
              }
              </div>
            </th>
       ))}
      {renderAddButton("Añadir")}
         </tr>
     </thead>
   
      <tbody>
          {data.map((item: any, index: number) => {
            return (
              <tr key={index}>
                {columns.map((col: any, colIndex: number) => {
                  const capitalizeFirstLetter = (string: string) => {
                    return string.charAt(0).toUpperCase() + string.slice(1);
                  };
                  return (
                    <td 
                      key={`${col.attributeName}-${colIndex}-${index}`}
                      data-cy={`${capitalizeFirstLetter(col.attributeName)}${capitalizeFirstLetter(title ?? '')}${index}`}
                      style={{ width: colWidths[colIndex] }}> {
                        col.type === "CHECKBOX" ?
                        (col.attributeName === "eventoCalendar" ?
                          (item[col.attributeName] ?
                            <Icon icon={'done'} id={`done${index}`}/>: 
                            <div className='input-container'>
                              <div className="inline-grid-checkbox">
                                <input
                                  checked={item["eventSelected"]}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePropertyChange(col.attributeName, e.target.checked, index)}
                                  type="checkbox"
                                  data-cy={`CheckboxCalendar${index}`}
                                />
                              </div>
                            </div>
                          )
                          :
                          <Icon icon={`${item[col.attributeName] ? 'done' : ''}`} />)
                          :
                          <TooltipComponent text={String(item[col.attributeName]).length > 30 ? item[col.attributeName] : ""}>
                          {(clickable && item != null ?
                            item.clienteId != null && (col.attributeName === "cif" || col.attributeName === "clienteNombre") ?
                            (<a className="link" href={Routes.CLIENTES_EDICION.replace(':id', item.clienteId)}>{truncate(item[col.attributeName])}</a>) :
                            item.ofertaCodigo != null && item.ofertaId != null && (col.attributeName == "ofertaCodigo") ?
                            (<a className="link" href={Routes.OFERTAS_EDICION.replace(':id', item.ofertaId)}>{truncate(item[col.attributeName])}</a>) :
                            item.oportunidadCodigo != null && (col.attributeName == "oportunidadCodigo") ?
                            (<a className="link" href={(Routes.OPORTUNIDADES+'?filter='+item.oportunidadCodigo)}>{truncate(item[col.attributeName])}</a>) :
                            truncate(item[col.attributeName]) :
                            <div className={ col.type == "NUMBER" ? 'number' : ''}>
                              {truncate(item[col.attributeName])}
                            </div>
                          )}</TooltipComponent>
                      }
                    </td>
                  )
                })}
                {controlButtons && <td className='flex-horiz'>
                  {renderEditButton(item.editable, item, (index))}
                  {renderDeleteButton(item.editable, item, (index))}
                </td> }
              </tr>
            )
          })}

        </tbody>
      </table>
    </div>
  )
}

export default CustomGridView