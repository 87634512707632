import React, { useContext } from 'react'
import TagCloud from '../../components/ui/objects/TagCloud/TagCloud'
import { GlobalContext, GlobalContextType } from '../../context/Global.Context';

const ContactosTagCloud = () => {
    const {globalState, updateGlobalState} = useContext(GlobalContext) as GlobalContextType;

    const tags = [
        {
            label: "Teléfono",
            text: String(globalState?.contactos?.filters?.telefono).length > 0 
            && globalState?.contactos?.filters?.telefono !== undefined 
            ? String(globalState?.contactos?.filters?.telefono) 
            : "",
            onClear: () => updateGlobalState("contactos", {filters: {...globalState.contactos?.filters,"telefono": undefined}})
        },
        {
            label: "Nombre y Apellidos",
            text:  globalState?.contactos?.filters?.personaContacto ?? "",
            onClear: () => updateGlobalState("contactos", {filters: {...globalState.contactos?.filters,"personaContacto": undefined}})
        },
    ]


  return (
    <TagCloud tags={tags} />
  )
}

export default ContactosTagCloud

function updateGlobalState(componentName: any, arg1: { filters: { personaContacto: any; telefono?: number | undefined; }; }) {
    throw new Error('Function not implemented.');
}
