import ApiRequest from "../../../../domain/ApiRequest";
import { EquipoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { EquipoCreateDto } from "../../domain/model/Equipo";

export default class EquipoRequestGetById extends ApiRequest<
string
>
{
	constructor(id: string, token: string) {
		super(
			"GET",
			EquipoApiEndpoints.GET_BY_ID_EQUIPO+id,
			{ Authorization: `${token}`},
			""
			
		)
	}
}