import { useCallback, useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { toast } from "react-toastify";
import IconButton from "../../../../../../components/ui/atoms/Buttons/IconButton";
import Dropdown, { DropdownProps } from "../../../../../../components/ui/atoms/Dropdown/Dropdown";
import FormInput from "../../../../../../components/ui/atoms/Input/FormInput/FormInput";
import Input from "../../../../../../components/ui/atoms/Input/FormInput/Input";
import ModalPage from "../../../../../../components/ui/atoms/ModalPage/ModalPage";
import SingleSuggestionInputField, { SingleSuggestionInputFieldProps } from "../../../../../../components/ui/atoms/SingleSuggestionInput/SingleSuggestionInput.Field";
import FormField from "../../../../../../components/ui/molecules/Form-field/FormField";
import TooltipComponent from "../../../../../../components/ui/molecules/Tooltip/TooltipComponent";
import { useLoading } from "../../../../../../context/Loading.Context";
import MaterialesRepository from "../../../../../../domain/MaterialesRepository";
import PlantasRepository from "../../../../../../domain/PlantasRepository";
import TramosRepository from "../../../../../../domain/TramosRepository";
import { MaterialCalculo } from "../../../../../../domain/model/Material";
import { PlantaDto } from "../../../../../../domain/model/Planta";
import { TramoReadingDto } from "../../../../../../domain/model/Tramo";
import UnidadMedida from "../../../../../../domain/model/UnidadMedida";
import { FormErrors } from "../../../../../../hooks/use-form";
import MaterialesApiRepository from "../../../../../../infraestructure/api/Materiales.ApiRepository";
import PlantasApiRepository from "../../../../../../infraestructure/api/Plantas.ApiRepository";
import TramosApiRepository from "../../../../../../infraestructure/api/Tramos.ApiRepository";
import { CustomButton } from "../../../../../../shared/components/Buttons/CustomButton";
import CustomInput from "../../../../../../shared/components/Inputs/CustomInput/CustomInput";
import { formatNumber } from "../../../../../../utils/NumberUtil";
import EquiposRepository from "../../../../../Equipos/domain/EquiposRepository";
import { EquipoCalculo } from "../../../../../Equipos/domain/model/Equipo";
import EquipoApiRepository from "../../../../../Equipos/infrastructure/api/Equipo.ApiRepository";
import EstudioDetalleApiRepository from "../../../../../Estudios/Infraestructure/api/EstudioDetalle.ApiRepository";
import EstudioDetalleRepository from "../../../../../Estudios/domain/EstudioDetalleRepository";
import EstudioDetalle from "../../../../../Estudios/domain/model/EstudioDetalle";
import EstudioDetalleAvanzado from "../../../../../Estudios/domain/model/EstudioDetalleAvanzado";
import EstudioDetalleAvanzadoElemento from "../../../../../Estudios/domain/model/EstudioDetalleAvanzadoElemento";
import EstudioDetalleAvanzadoEquipo from "../../../../../Estudios/domain/model/EstudioDetalleAvanzadoEquipo";
import EstudioDetalleAvanzadoMaterial from "../../../../../Estudios/domain/model/EstudioDetalleAvanzadoMaterial";
import EstudioDetalleAvanzadoTramo, { EstudioDetalleAvanzadoTramoDto } from "../../../../../Estudios/domain/model/EstudioDetalleAvanzadoTramo";
import EstudioDetalleAvanzadoTraslado from "../../../../../Estudios/domain/model/EstudioDetalleAvanzadoTraslado";
import { AuthContext } from "../../../../../Login/AuthContextProvider";
import UnidadObraElementoRepository from "../../../../../Unidades/domain/UnidadObraElementoRepository";
import UnidadesRepository from "../../../../../Unidades/domain/UnidadesRepository";
import { UnidadReadDto } from "../../../../../Unidades/domain/model/Unidad";
import { UnidadEquipoReadDto } from "../../../../../Unidades/domain/model/UnidadEquipo";
import { UnidadMaterialReadDto, UnidadObraMaterialDto } from "../../../../../Unidades/domain/model/UnidadMaterial";
import { UnidadObraElementoReadDto } from "../../../../../Unidades/domain/model/UnidadObraElemento";
import UnidadObraElementoApiRepository from "../../../../../Unidades/infraestructure/api/UnidadObraElementoApiRepository";
import UnidadesApiRepository from "../../../../../Unidades/infraestructure/api/UnidadesApiRepository";
import EstudioDetalleAvanzadoEquipoRepository from "../../../../domain/EstudioDetalleAvanzadoEquipoRepository";
import EstudioDetalleAvanzadoTramoRepository from "../../../../domain/EstudioDetalleAvanzadoTramoRepository";
import EstudioDetalleAvanzadoTrasladoRepository from "../../../../domain/EstudioDetalleAvanzadoTrasladoRepository";
import EstudioDetalleAvanzadoEquipoApiRepository from "../../../../infraestructure/api/EstudioDetalleAvanzadoEquipo.ApiRepository";
import EstudioDetalleAvanzadoTramoApiRepository from "../../../../infraestructure/api/EstudioDetalleAvanzadoTramo.ApiRepository";
import EstudioDetalleAvanzadoTrasladoApiRepository from "../../../../infraestructure/api/EstudioDetalleAvanzadoTraslado.ApiRepository";
import CalculoAvanzadoElementosEquipo from "./CalculoAvanzado.ElementosEquipo";
import "./CalculoAvanzado.scss";
import { updateDiasRendimiento, updateDiasTraslado, updateEquipos, updateMateriales, updateTotalEurosM2, updateTotales } from "./Calculos";

interface Props {
	openModal: boolean
	handleCloseModal: (estudioDetalleArray: EstudioDetalle[]) => void
	handleCloseCancelModal: () => void
	unidadObraId?: string
	unidadObraDescripcion?: string
	estudioDetalleDescripcion: string[]
	unidadMedida: UnidadMedida
	estudioDetalle?: EstudioDetalle,
	estudioDetalleAvanzado?: EstudioDetalleAvanzado,
	estudioDetalleId?: string,
	estudioDetalleAvanzadoId?: string,
	coeficienteEstudio: number,
	delegacionId: string,
	readOnly?: boolean,
	estudiosDetalleLength: number
}

const CalculoAvanzado: React.FC<Props> = ({ openModal,
	handleCloseModal,
	handleCloseCancelModal,
	estudioDetalleDescripcion,
	unidadMedida,
	estudioDetalleAvanzado,
	estudioDetalleId,
	estudioDetalleAvanzadoId,
	unidadObraId,
	coeficienteEstudio,
	delegacionId,
	readOnly,
	estudiosDetalleLength }) => {
	const UNIDAD_OBRA_ELEMENTO_TIPO_TRASLADO = 5;
	const CALCULO_AVANZADO_ID_MBC = 1;
	const UNIDAD_MEDIDA_TONELADAS = 1;
	const UNIDAD_MEDIDA_M2 = 2;

	const { getToken, getDelegacion } = useContext(AuthContext);
	const { setLoading } = useLoading();
	const [formErrors, setFormErrors] = useState<FormErrors<EstudioDetalleAvanzado>>({});
	const [selectedPlanta, setSelectedPlanta] = useState<PlantaDto>({} as PlantaDto);
	const [estudioDetalles, setEstudioDetalles] = useState<EstudioDetalle[]>();
	const [selectedMaterial, setSelectedMaterial] = useState<UnidadMaterialReadDto>();
	const [materialesUnidad, setMaterialesUnidad] = useState<UnidadObraMaterialDto[]>([]);
	const [materialesCalculos, setMaterialesCalculos] = useState<MaterialCalculo[]>(
		estudioDetalleAvanzado?.material != undefined ?
			estudioDetalleAvanzado?.material?.map((entity, index) => {
				return new MaterialCalculo({
					id: entity.id,
					codigoArticulo: entity.codigoArticulo,
					materialId: entity.materialId,
					densidad: entity.densidad,
					espesor: entity.espesor,
					superficie: entity.superficie,
					tmNecesarias: entity.toneladas,
					costeMezcla: (+entity.toneladas * +entity.baremo),
					mezcla: entity.materialDescripcion!,
					precio: entity.baremo,
					preciom2: (+entity.baremo * +entity.densidad * (+entity.espesor / 100)),
					totalEurosM2: 0,
					riego: entity.riego,
					coeficiente: entity.coeficiente ?? coeficienteEstudio,
					descripcionDetalle: !estudioDetalleAvanzado.totalizar ? estudioDetalleDescripcion[index] : "",
					unidadMedida: unidadMedida,
					orden: entity.orden,
					precioOriginal: entity.baremoOriginal,
					fechaSincronizacion: entity.fechaSincronizacion ? new Date(entity.fechaSincronizacion) : undefined
				})
			})
			:
			[]);

	const [selectedEquipo, setSelectedEquipo] = useState<UnidadEquipoReadDto>();
	const [selectedTraslado, setSelectedTraslado] = useState<UnidadObraElementoReadDto>();
	const [equiposUnidad, setEquiposUnidad] = useState<UnidadEquipoReadDto[]>([]);
	const [trasladosUnidad, setTrasladosUnidad] = useState<UnidadObraElementoReadDto[]>([]);
	const [equiposCalculos, setEquiposCalculos] = useState<EquipoCalculo[]>(
		estudioDetalleAvanzado?.equipo != undefined ?
			estudioDetalleAvanzado?.equipo?.map(entity => {
				return {
					id: entity.id,
					equipoId: entity.equipoId,
					descripcion: entity.equipoDescripcion,
					unidades: entity.unidades,
					elementos: entity.elementos,
					tarifa: entity.elementos ? entity.tarifa : entity.tarifa,
					costeEquipo: 0,
					dias: entity.dias ? entity.dias.toString() : ""
				}
			})
			: []
	);

	const [trasladosCalculos, setTrasladosCalculos] = useState<EstudioDetalleAvanzadoTraslado[]>(
		estudioDetalleAvanzado?.traslado != undefined ?
			estudioDetalleAvanzado?.traslado?.map(entity => {
				return {
					id: entity.id,
					unidadObraElementoId: entity.unidadObraElementoId,
					descripcion: entity.descripcion,
					unidades: entity.unidades,
					tarifa: entity.tarifa,
					dias: entity.dias,
					coste: 0
				}
			})
			: []
	);
	const [selectedTramo, setSelectedTramo] = useState<string>();
	const [tramosEstudio, setTramosEstudio] = useState<EstudioDetalleAvanzadoTramoDto[]>(
		estudioDetalleAvanzado?.tramo != undefined ?
			estudioDetalleAvanzado?.tramo?.map(entity => {
				return {
					id: entity.id,
					tramoId: entity.tramoId,
					descripcion: entity.descripcion ?? '',
					tarifa: entity.tarifa,
					tarifaOriginal: entity.tarifaOriginal,
					errors: []
				}
			})
			: []
	);
	const [tramosList, setTramosList] = useState<TramoReadingDto[]>();
	const [descripcion, setDescripcion] = useState<string[]>(estudioDetalleDescripcion);
	const [totalToneladas, setTotalToneladas] = useState<number>(materialesCalculos?.reduce((partialSum, a) => partialSum + +a.tmNecesarias, 0) ?? 0);
	let calculoTarifasEquipos = equiposCalculos?.reduce((partialSum, a) => partialSum + a.costeEquipo, 0) ?? 0;
	let calculoTarifasTraslados = trasladosCalculos?.reduce((partialSum, a) => partialSum + a.coste, 0) ?? 0;
	let totalTramos = tramosEstudio?.reduce((partialSum, a) => partialSum + +a.tarifa, 0) ?? 0;
	const [equipoEuroTm, setEquipoEuroTm] = useState<number>(+(calculoTarifasEquipos / totalToneladas).toFixed(2));
	const [trasladoEuroTm, setTrasladoEuroTm] = useState<number>(+(calculoTarifasTraslados / totalToneladas).toFixed(2));
	const [equipoTrasladoEuroTm, setEquipoTrasladoEuroTm] = useState<number>(equipoEuroTm + trasladoEuroTm);
	const [diasCalculoAvanzado, setDiasCalculoAvanzado] = useState<number>(estudioDetalleAvanzado?.dias ?? 0);
	const [rdoMedioCalculoAvanzado, setRdoMedioCalculoAvanzado] = useState<number>(totalToneladas / diasCalculoAvanzado);
	const [openModalElemento, setOpenModalElemento] = useState(false);
	const [indexSelected, setIndexSelected] = useState<number>();
	const [openDescModal, setOpenDescModal] = useState<boolean>(false);
	const [unidades, setUnidades] = useState<UnidadReadDto[]>([]);
	const [descOptions, setDescOptions] = useState<any>([]);
	const [descDetalleUnidad, setDescDetalleUnidad] = useState<string>("");
	const [indDetalleUnidad, setIndDetalleUnidad] = useState<number | null>(null);
	const [totalizar, setTotalizar] = useState<boolean>(estudioDetalleAvanzado?.totalizar ?? false);
	const [totalMateriales, setTotalMateriales] = useState<MaterialCalculo | undefined>();
	const [selectedMateriales, setSelectedMateriales] = useState<string>();

	const openModalElementos = () => {
		setOpenModalElemento(true);
	}

	useEffect(() => {
		if (indDetalleUnidad !== null && indDetalleUnidad >= 0) {
			setDescDetalleUnidad(materialesCalculos[indDetalleUnidad]?.descripcionDetalle);
		} else if (totalMateriales && indDetalleUnidad === -1) { // totalizar
			setDescDetalleUnidad(totalMateriales.descripcionDetalle);
		} else {
			setDescDetalleUnidad("");
		}		
	}, [indDetalleUnidad])

	useEffect(() => {
		if (getDelegacion().id != "") {
			fetchUnidades(getDelegacion()?.id);
		}
	}, [getDelegacion]);

	const fetchUnidades = (id: string) => {
		const unidadesRepo: UnidadesRepository = new UnidadesApiRepository(getToken());
		setLoading(true);
		return unidadesRepo.getByDelegacion(id)
			.then((unidadesObraList) => {
				var unidades = unidadesObraList.map((unidad) => {
					return { ...unidad }
				})
				setUnidades(unidades)
			})
			.finally(() => { setLoading(false) });
	}

	const handleCloseElementoEquipo = (elementos: EstudioDetalleAvanzadoElemento[], indexEquipo: number, equipo?: EquipoCalculo) => {
		setOpenModalElemento(false);
		let equipos = [...equiposCalculos];
		equipos[indexEquipo].elementos = elementos;
		equipos[indexEquipo].tarifa = calculateTarifaEquipos(elementos);
		equipos[indexEquipo].costeEquipo = +equipos[indexEquipo].dias * +equipos[indexEquipo].tarifa;
		calculoTarifasEquipos = equipos?.reduce((partialSum, a) => partialSum + a.costeEquipo, 0) ?? 0;
		const equipoEuroTm = +(calculoTarifasEquipos / totalToneladas).toFixed(2);
		setEquipoEuroTm(equipoEuroTm);
		setEquipoTrasladoEuroTm(equipoEuroTm + trasladoEuroTm);
		setEquiposCalculos(equipos);
	}

	const calculateTarifaEquipos = (elementos: EstudioDetalleAvanzadoElemento[]) => {
		let total = 0
		elementos.map((el) => {
			let elRendimiento = el.rendimiento
			if (elRendimiento) {
				total = total + (+el.tarifa * +elRendimiento)
			}
		});

		return total.toFixed(2);
	}

	const cancelElementoEquipo = () => {
		setOpenModalElemento(false);

	}

	const editEquipo = (equipo: EquipoCalculo, index: number) => {
		setIndexSelected(index);
		openModalElementos();
	}

	const handleClickEquipos = (equipo: UnidadEquipoReadDto) => {
		if ((selectedEquipo?.id !== equipo.id)) {
			setSelectedEquipo(equipo);
		}
	}

	const handleClickTraslados = (traslado: UnidadObraElementoReadDto) => {
		if ((selectedTraslado?.id !== traslado.id)) {
			setSelectedTraslado(traslado);
		}
	}

	useEffect(() => {
		fetchUnidadObraEquipos(unidadObraId ?? "");
		fetchUnidadObraTraslados(unidadObraId ?? "");

		if (estudioDetalleAvanzadoId) {
			fetchEstudioDetalle(estudioDetalleAvanzadoId);
		}

		// calculos equipos
		for (let stepEquipos = 0; stepEquipos < equiposCalculos.length; stepEquipos++) {
			updateDiasEquipos(equiposCalculos[stepEquipos].dias.toString(), stepEquipos);
		}

		// calculos traslados
		for (let stepTraslados = 0; stepTraslados < trasladosCalculos.length; stepTraslados++) {
			updateDiasTraslados(trasladosCalculos[stepTraslados].dias, stepTraslados);
		}
	}, [unidadObraId])

	useEffect(() => {
		const materiales: MaterialCalculo[] = [];
		totalTramos = tramosEstudio?.reduce((partialSum, a) => partialSum + +a.tarifa, 0) ?? 0;

		materialesCalculos.forEach((element, index) => {
			element.totalEurosM2 = +(+element.riego + +(+element.precio + equipoTrasladoEuroTm + +totalTramos).toFixed(2) * (+element.espesor / 100) * +element.densidad).toFixed(2);
			let material = updateTotales(new MaterialCalculo(materialesCalculos[index]), equipoTrasladoEuroTm, totalTramos, +unidadMedida.id, CALCULO_AVANZADO_ID_MBC);
			materiales.push(material);
		});

		setMaterialesCalculos(materiales);
		if (totalizar) updateTotalMateriales(materiales);
	}, [equipoTrasladoEuroTm, tramosEstudio]);

	useEffect(() => {
		updateDiasCalculoAvanzado(diasCalculoAvanzado);
		const equipoEuroTm = +(calculoTarifasEquipos / totalToneladas).toFixed(2);
		setEquipoEuroTm(equipoEuroTm);
		const trasladoEuroTm = +(calculoTarifasTraslados / totalToneladas).toFixed(2);
		setTrasladoEuroTm(trasladoEuroTm);
		setEquipoTrasladoEuroTm(equipoEuroTm + trasladoEuroTm);
	}, [totalToneladas]);

	const fetchEstudioDetalle = useCallback(
		(id: string) => {
			const repo: EstudioDetalleRepository = new EstudioDetalleApiRepository(getToken());

			repo.getByEstudioDetalleAvanzadoId(id).then((resp) => {
				setEstudioDetalles(resp);
			})
		}
		, []);

	useEffect(() => {
		if (estudioDetalles) {
			if (totalizar && totalMateriales) {
				totalMateriales.descripcionDetalle = estudioDetalles[0].descripcion;
				totalMateriales.estudioDetalleId = estudioDetalles[0].id;							
			} else {
				const materiales: MaterialCalculo[] = [];
				estudioDetalles?.forEach(element => {				 
					materialesCalculos.forEach((x, index) => {
						if (x.orden === element.orden) {
							let material = updateTotales(new MaterialCalculo(x), equipoTrasladoEuroTm, totalTramos, +unidadMedida.id, CALCULO_AVANZADO_ID_MBC);
							material.descripcionDetalle = element.descripcion;
							material.estudioDetalleId = element.id;
							materiales.push(material);
						}
					});
				});
				setMaterialesCalculos(materiales);
			}			
		}
	}, [estudioDetalles]);

	useEffect(() => {
		if (totalizar) {
			updateTotalMateriales(materialesCalculos);

			// actualizo el coeficiente al mismo valor para todos los materiales
			if (materialesCalculos.length > 1) updateCoeficientes(materialesCalculos[0].coeficiente, 0);
		} else {
			if (materialesCalculos.length > 0 && estudioDetalleDescripcion.length > 0) {
				materialesCalculos[0].descripcionDetalle = estudioDetalleDescripcion[0];
			}
			
			setTotalMateriales(undefined);
		}
	}, [totalizar]);

	const updateTotalMateriales = (materiales: MaterialCalculo[]) => {
		let totales: MaterialCalculo = {
			codigoArticulo: 0,
			materialId: undefined,
			mezcla: "",
			densidad: "",
			espesor: "",
			superficie: "",
			tmNecesarias: "",
			precio: "",
			preciom2: 0,
			riego: 0,
			costeMezcla: 0,
			totalEurosM2: 0,
			coeficiente: materiales[0].coeficiente,
			descripcionDetalle: estudioDetalleAvanzado?.totalizar ? estudioDetalleDescripcion[0] : materiales[0].descripcionDetalle,
			unidadMedida: unidadMedida,
			orden: 0,
			estudioDetalleId: estudioDetalleAvanzado?.totalizar ? estudioDetalleId : undefined
		};
		//let superficieTotal = 0;			
		materiales.forEach(m => {
			//superficieTotal += +m.superficie;
			totales.importeCoste = (totales.importeCoste ?? 0) + (m.importeCosteM2 ?? 0);
			totales.precioCosteM2 = (totales.precioCosteM2 ?? 0) + (m.precioCosteM2 ?? 0);
			totales.precioCosteTm = (totales.precioCosteTm ?? 0) + (m.precioCosteTm ?? 0);
			totales.precioVentaM2 = (totales.precioVentaM2 ?? 0) + (m.precioVentaM2 ?? 0);
			totales.precioVentaTm = (totales.precioVentaTm ?? 0) + (m.precioVentaTm ?? 0);
			totales.importeVenta = (totales.importeVenta ?? 0) + (m.importeVentaM2 ?? 0);				
		});
		totales.superficie = materiales[0].superficie;
		setTotalMateriales(totales);
	};

	const addMaterial = (id: string, descripcionMaterial: string | undefined) => {
		const repo: MaterialesRepository = new MaterialesApiRepository(getToken());
		const repoPlantas: PlantasRepository = new PlantasApiRepository(getToken());

		if (materialesCalculos.length >= 1) {
			const descrip = [...descripcion];
			descrip[materialesCalculos.length] = !estudioDetalleAvanzado?.totalizar ? estudioDetalleDescripcion[0] : "";
			setDescripcion(descrip);
		}

		repo.getMaterialById(id).then((resp) => {
			let materiales = [...materialesCalculos, {
				materialId: id,
				mezcla: resp.descripcion ?? descripcionMaterial,
				densidad: resp.densidad == 0 ? "1" : resp.densidad.toString(),
				espesor: "0",
				precio: resp.precio.toString(),
				precioOriginal: resp.precio.toString(),
				superficie: "0",
				tmNecesarias: "0",
				costeMezcla: 0,
				coeficiente: totalizar && totalMateriales ? totalMateriales.coeficiente : coeficienteEstudio,
				unidadMedida: unidadMedida,
				descripcionDetalle: estudioDetalleDescripcion[0],
				orden: Math.max(...(materialesCalculos.map(x => x.orden)), 0) + 1,
				fechaSincronizacion: new Date(resp.fechaSincronizacion)
			} as MaterialCalculo
			];
			setMaterialesCalculos(materiales);
			if (totalizar) updateTotalMateriales(materiales);

			repoPlantas.getByCodigo(resp.planta).then((planta) => {
				if (planta.length > 0) {
					setSelectedPlanta(planta[0]);
				}
			})
		})
	}

	const addEquipo = (id: string) => {
		const repo: EquiposRepository = new EquipoApiRepository(getToken());

		repo.getEquipoById(id).then((resp) => {
			setEquiposCalculos([...equiposCalculos, {
				equipoId: id, tarifa: resp.tarifa, descripcion: resp.descripcion, costeEquipo: 0
			} as EquipoCalculo]);
		})
	}

	const addTraslado = (id: string) => {
		let resp = trasladosUnidad.find(x => x.id == id);
		setTrasladosCalculos([...trasladosCalculos, { unidadObraElementoId: id, tarifa: resp?.tarifa, descripcion: resp?.descripcion, coste: 0 } as EstudioDetalleAvanzadoTraslado]);
	}

	const fetchUnidadObraEquipos = useCallback(
		(id: string) => {
			const repo: UnidadesRepository = new UnidadesApiRepository(getToken());

			repo.getEquiposUnidadConFiltro(id).then((resp) => {
				setEquiposUnidad([...equiposUnidad, ...resp]);
			})
		}
		, []);

	const fetchUnidadObraTraslados = useCallback(
		(id: string) => {
			const repo: UnidadObraElementoRepository = new UnidadObraElementoApiRepository(getToken());
			if (delegacionId) {
				repo.getUnidadObraElementosConFiltro({
					delegacionIds: [delegacionId],
					tipoElementoIds: [UNIDAD_OBRA_ELEMENTO_TIPO_TRASLADO]
				}).then((resp) => {
					setTrasladosUnidad(resp);
				})
			} else {
				toast.warning("Debe seleccionar una delegación en el estudio para poder añadir traslados");
			}
		}
		, []);

	const updateMaterial = (index: number,
		densidad: string | undefined = undefined,
		espesor: string | undefined = undefined,
		superficie: string | undefined = undefined,
		toneladas: string | undefined = undefined,
		precio: string | undefined = undefined
	) => {
		let { materiales, totalToneladasMateriales } = updateMateriales([...materialesCalculos], index, densidad, espesor, superficie, toneladas, precio, equipoTrasladoEuroTm, totalTramos, unidadMedida);

		const toneladasTotales = +(totalToneladasMateriales ?? totalToneladas);
		setTotalToneladas(toneladasTotales);

		let material = updateTotales(new MaterialCalculo(materiales[index]), equipoTrasladoEuroTm, totalTramos, +unidadMedida.id, CALCULO_AVANZADO_ID_MBC);
		materiales[index] = material;
		setMaterialesCalculos(materiales);
		if (totalizar) updateTotalMateriales(materiales);
	}

	const updateDiasCalculoAvanzado = (dias: number) => {
		setDiasCalculoAvanzado(dias);
		let rendimiento = updateDiasRendimiento(dias, totalToneladas);
		setRdoMedioCalculoAvanzado(rendimiento);
		const equipos = [...equiposCalculos];
		const traslados = [...trasladosCalculos];
		updateDias(equipos, traslados);
		setFormErrors({ ...formErrors, dias: undefined });
	};

	const updateDescripcion = (desc: any, index: number) => {
		if (index === -1) { // totalizar
			totalMateriales!.descripcionDetalle = desc;
		} else {
			const material = [...materialesCalculos];
			material[index].descripcionDetalle = desc;
			const descrip = [...descripcion];
			descrip[index] = desc;
			setDescripcion(descrip);
			setMaterialesCalculos(material);
		}
	}

	const updateDiasEquipos = (dias: string, index: number) => {
		let equiposCalc = updateEquipos(dias, undefined, index, [...equiposCalculos], 1)
		const traslados = [...trasladosCalculos];
		updateDias(equiposCalc, traslados);
		setEquiposCalculos(equiposCalc);
	}

	const updateDiasTraslados = (dias: string, index: number) => {
		let traslados = updateDiasTraslado(dias, index, [...trasladosCalculos]);
		const equipos = [...equiposCalculos];
		updateDias(equipos, traslados);
		setTrasladosCalculos(traslados);
	}

	const updateTarifaTransporte = (precio: string, index: number) => {
		const tramo = [...tramosEstudio];
		tramo[index].tarifa = precio;
		setTramosEstudio(tramo);
	};

	const updateDias = (equipos: EquipoCalculo[], traslados: EstudioDetalleAvanzadoTraslado[]) => {
		calculoTarifasEquipos = equipos?.reduce((partialSum, a) => partialSum + a.costeEquipo, 0) ?? 0;
		const equipoEuroTm = +(calculoTarifasEquipos / totalToneladas).toFixed(2);
		setEquipoEuroTm(equipoEuroTm);
		calculoTarifasTraslados = traslados?.reduce((partialSum, a) => partialSum + a.coste, 0) ?? 0;
		const trasladoEuroTm = +(calculoTarifasTraslados / totalToneladas).toFixed(2);
		setTrasladoEuroTm(trasladoEuroTm);
		setEquipoTrasladoEuroTm(equipoEuroTm + trasladoEuroTm);
	}

	const updateRiegos = (riego: any, index: number) => {
		const materiales = [...materialesCalculos];
		materiales[index].riego = riego;
		let materialFun = updateTotalEurosM2(materiales, index, equipoTrasladoEuroTm, totalTramos);
		materialFun[index] = updateTotales(new MaterialCalculo(materialFun[index]), equipoTrasladoEuroTm, totalTramos, +unidadMedida.id, CALCULO_AVANZADO_ID_MBC);
		setMaterialesCalculos(materialFun);
		if (totalizar) updateTotalMateriales(materiales);
	}

	const updateCoeficientes = (coeficiente: any, index: number) => {
		let materiales: MaterialCalculo[] = [];

		if (totalizar) {
			materialesCalculos.forEach(m => {
				let material = new MaterialCalculo(m);
				material.coeficiente = coeficiente;
				material = updateTotales(material, equipoTrasladoEuroTm, totalTramos, +unidadMedida.id, CALCULO_AVANZADO_ID_MBC);
				materiales.push(material);
			});
			updateTotalMateriales(materiales);	
		} else {
			materiales = [...materialesCalculos];
			materiales[index].coeficiente = coeficiente;
			materiales[index] = updateTotales(new MaterialCalculo(materiales[index]), equipoTrasladoEuroTm, totalTramos, +unidadMedida.id, CALCULO_AVANZADO_ID_MBC);
		}
		
		setMaterialesCalculos(materiales);
	}

	// const updatePrecioVentaM2 = (precioVenta: any, index: number) => {
	// 	let materiales: MaterialCalculo[] = [];

	// 		materiales = [...materialesCalculos];
	// 		materiales[index].precioVentaM2 = precioVenta;
	// 		materiales[index].coeficiente = +(materiales[index].totalEurosM2/precioVenta).toFixed(2);
	// 		materiales[index] = updateTotales(new MaterialCalculo(materiales[index]), equipoTrasladoEuroTm, totalTramos, +unidadMedida.id, CALCULO_AVANZADO_ID_MBC);

	// 	setMaterialesCalculos(materiales);
	// }

	const deleteMaterial = (index: number) => {
		const materiales = [...materialesCalculos];
		materiales.splice(index, 1);
		setMaterialesCalculos(materiales);

		if (totalizar) updateTotalMateriales(materiales);

		setTotalToneladas(materiales?.reduce((partialSum, a) => partialSum + +a.tmNecesarias, 0) ?? 0);
	}

	const deleteEquipo = (index: number) => {
		const repoDetalleEquipo: EstudioDetalleAvanzadoEquipoRepository = new EstudioDetalleAvanzadoEquipoApiRepository(getToken());
		const equipo = [...equiposCalculos];

		if (equiposCalculos[index].id) {
			repoDetalleEquipo.delete(equiposCalculos[index].id!).then((resp) => {
				equipo.splice(index, 1);
				setEquiposCalculos(equipo);
				toast.success("Equipo eliminado correctamente");
				updateDias(equipo, trasladosCalculos);
			});
		} else {
			equipo.splice(index, 1);
			setEquiposCalculos(equipo);
			toast.success("Equipo eliminado correctamente");
			updateDias(equipo, trasladosCalculos);
		}
	}

	const deleteTraslado = (index: number) => {
		const repoDetalle: EstudioDetalleAvanzadoTrasladoRepository = new EstudioDetalleAvanzadoTrasladoApiRepository(getToken());
		const traslado = [...trasladosCalculos];

		if (trasladosCalculos[index].id) {
			repoDetalle.delete(trasladosCalculos[index].id!).then(() => {
				traslado.splice(index, 1);
				setTrasladosCalculos(traslado);
				toast.success("Traslado eliminado correctamente");
				updateDias(equiposCalculos, traslado);
			});
		} else {
			traslado.splice(index, 1);
			setTrasladosCalculos(traslado);
			toast.success("Traslado eliminado correctamente");
			updateDias(equiposCalculos, traslado);
		}
	}

	const fetchTramos = useCallback(
		(search: string) => {
			const repo: TramosRepository = new TramosApiRepository(getToken());

			let list = repo.getConFiltro({
				nombre: search,
				instalacionId: Number(selectedPlanta.instalacionId)
			}).then((resp) => {
				setSelectedTramo("");
				setTramosList(resp);
				return resp;
			});
			return list;
		}, [selectedPlanta]);


	const onAddTramo = async () => {
		if (tramosEstudio.length > 0) {
			toast.error("No se puede añadir mas de un tramo por estudio.")
			return;
		}

		if (tramosList) {
			let tramoSeleced = tramosList.find(x => x.id == selectedTramo);
			setTramosEstudio([...tramosEstudio, { tramoId: tramoSeleced?.id!, tarifa: tramoSeleced?.precioTonelada.toString()!, descripcion: tramoSeleced?.descripcion, tarifaOriginal: tramoSeleced?.precioTonelada.toString()!}]);
		}
	}

	const onDeleteTramo = async (tramo: EstudioDetalleAvanzadoTramoDto) => {
		const repoDetalle: EstudioDetalleAvanzadoTramoRepository = new EstudioDetalleAvanzadoTramoApiRepository(getToken());
		if (tramo.id) {
			repoDetalle.delete(tramo.id).then(() => {
				let tramoUpdList = tramosEstudio.filter(x => x.id !== tramo.id);
				setTramosEstudio(tramoUpdList);
				toast.success("Traslado eliminado correctamente");
			});
		} else {
			let tramoUpdList = tramosEstudio.filter(x => x.id !== tramo.id);
			setTramosEstudio(tramoUpdList);
			toast.success("Traslado eliminado correctamente");
		}
	}

	const onChangeTramo = (tramo: string) => {
		setSelectedTramo(tramo);
	}

	const closeModal = () => {
		let estudioDetallesArray: EstudioDetalle[] = []
		let material = materialesCalculos?.map(entity => {
			return new EstudioDetalleAvanzadoMaterial({
				id: entity.id,
				codigoArticulo: entity.codigoArticulo,
				materialId: entity.materialId,
				densidad: entity.densidad,
				espesor: isNaN(+entity.espesor) ? "" : entity.espesor,
				superficie: entity.superficie,
				toneladas: entity.tmNecesarias,
				volumen: 0,
				baremo: entity.precio,
				materialDescripcion: entity.mezcla,
				riego: entity.riego,
				coeficiente: entity.coeficiente,
				descripcionDetalle: entity.descripcionDetalle,
				orden: entity.orden,
				baremoOriginal: entity.precioOriginal,
				fechaSincronizacion: entity.fechaSincronizacion
			});
		});

		let equipo = equiposCalculos?.map(entity => {
			return new EstudioDetalleAvanzadoEquipo({
				id: entity.id,
				equipoId: entity.equipoId,
				tarifa: entity.tarifa,
				unidades: entity.unidades,
				elementos: entity.elementos,
				dias: entity.dias,
				equipoDescripcion: entity.descripcion
			});
		});

		let tramo = tramosEstudio?.map(entity => {
			return new EstudioDetalleAvanzadoTramo({
				tramoId: entity.tramoId,
				descripcion: entity.descripcion,
				tarifa: entity.tarifa,
				tarifaOriginal: entity.tarifaOriginal
			});
		});

		let estudioAvanzado = new EstudioDetalleAvanzado({
			material: material,
			equipo: equipo,
			tramo: tramo,
			traslado: trasladosCalculos,
			dias: diasCalculoAvanzado,
			totalizar: totalizar
		});

		if (validateForm(estudioAvanzado)) {
			// si se totalizan los materiales, solo se graba un estudio detalle
			if (totalizar) {
				let estudioDet = newEstudioDetalle(totalMateriales!, estudioAvanzado);
				estudioDetallesArray.push(estudioDet);
			} else {
				materialesCalculos?.map((element, index) => {
					let estudioDet = newEstudioDetalle(element, estudioAvanzado);	
					estudioDetallesArray.push(estudioDet);
				});
			}	
			
			handleCloseModal(estudioDetallesArray);
		}
	}

	const newEstudioDetalle = (material: MaterialCalculo, estudioAvanzado: EstudioDetalleAvanzado) => {
		let estudioDet = {} as EstudioDetalle;
		let precioCoste = material.precioCosteTm ?? 0;
		let importeCoste = material.importeCoste ?? 0;
		let precioVenta = material.precioVentaTm ?? 0;
		let importeVenta = material.importeVenta ?? 0;

		if (+unidadMedida.id != UNIDAD_MEDIDA_TONELADAS) {
			precioCoste = material.precioCosteM2 ?? 0;
			precioVenta = material.precioVentaM2 ?? 0;
		}

		estudioDet.coeficiente = material.coeficiente;
		estudioDet.descripcion = material ? material.descripcionDetalle : '';
		estudioDet.precioCoste = precioCoste ?? 0;
		estudioDet.importeCoste = importeCoste ?? 0;
		estudioDet.precioVenta = precioVenta ?? 0;
		estudioDet.importeVenta = importeVenta ?? 0;
		estudioDet.estudioDetalleAvanzado = estudioAvanzado;
		estudioDet.medicion = +material.superficie;
		estudioDet.unidadMedida = material.unidadMedida;
		estudioDet.unidadMedidaId = material.unidadMedida.id;
		estudioDet.unidadObraId = unidadObraId ?? "";
		estudioDet.id = material.estudioDetalleId ?? "";
		estudioDet.estudioDetalleAvanzadoId = estudioDetalleAvanzadoId;
		estudioDet.orden = material.orden;
		estudioDet.ordenDetalle = estudiosDetalleLength;

		return estudioDet;
	}

	const validateForm = (data: EstudioDetalleAvanzado): boolean => {
		let errors: FormErrors<EstudioDetalleAvanzado> = {};
		const fieldIsRequired = "Campo obligatorio.";
		
		if (!data.dias || data.dias <= 0) {
			errors = { ...errors, dias: fieldIsRequired };
		}

		if (data.totalizar) {
			if (data.material!.length < 2) {
				errors = { ...errors, material: fieldIsRequired };
				toast.error("Para totalizar se debe añadir más de un material");
			} else if (!totalMateriales?.descripcionDetalle) {
				errors = { ...errors, material: fieldIsRequired };
				toast.error("La descrición del material no puede estar vacía");	
			}
		} else if (data.material?.find(x => !x.descripcionDetalle)) {
			errors = { ...errors, material: fieldIsRequired };
			toast.error("La descrición del material no puede estar vacía");
		}

		setFormErrors(errors);

		return Object.keys(errors).length == 0;
	}

	const handleClickDesc = (item: any, index: number, showModal: boolean) => {
		if (!showModal) return;

		setIndDetalleUnidad(index);
		let options = unidades.find((el) => el.calculoAvanzadoId === CALCULO_AVANZADO_ID_MBC)?.descripcionUnidadObra.filter((elem) => elem.unidadMedida.id === item.unidadMedida.id);
		setOpenDescModal(true);
		setDescOptions(options?.map((el) => {
			return {
				text: el.descripcion,
				id: el.id,
				selected: el.descripcion === item.unidadMedida.descripcion,
				onClick: () => {
					setDescDetalleUnidad(el.descripcion);
				}
			};
		}))
	}

	const disableFinalizar = () => {
		if (materialesCalculos.filter(x => Number(x.coeficiente) > 0).length == 0) {
			return true;
		}

		if ((estudioDetalleAvanzadoId
			|| materialesCalculos.length >= 1)
			&& materialesCalculos.filter(x => x.importeVenta == Infinity || x.importeVenta == 0 || Number.isNaN(x.importeVenta) || x.importeVenta == undefined).length == 0
			&& materialesCalculos.filter(x => Number(x.coeficiente) > 0).length != 0
		) {
			if (equiposCalculos.filter(x => x.costeEquipo == Infinity || x.costeEquipo == 0 || Number.isNaN(x.costeEquipo) || x.costeEquipo == undefined).length == 0
				&& trasladosCalculos.filter(x => x.coste == Infinity || x.coste == 0 || Number.isNaN(x.coste) || x.coste == undefined).length == 0
			) {
				return false
			} else {
				return true
			}
		} else {
			return true;
		}
	}

	const fetchMaterialesInput = useCallback((search: string) => {
		const repo: UnidadesRepository = new UnidadesApiRepository(getToken());

		var mate = repo.materialConFiltro({
			filter: {
				unidadObraId: unidadObraId,
				descripcion: search
			}
		}).then((resp) => {
			setMaterialesUnidad(resp);
			return resp;
		})
		return mate

	}, [unidadObraId])

	const onChangeMaterial = (id: string) => {
		if (id != "") {
			setSelectedMateriales(id);
			var material = materialesUnidad.find(x => x.id == id);
			if (material) {
				var matSelected: UnidadMaterialReadDto = {
					materialId: material.materialId,
					unidadObraId: material.unidadObraId,
					id: material.id,
					descripcion: material.descripcion

				};
				setSelectedMaterial(matSelected);
			}
		}
	}

	return (
		<div>
			<ModalPage
				handleCloseModal={closeModal}
				handleCloseModalCustom={handleCloseCancelModal}
				opened={openModal}
				fields={
					<>
						<h3>Material</h3>
						<div className="double-col-grid">
							<div className="relative">
								<FormField<SingleSuggestionInputFieldProps>
									label="Materiales"
									value={
										{
											id: selectedMateriales ?? "",
											text: materialesUnidad.find(x => x.id == selectedMateriales) ? materialesUnidad.find(x => x.id == selectedMateriales)!.descripcion : ""
										}
									}
									className={"op-input95"}
									disabled={false}
									required
									onChange={(e) => onChangeMaterial(e)}
									searchCallback={fetchMaterialesInput}
									component={SingleSuggestionInputField}
									idTest="MaterialesSugInput"
								/>

								{selectedMateriales &&
									<div className="abs-right-30">
										<IconButton
											icon="done"
                                            onClick={() => addMaterial(selectedMaterial ? selectedMaterial.materialId : "", selectedMaterial ? selectedMaterial.descripcion : "")}											label="DoneMaterial"
										/>
									</div>
								}
							</div>
							<div className="flex-horizontal justify-end">
								<FormInput
									label="Días"
									value={diasCalculoAvanzado > 0 ? diasCalculoAvanzado.toString() : ""}
									onChange={(e) => { updateDiasCalculoAvanzado(+e.target.value); }}
									type="number"
									required={true}
									min={0}
									error={formErrors.dias}
									idTest="DiasCalculoAvanzadoInput"
								/>
								<FormInput
									label="Rendimiento medio"
									type="number"
									value={rdoMedioCalculoAvanzado.toFixed(2)}
									onChange={(e) => { }}
									disabled={true}
								/>
							</div>
						</div>

						<table className="mini-table">
							<thead>
								<tr className='table-header'>
									<th>Mezcla</th>
									<th>Densidad</th>
									<th>Espesor (cm)</th>
									<th>Superficie (m2)</th>
									<th>Tm</th>
									<th>Precio/Tm</th>
									<th>Precio/m2</th>
								</tr>
							</thead>
							<tbody>
								{materialesCalculos?.map((item: MaterialCalculo, index: number, element) => (
									<tr key={index}>
										<td>
											<TooltipComponent text={item.mezcla}>
												{item.mezcla}
											</TooltipComponent>
										</td>
										<td>
											<Input
												className='inline-input number'
												value={item.densidad ?? ''}
												onChange={(e) => updateMaterial(index, e.target.value)}
												type="number"
												min={0}
											/>
										</td>
										<td>
											{
												unidadMedida.id == "1" ?
													<Input
														className='inline-input number'
														value={item.espesor}
														onChange={() => { }}
														type="number"
														min={0}
														data={`EspesorMaterialInput${index}`}
													/>
													:
													<Input
														className='inline-input number'
														value={item.espesor}
														onChange={(e) => updateMaterial(index, undefined, e.target.value)}
														type="number"
														data={`EspesorMaterialInput${index}`}
														min={0}
													/>
											}
										</td>
										<td>
											<Input
												className='inline-input number'
												value={item.superficie}
												onChange={(e) => updateMaterial(index, undefined, undefined, e.target.value)}
												type="number"
												min={0}
												data={`SuperficieMaterialInput${index}`}
											/>
										</td>
										<td>
											{
												unidadMedida.id == "1" ?
													<Input className='inline-input number'
														type="number"
														onChange={(e) => updateMaterial(index, undefined, undefined, undefined, e.target.value)}
														value={item.tmNecesarias}
														min={0}
														data={`TmNecesariasInput-${index}`}
													/>
													:
													<Input className='inline-input number'
														value={item.tmNecesarias}
														onChange={() => { }}
														type="number"
														min={0}
														data={`TmNecesariasInput${index}`}
													/>
											}
										</td>
										<td className={item.precio != item.precioOriginal ? 'edited' : ''}>
											<TooltipComponent text={`Precio actualizado el día ${item.fechaSincronizacion?.toLocaleDateString('es-es') ?? ""}`}>
												<Input
													className={`inline-input${item.precio != item.precioOriginal ? ' edited' : ''} number`}
													value={item.precio ?? ''}
													onChange={(e) => updateMaterial(index, undefined, undefined, undefined, undefined, e.target.value)}
													type="number"
													min={0}
												/>
											</TooltipComponent>
										</td>
										<td>
											<div className='inline-input number'>
												{item.preciom2 && item.preciom2 !== Infinity ? formatNumber(item.preciom2) : ""}
											</div>
										</td>
										<td><IconButton
											icon="delete"
											onClick={() => deleteMaterial(index)}
										/></td>
									</tr>
								))}

								<tr>
									<td colSpan={4}></td>
									<td data-cy={`TmNecesariasResultado`}>
										<div className='inline-input number'>
											{formatNumber(+totalToneladas.toFixed(2))}
										</div>
									</td>
									<td colSpan={3}></td>
								</tr>
							</tbody>
						</table>
						{/* --------------------------------------------------------- */}
						<h3>Equipos</h3>
						<div className="double-col-grid with-add-btn">
							<div className="relative">
								<FormField<DropdownProps>
									handleClick={() => { fetchUnidadObraEquipos(unidadObraId ?? "") }}
									label="Equipos"
									options={equiposUnidad.map(
										(element) => {
											return {
												text: element.descripcion + " - " + formatNumber(element.tarifa),
												id: element.id.toString(),
												selected: selectedEquipo?.id == element.id ? true : false,
												onClick: () => { handleClickEquipos(element); }
											}
										})}
									disabled={false}
									singleSelection={true}
									component={Dropdown}
								/>

								{selectedEquipo &&
									<div className="relative">
										<IconButton
											icon="done"
											onClick={() => addEquipo(selectedEquipo?.equipoId ? selectedEquipo.equipoId : "")}
											className="add-btn transform-translate-y-100"
											label="DoneEquipo"
										/>
									</div>
								}

								<table className="mini-table">
									<thead>
										<tr className='table-header'>
											<th>Equipo</th>
											<th>Tarifa</th>
											<th>Días</th>
											<th>Coste</th>
										</tr>
									</thead>
									<tbody>
										{equiposCalculos?.map((item: EquipoCalculo, index: number,) => (
											<tr key={`equipos-${item.id}-${index}`}>
												<td>
													<TooltipComponent text={item.descripcion}>
														{item.descripcion}
													</TooltipComponent>
												</td>
												<td>
													<div className='inline-input number'>
														{formatNumber(parseFloat(item.tarifa))}
													</div>
												</td>
												<td>
													<Input className='inline-input number' value={item.dias ?? ''}
														onChange={(e) => updateDiasEquipos(e.target.value, index)}
														type="number"
														min={0}
														data={`DiasEquipoInput${index}`}
													/>
												</td>
												<td>
													<div className='inline-input number'>
														{item.costeEquipo ? formatNumber(item.costeEquipo) : ""}
													</div>
												</td>
												<td>
													<div className="icons-horizontal-inlinegrid">

														<IconButton
															icon="delete"
															onClick={() => deleteEquipo(index)}
														/>
														<IconButton
															icon="edit"
															onClick={() => editEquipo(item, index)}
														/>
													</div>
												</td>
											</tr>
										))}
										<tr>
											<td colSpan={3}></td>
											<td colSpan={2}>
												<div className='inline-input number'>
													{equipoEuroTm > 0 ? formatNumber(equipoEuroTm) + " €/Tm" : ""}
												</div>
											</td>
										</tr>

										{openModalElemento ?
											<CalculoAvanzadoElementosEquipo
												openModal={openModalElemento}
												handleCloseModal={handleCloseElementoEquipo}
												handleCloseCancelModal={cancelElementoEquipo}
												equipoCalculo={equiposCalculos[indexSelected!]}
												delegacionId={delegacionId}
												unidadObraId={unidadObraId}
												indexEquipo={indexSelected!}
											/>
											: ""
										}
									</tbody>
								</table>
							</div>
							{/* TRASLADOS */}
							<div className="relative">
								<FormField<DropdownProps>
									label="Traslados"
									options={trasladosUnidad.map(
										(element) => {
											return {
												text: element.descripcion + " - " + formatNumber(element.tarifa),
												id: element.id.toString(),
												selected: selectedTraslado?.id == element.id ? true : false,
												onClick: () => { handleClickTraslados(element); }
											}
										})}
									disabled={false}
									singleSelection={true}
									component={Dropdown}
								/>

								{selectedTraslado &&
									<div className="relative">
										<IconButton
											icon="done"
											onClick={() => addTraslado(selectedTraslado?.id ? selectedTraslado?.id : "")}
											className="add-btn transform-translate-y-100"
											label="DoneTraslado"
										/>
									</div>
								}

								<table className="mini-table">
									<thead>
										<tr className='table-header'>
											<th>Traslado</th>
											<th>Tarifa</th>
											<th>Días</th>
											<th>Coste</th>
										</tr>
									</thead>
									<tbody>
										{trasladosCalculos?.map((item: EstudioDetalleAvanzadoTraslado, index: number) => (
											<tr key={`traslados-${item.id}-${index}`}>
												<td>
													<TooltipComponent text={item.descripcion}>
														{item.descripcion}
													</TooltipComponent>
												</td>
												<td>
													<div className='inline-input number'>
														{formatNumber(item.tarifa)}
													</div>
												</td>
												<td>
													<Input className='inline-input number' value={item.dias ?? ''}
														onChange={(e) => updateDiasTraslados(e.target.value, index)}
														type="number"
														min={0}
														data={`DiasTrasladoInput${index}`}
													/>
												</td>
												<td>
													<div className='inline-input number'>
														{formatNumber(item.coste)}
													</div>
												</td>
												<td>
													<IconButton
														icon="delete"
														onClick={() => deleteTraslado(index)}
													/>
												</td>

											</tr>
										))}
										<tr>
											<td colSpan={3}></td>
											<td colSpan={2}>
												<div className='inline-input number'>
													{trasladoEuroTm > 0 ? formatNumber(trasladoEuroTm) + " €/Tm" : ""}
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						{/* ------------------------------------------------ */}
						<h3>Transporte</h3>
						<div className="double-col-grid">
							<div className="relative">
								<FormField<SingleSuggestionInputFieldProps>
									label="Transporte"
									value={
										{
											id: tramosList && tramosList.length > 0 && selectedTramo && selectedTramo != "" ? tramosList.find(x => x.id == selectedTramo)!.id : "",
											text: tramosList && tramosList.length > 0 && selectedTramo && selectedTramo != "" ? tramosList.find(x => x.id == selectedTramo)!.descripcion : "",
										}
									}
									className={"op-input95"}
									disabled={materialesCalculos.length == 0}
									onChange={(e) => onChangeTramo(e)}
									searchCallback={fetchTramos}
									component={SingleSuggestionInputField}
								/>

								{(selectedTramo?.length != 0 && selectedTramo != null) ?
									<div className="abs-right-30">
										<IconButton
											icon="done"
											onClick={() => onAddTramo()}
											className="add-btn"
										/>
									</div>
									: ""
								}

							</div>
						</div>
						<table className="mini-table">
							<thead>
								<tr className='table-header'>
									<th>Transporte</th>
									<th>Tarifa</th>
									<th>Toneladas</th>
									<th>Coste</th>
								</tr>
							</thead>
							<tbody>

								{tramosEstudio?.map((item: EstudioDetalleAvanzadoTramoDto, index: number,) => (
									<tr key={index}>
										<td>{item.descripcion}</td>
										<td className={item.tarifa != item.tarifaOriginal ? 'edited' : ''}>
          									<Input
          									  className={`inline-input ${item.tarifa != item.tarifaOriginal ? 'edited' : ''} number`}
          									  value={item.tarifa ?? ''}
          									  onChange={(e) => updateTarifaTransporte(e.target.value, index)}
          									  type="number"
          									  min={0}
         									/>
        								</td>
										<td>
											<div className='inline-input number'>
												{formatNumber(totalToneladas)}
											</div>
										</td>
										<td>
											<div className='inline-input number'>
												{formatNumber(+item.tarifa * totalToneladas)}
											</div>
										</td>
										<td>
											<div className="icons-horizontal-inlinegrid">
												<IconButton
													icon="delete"
													onClick={() => onDeleteTramo(item)}
												/>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>

						{/* ------------------------------------------------ */}

						<h3>Riego</h3>
						<table className="mini-table">
							<thead>
								<tr className="table-header">
									<th>Descripción</th>
									<th>Uds</th>
									<th>Mezcla</th>
									<th>Precio Costes €/m2</th>
									<th>Precio Costes €/Tm</th>
									<th>Importe Costes €/{+unidadMedida.id === UNIDAD_MEDIDA_TONELADAS ? 'Tm' : 'm2'}</th>
									<th>Riego</th>
									<th>Coeficiente</th>
									<th>Precio Ventas €/m2</th>
									<th>Precio Ventas €/Tm</th>
									<th>Importe Ventas €/{+unidadMedida.id === UNIDAD_MEDIDA_TONELADAS ? 'Tm' : 'm2'}</th>

								</tr>
							</thead>
							<tbody>
								{materialesCalculos.map((item: MaterialCalculo, index: number, element) => (
									<tr key={index + (item?.id ?? "")}>
										<td 
											onClick={() => handleClickDesc(item, index, !totalizar) } 
											className={!totalizar ? "pointer" : ""}>
											{!totalizar && <TooltipComponent text={item.descripcionDetalle}>
												{item.descripcionDetalle}
											</TooltipComponent>}
										</td>

										<td>{unidadMedida.descripcion}</td>

										<td>
											<TooltipComponent text={item.mezcla}>
												{item.mezcla}
											</TooltipComponent>
										</td>
										<td>
											<div className='inline-input number'>
												{item.precioCosteM2 ? formatNumber(item.precioCosteM2) : ""}
											</div>
										</td>
										<td>
											<div className='inline-input number'>
												{item.precioCosteTm ? formatNumber(item.precioCosteTm) : ""}
											</div>
										</td>
										<td>
											<div className='inline-input number'>
												{item.importeCoste ? formatNumber(item.importeCoste) : ""}
											</div>
										</td>
										<td>
											<Input className='inline-input number'
												value={item.riego ?? ''}
												onChange={(e) => updateRiegos(e.target.value, index)}
												type="number"
												min={0}
												data={`RiegoInput${index}`}
											/>
										</td>
										<td>
											<Input className='inline-input number'
												value={item.coeficiente ?? ''}
												onChange={(e) => updateCoeficientes(e.target.value, index)}
												type="number"
												min={0}
												required
												data={`CoeficienteInput${index}`}
											/>
										</td>
										<td>
										{/* <Input className='inline-input number'
												value={item.precioVentaM2 ?? ''}
												onChange={(e) => updatePrecioVentaM2(e.target.value, index)}
												type="number"
												min={0}
												required
												data={`PrecioVentaM2Input${index}`}
											/> */}
											<div className='inline-input number'>
												{item.precioVentaM2 ? formatNumber(item.precioVentaM2) : ""}
											</div>
										</td>
										<td>
											<div className='inline-input number'>
												{item.precioVentaTm ? formatNumber(item.precioVentaTm) : ""}
											</div>
										</td>
										<td>
											<div className='inline-input number'>
												{item.importeVenta ? formatNumber(item.importeVenta) : ""}
											</div>
										</td>
									</tr>
								))}
								{/* totalizar */}
								{totalizar && totalMateriales && <tr>
									<td onClick={() => handleClickDesc(totalMateriales, -1, true)} className="pointer">
										<TooltipComponent text={totalMateriales.descripcionDetalle}>
											{totalMateriales.descripcionDetalle}
										</TooltipComponent>
									</td>
									<td>{unidadMedida.descripcion}</td>
									<td>Total Costes</td>
									<td>
										<div className='inline-input number'>
											{totalMateriales?.precioCosteM2 ? formatNumber(+totalMateriales.precioCosteM2) : ""}
										</div>
									</td>
									<td>
										<div className='inline-input number'>
											{totalMateriales?.precioCosteTm ? formatNumber(+totalMateriales.precioCosteTm) : ""}
										</div>
									</td>
									<td>
										<div className='inline-input number'>
											{totalMateriales?.importeCoste ? formatNumber(+totalMateriales.importeCoste) : ""}
										</div>
									</td>
									<td>									
									</td>
									<td>
										<TooltipComponent text={"Total Ventas"}>
											Total Ventas
										</TooltipComponent>
									</td>
									<td>
										<div className='inline-input number'>
											{totalMateriales?.precioVentaM2 ? formatNumber(+totalMateriales.precioVentaM2) : ""}
										</div>
									</td>
									<td>
										<div className='inline-input number'>
											{totalMateriales?.precioVentaTm ? formatNumber(+totalMateriales.precioVentaTm) : ""}
										</div>
									</td>
									<td>
										<div className='inline-input number'>
											{totalMateriales?.importeVenta ? formatNumber(+totalMateriales.importeVenta) : ""}
										</div>
									</td>
								</tr>}
							</tbody>							
						</table>

						{+unidadMedida.id === UNIDAD_MEDIDA_M2 && (estudioDetalleAvanzadoId !== undefined || materialesCalculos.length > 1) && 
						<div className="flex-horizontal">
							<input
								id="totalizar"
								style={{cursor: 'pointer'}}
								checked={totalizar}
								onChange={() => setTotalizar(!totalizar)}								
								type="checkbox"
							/>
							<label htmlFor="totalizar">Totalizar</label>							
						</div>}

						<div className="buttons-right">
							<button
								className="btn-primary"
								onClick={() => handleCloseCancelModal()}
							>
								Cancelar
							</button>

							<CustomButton
								title={materialesCalculos.length >= 1 && materialesCalculos.filter(x => x.importeVenta == Infinity || x.importeVenta == 0 || Number.isNaN(x.importeVenta)).length != 0 ? 'Complete el estudio para poder finalizarlo' : 'Finalizar'}
								className="btn-primary"
								btnStyle="accept"
								onButtonClick={() => { closeModal(); }}
								type="button"
								text="Finalizar"
								disabled={disableFinalizar()}
							/>
						</div>
					</>
				}
				modalTitle={"Cálculo Avanzado de MBC"}
				bodyClassName="--large"
				modalDisable={readOnly}
			>
			</ModalPage>

			{openDescModal ? <Modal
				className="narrow-modal"
				show={openDescModal}
				onHide={() => setOpenDescModal(false)}>
				<ModalHeader>
					<ModalTitle>
						Actualizar descripción
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className="desc-update-container">
						<label>{indDetalleUnidad != null && indDetalleUnidad >= 0 ? 
						`Actualizar descripción para la mezcla ${materialesCalculos[indDetalleUnidad].mezcla}:` 
						: "Actualizar descripción:"}</label>
						<CustomInput
							resizable
							value={descDetalleUnidad}
							className={'platform-input secondary-input'}
							formClassName={"secondary-input"}
							onChange={(e) => {
								setDescDetalleUnidad(e)
							}}
						/>
						<FormField<DropdownProps>
							value={descOptions?.find((item: any) => item?.selected)?.text}
							options={descOptions}
							required={true}
							singleSelection={true}
							component={Dropdown}
						/>
					</div>
					<div className="bottom-buttons">
						<CustomButton
							key={`removeCancelModal`}
							title={'Cancelar'}
							className={'btn-primary'}
							onButtonClick={() => {
								setOpenDescModal(false);
								setDescDetalleUnidad("");
								setIndDetalleUnidad(null);
							}}
							text="Cancelar"
						/>
						<CustomButton
							disabled={descDetalleUnidad ? descDetalleUnidad.trim() == "" : true}
							key={`confirmDeleteModal`}
							title={'Actualizar'}
							className={'btn-primary'}
							btnStyle="delete"
							onButtonClick={() => {
								if (indDetalleUnidad !== null) updateDescripcion(descDetalleUnidad, indDetalleUnidad);
								setOpenDescModal(false);
								setDescDetalleUnidad("");
								setIndDetalleUnidad(null);
							}}
							text="Aceptar"
						/>
					</div>
				</ModalBody>
			</Modal> : ""}
		</div >
	)
}

export default CalculoAvanzado;