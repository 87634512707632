import { useContext } from "react";
import TagCloud from "../../../../components/ui/objects/TagCloud/TagCloud";
import { ClienteTags } from "../../context/Cliente.Context";

const ClienteTagCloud: React.FC<{}> = () => {

	const { tags } = useContext(ClienteTags);

	return (
		<TagCloud tags={tags} />
	)
}

export default ClienteTagCloud;