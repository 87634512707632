import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface TooltipComponentProps {
    text: string;
    children: any;
    showLengthZero?: boolean
}

const TooltipComponent: React.FC<TooltipComponentProps> = ({ text, children, showLengthZero = false }) => {

    const tooltip = (
        <Tooltip
            id="tooltip"
            style={{
                whiteSpace: "pre-wrap",
                maxWidth: "600px",
                background: "white",
                padding: "12px",
                border: "1px solid #ffc72c"
            }
            }>
            {text ? text.toUpperCase() : ""}
        </Tooltip>
    )

    const tooltipLengthZero = (
        <Tooltip
            id="tooltip">
            {""}
        </Tooltip>
    )

    return (
        <OverlayTrigger
            placement='top'
            overlay={text?.length > 0 ? tooltip : tooltipLengthZero}
        >
            <div style={{ width: "100%" }} >{children}</div>
        </OverlayTrigger>
    )
}

export default TooltipComponent