import { EstudioDetalleApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import ApiRequest from "../../../../domain/ApiRequest";
import { EstudioDetalleDto, EstudioDetalleUpdateDto } from "../../domain/model/EstudioDetalle";

export default class EstudioDetalleUpdate extends ApiRequest<
EstudioDetalleUpdateDto,
EstudioDetalleDto
>
{
	constructor(data: EstudioDetalleUpdateDto, token: string) {
		super(
			"PUT",
			EstudioDetalleApiEndpoints.UPDATE,
			{ Authorization: `${token}`},
			data,
		)
	}
}