interface EstadoOfertaEntity  {
	id: string,
	descripcion: string,
}

export type EstadoOfertaFilter = {     
	filter: {
		ids: number[];
	}
 };

export default class EstadoOferta implements EstadoOfertaEntity {

	id: string;
	descripcion: string;
	

	constructor(entity: EstadoOfertaEntity) {
		
		this.id = entity.id;
		this.descripcion = entity.descripcion;
		
	}

	public static toArray(entities: EstadoOfertaEntity[]): EstadoOferta[] {
		return entities.map(entity => { return new EstadoOferta(entity) });
	}
}


export type EstadoOfertaDto = EstadoOferta;
export type EstadoOfertaCreationDto = EstadoOferta;
export type EstadoOfertaUpdateDto = EstadoOferta;
