import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { TipoSeguimientoClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { TipoSeguimientoClienteDto } from "../../domain/model/TipoSeguimientoCliente";

export default class TipoSeguimientoClienteRequestGetAll extends ApiRequest<{}, BaseResponse<TipoSeguimientoClienteDto[]>>
{
	constructor( token: string) {
		super(
			"GET",
			TipoSeguimientoClienteEndpoints.ALL,
			{ Authorization: token },
			{}
		)
	}
}