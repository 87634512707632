import ApiRequest from "../../../../domain/ApiRequest";
import { ClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { ClienteCreationDto } from "../../domain/model/Cliente";

export default class ClienteRequestCreate extends ApiRequest<
ClienteCreationDto,
boolean
>
{
	constructor(data: ClienteCreationDto, token: string) {
		super(
			"POST",
			ClienteEndpoints.CREAR,
			{ Authorization: `${token}`},
			data,
		)
	}
}