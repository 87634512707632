import OfertasProvider from "./context/Ofertas.Provider";
import OfertasGrid from "./views/objects/Ofertas.Grid";
import "./Ofertas.scss";
import OfertasFilterSection from "./views/objects/Filter section/Ofertas.FilterSection";
import TableScreen from "../../components/common/TableScreen/Table.Screen";
import { VentanaPermiso } from "../Login/domain/model/VentanaPermiso";

interface Props {
	permisoVentana: VentanaPermiso
}

const Ofertas: React.FC<Props> = ({permisoVentana}: Props) => {	
	return (
		<>
		<OfertasProvider>
    	<div className='grid-view-container'>

			<TableScreen
				title="Ofertas"
				className="ofertas"
				filterSection={
					<div className="filters-container">
					<OfertasFilterSection />
					</div>
				}>
					
			</TableScreen>
			<OfertasGrid permisoVentana={permisoVentana} />
		</div>
		</OfertasProvider>
		</>
	)
}

export default Ofertas;