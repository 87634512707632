import Tag from "../../atoms/Tag/Tag";
import { FilterTag } from "../../../../modules/Oportunidades/context/FilterFields.Context";

const TagCloud: React.FC<{
	tags: FilterTag[]
	idTest?:string
}> = ({
	tags,
	idTest
}) => {
	return (
		<div className="tag-cloud" data-cy="TagCloud">
			{
				tags.map(tag => {
					return tag.text ? (
						<div key={tag.label}>
					<Tag  label={tag.label} text={tag.text} handleClear={tag.onClear} idTest={idTest}/>
					</div>) : null
				})
			}
		</div>
	)
}

export default TagCloud;