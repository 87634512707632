import ApiRequest from "../../../../domain/ApiRequest";
import { BaseFilter } from "../../../../domain/BaseFilter";
import BaseResponse from "../../../../domain/BaseResponse";
import { ClausulasApiEndpoints, OfertasEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { ClausulaReadDto } from "../../domain/model/Clausula";

export default class ClausulasRequestListByFilter extends ApiRequest<
BaseFilter<any>,
BaseResponse<ClausulaReadDto[]>>
{
	constructor(data: any, token: string) {
		super(
			"POST",
			ClausulasApiEndpoints.CON_FILTRO,
			{ Authorization: token },
			data
		)
	}
}