import ApiRequest from "../../../../domain/ApiRequest";
import { ContratoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import Contrato from "../../domain/model/Contrato";

export default class ContratoAdd extends ApiRequest<
Contrato,
Contrato
>
{
	constructor(data: Contrato, token: string) {
		super(
			"POST",
			ContratoApiEndpoints.ADD,
			{ Authorization: `${token}`},
			data,
		)
	}
}