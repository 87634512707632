import { EstadoOportunidadesEndpoints } from "../../modules/Oportunidades/infraestructure/oportunidades.api-endpoints";
import { EstadoOportunidadesDto } from "../../domain/model/EstadoOportunidades";
import BaseResponse from "../../domain/BaseResponse";
import { BaseApiRequest } from "@pavabits/components";

export default class EstadosOportunidadesRequestListGetAll extends BaseApiRequest<undefined,BaseResponse<EstadoOportunidadesDto[]>> {
	public constructor(token: string) {
		super(
            "GET",
            EstadoOportunidadesEndpoints.ESTADO_OPO,
            undefined,
            { Authorization: token }

		)
	}
}