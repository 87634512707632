import ApiRequest from "../../../../domain/ApiRequest";
import { ContactoClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { ContactoClienteDto } from "../../domain/model/ContactosCliente";

export default class ContactoClienteRequestGetByContacto extends ApiRequest<{}, ContactoClienteDto[]>
{
	constructor(personaContacto: string, token: string) {
		super(
			"POST",
			ContactoClienteEndpoints.CON_FILTRO,
			{ Authorization: token },
			{ "filter": {
				"personaContacto": personaContacto
			  }
			}
		)
	}
}