import i18next from "i18next";
import { initReactI18next } from "react-i18next";

//Import all translation files

import translationSpanish from "./i18n/es.json";


//Import translation2 file

//---Using translation
const resources = {
    /*en: {
        main: translationSpanish,
    },*/
    es: {
        main: translationSpanish,
    }
}

//---Using different namespaces
// const resources = {
//     en: {
//       translationSpanish
//     },
//     es: {
//         home: translationSpanish,
//     },
//     fr: {
//         home: translationFrench,
//     },
// }

i18next
.use(initReactI18next)
.init({
  resources,
  lng:"es", //default language
});

export default i18next;