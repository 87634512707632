import { useContext, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import FormInput from "../../../../components/ui/atoms/Input/FormInput/FormInput";
import { CustomButton } from "../../../../shared/components/Buttons/CustomButton";
import { AuthContext } from "../../../Login/AuthContextProvider";
import OfertasRepository from "../../domain/OfertasRepository";
import OfertasApiRepository from "../../infraestructure/api/Ofertas.ApiRepository";

interface Props {
	open:boolean,
    ofertaId:string,
    descripcion_old?:string,
    url_old?:string,
    handleCloseModal:()=>void
}


const DriveUrlModal: React.FC<Props> = (
    {
        open,
        ofertaId,
        descripcion_old,
        url_old,
        handleCloseModal
    }:Props
)=>
{
    const [openDriverUrl, setOpenDriverUrl]=useState<boolean>(open);
    const [url, setUrl]=useState<string>(url_old??"");
    const [descripcion, setDescripcion]=useState<string>(descripcion_old??"");
    const { t } = useTranslation<['main']>(['main']);

    const {getToken, getDelegacion} = useContext(AuthContext);

    const save=()=>{
        const repo:OfertasRepository= new OfertasApiRepository(getToken())
            repo.OfertasUpdateDriveUrl(url, ofertaId,descripcion).then((resp)=>{
                toast.success("Url de google drive guardada correctamente");
                handleCloseModal();
            });
    }
return  (
    <Modal
    className="filters-modal modal-l"
    show={open}
    onHide={() => {setOpenDriverUrl(false);handleCloseModal();}}>
    <ModalHeader>
        <ModalTitle>
            Url Google Drive
        </ModalTitle>
    </ModalHeader>
    <ModalTitle className="modal-title--subtitle">
    </ModalTitle>
    <ModalBody>
        
        <FormInput
            label="Descripción"
            value={descripcion}
            onChange={(e)=>setDescripcion(e.target.value)}>
        </FormInput>
            
        <FormInput
            label="Url"
            value={url}
            onChange={(e)=>setUrl(e.target.value)}>
        </FormInput>
            
            <p></p>
            <div className="bottom-buttons">
                <CustomButton
                    text="Guardar"
                    onButtonClick={()=>{save()}}
                />
                <div className="inline-button">
                <CustomButton
                    text="Cancelar"
                    onButtonClick={()=>{handleCloseModal()}}
                />
            </div>
            
        </div>
    </ModalBody>
</Modal>
)
}

export default DriveUrlModal;