import { BaseApiRequest } from "@pavabits/components";
import BaseResponse from "../../../../domain/BaseResponse";
import { AccionComercialDto } from "../../domain/model/AccionComercial";
import { OportunidadesEndpoints } from "../oportunidades.api-endpoints";

export default class AccionesComercialesRequestById extends BaseApiRequest<
{},
BaseResponse<AccionComercialDto[]>
> {
	public constructor(oportunidadId: string, token: string) {
		super(
			"GET",
			OportunidadesEndpoints.ACCIONES_COMERCIALES,
			{},
			{ Authorization: `${token}`},
			{ "oportunidadId" : oportunidadId }
		)
	}
}