import { BaseRepository } from "@pavabits/components";
import ApiRepositoryBase from "../../domain/ApiRepositoryBase";
import BaseResponse from "../../domain/BaseResponse";
import UnidadMedida, { UnidadMedidaDto } from "../../domain/model/UnidadMedida";
import UnidadesMedidaRequestListByFilter from "../ws/UnidadesMedida.RequestListByFilter";
import UnidadesMedidaRepository from "../../domain/UnidadesMedidaRepository";


export default class UnidadesMedidaApiRepository
	extends BaseRepository<
		UnidadMedidaDto,
		UnidadMedida
	> implements UnidadesMedidaRepository {
        
    protected transformDtoIntoModel(dto: UnidadMedidaDto): UnidadMedida {
        return new UnidadMedida(dto);
    }

	async getConFiltro(): Promise<UnidadMedidaDto[]> {
		const res= await new UnidadesMedidaRequestListByFilter(this.authToken).executeAsPromise();
		return res?.items;
	}


}