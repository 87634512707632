import { BaseRepository } from "@pavabits/components";
import AdjudicacionRepository from "../../domain/AdjudicacionRepository";
import { AjudicacionDto } from "../../domain/model/Adjudicacion";
import Adjudicacion from "../../modules/Oportunidades/domain/model/Adjudicacion";
import Contratista from "../../modules/Oportunidades/domain/model/Contratista";
import AdjudicacionRequestListByFilter from "../ws/Adjudicacion.RequestListByFilter";

export default class AdjudicacionApiRepository extends BaseRepository<
AjudicacionDto[],
Adjudicacion[]
> implements AdjudicacionRepository {

	fetchAdjudicacion(data: any): Promise<Adjudicacion[]> {
		return new Promise((resolve, reject) => {
			return new AdjudicacionRequestListByFilter(data, this.authToken)
				.request()
				.then((response) => {
					if (response.success) {
						resolve(this.transformDtoIntoModel(response.data.items));
					} else {
						reject(`Error ${response.errorCode}: ${response.errorMessage}`);
					}
				})
				.catch((error) => {
					reject(error);
				})
		})
	}

	protected transformDtoIntoModel(dto: AjudicacionDto[]): Adjudicacion[] {
		if (dto && dto.length) {
			return dto.map(item => {
				return new Adjudicacion(
					item.codigoPlica,
					item.codigoLicitacion,
					{} as Contratista,
					item.contratistaDescripcion,
					item.cif,
					item.contratistaId
				)
			})
		}
		return []
	}

}