import SidebarNavigationItem from "./Sidebar.Navigation.Item";
import { Routes } from "../../../../router/routes/Routes";
import { useContext } from "react";
import { HistoryLocation } from "../../../../context/History.Context";
import { AuthContext } from "../../../../modules/Login/AuthContextProvider";
import SidebarDropdownItem from "./SidebarDropdownItem";
import { VentanaPermiso } from "../../../../modules/Login/domain/model/VentanaPermiso";

const SidebarNavigation: React.FC<{}> = () => {
	const { pathname } = useContext(HistoryLocation);
	const { hasClaim, getUserFunctionalities: permisosVentana } = useContext(AuthContext);

	return (
		<div className="sidebar__navigation" data-cy="SidebarNavegacion">
			<ul>
			<h4>MENÚ</h4>
				{
					permisosVentana().filter(x => !x.nombreVentana && x.url !== "").map((navItem: VentanaPermiso) => {
						const funcionalidades = permisosVentana().filter(x => x.nombrePadre === navItem.nombrePadre && x.nombreVentana);
						if (funcionalidades && funcionalidades.length > 0) {
							return (
								<div key={`navItem-${navItem.id}`} data-cy={navItem.nombrePadre}>
								<SidebarDropdownItem 
                                        selected={pathname.includes(navItem.url)}
                                        label={navItem.nombrePadre}
                                        options={funcionalidades.map(func => {
                                            return {
                                                id: func.id.toString(),
                                                optionLabel: func.nombreVentana,
                                                navigateTo: func.url,
                                                optionSelected: pathname.includes(func.url)
                                            }
                                        })}
                                        icon={navItem.icon}
                                        isImage={navItem.iconIsImage}
                                    />							
								</div>
							)
						}
						else {					 
							return <SidebarNavigationItem 
								key={`navItem-${navItem.id}`} 
								item={{  
									id: navItem.id.toString(),
									icon: navItem.icon,
									isImage: navItem.iconIsImage,
									label: navItem.nombrePadre ?? "",
									navigateTo: navItem.url,
									selected: pathname.includes(navItem.url)
								}} 
							/>
						}
					})
				}
			</ul>
		</div>
	)
}

export default SidebarNavigation;