import React from 'react'
import '../Loading/Loading.scss';
import { useLoading } from '../../../context/Loading.Context'

const Loading: React.FC = () => {

    const { isLoading} = useLoading();

    if (!isLoading) return null;

    return (
    <div className='loading-container'>
        <div className='loading-item'></div>
    </div>
    )
  
}

export default Loading;