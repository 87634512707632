import { BaseEntity } from "@pavabits/components";

interface TablePrintable {
	toMap: () => Map<any, string>
}

export default abstract class TablePrintableObject extends BaseEntity implements TablePrintable {
	
	abstract toMap: () => Map<any, string>;
	delegacionId: number | undefined;
	abstract controllerName: string
}