import ApiRequest from "../../../../domain/ApiRequest";
import Licitacion from "../../../Oportunidades/domain/model/Licitacion";
import { LicitacionesEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class LicitacionesRequestGetByCodigo extends ApiRequest<undefined, Licitacion> {
	constructor(codigo: number, token: string) {
		super(
			"GET",
			LicitacionesEndpoints.LICITACION_BY_CODIGO,
			{ Authorization: token },
			undefined,
			{ "codigo": codigo }
		)
	}
}