interface TipoSeguimientoClienteEntity  {
	id: string,
	descripcion: string,
	visible?: boolean
}


export default class TipoSeguimientoCliente implements TipoSeguimientoClienteEntity {

	id: string;
	descripcion: string;
	visible?: boolean;

	constructor(entity: TipoSeguimientoClienteEntity) {
		
		this.id = entity.id;
		this.descripcion = entity.descripcion;
		this.visible = entity.visible;
	}

	public static toArray(entities: TipoSeguimientoClienteEntity[]): TipoSeguimientoCliente[] {
		return entities.map(entity => { return new TipoSeguimientoCliente(entity) });
	}
}


export type TipoSeguimientoClienteDto = TipoSeguimientoCliente;
export type TipoSeguimientoClienteCreationDto = TipoSeguimientoCliente;
export type TipoSeguimientoClienteUpdateDto = TipoSeguimientoCliente;