import { useReducer } from "react"
import { MouseEventHandler } from "react";
import { BaseFilter, SortingField } from "../../../../domain/BaseFilter";
import { valueof } from "../../../../utils/Utilities";
import { ActionType, ConfigFilters, FilterAction, FilterReducer, FilterState, OutputFilters, SelectorFilter } from "./FilterTypes";

function prepareSortingCriteria(fields: SortingField[]) {
	let result: string[] = [];
	fields.forEach(field => {
		result.push(`${field.field}${field.desc ? " desc" : ""}`);
	})
	return result.join(", ");
}

function normalizeDecimalValue(value: number) {
	const decimalValue = value.toString().replace(",", ".");
	return decimalValue;
}

function reducer<T>(
	state: FilterState<T>,
	action: FilterAction<T>,
): FilterState<T> {

	switch (action.type) {
		case ActionType.UPDATE_FILTER:
			return {
				...state,
				filtering: {
					...state.filtering,
					filter: {
						...state.filtering.filter,
						[action.typeKey!]: action.payload,
					},
					skipCount: 0,
				}
			}
		case ActionType.CLEAR_FIELD:
			return {
				...state,
				filtering: {
					...state.filtering,
					skipCount: 0,
					filter: {
						...state.filtering.filter,
						[action.typeKey!]: undefined
					}
				}
			}
		case ActionType.SET_MAX_COUNT:
			return {
				...state,
				filtering: {
					...state.filtering,
					maxResultCount: action.payload,
				}
			}
		case ActionType.RESET:
			return {
				...action.payload
			}
		case ActionType.SORT:
			return {
				...state,
				sorting: action.payload,
				filtering: {
					...state.filtering,
					sortingCriteria: prepareSortingCriteria(action.payload),
				}
			};
		case ActionType.SET_PAGE:
			return {
				...state,
				filtering: {
					...state.filtering,
					skipCount: action.payload * state.filtering.maxResultCount
				}
			}
		case ActionType.PAGE_BACKWARDS:
			return {
				...state,
				filtering: {
					...state.filtering,
					skipCount: state.filtering.skipCount - state.filtering.maxResultCount
				}
			}
		case ActionType.DATE_SELECTED:
			const [dateFrom, dateUntil] = action.payload;

			return {
				...state,
				filtering: {
					...state.filtering,
					filter: {
						...state.filtering.filter,
						[action.typeKey!]: dateFrom,
						[action.secondaryKey!]: dateUntil,
					},
					skipCount: 0,
				},
			}
		case ActionType.CLEAR_DATE:
			return {
				...state,
				filtering: {
					...state.filtering,
					filter: {
						...state.filtering.filter,
						[action.typeKey!]: undefined,
						[action.secondaryKey!]: undefined,
					},
					skipCount: 0,
				},
			}
		case ActionType.MIN_MAX_INPUT:
			return {
				...state,
				filtering: {
					...state.filtering,
					filter: {
						...state.filtering.filter,
						[action.typeKey!]: action.payload.min && normalizeDecimalValue(action.payload.min),
						[action.secondaryKey!]: action.payload.max && normalizeDecimalValue(action.payload.max),
					},
					skipCount: 0,
				}
			}
		case ActionType.CLEAR_MINMAX:
			return {
				...state,
				filtering: {
					...state.filtering,
					filter: {
						...state.filtering.filter,
						[action.typeKey!]: undefined,
						[action.secondaryKey!]: undefined,
					},
					skipCount: 0,
				}
			}
		default:
			return state;
	}
}

export default function useFilters<T>(
	{
		filtering = {
			filter: {},
			maxResultCount: 10,
			skipCount: 0,
			sortingCriteria: "",
		},
		sorting,
	}: ConfigFilters<T>
): OutputFilters<T> {
	const initialFilter = { filtering, sorting }
	const [
		state,
		dispatch
	] = useReducer<FilterReducer<FilterState<T>, FilterAction<T>>>(reducer, initialFilter);

	const onSelectField = (typeKey: keyof T) : SelectorFilter => {
		return (
			{
				value: valueof(state.filtering?.filter, typeKey) ?? [],
				onChange: onSelected(typeKey),
				onClear: onClear(typeKey),
			}
		)
	}

	const onSearchField = (typeKey: keyof T) => {
		return (
			{
				value: valueof(state.filtering?.filter, typeKey),
				onChange: onSearch(typeKey),
				onClear: onClear(typeKey),
			}
		)
	}

	const onSelected = (typeKey: keyof T) => {
		return (value: React.MouseEvent<HTMLButtonElement, MouseEventHandler>) => {
			dispatch({
				type: ActionType.UPDATE_FILTER,
				typeKey,
				payload: value
			})
		}
	}

	const onSearch = (typeKey: keyof T) => {
		return (value: string) => {
			dispatch({
				type: ActionType.UPDATE_FILTER,
				typeKey,
				payload: value
			})
		}
	}

	const onClear = (typeKey: keyof T) => {
		return () => {
			dispatch({
				type: ActionType.CLEAR_FIELD,
				typeKey,
				payload: undefined
			})
		}
	}

	const onDateField = (typeKey: keyof T, secondaryKey?: keyof T) => {
		return (
			{
				value: [valueof(state.filtering?.filter, typeKey), valueof(state.filtering?.filter, secondaryKey!)],
				onChange: onDateSelect(typeKey, secondaryKey!),
				onClear: onDateClear(typeKey, secondaryKey!),
			}
		)
	}

	const onDateSelect = (typeKey: keyof T, secondaryKey: keyof T) => {
		return (dates: string[]) => {
			dispatch({
				type: ActionType.DATE_SELECTED,
				typeKey,
				secondaryKey,
				payload: dates,
			})
		}
	}

	const onDateClear = (typeKey: keyof T, secondaryKey: keyof T) => {
		return () => {
			dispatch({
				type: ActionType.CLEAR_DATE,
				typeKey,
				secondaryKey,
				payload: undefined,
			})
		}
	}

	const onRangeField = (key: keyof T) => {
		return (
			{
				value: valueof(state.filtering?.filter, key),
				onChange: onMinMax(key),
				onClear: onMinMaxClear(key),
			}
		)
	}

	const onMinMax = (key: keyof T) => {
		return (min?: number, max?: number) => {
			dispatch({
				type: ActionType.MIN_MAX_INPUT,
				payload: { min, max },
				typeKey: key,
			})
		}
	}

	const onMinMaxClear = (key: keyof T) => {
		return () => {
			dispatch({
				type: ActionType.CLEAR_MINMAX,
				payload: undefined,
				typeKey: key,
			})
		}
	}

	const onSetMaxCount = (filterKey: keyof BaseFilter<T>) => {
		return (value: number) => {
			dispatch({
				type: ActionType.SET_MAX_COUNT,
				filterKey,
				payload: value
			})
		}
	}

	const onReset = () => {
		return () => {
			dispatch({
				type: ActionType.RESET,
				payload: initialFilter
			})
		}
	}

	const onSort = (filterKey: keyof BaseFilter<T>) => {
		return (field: SortingField[]) => {
			dispatch({
				type: ActionType.SORT,
				filterKey,
				payload: field
			})
		}
	}

	const onPageSelect = (value: number) => {
		dispatch({
			type: ActionType.SET_PAGE,
			payload: value
		})
	}

	const onPageBackwards = () => {
		dispatch({
			type: ActionType.PAGE_BACKWARDS,
			payload: -1
		})
	}

	return {
		filter: state.filtering!,
		sorting: state.sorting!,
		onSelectField,
		onSearchField,
		onDateField,
		onRangeField,
		onReset,
		onSetMaxCount,
		onSort,
		onPageSelect,
		onPageBackwards,
	}
}