import { BaseApiRequest } from "@pavabits/components";
import ConfiguracionGridDto from "../../domain/model/ConfiguracionGrid/ConfiguracionGridDto";
import ConfiguracionGridFilter from "../../domain/model/ConfiguracionGrid/ConfiguracionGridFilter";
import ApiEndpoints from "../api-endpoints";

export default class ConfiguracionGridRequestListByFilter extends BaseApiRequest<
 ConfiguracionGridFilter,
 ConfiguracionGridDto[]
> {
	public constructor(data: ConfiguracionGridFilter, token: string) {
		super(
			"POST",
			ApiEndpoints.CONFIGURACION_GRID.CONFIGURACIONES,
			data,
			{ Authorization: `${token}`},
		)
	}
}