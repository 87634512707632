import ApiRequest from "../../../../domain/ApiRequest";
import { ElementosEquipoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class EquipoElementoRequestGetById extends ApiRequest<
string
>
{
	constructor(id: string, token: string) {
		super(
			"GET",
			ElementosEquipoApiEndpoints.GET_BY_ID+id,
			{ Authorization: `${token}`},
			""
			
		)
	}
}