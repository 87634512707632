
import { BaseApiRequest } from "@pavabits/components";

import { ElementoEquipoDto } from "../../domain/model/ElementoEquipo";
import BaseResponse from "../../../../domain/BaseResponse";
import { ElementosDtoFilter } from "../../domain/model/Equipo";
import { ElementosEquipoApiEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";

export default class EquipoElementoRequestListByFilter extends BaseApiRequest
<
	ElementosDtoFilter, 
	BaseResponse<ElementoEquipoDto[]>
>
{
	public constructor (data : ElementosDtoFilter, token : string){
        super(
            "POST",
            ElementosEquipoApiEndpoints.ELEMENTO_CON_FILTRO,
            data,
            {Authorization: `${token}`}
        )
    }
}