import ApiRequest from "../../../../domain/ApiRequest";
import BaseResponse from "../../../../domain/BaseResponse";
import { SeguimientoClienteEndpoints } from "../../../Oportunidades/infraestructure/oportunidades.api-endpoints";
import { SeguimientoClienteDto } from "../../domain/model/SeguimientoCliente";

export default class SeguimientoClienteRequestGetByClienteId extends ApiRequest<{}, BaseResponse<SeguimientoClienteDto[]>>
{
	constructor(id: number, token: string) {
		super(
			"POST",
			SeguimientoClienteEndpoints.CON_FILTRO,
			{ Authorization: token },
			{ "filter": {
				"clienteId": id
			  }
			}
		)
	}
}