import { Route } from "react-router-dom"
import Dashboard from "../../components/ui/objects/Dashboard/Dashboard"
import { VentanaPermiso } from "../../modules/Login/domain/model/VentanaPermiso"

interface Props {
	path: string
	exact?: boolean
	strict?: boolean
	Component: any,
	permisoVentana?: VentanaPermiso
}

const RouteDashboard: React.FC<Props> = (props: Props) => {
	const {
		Component,
		exact = true,
		path,
		strict = false,
		permisoVentana: permisoVentana
	} = props;
	
	return (
		<Route
			path={path}
			exact={exact}
			strict={strict}
			render={(props) => {
				return (
					<Dashboard>
						<Component {...props} permisoVentana={permisoVentana} />
					</Dashboard>
				)
			}}
		/>
	)
}

export default RouteDashboard;